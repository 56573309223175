import clsx from "clsx";
import { compactInteger } from "humanize-plus";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-v4";

export default class PreviewOrganizationName extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    organizationsStoreParams: PropTypes.object.isRequired,
    filterSource: PropTypes.object.isRequired,
    returnUrl: PropTypes.string.isRequired,
  };

  render() {
    const {
      organization: { name, id } = {},
      organizationsStoreParams,
      returnUrl,
      filterSource,
      visualizeScore,
      scores,
    } = this.props;

    return (
      <div className="preview-organization--header-name">
        {visualizeScore && (
          <span
            className={clsx(
              scores?.[id] * 100 <= 33.33 && "red",
              33.33 < scores?.[id] * 100 < 66.66 && "yellow",
              scores?.[id] * 100 >= 66.66 && "green"
            )}
          >
            {compactInteger(scores?.[id] * 100, 0)}
          </span>
        )}
        &nbsp;
        <Link
          to={{
            pathname: `/organizations/${id}`,
            state: { organizationsStoreParams, returnUrl, filterSource },
          }}
        >
          {name}
        </Link>
      </div>
    );
  }
}
