import React from "react";
import { withRouter, Link } from "react-router-v4";
import Card from "../../../card/Card";
import CardHeader from "../../../card/CardHeader";
import CardBody from "../../../card/CardBody";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";

const CompetitorRow = ({ competitor }) => {
  const {
    name,
    domain,
    location,
    age,
    number_employees,
    funding_amount,
    investors,
    web_traffic,
    company_classifications,
  } = competitor;

  const formatNumber = (number) =>
    number ? number.toLocaleString() : "Unknown";

  let investorsList = [];
  try {
    if (investors) {
      investorsList =
        typeof investors === "string" && investors.charAt(0) === "["
          ? investors
              .substring(1, investors.length - 1)
              .split(",")
              .map((investor) => investor.trim())
              .map((investor) => investor.substring(1, investor.length - 1))
          : investors;
    }
  } catch (e) {
    console.error("Error parsing investors:", e);
    investorsList = [];
  }

  return (
    <TableRow>
      <TableRowColumn>
        <a href={`https://${domain}`} title={name} target="_blank">
          {name}
        </a>
      </TableRowColumn>
      <TableRowColumn>{location}</TableRowColumn>
      <TableRowColumn>{age ? `${age.toFixed(0)}y` : "Unknown"}</TableRowColumn>
      <TableRowColumn>{formatNumber(number_employees)}</TableRowColumn>
      <TableRowColumn>{funding_amount}</TableRowColumn>
      <TableRowColumn
        title={
          investorsList && investorsList.length > 3
            ? investorsList.join(", ")
            : ""
        }
        style={{ width: "25%" }}
      >
        {investorsList && investorsList.length > 0
          ? investorsList.slice(0, 3).join(", ")
          : "None"}
      </TableRowColumn>
      <TableRowColumn>{formatNumber(parseInt(web_traffic))}</TableRowColumn>
      <TableRowColumn>{company_classifications}</TableRowColumn>
    </TableRow>
  );
};

const LLMCompetitorAnalysis = ({ organization }) => {
  const { ml_features: { llm_competitors_analysis: analysis = {} } = {} } =
    organization;

  if (!analysis) {
    return <React.Fragment />;
  }
  const { highlights: { competitors_table_json } = {} } = analysis;
  if (!competitors_table_json) {
    return <React.Fragment />;
  }

  const competitors =
    typeof competitors_table_json === "string"
      ? JSON.parse(competitors_table_json)
      : competitors_table_json;

  return (
    <div className="people-section">
      <Card>
        <CardHeader title="Market" />
        <CardBody>
          <div className="section-title" style={{ marginTop: "16px" }}>
            LLM Competitor Analysis: {analysis.transformed || "Unknown"}
          </div>
          <Table selectable={false} fixedHeader={true} height={300}>
            <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
              <TableRow>
                <TableHeaderColumn>Name</TableHeaderColumn>
                <TableHeaderColumn>Location</TableHeaderColumn>
                <TableHeaderColumn>Age</TableHeaderColumn>
                <TableHeaderColumn>Number of Employees</TableHeaderColumn>
                <TableHeaderColumn>Funding Amount</TableHeaderColumn>
                <TableHeaderColumn style={{ width: "25%" }}>
                  Investors
                </TableHeaderColumn>
                <TableHeaderColumn>Web Traffic (pw)</TableHeaderColumn>
                <TableHeaderColumn>Classifications</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {competitors.map((competitor, index) => (
                <CompetitorRow competitor={competitor} key={index} />
              ))}
            </TableBody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default withRouter(LLMCompetitorAnalysis);
