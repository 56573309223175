import request from "superagent";
import auth from "./Auth";
import Config from "../config";

export default class IsInterestingStore {
  constructor(organizationId) {
    this.organizationId = organizationId;
    this.url = `${Config.api.host}/organizations/${organizationId}/interesting`;
  }

  decide(assignCampaign) {
    return auth.getAuthData().then((authData) =>
      request
        .post(this.url)
        .send(assignCampaign)
        .query({
          access_token: authData.token,
          client: authData.client,
        })
        .then((response) => response.body)
        .then((body) => {
          if (typeof ga !== "undefined") {
            ga("send", {
              hitType: "event",
              eventCategory: "AfterFormEvaluation",
              eventAction: "set",
            });
          }
          return body;
        })
    );
  }
}
