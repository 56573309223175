import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

import Card from "../../../card/Card";
import CardHeader from "../../../card/CardHeader";
import CardBody from "../../../card/CardBody";
import Loading from "../../../loading/Loading";
import TimelineHeader from "./TimelineHeader";
import TimelineBody from "./TimelineBody";
import AddNoteDialog from "./AddNoteDialog";

import timelineEventTypes from "../../../../content/timelineEventTypes";
import defaultSelectedFilters from "../../../../content/defaultSelectedFilters";
import checklistTasks from "../../../../content/checklistTasks";
import makeContactChecklistConfig from "../../../../content/makeContactChecklist";
import unableToContactChecklistConfig from "../../../../content/unableToContactChecklist";
import moment from "moment";

export default class Timeline extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    organizationDecision: PropTypes.object.isRequired,
    timeline: PropTypes.object.isRequired,
    timelineLoading: PropTypes.bool.isRequired,
    refreshTimeline: PropTypes.func.isRequired,
    handleMakeContactChecklistChange: PropTypes.func.isRequired,
    openBackupMessageModal: PropTypes.func.isRequired,
  };

  state = {
    selectedFilters: ["all"],
    freshBuild: true,
    addStatusDialogOpen: false,
    addNoteDialogOpen: false,
  };

  usedEventTypes = null;
  skipTypes = ["sequence_steps", "events", "sources"];

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevProps.timeline, this.props.timeline)) {
      this.setState({
        freshBuild: true,
        selectedFilters: this.getDefaultSelectedFilters(this.props.timeline),
      });
    }
  }

  getDefaultSelectedFilters = (timeline = this.props.timeline) => {
    const { events = [] } = timeline;
    const availableFilters = new Set(
      events
        .map((event) => event.collection)
        .filter((collection) => defaultSelectedFilters.indexOf(collection) > -1)
    );
    return availableFilters.size > 0
      ? Array.from(availableFilters.values())
      : ["all"];
  };

  buildTimeline = (filteredTimeline) => {
    const timeline = { todo: [], upcoming: [], past: [] };
    const { organizationDecision, reconnectActive, reconnect } = this.props;

    filteredTimeline.forEach((event) => {
      switch (event.action_status) {
        case "todo":
          timeline.todo.push(event);
          break;
        case "upcoming":
          timeline.upcoming.push(event);
          break;
        case "past":
        default:
          this.handlePastEvent(timeline.past, event);
          break;
      }
    });

    if (reconnectActive && organizationDecision.stage === "in_tracking") {
      this.pushReconnect(timeline, reconnect);
    }

    const { stage, make_contact_checklist: makeContactChecklist = {} } =
      this.props.organizationDecision;

    this.pushChecklistTasks(
      timeline,
      makeContactChecklist,
      this.getChecklistConfig(stage)
    );

    return timeline;
  };

  // TODO: item.keen.timestamp is when the event object was created, not the creation of the actual event.
  //  It is wrong to use it.
  getPastEventTime = (item) => {
    switch (item.collection) {
      case "decisions":
        return item.decision.created_at;
      default:
        return item.keen.created_at || new Date(item.keen.timestamp);
    }
  };

  handlePastEvent = (past, event, date = null) => {
    const finalDate = new Date(date || this.getPastEventTime(event));
    const currentDates = {
      dateAndTime: finalDate.getTime(),
      date: finalDate.setHours(0, 0, 0, 0),
    };
    const pastDateIndex = this.getPastDateIndex(past, currentDates.date);
    if (pastDateIndex > -1) {
      this.pushEventToDate(
        past[pastDateIndex].content,
        event,
        currentDates.dateAndTime
      );
    } else {
      this.pushDateToPast(past, event, currentDates.date);
    }
  };

  getPastDateIndex = (past, date) =>
    past.findIndex((pastDate) => pastDate.date === date);

  pushEventToDate = (content, event, dateAndTime) => {
    this.addValueToArray(
      content,
      event,
      this.getIndexValueByDate(content, dateAndTime)
    );
  };

  pushDateToPast = (past, event, date) => {
    this.addValueToArray(
      past,
      { date: date, content: [event] },
      this.getIndexValueByDate(past, date)
    );
  };

  getIndexValueByDate = (array, date) =>
    array.findIndex((value) => date >= this.getDate(value));

  addValueToArray = (array, value, index) => {
    if (index < 0) {
      array.push(value);
    } else if (index === array.length) {
      array.unshift(value);
    } else {
      array.splice(index, 0, value);
    }
  };

  getDate = (value) =>
    new Date(
      value.date ||
        value.completedDate ||
        value.keen.timestamp ||
        value.keen.created_at
    ).getTime();

  pushReconnect = (timeline, reconnect = this.props.reconnect) => {
    if (new Date(reconnect.when) < new Date()) {
      timeline.todo.push(this.buildReconnect(reconnect, false));
    } else {
      timeline.upcoming.push(this.buildReconnect(reconnect, true));
    }
  };

  buildReconnect = (reconnect, canCancel) => ({
    type: "reconnect",
    collection: "reconnect",
    key: reconnect.when,
    reconnect,
    canCancel,
  });

  pushChecklistTasks = (timeline, checkedTasks, checklist) => {
    const checklistSectionsComplete = [];
    this.handleCheckedTasks(checkedTasks, timeline.past);
    if (!checklist) return;
    checklist.forEach((checklistSection, index) => {
      const dates = [];
      checklistSection.tasks.forEach((task) => {
        if (checklistSection.active === 0) {
          this.handleImmediateTask(checkedTasks, task, dates, timeline.todo);
        } else {
          if (checklistSectionsComplete.length === 0) {
            if (!(task in checkedTasks)) {
              this.handleUpcomingTask(task, timeline.upcoming);
            }
          } else {
            if (
              checklistSectionsComplete[checklistSectionsComplete.length - 1]
                .active === checklist[index - 1].active
            ) {
              const dueIn = this.getDueInDays(
                checklistSectionsComplete,
                checklistSection.active
              );
              if (new Date().getTime() >= dueIn) {
                this.handleImmediateTask(
                  checkedTasks,
                  task,
                  dates,
                  timeline.todo
                );
              } else {
                if (!(task in checkedTasks)) {
                  this.handleUpcomingTask(task, timeline.upcoming, dueIn);
                }
              }
            } else {
              if (!(task in checkedTasks)) {
                this.handleUpcomingTask(task, timeline.upcoming);
              }
            }
          }
        }
      });
      if (dates.length === checklistSection.tasks.length) {
        checklistSectionsComplete.push({
          active: checklistSection.active,
          timeCompleted: this.getLastCheckedTime(dates),
        });
      }
    });
  };

  handleCheckedTasks = (checkedTasks, past) => {
    for (const key in checkedTasks) {
      if (!checkedTasks.hasOwnProperty(key) || !checklistTasks[key]) continue;
      this.handlePastEvent(
        past,
        this.buildTaskObject(key, "past", checkedTasks[key]),
        checkedTasks[key]
      );
    }
  };

  handleImmediateTask = (checkedTasks, task, dates, todo) => {
    if (checkedTasks[task]) {
      dates.push(checkedTasks[task]);
    } else {
      this.pushTask(todo, this.buildTaskObject(task, "todo"));
    }
  };

  handleUpcomingTask = (task, upcoming, due = null) => {
    this.pushTask(upcoming, this.buildTaskObject(task, "upcoming", null, due));
  };

  getDueInDays = (complete, days) =>
    complete[complete.length - 1].timeCompleted + this.getDaysPassed(days);

  getDaysPassed = (days) => 1000 * 60 * 60 * 24 * days;

  pushTask = (content, task) => {
    content.push(task);
  };

  buildTaskObject = (key, type, completedDate = null, dueIn = null) => ({
    type: "inreachTask",
    key: key,
    text: checklistTasks[key].text,
    completed: type === "past",
    completedDate: completedDate,
    dueIn: dueIn,
  });

  getLastCheckedTime = (dates) => Math.max(...dates);

  getChecklistConfig = (stage) =>
    stage === "make_contact"
      ? makeContactChecklistConfig
      : stage === "unable_to_contact"
      ? unableToContactChecklistConfig
      : null;

  filterTimeline = (events, selectedFilters) => {
    if (events.length === 0) return [];
    return events
      .reverse()
      .filter((event, index) => {
        if (event.collection === "scorecard") {
          event.previous =
            (this.findNextMatchingEvent(events, "scorecard", -1, index) || {})
              .scorecard || null;
        }
        return this.shouldEventBeInTimeline(
          events,
          event,
          index,
          selectedFilters
        );
      })
      .reverse();
  };

  shouldEventBeInTimeline = (events, event, index, filters) => {
    if (event.action_status !== "past") return true;
    const filtersPassed =
      filters.includes(event.collection) || filters[0] === "all";
    return (
      filtersPassed &&
      timelineEventTypes[event.collection] &&
      !this.skipTypes.includes(event.collection) &&
      this.isClientInReach(event) &&
      this.eventStatusAllowed(event) &&
      !this.isDuplicateEvent(events, event, index) &&
      this.mailingEventHasRequiredFields(event) &&
      this.eventInboxAdded(event) &&
      this.eventEditsAllowed(event)
    );
  };

  isClientInReach = (event) => {
    if (
      event.collection === "mailings" ||
      event.collection === "tasks" ||
      event.collection === "sequence_steps" ||
      event.collection === "sequence_states" ||
      event.collection === "events" ||
      event.collection === "gmail_messages" ||
      event.collection === "social_messages" ||
      event.collection === "organizations_edits"
    ) {
      return true;
    }
    return (
      event[this.getCollectionObjectKey(event.collection)].client ===
      "inreachventures"
    );
  };

  eventInboxAdded = (event) => {
    if (event.collection === "inboxes") {
      const inboxType = "user_id" in event.inbox ? "inbox" : "form";
      if (inboxType === "inbox" && !event.inbox.inbox) {
        return false;
      }
    }
    return true;
  };

  eventEditsAllowed = (event) => {
    const {
      collection,
      organization_edit: { updated_fields: updatedFields = [] } = {},
    } = event;
    if (collection === "organizations_edits") {
      return (
        updatedFields.includes("primary_contact_id") ||
        updatedFields.includes("is_complete") ||
        (updatedFields.includes("person") &&
          !!updatedFields.find((updatedField) =>
            updatedField.includes("email")
          ))
      );
    } else return true;
  };

  eventStatusAllowed = (event) => {
    // There could be and more likely will be more types in here in the future after iterating and testing more companies.
    // That's why there's an if and switch statement
    if (event.collection === "communications") {
      switch (event.collection) {
        case "communications":
          return (
            event.communication.status === "DELIVERED" ||
            event.communication.status === "ERROR" ||
            event.communication.status === "REQUIRES_DATA" ||
            event.communication.status === "REQUIRES_ALTERNATE_DATA"
          );
      }
    } else {
      return true;
    }
  };

  isDuplicateEvent = (events, event, index) => {
    const previousDisplayedEvent = this.findNextMatchingEvent(
      events,
      event.collection,
      -1,
      index
    );
    if (!previousDisplayedEvent) return false;
    const collectionObjectKey = this.getCollectionObjectKey(event.collection);
    if (
      event.collection === "decisions" ||
      event.collection === "inboxes" ||
      event.collection === "communications" ||
      event.collection === "scorecard" ||
      event.collection === "social_messages" ||
      event.collection === "organizations_edits"
    ) {
      return !this.hasChanged(
        event.collection,
        event[collectionObjectKey],
        previousDisplayedEvent[collectionObjectKey]
      );
    } else {
      return event.collection === "calendar_event"
        ? event[collectionObjectKey].created ===
            previousDisplayedEvent[collectionObjectKey].created
        : event[collectionObjectKey].created_at ===
            previousDisplayedEvent[collectionObjectKey].created_at;
    }
  };

  findNextMatchingEvent = (events, type, direction, index) => {
    if (
      type === "tasks" ||
      type === "mailings" ||
      type === "sequence_steps" ||
      type === "event"
    )
      return null;
    index = this.incrementValue(index, direction);
    while (direction === -1 ? index >= 0 : index <= events.length - 1) {
      const event = events[index];
      if (event.collection === type) {
        return event;
      }
      index = this.incrementValue(index, direction);
    }
    return null;
  };

  incrementValue = (value, direction) => (direction === -1 ? --value : ++value);

  getCollectionObjectKey = (collection) => {
    switch (collection) {
      case "inboxes":
        return "inbox";
      case "decisions":
        return "decision";
      case "communications":
        return "communication";
      case "gmail_messages":
        return "message";
      case "sequence_states":
        return "sequence_state";
      default:
        return collection;
    }
  };

  hasChanged = (collection, current, previous) => {
    switch (collection) {
      case "inboxes":
        return (
          current.user_id !== previous.user_id ||
          current.assigned_to !== previous.assigned_to ||
          current.inbox !== previous.inbox ||
          current.notes !== previous.notes ||
          current.reason !== previous.reason
        );
      case "decisions":
        return (
          current.user_id !== previous.user_id ||
          current.is_final !== previous.is_final ||
          current.state !== previous.state ||
          current.stage !== previous.stage ||
          current.notes !== previous.notes ||
          current.assigned_to !== previous.assigned_to ||
          current.status !== previous.status ||
          current.source_filters !== previous.source_filters
        );
      case "communications":
        return current.status !== previous.status;
      case "scorecard":
        const { call: currentCalls = [] } = current;
        const { previous: previousCalls = [] } = previous;
        if (currentCalls.length > 0 || previousCalls.length > 0) {
          if (
            currentCalls.length !== previousCalls.length ||
            ("decision" in currentCalls[0] &&
              !("decision" in previousCalls[0])) ||
            ("recommendation" in currentCalls[0] &&
              !("recommendation" in previousCalls[0])) ||
            !_.isEqual(
              currentCalls[0].recommendation,
              previousCalls[0].recommendation
            ) ||
            !_.isEqual(currentCalls[0].decision, previousCalls[0].decision)
          ) {
            return true;
          }
        }
        return false;
      case "social_messages":
        return current.updated_at !== previous.updated_at;
      case "organizations_edits":
        return true;
      default:
        return false;
    }
  };

  mailingEventHasRequiredFields = (event) => {
    if (event.collection === "mailings") {
      const mailing = event.mailing || event.other.mailing;
      return !!event.person && mailing.subject && mailing.body_html;
    }
    return true;
  };

  getUsedEventTypes = (events, freshBuild) =>
    freshBuild
      ? (this.usedEventTypes = [
          ...new Set(events.map((event) => event.collection)),
        ].filter((event) => this.skipTypes.indexOf(event) === -1))
      : this.usedEventTypes;

  filterClick = (eventType) => {
    const filters = this.state.selectedFilters;
    // User clicked 'all' when it was already selected. Can't deselect 'all' filter. Return nothing
    if (eventType === "all" && filters[0] === "all") return;
    if (filters.includes(eventType)) {
      this.deselectFilter(filters, eventType);
    } else {
      this.selectFilter(filters, eventType);
    }
    this.setState({
      selectedFilters: filters,
      freshBuild: false,
    });
  };

  mobileFiltersToggleClick = (filters = this.state.selectedFilters) => {
    this.setState({
      selectedFilters:
        filters[0] === "all" ? this.getDefaultSelectedFilters() : ["all"],
      freshBuild: false,
    });
  };

  deselectFilter = (filters, type) => {
    if (filters.length === 1) {
      this.deselectLastFilter(filters);
    } else if (filters.length < 1) {
      // There's an error/something went wrong, filters are empty. Reset
      this.resetFilters(filters);
    } else {
      this.removeFilter(filters, type);
    }
  };

  selectFilter = (filters, type) => {
    if (type === "all") {
      this.selectAllFilter(filters);
    } else {
      this.selectDifferentFilterToAll(filters, type);
    }
  };

  deselectLastFilter = (filters) => {
    this.resetFilters(filters);
  };

  resetFilters = (filters) => {
    filters.splice(0, filters.length, "all");
  };

  removeFilter = (filters, type) => {
    filters.splice(filters.indexOf(type), 1);
  };

  selectAllFilter = (filters) => {
    this.resetFilters(filters);
  };

  selectDifferentFilterToAll = (filters = [], type) => {
    if (filters[0] === "all") {
      filters.splice(0, 1);
    }
    filters.push(type);
  };

  onTaskClick = (key) => {
    const { make_contact_checklist: currentMakeContactChecklist = {} } =
      this.props.organizationDecision;
    if (!currentMakeContactChecklist[key]) {
      this.props.handleMakeContactChecklistChange(
        Object.assign({}, currentMakeContactChecklist, {
          [key]: new Date().getTime(),
        })
      );
    } else {
      const { [key]: omit, ...res } = currentMakeContactChecklist;
      this.props.handleMakeContactChecklistChange(res);
    }
  };

  toggleAddEventDialog = (event) => {
    switch (event) {
      case "status":
        this.setState({
          addStatusDialogOpen: !this.state.addStatusDialogOpen,
        });
        break;
      case "note":
        this.setState({
          addNoteDialogOpen: !this.state.addNoteDialogOpen,
        });
        break;
    }
  };

  render() {
    const {
      timeline,
      timelineLoading,
      openBackupMessageModal,
      organizationDecision,
    } = this.props;
    const { selectedFilters, freshBuild, addNoteDialogOpen } = this.state;
    const { stage = "" } = organizationDecision;
    const { events = [] } = timeline || {};
    const filteredTimeline =
      events.length > 0
        ? this.filterTimeline(events.slice(0), selectedFilters)
        : [];
    return (
      <div className="timeline">
        <Card expandable={true}>
          <CardHeader title="Timeline">
            <If condition={!timelineLoading}>
              <TimelineHeader
                eventTypes={timelineEventTypes}
                usedEventTypes={this.getUsedEventTypes(events, freshBuild)}
                stage={stage}
                selectedFilters={selectedFilters}
                filterClick={this.filterClick}
                mobileFiltersToggleClick={this.mobileFiltersToggleClick}
                openAddEventDialog={this.toggleAddEventDialog}
                openBackupMessageModal={openBackupMessageModal}
                refreshTimeline={this.props.refreshTimeline}
              />
            </If>
          </CardHeader>
          <CardBody>
            <Choose>
              <When condition={!timelineLoading}>
                <TimelineBody
                  {...this.props}
                  timeline={this.buildTimeline(filteredTimeline)}
                  onTaskClick={this.onTaskClick}
                />
              </When>
              <Otherwise>
                <Loading align="top" />
              </Otherwise>
            </Choose>
          </CardBody>
        </Card>
        <AddNoteDialog
          open={addNoteDialogOpen}
          onCloseDialog={this.toggleAddEventDialog}
        />
      </div>
    );
  }
}
