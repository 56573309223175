const DISTANCE_BETWEEN_DATES = 1000 * 60 * 60 * 24 * 30;
export function pickFormDate({ created, date_submitted: dateSubmitted } = {}) {
  if (
    created &&
    dateSubmitted &&
    dateSubmitted > created + DISTANCE_BETWEEN_DATES
  ) {
    return dateSubmitted;
  }
  return created || dateSubmitted;
}
