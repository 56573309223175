import moment from "moment";
import React from "react";
import PropTypes from "prop-types";

import Tooltip from "../Tooltip";

import {
  capitalizeString,
  getDateAndTime,
  getUserName,
} from "../../lib/helpers";

const extractId = (word) => {
  if (word.substring(0, 1) === "#") {
    let id = "";
    word.split("").forEach((letter) => {
      if (/[a-z_]/.test(letter)) {
        id += letter;
      }
    });
    if (id !== "") return id;
  }
  return null;
};

class StructuredReason extends React.Component {
  static propTypes = {
    structuredReason: PropTypes.object.isRequired,
  };

  state = {
    showTooltip: false,
  };

  render() {
    const { structuredReason: { title = "", category = "" } = {} } = this.props;
    const { showTooltip = false } = this.state;
    return (
      <span
        className="structured-reason"
        rel={(el) => (this._parent = el)}
        onMouseLeave={() => {
          this.setState({ showTooltip: false });
        }}
        onMouseEnter={() => {
          this.setState({ showTooltip: true });
        }}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={capitalizeString(category)}
          customStyles={{
            top: "-28px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
        {title}
      </span>
    );
  }
}

export default class DisplayReason extends React.Component {
  static propTypes = {
    getDecisionUser: PropTypes.func.isRequired,
    decision: PropTypes.object.isRequired,
  };

  generateReasonHTML = (reason, structuredReasons) => {
    if (reason === "") return <i>No notes recorded</i>;
    const newReason = [];
    reason.split(" ").forEach((word) => {
      const id = extractId(word) || null;
      if (id !== null && typeof structuredReasons[id] !== "undefined") {
        if (
          newReason.length !== 0 &&
          typeof newReason[newReason.length - 1] !== "string"
        ) {
          newReason.push(", ");
        }
        newReason.push(
          <StructuredReason
            structuredReason={structuredReasons[id]}
            key={structuredReasons + id}
          />
        );
        if (word.substring(word.length - 1, word.length) === ".") {
          newReason.push(". ");
        }
      } else {
        if (
          newReason.length === 0 ||
          typeof newReason[newReason.length - 1] !== "string"
        ) {
          if (newReason.length === 0) {
            newReason.push(`${word} `);
          } else {
            newReason.push(` ${word} `);
          }
        } else {
          newReason[newReason.length - 1] += `${word} `;
        }
      }
    });
    return newReason;
  };

  render() {
    const { getDecisionUser, decision, stage } = this.props;

    const isMLUpdated = decision?.source_filters?.name === "ml_updated";

    if (isMLUpdated) {
      return (
        <div className="display-reason">
          <If condition={decision && getDecisionUser}>
            <span class="display-reason-user-date">
              {getUserName(decision.user_id)} -{" "}
              {
                getDateAndTime(parseInt(decision.source_filters.params.date))
                  .date
              }
            </span>
          </If>
          <b>ML Update - Keep {stage}</b>
          <br />
          <br />
          <span class="display-reason-last-notes-intro">
            Last available notes:
          </span>
          <span class="display-reason-last-notes">
            {this.generateReasonHTML(
              decision.notes || "",
              decision.reasons || {}
            )}
          </span>
        </div>
      );
    }

    return (
      <div className="display-reason">
        <If condition={decision && getDecisionUser}>
          <span class="display-reason-user-date">
            {getUserName(
              getDecisionUser(
                decision.assigned_to,
                decision.state,
                decision.user_id,
                decision.reasons
              )
            )}{" "}
            - {getDateAndTime(decision.created_at).date}
          </span>
        </If>
        {this.generateReasonHTML(decision.notes || "", decision.reasons || {})}
      </div>
    );
  }
}
