import React from "react";
import { Link, withRouter } from "react-router-v4";
import PropTypes from "prop-types";
import Tooltip from "../../Tooltip";
import { buildSelectionLine } from "../InvestorHelper";
import _ from "underscore";

class InreacWorkflow extends React.Component {
  static propTypes = {
    companyInfo: PropTypes.object,
    portfolio: PropTypes.object,
    showMaxLength: PropTypes.number.isRequired,
    client: PropTypes.string.isRequired,
    updateSelectedTab: PropTypes.func.isRequired,
  };

  state = {
    showTooltip: false,
    showFull: false,
    numberHiddenInfo: 0,
  };

  toggleFullProfileClickHandler = () => {
    this.setState({
      showFull: !this.state.showFull,
    });
  };

  hasError = () => {
    return true;
  };

  getWorkflowOrganization = (portfolioOrganizations) => {
    const { showMaxLength, updateSelectedTab } = this.props;
    const { numberHiddenInfo, showFull } = this.state;
    const WorkflowSizesOrganizations = portfolioOrganizations.reduce(
      (workflowOrganizations, investment) => {
        [
          "unable_to_contact",
          "passed",
          "more_info",
          "in_tracking",
          "make_contact",
          "call_meeting",
          "pre_qualification",
          "qualification",
        ].forEach((category) => {
          const isWorkflowOrganization =
            category === "unable_to_contact"
              ? this.unableToContact(investment)
              : category === "passed"
              ? this.passed(investment)
              : category === "moreInfo"
              ? this.moreInfo(investment)
              : category === "in_tracking"
              ? this.inTracking(investment)
              : category === "pre_qualification"
              ? this.preQualification(investment)
              : category === "qualification"
              ? this.qualification(investment)
              : this.makeContact(investment);
          if (isWorkflowOrganization) {
            workflowOrganizations[category]["totalRaised"] += investment[
              "total_raised_usd"
            ]
              ? investment["total_raised_usd"]
              : 0;
            workflowOrganizations[category]["moneyRaised"] += investment[
              "money_raised_usd"
            ]
              ? investment["money_raised_usd"]
              : 0;
            workflowOrganizations[category]["count"] += 1;
            workflowOrganizations[category]["organizationIds"].push(
              investment["organization_id"]
            );
          }
        });

        return workflowOrganizations;
      },
      {
        unable_to_contact: {
          totalRaised: 0,
          moneyRaised: 0,
          count: 0,
          organizationIds: [],
        },
        in_tracking: {
          totalRaised: 0,
          moneyRaised: 0,
          count: 0,
          organizationIds: [],
        },
        passed: {
          totalRaised: 0,
          moneyRaised: 0,
          count: 0,
          organizationIds: [],
        },
        more_info: {
          totalRaised: 0,
          moneyRaised: 0,
          count: 0,
          organizationIds: [],
        },
        make_contact: {
          totalRaised: 0,
          moneyRaised: 0,
          count: 0,
          organizationIds: [],
        },
        call_meeting: {
          totalRaised: 0,
          moneyRaised: 0,
          count: 0,
          organizationIds: [],
        },
        pre_qualification: {
          totalRaised: 0,
          moneyRaised: 0,
          count: 0,
          organizationIds: [],
        },
        qualification: {
          totalRaised: 0,
          moneyRaised: 0,
          count: 0,
          organizationIds: [],
        },
      }
    );
    const newNumberHiddenInfo = WorkflowSizesOrganizations
      ? Math.max(
          Object.keys(WorkflowSizesOrganizations).length - showMaxLength,
          0
        )
      : 0;
    if (numberHiddenInfo !== newNumberHiddenInfo) {
      this.setState({ numberHiddenInfo: newNumberHiddenInfo });
    }
    return (
      <React.Fragment key={"rounds"}>
        {Object.entries(WorkflowSizesOrganizations)
          .slice(
            0,
            showFull ? newNumberHiddenInfo + showMaxLength : showMaxLength
          )
          .map(([seriesKey, round]) => {
            return buildSelectionLine(
              round,
              seriesKey,
              "workflow",
              updateSelectedTab,
              WorkflowSizesOrganizations
            );
          })}
      </React.Fragment>
    );
  };

  unableToContact = (investment) => {
    const { latest_final_or_investor_decision: decision = {} } = investment;
    const { stage = "", activity = {} } = decision;
    return stage === "unable_to_contact";
  };

  moreInfo = (investment) => {
    const { latest_final_or_investor_decision: decision = {} } = investment;
    const { stage = "", activity = {} } = decision;
    return stage === "more_info";
  };
  preQualification = (investment) => {
    const { latest_final_or_investor_decision: decision = {} } = investment;
    const { stage = "", activity = {} } = decision;
    return stage === "pre_qualification";
  };
  qualification = (investment) => {
    const { latest_final_or_investor_decision: decision = {} } = investment;
    const { stage = "", activity = {} } = decision;
    return stage === "qualification";
  };
  inTracking = (investment) => {
    const { latest_final_or_investor_decision: decision = {} } = investment;
    const { stage = "", activity = {} } = decision;
    return stage === "in_tracking";
  };
  passed = (investment) => {
    const { latest_final_or_investor_decision: decision = {} } = investment;
    const { stage = "", activity = {} } = decision;
    return stage === "passed";
  };
  makeContact = (investment) => {
    const { latest_final_or_investor_decision: decision = {} } = investment;
    const { stage = "", activity = {} } = decision;
    return stage === "make_contact";
  };

  render() {
    const {
      tabsContent: { portfolioAggregation: portfolioAggregation = [] } = {},
      portfolioAggregationLoading,
      showMaxLength,
    } = this.props;
    const { showTooltip, showFull, numberHiddenInfo } = this.state;
    return (
      <div
        className="data-wrapper founded"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: false });
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: true });
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-header">InReach Workflow</div>
        <Choose>
          <When condition={portfolioAggregationLoading}>
            <div className="data-content text empty">Loading Data</div>
          </When>
          <When condition={!portfolioAggregation.length}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <Tooltip parent={this._parent} showTooltip={showTooltip} text="" />
            {this.getWorkflowOrganization(portfolioAggregation)}
          </Otherwise>
        </Choose>
        <div className="person-grid-item toggle-full-profile-grid-item">
          <If condition={numberHiddenInfo > 0}>
            <div className={`data-content text ${showFull ? "component" : ""}`}>
              <Link
                style={{ cursor: "pointer" }}
                onClick={this.toggleFullProfileClickHandler}
              >
                {showFull
                  ? `Hide  All`
                  : `Show All (${numberHiddenInfo + showMaxLength})`}
              </Link>
            </div>
          </If>
        </div>
      </div>
    );
  }
}

export default withRouter(InreacWorkflow);
