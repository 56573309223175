import React from "react";
import Moment from "moment";
import PropTypes from "prop-types";

import Loading from "./loading/Loading";

import TextField from "material-ui/TextField";
import { List, ListItem } from "material-ui/List";

import ActionAdd from "material-ui/svg-icons/content/add";
import ActionRemove from "material-ui/svg-icons/content/remove-circle";

export default class DecisionStatuses extends React.Component {
  static propTypes = {
    organizationDecision: PropTypes.object.isRequired,
    onStatusChange: PropTypes.func.isRequired,
  };

  state = {
    editing: false,
    loading: false,
    statuses: [],
    status: "",
  };

  componentWillMount() {
    if (this.props.organizationDecision?.statuses) {
      const statuses = this.props.organizationDecision.statuses;
      this.setState({
        statuses: statuses,
      });
    }
  }

  setStatus = (value) => {
    this.setState({
      status: value,
    });
  };

  removeStatus = (note) => {
    const newStatuses = this.state.statuses.filter((elem) => elem !== note);

    this.props.onStatusesChange(newStatuses).then((res) => {
      this.setState({
        statuses: newStatuses,
      });
    });
  };

  saveStatus = () => {
    const newStatuses = this.state.statuses.concat([
      {
        date: Moment().valueOf(),
        text: this.state.status,
      },
    ]);

    this.props.onStatusesChange(newStatuses).then((res) => {
      this.setState({
        statuses: newStatuses,
        status: "",
      });
    });
  };

  render() {
    return (
      <div className="edit-status-wrapper">
        <div className="data-header edit-status-header">Notes</div>
        <List>
          {this.state.statuses.map((note) => {
            return (
              <ListItem
                primaryText={note.text}
                secondaryText={Moment(note.date).format("DD MMMM YYYY")}
                rightIconButton={
                  <ActionRemove
                    className="delete"
                    color="#808080"
                    onClick={(event) => this.removeStatus(note)}
                  />
                }
                innerDivStyle={{
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 4,
                  paddingRight: 30,
                }}
              />
            );
          })}
        </List>
        <div class="edit-status">
          <TextField
            multiLine={true}
            name="add-notes"
            hintText={"Add note"}
            value={this.state.status}
            onChange={(event) => {
              this.setStatus(event.target.value);
            }}
            className="edit-status-textbox"
            disabled={false}
            textareaStyle={{
              color: "#7f7f7f",
            }}
          />
          <ActionAdd
            onClick={this.saveStatus}
            color="#5DAB49"
            className="edit-status-icon"
          />
        </div>
      </div>
    );
  }
}
