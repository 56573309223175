import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import JobOpenings from "./JobOpenings";

class OtherInfoPeople extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
  };

  render() {
    const { organization = {} } = this.props;
    return (
      <div className="other-info-company">
        <div className="section-title">People</div>
        <JobOpenings organization={organization} />
      </div>
    );
  }
}

export default withRouter(OtherInfoPeople);
