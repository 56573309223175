import React from "react";
import PropTypes from "prop-types";

import xss from "xss";
import _ from "underscore";

import TextField from "material-ui/TextField";

export default class EditOrganizationDescription extends React.Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
    updateOrganizationFields: PropTypes.func.isRequired,
    source: PropTypes.string,
  };

  state = {
    description: "",
    error: false,
  };

  componentWillMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.description !== this.props.description) {
      this.initialize();
    }
  }

  initialize = () => {
    const { description = "" } = this.props;
    this.setState({
      description,
      error: this.getDescriptionError(description),
    });
  };

  getDescriptionError = (description) => description.length < 125;

  handleChange = (event) => {
    const description = xss(event.target.value);
    this.setState({
      description,
      error: this.getDescriptionError(description),
    });
    this.debounceChange(description);
  };

  debounceChange = _.debounce((description) => {
    const trimmedDescription = description.trim();
    this.setState({
      description: trimmedDescription,
    });
    this.props.updateOrganizationFields([
      { field: "description", value: trimmedDescription },
    ]);
  }, 1500);

  render() {
    const { source = "edit-incomplete" } = this.props;
    const { description = "", error = false } = this.state;
    return (
      <div className="edit-organization-description">
        <If condition={source === "edit-incomplete"}>
          <div className="edit-section-header">DESCRIPTION</div>
        </If>
        <TextField
          className="edit-organization-input-field large"
          value={description}
          onChange={this.handleChange}
          multiLine={true}
          rows={1}
          rowsMax={10}
          fullWidth={true}
          errorText={error ? "Required" : false}
          name="edit-organization-description"
        />
      </div>
    );
  }
}
