import PropTypes from "prop-types";
import React from "react";

import Checkbox from "material-ui/Checkbox";
import Star from "material-ui/svg-icons/toggle/star";
import StarBorder from "material-ui/svg-icons/toggle/star-border";
import moment from "moment";
import SubTask from "./SubTask";
import EditSubTask from "./EditSubTask";

import TaskFiles from "./TaskFiles";
import Tooltip from "../../Tooltip";

export default class Task extends React.Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    task: PropTypes.object.isRequired,
    updateTask: PropTypes.func.isRequired,
    updateSubTask: PropTypes.func.isRequired,
    deleteSubTask: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    onNewQualification: PropTypes.func.isRequired,
  };

  state = {
    newSubTask: "",
    selectedRow: -1,
    enableTooltips: false,
  };

  _ref = React.createRef();

  toggleCompleteSubTask = (subTask) => {
    subTask.completed = subTask.completed ? null : moment().format("x");
    return subTask;
  };

  toggleCompleteTask = () => {
    const task = this.props.task;
    task.completed = task.completed ? null : moment().format("x");
    return task;
  };

  toggleTaskPriority = () => {
    const task = this.props.task;
    task.priority = task.priority === 1 ? 0 : 1;
    return task;
  };

  componentDidMount() {
    this.setState({
      enableTooltips: true,
    });
  }

  componentWillUnmount() {
    this.setState({
      enableTooltips: false,
    });
  }

  render() {
    const {
      priority = 0,
      title = "",
      completed,
      description = null,
      category,
      id,
      sub_tasks = [],
    } = this.props.task;
    const {
      updateTask,
      updateSubTask,
      deleteSubTask,
      task,
      organizationId,
      onNewQualification,
      deleteFile,
    } = this.props;

    return (
      <div className={priority > 0 ? "task priority" : "task"}>
        <div className="task-checkbox">
          <div className="task-flex-box">
            <div className="task-sub-task-box">
              <div ref={this._ref}>
                <Checkbox
                  label={title}
                  labelStyle={{ fontSize: "16px", fontWeight: "bold" }}
                  checked={!!completed}
                  onClick={() => {
                    updateTask(category, id, this.toggleCompleteTask());
                  }}
                  color="primary"
                />
                <If condition={this.state.enableTooltips}>
                  <Tooltip
                    parent={this._ref.current}
                    showOnHover={!!completed || !!description}
                    position="top"
                    text={
                      !completed && description
                        ? description
                        : "Completed on: " +
                          moment.unix(completed / 1000).format("llll")
                    }
                  />
                </If>
              </div>
              <If condition={sub_tasks.length > 0}>
                <div className="sub-tasks">
                  {sub_tasks.map((subTask) => {
                    return (
                      <SubTask
                        key={subTask.id}
                        subTask={subTask}
                        task={task}
                        updateSubTask={updateSubTask}
                        toggleCompleteSubTask={() => {
                          updateSubTask(
                            category,
                            id,
                            this.toggleCompleteSubTask(subTask)
                          );
                        }}
                        deleteSubTask={deleteSubTask}
                      />
                    );
                  })}
                </div>
              </If>
              <EditSubTask
                task={task}
                subTaskTitle={""}
                updateSubTask={updateSubTask}
              />
            </div>
            <TaskFiles
              task={task}
              organizationId={organizationId}
              deleteFile={deleteFile}
              onNewQualification={onNewQualification}
              {...this.props}
            />
            {priority > 0 ? (
              <Star
                color="rgb(93, 171, 73)"
                className="star"
                onClick={() => {
                  updateTask(category, id, this.toggleTaskPriority());
                }}
              />
            ) : (
              <StarBorder
                color="rgb(93, 171, 73)"
                className="star"
                onClick={() => {
                  updateTask(category, id, this.toggleTaskPriority());
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
