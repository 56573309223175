import React from "react";
import { withRouter } from "react-router-v4";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import Snackbar from "material-ui/Snackbar";
import TextField from "material-ui/TextField";

import ActionDone from "material-ui/svg-icons/action/done";

import CreateOrganization from "./create/CreateOrganization";
import Loading from "../loading/Loading";
import DialogWrapper from "../components/DialogWrapper";
class QuickAdd extends CreateOrganization {
  componentWillReceiveProps(nextProps) {
    this.setState(({ payload }) => ({
      payload: {
        ...payload,
        name: nextProps.companyName,
      },
    }));
  }

  render() {
    const { isSubmitting, submitError, payload, errors } = this.state;

    const actions = [
      <RaisedButton
        key="cancel"
        label="Cancel"
        primary={false}
        onClick={this.props.handleClose}
        style={{ marginRight: "12px" }}
        disabled={isSubmitting}
      />,
      <RaisedButton
        key="create"
        label="Create"
        primary={true}
        onClick={this.handleSubmit}
        disabled={isSubmitting}
      />,
    ];

    return (
      <DialogWrapper
        title="Create organization"
        actions={actions}
        modal={true}
        className="quick-add"
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        repositionOnUpdate={true}
        open={this.props.open}
      >
        <Snackbar
          open={!!submitError}
          message={submitError}
          autoHideDuration={2000}
        />

        <Choose>
          <When condition={isSubmitting}>
            <div className="loading-spinner">
              <Loading fixed={true} />
            </div>
          </When>

          <Otherwise>
            <form
              className="form"
              onInvalid={this.handleInvalid}
              onSubmit={this.handleSubmit}
            >
              <div className="organization-create-fields">
                <TextField
                  id="name"
                  name="name"
                  value={this.state.payload.name}
                  floatingLabelText="Name (required)"
                  floatingLabelFixed={true}
                  onChange={this.handleChange}
                  errorText={this.state.errors.name}
                  required={true}
                  fullWidth={true}
                />

                <TextField
                  id="homepage_url"
                  name="homepage_url"
                  type="url"
                  value={this.state.payload.homepage_url}
                  floatingLabelText="Website URL"
                  floatingLabelFixed={true}
                  onChange={this.handleChange}
                  errorText={this.state.errors.homepage_url}
                  title="Website URL, starting with http:// or https://"
                  pattern="(http|https)://.+"
                  fullWidth={true}
                />

                <TextField
                  id="introduced_by"
                  name="introduced_by"
                  value={this.state.payload.introduced_by}
                  floatingLabelText="Introduced By"
                  floatingLabelFixed={true}
                  onChange={this.handleChange}
                  errorText={this.state.errors.introduced_by}
                  fullWidth={true}
                />
              </div>
            </form>
          </Otherwise>
        </Choose>
      </DialogWrapper>
    );
  }
}

export default withRouter(QuickAdd);
