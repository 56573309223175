export default [
  "CEO",
  "CTO",
  "CMO",
  "CPO",
  "CFO",
  "COO",
  "Frontend developer",
  "Backend developer",
  "Fullstack developer",
  "Director",
  "Software engineer",
  "Product manager",
  "Product owner",
  "President",
  "VP of engineering",
  "VP of marketing",
  "UX designer",
  "Marketing manager",
  "Data scientist",
  "Marketing specialist",
  "VP of sales",
  "Sales manager",
  "Head of marketing",
  "Head of sales",
  "VP of growth",
  "Business developer",
  "VP of operations",
];
