import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip";

export default class PersonOrganizations extends React.Component {
  static propTypes = {
    person: PropTypes.object.isRequired,
  };

  organizationsRef = React.createRef();

  render() {
    const { organizations = [], id } = this.props.person;

    if (organizations.length < 2) {
      return false;
    }

    return (
      <div className="other-organizations-wrapper">
        <a href={`/people/${id}`} target="_blank">
          <div
            className="other-organizations-number"
            ref={this.organizationsRef}
          >
            {organizations.length}
          </div>
        </a>
        <Tooltip
          parent={this.organizationsRef.current}
          text="Other Organizations"
          showOnHover={true}
          position="top"
          customStyles={{
            right: "-20px",
          }}
        />
      </div>
    );
  }
}
