import React from "react";
import PropTypes from "prop-types";

import imageUrl from "../../../lib/imageUrl";

export default class PreviewInvestorAvatar extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
  };

  render() {
    const { investor: { image_url: avatar } = {} } = this.props;
    return (
      <div
        className="preview-organization--header-avatar"
        style={{ backgroundImage: `url(${imageUrl({ url: avatar })})` }}
      />
    );
  }
}
