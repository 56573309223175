export default {
  space: {
    exclusive: true,
  },
  people: {
    exclusive: true,
  },
  investment_type: {
    exclusive: true,
  },
  traffic: {
    exclusive: true,
  },
  timing: {
    exclusive: true,
  },
};
