import React from "react";
import Chip from "material-ui/Chip";
import Avatar from "material-ui/Avatar";
import FontIcon from "material-ui/FontIcon";
import NavigationCancel from "material-ui/svg-icons/navigation/cancel";
import IconButton from "material-ui/IconButton";

class FilterSummaryTag extends React.Component {
  render() {
    const getClassName = () => {
      switch (this.props.type) {
        case "location":
          return "place-icon";
        case "investor":
          return "money";
        case "money":
          return "money";
        case "query":
          return "search-icon-tag";
        case "state":
          return "map-signs-tag";
        case "stage":
          return "flag-tag";
        case "order_by":
          return "sliders-tag";
        case "calendar":
          return "calendar-tag";
        case "source":
          return "cloud-tag";
        case "tag":
          return "tag-icon";
        case "event":
          return "event-tag";
        case "cogs":
          return "cogs-tag";
        case "fire":
          return "fire-tag";
        case "number":
          return "hashtag";
        case "classification":
          return "classification-tag";
        case "hidespam":
          return "hide-spam-tag";
        case "investment-type":
          return "investment-type-icon";
        case "funding-stage":
          return "funding-stage-icon";
        case "name":
          return "search-icon-tag";
        case "terms":
          return "tag-icon";
        case "highlights":
          return "highlights-tag";
        case "person":
          return "person-tag";
        case "organization":
          return "organization-tag";
        default:
          return this.props.type;
      }
    };

    const isFiltersSet = this.props.type === "filterset";

    if (isFiltersSet) {
      return (
        <div className="filters-sets-tag">
          <div>
            <div> {this.props.text} </div>
            <IconButton
              onClick={
                !this.props.noDelete
                  ? () => {
                      this.props.handleDelete();
                    }
                  : null
              }
            >
              <NavigationCancel />
            </IconButton>
          </div>
        </div>
      );
    } else {
      return (
        <Chip
          onRequestDelete={
            !this.props.noDelete
              ? () => {
                  this.props.handleDelete();
                }
              : null
          }
          className="filter-tag"
        >
          <Avatar
            style={{ background: "black" }}
            icon={<FontIcon className={getClassName()} />}
          />
          <div className="filter-text">{this.props.text}</div>
        </Chip>
      );
    }
  }
}

export default FilterSummaryTag;
