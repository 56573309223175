import React from "react";
import PropTypes from "prop-types";

import INREACH_TEAM from "./../../../../../config/inreach_team";

export default class TimelineEventOrganizationEdit extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    people: PropTypes.array.isRequired,
  };

  buildLabel = (item = this.props.item) => {
    const { people } = this.props;
    const {
      organization_edit: {
        user_id: userId,
        updated_fields: updatedFields = [],
        updated_fields_and_values: updatedValues = {},
      } = {},
    } = item;
    const userName = (
      INREACH_TEAM.find((person) => person.email === userId) || {}
    ).name;
    if (updatedFields.includes("primary_contact_id")) {
      if (updatedValues.primary_contact_id && people.length) {
        const contactName = (
          people.find(
            (person) => person.id === updatedValues.primary_contact_id
          ) || {}
        ).name;
        if (contactName) {
          return (
            <span>
              <b>{userName}</b> selected <b>{contactName}</b> as{" "}
              <b>Primary Contact</b>
            </span>
          );
        }
      }
      return (
        <span>
          <b>{userName}</b> edited the <b>Primary Contact</b>
        </span>
      );
    }
    if (updatedFields.includes("is_complete")) {
      return (
        <span>
          <b>{userName}</b> marked the organization as <b>Complete</b>
        </span>
      );
    }
    if (
      !!updatedFields.find((updatedField) => updatedField.includes("email"))
    ) {
      const updatedField =
        updatedFields.find((updatedField) => updatedField.includes("email")) ||
        {};
      const contactId = updatedField.split(".")[1];
      const newEmail = updatedValues[updatedField];
      if (people.length) {
        const contactName = (
          people.find((person) => person.id === contactId) || {}
        ).name;
        return (
          <span>
            <b>{userName}</b> changed{" "}
            <b>
              {contactName}
              's
            </b>{" "}
            email to <b>{newEmail}</b>
          </span>
        );
      } else {
        return (
          <span>
            <b>{userName}</b> changed an email to <b>{newEmail}</b>
          </span>
        );
      }
    }
    return <span>Error: could not load the edited data.</span>;
  };

  render() {
    const label = this.buildLabel();
    return (
      <div className="timeline-event-inner-wrapper">
        <h5 className="timeline-event-label">{label}</h5>
      </div>
    );
  }
}
