import React from "react";
import PropTypes from "prop-types";

import Contact from "./Contact";

export default class ContactsBody extends React.Component {
  static propTypes = {
    contacts: PropTypes.array.isRequired,
    socialEngagedIds: PropTypes.array.isRequired,
    selectedPerson: PropTypes.number.isRequired,
  };

  buildClassName = (selectedPerson = this.props.selectedPerson) => {
    return "contacts-wrapper s" + selectedPerson;
  };

  isPrimaryContact = (primaryContactId, contactId) => {
    return primaryContactId ? primaryContactId === contactId : false;
  };

  isSocialEngaged = (socialEngagedIds, personId) => {
    return socialEngagedIds.includes(personId);
  };

  render() {
    const { contacts, primaryContactId, selectedPerson, socialEngagedIds } =
      this.props;
    return (
      <div className="contacts-body">
        <Choose>
          <When condition={contacts.length > 0}>
            <div className={this.buildClassName(selectedPerson)}>
              {contacts.map((person, i) => {
                return (
                  <Contact
                    key={person.id}
                    handleMakeContactChecklistChange={
                      this.props.handleMakeContactChecklistChange
                    }
                    person={person}
                    selected={selectedPerson === i}
                    isSocialEngaged={this.isSocialEngaged(
                      socialEngagedIds,
                      person.id
                    )}
                    isPrimary={this.isPrimaryContact(
                      primaryContactId,
                      person.id
                    )}
                    {...this.props}
                  />
                );
              })}
            </div>
          </When>
          <Otherwise>No contacts</Otherwise>
        </Choose>
      </div>
    );
  }
}
