import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";
import Tooltip from "../../Tooltip";
import PortfolioLink from "../investor_info/PortfolioLink";
import AccountBalanceIcon from "material-ui/svg-icons/editor/monetization-on";
import _ from "underscore";

class FundingRoundsPreview extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
  };
  state = {
    showTooltip: false,
  };

  hasError = () => {
    return true;
  };

  render() {
    const {
      investor: {
        id = "",
        funding_rounds = [],
        aggregations,
        aggregations: { early_stage = 0 } = {},
      } = {},
    } = this.props;
    const { showTooltip } = this.state;
    return (
      <div
        className="data-wrapper funding-rounds compressed"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: false });
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: true });
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text="Funding Rounds"
        />
        <div className="data-icon">
          <AccountBalanceIcon className="data-icon-svg" />
        </div>
        <Choose>
          <When condition={_.isEmpty(aggregations) || !funding_rounds.length}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="preview-box">
              <div className="invesor-profile-row">
                <div className="data-content text invesor-profile-div">
                  Total Rounds:
                </div>
                <div className="spacer" />
                <PortfolioLink
                  investorId={id}
                  text={funding_rounds.length}
                  filters={{ investor_ids: id }}
                />
              </div>
              <div className="invesor-profile-row">
                <div className="data-content text invesor-profile-div">
                  Early Stage:
                </div>
                <div className="spacer" />
                {early_stage}
              </div>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(FundingRoundsPreview);
