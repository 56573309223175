import React from "react";
import PropTypes from "prop-types";

import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";

import SocialLink from "../../SocialLink";

export default class ScorecardModalHeader extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    saving: PropTypes.bool.isRequired,
    hasConductedCall: PropTypes.bool.isRequired,
    newCall: PropTypes.func.isRequired,
    hasMadeChanges: PropTypes.bool.isRequired,
    latestCallNoShow: PropTypes.bool.isRequired,
    showNoShowButton: PropTypes.bool.isRequired,
    toggleConfirmNoShowDialog: PropTypes.func.isRequired,
    reloadOrganization: PropTypes.func.isRequired,
    filesFolder: PropTypes.string,
    calls: PropTypes.array.isRequired,
    scorecardModalEditIndex: PropTypes.string.isRequired,
  };

  openOrganizationTab = (tab) => {
    window.open(
      window.location.href.replace("/scorecard", `/${tab}`),
      "_blank"
    );
  };

  render() {
    const {
      organization: { homepage_url = "", id = "" } = {},
      title,
      close,
      edit,
      save,
      type,
      saving,
      hasConductedCall,
      newCall,
      hasMadeChanges,
      latestCallNoShow,
      showNoShowButton,
      toggleConfirmNoShowDialog,
      reloadOrganization,
      filesFolder,
      calls,
    } = this.props;
    return (
      <div className="scorecard-modal-header">
        <div className="scorecard-modal-title">{title}</div>
        <FlatButton
          className="view-profile"
          label="PROFILE"
          style={{
            marginRight: "12px",
          }}
          onClick={() => this.openOrganizationTab("profile")}
        />
        <FlatButton
          className="view-workflow"
          label="WORKFLOW"
          onClick={() => this.openOrganizationTab("workflow")}
        />
        <If condition={homepage_url !== ""}>
          <SocialLink source="website" link={homepage_url} />
        </If>
        <If condition={filesFolder !== ""}>
          <SocialLink
            source="folder"
            link={`https://drive.google.com/drive/u/1/folders/${filesFolder}`}
          />
        </If>
        <div className="spacer" />
        <Choose>
          <When condition={saving}>
            <RaisedButton
              className="scorecard-button scorecard-modal-primary-btn saving-scorecard"
              label="SAVING"
              disabled={true}
              backgroundColor="#7f7f7f"
              labelColor="#ffffff"
            />
          </When>
          <Otherwise>
            <Choose>
              <When condition={type === "view"}>
                <Choose>
                  <When condition={hasConductedCall && !latestCallNoShow}>
                    <RaisedButton
                      className="scorecard-button edit-scorecard"
                      label="EDIT MOST RECENT"
                      backgroundColor="#ffffff"
                      labelColor="#000000"
                      style={{
                        marginRight: "12px",
                      }}
                      onClick={() => edit(calls[0].id)}
                    />
                  </When>
                  <Otherwise>
                    <RaisedButton
                      className="scorecard-button new-call"
                      label="NEW CALL"
                      backgroundColor="#ffffff"
                      labelColor="#000000"
                      style={{
                        marginRight: "12px",
                      }}
                      onClick={() => newCall("new")}
                    />
                  </Otherwise>
                </Choose>
              </When>
              <Otherwise>
                <If condition={showNoShowButton}>
                  <RaisedButton
                    className="scorecard-button no-show"
                    label="NO SHOW"
                    backgroundColor="#ffffff"
                    labelColor="#000000"
                    style={{
                      marginRight: "12px",
                    }}
                    onClick={() => toggleConfirmNoShowDialog()}
                  />
                </If>
                <If condition={hasMadeChanges}>
                  <RaisedButton
                    className="scorecard-button scorecard-modal-primary-btn save-scorecard"
                    label="SAVE"
                    backgroundColor="#5dab49"
                    labelColor="#ffffff"
                    onClick={() => {
                      save();
                      reloadOrganization(id);
                    }}
                  />
                </If>
              </Otherwise>
            </Choose>
            <If condition={!hasMadeChanges}>
              <RaisedButton
                className="scorecard-button scorecard-modal-primary-btn close-scorecard"
                label="CLOSE"
                backgroundColor="#f44336"
                labelColor="#ffffff"
                onClick={() => {
                  type === "view" ? close() : save();
                }}
              />
            </If>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
