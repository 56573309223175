import React from "react";
import { withRouter } from "react-router-v4";

import RaisedButton from "material-ui/RaisedButton";

import Loading from "../../loading/Loading";
import Card from "../../card/Card";
import CardBody from "../../card/CardBody";
import EditOrganizationHeader from "./EditOrganizationHeader";
import EditBasicInfo from "./EditBasicInfo";
import EditOrganizationDescription from "./EditOrganizationDescription";
import EditOrganizationWebsite from "./EditOrganizationWebsite";
import EditOrganizationLocations from "./EditOrganizationLocations";
import EditOrganizationFoundedOn from "./EditOrganizationFoundedOn";
import EditOrganizationTotalFunding from "./EditOrganizationTotalFunding";
import EditOrganizationPeople from "./EditOrganizationPeople";

import OrganizationsNavigatorFetcher from "../OrganizationsNavigatorFetcher";
import OrganizationsNavigator from "../OrganizationsNavigator";

import OrganizationStore from "../../../storage/OrganizationStore";
import organizationsStore from "../../../storage/OrganizationsStore";
import PersonStore from "../../../storage/PersonStore";
import peopleStore from "../../../storage/PeopleStore";

import requiredOrganizationFields from "../../../content/requiredOrganizationFields";

import _ from "underscore";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import CreateOrganizationHelper from "../../../lib/CreateOrganizationHelper";
import DialogWrapper from "../../components/DialogWrapper";
import SyncWithLinkedIn from "./SyncWithLinkedIn";

class EditIncompleteOrganization extends React.Component {
  createOrganizationHelper = new CreateOrganizationHelper();

  state = {
    loading: true,
    saving: false,
    organization: {},
    people: [],
    client: "",
    error: null,
    errors: {},
  };

  organizationStore = {};
  personStore = {};
  organizationsNavigatorFetcher = {};

  componentWillMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.id !== this.props.params.id) {
      this.initialize();
    }
  }

  componentWillUnmount() {
    document.title = "InReach";
  }

  initialize = () => {
    const { location: { state: { organizationsStoreParams = {} } = {} } = {} } =
      this.props;
    organizationsStore.initialize(organizationsStoreParams);
    this.organizationsNavigatorFetcher = new OrganizationsNavigatorFetcher(
      organizationsStore
    );
    this.organizationStore = new OrganizationStore(this.props.params.id);
    this.organizationStore
      .getModel()
      .then((organization) => {
        document.title = organization.name;
        this.personStore = new PersonStore(organization.person_ids);
        this.personStore
          .getModels(organization.id)
          .then((people) => {
            this.setState({
              organization: organization,
              people: people,
              missingFields: this.getMissingFields(organization, people, true),
              loading: false,
            });
          })
          .catch(this.handleError);
      })
      .catch(this.handleError);
  };

  handleError = (error) => {
    console.error("Error", error);
    this.setState({ error: error.message, saving: false });
  };

  getOrganizationStatuses = () => {
    return {
      complete: this.getOrganizationIsComplete(),
      spam: this.getOrganizationIsSpam(),
    };
  };

  handleCancel = () => {
    const { router, location: { state: { returnUrl = "/" } = {} } = {} } =
      this.props;
    router.push(returnUrl);
  };

  getOrganizationIsComplete = () => this.getMissingFields().length === 0;

  getOrganizationIsSpam = () => {
    const { organization: { locations = [] } = {} } = this.state;
    if (locations.length > 0) {
      return (
        locations.filter(({ continent = "" }) => {
          return continent.toLowerCase() === "europe";
        }).length === 0
      );
    }
    return false;
  };

  getMissingFields = (
    organization = this.state.organization,
    people = this.state.people,
    firstLoad = false
  ) => {
    const requiredFields = {
      ...requiredOrganizationFields.reduce((fields, field) => {
        fields[field] = this.getDefaultValue(field);
        return fields;
      }, {}),
      ..._.pick(organization, requiredOrganizationFields),
    };
    return Object.entries(requiredFields).reduce(
      (missingArray, [field, value]) => {
        if (
          this.doesFieldRequireData(
            field,
            value,
            organization,
            people,
            firstLoad
          )
        ) {
          missingArray.push(field);
        }
        return missingArray;
      },
      []
    );
  };

  getDefaultValue = (field) => {
    switch (field) {
      case "founded_on":
      case "total_funding_usd":
        return 0;
      case "person_ids":
      case "locations":
        return [];
      case "description":
      case "homepage_url":
      case "linkedin":
      default:
        return "";
    }
  };

  doesFieldRequireData = (
    field,
    value,
    organization = this.state.organization,
    people = this.state.people,
    firstLoad = false
  ) => {
    switch (field) {
      case "description":
        // Description must contain one or more sentences (125 characters on the backend)
        return !value || value.length < 125;
      case "locations":
        // Locations must contain at least one location with the continent, country and city selected.
        // It must also have HQ selected if there are multiple locations
        return !this.areLocationRequirementsMet(value);
      case "founded_on":
        // Founded on must be defined and must be different to the created at date
        const { created_at: createdAt = 0 } = organization;
        return (
          !value ||
          new Date(value).toDateString() === new Date(createdAt).toDateString()
        );
      case "person_ids":
        // People must have at least one person with their role as founder or
        // co founder and that person must have a linkedin url
        return !this.arePeopleRequirementsMet(organization, people);
      case "homepage_url":
        return (
          value === "" ||
          !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?((\/\w{2})|(\/))?(\/)?$/.test(
            value
          )
        );
      case "linkedin":
        return value === "" || !this.createOrganizationHelper.isUrl(value);
      case "total_funding_usd":
        return firstLoad ? !value || value === "" || value === 0 : value === "";
      default:
        return !value;
    }
  };

  areLocationRequirementsMet = (locations = []) =>
    locations.filter((location) => {
      const { country = "", continent = "", is_hq: isHQ = false } = location;
      if (continent !== "" && country !== "") {
        return (locations.length > 1 && isHQ) || locations.length === 1;
      }
      return false;
    }).length > 0;

  arePeopleRequirementsMet = (organization = {}, people = []) => {
    const { primary_contact_id: primaryContact = "" } = organization;
    return (
      people.filter((person) => {
        const { name = "", role = "", linkedin_url: linkedIn = "" } = person;
        return (
          name !== "" &&
          role !== "" &&
          role.toLowerCase().indexOf("founder") !== -1 &&
          /https:\/\/(www\.)?linkedin\.com\/in\/[a-zA-ZÀ-ÖØ-öŠø-ÿ0-9-_%]+\/?(\?.*)?$/.test(
            linkedIn
          ) &&
          primaryContact !== ""
        );
      }).length > 0
    );
  };

  updateOrganizationFields = (data) => {
    const { organization = {} } = this.state;
    this.setState({
      organization: Object.assign(
        {},
        organization,
        data.reduce((changes, obj) => {
          changes[obj.field] = obj.value;
          return changes;
        }, {})
      ),
    });
  };

  saveOrganization = (additionalField) => {
    const { organization = {}, missingFields = [] } = this.state;
    const updateData = this.getUpdateData(organization, missingFields);
    if (additionalField) {
      const { field = "", value } = additionalField;
      updateData.push({
        op: "replace",
        path: `/${field}`,
        value,
      });
    }
    this.setState({
      saving: true,
    });
    this.organizationStore
      .updateModel(updateData)
      .then((organization) => {
        this.setState({
          saving: false,
        });
        this.goToNextOrganization();
      })
      .catch(this.handleError);
  };

  savePerson = (person) => {
    const { people = [] } = this.state;
    new PersonStore(person.id)
      .updateModel(
        person,
        this.getUpdateData(person, ["name", "role", "linkedin_url"])
      )
      .then(({ body: newPerson }) => {
        this.setState({
          people: people.map((person) => {
            if (person.id === newPerson.id) {
              return newPerson;
            }
            return person;
          }),
        });
      });
  };

  getUpdateData = (source, fields) => {
    const { organization = {}, people = [] } = this.state;
    return fields.reduce(
      (updateData, key) => {
        const { [key]: value = "" } = source;
        if (
          !this.doesFieldRequireData(key, value, organization, people, false)
        ) {
          if (key === "person_ids") {
            const {
              num_employees_min: minEmployees = 0,
              num_employees_max: maxEmployees = 0,
              primary_contact_id: primaryContact = "",
            } = source;
            updateData.push(
              {
                op: "replace",
                path: "/num_employees_min",
                value: minEmployees,
              },
              {
                op: "replace",
                path: "/num_employees_max",
                value: maxEmployees,
              },
              {
                op: "replace",
                path: "/primary_contact_id",
                value: primaryContact,
              },
              {
                op: "replace",
                path: "/person_ids",
                value: value,
              }
            );
          } else {
            updateData.push({
              op: "replace",
              path: `/${key}`,
              value,
            });
          }
        }
        return updateData;
      },
      [
        {
          op: "replace",
          path: "/name",
          value: organization.name,
        },
      ]
    );
  };

  saveNewPerson = (person) => {
    const { organization = {}, people = [] } = this.state;
    const { id = "", person_ids: personIds = [] } = organization;
    person.organizations = [id];
    peopleStore.create(person).then((newPerson) => {
      personIds.push(newPerson.id);
      organization.person_ids = personIds;
      this.setState({
        organization,
        people: [...people, ...[newPerson]],
      });
    });
  };

  deletePerson = (deleteId) => {
    const {
      organization: { primary_contact_id: primaryContact = "" },
      people = [],
    } = this.state;
    peopleStore.delete(deleteId).then(() => {
      this.setState({
        people: people.filter(({ id }) => id !== deleteId),
      });
      if (primaryContact === deleteId) {
        this.updateOrganizationFields([
          {
            field: "primary_contact_id",
            value: "",
          },
        ]);
      }
    });
  };

  handleSaveAndContinue = () => {
    this.saveOrganization();
  };

  handleIsComplete = () => {
    this.saveOrganization({ field: "is_complete", value: true });
  };

  handleInsufficientInfo = () => {
    this.saveOrganization({ field: "insufficient_info", value: true });
  };

  handleAcquired = () => {
    this.saveOrganization({ field: "funding_stage", value: "acquired" });
  };

  handleNever = () => {
    this.setState({ saving: true });
    this.organizationStore
      .setDecision({
        decision: {
          state: "never",
        },
      })
      .then(() => {
        this.setState({ saving: false });
        this.goToNextOrganization();
      })
      .catch(this.handleError);
  };

  goToNextOrganization = () => {
    const { router, location = {} } = this.props;
    const { organization: { id: orgId = "" } = {} } = this.state;
    const { next: { id: nextId = "" } = {} } =
      this.organizationsNavigatorFetcher.getNextAndPrevious(orgId);
    if (nextId !== "") {
      router.push({
        ...location,
        ...{
          pathname: `${location.pathname.replace(orgId, nextId)}`,
        },
      });
    } else {
      router.push({
        pathname: "/",
      });
    }
    window.scrollTo(0, 0);
  };

  getFieldError = (key) => {
    const { organization } = this.state;
    if (organization[key] === undefined) {
      return "Required";
    } else if (organization[key] !== "") {
      return this.state.errors[key];
    }
    return "";
  };

  render() {
    const {
      loading,
      saving,
      organization = {},
      people = [],
      missingFields = [],
      error,
    } = this.state;
    if (loading) {
      return <Loading />;
    }
    const {
      id,
      created_at: createdAt = 0,
      description = "",
      founded_on: founded = 0,
      homepage_url: website = "",
      locations = [],
      name = "",
      num_employees_min: minEmployees = 0,
      primary_contact_id: primaryContact = "",
      total_funding_usd: totalFunding = 0,
      source_refs: { CORESIGNAL_DB: coresignal = [] } = {},
    } = organization;

    const { complete = false, spam = false } = this.getOrganizationStatuses();

    return (
      <div className="edit-view">
        <OrganizationsNavigator
          organizationsNavigatorFetcher={this.organizationsNavigatorFetcher}
          currentId={id}
        />
        <EditOrganizationHeader
          cancel={this.handleCancel}
          title={`Edit Incomplete: ${name}`}
          source="edit-incomplete"
        />
        <div className="edit-body">
          <Card>
            <CardBody>
              <div className="edit-section">
                <EditBasicInfo
                  source="incomplete"
                  data={organization}
                  updateFields={this.updateOrganizationFields}
                />
                <Choose>
                  <When
                    condition={
                      !missingFields.includes("linkedin") &&
                      coresignal.length < 1
                    }
                  >
                    <SyncWithLinkedIn
                      organization={organization}
                      linkedin={organization.linkedin}
                      onSyncComplete={() => window.location.reload()}
                    />
                  </When>
                </Choose>
              </div>
              {missingFields.map((field) => (
                <div className="edit-section" key={field}>
                  <Choose>
                    <When condition={field === "description"}>
                      <EditOrganizationDescription
                        description={description}
                        updateOrganizationFields={this.updateOrganizationFields}
                      />
                    </When>
                    <When condition={field === "homepage_url"}>
                      <EditOrganizationWebsite
                        website={website}
                        updateOrganizationFields={this.updateOrganizationFields}
                      />
                    </When>
                    <When condition={field === "locations"}>
                      <EditOrganizationLocations
                        locations={locations}
                        updateOrganizationFields={this.updateOrganizationFields}
                      />
                    </When>
                    <When condition={field === "founded_on"}>
                      <EditOrganizationFoundedOn
                        founded={founded}
                        createdAt={createdAt}
                        updateOrganizationFields={this.updateOrganizationFields}
                      />
                    </When>
                    <When condition={field === "total_funding_usd"}>
                      <EditOrganizationTotalFunding
                        totalFunding={totalFunding}
                        updateOrganizationFields={this.updateOrganizationFields}
                      />
                    </When>
                    <When condition={field === "person_ids"}>
                      <EditOrganizationPeople
                        people={people}
                        minEmployees={minEmployees}
                        primaryContact={primaryContact}
                        updateOrganizationFields={this.updateOrganizationFields}
                        savePerson={this.savePerson}
                        saveNewPerson={this.saveNewPerson}
                        deletePerson={this.deletePerson}
                        organizationId={id}
                      />
                    </When>
                    <When condition={field === "linkedin"}>
                      <div className="edit-section">
                        {["linkedin"].map((key) => (
                          <div className="edit-section" key={key}>
                            <div className="edit-section-header">
                              {key.toUpperCase()}
                            </div>
                            <TextField
                              fullWidth={true}
                              className={`edit-${key}`}
                              name={`edit-${key}`}
                              value={organization[key] || ""}
                              errorText={this.getFieldError(key)}
                              onChange={(event) => {
                                const errors = Object.assign(
                                  {},
                                  this.state.errors
                                );

                                errors[key] =
                                  this.createOrganizationHelper.validateField(
                                    null,
                                    event.target.value,
                                    "URL"
                                  );

                                this.setState({ errors });

                                this.updateOrganizationFields([
                                  { field: key, value: event.target.value },
                                ]);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      <SyncWithLinkedIn
                        organization={organization}
                        linkedin={organization.linkedin}
                        onSyncComplete={() => window.location.reload()}
                      />
                    </When>
                  </Choose>
                </div>
              ))}
              <Choose>
                <When condition={missingFields.length > 0}>
                  <Choose>
                    <When condition={saving}>
                      <Loading small={true} />
                    </When>
                    <Otherwise>
                      <div className="edit-section">
                        <div className="edit-organization-outcome-buttons">
                          <RaisedButton
                            className="edit-organization-outcome-button"
                            label="COMPLETE"
                            backgroundColor="#5dab49"
                            labelColor="#ffffff"
                            onClick={this.handleIsComplete}
                            disabled={!complete || spam}
                          />
                          <RaisedButton
                            className="edit-organization-outcome-button"
                            label="SAVE & CONTINUE"
                            backgroundColor="#5dab49"
                            labelColor="#ffffff"
                            onClick={this.handleSaveAndContinue}
                            disabled={!spam}
                          />
                          <RaisedButton
                            className="edit-organization-outcome-button"
                            label="INSUFFICIENT INFO"
                            backgroundColor="#ffffff"
                            labelColor="#444444"
                            onClick={this.handleInsufficientInfo}
                          />
                          <RaisedButton
                            className="edit-organization-outcome-button"
                            label="ACQUIRED"
                            backgroundColor="#ffffff"
                            labelColor="#444444"
                            onClick={this.handleAcquired}
                          />
                          <RaisedButton
                            className="edit-organization-outcome-button"
                            label="NEVER"
                            backgroundColor="#f44336"
                            labelColor="#ffffff"
                            onClick={this.handleNever}
                          />
                        </div>
                      </div>
                    </Otherwise>
                  </Choose>
                </When>
                <Otherwise>
                  <div className="edit-section">
                    <div className="organization-complete-message">
                      This organization is complete
                    </div>
                  </div>
                </Otherwise>
              </Choose>
            </CardBody>
          </Card>
        </div>
        <DialogWrapper
          title="Error"
          actions={[
            <FlatButton
              label="Close"
              primary={true}
              onClick={() => this.setState({ error: null })}
            />,
          ]}
          modal={false}
          open={!!error}
          onRequestClose={() => this.setState({ error: null })}
        >
          {error}
        </DialogWrapper>
      </div>
    );
  }
}

export default withRouter(EditIncompleteOrganization);
