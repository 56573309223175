export default {
  continents: [
    "Europe",
    "North America",
    "South America",
    "Asia",
    "Africa",
    "Antarctica",
    "Oceania",
  ],
  countries: {
    Asia: [
      "Afghanistan, Islamic Republic of",
      "Armenia",
      "Azerbaijan",
      "Bahrain",
      "Bangladesh",
      "Bhutan",
      "British Indian Ocean Territory (Chagos Archipelago)",
      "Brunei Darussalam",
      "Cambodia",
      "China",
      "Christmas Island",
      "Cocos (Keeling) Islands",
      "Cyprus",
      "Georgia",
      "Hong Kong",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Israel",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Korea",
      "Kuwait",
      "Kyrgyz Republic",
      "Lao Peoples Democratic Republic",
      "Lebanon",
      "Macao, Special Administrative Region of China",
      "Malaysia",
      "Maldives",
      "Mongolia",
      "Myanmar",
      "Nepal",
      "Oman",
      "Pakistan",
      "Palestinian Territory",
      "Philippines",
      "Qatar",
      "Saudi Arabia",
      "Singapore",
      "Sri Lanka",
      "Syrian Arab Republic",
      "Taiwan",
      "Tajikistan",
      "Thailand",
      "Timor-Leste",
      "Turkey",
      "Turkmenistan",
      "United Arab Emirates",
      "Uzbekistan",
      "Vietnam",
      "Yemen",
    ],
    Europe: [
      "Albania",
      "Andorra",
      "Austria",
      "Belarus",
      "Belgium",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Croatia",
      "Czech Republic",
      "Denmark",
      "Estonia",
      "Faroe Islands",
      "Finland",
      "France",
      "Germany",
      "Gibraltar",
      "Greece",
      "Guernsey",
      "Holy See (Vatican City State)",
      "Hungary",
      "Iceland",
      "Ireland",
      "Isle of Man",
      "Italy",
      "Jersey",
      "Kosovo",
      "Latvia",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macedonia",
      "Malta",
      "Moldova",
      "Monaco",
      "Montenegro",
      "Netherlands",
      "Norway",
      "Poland",
      "Portugal",
      "Romania",
      "Russian Federation",
      "San Marino",
      "Serbia",
      "Slovakia (Slovak Republic)",
      "Slovenia",
      "Spain",
      "Svalbard & Jan Mayen Islands",
      "Sweden",
      "Switzerland",
      "Ukraine",
      "United Kingdom",
      "Ã…land Islands",
    ],
    Africa: [
      "Algeria",
      "Angola",
      "Benin",
      "Botswana",
      "Burkina Faso",
      "Burundi",
      "Cameroon",
      "Cape Verde",
      "Central African Republic",
      "Chad",
      "Comoros",
      "Congo",
      "Cote d Ivoire",
      "Djibouti",
      "Egypt",
      "Equatorial Guinea",
      "Eritrea",
      "Ethiopia",
      "Gabon",
      "Gambia",
      "Ghana",
      "Guinea",
      "Guinea-Bissau",
      "Kenya",
      "Lesotho",
      "Liberia",
      "Libyan Arab Jamahiriya",
      "Madagascar",
      "Malawi",
      "Mali",
      "Mauritania",
      "Mauritius",
      "Mayotte",
      "Morocco",
      "Mozambique",
      "Namibia",
      "Niger",
      "Nigeria",
      "Reunion",
      "Rwanda",
      "Saint Helena",
      "Sao Tome and Principe",
      "Senegal",
      "Seychelles",
      "Sierra Leone",
      "Somalia",
      "South Africa",
      "Sudan",
      "Swaziland",
      "Tanzania",
      "Togo",
      "Tunisia",
      "Uganda",
      "Western Sahara",
      "Zambia",
      "Zimbabwe",
    ],
    Oceania: [
      "American Samoa",
      "Australia",
      "Cook Islands",
      "Fiji",
      "French Polynesia",
      "Guam",
      "Kiribati",
      "Marshall Islands",
      "Micronesia",
      "Nauru",
      "New Caledonia",
      "New Zealand",
      "Niue",
      "Norfolk Island",
      "Northern Mariana Islands",
      "Palau",
      "Papua New Guinea",
      "Pitcairn Islands",
      "Samoa",
      "Solomon Islands",
      "Tokelau",
      "Tonga",
      "Tuvalu",
      "United States Minor Outlying Islands",
      "Vanuatu",
      "Wallis and Futuna",
    ],
    "North America": [
      "Anguilla",
      "Antigua and Barbuda",
      "Aruba",
      "Bahamas",
      "Barbados",
      "Belize",
      "Bermuda",
      "British Virgin Islands",
      "Canada",
      "Cayman Islands",
      "Costa Rica",
      "Cuba",
      "Dominica",
      "Dominican Republic",
      "El Salvador",
      "Greenland",
      "Grenada",
      "Guadeloupe",
      "Guatemala",
      "Haiti",
      "Honduras",
      "Jamaica",
      "Martinique",
      "Mexico",
      "Montserrat",
      "Netherlands Antilles",
      "Nicaragua",
      "Panama",
      "Puerto Rico",
      "Saint Barthelemy",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Martin",
      "Saint Pierre and Miquelon",
      "Saint Vincent and the Grenadines",
      "Trinidad and Tobago",
      "Turks and Caicos Islands",
      "United States Virgin Islands",
      "United States of America",
    ],
    Antarctica: [
      "Antarctica",
      "Bouvet Island (Bouvetoya)",
      "French Southern Territories",
      "Heard Island and McDonald Islands",
      "South Georgia and the South Sandwich Islands",
    ],
    "South America": [
      "Argentina",
      "Bolivia",
      "Brazil",
      "Chile",
      "Colombia",
      "Ecuador",
      "Falkland Islands (Malvinas)",
      "French Guiana",
      "Guyana",
      "Paraguay",
      "Peru",
      "Suriname",
      "Uruguay",
      "Venezuela",
    ],
  },
};
