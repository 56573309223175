import React from "react";
import { Link, withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import _ from "underscore";

import BasicInfo from "./BasicInfo";
import Analytics from "../../Analytics";
import Insights from "../../Insights";
import Price from "./Price";
import BusinessModel from "./BusinessModel";
import Released from "./Released";
import ViewOnStore from "./ViewOnStore";
import Category from "./Category";
import Description from "./Description";
import Images from "./Images";
import NewTag from "../../NewTag";
import Ratings from "../../Ratings";
import RaisedButton from "material-ui/RaisedButton";

class AppProduct extends React.Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    signalsLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool,
  };

  buildProduct = () => {
    const {
      product: {
        cover: image,
        name,
        rating = 0,
        rating_count: ratingCount = 0,
        analytics,
        revenue,
        ranking: {
          matched_highlights: highlights = [],
          matched_risks: risks = [],
          matched_red_flags: redFlags = [],
        } = {},
        price = 0,
        released_on: released = 0,
        id,
        store,
        main_category: category = "",
        description = "",
        screenshot_urls: images = [],
      } = {},
    } = this.props;
    return {
      basicInfo: {
        image,
        name,
        store,
      },
      analytics,
      revenue,
      ratings: {
        rating,
        ratingCount,
      },
      highlights,
      risks,
      redFlags,
      price,
      businessModel: "",
      released,
      linkDetails: {
        store,
        id,
      },
      category,
      description,
      images,
    };
  };

  hasChanged = () => {
    const { product = {}, useSnapshot = false, previous = false } = this.props;
    const { snapshot: snapshotProduct = {} } = product;
    if (
      useSnapshot &&
      !previous &&
      Object.keys(snapshotProduct).filter(
        (key) =>
          ![
            "analytics",
            "businessModel",
            "decisionCreatedAt",
            "revenue",
          ].includes(key)
      ).length === 0
    ) {
      return !_.isEqual(
        this.getAppProductPropertiesUsed(product),
        this.getAppProductPropertiesUsed(snapshotProduct)
      );
    } else {
      return false;
    }
  };

  getAppProductPropertiesUsed = (product) =>
    _.pick(
      product,
      "cover",
      "name",
      "rating",
      "rating_count",
      "ranking",
      "price",
      "category",
      "description",
      "screenshot_urls"
    );

  getLink = ({ store, id }) => {
    switch (store) {
      case "IOS":
        return `https://apps.apple.com/gb/app/id${id}`;
      case "PLAY":
        return `https://play.google.com/store/apps/details?id=${id}&hl=en_GB&showAllReviews=true`;
    }
  };

  filterMlUpdates = () => {
    const { mlUpdates, product: { id = "", store = "" } = {} } = this.props;
    const mlUpdatesFiltered = mlUpdates
      .filter(
        (object) =>
          object.name.includes("product") &&
          object.raw === id &&
          object.score > 0
      )
      .map((update) => {
        if (store === "PLAY" && update.notes) {
          return update.notes[0].replace("iOs app", "Android app");
        } else {
          return update.notes ? update.notes[0] : update.name;
        }
      });
    return mlUpdatesFiltered;
  };

  render() {
    const {
      signalsLoading,
      product: { snapshot = {} } = {},
      useSnapshot = false,
      organization,
      organizationDecision,
    } = this.props;
    const {
      basicInfo,
      analytics,
      revenue,
      ratings,
      highlights,
      risks,
      redFlags,
      price,
      businessModel,
      released,
      linkDetails,
      category,
      description,
      images,
    } = this.buildProduct();
    const hasChanged = this.hasChanged();
    return (
      <div className="product app-product">
        <If condition={hasChanged}>
          <NewTag source="app-product" snapshot={snapshot} />
        </If>
        <div className="grid-container product-grid-container app-grid-container">
          <div className="product-grid-item basic-info-grid-item">
            <BasicInfo info={basicInfo} type="app" />
          </div>
          <div className="product-grid-item insights-grid-item">
            <Insights
              highlights={highlights}
              risks={risks}
              redFlags={redFlags}
              mlUpdates={this.filterMlUpdates()}
            />
          </div>
          <div className="product-grid-item performance-grid-item">
            <div className="grid-container ratings-grid-container">
              <div className="ratings-grid-item ratings-summary-grid-item">
                <Ratings ratings={ratings} productType="app" showTitle={true} />
              </div>
              <div className="ratings-grid-item store-button-grid-item">
                <Link target="_blank" to={this.getLink(linkDetails)}>
                  <RaisedButton
                    className="view-ratings-button"
                    label="See Full Ratings"
                    backgroundColor="#7f7f7f"
                    labelColor="#ffffff"
                  />
                </Link>
              </div>
            </div>
            <Analytics
              productType="app"
              graphType="full"
              analytics={analytics}
              signalsLoading={signalsLoading}
              snapshot={snapshot}
              useSnapshot={useSnapshot}
              title="Weekly App Downloads"
              organization={organization}
              organizationDecision={organizationDecision}
              showSubTitle={false}
            />
            <Analytics
              productType="app"
              graphType="half"
              analytics={revenue}
              signalsLoading={signalsLoading}
              snapshot={snapshot}
              useSnapshot={useSnapshot}
              title="Weekly App Revenue"
              organization={organization}
              organizationDecision={organizationDecision}
              showSubTitle={false}
            />
          </div>
          <div className="product-grid-item other-info-grid-item">
            <div className="other-info">
              <div className="grid-container app-other-info-grid-container">
                <div className="app-other-info-grid-item border1" />
                <div className="app-other-info-grid-item price-grid-item">
                  <Price price={price} />
                </div>
                <div className="app-other-info-grid-item category-grid-item">
                  <Category category={category} />
                </div>
                <div className="app-other-info-grid-item released-grid-item">
                  <Released released={released} />
                </div>
                <div className="app-other-info-grid-item description-grid-item">
                  <Description description={description} />
                </div>
                <div className="app-other-info-grid-item images-grid-item">
                  <Images images={images} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AppProduct);
