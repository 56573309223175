import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link, withRouter } from "react-router-v4";
import _ from "underscore";

import { ListItem } from "material-ui/List";

class PersonMenuItem extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    active: false,
  };

  render() {
    const { active, name, email, icon, onClick } = this.props;

    const classNames = cx("inbox-nav-item", { "inbox-item-active": active });

    const inactiveStyle = {
      borderLeft: "0px solid transparent",
      paddingLeft: "6px",
    };

    const activeStyle = {
      color: "#5dab49",
      borderLeft: "6px solid #5dab49",
    };

    const { location } = this.props;

    const to = {
      ...location,
      query:
        email === ""
          ? _.omit(location.query, "user_inbox")
          : { ...location.query, user_inbox: email },
    };

    return (
      <ListItem
        containerElement={<Link className={classNames} to={to} />}
        style={active ? activeStyle : inactiveStyle}
        leftIcon={icon}
        primaryText={name}
        onClick={onClick}
      />
    );
  }
}

export default withRouter(PersonMenuItem);
