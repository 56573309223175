import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import ToggleStar from "material-ui/svg-icons/toggle/star";

class CustomRankIcon extends React.Component {
  static propTypes = {
    rank: PropTypes.string.isRequired,
  };

  render() {
    const { rank } = this.props;
    return (
      <div className={`custom-rank-icon ${rank}`}>
        <ToggleStar className="star star-1" />
        <ToggleStar className="star star-2" />
        <ToggleStar className="star star-3" />
      </div>
    );
  }
}

export default withRouter(CustomRankIcon);
