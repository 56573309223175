import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";

import moment from "moment";
import { campaignConfigs } from "../../../../../config/campaigns";
import TimelineEventActions from "./TimelineEventActions";

export default class TimelineEventSequenceState extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  state = {};

  renderLabel = (item = this.props.item) => {
    const {
      person: { name: personName = "Unknown" } = {},
      sequence_state: {
        created_at: createdAt = new Date().getTime(),
        creator: { name: creatorName = "Unknown" } = {},
        sequence: { name: sequenceName = "Unknown" } = {},
      } = {},
    } = item;
    return (
      <span>
        <b>{sequenceName}</b> sent to <b>{personName}</b>
      </span>
    );
  };

  render() {
    return (
      <div className="timeline-event-inner-wrapper">
        <h5 className="timeline-event-label">{this.renderLabel()}</h5>
      </div>
    );
  }
}
