import React from "react";
import PropTypes from "prop-types";
import { ListItem } from "material-ui/List";
import PersonExperience from "./PersonExperience";

export default class PersonExperiences extends React.Component {
  static propTypes = {
    experiences: PropTypes.array.isRequired,
    personId: PropTypes.string.isRequired,
    highlights: PropTypes.object.isRequired,
  };

  render() {
    const {
      experiences = [],
      personId,
      highlights: {
        experience: experienceHighlights = {},
        experienced_founder: experiencedFounderHighlights = {},
      } = {},
    } = this.props;

    const recentExperiences = experiences.slice(0, 3),
      previousExperiences = experiences.slice(3);

    const highlightedExperienceCompanies = (
      experienceHighlights[personId] || []
    ).map((e) => e.company);
    const highlightedSeniorityCompanies = (
      experiencedFounderHighlights[personId] || []
    ).map((e) => e.company);

    return (
      <div className="person-experiences">
        {recentExperiences.map((experience = {}) => (
          <PersonExperience
            key={experience.title + "-" + experience.company}
            experience={experience}
            highlighted={
              experience.company &&
              (highlightedExperienceCompanies.includes(experience.company) ||
                highlightedSeniorityCompanies.includes(experience.company))
            }
          />
        ))}
        <If condition={previousExperiences.length > 0}>
          <ListItem
            primaryText="Previous positions"
            initiallyOpen={false}
            primaryTogglesNestedList={true}
            nestedItems={previousExperiences.map((experience = {}) => (
              <PersonExperience
                key={experience.title + "-" + experience.company}
                experience={experience}
                highlighted={
                  experience.company &&
                  (highlightedExperienceCompanies.includes(
                    experience.company
                  ) ||
                    highlightedSeniorityCompanies.includes(experience.company))
                }
              />
            ))}
          />
        </If>
      </div>
    );
  }
}
