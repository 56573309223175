import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import Label from "material-ui/svg-icons/action/label";

import { capitalizeString } from "../../../../lib/helpers";
import Tooltip from "../../../Tooltip";

class CompressedInvestmentTypeStage extends React.Component {
  static propTypes = {
    investmentType: PropTypes.string.isRequired,
    investmentStage: PropTypes.string.isRequired,
  };

  state = {
    showTooltip: false,
  };

  hasError = () => {
    const { investmentType, investmentStage } = this.props;
    return (
      investmentType &&
      investmentType !== "saas" &&
      investmentType !== "marketplace" &&
      investmentType !== "consumer" &&
      investmentStage &&
      !/pre[-_ ]product/g.test(investmentStage) &&
      !/pre[-_ ]product[-_ ]market[-_ ]fit/g.test(investmentStage) &&
      !/pre[-_ ]marketing[-_ ]at[-_ ]scale/g.test(investmentStage)
    );
  };

  getTooltipText = () => {
    const text = ["Investment Type / Stage"];
    if (this.hasError()) {
      text.push(
        "Investment Type and / or Investment Stage does not match our investment strategy"
      );
    }
    return text.join("<br/>");
  };

  getInvestmentTypeStageClasses = () => {
    const classNames = ["data-content", "text"];
    if (this.hasError()) {
      classNames.push("red-flag");
    }
    return classNames.join(" ");
  };

  getInvestmentTypeStageString = () => {
    const { investmentType, investmentStage } = this.props;
    return `${
      investmentType
        ? capitalizeString(investmentType.replace(/[-_]/g, " "))
        : "Unknown"
    }\n${
      investmentStage
        ? capitalizeString(investmentStage.replace(/[-_]/g, " "))
        : "Unknown"
    }`;
  };

  render() {
    const { showTooltip } = this.state;
    return (
      <div
        className="data-wrapper compressed-investment-type-stage compressed"
        onMouseLeave={() => {
          this.setState({ showTooltip: false });
        }}
        onMouseEnter={() => {
          this.setState({ showTooltip: true });
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-icon">
          <Label className="data-icon-svg" />
        </div>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
        />
        <div className={this.getInvestmentTypeStageClasses()}>
          {this.getInvestmentTypeStageString()}
        </div>
      </div>
    );
  }
}

export default withRouter(CompressedInvestmentTypeStage);
