import PropTypes from "prop-types";
import React from "react";
import TwitterUserStore from "../../storage/TwitterUserStore";

import Loading from "../loading/Loading";
import RaisedButtonWithIcon from "../organization/RaisedButtonWithIcon";
import Image from "../Image";

class TwitterUser extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  state = {};

  componentWillMount() {
    this.userstore = new TwitterUserStore(this.props.id);
    this.userstore.getModel().then(this.handleUserChange);
  }

  handleUserChange = (user) => {
    this.setState({ user: user });
  };

  goTo = (website) => {
    window.open(website);
    return;
  };

  render() {
    var user = this.state.user;
    if (!user) {
      return <Loading />;
    }

    return (
      <div className="twitter-user">
        <div className="main-info">
          <Image url={user.profile_image_url} />
          <div className="info">
            <h4
              onClick={() => {
                this.goTo(`https://twitter.com/${user.screen_name}`);
              }}
            >
              {user.name}
            </h4>
            <p className="description">{user.description}</p>
          </div>
        </div>
        <div className="stats-info">
          <div className="followers_count">
            <label> Followers </label>
            <p> {user.followers_count} </p>
          </div>
          <div className="friends_count">
            <label> Following </label>
            <p> {user.friends_count} </p>
          </div>
          <div className="listed_count">
            <label> Listed Count </label>
            <p> {user.listed_count} </p>
          </div>
          <If condition={user.url}>
            <RaisedButtonWithIcon
              iconOnly={true}
              label="Website"
              icon="website primary"
              onClick={() => this.goTo(user.url)}
            />
          </If>
        </div>
      </div>
    );
  }
}

export default TwitterUser;
