import React from "react";
import InvestorsSuggestStore from "../../storage/InvestorsSuggestStore";
import TextField from "material-ui/TextField";

export default class InvestorPicker extends React.Component {
  state = {
    searchTerm: "",
    loading: false,
    investors: [],
  };

  investorsSuggestStore = new InvestorsSuggestStore();

  handleChangeInput = (event, searchTerm) => {
    this.setState(() => ({ searchTerm }));

    if (!this.state.loadSuggestions) {
      this.loadSuggestions(searchTerm);
    }
  };

  loadSuggestions = (searchTerm) => {
    this.setState(() => ({ organzations: [] }));

    if (!searchTerm) {
      return;
    }

    this.setState(
      () => ({
        loadingSuggestions: true,
      }),
      () =>
        this.investorsSuggestStore
          .suggestByName(searchTerm)
          .then((investors = []) =>
            this.setState(() => ({ loadingSuggestions: false, investors }))
          )
          .catch(console.error)
    );
  };

  render() {
    return (
      <div>
        <TextField
          id="investor-search"
          onChange={this.handleChangeInput}
          fullWidth={true}
          hintText="Investor name"
        />
        {this.props.children ? this.props.children(this.state) : null}
      </div>
    );
  }
}
