import PropTypes from "prop-types";
import React from "react";
import Divider from "material-ui/Divider";
import SocialLink from "./SocialLink";
import Tags from "./Tags";
import PersonExperiences from "./PersonExperiences";
import PersonEducations from "./PersonEducations";
import Image from "../Image";
import LinkedIn from "../LinkedIn";
import FlatButton from "material-ui/FlatButton";
import MuiTheme from "../../lib/MuiTheme";
import Highlights from "./Highlights";
import PersonOrganizations from "./PersonOrganizations";

import { Link } from "react-router-v4";
import ImageEdit from "material-ui/svg-icons/image/edit";
import ActionDelete from "material-ui/svg-icons/action/delete";

const linkedInUrl = (s) =>
  /^https?:\/\/(?:[a-z]+\.)?linkedin.com\/in\//.test(s)
    ? s
    : s
    ? `https://www.linkedin.com/in/${s}`
    : null;

export default class Person extends React.Component {
  static propTypes = {
    person: PropTypes.object.isRequired,
    organizationFeatures: PropTypes.object,
  };

  render() {
    const {
      person = {},
      organizationFeatures: { highlights: { highlights = {} } = {} } = {},
    } = this.props;

    const isPrimaryContact =
        person.id && person.id === this.props.primaryContactId,
      renderName = () => {
        if (person.id) {
          if (this.props.showAsPublicProfile) {
            return <h4 className="name">{person.name}</h4>;
          } else {
            return (
              <Link to={`/people/${person.id}`}>
                <h4 className="name">{person.name}</h4>
              </Link>
            );
          }
        }
        return <h4 className="name">{person.name}</h4>;
      },
      renderTitle = () => {
        let titleString = "";

        if (isPrimaryContact) {
          titleString += " PRIMARY CONTACT ";
        }

        if (person.role && person.title) {
          titleString += `${person.role} - ${person.title}`;
        } else if (person.role && !person.title) {
          titleString += `${person.role}`;
        } else if (person.title && !person.role) {
          titleString += `${person.title}`;
        }

        return <p className="title"> {titleString} </p>;
      },
      renderHighlights = (highlights) => {
        let personHighlights = {};
        Object.keys(highlights).map((highlightName) => {
          Object.keys(highlights[highlightName]).map((id) => {
            if (person.id.toString() === id) {
              personHighlights[highlightName] = highlights[highlightName][id];
            }
          });
        });
        return personHighlights;
      };

    return (
      <div className="person">
        <div className="data">
          <div key={person.name} className="person-list-item">
            <div className="primary-text">
              <div className="text-info">
                <Image bordered={isPrimaryContact} url={person.image_url} />
                {renderName()}
                {renderTitle()}
                <If condition={!this.props.editMode}>
                  <Highlights data={renderHighlights(highlights)} />
                </If>
                <If
                  condition={
                    !!person.organizations && person.organizations.length > 1
                  }
                >
                  <PersonOrganizations person={person} />
                </If>
                <If condition={this.props.editMode}>
                  <FlatButton
                    onClick={() => this.props.onEditPerson(person.id)}
                    style={{ color: MuiTheme.palette.secondary1color }}
                    label="EDIT"
                  />

                  <FlatButton
                    onClick={() =>
                      isPrimaryContact
                        ? this.props.onSetAsPrimaryContact()
                        : this.props.onSetAsPrimaryContact(person.id)
                    }
                    style={{ color: MuiTheme.palette.secondary1color }}
                    label={
                      isPrimaryContact ? "UNSET AS PRIMARY" : "SET AS PRIMARY"
                    }
                  />
                </If>
                <FlatButton
                  style={{ alignSelf: "flex-start" }}
                  label="Remove"
                  labelStyle={{ color: "#C30000" }}
                  icon={<ActionDelete color={"#C30000"} />}
                  onClick={() => this.props.removePerson(person.id)}
                />
              </div>
              <If condition={!this.props.hideSocialLink}>
                <div className="social-links">
                  <SocialLink source="website" link={person.url} />
                  <SocialLink source="twitter" link={person.twitter_url} />
                  <SocialLink source="angellist" link={person.angellist_url} />
                  <SocialLink
                    source="linkedin"
                    link={linkedInUrl(person.linkedin_url)}
                  />
                  <SocialLink source="facebook" link={person.facebook_url} />
                  <SocialLink source="github" link={person.github_url} />
                  <If condition={!this.props.showAsPublicProfile}>
                    <SocialLink
                      source="mail"
                      link={person.email}
                      isMail={true}
                    />
                  </If>
                  <If condition={!this.props.editMode}>
                    <a href={`/people/${person.id}/edit`} target="_blank">
                      <ImageEdit
                        className="contacts-edit-contact"
                        color="#337ab7"
                      />
                    </a>
                  </If>
                </div>
              </If>
              <If condition={!this.props.editMode}>
                <If condition={!person.linkedin_url}>
                  <div className="linkedin-actions">
                    <LinkedIn
                      name={person.name}
                      organizationName={this.props.organizationName}
                    />
                    <LinkedIn name={person.name} />
                  </div>
                </If>
                <If condition={person.bio}>
                  <div className="additional-info">
                    <Divider inset={true} />
                    <div>
                      <label>Bio</label>
                      <p> {person.bio} </p>
                    </div>
                  </div>
                </If>
                <If condition={person.experience}>
                  <div className="additional-info">
                    <Divider inset={true} />
                    <div>
                      <label>Experience</label>
                      <PersonExperiences
                        experiences={person.experience}
                        personId={person.id.toString()}
                        highlights={highlights}
                      />
                    </div>
                  </div>
                </If>
                <If condition={person.skills}>
                  <div className="additional-info">
                    <Divider inset={true} />
                    <div>
                      <label>Skills</label>
                      <Tags data={person.skills} />
                    </div>
                  </div>
                </If>
                <If condition={person.education}>
                  <div className="additional-info">
                    <Divider inset={true} />
                    <div>
                      <label>Education</label>
                      <PersonEducations education={person.education} />
                    </div>
                  </div>
                </If>
              </If>
            </div>
          </div>
        </div>
        <If condition={!this.props.editMode}>
          <Divider />
        </If>
      </div>
    );
  }
}
