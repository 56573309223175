import PropTypes from "prop-types";
import React from "react";
import Ago from "../Ago";

class DateCell extends React.Component {
  render() {
    return <Ago timestamp={this.props.person[this.props.columnKey]} />;
  }
}

DateCell.propTypes = {
  person: PropTypes.object.isRequired,
  columnKey: PropTypes.string.isRequired,
};

export default DateCell;
