import auth from "./Auth";
import config from "../config";
import request from "superagent";

const {
  api: { host },
} = config;

export default class FilesStore {
  constructor(id) {
    this.url = `${host}/organizations/${id}/files`;
  }

  get() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.url)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .set("Accept", "application/json")
      )
      .then((response) => response.body)
      .catch((error) => {
        if (error.response && error.response.notFound) {
          return {
            files: [],
          };
        }
        throw error;
      });
  }

  createFolder = () => {
    return auth
      .getAuthData()
      .then((authData) =>
        request.put(this.url).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then((response) => response.body);
  };

  uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return auth
      .getAuthData()
      .then((authData) =>
        request.post(this.url).send(formData).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then((response) => response.body);
  };

  copyOrAddFile = (fileId, action) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request.post(`${this.url}/${fileId}`).query({
          access_token: authData.token,
          client: authData.client,
          action,
        })
      )
      .then((response) => response.body);
  };
}
