import React, { useState } from "react";
import DialogWrapper from "../components/DialogWrapper";
import { RaisedButton } from "material-ui";
import auth from "../../storage/Auth";
import Config from "../../config";
import request from "superagent";
import PropTypes from "prop-types";

const successMessage = "Create completed successfully!";

function wasSuccessful(createStatus) {
  return createStatus === successMessage;
}

function CreateFromLinkedIn({ linkedin, onCreateComplete, organizationId }) {
  const [isCreating, setIsCreating] = useState(false);
  const [createStatus, setCreateStatus] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCreate = () => {
    setIsCreating(true);
    setOpenModal(true);
    setCreateStatus("Creating...");

    const timeoutId = setTimeout(() => {
      setIsCreating(false);
      setCreateStatus("Create timed out.");
    }, 3 * 60 * 1000);

    triggerCreateFromLinkedIn(linkedin, organizationId)
      .then((person) => {
        clearTimeout(timeoutId);
        setIsCreating(false);
        setCreateStatus(successMessage);
        onCreateComplete(person);
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        setIsCreating(false);
        setCreateStatus("Error: " + error.message);
      });
  };
  if (!linkedin) {
    return (
      <div>
        <button disabled={true}>Add LinkedIn url to create</button>
      </div>
    );
  }

  return (
    <div>
      <button onClick={handleCreate} disabled={isCreating}>
        {isCreating ? "Creating..." : "Create from LinkedIn url"}
      </button>
      <DialogWrapper
        open={openModal}
        onClose={handleClose}
        modal={true}
        actions={[
          <RaisedButton onClick={handleClose} color="primary" label="close" />,
        ]}
        repositionOnUpdate={true}
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
      >
        <h3>{createStatus}</h3>
        {wasSuccessful(createStatus) && (
          <p>You will be redirected to the person page in a few seconds.</p>
        )}
      </DialogWrapper>
    </div>
  );
}

CreateFromLinkedIn.propTypes = {
  linkedin: PropTypes.string.isRequired,
  onCreateComplete: PropTypes.func.isRequired,
  organizationId: PropTypes.string,
};

const triggerCreateFromLinkedIn = (linkedin, organizationId) =>
  auth
    .getAuthData()
    .then(({ token, client }) =>
      request
        .post(`${Config.api.host}/providers/coresignal/db/people`)
        .set("Content-Type", "application/json")
        .query({ access_token: token, client })
        .send({ organization_id: organizationId, linkedin })
    )
    .then((response) => response.body);

export default CreateFromLinkedIn;
