import EmailValidationStore from "../../storage/EmailValidationStore";
import PropTypes from "prop-types";
import React from "react";
import FlatButton from "material-ui/FlatButton";
import LocationSearching from "material-ui/svg-icons/device/location-searching";
import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import Loading from "../loading/Loading";
import DialogWrapper from "../components/DialogWrapper";
class ValidateEmail extends React.Component {
  constructor(props) {
    super(props);

    this.emailValidator = new EmailValidationStore();

    this.state = {
      validating: false,
    };

    this.validateEmail = this.validateEmail.bind(this);
    this.handleCloseValidatingDialog =
      this.handleCloseValidatingDialog.bind(this);
  }

  validateEmail() {
    if (!this.props.email) return;
    this.setState({
      validating: true,
    });
    this.emailValidator
      .validate(this.props.email)
      .then((result) => {
        const { validations = {} } = result;
        this.setState({
          validations: validations,
          error: null,
        });
        this.props.handleVerifiedEmail(
          Object.entries(validations).some(
            ([key, validation]) => validation !== "invalid"
          ) || false
        );
      })
      .catch((error) => {
        this.setState({
          error: error,
          validations: null,
        });
      });
  }

  handleCloseValidatingDialog() {
    this.setState({
      validating: false,
      validations: null,
    });
  }

  render() {
    const validations = this.state.validations;
    const actions = [
      <RaisedButton
        className="assign-action"
        backgroundColor="#5DAB49"
        label="OK"
        style={{
          marginRight: "10px",
        }}
        onClick={this.handleCloseValidatingDialog}
      />,
    ];

    return (
      <div className="validate-email-component">
        <FlatButton
          key="validate-email"
          className="validate-email"
          label="Validate"
          onClick={this.validateEmail}
          icon={<LocationSearching color={"#5DAB49"} />}
        />
        <DialogWrapper
          title="Validating Emails"
          actions={actions}
          modal={false}
          open={this.state.validating}
          onRequestClose={this.handleCloseValidatingDialog}
        >
          <If condition={this.state.validations}>
            <div className="data-column">
              <label>Hunter</label>
              <div
                className={
                  validations.hunter === "invalid" ? "invalid" : "valid"
                }
              >
                <span>{validations.hunter}</span>
              </div>
            </div>
          </If>
          <If condition={this.state.error}>
            <div>
              There was a problem retrieving the validations: {this.state.error}
            </div>
          </If>
          <If condition={!this.state.validations && !this.state.error}>
            <Loading />
          </If>
        </DialogWrapper>
      </div>
    );
  }
}

ValidateEmail.propTypes = {
  email: PropTypes.string.isRequired,
  handleVerifiedEmail: PropTypes.func.isRequired,
};

export default ValidateEmail;
