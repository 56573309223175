import React from "react";
import PropTypes from "prop-types";

import Avatar from "material-ui/Avatar";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import personPlaceholder from "../../../images/person-placeholder.jpg";
import { truncateString } from "../../lib/helpers";

export default class SelectPerson extends React.Component {
  static propTypes = {
    onSelectPerson: PropTypes.func.isRequired,
    label: PropTypes.string,
    multiple: PropTypes.bool.isRequired,
    people: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        email: PropTypes.string,
        imageUrl: PropTypes.string,
      }).isRequired
    ),
    selected: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    ]),
    usedKey: PropTypes.string,
    withRoles: PropTypes.bool,
    disabledPeopleIds: PropTypes.array,
    className: PropTypes.string,
    includeNone: PropTypes.bool,
  };

  static defaultProps = {
    multiple: false,
    disabledPeopleIds: [],
    className: "",
    includeNone: false,
  };

  render() {
    const {
      label,
      multiple,
      people,
      onSelectPerson,
      selected,
      disabledPeopleIds,
      className = "",
      fullWidth = true,
      disabled = false,
      usedKey = "email",
      withRoles = false,
      includeNone = false,
    } = this.props;
    return (
      <SelectField
        className={`select-person ${className}`}
        name="select-person"
        selectedMenuItemStyle={{ color: "#5DAB49" }}
        floatingLabelText={label}
        floatingLabelFixed={true}
        value={selected}
        onChange={onSelectPerson}
        multiple={multiple}
        disabled={disabled}
        fullWidth={fullWidth}
      >
        <If condition={withRoles || includeNone}>
          <MenuItem
            className="select-person-menu-item"
            key={"none"}
            value={"none"}
            primaryText={"None"}
          />
        </If>
        {people.map((p) => {
          const primaryLabels = withRoles
            ? [p.name, p.role, truncateString(p.title, 45), p.email]
                .filter(Boolean)
                .join(" - ")
            : p.name;

          return (
            <MenuItem
              disabled={disabledPeopleIds.indexOf(p.id) > -1}
              className="select-person-menu-item"
              key={p[usedKey]}
              value={p[usedKey]}
              primaryText={primaryLabels}
              leftIcon={
                <Avatar
                  className="select-person-avatar"
                  src={p.imageUrl || personPlaceholder}
                />
              }
            />
          );
        })}
      </SelectField>
    );
    p;
  }
}
