export default class OrganizationsNavigatorFetcher {
  constructor(organizationsStore) {
    this.organizationsStore = organizationsStore;
  }

  getNextAndPrevious = (id) => {
    const organizations = this.getAll();
    const index = this.getCurrentIndex(organizations, id);
    if (index === organizations.length - 2) {
      this.fetchNext(organizations.length);
    }
    return {
      previous: this.getNameAndId(organizations[index - 1] || {}),
      next: this.getNameAndId(organizations[index + 1] || {}),
    };
  };

  getAll = () => {
    return this.organizationsStore.getAll();
  };

  getCurrentIndex = (organizations, id) =>
    organizations.findIndex((organization) => organization.id === id);

  fetchNext = (offset) => {
    this.organizationsStore.fetch(offset);
  };

  getNameAndId = (organization) => {
    return organization
      ? {
          id: organization.id,
          name: organization.name,
        }
      : null;
  };
}
