import React from "react";
import PropTypes from "prop-types";

import ScorecardModalSectionContentCallInput from "./ScorecardModalSectionContentCallInput";
import Tooltip from "../../../Tooltip";

import {
  isValid,
  getExampleLabel,
} from "../../../../lib/questionInputValidation";

class ScorecardQuestion extends React.Component {
  static propTypes = {
    section: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    currentStructuredContent: PropTypes.array.isRequired,
  };

  render() {
    const {
      section = "",
      format = "",
      question = "",
      currentStructuredContent = [],
    } = this.props;
    return (
      <li className="question">
        <div
          className="question-inner"
          ref={(el) => (this._tooltipTarget = el)}
        >
          <If
            condition={
              section === "product" &&
              (format === "integer" ||
                format === "integer-growth" ||
                format === "money" ||
                format === "money-growth")
            }
          >
            <Tooltip
              parent={this._tooltipTarget}
              position="top left"
              text={`${getExampleLabel(format)}`}
              showOnHover
            />
            <ScorecardQuestionValidation
              currentStructuredContent={currentStructuredContent}
              format={format}
            />
          </If>
          {question}
        </div>
      </li>
    );
  }
}

class ScorecardQuestionValidation extends React.Component {
  static propTypes = {
    currentStructuredContent: PropTypes.array.isRequired,
    format: PropTypes.string.isRequired,
  };

  getStatus = (structuredContent = {}, format = "") =>
    structuredContent.length === 0 ||
    structuredContent.some(({ content = "" }) => content === "")
      ? "empty"
      : structuredContent.some(({ content = "" }) => !isValid(format, content))
      ? "invalid"
      : "valid";

  render() {
    const { currentStructuredContent = [], format = "" } = this.props;
    const status = this.getStatus(currentStructuredContent, format);
    return (
      <Choose>
        <When condition={status === "empty"}>❌</When>
        <When condition={status === "invalid"}>⚠️</When>
        <When condition={status === "valid"}>✅</When>
      </Choose>
    );
  }
}

export default class ScorecardModalSectionContentCall extends React.Component {
  static propTypes = {
    formattedContent: PropTypes.string.isRequired,
    structuredContent: PropTypes.object.isRequired,
    noShow: PropTypes.bool.isRequired,
    scorecardType: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
    sectionIndex: PropTypes.number.isRequired,
    relevantQuestions: PropTypes.array.isRequired,
    allQuestions: PropTypes.array.isRequired,
    failedToLoadTemplates: PropTypes.bool.isRequired,
    availableTemplates: PropTypes.bool.isRequired,
    handleChangeSection: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    callId: PropTypes.string.isRequired,
    selectCallType: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
  };

  render() {
    const {
      formattedContent = " ",
      structuredContent = {},
      noShow,
      scorecardType,
      section,
      sectionIndex,
      relevantQuestions,
      allQuestions,
      failedToLoadTemplates,
      availableTemplates,
      handleChangeSection,
      expanded,
      callId,
      selectCallType,
      show,
    } = this.props;

    return (
      <div className="scorecard-modal-section-content-call">
        <div className="scorecard-modal-section-content-call-notes-questions">
          <ScorecardModalSectionContentCallInput
            section={section}
            sectionIndex={sectionIndex}
            formattedContent={formattedContent}
            structuredContent={structuredContent}
            noShow={noShow}
            relevantQuestions={relevantQuestions}
            allQuestions={allQuestions}
            handleChangeSection={handleChangeSection}
            availableTemplates={availableTemplates}
            editing={scorecardType !== "view"}
            expanded={expanded}
            callId={callId}
            selectCallType={selectCallType}
            show={show}
          />
          <If
            condition={
              section !== "risks" && !noShow && relevantQuestions.length > 1
            }
          >
            <div className="scorecard-modal-section-content-call-questions">
              <div className="questions-title">Headers</div>
              <Choose>
                <When condition={failedToLoadTemplates}>
                  <div className="question">Failed to load templates</div>
                </When>
                <Otherwise>
                  <Choose>
                    <When condition={relevantQuestions.length > 1}>
                      <ul className="questions-list">
                        {relevantQuestions.map(
                          ({ key, question, section, format }) => (
                            <Choose>
                              <When condition={format === "header"}>
                                <div className="questions-header" key={key}>
                                  {question}
                                </div>
                              </When>
                              <Otherwise>
                                <ScorecardQuestion
                                  key={key}
                                  section={section}
                                  format={format}
                                  question={question}
                                  currentStructuredContent={
                                    structuredContent[key] || []
                                  }
                                />
                              </Otherwise>
                            </Choose>
                          )
                        )}
                      </ul>
                    </When>
                    <Otherwise>
                      <Choose>
                        <When condition={availableTemplates}>
                          <div className="question">No questions</div>
                        </When>
                        <Otherwise>
                          <div className="question">
                            <Choose>
                              <When condition={section !== "product"}>
                                Please select a call type
                              </When>
                              <Otherwise>
                                Please select the investment type and revenue
                                model
                              </Otherwise>
                            </Choose>
                          </div>
                        </Otherwise>
                      </Choose>
                    </Otherwise>
                  </Choose>
                </Otherwise>
              </Choose>
            </div>
          </If>
        </div>
      </div>
    );
  }
}
