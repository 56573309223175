import React from "react";
import PropTypes from "prop-types";

import xss from "xss";

export default class TimelineEventMailingBody extends React.Component {
  static propTypes = {
    email: PropTypes.object.isRequired,
  };

  render() {
    const { email } = this.props;
    return (
      <div className="timeline-event-body">
        <div className="timeline-event-email-header">
          <p>
            <span>From:</span> {email.emailFrom || "-"}
          </p>
          <p>
            <span>To:</span> {email.emailTo || "-"}
          </p>
          <p>
            <span>Date:</span> {email.sent || "-"}
          </p>
          <p>
            <span>Subject:</span> {email.subject || "-"}
          </p>
        </div>
        <div
          className="timeline-event-email-body"
          dangerouslySetInnerHTML={{ __html: xss(email.body) }}
        />
      </div>
    );
  }
}
