import request from "superagent";
import auth from "./Auth";
import Config from "../config";

import titles from "../content/titles";

const withTitle = (person = {}) => ({
  ...person,
  title: titles[person.title] || person.title,
});

const euros = (x) => {
  const f = parseFloat(x);

  if (!f || isNaN(f)) {
    return undefined;
  }

  return f.toLocaleString(undefined, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

function completeFirst(a, b) {
  return b.status.startsWith("Complete") ? 1 : -1;
}

function newestFirst(a, b) {
  return b.dateSubmitted - a.dateSubmitted;
}

function getV2Status(entry) {
  if (entry.status) {
    switch (entry.status) {
      case "NOT_STARTED":
        return "Not Started";
      case "PARTIAL":
        return "Partial";
      case "COMPLETE":
        return "Complete";
    }
  }
  return !!entry.form.submitted_at ? "Complete" : "Partial";
}

class FormOrganizationStore {
  constructor(organization) {
    const {
      source_refs: {
        FUNDING: v1FundingIds = [],
        V2FUNDING: v2FundingIds = [],
        V3FUNDING: v3FundingIds = [],
      } = {},
    } = organization;
    this.responseIds = v1FundingIds;
    this.v2responseIds = v2FundingIds;
    this.v3responseIds = v3FundingIds;
  }

  getBestEntry() {
    return Promise.all([
      ...this.responseIds.map(this.getV1FundingForm),
      ...this.v2responseIds.map(this.getV2FundingForm),
      ...this.v3responseIds.map(this.getV3FundingForm),
    ]).then((values) => this.bestEntry(values));
  }

  bestEntry(forms = []) {
    if (forms.length < 1) {
      return null;
    }
    return forms.sort(completeFirst).sort(newestFirst).shift();
  }

  getAllForms() {
    return Promise.all([
      ...this.responseIds.map(this.getV1FundingForm),
      ...this.v2responseIds.map(this.getV2FundingForm),
      ...this.v3responseIds.map(this.getV3FundingForm),
    ]).then((forms) => {
      const response = { forms };
      const bestEntry = this.bestEntry(
        Object.entries(forms).map(([id, form]) => form)
      );
      if (bestEntry) {
        response.best_entry_id = bestEntry.id;
      }
      return response;
    });
  }

  getV2FundingForm(id) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(Config.api.host + "/providers/v2funding/entries/" + id)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then((response) => response.body)
      .then((r) => {
        const answers = r.answers || {};
        const freshCapital = answers.fresh_capital || {};
        const externalFunding = answers.external_funding || {};
        const founders = answers.founders || [];
        const aboutYou = answers.about_you || {};
        if (aboutYou.founder === "Yes") {
          founders.unshift(answers.about_you);
        }
        const numberOfEmployees = answers.number_of_employees || {};
        const keyEmployees = answers.key_employees || [];
        if (aboutYou.founder !== "Yes") {
          keyEmployees.unshift(answers.about_you);
        }

        const status = getV2Status(r);
        return {
          id: r.form.form_id,
          submitted_by: aboutYou,
          status: status,
          date_submitted: answers.updated_at || r.form.updated_at,
          created: answers.created_at || r.form.created_at,
          email: r.email,
          version: "v2",
          people: {
            founders: founders.map(withTitle),
            inspiration: answers.inspiration,
            link_with_business: answers.founders_link,
            key_employee_details: keyEmployees.map(withTitle),
            employees: {
              number_engineering_product: numberOfEmployees.engineering_product,
              number_sales_marketing: numberOfEmployees.sales_marketing,
              number_other: numberOfEmployees.other,
              number_total_employees: numberOfEmployees.total,
            },
          },
          product: {
            product_info: {
              owner: answers.product_owner,
              vision: answers.product_vision,
            },
            problem: answers.problem,
            solution: answers.solution,
            customer: answers.customer,
            priorities: answers.priorities,
            other_kpis: answers.other_kpis,
            users: {
              users_last_month: answers.users_last_month,
              users_two_months_ago: answers.users_two_months_ago,
              users_three_months_ago: answers.users_three_months_ago,
            },
            revenue: {
              revenue_last_month: answers.revenue_last_month,
              revenue_two_months_ago: answers.revenue_two_months_ago,
              revenue_three_months_ago: answers.revenue_three_months_ago,
            },
          },
          market: {
            sales_model: answers.sales_model,
            approach: answers.marketing_approach,
            opportunity: answers.addressable_market,
            competitors: answers.competitiors,
            position: answers.positioning,
            marketing_approach: answers.marketing_approach,
            target_segment: answers.target_segment,
          },
          funding: {
            external_funding: {
              received: euros(externalFunding.amount),
              months_left_current_capital: externalFunding.runway,
            },
            fresh_capital: {
              looking_for_amount: euros(freshCapital.amount),
              months_duration_next_capital: freshCapital.runway,
            },
          },
        };
      });
  }

  getV3FundingForm(id) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(
            Config.api.host +
              "/providers/v3funding/submissions/" +
              id +
              "/answers"
          )
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then((response) => response.body)
      .then((answers) => {
        const founders = answers.founders || [];
        if (answers.are_you_a_founder) {
          founders.unshift({
            name: answers.your_name,
            role: answers.your_role,
            title: answers.your_title,
            linkedin_url: answers.linked_in,
            ownership: answers.your_ownership,
          });
        }

        return {
          id: id,
          submitted_by: {
            first_name: answers.your_name,
          },
          status: "Complete",
          date_submitted: answers.updated_at,
          created: answers.created_at,
          email: answers.your_email,
          version: "v3",
          people: {
            introduced_by: answers.introduced_by,
            founders: founders,
            description: answers.description,
            right_team: answers.right_team,
          },
          product: {
            problem: answers.problem,
            solution: answers.solution,
            customer: answers.customer,
            revenue_model: answers.revenue_model,
            customers: {
              customers_last_month: answers.one_month_ago_customers,
              customers_two_months_ago: answers.two_month_ago_customers,
              customers_three_months_ago: answers.three_month_ago_customers,
            },
            mrr: {
              mrr_last_month: answers.one_month_ago_mrr,
              mrr_two_months_ago: answers.two_month_ago_mrr,
              mrr_three_months_ago: answers.three_month_ago_mrr,
            },
            active_users: {
              active_users_last_month: answers.one_month_ago_active_users,
              active_users_two_months_ago: answers.two_month_ago_active_users,
              active_users_three_months_ago:
                answers.three_month_ago_active_users,
            },
            revenue: {
              revenue_last_month: answers.one_month_ago_revenue,
              revenue_two_months_ago: answers.two_month_ago_revenue,
              revenue_three_months_ago: answers.three_month_ago_revenue,
            },
            north_star: answers.north_star,
            suppliers: {
              suppliers_last_month: answers.one_month_ago_suppliers,
              suppliers_two_months_ago: answers.two_month_ago_suppliers,
              suppliers_three_months_ago: answers.three_month_ago_suppliers,
            },
            buyers: {
              buyers_last_month: answers.one_month_ago_buyers,
              buyers_two_months_ago: answers.two_month_ago_buyers,
              buyers_three_months_ago: answers.three_month_ago_buyers,
            },
            gmv: {
              gmv_last_month: answers.one_month_ago_gmv,
              gmv_two_months_ago: answers.two_month_ago_gmv,
              gmv_three_months_ago: answers.three_month_ago_gmv,
            },
          },
          market: {
            competitors: answers.key_competitors,
            position: answers.key_position,
          },
          funding: {
            funding: answers.funding,
            investors: answers.investors,
            funding_requirements: answers.funding_requirements,
            supporting_documents: answers.supporting_documents,
            supporting_links: answers.supporting_links,
          },
        };
      });
  }

  getV1FundingForm(id) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(Config.api.host + "/providers/funding/entries/" + id)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then((response) => response.body)
      .then((bodyResponse) => {
        return {
          id: bodyResponse.id,
          funding: bodyResponse.funding,
          market: bodyResponse.market,
          product: bodyResponse.product,
          risks: bodyResponse.risk,
          people: bodyResponse.team,
          status: bodyResponse.status,
          date_submitted: bodyResponse.dateSubmitted,
          version: "v1",
        };
      });
  }
}

export default FormOrganizationStore;
