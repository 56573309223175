import PropTypes from "prop-types";
import React from "react";
import { Card, CardHeader } from "material-ui/Card";

import _ from "underscore";
import ReactHighcharts from "react-highcharts";

export default class Signals extends React.Component {
  static propTypes = {
    signals: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
  };

  state = {
    expanded: this.props.expanded,
  };

  buildConfig = () => {
    const yAxisValues = [];

    this.props.signals.map((data) => {
      if (data.timestamp > 0) {
        yAxisValues.push([data.timestamp, Number(data.value)]);
      }
    });

    return {
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          month: "%b %y",
        },
        tickInterval: 30 * 24 * 3600 * 1000,
      },
      series: [
        {
          data: yAxisValues,
          name: this.props.title,
          color: "rgba(33,79,119,1)",
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, "rgb(33,79,119)"],
              [1, "#f4f4f4"],
            ],
          },
        },
      ],
      chart: {
        type: "area",
      },
      tooltip: {
        headerFormat: "<b>{series.name}</b><br>",
        pointFormat: "{point.x:%e %b %Y}: {point.y:.2f}",
      },
      plotOptions: {
        area: {
          marker: {
            enabled: true,
          },
        },
        series: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    };
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.expanded !== nextProps.expanded ||
      this.props.signals !== nextProps.signals ||
      this.props.title !== nextProps.title ||
      this.state.expanded !== nextState.expanded
    );
  }

  handleExpandChange = (expanded) => {
    this.setState({ expanded: expanded });
    const ev = document.createEvent("Event");
    ev.initEvent("resize", true, true);
    window.dispatchEvent(ev);
  };

  render() {
    const getSignalsClass = () => {
        if (this.state.expanded) {
          return "signals";
        }
        return "signals reduced";
      },
      config = this.buildConfig();

    if (_.isEmpty(this.props.signals)) {
      return <span />;
    }
    return (
      <Card
        expanded={this.state.expanded}
        onExpandChange={this.handleExpandChange}
      >
        <CardHeader
          title={this.props.title}
          actAsExpander={true}
          showExpandableButton={true}
        />
        <div className={getSignalsClass()}>
          <ReactHighcharts config={config} />
        </div>
      </Card>
    );
  }
}
