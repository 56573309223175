import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import NumberOfLeadInvestors from "./NumberOfLeadInvestors";
import NumberOfInvestors from "./NumberOfInvestors";
import InvestorsTable from "./InvestorsTable";

class Investors extends React.Component {
  static propTypes = {
    fundingRounds: PropTypes.array.isRequired,
    fundingRoundsLoading: PropTypes.bool.isRequired,
    investors: PropTypes.array.isRequired,
    investorsLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool.isRequired,
  };

  render() {
    const {
      fundingRounds = [],
      fundingRoundsLoading,
      investors = [],
      investorsLoading,
      useSnapshot = false,
    } = this.props;
    return (
      <div className="investors-wrapper">
        <div className="grid-container investors-grid-container">
          <div className="investors-grid-item number-of-lead-investors-grid-item">
            <NumberOfLeadInvestors
              fundingRounds={fundingRounds}
              useSnapshot={useSnapshot}
            />
          </div>
          <div className="investors-grid-item border-1-grid-item" />
          <div className="investors-grid-item number-of-investors-grid-item">
            <NumberOfInvestors
              investors={investors}
              useSnapshot={useSnapshot}
            />
          </div>
          <div className="investors-grid-item investors-table-grid-item">
            <InvestorsTable
              fundingRounds={fundingRounds}
              fundingRoundsLoading={fundingRoundsLoading}
              investors={investors}
              investorsLoading={investorsLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Investors);
