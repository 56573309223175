import React from 'react';
import Main from '../../../components/js/ui/Main';

export default function Dig(props) {
  const {auth0, inboxState, mountParcel, userState} = props;

  return (
    <div data-v-dig>
      <Main
        auth0={auth0}
        inboxState={inboxState}
        mountParcel={mountParcel}
        userState={userState}
      />
    </div>
  );
}
