import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

class Category extends React.Component {
  static propTypes = {
    category: PropTypes.string.isRequired,
  };

  render() {
    const { category } = this.props;
    return (
      <div className="data-wrapper price">
        <div className="data-header">Category</div>
        <Choose>
          <When condition={category === ""}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="data-content text">{category}</div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(Category);
