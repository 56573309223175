import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-v4";
import AppBar from "material-ui/AppBar";
import _ from "underscore";
import IconButton from "material-ui/IconButton";
import ActionDelete from "material-ui/svg-icons/action/delete";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import RaisedButton from "material-ui/RaisedButton";
import Paper from "material-ui/Paper";
import FiltersSets from "./FiltersSets";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { scorerSortKey } from "../../lib/predictions";
import CSVFilter from "./CSVFilter";
import investmentTypesMap from "../../content/investmentTypes";
import MLTagsFilter from "./MLTagsFilter";
import SelectOperator from "../SelectOperator";
import insights from "../../content/insights";
import DatePicker from "material-ui/DatePicker";
import TextField from "material-ui/TextField";
import Divider from "material-ui/Divider";
import StateFilter from "../StateFilter";
import StagesStreak from "../StagesStreak";
import LOCATIONS from "../../content/locations";
import CountriesFilter from "./CountriesFilter";
import SOURCES from "../../content/sources";
import InReachToggle from "../InReachToggle";
import ExportToCSV from "./ExportToCSV";
import ContentSave from "material-ui/svg-icons/content/save";

import moment from "moment";
import { activeInReachTeam } from "../../lib/helpers";

const DEFAULT_FILTER_PARAMS = {
  based_in_continent: "Europe",
};
const ACTIVE_TEAM = activeInReachTeam();

function toMoment(epoch) {
  return epoch ? moment(parseInt(epoch))._d : null;
}

function displayPrediction(prediction) {
  return !!prediction ? parseFloat(prediction) * 100 : "";
}

export default class OrganizationFilterSet extends React.Component {
  static propTypes = {
    searchParams: PropTypes.object,
  };
  state = {};

  render() {
    const {
      client,
      tags,
      orderByValue,
      highlights,
      getTransitionalState,
      investmentTypes,
      searchParams,
      handleClearFilter,
      handleCloseFilter,
      selectedFiltersSet,
      updateFiltersSets,
      handleDeselectFiltersSet,
      handleSelectFiltersSet,
      handleDeleteFiltersSet,
      filtersSets,
      handleOrderByChange,
      handleOrderChange,
      handleIdsChange,
      handleInvestmentTypesChange,
      handleTermsChange,
      handleMLTagChange,
      handleMLTagRemove,
      handleTrendOrganizationMagnitudeChange,
      handleTrendOrganizationMagnitudeValue,
      handleTrendOrganizationBetaChange,
      handleHighlightChange,
      handleFoundedOnAfterChange,
      handleFoundedOnBeforeChange,
      handleCreatedAtAfterChange,
      handleCreatedAtBeforeChange,
      handleChanges,
      handleInvestmentGreaterThanChange,
      handleStateChange,
      handleInvestmentLessThanChange,
      handleNeverPredictionGreaterThanChange,
      handleNeverPredictionLessThanChange,
      handleNotNowPredictionGreaterThanChange,
      handleNotNowPredictionLessThanChange,
      handleContactPredictionGreaterThanChange,
      handleContactPredictionLessThanChange,
      saveCurrentParamsAsDefaultFilters,
      handleTrendOrganizationBetaValue,
      exportToCSV,
      handleChange,
      userHasFullAccess,
      handleStageChange,
      handleContinentChange,
      handleCountriesChange,
      handleCityChange,
      handleInvestorChange,
      handleSourceChange,
      handleEventChange,
      handleInboxChange,
      handleClassificationChange,
      handleSpamChange,
      handleRequiresMoreInfoChange,
      handleIsCompleteChange,
      handleShowPredictionChange,
      handleShowClassificationsChange,
      handleTransitionalStateChange,
    } = this.props;

    return (
      <div className="filter-menu">
        <AppBar
          className="appBar secondary"
          title="Filters"
          iconElementLeft={
            <div>
              <If condition={!_.isEqual(searchParams, DEFAULT_FILTER_PARAMS)}>
                <IconButton
                  iconStyle={{ color: "white" }}
                  onClick={handleClearFilter}
                >
                  <ActionDelete style={{ color: "white" }} />
                </IconButton>
              </If>
              <If condition={_.isEqual(searchParams, DEFAULT_FILTER_PARAMS)}>
                <div />
              </If>
            </div>
          }
          iconElementRight={
            <IconButton className="close-filter" onClick={handleCloseFilter}>
              <NavigationClose />
            </IconButton>
          }
        />
        <div className="filter-container">
          <Link to={"/incomplete"}>
            <RaisedButton
              className="incomplete-button"
              label="INCOMPLETE"
              backgroundColor="#5dab49"
              labelColor="#ffffff"
            />
          </Link>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="tag-icon" />
                <h5> Filters Set </h5>
              </div>
              <div className="filter-data">
                <FiltersSets
                  selectedFiltersSet={selectedFiltersSet}
                  updateFiltersSets={updateFiltersSets}
                  handleDeselectFiltersSet={handleDeselectFiltersSet}
                  handleSelectFiltersSet={handleSelectFiltersSet}
                  handleDeleteFiltersSet={handleDeleteFiltersSet}
                  data={filtersSets}
                  searchParams={searchParams}
                />
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="sliders" />
                <h5> Ordering </h5>
              </div>
              <div className="filter-data">
                <label> Sort by </label>
                <SelectField
                  className="select sort-by"
                  value={orderByValue}
                  onChange={handleOrderByChange}
                >
                  <MenuItem value="founded_on" primaryText="Founded On" />
                  <MenuItem value="updated_at" primaryText="Updated At" />
                  <MenuItem value="created_at" primaryText="Created At" />
                  <MenuItem
                    value={scorerSortKey}
                    primaryText="Discover Score"
                  />
                  <MenuItem
                    value="predictions.never"
                    primaryText="Spam Score"
                  />
                  <MenuItem
                    value="aggregated_followon_percentage"
                    primaryText="Investor Score"
                  />
                </SelectField>
              </div>
              <div className="filter-data">
                <label> Order </label>
                <SelectField
                  className="select"
                  value={searchParams.order || "desc"}
                  onChange={handleOrderChange}
                >
                  <MenuItem value="desc" primaryText="Descending" />
                  <MenuItem value="asc" primaryText="Ascending" />
                </SelectField>
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="tag-icon" />
                <h5> Organizations </h5>
              </div>
              <div className="filter-data">
                <CSVFilter
                  didChangeValues={handleIdsChange}
                  hintText="Comma separated ids"
                  id="ids"
                  label="Add ID"
                  values={searchParams.ids}
                />
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <h5> Investment Type </h5>
              </div>
              <div className="filter-data">
                <SelectField
                  multiple={true}
                  fullWidth={true}
                  hintText="Select an investment type"
                  value={investmentTypes}
                  onChange={handleInvestmentTypesChange}
                >
                  {Object.keys(investmentTypesMap).map((key) => (
                    <MenuItem
                      key={key}
                      insetChildren={true}
                      checked={
                        investmentTypes && investmentTypes.indexOf(key) > -1
                      }
                      value={key}
                      primaryText={investmentTypesMap[key].name}
                    />
                  ))}
                </SelectField>
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="cog-icon" />
                <h5> Tags </h5>
              </div>
              <MLTagsFilter
                selected={tags}
                onSelectTag={handleMLTagChange}
                removeTag={handleMLTagRemove}
              />
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="tag-icon" />
                <h5> Terms </h5>
              </div>
              <div className="filter-data">
                <SelectOperator
                  operator={searchParams.terms_operator || "or"}
                  handleOperatorChange={(value) =>
                    handleChange("terms_operator", value)
                  }
                />
              </div>
              <div className="filter-data">
                <CSVFilter
                  didChangeValues={handleTermsChange}
                  hintText="Comma separated terms"
                  id="terms"
                  label="Add Term"
                  values={searchParams.terms}
                />
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="trends-percentile" />
                <h5> Organization Trends </h5>
              </div>
              <div className="filter-data">
                <label> Trend Size Better Than</label>
                <SelectField
                  className="select sort-by"
                  onChange={handleTrendOrganizationMagnitudeChange}
                  value={handleTrendOrganizationMagnitudeValue(
                    searchParams.trend_organization_magnitude_gt
                  )}
                >
                  <MenuItem value="very_high" primaryText="Very Large" />
                  <MenuItem value="high" primaryText="Large" />
                  <MenuItem value="medium" primaryText="Medium" />
                  <MenuItem value="all" primaryText="View all" />
                </SelectField>
              </div>
              <div className="filter-data">
                <label>Trend Traction Better Than</label>
                <SelectField
                  className="select sort-by"
                  onChange={handleTrendOrganizationBetaChange}
                  value={handleTrendOrganizationBetaValue(
                    searchParams.trend_organization_beta_gt
                  )}
                >
                  <MenuItem value="very_high" primaryText="Very Increasing" />
                  <MenuItem value="high" primaryText="Increasing" />
                  <MenuItem value="medium" primaryText="Flat" />
                  <MenuItem value="all" primaryText="View all" />
                </SelectField>
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="highlights-icon" />
                <h5> Highlights </h5>
              </div>
              <div className="filter-data">
                <SelectField
                  multiple={true}
                  fullWidth={true}
                  hintText="Select a highlight category"
                  value={highlights}
                  onChange={handleHighlightChange}
                >
                  {Object.keys(insights).map((key) => (
                    <MenuItem
                      key={key}
                      insetChildren={true}
                      checked={highlights && highlights.indexOf(key) > -1}
                      value={key}
                      rightIcon={insights[key].icon}
                      primaryText={insights[key].name}
                    />
                  ))}
                </SelectField>
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="calendar" />
                <h5> Date </h5>
              </div>
              <div className="filter-data">
                <label> Founded </label>
                <div className="date-container">
                  <DatePicker
                    className="date-picker"
                    hintText="FROM"
                    value={toMoment(searchParams.founded_on_after)}
                    onChange={handleFoundedOnAfterChange}
                    autoOk={true}
                  />
                  <If condition={searchParams.founded_on_after}>
                    <IconButton
                      onClick={() => {
                        handleFoundedOnAfterChange(null, null);
                      }}
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>
                  <DatePicker
                    className="date-picker"
                    hintText="TO"
                    value={toMoment(searchParams.founded_on_before)}
                    onChange={handleFoundedOnBeforeChange}
                    autoOk={true}
                  />
                  <If condition={searchParams.founded_on_before}>
                    <IconButton
                      onClick={() => {
                        handleFoundedOnBeforeChange(null, null);
                      }}
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>
                </div>
              </div>
              <div className="filter-data">
                <label> Created </label>
                <div className="date-container">
                  <DatePicker
                    className="date-picker"
                    hintText="FROM"
                    value={toMoment(searchParams.created_at_after)}
                    onChange={handleCreatedAtAfterChange}
                    autoOk={true}
                  />
                  <If condition={searchParams.created_at_after}>
                    <IconButton
                      onClick={() => {
                        handleCreatedAtAfterChange(null, null);
                      }}
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>

                  <DatePicker
                    className="date-picker"
                    hintText="TO"
                    value={toMoment(searchParams.created_at_before)}
                    onChange={handleCreatedAtBeforeChange}
                    autoOk={true}
                  />
                  <If condition={searchParams.created_at_before}>
                    <IconButton
                      onClick={() => {
                        handleCreatedAtBeforeChange(null, null);
                      }}
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>
                </div>
              </div>
              <div className="filter-data">
                <label> Analyst decision </label>
                <div className="date-container">
                  <DatePicker
                    className="date-picker"
                    hintText="FROM"
                    value={toMoment(searchParams.user_decision_made_gt)}
                    onChange={(defaultParam, moment) =>
                      handleChanges([
                        {
                          key: "user_decision_made_gt",
                          value: moment ? moment.valueOf() : undefined,
                        },
                        { key: "has_user_decisions", value: "true" },
                        { key: "by_client", value: client },
                      ])
                    }
                    autoOk={true}
                  />
                  <If condition={searchParams.user_decision_made_gt}>
                    <IconButton
                      onClick={() =>
                        handleChanges([
                          { key: "user_decision_made_gt", value: null },
                          { key: "has_user_decisions", value: null },
                          { key: "by_client", value: null },
                        ])
                      }
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>

                  <DatePicker
                    className="date-picker"
                    hintText="TO"
                    value={toMoment(searchParams.user_decision_made_lt)}
                    onChange={(defaultParam, moment) =>
                      handleChanges([
                        {
                          key: "user_decision_made_lt",
                          value: moment ? moment.valueOf() : undefined,
                        },
                        { key: "has_user_decisions", value: "true" },
                        { key: "by_client", value: client },
                      ])
                    }
                    autoOk={true}
                  />
                  <If condition={searchParams.user_decision_made_lt}>
                    <IconButton
                      onClick={() =>
                        handleChanges([
                          { key: "user_decision_made_lt", value: null },
                          { key: "has_user_decisions", value: null },
                          { key: "by_client", value: null },
                        ])
                      }
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>
                </div>
              </div>
              <div className="filter-data">
                <label> Partner decision </label>
                <div className="date-container">
                  <DatePicker
                    className="date-picker"
                    hintText="FROM"
                    value={toMoment(searchParams.decision_made_gt)}
                    onChange={(defaultParam, moment) =>
                      handleChanges([
                        {
                          key: "decision_made_gt",
                          value: moment ? moment.valueOf() : undefined,
                        },
                        { key: "by_client", value: client },
                      ])
                    }
                    autoOk={true}
                  />
                  <If condition={searchParams.decision_made_gt}>
                    <IconButton
                      onClick={() =>
                        handleChanges([
                          { key: "decision_made_gt", value: null },
                          { key: "by_client", value: null },
                        ])
                      }
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>

                  <DatePicker
                    className="date-picker"
                    hintText="TO"
                    value={toMoment(searchParams.decision_made_lt)}
                    onChange={(defaultParam, moment) =>
                      handleChanges([
                        {
                          key: "decision_made_lt",
                          value: moment ? moment.valueOf() : undefined,
                        },
                        { key: "by_client", value: client },
                      ])
                    }
                    autoOk={true}
                  />
                  <If condition={searchParams.decision_made_lt}>
                    <IconButton
                      onClick={() =>
                        handleChanges([
                          { key: "decision_made_lt", value: null },
                          { key: "by_client", value: null },
                        ])
                      }
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>
                </div>
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="money" />
                <h5> Investment </h5>
              </div>
              <div className="filter-data">
                <label> Investment Amount </label>
                <div>
                  <TextField
                    className="input"
                    id="investment_gt"
                    hintText="$ greater than..."
                    underlineFocusStyle={{ borderColor: "#337ab7" }}
                    defaultValue={searchParams.investment_gt}
                    onChange={handleInvestmentGreaterThanChange}
                  />
                  <TextField
                    className="input"
                    id="investment_lt"
                    hintText="$ less than..."
                    underlineFocusStyle={{ borderColor: "#337ab7" }}
                    defaultValue={searchParams.investment_lt}
                    onChange={handleInvestmentLessThanChange}
                  />
                </div>
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="map-signs" />
                <h5> Decision </h5>
              </div>
              <div className="filter-data center states-filter">
                <label> Partner Decision </label>
                <Divider />
                <StateFilter
                  state={searchParams.state}
                  onStateChange={handleStateChange}
                  client={client}
                  optionalState={"unseen"}
                  showIcons={true}
                  showUnseen={true}
                />
              </div>
              <If condition={userHasFullAccess}>
                <div className="filter-data center states-filter">
                  <label> Analyst Decision </label>
                  <Divider />
                  <StateFilter
                    state={getTransitionalState}
                    onStateChange={handleTransitionalStateChange}
                    client={client}
                    optionalState={"unseen"}
                    analystFilter={true}
                    showIcons={true}
                    showUnseen={true}
                  />
                </div>
                <Choose>
                  <When condition={getTransitionalState()}>
                    <div className="filter-data stages-streak">
                      <label> Select Analyst </label>
                      <Divider />
                      <SelectField
                        className="select select-user"
                        value={searchParams.by_user}
                        onChange={(event, index, value) =>
                          handleChange("by_user", value)
                        }
                      >
                        {ACTIVE_TEAM.map((teamMember) => (
                          <MenuItem
                            value={teamMember.email}
                            primaryText={teamMember.name}
                            key={teamMember.name}
                          />
                        ))}
                      </SelectField>
                    </div>
                  </When>
                  <When condition={searchParams.state === "contact"}>
                    <div className="filter-data stages-streak">
                      <label> Assigned To </label>
                      <Divider />
                      <SelectField
                        className="select select-user"
                        value={searchParams.assigned_to}
                        onChange={(event, index, value) =>
                          handleChange("assigned_to", value)
                        }
                      >
                        {ACTIVE_TEAM.map((teamMember) => (
                          <MenuItem
                            value={teamMember.email}
                            primaryText={teamMember.name}
                            key={teamMember.name}
                          />
                        ))}
                      </SelectField>
                    </div>
                    <div className="filter-data stages-streak">
                      <label> Organizer </label>
                      <Divider />
                      <SelectField
                        className="select select-user"
                        value={searchParams.organizer}
                        onChange={(event, index, value) =>
                          handleChange("organizer", value)
                        }
                      >
                        {ACTIVE_TEAM.map((teamMember) => (
                          <MenuItem
                            value={teamMember.email}
                            primaryText={teamMember.name}
                            key={teamMember.name}
                          />
                        ))}
                      </SelectField>
                    </div>
                  </When>
                </Choose>
              </If>
              <If condition={userHasFullAccess}>
                <div className="filter-data center states-filter">
                  <StagesStreak
                    stage={searchParams.stage}
                    handleStageChange={handleStageChange}
                    ignoreDisabled={true}
                  />
                </div>
              </If>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="place-icon" />
                <h5> Location </h5>
              </div>
              <div className="filter-data">
                <label> Continent </label>
                <SelectField
                  className="select"
                  id="based_in_continent"
                  value={searchParams.based_in_continent}
                  onChange={handleContinentChange}
                >
                  <MenuItem value={undefined} primaryText="Any Continent" />
                  {LOCATIONS.continents.map(
                    function (continent) {
                      return (
                        <MenuItem
                          key={continent}
                          value={continent}
                          primaryText={continent}
                        />
                      );
                    }.bind(this)
                  )}
                </SelectField>
              </div>
              <If condition={searchParams.based_in_continent}>
                <div className="filter-data">
                  <CountriesFilter
                    continent={searchParams.based_in_continent}
                    countries={searchParams.based_in_country}
                    handleCountriesChange={handleCountriesChange}
                  />
                </div>
              </If>
              <If
                condition={
                  searchParams.based_in_country &&
                  searchParams.based_in_country.split(",").length === 1
                }
              >
                <div className="filter-data">
                  <label> City </label>
                  <TextField
                    className="input"
                    id="based_in_city"
                    hintText="Type the city here..."
                    underlineFocusStyle={{ borderColor: "#337ab7" }}
                    defaultValue={searchParams.based_in_city}
                    onChange={handleCityChange}
                  />
                </div>
              </If>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="money" />
                <h5> investor </h5>
              </div>
              <div className="filter-data">
                <label> Investor name </label>
                <TextField
                  className="input"
                  id="investor"
                  hintText="Type the investor name here..."
                  underlineFocusStyle={{ borderColor: "#337ab7" }}
                  defaultValue={searchParams.investor}
                  onChange={handleInvestorChange}
                />
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="cloud" />
                <h5> source </h5>
              </div>
              <div className="filter-data">
                <label> Source name </label>
                <SelectField
                  className="select"
                  value={searchParams.source}
                  onChange={handleSourceChange}
                >
                  {SOURCES.map(({ value, label }) => (
                    <MenuItem key={value} value={value} primaryText={label} />
                  ))}
                </SelectField>
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="event" />
                <h5> Event </h5>
              </div>
              <div className="filter-data">
                <label> Event name </label>
                <TextField
                  className="input"
                  id="event"
                  hintText="Type the event name here..."
                  underlineFocusStyle={{ borderColor: "#337ab7" }}
                  defaultValue={searchParams.event}
                  onChange={handleEventChange}
                />
              </div>
            </div>
          </Paper>
          <If condition={userHasFullAccess}>
            <Paper zDepth={1} className="paper">
              <div className="filter-section">
                <div className="title">
                  <div className="flag" />
                  <h5> Inbox </h5>
                </div>
                <div className="filter-data">
                  <label> Show inbox </label>
                  <div className="toggle">
                    <InReachToggle
                      toggled={searchParams.inbox === "true"}
                      onToggle={handleInboxChange}
                    />
                  </div>
                </div>
              </div>
            </Paper>
          </If>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="classification" />
                <h5> classification </h5>
              </div>
              <div className="filter-data">
                <StateFilter
                  state={searchParams.classification}
                  onStateChange={handleClassificationChange}
                  client={client}
                  showIcons={true}
                  optionalState={"na"}
                  showUnseen={true}
                />
              </div>
              <div className="filter-data">
                <label> hide spam </label>
                <div className="toggle">
                  <InReachToggle
                    toggled={
                      (searchParams.classification_not || "").indexOf("never") >
                      -1
                    }
                    onToggle={(e, toggled) => {
                      handleSpamChange(toggled);
                    }}
                  />
                </div>
              </div>
              <div className="filter-data">
                <label> Requires More Info </label>
                <div className="toggle">
                  <InReachToggle
                    toggled={searchParams.requires_more_info === "true"}
                    onToggle={(e, toggled) => {
                      handleRequiresMoreInfoChange(toggled);
                    }}
                  />
                </div>
              </div>
              <div className="filter-data">
                <label> Show Only Incomplete </label>
                <div className="toggle">
                  <InReachToggle
                    toggled={searchParams.is_complete === "false"}
                    onToggle={(e, toggled) => {
                      handleIsCompleteChange(toggled);
                    }}
                  />
                </div>
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="cogs" />
                <h5> Predictions </h5>
              </div>
              <div className="filter-data">
                <label> Show predictions </label>
                <div className="toggle">
                  <InReachToggle
                    toggled={!!searchParams.show_predictions}
                    onToggle={(e, toggled) => {
                      handleShowPredictionChange(toggled);
                    }}
                  />
                </div>
              </div>
              <div className="filter-data">
                <label> Show classifications </label>
                <div className="toggle">
                  <InReachToggle
                    toggled={!!searchParams.show_classifications}
                    onToggle={(e, toggled) => {
                      handleShowClassificationsChange(toggled);
                    }}
                  />
                </div>
              </div>
              <div className="filter-data">
                <label> Never </label>
                <div className="date-container">
                  <TextField
                    className="input"
                    id="never_prediction_gt"
                    hintText="% NEVER >"
                    underlineFocusStyle={{ borderColor: "#337ab7" }}
                    defaultValue={displayPrediction(
                      searchParams.never_prediction_gt
                    )}
                    onChange={handleNeverPredictionGreaterThanChange}
                  />
                  <TextField
                    className="input"
                    id="never_prediction_lt"
                    hintText="% NEVER <"
                    underlineFocusStyle={{ borderColor: "#337ab7" }}
                    defaultValue={displayPrediction(
                      searchParams.never_prediction_lt
                    )}
                    onChange={handleNeverPredictionLessThanChange}
                  />
                </div>
              </div>
              <div className="filter-data">
                <label> Not Now </label>
                <div className="date-container">
                  <TextField
                    className="input"
                    id="not_now_prediction_gt"
                    hintText="% NOT NOW >"
                    underlineFocusStyle={{ borderColor: "#337ab7" }}
                    defaultValue={displayPrediction(
                      searchParams.not_now_prediction_gt
                    )}
                    onChange={handleNotNowPredictionGreaterThanChange}
                  />
                  <TextField
                    className="input"
                    id="not_now_prediction_lt"
                    hintText="% NOT NOW <"
                    underlineFocusStyle={{ borderColor: "#337ab7" }}
                    defaultValue={displayPrediction(
                      searchParams.not_now_prediction_lt
                    )}
                    onChange={handleNotNowPredictionLessThanChange}
                  />
                </div>
              </div>
              <div className="filter-data">
                <label> In Progress </label>
                <div className="date-container">
                  <TextField
                    className="input"
                    id="contact_prediction_gt"
                    hintText="% IN PROGRESS >"
                    underlineFocusStyle={{ borderColor: "#337ab7" }}
                    defaultValue={displayPrediction(
                      searchParams.contact_prediction_gt
                    )}
                    onChange={handleContactPredictionGreaterThanChange}
                  />
                  <TextField
                    className="input"
                    id="contact_prediction_lt"
                    hintText="% IN PROGRESS <"
                    underlineFocusStyle={{ borderColor: "#337ab7" }}
                    defaultValue={displayPrediction(
                      searchParams.contact_prediction_lt
                    )}
                    onChange={handleContactPredictionLessThanChange}
                  />
                </div>
              </div>
            </div>
          </Paper>
          <div className="export-csv-container">
            <ExportToCSV exportToCSV={exportToCSV} />
          </div>
          <div className="save-button-container">
            <RaisedButton
              backgroundColor="white"
              labelColor="#5DAB49"
              label="Save as default filters"
              onClick={saveCurrentParamsAsDefaultFilters}
              icon={<ContentSave />}
            />
          </div>
        </div>
      </div>
    );
  }
}
