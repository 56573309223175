import PropTypes from "prop-types";
import React from "react";
import { Card, CardHeader, CardText } from "material-ui/Card";
import _ from "underscore";

import Task from "./Task";

export default class Category extends React.Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    categoryTitle: PropTypes.string.isRequired,
    categoryIcon: PropTypes.object.isRequired,
    initiallyExpanded: PropTypes.bool.isRequired,
  };

  render() {
    const { categoryTitle, category, initiallyExpanded, categoryIcon } =
      this.props;
    return (
      <div className="category">
        <Card initiallyExpanded={initiallyExpanded}>
          <CardHeader
            actAsExpander={true}
            className="category-header"
            showExpandableButton={true}
            titleStyle={{ fontWeight: "bold", fontSize: "18px" }}
          >
            <div className="header-icon-title">
              {categoryIcon}
              <p className="category-title">
                <strong>{categoryTitle}</strong>
              </p>
            </div>
          </CardHeader>
          <CardText expandable={true} style={{ padding: "0" }}>
            {_.sortBy(Object.values(category), "order").map((task) => {
              return <Task key={task.id} task={task} {...this.props} />;
            })}
          </CardText>
        </Card>
      </div>
    );
  }
}
