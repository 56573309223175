import React from "react";
import SocialWhatshot from "material-ui/svg-icons/social/whatshot";
import SocialDomain from "material-ui/svg-icons/social/domain";
import ActionTrendingUp from "material-ui/svg-icons/action/trending-up";
import SocialPerson from "material-ui/svg-icons/social/person";
import SocialPersonAdd from "material-ui/svg-icons/social/person-add";
import SocialSchool from "material-ui/svg-icons/social/school";
import EditorAttachMoney from "material-ui/svg-icons/editor/attach-money";
import ActionBuild from "material-ui/svg-icons/action/build";
import AlertError from "material-ui/svg-icons/alert/error";
import AlertWarning from "material-ui/svg-icons/alert/warning";

export default {
  keywords: {
    icon: <SocialWhatshot className="highlight-icon" />,
    name: "Keyword",
  },
  experience: {
    icon: <SocialDomain className="highlight-icon" />,
    name: "Experience of the team",
  },
  trend: {
    icon: <ActionTrendingUp className="highlight-icon" />,
    name: "Trend",
  },
  serial_entrepreneur: {
    icon: <SocialPerson className="highlight-icon" />,
    name: "Serial Entrepreneur",
  },
  investors: {
    icon: <EditorAttachMoney className="highlight-icon" />,
    name: "Investors",
  },
  education: {
    icon: <SocialSchool className="highlight-icon" />,
    name: "Education",
  },
};
