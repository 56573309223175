import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { RaisedButton } from "material-ui";

import labels from "../../../config/structured_feedback";
import structuredFeedbackTypes from "../../../config/structured_feedback_types";
import StructuredFeedbackStore from "../../../storage/StructuredFeedbackStore";
import DialogWrapper from "../../components/DialogWrapper";
import moment from "moment";
import _ from "underscore";
import Config from "../../../config";

const supportedReducedStates = new Set(
  labels
    .flatMap((label) => Object.keys(label))
    .filter((key) => key.endsWith("_name"))
    .map((key) => key.replace("_name", ""))
);
const labelsByKey = labels.reduce((acc, label) => {
  acc[label.key] = label;
  return acc;
}, {});

function reducedNameKey(type) {
  return `${type.toLowerCase()}_name`;
}

function shouldWeOpen(structuredFeedbackType, feedback) {
  if (structuredFeedbackType === true || structuredFeedbackType === false) {
    return structuredFeedbackType;
  }
  if (self.innerWidth < 1024) {
    return false;
  }
  // Uncomment this to stop the modal from opening if there has already been structured feedback
  // if (Object.keys(feedback).length !== 0) {
  //   return false;
  // }

  return supportedReducedStates.has(structuredFeedbackType);
}

function labelName(label, type) {
  if (areWeInReducedMode(type)) {
    return label[reducedNameKey(type)];
  }
  return label.name;
}

function areWeInReducedMode(structuredFeedbackType) {
  return typeof structuredFeedbackType === "string";
}

function calculateLabelsBySectionForType(type) {
  if (areWeInReducedMode(type)) {
    return {
      undefined: {
        undefined: labels.filter((label) => label[reducedNameKey(type)]),
      },
    };
  }

  return labels.reduce((acc, label) => {
    if (!acc[label.section]) {
      acc[label.section] = [];
    }
    if (!acc[label.section][label.sub_section]) {
      acc[label.section][label.sub_section] = [];
    }
    acc[label.section][label.sub_section].push(label);
    return acc;
  }, []);
}

function sectionName(section) {
  return section
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const structuredFeedbackStore = new StructuredFeedbackStore();
const mutuallyExclusiveLabelsByType = labels.reduce((acc, feedback) => {
  if (!feedback.feedback_type) {
    return acc;
  }
  if (!(feedback.feedback_type in structuredFeedbackTypes)) {
    return acc;
  }
  if (!structuredFeedbackTypes[feedback.feedback_type].exclusive) {
    return acc;
  }
  if (!acc[feedback.feedback_type]) {
    acc[feedback.feedback_type] = new Set();
  }

  acc[feedback.feedback_type].add(feedback.key);
  return acc;
}, {});

function calculateCurrentLabels(previousLabels, newLabel) {
  if (newLabel.key in previousLabels) {
    return _.omit(previousLabels, newLabel.key);
  }
  if (
    newLabel.feedback_type &&
    newLabel.feedback_type in mutuallyExclusiveLabelsByType
  ) {
    return {
      ..._.omit(previousLabels, [
        ...mutuallyExclusiveLabelsByType[newLabel.feedback_type],
      ]),
      [newLabel.key]: newLabel,
    };
  }
  return {
    ...previousLabels,
    [newLabel.key]: newLabel,
  };
}

function StructuredFeedback(props) {
  const {
    displayFeedbackButton = true,
    structuredFeedbackType,
    handleOpen,
    handleClose,
    organization,
    userId,
    handleError,
  } = props;
  const [feedback, setFeedback] = useState({});
  const [loading, setLoading] = useState(displayFeedbackButton);
  const labelsBySection = calculateLabelsBySectionForType(
    structuredFeedbackType
  );

  if (!Config.structured_feedback.is_enabled) {
    return <React.Fragment />;
  }

  if (displayFeedbackButton) {
    useEffect(() => {
      structuredFeedbackStore
        .getStructuredFeedbackForOrganization(organization.id)
        .then((structuredFeedback) => {
          setFeedback(
            structuredFeedback.reduce((acc, sf) => {
              if (sf.label in labelsByKey) {
                acc[sf.label] = labelsByKey[sf.label];
              }
              return acc;
            }, {})
          );
          setLoading(false);
        })
        .catch((error) => {
          console.error(`Problem getting feedback`, error);
          setLoading(false);
          if (areWeInReducedMode(structuredFeedbackType)) {
            handleClose();
          }
          handleError(error);
        });
    }, []);
  }

  function handleLabelClick(section, subSection, label) {
    setLoading(true);
    structuredFeedbackStore
      .createStructuredFeedback({
        primary_model_type: "ORGANIZATION",
        primary_model_id: organization.id,
        created_at: moment.utc().toISOString(),
        external_snapshot_type: "ORGANIZATION_SNAPSHOT",
        feedback_type: label.feedback_type,
        label: label.key,
        user_id: userId,
        removed: label.key in feedback,
      })
      .then((savedLabel) => {
        setFeedback((previousLabels) =>
          calculateCurrentLabels(previousLabels, label)
        );
        setLoading(false);
        if (areWeInReducedMode(structuredFeedbackType)) {
          setTimeout(() => {
            handleClose();
          }, 200);
        }
      })
      .catch((error) => {
        console.error(`Problem saving label: ${label.key}`, error);
        setLoading(false);
        if (areWeInReducedMode(structuredFeedbackType)) {
          handleClose();
        }
        handleError(error);
      });
  }

  return (
    <React.Fragment>
      <Choose>
        <When condition={displayFeedbackButton}>
          <RaisedButton
            label={loading ? "Loading..." : "Feedback"}
            backgroundColor={
              Object.keys(feedback).filter((key) => !feedback[key].removed)
                .length > 0
                ? "lightgrey"
                : "white"
            }
            onClick={handleOpen}
            className="structured-feedback-button"
            style={self.innerWidth < 1024 ? { display: "none" } : {}}
            disabled={loading}
          />
        </When>
      </Choose>
      <DialogWrapper
        open={shouldWeOpen(structuredFeedbackType, feedback)}
        onClose={handleClose}
        className="structured-feedback-modal"
        modal={true}
        actions={[
          <RaisedButton onClick={handleClose} color="primary" label="close" />,
        ]}
        repositionOnUpdate={true}
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        contentClassName={
          areWeInReducedMode(structuredFeedbackType)
            ? "structured-feedback-modal-reduced-content"
            : "structured-feedback-modal-content"
        }
        contentWidthMultiplier={
          areWeInReducedMode(structuredFeedbackType) ? 12 : 18
        }
        bodyClassName="structured-feedback-modal-body"
      >
        <h3>Structured Feedback</h3>
        <div>
          {Object.entries(labelsBySection).map(
            ([section, subSectionAndLabels]) => (
              <div key={section} className="structured-feedback-section">
                {section && section !== "undefined" ? (
                  <h4 className="structured-feedback-section-title">
                    {sectionName(section)}
                  </h4>
                ) : (
                  <span />
                )}
                {Object.entries(subSectionAndLabels).map(
                  ([subSection, labels]) => (
                    <div key={subSection}>
                      {subSection && subSection !== "undefined" ? (
                        <h5>{sectionName(subSection)}</h5>
                      ) : (
                        <span />
                      )}
                      {labels.map((label) => (
                        <RaisedButton
                          key={label}
                          label={labelName(label, structuredFeedbackType)}
                          backgroundColor={
                            label.key in feedback &&
                            !feedback[label.key].removed
                              ? "lightgrey"
                              : "white"
                          }
                          className="label"
                          onClick={() =>
                            handleLabelClick(section, subSection, label)
                          }
                          disabled={loading}
                        />
                      ))}
                    </div>
                  )
                )}
              </div>
            )
          )}
        </div>
      </DialogWrapper>
    </React.Fragment>
  );
}

StructuredFeedback.propTypes = {
  structuredFeedbackType: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  displayFeedbackButton: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  organization: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default StructuredFeedback;
