import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

import DialogWrapper from "../../components/DialogWrapper";

export default class ConfirmCloseWithoutSavingRecommendationDecisionDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  };

  render() {
    const { open, onCancel, onConfirm, type } = this.props;
    const actions = [
      <FlatButton
        label="Go Back"
        style={{
          marginRight: "12px",
        }}
        onClick={onCancel}
      />,
      <FlatButton
        label="Continue"
        style={{
          backgroundColor: "#f44336",
        }}
        labelStyle={{
          color: "#ffffff",
        }}
        onClick={onConfirm}
      />,
    ];
    return (
      <DialogWrapper
        title={`You have not saved your ${type}!`}
        modal={false}
        open={open}
        onRequestClose={onCancel}
        titleStyle={{
          paddingBottom: "6px",
          lineHeight: "28px",
        }}
        contentStyle={{
          position: "relative",
          width: "375px",
        }}
        repositionOnUpdate={true}
        actions={actions}
      >
        {`You started to create your ${type}, but you didn't save it. Perhaps there was an error? Are you sure you would like to continue?`}
      </DialogWrapper>
    );
  }
}
