import React from "react";
import PropTypes from "prop-types";

import ActionAlarm from "material-ui/svg-icons/alert/warning";

export default class NoPrimaryContact extends React.Component {
  static propTypes = {};

  render() {
    return (
      <div className="no-primary-contact">
        <ActionAlarm
          style={{ color: "#f44336" }}
          className="no-primary-contact-icon"
        />
        <div className="no-primary-contact-text">
          No primary contact selected
        </div>
      </div>
    );
  }
}
