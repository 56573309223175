import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import moment from "moment";

class FirstSeen extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
  };

  getFirstSeenString = () => {
    const { organization: { created_at = 0 } = {} } = this.props;
    return `${moment(created_at).fromNow()} (${new Date(
      created_at
    ).getFullYear()})`;
  };

  render() {
    const { organization: { created_at = 0 } = {} } = this.props;
    return (
      <div className="data-wrapper first-seen">
        <div className="data-header">First Seen</div>
        <Choose>
          <When condition={created_at === 0}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="data-content text">{this.getFirstSeenString()}</div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(FirstSeen);
