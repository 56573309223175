import React from "react";
import PropTypes from "prop-types";
import IncompleteStore from "../../storage/IncompleteStore";
import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import LinearProgress from "material-ui/LinearProgress";
import DialogWrapper from "../components/DialogWrapper";
export class CompleteBatch extends React.Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    batchName: PropTypes.string.isRequired,
  };

  state = {
    uploading: false,
    error: null,
  };

  incompleteStore = new IncompleteStore();

  setRef = (el) => (this._csvimport = el);

  upload = () => {
    this.setState({ uploading: true });

    const reader = new FileReader(),
      { batchName, close } = this.props;
    reader.onload = (event) => {
      this.incompleteStore
        .uploadCompleted(batchName, event.target.result)
        .then(() => {
          this.setState({
            uploading: false,
          });
          close();
        })
        .catch((error) => {
          console.error("Error", error);
          this.setState({ error });
        });
    };
    reader.onerror = (error) => {
      console.error("Error", error);
      this.setState({ error });
    };
    reader.readAsText(this._csvimport.files[0]);
  };

  render() {
    const { batchName, close } = this.props,
      { uploading, error } = this.state;

    return (
      <DialogWrapper
        title={`Upload a completed CSV for ${batchName}`}
        actions={[
          <RaisedButton
            key="cancel"
            label="Cancel"
            primary={false}
            onClick={close}
            style={{ marginRight: "12px" }}
            disabled={false}
          />,
        ]}
        modal={true}
        className="quick-add"
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        repositionOnUpdate={true}
        open={true}
      >
        <Choose>
          <When condition={error}>
            <p>Error: {JSON.stringify(error)}</p>
          </When>

          <When condition={uploading}>
            <LinearProgress />
          </When>

          <Otherwise>
            <input
              ref={this.setRef}
              type="file"
              onChange={this.upload}
              accept=".csv"
            />
          </Otherwise>
        </Choose>
      </DialogWrapper>
    );
  }
}
