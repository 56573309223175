import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { RaisedButton } from "material-ui";

import DialogWrapper from "../../components/DialogWrapper";
import moment from "moment";
import _ from "underscore";
import Config from "../../../config";
import WorkflowSyncStore from "../../../storage/WorkflowSyncStore";
import auth from "../../../storage/Auth";
import request from "superagent";

const workflowSyncStore = new WorkflowSyncStore();

function WorkflowSync(props) {
  const { handleClose, open, organization, userId, handleError } = props;
  const { source_refs: { HUBSPOT: [organizationHubspotId] = [] } = {} } =
    organization;
  const [syncing, setSyncing] = useState(false);
  const [companyId, setCompanyId] = useState(organizationHubspotId);
  const [syncStatus, setSyncStatus] = useState("");

  if (!Config.workflow_sync.is_enabled) {
    return <React.Fragment />;
  }

  function handleSyncClick() {
    setSyncing(true);
    setSyncStatus("Syncing...");

    let intervalId;
    const timeoutId = setTimeout(() => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      setSyncing(false);
      setSyncStatus("Sync timed out.");
    }, 3 * 60 * 1000);

    workflowSyncStore
      .syncOrganization(organization)
      .then(() => {
        intervalId = setInterval(() => {
          checkSyncStatus(organization.id)
            .then((companyId) => {
              if (companyId) {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
                setSyncing(false);
                setCompanyId(companyId);
                setSyncStatus("Sync successful");
                setTimeout(() => {
                  handleClose();
                }, 200);
              } else {
                setSyncStatus((previousSyncStatus) => previousSyncStatus + ".");
              }
            })
            .catch((error) => {
              console.error(
                `Problem retrieving organization: ${organization.id}`,
                error
              );
              clearInterval(intervalId);
              clearTimeout(timeoutId);
              setSyncing(false);
              setSyncStatus("Error: " + error.message);
            });
        }, 2000);
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        setSyncing(false);
        setSyncStatus("Error: " + error.message);
      });
  }

  return (
    <React.Fragment>
      <DialogWrapper
        open={open}
        onClose={handleClose}
        className="workflow-sync-modal"
        modal={true}
        actions={[
          <RaisedButton onClick={handleClose} color="primary" label="close" />,
        ]}
        repositionOnUpdate={true}
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        contentClassName="workflow-sync-modal-content"
        bodyClassName="workflow-sync-modal-body"
      >
        <h3>Sync to Hubspot</h3>
        <Choose>
          <When condition={!!companyId}>
            <a
              href={`https://app-eu1.hubspot.com/contacts/25532501/record/0-2/${companyId}`}
              target="_blank"
            >
              This organization is already synced to Hubspot.
            </a>
          </When>
        </Choose>
        <p>
          {!syncing
            ? `${
                !!companyId ? "Re-" : ""
              }Syncing this organization will update the organization's data in Hubspot.`
            : syncStatus}
          <br />
          <RaisedButton
            key="sync-organization"
            label={!!companyId ? "Re-Sync" : "Sync"}
            onClick={() => handleSyncClick()}
            disabled={syncing}
          />
        </p>
      </DialogWrapper>
    </React.Fragment>
  );
}

function getHubspotId(organization) {
  const { source_refs: { HUBSPOT: [organizationHubspotId] = [] } = {} } =
    organization;
  return organizationHubspotId;
}

const checkSyncStatus = (organizationId) => {
  return auth
    .getAuthData()
    .then(({ token, client }) =>
      request
        .get(`${Config.api.host}/organizations/${organizationId}`)
        .set("Content-Type", "application/json")
        .query({ access_token: token, client })
    )
    .then((response) => {
      return getHubspotId(response.body);
    });
};

WorkflowSync.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default WorkflowSync;
