import React from "react";
import PropTypes from "prop-types";

import _ from "underscore";

import ContentEditable from "react-contenteditable";

import { getCurrentCaretPosition, setCaretPosition } from "../../../lib/caret";

import SelectStructuredContent from "./SelectStructuredContent";
import StructuredContentValue from "./StructuredContentValue";

const getActiveContentElement = () =>
  document.querySelector('span[data-editing="true"]');
const getEditingStateElement = () =>
  document.getElementById("editing-state-wrapper");

const setSpanWidths = () => {
  const elements = document.querySelectorAll(
    ".structured-text-editor--root span"
  );
  for (const element of elements) {
    if ("data-id" in element.attributes) {
      const structuredComponent = document.querySelector(
        `div[data-structured-id='${element.attributes["data-id"].nodeValue}']`
      );
      if (structuredComponent !== null) {
        element.style.width = structuredComponent.clientWidth;
      }
    }
  }
};

const isPreviousContentStructuredContent = (textContent, caretPosition) => {
  const trimmedContent = textContent.substring(0, caretPosition);
  let index = trimmedContent.length - 1;
  let colonFound = false;
  while (index >= 0) {
    switch (trimmedContent.substring(index, index + 1)) {
      case "\xa0":
        return false;
      case ":":
        if (!colonFound) {
          colonFound = true;
        } else {
          return false;
        }
        break;
      case "#":
        return colonFound;
    }
    index--;
  }
  return false;
};

const getTrimmedTextContent = (textContent, caretPosition, left) => {
  if (left) {
    const trimmedTextContent = textContent.substring(0, caretPosition);
    return trimmedTextContent.substring(
      trimmedTextContent.lastIndexOf("#"),
      trimmedTextContent.length
    );
  } else {
    return textContent.slice(caretPosition);
  }
};

const getStructuredComponent = (id) =>
  document.querySelector(`div[data-structured-id='${id}']`);

export default class StructuredTextEditor extends React.Component {
  static propTypes = {
    savedFormattedContent: PropTypes.string.isRequired,
    savedStructuredContent: PropTypes.object.isRequired,
    noShow: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    relevantQuestions: PropTypes.array,
    allQuestions: PropTypes.array,
    useStructured: PropTypes.bool.isRequired,
    section: PropTypes.string.isRequired,
    sectionIndex: PropTypes.number.isRequired,
    canEdit: PropTypes.bool.isRequired,
    expanded: PropTypes.bool.isRequired,
    callId: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
  };

  state = {
    canType: true,
    editingState: "none",
    activeStructuredContent: { id: "", key: "", value: "" },
    visibleStructuredContent: [],
  };

  contentEditable = React.createRef();

  componentDidMount() {
    setTimeout(() => {
      this.setState(
        {
          visibleStructuredContent: this.getVisibleStructuredContent(),
        },
        () => {
          // setSpanWidths();
        }
      );
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.savedFormattedContent !== this.props.savedFormattedContent ||
      !_.isEqual(
        prevProps.savedStructuredContent,
        this.props.savedStructuredContent
      ) ||
      prevProps.useStructured !== this.props.useStructured ||
      prevProps.canEdit !== this.props.canEdit ||
      prevProps.expanded !== this.props.expanded ||
      prevProps.show !== this.props.show
    ) {
      this.setState(
        {
          visibleStructuredContent: this.getVisibleStructuredContent(),
        },
        () => {
          //setSpanWidths();
        }
      );
    }
  }

  getContentEditableClassName = () => {
    const { section = "", sectionIndex = 0 } = this.props;
    const { canType = false } = this.state;
    const classNames = [
      "structured-text-editor--editor",
      `structured-text-editor--editor-${section}-${sectionIndex}`,
    ];
    if (canType) {
      classNames.push("structured-text-editor--can-type");
    }
    return classNames.join(" ");
  };

  getContentHTML = () => {
    const { savedFormattedContent = " ", noShow = false, canEdit } = this.props;
    if (canEdit) {
      return savedFormattedContent;
    } else if (noShow) {
      return "<p><strong>NO SHOW</strong></p>";
    } else {
      return savedFormattedContent !== "" &&
        savedFormattedContent !== " " &&
        savedFormattedContent !== "<p><br></p>"
        ? savedFormattedContent
        : "<p><strong>No notes recorded</strong></p>";
    }
  };

  handleContentChange = ({ target: { value = "" } = {} }) => {
    this.props.handleChange({
      formatted_content: value,
      structured_content: this.getStructuredContentFromDom(),
    });
  };

  /*handleContentChange = _.debounce(({target: {value = ''} = {}}) => {
    this.props.handleChange({
      formatted_content: value,
      structured: this.getStructuredContentFromDom()
    });
  }, 2000);*/

  getVisibleStructuredContent = () => {
    const { section = "", sectionIndex = 0 } = this.props;
    const elements = document.querySelectorAll(
      `.structured-text-editor--editor-${section}-${sectionIndex} span`
    );
    const mappedElements = [];
    for (const { offsetLeft = 0, offsetTop = 0, attributes } of elements) {
      const elementDetails = {
        top: offsetTop,
        left: offsetLeft,
      };
      for (const { name = "", value = "" } of attributes) {
        elementDetails[name.replace("data-", "")] = value;
      }
      if (
        typeof elementDetails.editing === "undefined" &&
        typeof elementDetails.id !== "undefined" &&
        typeof elementDetails.key !== "undefined"
      ) {
        mappedElements.push(elementDetails);
      }
    }
    return mappedElements;
  };

  getStructuredContentFromDom = () => {
    const { allQuestions = [], section = "", sectionIndex = 0 } = this.props;
    const elements = document.querySelectorAll(
      `.structured-text-editor--editor-${section}-${sectionIndex} span`
    );
    const structuredContent = {};
    for (const { attributes } of elements) {
      const attributesArray = [...attributes];
      if (
        typeof attributesArray.find(({ name = "" }) => name === "data-id") !==
        "undefined"
      ) {
        const values = [...attributes].reduce(
          (structuredContent, { name = "", value = "" }) => {
            const trimmedName = name.replace("data-", "");
            if (["id", "key", "value"].indexOf(trimmedName) > -1) {
              structuredContent[trimmedName] = value;
            }
            return structuredContent;
          },
          {}
        );
        const structuredContentObj = {
          uniqueId: values.id,
          content: values.value,
          originalTemplate:
            allQuestions.find(({ key }) => key === values.key) || {},
        };
        if (typeof structuredContent[values.key] !== "undefined") {
          structuredContent[values.key].push(structuredContentObj);
        } else {
          structuredContent[values.key] = [structuredContentObj];
        }
      }
    }
    return structuredContent;
  };

  handleKeyDown = (event) => {
    switch (event.key) {
      case "Backspace":
      case "ArrowLeft":
      case "ArrowRight":
      case "ArrowUp":
      case "ArrowDown":
        this.handleMovementKeyDown(event);
        break;
      case "#":
        setTimeout(() => {
          this.addDefaultStructuredContent();
        }, 10);
        break;
    }
  };

  handleMovementKeyDown = (event) => {
    const { section = "", sectionIndex = 0 } = this.props;
    const caretPosition = getCurrentCaretPosition(
      `structured-text-editor--editor-${section}-${sectionIndex}`
    );
    const left = ["Backspace", "ArrowLeft", "ArrowUp"].includes(event.key);
    const textContent = document.getElementsByClassName(
      `structured-text-editor--editor-${section}-${sectionIndex}`
    )[0].textContent;
    if (
      (left &&
        isPreviousContentStructuredContent(textContent, caretPosition - 1)) ||
      (!left && textContent.substring(caretPosition, caretPosition + 1) === "#")
    ) {
      event.preventDefault();
      const { id, key, value } = this.getNextStructuredContent(
        textContent,
        caretPosition,
        left
      );
      this.handleEditCompletedStructuredContentValue(id, key, value);
    }
  };

  getNextStructuredContent = (textContent, caretPosition, left) => {
    const { savedStructuredContent = {} } = this.props;
    // DEBUG: https://regex101.com/r/YAmIg2/1
    const id = /(?:#)(.+?)(?=:)/.exec(
      getTrimmedTextContent(textContent, caretPosition, left)
    )[1];
    const key = /(.+)(?=_)/.exec(id)[0];
    const { content: value = "" } = savedStructuredContent[key].find(
      ({ uniqueId }) => uniqueId === id
    );
    return {
      id,
      key,
      value,
    };
  };

  addDefaultStructuredContent = () => {
    const {
      savedFormattedContent = "",
      savedStructuredContent = {},
      handleChange,
    } = this.props;
    const caretPosition = savedFormattedContent.match(
      /#(?![a-zA-Z0-9.\-_:£$€&;+()=,<>?*^%#@!\/ ]*?<\/span>)/
    ).index;
    handleChange({
      formatted_content: `${savedFormattedContent.slice(
        0,
        caretPosition
      )}<span data-editing="true" data-editing-key="true" class="structured-content-span">#</span>${savedFormattedContent.slice(
        caretPosition + 1
      )}`,
      structured_content: savedStructuredContent,
    }).then(() => {
      this.setEditingKey();
    });
  };

  handleRemoveActiveStructuredContent = () => {
    const {
      savedFormattedContent = "",
      savedStructuredContent = {},
      handleChange,
    } = this.props;
    handleChange({
      formatted_content: savedFormattedContent.replace(
        /(<span data-editing="true".*?>.+?<\/span>)/,
        '<div id="target"></div>$1'
      ),
      structured_content: savedStructuredContent,
    }).then(() => {
      this.changeActiveStructuredContent(true, "").then(() => {
        this.setActiveStructuredContentValues("", "", "");
        this.setEditingNone(null, false);
      });
    });
  };

  handleStructuredContentSelected = (key) => {
    const { savedStructuredContent: { [key]: structuredContent = [] } = {} } =
      this.props;
    const id = `${key}_${structuredContent.length + 1}`;
    this.changeActiveStructuredContent(
      true,
      `<span data-editing="true" data-id="${id}" data-key="${key}" class="structured-content-span">#${key}:</span>&nbsp;`
    ).then(() => {
      this.setActiveStructuredContentValues(id, key, "");
      this.setEditingValue();
    });
  };

  changeActiveStructuredContent = (
    currentlyActive,
    newStructuredContent,
    id = ""
  ) =>
    new Promise((resolve) => {
      const { savedFormattedContent = "", handleChange } = this.props;
      const findRegEx = currentlyActive
        ? /<span data-editing="true".*?>.+?<\/span>/
        : new RegExp(`<span data-id="${id}".*?>.+?<\/span>`);
      handleChange({
        formatted_content: savedFormattedContent.replace(
          findRegEx,
          newStructuredContent
        ),
        structured_content: this.getStructuredContentFromDom(),
      }).then(() => {
        resolve();
      });
    });

  setActiveStructuredContentValues = (
    id = this.state.activeStructuredContent.id,
    key,
    value
  ) => {
    this.setState({
      activeStructuredContent: {
        id,
        key,
        value,
      },
    });
  };

  handleStructuredContentValueConfirm = () => {
    const { activeStructuredContent: { id = "", key = "", value = "" } = {} } =
      this.state;
    const newStructuredContent = `<span data-id="${id}" data-key="${key}" data-value="${value}" class="structured-content-span" style="width:${
      getStructuredComponent(id).clientWidth
    }px">#${id}:${value}</span>&nbsp;`;
    this.changeActiveStructuredContent(true, newStructuredContent).then(() => {
      this.setEditingNone(
        document.querySelector(`span[data-id='${id}']`).nextSibling,
        true
      );
      this.setActiveStructuredContentValues("", "", "");
    });
  };

  handleEditCompletedStructuredContentValue = (id, key, value) => {
    this.changeActiveStructuredContent(
      false,
      `<span data-editing="true" data-id="${id}" data-key="${key}" data-value="${value}" class="structured-content-span" style="width:${
        getStructuredComponent(id).clientWidth
      }px">#${id}:${value}</span>&nbsp;`,
      id
    ).then(() => {
      this.setActiveStructuredContentValues(id, key, value);
      this.setEditingValue();
      const {
        savedFormattedContent = "",
        savedStructuredContent = {},
        handleChange,
      } = this.props;
      handleChange({
        formatted_content: savedFormattedContent.replace(
          '<div id="target"></div>',
          ""
        ),
        structured_content: savedStructuredContent,
      });
    });
  };

  setEditingKey = () => {
    this.toggleCanType(false, "key").then(() => {
      const activeContentElement = getActiveContentElement();
      const editingStateElement = getEditingStateElement();
      editingStateElement.style.left = `${
        activeContentElement.offsetLeft + 8
      }px`;
      editingStateElement.style.top = `${activeContentElement.offsetTop + 1}px`;
      document.getElementById("select-structured-content-input-field").focus();
    });
  };

  setEditingValue = () => {
    this.toggleCanType(false, "value").then(() => {
      const activeContentElement = getActiveContentElement();
      const editingStateElement = getEditingStateElement();
      editingStateElement.style.left = `${activeContentElement.offsetLeft}px`;
      editingStateElement.style.top = `${activeContentElement.offsetTop}px`;
      document.getElementById("structured-content-value-input-field").focus();
    });
  };

  setEditingNone = (selectNode = null, useParamNode = false) => {
    return new Promise((resolve) => {
      this.toggleCanType(true, "none").then(() => {
        if ((useParamNode && selectNode !== null) || !useParamNode) {
          setCaretPosition(
            useParamNode
              ? selectNode
              : document.getElementById("target").nextSibling
          );
        } else {
          this.contentEditable.current.focus();
        }
        resolve();
      });
    });
  };

  toggleCanType = (canType = !this.state.canType, editingState = "none") =>
    new Promise((resolve) => {
      this.setState(
        {
          canType,
          editingState,
        },
        () => {
          resolve();
        }
      );
    });

  getStructuredContent = (key, id) => {
    const { savedStructuredContent = {}, allQuestions = [] } = this.props;
    return (
      allQuestions.find(({ key: contentKey }) => contentKey === key) ||
      (
        (savedStructuredContent[key] || []).find(
          ({ uniqueId }) => uniqueId === id
        ) || {}
      ).originalTemplate || {
        key,
        question: key
          .split(/[._]/)
          .slice(1)
          .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
          .join(" "),
        //question: key,
        format: "integer",
      } ||
      {}
    );
  };

  render() {
    const {
      relevantQuestions = [],
      useStructured = false,
      canEdit: propsCanEdit = false,
    } = this.props;
    const {
      canType: stateCanType = false,
      editingState = "none",
      activeStructuredContent: {
        id: activeStructuredContentId = "",
        key: activeStructuredContentKey = "",
        value: activeStructuredContentValue = "",
      } = {},
      visibleStructuredContent = [],
    } = this.state;
    if (!useStructured) {
      return (
        <div className="structured-text-editor--root">
          <ContentEditable
            innerRef={this.contentEditable}
            className={this.getContentEditableClassName()}
            html={this.getContentHTML()}
            disabled={!propsCanEdit}
            onChange={this.handleContentChange}
          />
        </div>
      );
    }
    return (
      <div className="structured-text-editor--root">
        <ContentEditable
          innerRef={this.contentEditable}
          className={this.getContentEditableClassName()}
          html={this.getContentHTML()}
          disabled={!propsCanEdit || !stateCanType}
          onChange={this.handleContentChange}
          onKeyDown={this.handleKeyDown}
        />
        <If condition={editingState === "key" || editingState === "value"}>
          <div
            className="structured-text-editor--editing-state-wrapper"
            id="editing-state-wrapper"
          >
            <Choose>
              <When condition={editingState === "key"}>
                <SelectStructuredContent
                  content={relevantQuestions.filter(({ format }) =>
                    [
                      "integer",
                      "integer-growth",
                      "money",
                      "money-growth" /*, 'header'*/,
                    ].includes(format)
                  )}
                  cancel={this.handleRemoveActiveStructuredContent}
                  selected={this.handleStructuredContentSelected}
                />
              </When>
              <When condition={editingState === "value"}>
                <StructuredContentValue
                  editing={true}
                  canEdit={propsCanEdit}
                  structuredContent={this.getStructuredContent(
                    activeStructuredContentKey,
                    activeStructuredContentId
                  )}
                  id={activeStructuredContentId}
                  value={activeStructuredContentValue}
                  change={this.setActiveStructuredContentValues}
                  confirm={this.handleStructuredContentValueConfirm}
                  cancel={this.handleRemoveActiveStructuredContent}
                />
              </When>
            </Choose>
          </div>
        </If>
        {visibleStructuredContent.map(({ top, left, id, key, value }) => (
          <div
            className="structured-text-editor--complete-structured-content-wrapper"
            style={{ top, left }}
            key={id}
          >
            <StructuredContentValue
              editing={false}
              canEdit={propsCanEdit}
              structuredContent={this.getStructuredContent(key, id)}
              id={id}
              value={value}
              edit={this.handleEditCompletedStructuredContentValue}
            />
          </div>
        ))}
      </div>
    );
  }
}
