import FlatButton from "material-ui/FlatButton";
import { List, ListItem } from "material-ui/List";
import Delete from "material-ui/svg-icons/action/delete";
import AddFile from "./AddFile";
import React from "react";
import xlsIcon from "../../../../images/xls-file-64px.png";
import docIcon from "../../../../images/doc-file-64px.png";
import pptIcon from "../../../../images/ppt-file-64px.png";
import pdfIcon from "../../../../images/pdf-file-64px.png";
import jpegIcon from "../../../../images/jpeg-file-64px.png";
import blankIcon from "../../../../images/blank-file-64px.png";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import DialogWrapper from "../../components/DialogWrapper";

export default class TaskFiles extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    deleteFile: PropTypes.func.isRequired,
    onNewQualification: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    driveFiles: PropTypes.array,
    driveFolder: PropTypes.string,
  };

  state = {
    showUploadModal: false,
    uploading: false,
  };

  buildFileUrl = (file) => {
    if (file.mime_type === "application/vnd.google-apps.spreadsheet")
      return `https://docs.google.com/spreadsheets/d/${file.drive_id}/edit`;
    if (file.mime_type === "application/vnd.google-apps.document")
      return `https://docs.google.com/document/d/${file.drive_id}/edit`;
    if (file.mime_type === "application/vnd.google-apps.presentation")
      return `https://docs.google.com/presentation/d/${file.drive_id}/edit`;
    if (file.mime_type === "application/pdf") return;
    else {
      return `https://drive.google.com/file/d/${file.drive_id}/view`;
    }
  };

  getIcon = (file) => {
    if (file.mime_type === "application/vnd.google-apps.spreadsheet")
      return xlsIcon;
    if (file.mime_type === "application/vnd.google-apps.document")
      return docIcon;
    if (file.mime_type === "application/vnd.google-apps.presentation")
      return pptIcon;
    if (file.mime_type === "application/pdf") return pdfIcon;
    if (file.mime_type === "application/png") return jpegIcon;
    else {
      return blankIcon;
    }
  };

  onFileUploaded = () => {
    this.setState({ showUploadModal: false });
  };

  onUploadingChange = (value) => {
    this.setState({ uploading: value });
  };

  render() {
    const { category, files = [], title } = this.props.task;

    const {
      organization,
      task,
      onNewQualification,
      driveFolder,
      driveFiles,
      deleteFile,
    } = this.props;
    const { showUploadModal, uploading } = this.state;
    return (
      <div className="task-files">
        <DialogWrapper
          title={`Add file to task: ${title}`}
          open={showUploadModal}
          actions={[
            <FlatButton
              key="cancel"
              label="Cancel"
              onClick={() => this.setState({ showUploadModal: false })}
              disabled={uploading}
            />,
          ]}
        >
          <AddFile
            organizationId={organization.id}
            task={task}
            onNewQualification={onNewQualification}
            onFileUploaded={this.onFileUploaded}
            driveFolder={driveFolder}
            driveFiles={driveFiles}
            onUploadingChange={this.onUploadingChange}
            uploading={uploading}
            {...this.props}
          />
        </DialogWrapper>
        <div>
          <p style={{ margin: 0 }}>
            <strong>Files</strong>
          </p>
        </div>
        <div className="section-container">
          <List>
            {files.map((file) => {
              return (
                <ListItem
                  key={file.drive_id}
                  primaryText={file.title}
                  leftAvatar={
                    <img
                      src={this.getIcon(file)}
                      alt="Logo"
                      style={{ height: "32px", width: "32px" }}
                    />
                  }
                  onClick={() => window.open(this.buildFileUrl(file), "_blank")}
                  rightIconButton={
                    <Delete
                      className="delete"
                      color="#808080"
                      onClick={() => {
                        deleteFile(category, file.task_id, file.drive_id);
                      }}
                    />
                  }
                />
              );
            })}
          </List>
          <div className="qualification-files-buttons">
            <If condition={driveFolder}>
              <a
                href={`https://drive.google.com/drive/u/1/folders/${driveFolder}`}
                target="_blank"
              >
                <RaisedButton
                  style={{
                    backgroundColor: "#337ab7",
                    marginTop: "0",
                    width: "140px",
                  }}
                  label="Drive Folder"
                />
              </a>
              <RaisedButton
                label="Add files"
                primary={true}
                style={{ marginLeft: "12px", width: "140px" }}
                onClick={() => this.setState({ showUploadModal: true })}
              />
            </If>
          </div>
        </div>
      </div>
    );
  }
}
