export default {
  inboxes: 0,
  decisions: 0,
  call_feedback: 0,
  reconnect: 0,
  tasks: 1,
  mailings: 1,
  gmail_messages: 1,
  communications: 1,
  calendar_event: 2,
  qualification: 2,
  pre_qualification: 2,
};
