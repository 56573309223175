import React from "react";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import { BuildInvestmentTypeStage } from "./InvestmentTypeStage";
import { CircularProgress } from "material-ui";

import DialogWrapper from "../components/DialogWrapper";

export default class QualificationSelectors extends React.Component {
  static propTypes = {
    onDone: PropTypes.func.isRequired,
    handlePreQualificationGoToQualificationClick: PropTypes.func.isRequired,
    isSelectingQualification: PropTypes.bool.isRequired,
    investmentValues: PropTypes.object.isRequired,
  };

  state = {
    stateInvestmentStage: null,
    stateInvestmentType: null,
    submitting: false,
  };

  componentDidMount() {
    const {
      investmentValues: {
        investmentType: { type: orgInvestmentType },
        investmentStage: { stage: orgInvestmentStage },
      },
    } = this.props;

    this.setState({
      stateInvestmentType: orgInvestmentType,
      stateInvestmentStage: orgInvestmentStage,
    });
  }

  changeInvestmentValue = (investmentInfo, value) => {
    this.setState({ [investmentInfo]: value });
  };

  render() {
    const {
      onDone,
      isSelectingQualification,
      handlePreQualificationGoToQualificationClick,
      investmentValues,
    } = this.props;
    const { stateInvestmentStage, stateInvestmentType, submitting } =
      this.state;
    const { investmentType, investmentStage } = investmentValues;

    const actions = [
      <RaisedButton
        key="cancel"
        className="edit-email-close"
        value="cancel"
        backgroundColor={"red"}
        labelColor={"#ffffff"}
        style={{ marginRight: "16px" }}
        onClick={() => {
          onDone(false);
        }}
        label={"Close"}
      />,
      <RaisedButton
        key="done"
        className="edit-email-done"
        value="done"
        primary={true}
        labelColor={"#ffffff"}
        style={{ marginRight: "16px" }}
        onClick={() => {
          this.setState({ submitting: true });
          handlePreQualificationGoToQualificationClick(
            stateInvestmentType,
            stateInvestmentStage
          ).then(() => {
            this.setState({ submitting: false });
            onDone(false);
          });
        }}
        label={"Done"}
      />,
    ];
    return (
      <DialogWrapper
        className=""
        title={`Qualification: Select Investment Type and Stage`}
        actions={actions}
        modal={true}
        open={isSelectingQualification}
        repositionOnUpdate={true}
        autoDetectWindowHeight={true}
        contentStyle={{
          width: "98%",
          maxWidth: "1080px",
        }}
      >
        <Choose>
          <When condition={submitting}>
            <div className="loading-qualification">
              <div>Creating Qualification files...</div>
              <CircularProgress
                size={24}
                style={{ marginLeft: "8px", marginTop: "-2px" }}
              />
            </div>
          </When>
          <Otherwise>
            <div className="call-details-row">
              <div className="label-column">Investment Type</div>
              <div className="content-column">
                <BuildInvestmentTypeStage
                  value={investmentType}
                  selectedValue={stateInvestmentType}
                  updateInvestmentInfo={(value) => {
                    this.changeInvestmentValue(
                      "stateInvestmentType",
                      value.investment_type
                    );
                  }}
                  disabled={false}
                  setHasMadeChanges={() => {}}
                  investmentInfo="investment_type"
                />
              </div>
            </div>
            <div className="call-details-row">
              <div className="label-column">Investment Stage</div>
              <div className="content-column">
                <BuildInvestmentTypeStage
                  value={investmentStage}
                  selectedValue={stateInvestmentStage}
                  updateInvestmentInfo={(value) => {
                    this.changeInvestmentValue(
                      "stateInvestmentStage",
                      value.investment_stage
                    );
                  }}
                  disabled={false}
                  setHasMadeChanges={() => {}}
                  investmentInfo="investment_stage"
                />
              </div>
            </div>
          </Otherwise>
        </Choose>
      </DialogWrapper>
    );
  }
}
