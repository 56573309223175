import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import { sha256 } from "js-sha256";

import AlertStore from "../../storage/AlertStore";

export default class AlertsCheckbox extends React.Component {
  static propTypes = {
    client: PropTypes.string.isRequired,
    investor: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.AlertStore = new AlertStore();

    this.state = {
      loading: true,
      disabled: false,
      subscribed: null,
    };
  }

  componentDidMount() {
    const {
      client,
      investor: { name },
      profile: { email },
    } = this.props;

    this.AlertStore.getAlert(
      `${client}-${email}-INVESTOR-${sha256(name)}`
    ).then(
      (response) => {
        this.setState({ loading: false, subscribed: true });
      },
      (reason) => {
        this.setState({ loading: false, subscribed: false });
      }
    );
  }

  handleCheckbox = (event) => {
    event.preventDefault();
    const { client, investor, profile } = this.props;
    if (this.state.subscribed) {
      this.setState({ disabled: true });
      this.AlertStore.deleteAlert(
        `${client}-${profile.email}-INVESTOR-${sha256(investor.name)}`
      ).then(
        (response) => {
          this.setState({ disabled: false, subscribed: false });
        },
        (reason) => {
          this.setState({ disabled: false, subscribed: true });
        }
      );
    } else {
      this.setState({ disabled: true });
      this.AlertStore.createAlert(
        investor.id,
        investor.name,
        profile.email
      ).then(
        (response) => {
          this.setState({ disabled: false, subscribed: true });
        },
        (reason) => {
          this.setState({ disabled: false, subscribed: false });
        }
      );
    }
  };

  render() {
    const { disabled, loading, subscribed } = this.state;

    return (
      <React.Fragment>
        <If condition={loading}>
          <span>Loading...</span>
        </If>
        <If condition={!loading}>
          <div class="alert-form">
            <button
              type="button"
              class={clsx(
                "alert-button",
                "btn",
                "btn-sm",
                !subscribed && "btn-outline-secondary",
                subscribed && "btn-secondary"
              )}
              disabled={disabled}
              onClick={this.handleCheckbox}
            >
              <i
                class={clsx(
                  "bi",
                  subscribed && "bi-bell",
                  !subscribed && "bi-bell-slash"
                )}
              />
              Alert Me
            </button>
          </div>
        </If>
      </React.Fragment>
    );
  }
}
