import React from "react";
import { IconButton, ToolbarTitle } from "material-ui";
import NavigationClose from "material-ui/svg-icons/navigation/close";

export default function DoNotContact() {
  return (
    <div style={{ backgroundColor: "red" }}>
      <IconButton>
        <NavigationClose />
      </IconButton>
      <ToolbarTitle text="Do Not Contact" />
    </div>
  );
}
