import React from "react";
import { Link, withRouter } from "react-router-v4";
import PropTypes from "prop-types";

class Similarity extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
  };

  getLink = () => {
    const { organization } = this.props;

    const params = new URLSearchParams({
      organizations_similar_to: organization.id,
      order_by: "score",
      order: "desc",
    });

    return `/organizations?${params.toString()}`;
  };

  render() {
    return (
      <div className="similarity_link">
        <Link to={this.getLink()} target="_blank">
          <span>Similar Organizations</span>
          <span class="bi bi-building"></span>
        </Link>
      </div>
    );
  }
}

export default withRouter(Similarity);
