import React from "react";
import PropTypes from "prop-types";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import INVESTMENT_STAGES from "../../../content/investmentStages";

export default class EditInvestmentStage extends React.Component {
  static propTypes = {
    investmentStage: PropTypes.string,
    onChangeInvestmentStage: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="edit-funding-stage">
        <div className="selectors-container">
          <SelectField
            className="select-funding-stage"
            value={this.props.investmentStage}
            onChange={(event, index, value) =>
              this.props.onChangeInvestmentStage(value)
            }
            autoWidth={true}
          >
            <MenuItem
              className="funding-stage-entry"
              key="empty"
              value=""
              primaryText=""
            />
            {Object.entries(INVESTMENT_STAGES).map(([key, { name }]) => (
              <MenuItem
                className="funding-stage-entry"
                key={key}
                value={key}
                primaryText={name}
              />
            ))}
          </SelectField>
        </div>
      </div>
    );
  }
}
