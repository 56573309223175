import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";
import PersonExperience from "../../../PersonExperience";

import { capitalizeString } from "../../../../../lib/helpers";

class EducationExperienceItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
  };

  getContentItemDate = () => {
    const { item } = this.props;
    const {
      start = null,
      end = null,
      duration = null,
      from = null,
      to = null,
    } = item;
    if (start) {
      return `${start} - ${end || "Present"} (${PersonExperience.duration(
        start,
        end,
        duration
      )})`;
    } else if (from) {
      return `${from} - ${to}`;
    }
    return "";
  };

  getHighlightedPart = () => {
    const { item: { reason = null } = {}, type } = this.props;
    if (!reason) {
      return null;
    }
    if (type === "education") {
      switch (reason) {
        case "title":
          return "main";
        case "subtitle":
          return "secondary";
        case "from":
        case "to":
          return "third";
      }
    } else {
      switch (reason) {
        case "company":
          return "main";
        case "title":
          return "secondary";
        case "start":
        case "end":
          return "third";
      }
    }
  };

  render() {
    const { item, type } = this.props;
    const highlighted = this.getHighlightedPart();
    return (
      <div className="data-content-list-item component education-experience-list-item">
        <div
          className={`education-experience-container${
            highlighted ? " highlighted" : ""
          }`}
        >
          <div
            className={`education-experience-main${
              highlighted === "main" ? " highlighted-text" : ""
            }`}
          >
            {type === "education"
              ? capitalizeString(item.title || "")
              : capitalizeString(item.company || "")}
          </div>
          <div
            className={`education-experience-secondary${
              highlighted === "secondary" ? " highlighted-text" : ""
            }`}
          >
            {type === "education"
              ? capitalizeString(item.subtitle || "")
              : capitalizeString(item.title || "")}
          </div>
          <If condition={item.start || item.from}>
            <div
              className={`education-experience-third${
                highlighted === "third" ? " highlighted-text" : ""
              }`}
            >
              {this.getContentItemDate()}
            </div>
          </If>
        </div>
      </div>
    );
  }
}

class EducationExperience extends React.Component {
  static propTypes = {
    content: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
  };

  render() {
    const { content, type } = this.props;
    return (
      <div className={`data-wrapper ${type}`}>
        <div className="data-header">{capitalizeString(type)}</div>
        <Choose>
          <When condition={content.length === 0}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="data-content list">
              {content.map((contentItem, index) => (
                <EducationExperienceItem
                  item={contentItem}
                  type={type}
                  key={index}
                />
              ))}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(EducationExperience);
