import React from "react";
import PropTypes from "prop-types";
import { ListItem } from "material-ui/List";
import IconButton from "material-ui/IconButton";

import ActionOpenInNew from "material-ui/svg-icons/action/open-in-new";
import xlsIcon from "../../../../images/xls-file-64px.png";
import docIcon from "../../../../images/doc-file-64px.png";
import pptIcon from "../../../../images/ppt-file-64px.png";
import pdfIcon from "../../../../images/pdf-file-64px.png";
import jpegIcon from "../../../../images/jpeg-file-64px.png";
import blankIcon from "../../../../images/blank-file-64px.png";

export default class File extends React.Component {
  static propTypes = {
    file: PropTypes.object.isRequired,
  };

  getIcon = (file) => {
    if (file.mime_type === "application/vnd.google-apps.spreadsheet")
      return xlsIcon;
    if (file.mime_type === "application/vnd.google-apps.document")
      return docIcon;
    if (file.mime_type === "application/vnd.google-apps.presentation")
      return pptIcon;
    if (file.mime_type === "application/pdf") return pdfIcon;
    if (file.mime_type === "application/png") return jpegIcon;
    else {
      return blankIcon;
    }
  };

  render() {
    const { id, name } = this.props.file,
      url = `https://drive.google.com/open?id=${id}`;

    return (
      <div className="file">
        <ListItem
          className="filename"
          primaryText={name}
          leftAvatar={
            <img
              src={this.getIcon(this.props.file)}
              alt="Logo"
              style={{
                height: "32px",
                width: "32px",
                top: "16px",
                left: "0px",
              }}
            />
          }
          onClick={() => window.open(url, "_blank")}
          rightIconButton={
            <IconButton
              tooltip={`Open ${name}`}
              style={{ top: "0px", right: "0px", padding: "16px 0" }}
            >
              <a href={url} target="_blank">
                <ActionOpenInNew />
              </a>
            </IconButton>
          }
        />
      </div>
    );
  }
}
