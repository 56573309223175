import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-v4";

import { Tab, Tabs } from "material-ui/Tabs";
import CommunicationBusiness from "material-ui/svg-icons/communication/business";
import CommunicationForum from "material-ui/svg-icons/communication/forum";
import CommunicationCall from "material-ui/svg-icons/communication/call";
import ActionList from "material-ui/svg-icons/action/list";

class OrganizationTabs extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired,
    selectedTab: PropTypes.string.isRequired,
    userHasFullAccess: PropTypes.bool.isRequired,
    temporaryShowNote: PropTypes.object,
    updateSelectedTab: PropTypes.func.isRequired,
  };

  isQualificationDisabled = () => {
    const { organization, client } = this.props;
    return !(
      !!organization.source_refs &&
      !!organization.source_refs.QUALIFICATION &&
      organization.source_refs.QUALIFICATION.some((ref) =>
        ref.includes(`${organization.id}-${client}`)
      )
    );
  };

  setSelectedTab = (value) => {
    if (value === "qualification" && this.isQualificationDisabled()) {
      return null;
    }
    this.props.updateSelectedTab(value);
    const { id } = this.props.params;
    const { query } = this.props.location;
    this.props.router.replace({
      pathname:
        value === "profile"
          ? `/organizations/${id}`
          : `/organizations/${id}/${value}`,
      query,
    });
  };

  render() {
    const { userHasFullAccess, temporaryShowNote, selectedTab } = this.props;

    return (
      <div className="organization-component">
        <Tabs
          onChange={this.setSelectedTab}
          value={this.props.params.tab || "profile"}
          className={"organization-tabs"}
        >
          <Tab
            label={window.innerWidth >= 768 && "Profile"}
            value="profile"
            icon={<CommunicationBusiness />}
            className={
              selectedTab === "profile"
                ? "organization-tab selected"
                : "organization-tab"
            }
          />
          <Tab
            label={window.innerWidth >= 768 && "Workflow"}
            value="workflow"
            icon={<CommunicationForum />}
            className={
              selectedTab === "workflow"
                ? "organization-tab selected"
                : "organization-tab"
            }
          />
          <If condition={userHasFullAccess || !!temporaryShowNote}>
            <Tab
              label={window.innerWidth >= 768 && "Scorecard"}
              value="scorecard"
              icon={<CommunicationCall />}
              className={
                selectedTab === "scorecard"
                  ? "organization-tab selected"
                  : "organization-tab"
              }
            />
          </If>
          <If condition={userHasFullAccess}>
            <Tab
              label={window.innerWidth >= 768 && "Qualification"}
              value="qualification"
              icon={<ActionList />}
              className={
                this.isQualificationDisabled()
                  ? "organization-tab disabled"
                  : selectedTab === "qualification"
                  ? "organization-tab selected"
                  : "organization-tab"
              }
            />
          </If>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(OrganizationTabs);
