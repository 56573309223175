import auth from "./Auth";
import Config from "../config";
import request from "superagent";
import scorecardSections from "../content/scorecardSections";
const CACHE = {};

export default class ScorecardTemplateStore {
  constructor() {
    this.baseUrl = `${Config.api.host}/templates/v3`;
  }

  getTemplates() {
    if (CACHE.templates) {
      return Promise.resolve(CACHE.templates);
    }
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.baseUrl)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .set("Accept", "application/json")
      )
      .then((response) => {
        CACHE.templates = response.body.templates;
        return response.body.templates;
      });
  }

  getTemplate(templateId) {
    if (CACHE.templates) {
      return Promise.resolve(
        CACHE.templates.find((template) => template.id === templateId)
      );
    }
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(`${this.baseUrl}/${templateId}`)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .set("Accept", "application/json")
      )
      .then((response) => response.body);
  }

  getSectionsForTemplates() {
    if (CACHE.sections) {
      return Promise.resolve(CACHE.sections);
    }
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.baseUrl)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .set("Accept", "application/json")
      )
      .then((response) => {
        const sections = response.body.templates.reduce((acc, template) => {
          acc[template.id] = Object.keys(template).filter(
            (key) => !["order", "id", "title"].includes(key)
          );
          return acc;
        }, {});
        sections["form"] = ["people", "product", "market", "funding"];
        CACHE.sections = sections;
        return sections;
      });
  }
}
