import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";
import InvestmentsInfo from "./investor_info/InvestmentsInfo";
import InvestmentCategory from "./investor_info/InvestmentCategory";
import InreachDecisions from "./investor_info/InreachDecisions";
import InreachWorkflow from "./investor_info/InreachWorkflow";
import Contacts from "./investor_info/Contacts";
import InvestorInsights from "./investor_info/InvestorInsights";
import Locations from "../organization/profile/summary/Locations";

class InvestorSummaryRow extends React.Component {
  static propTypes = {
    companyInfo: PropTypes.object.isRequired,
    tabsContent: PropTypes.object,
    isTop: PropTypes.bool.isRequired,
  };

  render() {
    const {
      companyInfo: { locations, employees },
      isTop,
    } = this.props;
    return (
      <div className="company-info">
        <div className="grid-container investor-info-grid-container">
          <div className="investor-info-grid-item grid-item-1">
            {isTop ? (
              <Locations
                locations={locations}
                compressed={false}
                profileType={"investor"}
              />
            ) : (
              <InvestorInsights
                showMaxLength={3}
                sectionType={"workflow"}
                {...this.props}
              />
            )}
          </div>
          <div className="investor-info-grid-item border-1-grid-item" />
          <div className="investor-info-grid-item grid-item-2">
            {isTop ? (
              <InvestmentsInfo {...this.props} />
            ) : (
              <InvestmentCategory
                showMaxLength={2}
                sectionType={"topInvestment"}
                title={"top Companies"}
                {...this.props}
              />
            )}
          </div>
          <div className="investor-info-grid-item border-2-grid-item" />
          <div className="investor-info-grid-item grid-item-3">
            {isTop ? (
              <InvestmentCategory
                showMaxLength={3}
                sectionType={"fundingType"}
                title={"Average Rounds Size"}
                {...this.props}
              />
            ) : (
              <InvestmentCategory
                showMaxLength={3}
                sectionType={"investmentYears"}
                title={"investor activity"}
                {...this.props}
              />
            )}
          </div>
          <div className="investor-info-grid-item border-3-grid-item" />
          <div className="investor-info-grid-item grid-item-4">
            {isTop ? (
              <InvestmentCategory
                showMaxLength={3}
                locations={locations}
                sectionType={"locations"}
                title={"Geographic Coverage"}
                {...this.props}
              />
            ) : (
              <InreachDecisions
                showMaxLength={3}
                sectionType={"decisions"}
                {...this.props}
              />
            )}
          </div>
          <div className="investor-info-grid-item border-4-grid-item" />
          <div className="investor-info-grid-item grid-item-5">
            {isTop ? (
              <InvestmentCategory
                showMaxLength={4}
                sectionType={"investmentTypes"}
                title={"Investment type"}
                {...this.props}
              />
            ) : (
              <InreachWorkflow
                showMaxLength={3}
                sectionType={"workflow"}
                {...this.props}
              />
            )}
          </div>
          <div className="investor-info-grid-item border-5-grid-item" />
          <div className="investor-info-grid-item grid-item-6">
            {isTop ? (
              <InvestmentCategory
                showMaxLength={2}
                sectionType={"tags"}
                title={"Industries (Tags)"}
                {...this.props}
              />
            ) : (
              <Contacts
                showMaxLength={3}
                sectionType={"workflow"}
                {...this.props}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InvestorSummaryRow);
