import React from "react";
import PropTypes from "prop-types";
import TimelineEventMailingBody from "./TimelineEventMailingBody";

import communicationTypes from "../../../../../content/communicationTypes.js";

import moment from "moment";
import xss from "xss";
import DeliveredIcon from "material-ui/svg-icons/content/send";
import ViewedIcon from "material-ui/svg-icons/action/face";
import RepliedIcon from "material-ui/svg-icons/content/reply";
import Tooltip from "../../../../Tooltip";
import { truncateString } from "../../../../../lib/helpers";

export default class TimelineEventCommunications extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    getUserName: PropTypes.func.isRequired,
  };

  maxLabelLength = 120;

  emailDeliveredRef = React.createRef();
  emailReadRef = React.createRef();
  emailRepliedRef = React.createRef();

  buildItem = (item = this.props.item) => {
    const {
      status,
      sent: sentTo = [],
      type = "",
      message = {},
    } = item.communication;

    const label = this.getCommunicationLabels(status, sentTo, type).label;
    return {
      label: this.getShortLabel(label),
      showBody: typeof message.body !== "undefined",
      email: {
        emailFrom: message.from_address || null,
        emailTo: message.to_address || null,
        subject: message.subject || null,
        body: message.body || null,
        sent:
          sentTo.length > 0
            ? moment(sentTo[0].sent).format("dddd, MMMM DD, YYYY LT")
            : null,
      },
    };
  };

  getCommunicationLabels = (status, sent, type) => {
    if (status === "DELIVERED") {
      const personOpened = (sent.filter((c) => c.status === "opened")[0] || {})
        .to;
      const personDelivered = (
        sent.filter((c) => c.status === "delivered")[0] || {}
      ).to;
      return {
        label: `<b>${communicationTypes[type]}</b> - ${
          personOpened ? "Opened" : "Delivered"
        }: ${personOpened ? personOpened : personDelivered}`,
      };
    } else if (status === "ERROR") {
      return {
        label: `<b>${communicationTypes[type]}</b> - Error`,
      };
    } else if (status === "REQUIRES_DATA") {
      return {
        label: `<b>${communicationTypes[type]}</b> - ⚠ No Working Email Address`,
      };
    } else if (status === "REQUIRES_ALTERNATE_DATA") {
      const personBounced = sent.filter(
        (c) => c.status === "bounced" || c.status === "dropped"
      )[0];
      return {
        label: `<b>${communicationTypes[type]}</b> - ⚠ Error with ${personBounced.to} (${personBounced.status})`,
      };
    } else {
      return {};
    }
  };

  getShortLabel = (label) => {
    return `${truncateString(label, this.maxLabelLength)}`;
  };

  reformatDate = (sent) => {
    return moment(sent).format("dddd, MMMM DD, YYYY LT");
  };

  buildEmailStatus = () => {
    if (!this.props.item) return null;
    const {
      communication: { status: status, sent: sent = [] },
    } = this.props.item;
    const deliveredAt =
      status === "DELIVERED" && sent.length
        ? (sent.filter((c) => c.status === "delivered")[0] || {}).sent
        : null;
    const openedAt =
      status === "DELIVERED" && sent.length
        ? (sent.filter((c) => c.status === "opened")[0] || {}).sent
        : null;
    return (
      <React.Fragment>
        <div ref={this.emailDeliveredRef} className="email-status">
          <DeliveredIcon
            ref
            color={deliveredAt ? "#48a0ff" : "#c7c7c7"}
            style={{ marginRight: "8px" }}
          />
        </div>
        <Tooltip
          parent={this.emailDeliveredRef.current}
          text={
            deliveredAt
              ? `Delivered on ${this.reformatDate(deliveredAt)}`
              : "Hasn't been delivered"
          }
          showOnHover={true}
          position="Top"
          customStyles={{ right: 0 }}
        />
        {/*<div ref={this.emailReadRef} className="email-status">*/}
        {/*<ViewedIcon*/}
        {/*color={openedAt ? '#48a0ff' : '#c7c7c7'}*/}
        {/*style={{marginRight: '8px'}}*/}
        {/*/>*/}
        {/*</div>*/}
        {/*<Tooltip*/}
        {/*parent={this.emailReadRef.current}*/}
        {/*text={*/}
        {/*openedAt*/}
        {/*? `Opened on ${this.reformatDate(openedAt)}`*/}
        {/*: "Hasn't been opened"*/}
        {/*}*/}
        {/*showOnHover={true}*/}
        {/*position="Top"*/}
        {/*customStyles={{right: 0}}*/}
        {/*/>*/}
        <div ref={this.emailRepliedRef} className="email-status">
          <RepliedIcon
            color={"#ffffff"}
            stroke={"#c7c7c7"}
            style={{ marginRight: "8px" }}
          />
        </div>
        <Tooltip
          parent={this.emailRepliedRef.current}
          text={`No reply info from the old system`}
          showOnHover={true}
          position="Top"
          customStyles={{ right: 0 }}
        />
      </React.Fragment>
    );
  };

  render() {
    const { label, showBody, email } = this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <div className="timeline-event-email-text">
          <h5
            className="timeline-event-label"
            dangerouslySetInnerHTML={{ __html: xss(label) }}
          />
        </div>
        <div className="spacer" />
        {this.buildEmailStatus()}
        <If condition={showBody}>
          <TimelineEventMailingBody email={email} />
        </If>
      </div>
    );
  }
}
