import PropTypes from "prop-types";
import React from "react";
import People from "./People";
import PersonFilters from "./PersonFilters";
import FilterSummary from "./FilterSummary";
import _ from "underscore";

class PeopleSearch extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    var params = this.urlParams();

    this.state = {
      visibleFilters: false,
      offset: params.offset,
      searchParams: _.omit(params, "offset"),
    };
  }

  handleChange = _.debounce((searchParams) => {
    this.setState({
      offset: 0,
      searchParams: searchParams,
    });
    this.changeUrl(
      "/people",
      _.extend(
        {
          offset: 0,
        },
        searchParams
      )
    );
  }, 400);

  urlParams = () => {
    var params = this.props.location.query;
    params.offset = parseInt(params.offset || "0");
    return params;
  };

  changeUrl = (url, params) => {
    if (
      url !== this.props.location.pathname ||
      !_.isEqual(params, this.urlParams())
    ) {
      this.context.router.push({
        pathname: url,
        query: params,
      });
    }
  };

  toggleShowFilters = (e) => {
    e.preventDefault();
    this.setState({ visibleFilters: !this.state.visibleFilters });
  };

  render() {
    return (
      <div>
        <div className="row search">
          <div className="col-sm-11 col-xs-8 pull-left">
            {this.state.visibleFilters ? (
              <PersonFilters
                searchParams={this.state.searchParams}
                onChange={this.handleChange}
              />
            ) : (
              <FilterSummary params={this.state.searchParams} />
            )}
          </div>
          <div className="col-sm-1 col-xs-4 pull-right">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={this.toggleShowFilters}
            >
              {this.state.visibleFilters ? "Hide" : "Show"} Filters
            </button>
          </div>
        </div>
        <People
          searchParams={this.state.searchParams}
          offset={this.state.offset}
          changeUrl={this.changeUrl}
        />
      </div>
    );
  }
}

export default PeopleSearch;
