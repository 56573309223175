import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-v4";
import InvestorStore from "../../../storage/InvestorStore";
import diff from "deep-diff";
import auth from "../../../storage/Auth";
import _ from "underscore";
import EditInvestorHeader from "./EditInvestorHeader";
import Loading from "../../loading/Loading";
import { Dialog, FlatButton, TextField } from "material-ui";
import Card from "../../card/Card";
import CardBody from "../../card/CardBody";
import PatchHelper from "../../../lib/PatchHelper";
import EditBasicInfo from "./EditBasicInfo";
import EditOrganizationLocations from "../../organization/edit/EditOrganizationLocations";
import CreateOrganizationHelper from "../../../lib/CreateOrganizationHelper";
import DialogWrapper from "../../components/DialogWrapper";
export default class EditInvestorFields extends React.Component {
  static propTypes = {
    initialInvestor: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    saveSuccess: PropTypes.bool.isRequired,
    showErrorDialog: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    handleErrorDialogClose: PropTypes.func.isRequired,
  };

  state = {
    investor: this.props.initialInvestor,
    errors: {},
    modifiedFields: [],
  };

  createInvestorHelper = new CreateOrganizationHelper();

  getModifiedFields = (initialInvestor, modifiedInvestor) => {
    const investorDiff = diff(initialInvestor, modifiedInvestor);

    return investorDiff
      ? investorDiff.map((diffEntry) =>
          diffEntry.path ? diffEntry.path[0] : null
        )
      : [];
  };

  setUpdatedFields = (investor) => {
    const modifiedFields = this.getModifiedFields(
      this.props.initialInvestor,
      investor
    );

    this.setState({
      modifiedFields,
    });
  };

  handleInvestorChange = (investor) => {
    this.setState({ investor });
    this.setUpdatedFields(investor);
  };

  checkIfErrors = (errors) => {
    return Object.keys(errors).filter((field) => !!errors[field]).length > 0;
  };

  updateInvestorField = (field, value) => {
    const updatedInvestor = Object.assign({}, this.state.investor, {
      [field]: value,
    });
    this.setUpdatedFields(updatedInvestor);
    this.handleInvestorChange(updatedInvestor);
  };

  updateInvestorFields = (data) => {
    const { investor = {} } = this.state;
    data.forEach(({ field, value }) => {
      investor[field] = value;
    });
    this.setUpdatedFields(investor);
    this.handleInvestorChange(investor);
  };

  validateInvestorField = (field, value, type) => {
    const errors = Object.assign({}, this.state.errors);
    errors[field] = this.createInvestorHelper.validateField(null, value, type);

    this.setState({ errors });
  };

  checkIfFieldsHaveBeenEdited = (fields) => {
    return (
      fields.filter((field) => this.state.modifiedFields.indexOf(field) !== -1)
        .length > 0
    );
  };

  renderEditedField = (fields) => {
    if (!this.checkIfFieldsHaveBeenEdited(fields)) {
      return false;
    }
    return <p className="edited-field">EDITED</p>;
  };

  render() {
    const { modifiedFields, investor, errors } = this.state;
    const {
      save,
      cancel,
      isSaving,
      saveSuccess,
      showErrorDialog,
      errorMessage,
      handleErrorDialogClose,
    } = this.props;

    return (
      <div className="edit-view">
        <EditInvestorHeader
          isSaving={isSaving}
          saveSuccess={saveSuccess}
          cancel={cancel}
          confirm={save}
          disable={this.checkIfErrors(errors) || modifiedFields.length === 0}
          title={`Edit: ${investor.name}`}
          source="edit"
          isInsideDialog={false}
        />
        <div className="edit-body">
          <Card>
            <CardBody>
              <div className="edit-section">
                <EditBasicInfo
                  source="incomplete"
                  investor={investor}
                  updateFields={this.updateInvestorFields}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  SHORT DESCRIPTION
                  {this.renderEditedField(["short_description"])}
                </div>
                <TextField
                  fullWidth={true}
                  name="edit-short-description"
                  className="edit-short-description"
                  value={investor.short_description}
                  onChange={(event) =>
                    this.updateInvestorField(
                      "short_description",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  DESCRIPTION
                  {this.renderEditedField(["description"])}
                </div>
                <TextField
                  className="edit-organization-input-field large"
                  value={investor.description}
                  onChange={(event) =>
                    this.updateInvestorField("description", event.target.value)
                  }
                  multiLine={true}
                  rows={1}
                  rowsMax={10}
                  fullWidth={true}
                  name="edit-investor-description"
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  WEBSITE
                  {this.renderEditedField(["url"])}
                </div>
                <TextField
                  className="edit-organization-website-field edit-organization-input-field large"
                  value={investor.url}
                  errorText={errors["url"]}
                  onChange={(event) => {
                    this.validateInvestorField(
                      "url",
                      event.target.value,
                      "URL"
                    );
                    this.updateInvestorField("url", event.target.value);
                  }}
                  name="edit-investor-website"
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  LOCATIONS
                  {this.renderEditedField(["locations"])}
                </div>
                <EditOrganizationLocations
                  locations={investor.locations}
                  updateOrganizationFields={this.updateInvestorFields}
                  source="edit"
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  CRUNCHBASE
                  {this.renderEditedField(["crunchbase_url"])}
                </div>
                <TextField
                  fullWidth={true}
                  className="edit-organization-crunchbase-field edit-organization-input-field large"
                  name="edit-investor-crunchbase-url"
                  value={investor.crunchbase_url}
                  errorText={errors["crunchbase_url"]}
                  onChange={(event) => {
                    this.validateInvestorField(
                      "crunchbase_url",
                      event.target.value,
                      "URL"
                    );
                    this.updateInvestorField(
                      "crunchbase_url",
                      event.target.value
                    );
                  }}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  DEALROOM
                  {this.renderEditedField(["dealroom_url"])}
                </div>
                <TextField
                  fullWidth={true}
                  className="edit-organization-dealroom-field edit-organization-input-field large"
                  name="edit-investor-dealroom-url"
                  value={investor.dealroom_url}
                  errorText={errors["dealroom_url"]}
                  onChange={(event) => {
                    this.validateInvestorField(
                      "dealroom_url",
                      event.target.value,
                      "URL"
                    );
                    this.updateInvestorField(
                      "dealroom_url",
                      event.target.value
                    );
                  }}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  ORGANIZATION ID
                  {this.renderEditedField(["organization_id"])}
                </div>
                <TextField
                  fullWidth={true}
                  className="edit-organization-input-field small"
                  name="edit-investor-organization-id"
                  value={investor.organization_id}
                  onChange={(event) =>
                    this.updateInvestorField(
                      "organization_id",
                      event.target.value
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </div>
        <DialogWrapper
          title="Error while saving edited investor"
          actions={[
            <FlatButton
              label="Close"
              primary={true}
              onClick={handleErrorDialogClose}
            />,
          ]}
          modal={false}
          open={showErrorDialog}
          onRequestClose={handleErrorDialogClose}
        >
          {errorMessage}
        </DialogWrapper>
      </div>
    );
  }
}
