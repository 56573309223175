import React from "react";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import StagesStreak from "../../ui/StagesStreak";
import { CircularProgress } from "material-ui";

import DialogWrapper from "../components/DialogWrapper";

export default class StageChange extends React.Component {
  static propTypes = {
    isChangingStage: PropTypes.bool.isRequired,
    onDone: PropTypes.func.isRequired,
    stage: PropTypes.string.isRequired,
    reduced: PropTypes.bool.isRequired,
    onStageChange: PropTypes.func.isRequired,
    organizationDecision: PropTypes.object.isRequired,
  };

  state = {
    submitting: false,
  };

  render() {
    const { isChangingStage, onDone, onStageChange, organizationDecision } =
      this.props;
    const { submitting } = this.state;
    const actions = [
      <RaisedButton
        key="cancel"
        className="edit-email-close"
        value="cancel"
        backgroundColor={"red"}
        labelColor={"#ffffff"}
        style={{ marginRight: "16px" }}
        onClick={() => {
          onDone(false);
        }}
        label={"Close"}
      />,
    ];
    return (
      <DialogWrapper
        className=""
        title={`Change stage`}
        actions={actions}
        modal={true}
        open={isChangingStage}
        repositionOnUpdate={true}
        autoDetectWindowHeight={true}
      >
        <Choose>
          <When condition={submitting}>
            <div className="loading-qualification">
              <div>Changing stage...</div>
              <CircularProgress
                size={24}
                style={{ marginLeft: "8px", marginTop: "-2px" }}
              />
            </div>
          </When>
          <Otherwise>
            <StagesStreak
              stage={organizationDecision.stage}
              reduced={true}
              ignoreDisabled={organizationDecision.state !== "contact"}
              handleStageChange={(stage) => {
                this.setState({ submitting: true });
                onStageChange(stage).then(() => {
                  this.setState({ submitting: false });
                });
              }}
            />
          </Otherwise>
        </Choose>
      </DialogWrapper>
    );
  }
}
