import React from "react";
import PropTypes from "prop-types";

import TimelineEvent from "./timeline-event/TimelineEvent.js";

export default class TimelineSectionContent extends React.Component {
  static propTypes = {
    section: PropTypes.string.isRequired,
    content: PropTypes.array.isRequired,
  };

  mergeMailingsGmails(content) {
    const gmailMessages = content.filter(
      (event) =>
        event.collection === "gmail_messages" && (event.message || {}).sent
    );
    const mailings = content.filter(
      (event) =>
        event.collection === "mailings" && (event.mailing || {}).delivered_at
    );
    const communications = content.filter(
      (event) =>
        event.collection === "communications" &&
        ((event.communication || {}).sent || [{ sent: 0 }])[0].sent
    );

    if (
      (!gmailMessages.length || !mailings.length) &&
      (!gmailMessages.length || !communications.length)
    ) {
      return content;
    }
    const mailingTimes = mailings.map((mailing) =>
      new Date(mailing.mailing.delivered_at).getTime()
    );
    const gmailTimes = gmailMessages.map(
      (gmailMessage) => gmailMessage.message.sent
    );

    const communicationTimes = communications.map((communication) => {
      const sent = (((communication.communication || {}).sent || [{}])[0] || {})
        .sent;
      return sent ? parseInt(sent / 1000) * 1000 : 0;
    });

    return content
      .filter(
        (event) =>
          (event.collection === "gmail_messages" &&
            (event.message || {}).sent &&
            mailingTimes.includes(event.message.sent)) ||
          (event.collection === "gmail_messages" &&
            (event.message || {}).sent &&
            communicationTimes.includes(event.message.sent)) ||
          !(
            ["mailings", "communications"].includes(event.collection) &&
            (((event.mailing || {}).delivered_at &&
              gmailTimes.includes(
                new Date(event.mailing.delivered_at).getTime()
              )) ||
              ("communication" in event &&
                "sent" in event.communication &&
                event.communication.sent[0].sent &&
                gmailTimes.includes(
                  parseInt(event.communication.sent[0].sent / 1000) * 1000
                )))
          )
      )
      .map((event) => {
        if (
          event.collection === "gmail_messages" &&
          (event.message || {}).sent &&
          mailingTimes.includes(event.message.sent)
        ) {
          const mailingToMerge =
            mailings[mailingTimes.indexOf(event.message.sent)];
          event.merged_email = true;
          event.mailing = mailingToMerge.mailing;
        } else if (
          event.collection === "gmail_messages" &&
          (event.message || {}).sent &&
          communicationTimes.includes(event.message.sent)
        ) {
          const mailingToMerge =
            communications[communicationTimes.indexOf(event.message.sent)];
          event.merged_email = true;
          event.communication = mailingToMerge.communication;
        }
        return event;
      });
  }

  filterUserRoleDuplicates(content) {
    let decisions = [];
    let filtered = [];
    content.forEach((event) => {
      if (event.collection === "decisions") {
        let item = { ...event };
        if (decisions.includes(item.decision.created_at)) {
          return;
        }
        decisions.push(item.decision.created_at);
        filtered.push(event);
      } else {
        filtered.push(event);
      }
    });
    return filtered;
  }

  render() {
    const { section, content } = this.props;
    const mergedContent = this.filterUserRoleDuplicates(
      this.mergeMailingsGmails(content)
    );
    return (
      <div className={section + "-section-content"}>
        {mergedContent
          .filter((event, i) => {
            if (event.collection === "decisions") {
              delete event.decision.user_role;
              return mergedContent.indexOf(event) === i;
            }
            return true;
          })
          .map((item, i) => {
            const key = item.keen ? item.keen.timestamp : item.key;
            return <TimelineEvent key={key} item={item} {...this.props} />;
          })}
      </div>
    );
  }
}
