import auth from "./Auth";
import Config from "../config";
import request from "superagent";

export default class StructuredFeedbackStore {
  constructor() {
    this.baseUrl = `${Config.structured_feedback.host}/structured_feedback`;
  }

  getStructuredFeedbackForOrganization(organizationId) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(`${this.baseUrl}/ORGANIZATION/${organizationId}`)
          .set("Accept", "application/json")
          .set("Authorization", `Bearer ${authData.token}`)
      )
      .then((response) => {
        return response.body;
      });
  }

  createStructuredFeedback(structuredFeedback) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(this.baseUrl)
          .send(structuredFeedback)
          .set("Accept", "application/json")
          .set("Authorization", `Bearer ${authData.token}`)
      )
      .then((response) => {
        return response.body;
      });
  }
}
