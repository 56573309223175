import React from "react";
import PropTypes from "prop-types";

import EditOrganizationPeopleListPerson from "./EditOrganizationPeopleListPerson";
import AddCircleOutline from "material-ui/svg-icons/content/add-circle";

import _ from "underscore";

import contactRolePriorities from "../../../content/contactRolePriorities";

export default class EditOrganizationPeopleList extends React.Component {
  static propTypes = {
    people: PropTypes.array.isRequired,
    primaryContact: PropTypes.string.isRequired,
    updateOrganizationFields: PropTypes.func.isRequired,
    savePerson: PropTypes.func.isRequired,
    saveNewPerson: PropTypes.func.isRequired,
    deletePerson: PropTypes.func.isRequired,
    organizationId: PropTypes.string.isRequired,
  };

  state = {
    people: [],
    addingNewPerson: false,
  };

  componentWillMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.people, this.props.people)) {
      this.initialize();
    }
  }

  initialize = () => {
    this.setState({
      people: this.getSortedPeople(),
      addingNewPerson: false,
    });
  };

  getSortedPeople = () => {
    const { people = [] } = this.props;
    if (people.length > 1) {
      return people.sort((a, b) => this.getPersonPriority(a, b));
    }
    return people;
  };

  getPersonPriority = (a, b) =>
    this.getRolePriority(this.getRole(a)) -
    this.getRolePriority(this.getRole(b));

  getRolePriority = (personRole) => {
    const contactRolePrioritiesKeys = Object.keys(contactRolePriorities);
    const role = contactRolePrioritiesKeys.find(
      (role) => personRole.indexOf(role) > -1
    );
    const priority = contactRolePriorities[role];
    return typeof priority !== "undefined"
      ? priority
      : contactRolePrioritiesKeys.length;
  };

  getRole = (person) => {
    const { primaryContact = "" } = this.props;
    if (primaryContact && primaryContact === person.id) {
      return "primary";
    }
    return String(person.role || person.title || "").toLowerCase();
  };

  getHasALinkedInUrl = () => {
    const { people = [] } = this.state;
    return (
      people.filter(({ linkedin_url: linkedIn = "" }) => linkedIn !== "")
        .length > 0
    );
  };

  handleAddPerson = () => {
    this.setState({
      addingNewPerson: true,
    });
  };

  handleCancelAddingPerson = () => {
    this.setState({
      addingNewPerson: false,
    });
  };

  render() {
    const {
      primaryContact,
      updateOrganizationFields,
      savePerson,
      saveNewPerson,
      deletePerson,
      organizationId,
    } = this.props;
    const { people = [], addingNewPerson = false } = this.state;
    const hasALinkedInUrl = this.getHasALinkedInUrl();
    return (
      <div className="edit-organization-people-list">
        {people.map((person, index) => (
          <EditOrganizationPeopleListPerson
            key={person.id}
            person={person}
            primaryContact={primaryContact}
            updateOrganizationFields={updateOrganizationFields}
            savePerson={savePerson}
            index={index}
            hasALinkedInUrl={hasALinkedInUrl}
            newPerson={false}
            deletePerson={deletePerson}
            organizationId={organizationId}
          />
        ))}
        <Choose>
          <When condition={addingNewPerson}>
            <EditOrganizationPeopleListPerson
              person={{
                image_url: "",
                name: "",
                role: "",
                linkedin_url: "",
              }}
              newPerson={true}
              saveNewPerson={saveNewPerson}
              cancelAddingPerson={this.handleCancelAddingPerson}
              organizationId={organizationId}
            />
          </When>
          <Otherwise>
            <div
              className="edit-organization-add-button"
              onClick={this.handleAddPerson}
            >
              <AddCircleOutline color={"#5DAB49"} className="add-icon" />
              <div className="add-text">ADD PERSON</div>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
