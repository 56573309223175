import React from "react";
import { withRouter, Link } from "react-router-v4";
import PropTypes from "prop-types";

class Email extends React.Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
  };

  render() {
    const { email = "" } = this.props;
    return (
      <div className="data-wrapper role">
        <div className="data-header">Email</div>
        <Choose>
          <When condition={email === ""}>
            <div className="data-content text empty">No email address</div>
          </When>
          <Otherwise>
            <div className="data-content text">
              <Link href={`mailto:${email}`}>{email}</Link>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(Email);
