import PropTypes from "prop-types";

import React from "react";
import Categories from "./Categories";
import LinearProgress from "material-ui/LinearProgress";
import _ from "underscore";
import moment from "moment";
import Tooltip from "../../Tooltip";
import { qualificationProgress } from "./qualification";

export default class QualificationTab extends React.Component {
  static propTypes = {
    qualification: PropTypes.object.isRequired,
    qualificationLoading: PropTypes.bool.isRequired,
    updateTask: PropTypes.func.isRequired,
    updateSubTask: PropTypes.func.isRequired,
    deleteSubTask: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    updateQualification: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    reloadOrganization: PropTypes.func.isRequired,
    handleOrganizationStageChange: PropTypes.func,
    handleOrganizationChange: PropTypes.func.isRequired,
    showErrorDialog: PropTypes.func.isRequired,
    client: PropTypes.string,
    filesFolder: PropTypes.string,
    files: PropTypes.array.isRequired,
  };

  render() {
    const {
      qualification,
      updateTask,
      updateSubTask,
      deleteSubTask,
      deleteFile,
      updateQualification,
      client,
      organization,
      files,
      filesFolder,
    } = this.props;
    const progress = qualificationProgress(qualification);
    return (
      <div className="qualification-tab full-box-container">
        <div className="company-info">
          <div className="progress-container" ref={(el) => (this.el = el)}>
            <div className="qualification-progress-title">
              <p>
                <strong>Overall Progress:</strong>
              </p>
            </div>
            <div className="qualification-progress-bar-wrapper">
              <LinearProgress
                className="qualification-progress-bar"
                mode="determinate"
                value={progress}
                color={
                  progress < 25
                    ? "red"
                    : progress < 50
                    ? "orange"
                    : progress < 75
                    ? "yellow"
                    : "#5dab49"
                }
              />
              <div className="qualification-progress-percentage">
                <p>
                  <strong>{progress}%</strong>
                </p>
              </div>
            </div>
            <If condition={progress === 100}>
              <p className={"qualification-crown"}>👑</p>
            </If>
            <Tooltip
              parent={this.el}
              showOnHover={true}
              position="bottom"
              progressBar={true}
              text={
                "Created at: " +
                moment.unix(qualification.created_at / 1000).format("llll") +
                ", Last update on: " +
                moment.unix(qualification.updated_at / 1000).format("llll")
              }
            />
          </div>
        </div>
        <Categories
          qualification={qualification}
          client={client}
          updateTask={updateTask}
          updateSubTask={updateSubTask}
          deleteSubTask={deleteSubTask}
          deleteFile={deleteFile}
          onNewQualification={updateQualification}
          organization={organization}
          driveFiles={files}
          driveFolder={filesFolder}
        />
      </div>
    );
  }
}
