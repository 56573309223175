import ModelStore from "./ModelStore";
import request from "superagent";
import auth from "./Auth";

export const PersonStore = function (id) {
  this.id = id;
  this.path = "/people/{id}";

  return this;
};
PersonStore.prototype = new ModelStore();

PersonStore.prototype.updateModel = function (person, patchData) {
  return auth.getAuthData().then((authData) =>
    request.patch(this.url()).send(patchData).query({
      access_token: authData.token,
      client: authData.client,
    })
  );
};

export default PersonStore;
