import React from "react";
import OrganizationHeader from "./OrganizationHeader";
import OrganizationTabs from "./OrganizationTabs";
import PropTypes from "prop-types";

export default class OrganizationTopBar extends React.Component {
  render() {
    return (
      <React.Fragment>
        <OrganizationHeader {...this.props} />
        <OrganizationTabs {...this.props} />
      </React.Fragment>
    );
  }
}
