import React from "react";
import RaisedButtonWithIcon from "./organization/RaisedButtonWithIcon";

import auth from "../storage/Auth";

class Login extends React.Component {
  componentDidMount() {
    this.unsubscribe = auth.subscribe(() => {
      this.props.router.push("/");
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return (
      <div className="login-page inreach-bg">
        <div className="login-container">
          <div className="logo" />
          <RaisedButtonWithIcon
            label="Login"
            icon="login primary"
            alwaysExpanded={true}
            onClick={auth.showLock}
          />
        </div>
      </div>
    );
  }
}

export default Login;
