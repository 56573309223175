import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import Carousel, { Modal, ModalGateway } from "react-images";

import imageUrl from "../../../../../lib/imageUrl";

//import Image from '../../../../Image';

class Images extends React.Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
  };

  state = {
    imageModalOpen: false,
  };

  handleToggleModal = () => {
    this.setState({
      imageModalOpen: !this.state.imageModalOpen,
    });
  };

  render() {
    const { images = [] } = this.props;
    const { imageModalOpen } = this.state;
    return (
      <div className="data-wrapper images">
        <div className="data-header">Images</div>
        <Choose>
          <When condition={images.length === 0}>
            <div className="data-content text empty">No images</div>
          </When>
          <Otherwise>
            <div className="data-content list horizontal">
              {images.slice(0, 4).map((image) => (
                <div className="data-content-list-item image" key={image}>
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url('${imageUrl({
                        url: image,
                        width: 400,
                      })}')`,
                    }}
                    onClick={this.handleToggleModal}
                  />
                </div>
              ))}
            </div>
          </Otherwise>
        </Choose>
        <ModalGateway>
          {imageModalOpen ? (
            <Modal onClose={this.handleToggleModal}>
              <Carousel
                views={images.reduce((newImages, image) => {
                  newImages.push({
                    src: imageUrl({ url: image, width: 400 }),
                  });
                  return newImages;
                }, [])}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  }
}

export default withRouter(Images);
