import React, { Component } from "react";
import auth from "../../../storage/Auth";
import MergeOrganization from "./MergeOrganization";

export default class MergeOrganizationRoute extends Component {
  onSuccessfulMerge = (id) => {
    this.props.router.push(`/organizations/${id}`);
  };

  componentWillMount() {
    auth.getAuthData().then(({ client }) => this.setState({ client }));
  }

  render() {
    const { source, target } = this.props.params;

    return (
      <MergeOrganization
        source={source}
        target={target}
        handleSuccessfulMerge={this.onSuccessfulMerge}
      />
    );
  }
}
