import React from "react";
import PropTypes from "prop-types";

import TextField from "material-ui/TextField";

import SocialLink from "../../organization/SocialLink";

import xss from "xss";
import _ from "underscore";
import EditImage from "./EditImage";

export default class EditBasicInfo extends React.Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    updateFields: PropTypes.func.isRequired,
  };

  state = {
    image: "",
    name: "",
    socials: {
      website: "",
      linkedin: "",
      crunchbase: "",
      angellist: "",
    },
    error: false,
  };

  componentWillMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.data, this.props.data)) {
      this.initialize();
    }
  }

  initialize = () => {
    const {
      data: {
        image_url: image = "",
        name = "",
        homepage_url: website = "",
        linkedin = "",
        crunchbase = "",
        angellist,
      } = {},
    } = this.props;
    this.setState({
      image,
      name,
      error: name === "",
      socials: {
        website,
        linkedin,
        crunchbase,
        angellist,
      },
    });
  };

  handleChange = (event) => {
    const name = xss(event.target.value);
    this.setState({
      name,
      error: name === "",
    });
    this.debounceChange("name", name.trim());
  };

  handleImageChange = (image) => {
    this.setState({
      image,
    });
    this.debounceChange("image_url", image);
  };

  debounceChange = _.debounce((field, value) => {
    this.setState({
      [field]: value,
    });
    this.props.updateFields([{ field: field, value: value }]);
  }, 1500);

  render() {
    const {
      image = "",
      name = "",
      error = false,
      socials: {
        website = "",
        linkedin = "",
        crunchbase = "",
        angellist = "",
      } = {},
    } = this.state;
    const { source } = this.props;
    const searchType = source === "edit-person" ? "people" : "companies";
    return (
      <div className="edit-basic-info">
        <div className="edit-image">
          <EditImage imageSrc={image} updateImageSrc={this.handleImageChange} />
        </div>
        <div className="edit-info">
          <TextField
            className="edit-name edit-organization-input-field medium"
            value={name}
            onChange={this.handleChange}
            errorText={error ? "Required" : false}
            name="edit-name"
          />
          <div className="social-links">
            <If condition={website !== ""}>
              <SocialLink source="website" link={website} />
            </If>
            <SocialLink
              source="linkedin"
              link={
                linkedin === ""
                  ? `https://www.linkedin.com/search/results/${searchType}?keywords=${encodeURIComponent(
                      name
                    )}`
                  : linkedin
              }
            />
            <If condition={crunchbase !== ""}>
              <SocialLink source="crunchbase" link={crunchbase} />
            </If>
            <If condition={angellist !== ""}>
              <SocialLink source="angellist" link={angellist} />
            </If>
            <SocialLink
              source="google"
              link={`https://www.google.com/search?q=${name}`}
            />
          </div>
        </div>
      </div>
    );
  }
}
