import React from "react";
import PropTypes from "prop-types";

import Tooltip from "../Tooltip";
import sourceImage from "../../lib/sourceImage";

export default class Source extends React.Component {
  static propTypes = {
    provider: PropTypes.string.isRequired,
    isOrigin: PropTypes.bool.isRequired,
  };

  state = {
    showTooltip: false,
  };

  render() {
    const { provider, isOrigin } = this.props;
    return (
      <li
        ref={(el) => (this._sourcecell = el)}
        className="source_list"
        key={provider}
        onMouseLeave={() => {
          this.setState({ showTooltip: false });
        }}
        onMouseEnter={() => {
          this.setState({ showTooltip: true });
        }}
      >
        <Tooltip
          parent={this._sourcecell}
          showTooltip={this.state.showTooltip}
          text={`${isOrigin ? "origin:" : ""} ${provider}`}
        />
        <div
          className={`source_logo ${isOrigin ? "origin" : ""}`}
          style={{ background: `url(${sourceImage(provider)})` }}
        />
      </li>
    );
  }
}
