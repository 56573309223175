import React from "react";
import NotificationDoNotDisturb from "material-ui/svg-icons/notification/do-not-disturb";

class Unauthorised extends React.Component {
  render() {
    return (
      <div className="unauthorized page-width">
        <h1> Unauthorised </h1>
        <NotificationDoNotDisturb
          style={{
            width: "300px",
            height: "300px",
          }}
          color="lightgray"
        />
        <h4> Unfortunately you are not authorised to see this </h4>
      </div>
    );
  }
}

export default Unauthorised;
