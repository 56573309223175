import React from "react";
import PropTypes from "prop-types";

export default class CustomModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool,
    customStyles: PropTypes.object,
  };

  buildClassName = () => {
    const { show, fullScreen } = this.props;
    const classNames = ["custom-modal"];
    if (show) classNames.push("show");
    if (fullScreen) classNames.push("full-screen");
    return classNames.join(" ");
  };

  render() {
    return (
      <div
        data-test="custom-modal"
        className={this.buildClassName()}
        style={this.props.customStyles || {}}
      >
        {this.props.children}
      </div>
    );
  }
}
