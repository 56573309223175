import React from "react";
import auth from "../storage/Auth";

class Profile extends React.Component {
  state = {
    profile: null,
    roles: [],
  };

  componentDidMount() {
    auth
      .getAuthData()
      .then(({ profile, roles }) => {
        if (typeof ga !== "undefined") {
          ga("set", "&uid", btoa(profile.sub));
        }
        this.setState({ profile, roles });
      })
      .catch((err) => {
        console.error("Error loading the Profile", err);
      });
  }

  render() {
    const { profile, roles } = this.state;

    return (
      <div className="profile-navigation">
        <Choose>
          <When condition={!profile}>
            <div className="loading">Loading</div>
          </When>
          <Otherwise>
            <img className="profile-image" src={profile.picture} />
            <p className="name"> {profile.name} </p>
            <p className="email"> {profile.email} </p>
            <If condition={roles.length > 0}>
              <p className="roles"> {roles.join(", ")} </p>
            </If>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default Profile;
