import auth from "./Auth";
import Config from "../config";
import request from "superagent";

export default class WorkflowSyncStore {
  constructor() {
    this.isEnabled = Config.workflow_sync.is_enabled;
    this.baseUrl = `${Config.workflow_sync.host || ""}/sync`;
  }

  syncOrganization(organization, userId, digSource) {
    if (!this.isEnabled) {
      return Promise.resolve();
    }
    const id = organization.id;
    const body = {
      id,
    };
    if (userId) {
      body.user_id = userId;
    }
    if (digSource) {
      body.dig_source = digSource;
    }
    console.log(`Syncing organization: ${id}`);

    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(this.baseUrl)
          .send(body)
          .set("Accept", "application/json")
          .set("Authorization", `Bearer ${authData.token}`)
      )
      .then((response) => {
        return response.body;
      })
      .then(({ organization }) => organization)
      .catch((error) => {
        if (
          error.message.includes("timeout") ||
          error.message.includes("terminated")
        ) {
          return Promise.resolve(organization);
        }
        return Promise.reject(error);
      });
  }
}
