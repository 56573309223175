import React from "react";
import { Card } from "material-ui/Card";
import Photos from "./Photos";

class CompletedEvaluation extends React.Component {
  render() {
    return (
      <div className="completed-evaluation">
        <Photos />
      </div>
    );
  }
}

export default CompletedEvaluation;
