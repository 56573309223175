import React from "react";
import PropTypes from "prop-types";
import { evaluationsStages } from "./../../config/evaluation_stages";
import FlatButton from "material-ui/RaisedButton";
import classNames from "classnames";
import CircularProgress from "material-ui/CircularProgress";
import CustomizeCampaign from "../assign/CustomizeCampaign";
import _ from "underscore";
import moment from "moment";
import { withRouter } from "react-router-v4";
import AssignCampaigns from "../../lib/AssignCampaigns";
import CampaignsStore from "../../storage/CampaignsStore";
import ReconnectStore from "../../storage/ReconnectStore";
import DecisionStore from "../../storage/DecisionStore";
import PreQualificationStore from "../../storage/PreQualificationStore";
import checklistTasks from "../../content/checklistTasks";
import CalendarEventsStore from "../../storage/CalendarEventsStore";
import OrganizationStore from "../../storage/OrganizationStore";
import { getDateAndTime } from "../../lib/helpers";
import Warning from "material-ui/svg-icons/alert/warning";
import PassIcon from "material-ui/svg-icons/content/block";
import KeepIcon from "material-ui/svg-icons/action/done";
import SkipIcon from "material-ui/svg-icons/action/highlight-off";
import ReconnectIcon from "material-ui/svg-icons/action/update";
import EmailIcon from "material-ui/svg-icons/communication/mail-outline";
import NextStepsIcon from "material-ui/svg-icons/navigation/last-page";
import UnableToContactIcon from "material-ui/svg-icons/action/search";
import RestartIcon from "material-ui/svg-icons/av/fast-rewind";
import ScheduleIcon from "material-ui/svg-icons/communication/contact-phone";
import objectHash from "object-hash";
import QualificationSelectors from "./QualificationSelectors";
import Organizations from "../../lib/Organizations";
import {
  qualificationProgress,
  qualificationCategoryNames,
} from "../organization/qualification/qualification";
import { IconButton } from "material-ui";
import WorkflowSyncStore from "../../storage/WorkflowSyncStore";

const defaultLabels = {
  never: "Never",
  not_now: "Not Now",
  missed_opportunity: "Missed Opportunity",
  contact: "In Progress",
};

const mlUpdatedReasons = [
  "DIG_ML_UPDATED",
  "MORE_MATURE",
  "SIMILARITY",
  "STRUCTURED_FEEDBACK",
];

function buttonClass(value, state) {
  return classNames("button", {
    "button-left": value === "pass",
    "button-center-left": value === "reconnect",
    "button-center-right": value === "makeContact",
    "state-active": value === state,
  });
}

const CAMPAIGN_PREFIXES_WITH_ASSIGNEES = [
  "interesting_make_contact",
  "interesting_partial_make_contact",
  "make_contact",
  "reconnect_make_contact",
];

class EvaluationBar extends React.Component {
  static propTypes = {
    forms: PropTypes.array,
    formStatus: PropTypes.string,
    organization: PropTypes.object.isRequired,
    organizationDecision: PropTypes.object.isRequired,
    existingCampaign: PropTypes.object,
    existingSequenceStates: PropTypes.object,
    client: PropTypes.string.isRequired,
    reloadOrganization: PropTypes.func.isRequired,
    onStageChange: PropTypes.func.isRequired,
    tabsContent: PropTypes.object.isRequired,
    reconnect: PropTypes.object,
    handlePreQualificationGoToQualificationClick: PropTypes.func.isRequired,
    investmentValues: PropTypes.object.isRequired,
    showErrorDialog: PropTypes.func.isRequired,
    getLatestCall: PropTypes.func.isRequired,
    handleOrganizationChange: PropTypes.func.isRequired,
    onMakeContactChecklistChange: PropTypes.func.isRequired,
    resetSocialMessagesReminders: PropTypes.func.isRequired,
    recentMessages: PropTypes.object,
    structuredReasons: PropTypes.object.isRequired,
    openStructuredFeedback: PropTypes.func.isRequired,
  };

  campaignStore = new CampaignsStore(this.props.organization.id);
  reconnectStore = new ReconnectStore(this.props.organization.id);
  decisionStore = new DecisionStore(this.props.organization.id);
  workflowSyncStore = new WorkflowSyncStore();
  preQualificationStore = new PreQualificationStore(this.props.organization.id);
  calendarEventsStore = new CalendarEventsStore(this.props.organization.id);
  organizationStore = new OrganizationStore(this.props.organization.id);
  qualificationProgress = 0;

  state = {
    sendingCampaign: false,
    campaignSelected: null,
    isEditingCampaign: false,
    evaluationStage: null,
    calendarEvents: null,
    isSelectingQualification: false,
    isComplete: null,
    actionSelected: null,
    from: null,
    assignedTo: null,
    organizer: null,
    campaignPrefix: null,
    stoppedMlUpdatedOrKeptDecision: "",
  };

  exitingOldForms = (organization, organizationDecision, interesting) => {
    return (
      organization &&
      organization.source_refs &&
      (organization.source_refs.FUNDING ||
        organization.source_refs.V2FUNDING ||
        organization.source_refs.V3FUNDING) &&
      organizationDecision.interesting === interesting
    );
  };

  scoreCardHasContent = () => {
    const { scorecard: { calls = [] } = {}, getLatestCall } = this.props;

    if (calls.length > 0) {
      const { no_show: noShow = false, notes = {} } = getLatestCall();
      const hasNotes =
        notes &&
        Object.values(notes).some(
          ({ formatted_content: content }) => !!content
        );
      if (hasNotes && !noShow) {
        return true;
      }
    }
    return false;
  };

  componentDidMount() {
    const { organizationDecision, tabsContent: { qualification = {} } = {} } =
      this.props;
    if (
      ["make_contact", "call_meeting", "pre_qualification"].includes(
        organizationDecision.stage
      )
    ) {
      this.calendarEventsStore.calendarEvents().then((calendarEvents) => {
        this.setState({ calendarEvents: calendarEvents });
      });
    }
    this.qualificationProgress = qualificationProgress(qualification);
  }

  static getDerivedStateFromProps(props, state) {
    // const isMLUpdated =
    //   props.organizationDecision?.source_filters?.name === 'ml_updated';
    if (
      // isMLUpdated &&
      props?.organizationDecision?.notes?.includes("#keep")
    ) {
      state.stoppedMlUpdatedOrKeptDecision += " keep";
    }
    if (
      // isMLUpdated &&
      props?.organizationDecision?.notes?.includes("#stop_ml_updated")
    ) {
      state.stoppedMlUpdatedOrKeptDecision += " stop_ml_updated";
    }
  }

  componentDidUpdate(previousProps) {
    const {
      organizationDecision: {
        assigned_to: previousAssignedTo,
        organizer: previousOrganizer,
      },
    } = previousProps;
    const {
      organizationDecision: {
        assigned_to: currentAssignedTo,
        organizer: currentOrganizer,
      },
      tabsContent: { qualification: currentQualification = {} } = {},
    } = this.props;
    const { from, stateAssignedTo, campaignSelected } = this.state;

    // So the user left the modal and changed the assigned_to / organizer
    if (
      campaignSelected &&
      (previousAssignedTo !== currentAssignedTo ||
        previousOrganizer !== currentOrganizer)
    ) {
      const newFrom =
        previousAssignedTo === stateAssignedTo &&
        from === stateAssignedTo &&
        currentAssignedTo !== stateAssignedTo
          ? currentAssignedTo
          : this.defaultFrom(this.getCampaignPrefix(campaignSelected));
      this.handlePeopleChanges(newFrom, currentAssignedTo, currentOrganizer);
    }
    this.qualificationProgress = qualificationProgress(currentQualification);
  }

  isInMlInbox = () => {
    const { organization, client } = this.props;
    const { inboxes: { [client]: inboxes = [] } = {} } = organization;

    return inboxes.some((inbox) => {
      return inbox.reason
        ? mlUpdatedReasons.includes(inbox.reason.toUpperCase()) &&
            inbox.inbox === true
        : false;
    });
  };

  isChecklistComplete = (organizationDecision) => {
    const stageChecklist = Object.keys(checklistTasks).filter(
      (key) => checklistTasks[key].checklist === organizationDecision.stage
    );
    const currentCheckList = organizationDecision.make_contact_checklist
      ? Object.keys(organizationDecision.make_contact_checklist).filter(
          (task) => stageChecklist.includes(task)
        )
      : [];
    return currentCheckList.length === stageChecklist.length;
  };

  getEvaluationStage = () => {
    const {
      organization,
      organizationDecision,
      formStatus,
      existingCampaign,
      existingSequenceStates,
      reconnect,
      client,
      tabsContent,
      getLatestCall,
      scorecard,
    } = this.props;

    const isComplete = formStatus ? formStatus === "Complete" : true;
    const { calendarEvents } = this.state;
    const evaluationConfig = evaluationsStages[organizationDecision.stage];
    switch (organizationDecision.state) {
      case "contact":
        switch (organizationDecision.stage) {
          case "more_info":
            if (
              this.exitingOldForms(
                organization,
                organizationDecision,
                undefined
              )
            ) {
              return isComplete
                ? evaluationConfig.outbound_form
                : evaluationConfig.outbound_partial_form;
            } else if (
              this.exitingOldForms(organization, organizationDecision, false) &&
              isComplete
            ) {
              return evaluationConfig.post_outbound_complete_form;
            } else if (
              organizationDecision.fallback_to_call &&
              !existingSequenceStates
            ) {
              return evaluationConfig.fall_back;
            } else if (existingCampaign && !_.isEmpty(existingCampaign)) {
              return evaluationConfig.manual_help_issues;
            } else {
              return evaluationConfig.none;
            }
          case "make_contact":
            if (
              calendarEvents &&
              calendarEvents.length &&
              calendarEvents[0].start >= Date.now()
            ) {
              return evaluationConfig.call_scheduled;
            } else if (this.isChecklistComplete(organizationDecision)) {
              return evaluationConfig.checklist_done;
            } else {
              return evaluationConfig.checklist_ongoing;
            }
          case "call_meeting":
            if (
              calendarEvents &&
              calendarEvents.length &&
              calendarEvents[0].start >= Date.now()
            ) {
              return evaluationConfig.call_scheduled;
            } else if (
              // tabsContent &&
              // tabsContent.scorecard &&
              !_.isEmpty(scorecard) &&
              scorecard.calls
            ) {
              const lastCall = getLatestCall();
              if (lastCall.no_show) {
                return evaluationConfig.after_call_no_show;
              } else if (lastCall.recommendations || lastCall.decision) {
                if (lastCall.decision) {
                  if (
                    ["CALL", "MEETING"].includes(lastCall.decision.decision)
                  ) {
                    if (lastCall.template_id === "formcall") {
                      return evaluationConfig.after_call_completed_positive_form_call;
                    } else {
                      return evaluationConfig.after_call_completed_positive;
                    }
                  } else if (lastCall.decision.decision === "PASS") {
                    return evaluationConfig.after_call_completed_pass;
                  } else if (lastCall.decision.decision === "RECONNECT") {
                    return evaluationConfig.after_call_completed_reconnect;
                  } else if (lastCall.decision.decision === "WRONG_CALL") {
                    return evaluationConfig.after_call_completed_wrong_call;
                  } else {
                    return evaluationConfig.after_call_completed_email;
                  }
                } else {
                  return evaluationConfig.after_call_decision;
                }
              } else {
                return evaluationConfig.after_call_recommendation;
              }
            } else {
              return evaluationConfig.call_event_failure;
            }

          case "pre_qualification":
            const lastCall =
              // tabsContent &&
              !_.isEmpty(scorecard) && scorecard.calls ? getLatestCall() : {};

            if (
              calendarEvents &&
              calendarEvents.length &&
              calendarEvents[0].start >= Date.now()
            ) {
              return evaluationConfig.call_scheduled;
            } else if (
              // tabsContent &&
              !_.isEmpty(scorecard) &&
              scorecard.calls &&
              lastCall.template_id === "prequalificationcall"
            ) {
              if (lastCall.no_show) {
                return evaluationConfig.after_call_no_show;
              } else if (lastCall.recommendations || lastCall.decision) {
                if (lastCall.decision) {
                  if (
                    ["CALL", "MEETING"].includes(lastCall.decision.decision)
                  ) {
                    return evaluationConfig.after_call_completed_positive;
                  } else if (lastCall.decision.decision === "PASS") {
                    return evaluationConfig.after_call_completed_pass;
                  } else if (lastCall.decision.decision === "RECONNECT") {
                    return evaluationConfig.after_call_completed_reconnect;
                  } else if (lastCall.decision.decision === "WRONG_CALL") {
                    return evaluationConfig.after_call_completed_wrong_call;
                  } else {
                    return evaluationConfig.after_call_completed_email;
                  }
                } else {
                  return evaluationConfig.after_call_decision;
                }
              } else {
                return evaluationConfig.after_call_recommendation;
              }
            } else {
              return evaluationConfig.call_event_failure;
            }
          case "qualification":
            if (this.qualificationProgress === 100) {
              return evaluationConfig.checklist_done;
            } else {
              return evaluationConfig.checklist_ongoing;
            }
          case "passed":
            if (this.isInMlInbox()) {
              return evaluationConfig.ml_updated;
            } else {
              return evaluationConfig.none;
            }
          case "in_tracking":
            if (!_.isEmpty(reconnect) && reconnect.when > Date.now()) {
              return evaluationConfig.waiting_period;
            } else if (this.isInReconnectInbox(organization, client)) {
              return evaluationConfig.reply_received;
            } else if (existingSequenceStates) {
              return evaluationConfig.reconnect_campaign;
            } else if (
              !_.isEmpty(reconnect) &&
              reconnect.when < Date.now() &&
              !existingSequenceStates
            ) {
              return evaluationConfig.reconnect_campaign_done;
            } else if (existingCampaign && !_.isEmpty(existingCampaign)) {
              return evaluationConfig.manual_help_issues;
            } else {
              return evaluationConfig.reconnect_not_found;
            }

          case "unable_to_contact":
            if (
              organizationDecision.make_contact_checklist &&
              this.isChecklistComplete(organizationDecision)
            ) {
              return evaluationConfig.checklist_done;
            } else {
              return evaluationConfig.checklist_ongoing;
            }
          default:
            return {};
        }

      case "not_now":
      case "missed_opportunity":
      case "never":
        if (this.isInMlInbox()) {
          return evaluationsStages.not_now_never.ml_updated;
        } else if (
          this.exitingOldForms(organization, organizationDecision, undefined)
        ) {
          return isComplete
            ? evaluationsStages.not_now_never.inbound_form
            : evaluationsStages.not_now_never.inbound_partial_form;
        } else {
          return evaluationsStages.not_now_never.none;
        }

      case "unseen":
      default:
        if (
          this.exitingOldForms(organization, organizationDecision, undefined)
        ) {
          return isComplete
            ? evaluationsStages.unseen.inbound_form
            : evaluationsStages.unseen.inbound_partial_form;
        } else if (
          _.some(organizationDecision.activity, (list) => list.analysts.length)
        ) {
          return evaluationsStages.unseen.not_final;
        } else {
          return evaluationsStages.unseen.none;
        }
    }
  };

  goToTab = (goTo) => {
    const { organization, router, updateSelectedTab } = this.props;
    updateSelectedTab(goTo);
    router.push({
      pathname: `/organizations/${organization.id}/${goTo}`,
    });
  };

  sendPreQualificationPassCampaign = (
    campaignSelected,
    campaignConfiguration
  ) => {
    const {
      organization,
      reloadOrganization,
      showErrorDialog,
      organizationDecision,
    } = this.props;
    const { from, assignedTo, organizer } = this.state;
    const { passReason, createdStructuredReasons } = campaignConfiguration;
    const assignCampaign = AssignCampaigns.processCampaign(
      campaignSelected,
      campaignConfiguration,
      organizationDecision,
      from,
      assignedTo,
      organizer,
      "contact",
      "passed"
    );
    return Promise.all([
      this.decisionStore.decide(assignCampaign),
      this.preQualificationStore.goToPass(passReason, createdStructuredReasons),
    ])
      .then(() => {
        reloadOrganization(organization.id);
      })
      .catch((error) => {
        console.error(error);
        showErrorDialog(AssignCampaigns.processError(error));
      });
  };

  sendPreQualificationReconnectCampaign = (
    campaignSelected,
    campaignConfiguration
  ) => {
    const {
      showErrorDialog,
      reloadOrganization,
      organizationDecision,
      organization,
    } = this.props;
    const { from, assignedTo, organizer } = this.state;
    const assignCampaign = AssignCampaigns.processCampaign(
      campaignSelected,
      campaignConfiguration,
      organizationDecision,
      from,
      assignedTo,
      organizer,
      "contact",
      "in_tracking"
    );
    const { when, reconnectReason, createdStructuredReasons } =
      campaignConfiguration;

    return Promise.all([
      this.reconnectStore.reconnect(assignCampaign),
      this.preQualificationStore.goToReconnect(
        {
          when: when.getTime(),
          reason: reconnectReason,
        },
        createdStructuredReasons
      ),
    ])
      .then(() => {
        reloadOrganization(organization.id);
      })
      .catch((error) => {
        showErrorDialog(AssignCampaigns.processError(error));
      });
  };

  sendReconnectCampaign = (campaignSelected, campaignConfiguration) => {
    const {
      reloadOrganization,
      organization,
      organizationDecision,
      showErrorDialog,
    } = this.props;
    const { from, assignedTo, organizer } = this.state;
    const assignCampaign = AssignCampaigns.processCampaign(
      campaignSelected,
      campaignConfiguration,
      organizationDecision,
      from,
      assignedTo,
      organizer
    );

    return this.reconnectStore
      .reconnect(assignCampaign)
      .then((organization) =>
        this.workflowSyncStore.syncOrganization(organization)
      )
      .then(() => {
        reloadOrganization(organization.id);
        this.setState({ sendingCampaign: false });
      })
      .catch((error) => {
        showErrorDialog(AssignCampaigns.processError(error));
      });
  };

  campaignToInteresting = (campaign) => {
    switch (campaign) {
      case "pass_after_form":
        return false;
      case "interesting_partial_make_contact":
      case "interesting_make_contact":
        return true;
      default:
        return false;
    }
  };

  campaignToState = (campaign, existingDecision) => {
    const { state } = existingDecision || {};
    switch (campaign) {
      case "pass_after_form":
        return state || "not_now";
      case "interesting_partial_make_contact":
      case "interesting_make_contact":
        return "contact";
    }
  };

  campaignToStage = (campaign) => {
    switch (campaign) {
      case "pass_after_form":
        return "passed";
      case "interesting_partial_make_contact":
      case "interesting_make_contact":
        return "make_contact";
      case "send_form":
        return "more_info";
      default:
        return campaign;
    }
  };

  getCampaignPrefix = (campaignSelected) => {
    if (campaignSelected.includes("send_form")) {
      return "send_form";
    } else if (campaignSelected.includes("interesting_make_contact")) {
      return "interesting_make_contact";
    } else if (campaignSelected.includes("interesting_partial_make_contact")) {
      return "interesting_partial_make_contact";
    } else if (campaignSelected.includes("reconnect_make_contact")) {
      return "reconnect_make_contact";
    } else if (campaignSelected.includes("reconnect_passed_roberto")) {
      return "reconnect_passed_roberto";
    } else if (campaignSelected.includes("reconnect_passed_assignee")) {
      return "reconnect_passed_assignee";
    } else if (campaignSelected.includes("fallback")) {
      return "fallback";
    } else if (campaignSelected.includes("reconnect")) {
      return "reconnect";
    } else if (campaignSelected.includes("make_contact")) {
      return "make_contact";
    } else if (campaignSelected.includes("pass_after_form")) {
      return "pass_after_form";
    } else {
      return campaignSelected;
    }
  };

  submitFormCampaign = (campaignSelected, campaignConfiguration) => {
    const { client, organization, handleSubmit } = this.props;
    const { isComplete, from, assignedTo, organizer } = this.state;
    const { campaignEnabled } = campaignConfiguration;
    const campaignPrefix = this.getCampaignPrefix(campaignSelected);
    const isInteresting = this.campaignToInteresting(campaignPrefix);
    const existingDecision = Object.assign(
      {
        sourceFilters: {
          id: "inbox-form",
          name: "Inbox Form",
          route: "inbox",
          params: {
            reason: "form",
          },
        },
        origin: "inbox",
        source: "dig",
        client: client,
        organization_id: organization.id,
      },
      Organizations.latestFinalOrInvestorDecision(organization, client) || {}
    );

    const assignCampaign = AssignCampaigns.processCampaign(
      campaignSelected,
      campaignConfiguration,
      existingDecision,
      from,
      assignedTo,
      organizer,
      this.campaignToState(campaignPrefix, existingDecision),
      this.campaignToStage(campaignPrefix)
    );

    const isOutbound = assignCampaign.decision.form;

    assignCampaign.interesting = isInteresting;
    if (!isComplete && isOutbound && campaignPrefix === "make_contact") {
      assignCampaign.jump_to_call = true;
      assignCampaign.decision.notes = "Jump to call";
    }
    if (!isComplete && campaignEnabled) {
      assignCampaign.is_complete = true;
    }

    return this.handleFormEvaluationSubmit(assignCampaign);
  };

  handleFormEvaluationSubmit = (assignCampaign) => {
    const { showErrorDialog, openStructuredFeedback } = this.props;
    this.setState({ updating: true });
    return this.handleFormEvaluationChange(assignCampaign)
      .then((organization) => this.setState({ updating: false }))
      .then(() => {
        if (assignCampaign.interesting) {
          openStructuredFeedback("contact");
        }
      })
      .catch((error) => {
        this.setState({ updating: false });
        showErrorDialog(AssignCampaigns.processError(error));
      });
  };

  handleFormEvaluationChange = (assignCampaign) => {
    const { showErrorDialog, handleOrganizationChange, organization } =
      this.props;
    return this.organizationStore
      .setIsInteresting(assignCampaign)
      .then((organization) => {
        if (assignCampaign.interesting) {
          return this.workflowSyncStore.syncOrganization(organization);
        }
        return Promise.resolve(organization);
      })
      .then(handleOrganizationChange)
      .catch((error) => {
        console.log("error", error);
        showErrorDialog(error);
      });
  };

  sendPassCampaign = (campaignSelected, campaignConfiguration) => {
    const {
      organization,
      reloadOrganization,
      organizationDecision,
      showErrorDialog,
    } = this.props;
    const { from, assignedTo, organizer } = this.state;

    const assignCampaign = AssignCampaigns.processCampaign(
      campaignSelected,
      campaignConfiguration,
      organizationDecision,
      from,
      assignedTo,
      organizer,
      "contact",
      "passed"
    );

    return this.decisionStore
      .decide(assignCampaign)
      .then(() => {
        reloadOrganization(organization.id);
        this.setState({ sendingCampaign: false });
      })
      .catch((error) => {
        showErrorDialog(AssignCampaigns.processError(error));
      });
  };

  campaignToForm = (campaign) => {
    switch (campaign) {
      case "send_form":
        return true;
      default:
        return false;
    }
  };

  sendRestartCampaign = (campaignSelected, campaignConfiguration) => {
    const {
      organization,
      reloadOrganization,
      organizationDecision,
      showErrorDialog,
      onMakeContactChecklistChange,
      resetSocialMessagesReminders,
    } = this.props;
    const { from, assignedTo, organizer } = this.state;
    const campaignPrefix = this.getCampaignPrefix(campaignSelected);
    this.setState({ loading: true });

    const assignCampaign = AssignCampaigns.processCampaign(
      campaignSelected,
      campaignConfiguration,
      organizationDecision,
      from,
      assignedTo,
      organizer,
      "contact",
      this.campaignToStage(campaignPrefix),
      this.campaignToForm(campaignPrefix)
    );
    return this.decisionStore
      .decide(assignCampaign)
      .then((organization) =>
        this.workflowSyncStore.syncOrganization(organization)
      )
      .then(() => onMakeContactChecklistChange({}))
      .then(() => resetSocialMessagesReminders())
      .then(() => {
        reloadOrganization(organization.id);
        this.setState({ sendingCampaign: false });
      })
      .catch((error) => {
        showErrorDialog(AssignCampaigns.processError(error));
      });
  };

  sendMakeContactCampaign = (campaignSelected, campaignConfiguration) => {
    const {
      organization,
      reloadOrganization,
      organizationDecision,
      showErrorDialog,
    } = this.props;
    const { from, assignedTo, organizer } = this.state;
    const assignCampaign = AssignCampaigns.processCampaign(
      campaignSelected,
      campaignConfiguration,
      organizationDecision,
      from,
      assignedTo,
      organizer,
      "contact",
      "make_contact"
    );
    return this.decisionStore
      .decide(assignCampaign)
      .then((organization) =>
        this.workflowSyncStore.syncOrganization(organization)
      )
      .then((organization) => {
        reloadOrganization(organization.id);
        this.setState({ sendingCampaign: false });
      })
      .catch((error) => {
        showErrorDialog(AssignCampaigns.processError(error));
      });
  };

  sendEmailCampaign = (campaignSelected, campaignConfiguration) => {
    const {
      organization,
      reloadOrganization,
      organizationDecision,
      showErrorDialog,
    } = this.props;
    const { from, assignedTo, organizer } = this.state;

    const assignCampaign =
      organizationDecision && organizationDecision.is_final
        ? AssignCampaigns.processCampaign(
            campaignSelected,
            campaignConfiguration,
            organizationDecision,
            from,
            assignedTo,
            organizer,
            organizationDecision.state,
            organizationDecision.stage
          )
        : AssignCampaigns.processCampaignWithNoDecision(
            campaignSelected,
            campaignConfiguration,
            from
          );

    return this.campaignStore
      .sendCampaign(assignCampaign)
      .then(() => {
        reloadOrganization(organization.id);
        this.setState({ sendingCampaign: false });
      })
      .catch((error) => {
        showErrorDialog(AssignCampaigns.processError(error));
      });
  };

  sendKeepCampaign = () => {
    const {
      showErrorDialog,
      handleOrganizationChange,
      organizationDecision,
      openStructuredFeedback,
    } = this.props;
    const now = Date.now();

    organizationDecision.notes = !!organizationDecision.notes
      ? organizationDecision.notes + " " + "#keep"
      : "#keep";

    const sourceFilters = {
      id: "ml_updated",
      name: "ml_updated",
      params: {
        date: now,
      },
    };
    const assignCampaign = {
      decision: {
        ...organizationDecision,
        source_filters: sourceFilters,
        created_at: now,
        updated_at: now,
      },
    };

    return this.organizationStore
      .setDecision(assignCampaign)
      .then(handleOrganizationChange)
      .then(() => openStructuredFeedback("not_now"))
      .catch((error) => {
        console.log("error", error);
        showErrorDialog(error);
      });
  };

  sendStopMlUpdatedCampaign = () => {
    const {
      showErrorDialog,
      handleOrganizationChange,
      organizationDecision,
      openStructuredFeedback,
    } = this.props;
    const now = Date.now();

    organizationDecision.notes = !!organizationDecision.notes
      ? organizationDecision.notes + " " + "#stop_ml_updated"
      : "#stop_ml_updated";

    const reason = {
      id: "stop_ml_updated",
      title: "Stop ML Updated",
      category: "general",
      investment_types: ["consumer", "saas", "marketplace", "other"],
      is_new: false,
    };

    if (!organizationDecision.reasons) {
      organizationDecision.reasons = {
        stop_ml_updated: reason,
      };
    } else {
      organizationDecision.reasons["stop_ml_updated"] = reason;
    }

    const assignCampaign = {
      decision: {
        ...organizationDecision,
        created_at: now,
        updated_at: now,
      },
    };

    return this.organizationStore
      .setDecision(assignCampaign)
      .then(handleOrganizationChange)
      .then(() => openStructuredFeedback("never"))
      .catch((error) => {
        console.log("error", error);
        showErrorDialog(error);
      });
  };

  sendScheduleCallCampaign = (campaignSelected, campaignConfiguration) => {
    const {
      organization,
      reloadOrganization,
      organizationDecision,
      showErrorDialog,
    } = this.props;
    const { from, assignedTo, organizer } = this.state;
    const assignCampaign = AssignCampaigns.processCampaign(
      campaignSelected,
      campaignConfiguration,
      organizationDecision,
      from,
      assignedTo,
      organizer,
      "contact",
      "make_contact"
    );
    return this.decisionStore
      .decide(assignCampaign)
      .then(() => {
        reloadOrganization(organization.id);
        this.setState({ sendingCampaign: false });
      })
      .catch((error) => {
        showErrorDialog(AssignCampaigns.processError(error));
      });
  };

  sendToPreQualification = (campaignSelected, campaignConfiguration) => {
    const {
      organization,
      reloadOrganization,
      organizationDecision,
      showErrorDialog,
    } = this.props;
    const { from, assignedTo, organizer } = this.state;
    const assignCampaign = AssignCampaigns.processCampaign(
      campaignSelected,
      campaignConfiguration,
      organizationDecision,
      from,
      assignedTo,
      organizer,
      "contact",
      "pre_qualification"
    );
    return this.decisionStore
      .decide(assignCampaign)
      .then(() => {
        reloadOrganization(organization.id);
        this.setState({ sendingCampaign: false });
      })
      .catch((error) => {
        showErrorDialog(AssignCampaigns.processError(error));
      });
  };

  submitCampaign = (campaignSelected, campaignConfiguration) => {
    const { organizationDecision } = this.props;
    const { actionSelected } = this.state;
    switch (campaignSelected) {
      case "interesting_make_contact":
      case "interesting_make_contact_with_assignee_and_organizer":
      case "interesting_make_contact_with_assignee":
      case "interesting_make_contact_with_organizer":
      case "interesting_partial_make_contact":
      case "interesting_partial_make_contact_with_assignee_and_organizer":
      case "interesting_partial_make_contact_with_assignee":
      case "interesting_partial_make_contact_with_organizer":
        return this.submitFormCampaign(campaignSelected, campaignConfiguration);
      case "make_contact_with_assignee_and_organizer":
      case "make_contact":
      case "make_contact_with_assignee":
      case "make_contact_with_organizer":
      case "reconnect_make_contact_with_assignee_and_organizer":
      case "reconnect_make_contact_with_organizer":
      case "reconnect_make_contact_with_assignee":
      case "reconnect_make_contact":
        if (actionSelected === "restart") {
          return this.sendRestartCampaign(
            campaignSelected,
            campaignConfiguration
          );
        } else {
          return this.sendMakeContactCampaign(
            campaignSelected,
            campaignConfiguration
          );
        }
      case "pass_after_form":
        return this.submitFormCampaign(campaignSelected, campaignConfiguration);
      case "pass_after_call":
      case "reconnect_passed_roberto":
      case "reconnect_passed_assignee":
      case "pass":
        if (
          organizationDecision &&
          organizationDecision.stage === "pre_qualification"
        ) {
          return this.sendPreQualificationPassCampaign(
            campaignSelected,
            campaignConfiguration
          );
        } else {
          return this.sendPassCampaign(campaignSelected, campaignConfiguration);
        }

      case "reconnect":
      case "schedule_reconnect":
      case "schedule_reconnect_operational":
        if (
          organizationDecision &&
          organizationDecision.stage === "pre_qualification"
        ) {
          return this.sendPreQualificationReconnectCampaign(
            campaignSelected,
            campaignConfiguration
          );
        } else {
          return this.sendReconnectCampaign(
            campaignSelected,
            campaignConfiguration
          );
        }
      case "send_form":
        return this.sendRestartCampaign(
          campaignSelected,
          campaignConfiguration
        );
      case "email":
        return this.sendEmailCampaign(campaignSelected, campaignConfiguration);
      case "schedule_call":
      case "schedule_call_by_organizer":
        return this.sendScheduleCallCampaign(
          campaignSelected,
          campaignConfiguration
        );
      case "pre_qualification":
        return this.sendToPreQualification(
          campaignSelected,
          campaignConfiguration
        );
    }
    return Promise.reject(`Unknown campaign: ${campaignSelected}`);
  };

  campaignName = (campaignPrefix, from, assignedTo, organizer) => {
    if (["pass_after_form", "send_form"].includes(campaignPrefix)) {
      return campaignPrefix;
    }
    if (CAMPAIGN_PREFIXES_WITH_ASSIGNEES.includes(campaignPrefix)) {
      if (assignedTo && assignedTo !== from) {
        if (organizer && organizer !== assignedTo && organizer !== from) {
          return campaignPrefix + "_with_assignee_and_organizer";
        }
        return campaignPrefix + "_with_assignee";
      }
      if (organizer && organizer !== assignedTo && organizer !== from) {
        return campaignPrefix + "_with_organizer";
      }
    }
    return campaignPrefix;
  };

  isInReconnectInbox = (organization, client) => {
    const { inboxes: { [client]: inboxes = [] } = {} } = organization;
    return inboxes.some(
      (inbox) => inbox.reason === "RECONNECT" && inbox.inbox === true
    );
  };

  reconnectActivated = (organizationDecision) => {
    const { organization, client } = this.props;
    if (
      organizationDecision.stage === "in_tracking" &&
      this.isInReconnectInbox(organization, client)
    ) {
      return true;
    } else {
      return false;
    }
  };

  selectCampaign = (
    actionSelected,
    evaluationStage,
    from,
    assignedTo,
    organizer
  ) => {
    const { organizationDecision } = this.props;
    const reconnectActivated = this.reconnectActivated(organizationDecision);
    switch (actionSelected) {
      case "make_contact":
        if (evaluationStage.afterForm) {
          return this.campaignName(
            evaluationStage.isComplete
              ? "interesting_make_contact"
              : "interesting_partial_make_contact",
            from,
            assignedTo,
            organizer
          );
        } else if (reconnectActivated) {
          return this.campaignName(
            "reconnect_make_contact",
            from,
            assignedTo,
            organizer
          );
        } else {
          return this.campaignName("make_contact", from, assignedTo, organizer);
        }
      case "pass":
        if (evaluationStage.afterForm) {
          return "pass_after_form";
        } else if (
          organizationDecision.stage &&
          organizationDecision.stage in
            ["call_meeting", "pre-qualification", "qualification"]
        ) {
          return "pass_after_call";
        } else if (reconnectActivated) {
          return assignedTo === from
            ? "reconnect_passed_roberto"
            : "reconnect_passed_assignee";
        } else {
          return "pass";
        }
      case "reconnect":
        return reconnectActivated
          ? "reconnect"
          : this.scoreCardHasContent()
          ? "schedule_reconnect"
          : "schedule_reconnect_operational";
      case "restart":
        if (organizationDecision.form) {
          if (organizationDecision.fallback_to_call) {
            return this.campaignName("send_form", from, assignedTo, organizer);
          } else {
            return this.campaignName("send_form", from, assignedTo, organizer);
          }
        } else {
          return this.campaignName("make_contact", from, assignedTo, organizer);
        }
      case "pre_qualification":
        return "pre_qualification";
      case "schedule_call":
        if (
          organizationDecision.assigned_to !== organizationDecision.organizer
        ) {
          return "schedule_call_by_organizer";
        } else {
          return "schedule_call";
        }
      default:
        return actionSelected;
    }
  };

  setCustomizeCampaign = (action, evaluationStage) => {
    const {
      campaignSelected,
      campaignPrefix,
      from: stateFrom,
      assignedTo: stateAssignedTo,
      organizer: stateOrganizer,
    } = this.state;
    if (campaignSelected && campaignPrefix === action) {
      this.setState({
        isEditingCampaign: true,
      });
      return;
    }
    const {
      organizationDecision: {
        assigned_to: decisionAssignedTo = "amanda@inreachventures.com",
        organizer: decisionOrganizer = "luana@inreachventures.com",
      },
    } = this.props;
    const from = stateFrom || this.defaultFrom(action);
    const assignedTo = stateAssignedTo || decisionAssignedTo;
    const organizer = stateOrganizer || decisionOrganizer;
    this.setState({
      isEditingCampaign: true,
      campaignSelected: this.selectCampaign(
        action,
        evaluationStage,
        from,
        assignedTo,
        organizer
      ),
      campaignPrefix: action,
      from,
      assignedTo,
      organizer,
    });
  };

  defaultFrom = (action) => {
    const {
        organizationDecision: { assigned_to: assignedTo, stage },
      } = this.props,
      partnerFrom =
        process.env.NODE_ENV === "test" || process.env.NODE_ENV === "codeship"
          ? "test.partner@inreachventures.com"
          : "amanda@inreachventures.com";
    if (action === "reconnect") {
      if (this.scoreCardHasContent()) {
        return assignedTo;
      }
      return partnerFrom;
    }
    switch (stage) {
      case "more_info":
      case "make_contact":
      case undefined:
        return partnerFrom;
    }
    return assignedTo;
  };

  toggleEditCampaign = (isEditingCampaign) => {
    this.setState({ isEditingCampaign: isEditingCampaign });
  };

  toggleQualificationSelectors = (isSelectingQualification) => {
    this.setState({ isSelectingQualification: isSelectingQualification });
  };

  buttonLabel = (sendingCampaign, action, organizationDecision) => {
    if (sendingCampaign) {
      return null;
    }
    if (action === "stop_ml_updated") {
      return this.state.stoppedMlUpdatedOrKeptDecision === "stop_ml_updated"
        ? "Stopped ML Updated"
        : "Stop ML Updated";
    }
    if (action === "keep") {
      const label = !!organizationDecision.stage
        ? organizationDecision.stage
        : defaultLabels[organizationDecision.state];
      return (
        (this.state.stoppedMlUpdatedOrKeptDecision === "keep"
          ? "Kept "
          : "Keep ") + label.replace(new RegExp("_", "g"), " ")
      );
    }
    return action.replace(new RegExp("_", "g"), " ");
  };

  getMargin = (hideLabel) => {
    return hideLabel ? " profile_summary" : "";
  };

  buildEvaluationActions = (evaluationStage) => {
    const { onStageChange, organizationDecision, hideLabel } = this.props;
    const { sendingCampaign } = this.state;
    const loadingIndicator = (
      <div className="loading-indicator">
        <CircularProgress size={20} />
      </div>
    );
    const icons = {
      stop_ml_updated: <SkipIcon />,
      keep: <KeepIcon />,
      pass: <PassIcon />,
      reconnect: <ReconnectIcon />,
      email: <EmailIcon />,
      nextSteps: <NextStepsIcon />,
      unable_to_contact: <UnableToContactIcon />,
      restart: <RestartIcon />,
      schedule_call: <ScheduleIcon />,
    };
    if (evaluationStage.actions) {
      return evaluationStage.actions.map((action) => {
        return (
          <React.Fragment>
            <If condition={!hideLabel}>
              <FlatButton
                key={action}
                value={action}
                icon={
                  sendingCampaign
                    ? loadingIndicator
                    : [
                        "make_contact",
                        "call_meeting",
                        "pre_qualification",
                        "qualification",
                        "term_sheet",
                      ].includes(action)
                    ? icons.nextSteps
                    : icons[action]
                }
                label={
                  hideLabel
                    ? ""
                    : this.buttonLabel(
                        sendingCampaign,
                        action,
                        organizationDecision
                      )
                }
                style={{ marginLeft: "1px", marginRight: "1px" }}
                className={buttonClass(action) + this.getMargin(hideLabel)}
                onClick={() => {
                  this.setState({ actionSelected: action }, () => {
                    if (
                      ["call_meeting", "unable_to_contact"].includes(action)
                    ) {
                      this.setState({ sendingCampaign: true });
                      onStageChange(action).then(() =>
                        this.setState({ sendingCampaign: false })
                      );
                    } else if (action === "qualification") {
                      this.toggleQualificationSelectors(true);
                    } else if (action === "stop_ml_updated") {
                      this.sendStopMlUpdatedCampaign().then(() => {
                        this.setState({
                          stoppedMlUpdatedOrKeptDecision: "stop_ml_updated",
                          stoppedMlUpdatedTaken: true,
                        });
                      });
                    } else if (action === "keep") {
                      this.sendKeepCampaign().then(() => {
                        this.setState({
                          stoppedMlUpdatedOrKeptDecision: "keep",
                          keptDecisionTaken: true,
                        });
                      });
                    } else {
                      this.setCustomizeCampaign(action, evaluationStage);
                    }
                  });
                }}
                disabled={
                  this.state.stoppedMlUpdatedOrKeptDecision.includes(action) &&
                  moment() <=
                    moment(organizationDecision.created_at).add(2, "hours")
                }
              />
            </If>
            <If condition={hideLabel}>
              <IconButton
                key={action}
                value={action}
                tooltip={this.buttonLabel(
                  sendingCampaign,
                  action,
                  organizationDecision
                ).toUpperCase()}
                style={{
                  background: "#ffffff",
                  width: "50px",
                  marginLeft: "1px",
                  marginRight: "1px",
                }}
                iconStyle={{ background: "#ffffff" }}
                onClick={() => {
                  this.setState({ actionSelected: action }, () => {
                    if (
                      ["call_meeting", "unable_to_contact"].includes(action)
                    ) {
                      this.setState({ sendingCampaign: true });
                      onStageChange(action).then(() =>
                        this.setState({ sendingCampaign: false })
                      );
                    } else if (action === "qualification") {
                      this.toggleQualificationSelectors(true);
                    } else if (action === "stop_ml_updated") {
                      this.sendStopMlUpdatedCampaign().then(() => {
                        this.setState({
                          stoppedMlUpdatedOrKeptDecision: "stop_ml_updated",
                        });
                      });
                    } else if (action === "keep") {
                      this.sendKeepCampaign().then(() => {
                        this.setState({
                          stoppedMlUpdatedOrKeptDecision: "keep",
                        });
                      });
                    } else {
                      this.setCustomizeCampaign(action, evaluationStage);
                    }
                  });
                }}
                disabled={
                  this.state.stoppedMlUpdatedOrKeptDecision.includes(action) &&
                  moment() <=
                    moment(organizationDecision.created_at).add(2, "hours")
                }
              >
                {sendingCampaign
                  ? loadingIndicator
                  : [
                      "make_contact",
                      "call_meeting",
                      "pre_qualification",
                      "qualification",
                      "term_sheet",
                    ].includes(action)
                  ? icons.nextSteps
                  : icons[action]}
              </IconButton>
            </If>
          </React.Fragment>
        );
      });
    } else {
      return null;
    }
  };

  getDefaultLabel = (goTo) => {
    if (goTo === "profile") {
      return "Open Profile Tab";
    }
    if (goTo === "scorecard") {
      return "Open Scorecard Tab";
    }
    if (goTo === "workflow") {
      return "Open Workflow Tab";
    }
    if (goTo === "qualification") {
      return "Open Qualification Tab";
    }
    return "View Here";
  };

  buildGoTo = (evaluationStage) => {
    return evaluationStage.goTo ? (
      <a
        onClick={() => this.goToTab(evaluationStage.goTo)}
        className="evaluation-link"
      >
        {!!evaluationStage.goTo
          ? this.getDefaultLabel(evaluationStage.goTo)
          : null}
      </a>
    ) : null;
  };

  getNextChecklistTask = (evaluationStage) => {
    const { organizationDecision } = this.props;
    if (
      ["make_contact", "unable_to_contact"].includes(
        organizationDecision.stage
      ) &&
      evaluationStage.requires_checklist
    ) {
      const stageChecklist = Object.keys(checklistTasks).filter(
        (key) => checklistTasks[key].checklist === organizationDecision.stage
      );

      const currentCheckList = organizationDecision.make_contact_checklist
        ? Object.keys(organizationDecision.make_contact_checklist).filter(
            (task) => stageChecklist.includes(task)
          )
        : [];

      return (
        _.where(checklistTasks, {
          checklist: organizationDecision.stage,
          order: currentCheckList ? currentCheckList.length : 0,
        })[0].text + ". "
      );
    } else if ("qualification" === organizationDecision.stage)
      return this.qualificationProgress
        ? this.qualificationProgress + "%"
        : "0%";
  };

  formatCallDate = (evaluationStage) => {
    const { calendarEvents } = this.state;
    if (evaluationStage.requires_call_date) {
      const dateTime = getDateAndTime(calendarEvents[0].start);
      return (
        <div className="evaluation-call-date">{`${dateTime.date} - ${dateTime.time}`}</div>
      );
    } else return null;
  };

  formatReconnectDate = (evaluationStage) => {
    const { when: reconnectAt = Date.now() } = this.props.reconnect || {};

    if (evaluationStage.requires_reconnect_date) {
      const dateTime = getDateAndTime(reconnectAt);
      return (
        <div className="evaluation-call-date">{`${dateTime.date} - ${dateTime.time}`}</div>
      );
    } else return null;
  };

  handleFromChange = (from) => {
    const { assignedTo, organizer } = this.state;
    this.handlePeopleChanges(from, assignedTo, organizer);
  };

  handleAssignedToChange = (assignedTo) => {
    const { from, organizer } = this.state;
    this.handlePeopleChanges(from, assignedTo, organizer);
  };

  handleOrganizerChange = (organizer) => {
    const { from, assignedTo } = this.state;
    this.handlePeopleChanges(from, assignedTo, organizer);
  };

  handlePeopleChanges = (from, assignedTo, organizer) => {
    const { campaignSelected } = this.state;
    const campaignPrefix = this.getCampaignPrefix(campaignSelected);
    this.setState({
      from,
      assignedTo,
      organizer,
      campaignSelected: this.campaignName(
        campaignPrefix,
        from,
        assignedTo,
        organizer
      ),
    });
  };

  render() {
    const {
      campaignSelected,
      isEditingCampaign,
      isSelectingQualification,
      actionSelected,
      from,
      assignedTo,
      organizer,
    } = this.state;
    const {
      organizationDecision,
      organization,
      client,
      handlePreQualificationGoToQualificationClick,
      investmentValues,
      recentMessages,
      structuredReasons = {},
    } = this.props;

    const evaluationStage = this.getEvaluationStage();

    return (
      <React.Fragment>
        <div className="evaluation-bar">
          <div className="evaluation-bar-text">
            <If condition={!!evaluationStage.type}>
              <Warning className={"icon " + evaluationStage.type} />
            </If>
            {evaluationStage.longLabel}
            {this.formatCallDate(evaluationStage)}
            {this.formatReconnectDate(evaluationStage)}
            {this.getNextChecklistTask(evaluationStage)}
            {this.buildGoTo(evaluationStage)}
          </div>
          <div className="spacer" />
          <div className="evaluation-bar-actions">
            {this.buildEvaluationActions(evaluationStage)}
          </div>
          <If condition={campaignSelected}>
            <CustomizeCampaign
              selectAssigned={true}
              organization={organization}
              from={from}
              assignedTo={assignedTo}
              organizer={organizer}
              isEditingCampaign={isEditingCampaign}
              campaignSelected={campaignSelected}
              onDone={this.toggleEditCampaign}
              submitCampaign={this.submitCampaign}
              client={client}
              decision={organizationDecision}
              evaluationStage={evaluationStage}
              defaultMailingEnabled={
                !["pass", "reconnect"].includes(actionSelected)
              }
              onChangeFrom={this.handleFromChange}
              onChangeAssignedTo={this.handleAssignedToChange}
              onChangeOrganizer={this.handleOrganizerChange}
              recentMessages={recentMessages}
              structuredReasons={structuredReasons}
            />
          </If>
          <If condition={isSelectingQualification}>
            <QualificationSelectors
              onDone={this.toggleQualificationSelectors}
              handlePreQualificationGoToQualificationClick={
                handlePreQualificationGoToQualificationClick
              }
              isSelectingQualification={isSelectingQualification}
              investmentValues={investmentValues}
            />
          </If>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(EvaluationBar);
