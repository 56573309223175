import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import FoundedOn from "./FoundedOn";
import Locations from "./Locations";
import CompressedFundingData from "./CompressedFundingData";
import FundingAmount from "../FundingAmount";
import LastFundingRound from "./LastFundingRound";
import CompressedInvestors from "./CompressedInvestors";
import Investors from "./Investors";
import CompressedInvestmentTypeStage from "./CompressedInvestmentTypeStage";
import InvestmentType from "./InvestmentType";
import InvestmentStage from "./InvestmentStage";
import Employees from "./Employees";

class CompanyInfo extends React.Component {
  static propTypes = {
    useSnapshot: PropTypes.bool.isRequired,
    companyInfo: PropTypes.object.isRequired,
    fundingRoundsLoading: PropTypes.bool.isRequired,
    investorsLoading: PropTypes.bool.isRequired,
    getInvestors: PropTypes.func,
    compressed: PropTypes.bool.isRequired,
  };

  render() {
    const {
      useSnapshot,
      companyInfo: {
        founded,
        locations,
        fundingAmount,
        fundingRounds = {},
        investors,
        investmentType,
        investmentStage,
        employees,
      },
      fundingRoundsLoading,
      investorsLoading,
      getInvestors,
      compressed = false,
    } = this.props;
    return (
      <div className="company-info">
        <div className="grid-container company-info-grid-container">
          <div className="company-info-grid-item founded-on-grid-item">
            <FoundedOn founded={founded} compressed={compressed} />
          </div>
          <div className="company-info-grid-item border-1-grid-item" />
          <div className="company-info-grid-item locations-grid-item">
            <Locations
              locations={locations}
              useSnapshot={useSnapshot}
              compressed={compressed}
            />
          </div>
          <div className="company-info-grid-item border-2-grid-item" />
          <div className="company-info-grid-item funding-grid-item">
            <Choose>
              <When condition={compressed}>
                <CompressedFundingData
                  fundingAmount={fundingAmount}
                  fundingRounds={fundingRounds}
                  fundingRoundsLoading={fundingRoundsLoading}
                  useSnapshot={useSnapshot}
                />
              </When>
              <Otherwise>
                <FundingAmount
                  fundingAmount={fundingAmount}
                  useSnapshot={useSnapshot}
                  compressed={compressed}
                />
                <LastFundingRound
                  fundingRounds={fundingRounds}
                  fundingRoundsLoading={fundingRoundsLoading}
                  useSnapshot={useSnapshot}
                />
              </Otherwise>
            </Choose>
          </div>
          <div className="company-info-grid-item border-3-grid-item" />
          <div className="company-info-grid-item investors-grid-item">
            <Choose>
              <When condition={compressed}>
                <CompressedInvestors
                  investors={investors}
                  investorsLoading={investorsLoading}
                  getInvestors={getInvestors}
                />
              </When>
              <Otherwise>
                <Investors
                  investors={investors}
                  investorsLoading={investorsLoading}
                  useSnapshot={useSnapshot}
                  compressed={compressed}
                />
              </Otherwise>
            </Choose>
          </div>
          <div className="company-info-grid-item border-4-grid-item" />
          <div className="company-info-grid-item investment-type-stage-grid-item">
            <Choose>
              <When condition={compressed}>
                <CompressedInvestmentTypeStage
                  investmentType={investmentType}
                  investmentStage={investmentStage}
                />
              </When>
              <Otherwise>
                <InvestmentType
                  investmentType={investmentType}
                  compressed={compressed}
                />
                <InvestmentStage
                  investmentStage={investmentStage}
                  compressed={compressed}
                />
              </Otherwise>
            </Choose>
          </div>
          <div className="company-info-grid-item border-5-grid-item" />
          <div className="company-info-grid-item employees-grid-item">
            <Employees
              employees={employees}
              useSnapshot={useSnapshot}
              compressed={compressed}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanyInfo);
