import React from "react";
import PropTypes from "prop-types";

import Loading from "../../../../loading/Loading";

export default class TimelineEventActions extends React.Component {
  static propTypes = {
    actions: PropTypes.array.isRequired,
    target: PropTypes.string,
  };

  getHrefTarget = (target = this.props.target) => {
    return !target ? "_blank" : target;
  };

  getActionLink = (action) => {
    if (action.loading) {
      return <Loading key={action.text} small={true} />;
    } else {
      if (action.href) {
        return (
          <a key={action.text} href={action.href} target={this.getHrefTarget()}>
            {action.text}
          </a>
        );
      } else if (action.onClick) {
        return (
          <a
            key={action.text}
            onClick={action.onClick}
            style={{
              color: action.color === "red" ? "#ab0e0e" : "#337ab7",
              marginLeft: "18px",
            }}
          >
            {action.text}
          </a>
        );
      }
    }
  };

  render() {
    const { actions } = this.props;
    if (actions.length === 0) return null;
    return (
      <div className="timeline-event-actions">
        {actions.map((action) => {
          return this.getActionLink(action);
        })}
      </div>
    );
  }
}
