import React from "react";
import PropTypes from "prop-types";

import auth from "../storage/Auth";

export default class AuthCallback extends React.Component {
  componentDidMount() {
    const { hash } = this.props.location;
    if (!hash) {
      this.props.router.push("/login");
    }

    this.unsubscribe = auth.subscribe((error, _) => {
      if (error) {
        console.error("Auth error", error);
      }
      this.props.router.push(!!error ? "/login" : "/");
    });

    auth.parseHash(hash.slice(1));
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return null;
  }
}
