import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import DialogWrapper from "../../../components/DialogWrapper";

export default class AddNoteDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    onCloseDialog: PropTypes.func.isRequired,
  };

  state = {
    value: "",
  };

  handleTextFieldChange = (e, value) => {
    this.setState({ value });
  };

  render() {
    const { open, onCloseDialog } = this.props;
    const { value } = this.state;
    const actions = [
      <FlatButton
        label="Cancel"
        style={{
          marginRight: "12px",
        }}
        onClick={() => onCloseDialog("note")}
      />,
      <FlatButton
        label="Add note"
        style={{
          backgroundColor: "#5dab49",
        }}
        onClick={this.addNote}
        labelStyle={{
          color: "#ffffff",
        }}
      />,
    ];
    return (
      <DialogWrapper
        title="Add note"
        modal={false}
        open={open}
        onRequestClose={() => onCloseDialog("note")}
        titleStyle={{
          paddingBottom: "0px",
        }}
        contentStyle={{
          position: "relative",
          width: "400px",
        }}
        repositionOnUpdate={true}
        actions={actions}
      >
        <TextField
          multiLine={true}
          value={value}
          onChange={this.handleTextFieldChange}
          floatingLabelText="Note text"
          style={{
            width: "100%",
            marginTop: "-12px",
          }}
        />
      </DialogWrapper>
    );
  }
}
