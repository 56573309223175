import PropTypes from "prop-types";
import React from "react";

import Checkbox from "material-ui/Checkbox";
import Edit from "material-ui/svg-icons/image/edit";
import Delete from "material-ui/svg-icons/action/delete";
import EditSubTask from "./EditSubTask";

export default class SubTask extends React.Component {
  static propTypes = {
    subTask: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
    updateSubTask: PropTypes.func.isRequired,
    deleteSubTask: PropTypes.func.isRequired,
  };

  state = {
    editing: false,
    editSubTask: "",
  };

  handleEditSubTask = () => {
    this.setState({
      editing: true,
      editSubTask: this.props.subTask.title,
    });
  };

  handleDeleteSubTask = () => {
    const { deleteSubTask, task, subTask } = this.props;
    deleteSubTask(task.category, task.id, subTask.id);
  };

  handleSubmitSubTask = () => {
    this.setState({
      editing: false,
    });
  };

  render() {
    const { task, subTask, updateSubTask, toggleCompleteSubTask } = this.props;

    if (this.state.editing) {
      return (
        <EditSubTask
          task={task}
          subTaskTitle={subTask.title}
          updateSubTask={updateSubTask}
          subTask={subTask}
          handleSubmitSubTask={this.handleSubmitSubTask}
        />
      );
    } else {
      return (
        <div className="sub-task">
          <Checkbox
            label={subTask.title}
            checked={!!subTask.completed}
            className="sub-task-check-box"
            onClick={() => {
              toggleCompleteSubTask(subTask);
            }}
            color="primary"
          />

          <Edit
            className="edit"
            color="#808080"
            onClick={() => {
              this.handleEditSubTask();
            }}
          />
          <Delete
            className="delete"
            color="#808080"
            onClick={() => {
              this.handleDeleteSubTask();
            }}
          />
        </div>
      );
    }
  }
}
