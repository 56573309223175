import React, { Component } from "react";
import Avatar from "material-ui/Avatar";
import FontIcon from "material-ui/FontIcon";

export default class MergeArrows extends Component {
  render() {
    return (
      <div className="MergeOrganizations--organizations-arrow">
        <Avatar
          backgroundColor="tomato"
          icon={<FontIcon className="arrow-right" />}
          size={32}
        />
      </div>
    );
  }
}
