import React from "react";
import Overview from "./Overview";

export default class InvestorOverview extends React.Component {
  render() {
    return (
      <Overview
        stages={[
          "termsheet",
          "qualification",
          "pre_qualification",
          "call_meeting",
          "make_contact",
          "more_info",
        ]}
        nextPathname="/overview/reconnect"
        {...this.props}
      />
    );
  }
}
