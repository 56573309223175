import auth from "../storage/Auth";
import Config from "../config";
import request from "superagent";

export default class SequenceStateStore {
  constructor(organizationId) {
    this.url = `${Config.api.host}/organizations/${organizationId}/sequence_states`;
  }

  sequenceStates = () =>
    auth
      .getAuthData()
      .then((authData) =>
        request.get(this.url).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then((response) => response.body);
}
