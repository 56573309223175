import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-v4";
import ImageNavigateBefore from "material-ui/svg-icons/image/navigate-before";
import ImageNavigateNext from "material-ui/svg-icons/image/navigate-next";

class InvestorNavigator extends React.Component {
  static propTypes = {
    investor: PropTypes.object,
  };

  state = {
    next: {},
    previous: {},
    nextArrowContainer: null,
    prevArrowContainer: null,
    showPrevInvestor: false,
    showNextInvestor: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentId !== this.props.currentId) {
      this.initialize();
    }
  }

  initialize = () => {
    const { currentId = "", organizationsNavigatorFetcher } = this.props;
    this.setState(organizationsNavigatorFetcher.getNextAndPrevious(currentId));
  };

  getUrl = (id) => {
    const {
      location: { pathname = "" },
      currentId,
    } = this.props;
    return `${pathname.replace(currentId, id)}`;
  };

  goToPage = (direction) => {
    const { router, location } = this.props;
    const { [direction]: { id = "" } = {} } = this.state;
    router.push({
      ...location,
      pathname: this.getUrl(id),
    });
  };

  render() {
    const {
      previous: { name: previousName = "" } = {},
      next: { name: nextName = "" } = {},
    } = this.state;
    if (previousName !== "" || nextName !== "") {
      return (
        <div className="Investor-navigator">
          <div className="Investor-navigator-button prev-Investor">
            <If condition={previousName !== ""}>
              <Link
                onClick={() => {
                  this.goToPage("previous");
                }}
              >
                <div
                  onMouseLeave={() =>
                    this.setState({ showPrevInvestor: false })
                  }
                  onMouseEnter={() => this.setState({ showPrevInvestor: true })}
                  className="arrow-container"
                >
                  <ImageNavigateBefore color={"white"} />
                  <If condition={this.state.showPrevInvestor}>
                    <p>{previousName}</p>
                  </If>
                </div>
              </Link>
            </If>
          </div>
          <div className="Investor-navigator-button next-organization">
            <If condition={nextName !== ""}>
              <Link
                onClick={() => {
                  this.goToPage("next");
                }}
              >
                <div
                  onMouseLeave={() =>
                    this.setState({ showNextInvestor: false })
                  }
                  onMouseEnter={() => this.setState({ showNextInvestor: true })}
                  className="arrow-container"
                >
                  <If condition={this.state.showNextInvestor}>
                    <p>{nextName}</p>
                  </If>
                  <ImageNavigateNext color={"white"} />
                </div>
              </Link>
            </If>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default withRouter(InvestorNavigator);
