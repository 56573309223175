import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import People from "material-ui/svg-icons/social/people";

import { getDataWrapperClassNames } from "../../../../lib/helpers";

class PeoplePerformance extends React.Component {
  static propTypes = {
    people: PropTypes.object.isRequired,
    compressed: PropTypes.bool.isRequired,
    organization: PropTypes.object.isRequired,
  };

  render() {
    const { previous = false, compressed = false } = this.props;
    const {
      people: { mlUpdates = [] },
      organization,
    } = this.props;
    return (
      <div className="people">
        <If condition={!compressed}>
          <div className="section-title">{`${
            previous ? "Previous " : ""
          }People${window.innerWidth < 768 ? " Performance" : ""}`}</div>
        </If>
        <div className="people-grid-item founders-team-highlights-grid-item">
          <div className={getDataWrapperClassNames(compressed)}>
            <div className="data-icon">
              <People className="data-icon-svg" />
            </div>
            <div className="verfied-highlights" data-testid="highlights">
              {organization.ml_features?.number_tech_people?.score > 0 && (
                <span
                  className="verified-label verified-tech-person"
                  data-testid="techie"
                >
                  <i className="fas fa-chalkboard-teacher"></i>Tech DNA
                </span>
              )}
              {organization.ml_features?.aggregated_days_at_top_tech?.score >
                0 && (
                <span
                  className="verified-label verified-worked-at-top-tech"
                  data-testid="top-tech"
                >
                  <i className="fas fa-user-check"></i>Worked at Top Tech
                  Company
                </span>
              )}
              {organization.ml_features?.aggregated_people_with_previous_exits
                ?.score > 0 && (
                <span
                  className="verified-label verified-has-previous-exit"
                  data-testid="previous-exit"
                >
                  <i className="fas fa-sign-out-alt"></i>Previous Exit
                </span>
              )}
              {organization.ml_features?.founders_education?.score > 0 && (
                <span
                  className="verified-label verified-good-education"
                  data-testid="good-education"
                >
                  <i className="fas fa-user-graduate"></i>Good Education
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PeoplePerformance);
