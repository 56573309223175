export default {
  FORM: "Form",
  FORM_PARTIAL: "Partial Form",
  FORM_UPDATED: "Updated Form",
  CREATE: "Manually added",
  IS_THIS_INTERESTING: "Is this interesting?",
  IS_THIS_SPAM: "Is this spam?",
  REVIEW: "Review",
  REVIEW_FORM: "Review Form",
  DIG_ML: "DIG Machine Learning",
  DIG_ML_UPDATED: "DIG ML Updated",
  FUNDED: "Funded",
  UNABLE_TO_CONTACT: "Unable to Contact",
  UNABLE_TO_RECONNECT: "Unable to Reconnect",
  SOCIAL_MESSAGES: "Social Messages",
  MISSED_OPPORTUNITY: "Missed Opportunity",
  DISAGREEMENT: "Feedback",
  CALL_FEEDBACK: "Recommendation Call",
  RECONNECT: "Reconnect",
  ML_EXPERIMENTS: "ML Experiments",
  TRAINING: "Training",
  FEEDBACK: "Feedback",
  PRE_QUALIFICATION: "Pre Qualification",
  QUALIFICATION: "Qualification",
  NO_SHOW: "No Show",
  SCHEDULE_CALL: "Schedule Call",
  INTERESTING_INVESTORS: "Interesting Investors",
  ALERT: "Alerts",
  STRUCTURED_FEEDBACK: "Structured Feedback",
};
