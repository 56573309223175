import React from "react";

class PersonEducation extends React.Component {
  render() {
    const { education = {} } = this.props;
    return (
      <div key={`${education.title}`} className="person-experience">
        <If condition={education.title}>
          <div className="title">{education.title}</div>
        </If>
        <If condition={education.subtitle}>
          <div className="company">{education.subtitle}</div>
        </If>
        <If condition={education.from && education.to}>
          <div className="duration">
            {education.start} - {education.end}
          </div>
        </If>
      </div>
    );
  }
}

export default PersonEducation;
