import React from "react";
import { withRouter, Link } from "react-router-v4";
import PropTypes from "prop-types";

import Tooltip from "../../../Tooltip";
import Loading from "../../../loading/Loading";
import SocialLink from "../../SocialLink";

import { getDataWrapperClassNames } from "../../../../lib/helpers";

class Investor extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    isInteresting: PropTypes.bool.isRequired,
  };

  state = {
    showTooltip: false,
  };

  getClassNames = () => {
    const {
      isInteresting,
      investor: {
        highlighted = false,
        ml_features: { is_top_tier: { score: isTopTier = 0 } = {} } = {},
      } = {},
    } = this.props;
    const classNames = ["investor"];
    if (isInteresting) {
      classNames.push("highlighted-text");
    }
    if (isTopTier) {
      classNames.push("bold-text");
    }
    return classNames.join(" ");
  };

  render() {
    const {
      investor: {
        name,
        id,
        highlighted = false,
        crunchbase_url = "",
        ml_features: { is_top_tier: { score: isTopTier = 0 } = {} } = {},
      } = {},
      isNew = false,
    } = this.props;
    const { showTooltip } = this.state;

    return (
      <div
        className="data-content-list-item component"
        onMouseLeave={() => {
          if (highlighted) {
            this.setState({ showTooltip: false });
          }
        }}
        onMouseEnter={() => {
          if (highlighted) {
            this.setState({ showTooltip: true });
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={
            isTopTier
              ? "This is a top tier investor"
              : "This is a interesting investor"
          }
        />
        <div className="icon-text-component investor">
          <If condition={crunchbase_url !== ""}>
            <div className="icon crunchbase">
              <SocialLink source="crunchbase" link={crunchbase_url} />
            </div>
          </If>
          <Link to={`/investors/${id}`} className={this.getClassNames()}>
            {name}
          </Link>
          <If condition={isNew}>
            <div className="spacer" />
            <div className="new-tag-wrapper new-inline">
              <div className={`new-tag`}>New</div>
            </div>
          </If>
        </div>
      </div>
    );
  }
}

class Investors extends React.Component {
  static propTypes = {
    investors: PropTypes.object.isRequired,
    investorsLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool,
    compressed: PropTypes.bool.isRequired,
  };

  state = {
    showOtherInvestors: false,
  };

  getNewInvestorsIds = () => {
    const {
      investors: {
        investors = [],
        snapshot: { investors: snapshotInvestors = [] } = {},
      },
    } = this.props;
    const snapshotInvestorsIds = snapshotInvestors.map(
      (investor) => investor.id
    );
    const newInvestors = investors.filter(
      (investor) => !snapshotInvestorsIds.includes(investor.id)
    );
    return newInvestors.map((investor) => investor.id);
  };

  getFundedByTopTier = () => {
    const {
      investors: { investors = [] },
    } = this.props;
    const topTierInvestors = investors.filter(
      (investor) => this.isTopTierFeature(investor).score
    );
    return topTierInvestors.length > 0;
  };

  isTopTierFeature = (investor = {}) => {
    const { ml_features: { is_top_tier: isTopTier = {} } = {} } = investor;
    return isTopTier;
  };

  getInvestors = () => {
    const {
      investors: {
        investors = [],
        highlightedInvestorNames = [],
        investorIds,
      } = {},
    } = this.props;
    if (investors.length === 0) {
      return {
        highlightedInvestors: [],
        otherInvestors: [],
      };
    }
    const hasHighlightedInvestor = highlightedInvestorNames.length > 0;
    const { highlightedInvestors, otherInvestors } = investors.reduce(
      ({ highlightedInvestors, otherInvestors }, investor, index) => {
        if (
          investorIds.interestingInvestors.includes(investor.id) ||
          this.isTopTierFeature(investor).score
        ) {
          investor.highlighted = true;
        }
        (hasHighlightedInvestor && investor.highlighted) ||
        (!hasHighlightedInvestor && index < 3)
          ? highlightedInvestors.push(investor)
          : otherInvestors.push(investor);
        return {
          highlightedInvestors,
          otherInvestors,
        };
      },
      {
        highlightedInvestors: [],
        otherInvestors: [],
      }
    );

    return {
      highlightedInvestors,
      otherInvestors,
    };
  };

  getIsInteresting = (id) => {
    const {
      investors: { investorIds },
    } = this.props;
    return investorIds.interestingInvestors.includes(id);
  };

  handleToggleOtherInvestors = () => {
    this.setState({
      showOtherInvestors: !this.state.showOtherInvestors,
    });
  };

  render() {
    const {
      investorsLoading,
      previous = false,
      investors: { aggregatedFollowonScore, investorIds, snapshot = {} },
      compressed = false,
      useSnapshot,
    } = this.props;
    const { showOtherInvestors } = this.state;
    const { highlightedInvestors, otherInvestors } = this.getInvestors();
    const newInvestorIds = this.getNewInvestorsIds();
    const fundedByTopTier = this.getFundedByTopTier();
    return (
      <div className={getDataWrapperClassNames(compressed, ["investors"])}>
        <div className="data-header">
          <If condition={previous}>Previous&nbsp;</If>
          Investors
        </div>
        <Choose>
          <When condition={investorsLoading}>
            <Loading small={true} align="left" />
          </When>
          <Otherwise>
            <Choose>
              <When
                condition={
                  highlightedInvestors.length === 0 &&
                  otherInvestors.length === 0
                }
              >
                <div className="data-content text empty">No data</div>
              </When>
              <Otherwise>
                <If condition={highlightedInvestors.length > 0}>
                  <div className="data-content list investors">
                    {highlightedInvestors
                      .sort((a, b) => {
                        const isAInteresting = this.getIsInteresting(a.id);
                        const isBInteresting = this.getIsInteresting(b.id);
                        if (isAInteresting === isBInteresting) {
                          if (a.name > b.name) {
                            return 1;
                          }
                          if (a.name < b.name) {
                            return -1;
                          }
                          return 0;
                        }
                        return isAInteresting > isBInteresting ? -1 : 1;
                      })
                      .map((investor) => (
                        <Investor
                          investor={investor}
                          key={investor.id}
                          isInteresting={this.getIsInteresting(investor.id)}
                          isNew={
                            useSnapshot && newInvestorIds.includes(investor.id)
                          }
                        />
                      ))}
                  </div>
                </If>
                <If condition={otherInvestors.length > 0}>
                  <If condition={showOtherInvestors}>
                    <div className="data-content list investors">
                      {otherInvestors
                        .sort((a, b) => {
                          const isAInteresting = this.getIsInteresting(a.id);
                          const isBInteresting = this.getIsInteresting(b.id);
                          if (isAInteresting === isBInteresting) {
                            if (a.name > b.name) {
                              return 1;
                            }
                            if (a.name < b.name) {
                              return -1;
                            }
                            return 0;
                          }
                          return isAInteresting < isBInteresting ? 1 : -1;
                        })
                        .map((investor) => (
                          <Investor
                            investor={investor}
                            key={investor.id}
                            isInteresting={this.getIsInteresting(investor.id)}
                            isNew={
                              useSnapshot &&
                              newInvestorIds.includes(investor.id)
                            }
                          />
                        ))}
                    </div>
                  </If>
                  <div
                    className={`data-content text ${
                      showOtherInvestors || highlightedInvestors.length > 0
                        ? "component"
                        : ""
                    }`}
                    style={{ display: "flex" }}
                  >
                    <Link
                      style={{ cursor: "pointer" }}
                      onClick={this.handleToggleOtherInvestors}
                    >
                      {showOtherInvestors
                        ? "Hide investors"
                        : highlightedInvestors.length > 0
                        ? "Show more investors"
                        : "Show investors"}
                    </Link>
                    <If
                      condition={
                        useSnapshot &&
                        !showOtherInvestors &&
                        otherInvestors.filter((investor) =>
                          newInvestorIds.includes(investor.id)
                        ).length > 0
                      }
                    >
                      <div className="spacer" />
                      <div className="new-tag-wrapper ">
                        <div className={`new-tag`}>New</div>
                      </div>
                    </If>
                  </div>
                </If>
                <If condition={aggregatedFollowonScore > 0}>
                  <hr />
                  <p>
                    Top tier follow on probability:{" "}
                    <span
                      className={
                        aggregatedFollowonScore > 15 ? "highlighted-text" : ""
                      }
                    >
                      {fundedByTopTier
                        ? "N/A - Already funded by Top Tier"
                        : `${Math.round(aggregatedFollowonScore)}%`}
                    </span>
                  </p>
                </If>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(Investors);
