import PropTypes from "prop-types";
import React from "react";
import NameCell from "./NameCell";
import TitleCell from "./TitleCell";
import SourcesCell from "./SourcesCell";

import AvatarCell from "./AvatarCell";
import BioCell from "./BioCell";
import DateCell from "./DateCell";

class PersonSummary extends React.Component {
  static propTypes = {
    person: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="row organization-row">
        <div className="col-lg-2 col-sm-4 col-xs-12">
          <AvatarCell {...this.props} />
          <NameCell {...this.props} />
        </div>
        <div className="col-lg-2 col-sm-4 col-xs-12 text-center">
          <TitleCell {...this.props} />
        </div>
        <div className="col-lg-1 col-sm-4 col-xs-12 text-center">
          <SourcesCell {...this.props} />
        </div>
        <div className="col-lg-1 col-sm-2 col-xs-4 text-center">
          <div className="visible-xs-block visible-sm-block visible-md-block">
            <strong>First Seen: </strong>
          </div>
          <DateCell {...this.props} columnKey="created_at" />
        </div>
        <div className="col-lg-1 col-sm-2 col-xs-4 text-center">
          <div className="visible-xs-block visible-sm-block visible-md-block">
            <strong>Updated: </strong>
          </div>
          <DateCell {...this.props} columnKey="updated_at" />
        </div>
        <div className="col-lg-1 col-sm-2 col-xs-4 text-center">
          <div className="visible-xs-block visible-sm-block visible-md-block">
            <strong>Trend: </strong>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12 col-xs-12">
          <BioCell {...this.props} />
        </div>
      </div>
    );
  }
}

export default PersonSummary;
