import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import Card from "../../../card/Card";
import CardHeader from "../../../card/CardHeader";
import CardBody from "../../../card/CardBody";
import Loading from "../../../loading/Loading";
import KeyPeople from "./KeyPeople";
import OtherPeople from "./OtherPeople";
import CustomRankIcon from "../CustomRankIcon";

import contactRolePriorities from "../../../../content/contactRolePriorities";
import convertedLegacyInsights from "../../../../content/convertedLegacyInsights";

import _ from "underscore";

class PeopleSection extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    snapshot: PropTypes.object.isRequired,
    snapshotLoading: PropTypes.bool.isRequired,
    people: PropTypes.array.isRequired,
    peopleLoading: PropTypes.bool.isRequired,
    editPerson: PropTypes.func.isRequired,
  };

  buildPeople = () => {
    const { people = [] } = this.props;
    if (people.length > 0) {
      const { highlighted, other } = people.reduce(
        ({ highlighted, other }, person) => {
          const ranking = this.getPersonRanking(person.id);
          if (ranking !== null || this.isPersonImportant(person)) {
            if (ranking !== null) {
              highlighted.push({
                ...person,
                isHighlighted: true, //'rank' in ranking,
                ranking: _.omit(ranking, "person_id"),
                snapshot: this.getPersonSnapshot(person.id),
              });
            } else {
              highlighted.push({
                ...person,
                isHighlighted: false,
                snapshot: this.getPersonSnapshot(person.id),
              });
            }
          } else {
            other.push({
              ...person,
              isHighlighted: false,
              snapshot: this.getPersonSnapshot(person.id),
            });
          }
          return {
            highlighted,
            other,
          };
        },
        { highlighted: [], other: [] }
      );
      //highlighted.sort(this.sortHighlightedPeople);
      other.sort(this.sortOtherPeople);
      return {
        highlighted: this.sortHighlightedPeople(highlighted),
        other,
      };
    } else {
      return {
        highlighted: [],
        other: [],
      };
    }
  };

  getPersonRanking = (personId, snapshot = false) => {
    const {
      organization: {
        ml_features: { ui_ranking: { people: peopleRanking = [] } = {} } = {},
      } = {},
      snapshot: {
        organization: {
          ml_features: {
            ui_ranking: { people: snapshotPeopleRanking = [] } = {},
          } = {},
        } = {},
      } = {},
    } = this.props;
    return (
      ((!snapshot && peopleRanking.length > 0) ||
      (snapshot && snapshotPeopleRanking.length > 0)
        ? (!snapshot ? peopleRanking : snapshotPeopleRanking).find(
            ({ person_id: id }) => id === personId
          )
        : this.getLegacyHighlights(personId, snapshot)) || null
    );
  };

  getLegacyHighlights = (personId, snapshot = false) => {
    const {
      organization: {
        ml_features: { highlights: { highlights = {} } = {} } = {},
      } = {},
      snapshot: {
        organization: {
          ml_features: { highlights: { snapshotHighlights = {} } = {} } = {},
        } = {},
      } = {},
    } = this.props;
    const ranking = Object.entries(
      !snapshot ? highlights : snapshotHighlights
    ).reduce(
      (personRanking, [highlightKey, { [personId]: highlights = [] }]) => {
        if (highlights.length > 0) {
          personRanking.matched_highlights.push({
            ...convertedLegacyInsights[
              highlightKey
              /*highlightKey === 'experienced_founder' &&
              typeof highlights[0] === 'string'
                ? 'experienced_founder_bio'
                : highlightKey*/
            ],
            key_information: this.extractKeyInformation(
              highlightKey,
              highlights,
              personId
            ),
          });
        }
        return personRanking;
      },
      {
        matched_highlights: [],
        matched_risks: [],
      }
    );
    return ranking.matched_highlights.length > 0 ||
      ranking.matched_risks.length > 0
      ? ranking
      : null;
  };

  extractKeyInformation = (highlightKey, highlights = [], personId) => {
    if (
      highlightKey === "serial_entrepreneur" ||
      (highlightKey === "experienced_founder" &&
        typeof highlights[0] === "string")
    ) {
      return [{ bio: this.getPersonBio(personId) }];
    } else {
      return highlights;
    }
  };

  getPersonBio = (personId) => {
    const { people = [] } = this.props;
    return people.find(({ id }) => id === personId).bio || "";
  };

  isPersonImportant = (person, snapshot = false) => {
    const {
      organization: {
        primary_contact_id: primaryContactId = "",
        ml_features: {
          person_roles: { highlights: personRoles = {} } = {},
        } = {},
      } = {},
      snapshot: {
        organization: {
          primary_contact_id: snapshotPrimaryContactId = "",
          ml_features: {
            person_roles: { highlights: snapshotPersonRoles = {} } = {},
          } = {},
        } = {},
      },
    } = this.props;
    const { id, role = "", title = "" } = person;
    if (!_.isEmpty(!snapshot ? personRoles : snapshotPersonRoles)) {
      const personRole = (Object.entries(
        !snapshot ? personRoles : snapshotPersonRoles
      ).find(([personId]) => personId === id) || [null, ""])[1];
      return (
        id === (!snapshot ? primaryContactId : snapshotPrimaryContactId) ||
        personRole === "founder" ||
        personRole === "management"
      );
    } else {
      const roles = [
        "primary",
        "founder",
        "ceo",
        "cto",
        "cfo",
        "coo",
        "cpo",
        "cmo",
      ];
      return (
        id === (!snapshot ? primaryContactId : snapshotPrimaryContactId) ||
        (
          roles.find(
            (allowedRole) => role.toLowerCase().indexOf(allowedRole) > -1
          ) || []
        ).length > 0 ||
        (
          roles.find(
            (allowedRole) => title.toLowerCase().indexOf(allowedRole) > -1
          ) || []
        ).length > 0
      );
    }
  };

  getPersonSnapshot = (id) => {
    const { snapshot: { people = [] } = {} } = this.props;
    const person = people.find((person) => person.id === id) || null;
    if (person === null) {
      return {};
    }
    const ranking = this.getPersonRanking(id, true);
    if (ranking !== null || this.isPersonImportant(person, true)) {
      if (ranking !== null) {
        return {
          ...person,
          isHighlighted: true,
          ranking: _.omit(ranking, "person_id"),
        };
      } else {
        return {
          ...person,
          isHighlighted: false,
        };
      }
    } else {
      return {
        ...person,
        isHighlighted: false,
      };
    }
  };

  sortHighlightedPeople = (highlighted) => {
    const { organization: { primary_contact_id: primaryContactId = "" } = {} } =
      this.props;
    const primaryContacts = [],
      ceos = [],
      founders = [],
      cLevels = [],
      employees = [];
    highlighted.forEach((person) => {
      const { id, title = "", role = "" } = person;
      if (id === primaryContactId) {
        primaryContacts.push(person);
      } else if (
        title.toLowerCase().indexOf("ceo") > -1 ||
        role.toLowerCase().indexOf("ceo") > -1
      ) {
        ceos.push(person);
      } else if (
        title.toLowerCase().indexOf("founder") > -1 ||
        role.toLowerCase().indexOf("founder") > -1
      ) {
        founders.push(person);
      } else if (
        ["cto", "cfo", "coo", "cpo", "cmo"].filter(
          (cLevelRole) =>
            role.toLowerCase().indexOf(cLevelRole) > -1 ||
            title.toLowerCase().indexOf(cLevelRole) > -1
        ).length > 1
      ) {
        cLevels.push(person);
      } else {
        employees.push(person);
      }
    });
    return [
      ...primaryContacts.sort(this.sortPeopleGroup),
      ...ceos.sort(this.sortPeopleGroup),
      ...founders.sort(this.sortPeopleGroup),
      ...cLevels.sort(this.sortPeopleGroup),
      ...employees.sort(this.sortPeopleGroup),
    ];
  };

  sortPeopleGroup = (a, b) => {
    if (a.ranking && b.ranking) {
      return a.ranking.importance
        ? b.ranking.importance - a.ranking.importance
        : b.ranking.matched_highlights.length -
            a.ranking.matched_highlights.length;
    } else if (a.ranking && !b.ranking) {
      return -1;
    } else if (b.ranking && !a.ranking) {
      return 1;
    } else {
      return this.sortOtherPeople(a, b);
    }
  };

  sortOtherPeople = (a, b) =>
    this.getRolePriority(this.getRole(a)) -
    this.getRolePriority(this.getRole(b));

  getRolePriority = (role) =>
    contactRolePriorities[role] || Object.keys(contactRolePriorities).length;

  getRole = ({ id, role, title }) => {
    const {
      organization: { primary_contact_id: primaryContactId = null } = {},
    } = this.props;
    if (primaryContactId && primaryContactId === id) {
      return "primary";
    }
    return (role || title || "").toLowerCase();
  };

  getCardRankIcon = () => {
    const {
      organization: {
        ml_features: { ui_ranking: { people = [] } = {} } = {},
      } = {},
    } = this.props;
    if (people.length === 0) {
      return null;
    }
    const rank = people.sort((a, b) => b.importance - a.importance)[0].rank;
    return <CustomRankIcon rank={rank} />;
    //return <ActionBookmark className={`rank-icon ${rank}`} />;
  };

  render() {
    const {
      organization = {},
      peopleLoading,
      editPerson,
      useSnapshot = false,
    } = this.props;
    const { highlighted, other } = this.buildPeople();
    return (
      <div className="people-section">
        <Card>
          <CardHeader title="People" icon={this.getCardRankIcon()} />
          <CardBody>
            <Choose>
              <When condition={peopleLoading}>
                <Loading align="top" />
              </When>
              <Otherwise>
                <KeyPeople
                  organization={organization}
                  people={highlighted}
                  editPerson={editPerson}
                  useSnapshot={useSnapshot}
                />
                <If condition={other.length > 0}>
                  <hr />
                  <OtherPeople
                    organization={organization}
                    people={other}
                    editPerson={editPerson}
                    useSnapshot={useSnapshot}
                  />
                </If>
              </Otherwise>
            </Choose>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(PeopleSection);
