import React from "react";
import OrganizationsSearch from "./organization/OrganizationsSearch";
import Loading from "./loading/Loading";
import auth from "../storage/Auth";

class SpamRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    auth
      .getAuthData()
      .then(({ client }) => {
        this.setState({ client });
      })
      .catch((err) => {
        console.log("Unable to retrieve client", err);
      });
  }

  render() {
    const client = this.state.client;
    if (!client) {
      return <Loading />;
    }
    return (
      <OrganizationsSearch
        initSearchParams={{
          based_in_continent: "Europe",
          state: "unseen",
          classification: "never",
          is_in_inbox: "false",
          requires_more_info: "false",
        }}
        client={client}
        location={this.props.location}
        context={this.props.context}
        route={this.props.route}
      />
    );
  }
}

export default SpamRoute;
