import React from "react";
import PropTypes from "prop-types";

export default class SocialLink extends React.Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
    link: PropTypes.string,
    isMail: PropTypes.bool,
    additionalClasses: PropTypes.array,
  };

  getClassNames = () => {
    const { link, additionalClasses = [] } = this.props;
    const classNames = ["social-link", ...additionalClasses];
    if (!link) {
      classNames.push("inactive");
    }
    return classNames.join(" ");
  };

  render() {
    const { link, source, isMail = false } = this.props,
      fullLink = `${isMail ? "mailto:" : ""}${link}`;

    return (
      <a className={this.getClassNames()} href={fullLink} target="_blank">
        <div className={source}>
          {isMail && (
            <span
              style={{ marginLeft: "5px", marginTop: "2px", fontSize: "0.6em" }}
            >
              {link}
            </span>
          )}
        </div>
      </a>
    );
  }
}
