import PropTypes from "prop-types";
import React from "react";
import Checkbox from "material-ui/Checkbox";

export default class IsNotSpam extends React.Component {
  static propTypes = {
    isNotSpam: PropTypes.bool.isRequired,
    handleIsNotSpamChange: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, checked) {
    this.props.handleIsNotSpamChange(this.props.organization_id, checked);
  }

  render() {
    return (
      <div>
        <Checkbox
          label="Not Spam"
          checked={this.props.isNotSpam}
          onCheck={this.handleChange}
          labelStyle={{ width: "auto" }}
        />
      </div>
    );
  }
}
