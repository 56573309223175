import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";
import Card from "../../../card/Card";
import CardHeader from "../../../card/CardHeader";
import CardBody from "../../../card/CardBody";
import SocialLink from "../../SocialLink";
import Insights from "../Insights";
import ProducthuntPostStore from "../../../../storage/ProducthuntPostStore";
import { getLinkedInUrl } from "../../../../lib/helpers";
import ClearbitPersonStore from "../../../../storage/ClearbitPersonStore";

class SocialSection extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    signals: PropTypes.object.isRequired,
  };
  state = {};
  buildSocial = () => {
    const {
      organization: {
        facebook = null,
        twitter = null,
        angellist = null,
        crunchbase = null,
      } = {},
    } = this.props;
    return {
      facebook: facebook,
      twitter: twitter,
      crunchbase: crunchbase,
      angellist: angellist,
    };
  };

  componentDidMount() {
    const {
      organization: {
        source_refs: { PRODUCTHUNT: productHuntRef = null } = {},
      } = {},
    } = this.props;
    if (productHuntRef) {
      this.producthunt = new ProducthuntPostStore(productHuntRef[0]);
      this.producthunt
        .getModel()
        .then((productHuntObject) => this.setState({ productHuntObject }));
    }
  }

  filterMlUpdates = () => {
    const { mlUpdates } = this.props;
    return mlUpdates
      .filter(
        (object) =>
          object.name.includes("product") &&
          object.raw === "social" &&
          object.score > 0
      )
      .map((update) => (update.notes ? update.notes[0] : update.name));
  };

  render() {
    const social = this.buildSocial();
    const { productHuntObject: { discussion_url: appUrl = null } = {} } =
      this.state;
    const productHunt = appUrl ? appUrl.split("?")[0] : null;
    return (
      <div className="people-section">
        <Card>
          <CardHeader title="Social" />
          <CardBody>
            <div className="product-grid-item insights-grid-item">
              <Insights mlUpdates={this.filterMlUpdates()} />
            </div>
            <div className="section-title" style={{ marginTop: "16px" }}>
              Social Links
            </div>
            <div className="links-container">
              <SocialLink source="twitter" link={social.twitter} />
              <SocialLink source="facebook" link={social.facebook} />
              <SocialLink source="producthunt" link={productHunt} />
              <SocialLink source="crunchbase" link={social.crunchbase} />
              <SocialLink source="angellist" link={social.angellist} />
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(SocialSection);
