import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-v4";
import moment from "moment";

import Avatar from "material-ui/Avatar";
import LinearProgress from "material-ui/LinearProgress";
import IconButton from "material-ui/IconButton";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";

import EditorModeEdit from "material-ui/svg-icons/editor/mode-edit";

import OverviewEditOrganizationEditOrganization from "./OverviewEditOrganization";

import Organizations from "../../lib/Organizations";
import imageUrl from "../../lib/imageUrl";

import inreachTeam from "../../config/inreach_team";

import personPlaceholder from "../../../images/person-placeholder.jpg";
import Tooltip from "../Tooltip";

const teamMap = inreachTeam.reduce(
  (acc, { email, name, imageUrl }) => ({ ...acc, [email]: { name, imageUrl } }),
  {}
);

const teamMember = (email) =>
  teamMap[email] || {
    name: "None",
    imageUrl: personPlaceholder,
  };

class PrimaryContact extends React.Component {
  static propTypes = {
    person: PropTypes.object,
    loadingPeople: PropTypes.bool.isRequired,
  };

  render() {
    const { person, loadingPeople } = this.props;

    return (
      <Choose>
        <When condition={loadingPeople}>
          <LinearProgress />
        </When>
        <When condition={!!person}>
          <span>
            <Avatar
              src={imageUrl({ url: person.image_url || personPlaceholder })}
              size={20}
            />{" "}
            {person.name}
          </span>
        </When>
        <Otherwise>
          <span>None</span>
        </Otherwise>
      </Choose>
    );
  }
}

export default class OverviewStageTable extends React.Component {
  static propTypes = {
    organizations: PropTypes.array.isRequired,
    people: PropTypes.object.isRequired,
    loadingPeople: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    statusFieldTitle: PropTypes.string,
    statusField: PropTypes.func,
  };

  static defaultProps = {
    statusFieldTitle: "Status",
    statusField: (organization, client, now) => {
      const { status, statuses } =
        Organizations.getOrganizationState(organization, client) || {};
      let field = [];
      if (statuses) {
        field = field.concat(
          statuses.map(({ text }) => text).filter((text) => !!text)
        );
      }
      if (status) {
        field.push(status);
      }
      return field.join(", ");
    },
    onSave: () => {},
  };

  state = {
    editOrganization: null,
  };

  handleSaveDecision = (updatedOrganization) => {
    this.setState({ editOrganization: null });

    this.props.onSave(updatedOrganization);
  };

  render() {
    const {
        client,
        organizations,
        people,
        loadingPeople,
        statusField,
        statusFieldTitle,
      } = this.props,
      now = moment();

    return (
      <div>
        <Table selectable={false}>
          <TableHeader
            adjustForCheckbox={false}
            displaySelectAll={false}
            enableSelectAll={false}
          >
            <TableRow>
              <TableHeaderColumn
                className="overview-medium-mobile-view"
                style={{ width: "68px" }}
              >
                {" "}
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: "24px" }}> </TableHeaderColumn>
              <TableHeaderColumn style={{ width: "145px" }}>
                Name
              </TableHeaderColumn>
              <TableHeaderColumn
                style={{ width: "145px" }}
                className="overview-small-mobile-view"
              >
                Last update
              </TableHeaderColumn>
              <TableHeaderColumn
                style={{ width: "145px" }}
                className="overview-large-mobile-view"
              >
                Time in Stage
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: "145px" }}>
                Assignee
              </TableHeaderColumn>
              <TableHeaderColumn
                style={{ width: "145px" }}
                className="overview-large-mobile-view"
              >
                Organizer
              </TableHeaderColumn>
              <TableHeaderColumn className="overview-large-mobile-view">
                Notes
              </TableHeaderColumn>
              <TableHeaderColumn className="overview-large-mobile-view">
                {statusFieldTitle}
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
            selectable={false}
            stripedRows={false}
            showRowHover={true}
          >
            {organizations.map((organization) => {
              const { id, name, image_url } = organization;
              const {
                updated_at,
                created_at,
                assigned_to,
                organizer,
                notes,
                priority,
              } = Organizations.getOrganizationState(organization, client);

              const teamMemberAssignee = teamMember(assigned_to);
              const teamMemberOrganizer = teamMember(organizer);

              return (
                <TableRow
                  key={id}
                  selectable={false}
                  style={priority === 1 ? { backgroundColor: "#ffdf87" } : {}}
                >
                  <TableRowColumn
                    className="overview-medium-mobile-view"
                    style={{ width: "68px" }}
                  >
                    <IconButton
                      onClick={() => {
                        this.setState({ editOrganization: organization });
                      }}
                    >
                      <EditorModeEdit />
                    </IconButton>
                  </TableRowColumn>
                  <TableRowColumn style={{ width: "24px" }}>
                    <Link
                      to={() => ({
                        pathname: `/organizations/${id}`,
                        state: { returnUrl: `/overview` },
                      })}
                    >
                      <Avatar src={imageUrl({ url: image_url })} size={20} />
                    </Link>
                  </TableRowColumn>
                  <TableRowColumn style={{ width: "145px" }}>
                    <Link
                      to={() => ({
                        pathname: `/organizations/${id}`,
                        state: { returnUrl: `/overview` },
                      })}
                    >
                      {name}
                    </Link>
                  </TableRowColumn>
                  <TableRowColumn
                    style={{ width: "145px" }}
                    className="overview-small-mobile-view"
                  >
                    {moment(updated_at).fromNow()}
                  </TableRowColumn>
                  <TableRowColumn
                    style={{ width: "145px" }}
                    className="overview-large-mobile-view"
                  >
                    {moment(created_at).fromNow(true)}
                  </TableRowColumn>
                  <TableRowColumn style={{ width: "145px" }}>
                    <Avatar src={teamMemberAssignee.imageUrl} size={20} />{" "}
                    {teamMemberAssignee.name}
                  </TableRowColumn>
                  <TableRowColumn
                    style={{ width: "145px" }}
                    className="overview-large-mobile-view"
                  >
                    <Avatar src={teamMemberOrganizer.imageUrl} size={20} />{" "}
                    {teamMemberOrganizer.name}
                  </TableRowColumn>
                  <TableRowColumn className="overview-large-mobile-view">
                    {notes}
                  </TableRowColumn>
                  <TableRowColumn className="overview-large-mobile-view">
                    {statusField(organization, client, now)}
                  </TableRowColumn>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <If condition={this.state.editOrganization}>
          <OverviewEditOrganizationEditOrganization
            client={client}
            organization={this.state.editOrganization}
            onCancel={() => {
              this.setState({ editOrganization: null });
            }}
            onSave={this.handleSaveDecision}
          />
        </If>
      </div>
    );
  }
}
