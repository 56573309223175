import { reasons } from "../content/inbox";
import { sum } from "./helpers";
import _ from "underscore";

const reasonsMap = reasons.reduce(
  (acc, reason) => ({
    ...acc,
    [reason.reason]: reason,
  }),
  {}
);

export default {
  reason(reasonName) {
    return reasonsMap[reasonName.toLowerCase()] || [];
  },

  countForReason(reasonName, reasonCounts) {
    const {
      filters = [],
      experiments = [],
      ignore_experiments: ignoreExperiments = [],
    } = this.reason(reasonName);

    const count = filters
      .map((filter) => reasonCounts[filter.toUpperCase()])
      .filter(Boolean)
      .map(({ name, count = 0, experiment_counts: experimentCounts = {} }) => {
        if (_.isEmpty(experiments)) {
          const experimentDetractor = _.values(
            _.pick(experimentCounts, ignoreExperiments)
          ).reduce(sum, 0);
          return count - experimentDetractor;
        }
        return _.values(_.pick(experimentCounts, experiments)).reduce(sum, 0);
      })
      .reduce(sum, 0);

    return count;
  },

  allReasonCounts(reasonCounts) {
    const allReasons = Object.keys(reasonsMap)
      .map((reasonName) => [
        reasonName,
        this.countForReason(reasonName, reasonCounts),
      ])
      .reduce((acc, [reasonName, count]) => {
        acc[reasonName] = count;
        return acc;
      }, {});
    return { ...allReasons, inbox: _.values(allReasons).reduce(sum, 0) };
  },

  getDefaultReason(organization, client) {
    if (organization && organization.classification) {
      if (organization.classification[client] === "never") {
        return "IS_THIS_SPAM";
      }

      if (organization.classification[client] === "contact") {
        return "DISCOVER";
      }
    }

    return "IS_THIS_INTERESTING";
  },

  getUserInboxes(organization, client, userId) {
    const { inboxes: { [client]: inboxes = [] } = {} } = organization;

    return inboxes.filter((i) => i.user_id === userId);
  },

  getInbox(organization, client, assignedTo, userId) {
    const { inboxes: { [client]: inboxes = [] } = {} } = organization;

    return inboxes.find(
      (i) => i.assigned_to === assignedTo && (!userId || i.user_id === userId)
    );
  },
};
