import React from "react";
import { ListItem } from "material-ui/List";
import PersonEducation from "./PersonEducation";

const educationKey = (education) =>
  `${education.title}-${education.from}-${education.to}`;

class PersonEducations extends React.Component {
  render() {
    const recentEducations = this.props.education.slice(0, 3),
      previousEducations = this.props.education.slice(3);

    return (
      <div className="person-experiences">
        {recentEducations.map((education) => (
          <PersonEducation
            key={educationKey(education)}
            education={education}
          />
        ))}
        <If condition={previousEducations.length > 0}>
          <ListItem
            primaryText="More Education"
            initiallyOpen={false}
            primaryTogglesNestedList={true}
            nestedItems={previousEducations.map((education) => (
              <PersonEducation
                key={educationKey(education)}
                education={education}
              />
            ))}
          />
        </If>
      </div>
    );
  }
}

export default PersonEducations;
