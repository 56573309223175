import PropTypes from "prop-types";
import React from "react";
import Person from "./Person";
import Paper from "material-ui/Paper";
import AppBar from "material-ui/AppBar";
import Loading from "../loading/Loading";
import { ListItem } from "material-ui/List";

import _ from "underscore";
import PersonStore from "../../storage/PersonStore";

export default class People extends React.Component {
  static propTypes = {
    person_ids: PropTypes.array.isRequired,
  };

  state = {
    CLevelBucket: [],
    foundersBucket: [],
    teamBucket: [],
  };

  sortPeople = (people) => {
    const peopleCopy = people.slice(),
      CLevelBucket = [],
      foundersBucket = [],
      teamBucket = [];

    peopleCopy.forEach((person) => {
      const { title = "", role = "" } = person;
      if (
        title.toLowerCase().indexOf("ceo") !== -1 ||
        title.toLowerCase().indexOf("cto ") !== -1
      ) {
        CLevelBucket.push(person);
      } else if (
        role.toLowerCase().indexOf("founder") !== -1 ||
        title.toLowerCase().indexOf("founder") !== -1 ||
        person.id === this.props.primaryContactId
      ) {
        foundersBucket.push(person);
      } else {
        teamBucket.push(person);
      }
    });

    return {
      CLevelBucket,
      foundersBucket,
      teamBucket,
    };
  };

  initComponent = (props) => {
    this.setState({
      CLevelBucket: [],
      foundersBucket: [],
      teamBucket: [],
    });

    const personStore = new PersonStore(props.person_ids);
    personStore
      .getModels(
        props.temporaryExpirationDate,
        props.temporarySignature,
        props.temporaryShowNote,
        props.organization.id
      )
      .then((people) => {
        const uniquePeople = [];
        for (let i = 0; i < people.length; i++) {
          let isFirstInstance = true;
          for (let j = 0; j < uniquePeople.length; j++) {
            if (_.isEqual(uniquePeople[j], people[i])) {
              isFirstInstance = false;
            }
          }
          if (isFirstInstance) uniquePeople.push(people[i]);
        }

        const peopleBuckets = this.sortPeople(uniquePeople);
        this.setState({
          CLevelBucket: peopleBuckets.CLevelBucket,
          foundersBucket: peopleBuckets.foundersBucket,
          teamBucket: peopleBuckets.teamBucket,
        });

        if (this.props.onCompleteRefreshData) {
          this.props.onCompleteRefreshData();
        }
      })
      .catch((error) => console.error("err", error));
  };

  componentWillMount() {
    this.initComponent(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.organization.id !== this.props.organization.id) {
      this.initComponent(nextProps);
      return;
    }

    if (nextProps.refreshData) {
      this.initComponent(nextProps);
    }
  }

  render() {
    const { CLevelBucket, foundersBucket, teamBucket } = this.state;

    if (
      CLevelBucket.length + foundersBucket.length + teamBucket.length === 0 &&
      this.props.person_ids.length > 0
    ) {
      return <Loading />;
    }

    const renderPeople = (people) => {
      return (
        <div>
          {people.map((person, index) => {
            return (
              <Person
                key={`${person.name}${index}`}
                editMode={this.props.editMode}
                onEditPerson={this.props.onEditPerson}
                person={person}
                showAsPublicProfile={this.props.showAsPublicProfile}
                onSetAsPrimaryContact={this.props.onSetAsPrimaryContact}
                primaryContactId={this.props.primaryContactId}
                organizationName={this.props.organization.name}
                organizationFeatures={this.props.organization.ml_features}
                hideSocialLink={this.props.hideSocialLink}
                removePerson={this.props.removePerson}
              />
            );
          })}
        </div>
      );
    };

    if (this.props.editMode) {
      return renderPeople([...CLevelBucket, ...foundersBucket, ...teamBucket]);
    } else {
      return (
        <div className="full-box-container">
          <Paper zDepth={1}>
            <AppBar
              className="appBar light"
              title="People"
              iconElementLeft={
                <div className="icon-container">
                  <div className="people-icon" />
                </div>
              }
            />
            {renderPeople([...CLevelBucket, ...foundersBucket])}
            <If condition={teamBucket.length > 0}>
              <ListItem
                primaryText={`Other people (${teamBucket.length})`}
                initiallyOpen={false}
                primaryTogglesNestedList={true}
                nestedItems={teamBucket.map((person, index) => {
                  return (
                    <Person
                      key={`${person.name}${index}`}
                      editMode={this.props.editMode}
                      onEditPerson={this.props.onEditPerson}
                      person={person}
                      showAsPublicProfile={this.props.showAsPublicProfile}
                      onSetAsPrimaryContact={this.props.onSetAsPrimaryContact}
                      primaryContactId={this.props.primaryContactId}
                      organizationName={this.props.organization.name}
                      organizationFeatures={this.props.organization.ml_features}
                      hideSocialLink={this.props.hideSocialLink}
                      removePerson={this.props.removePerson}
                    />
                  );
                })}
              />
            </If>
          </Paper>
        </div>
      );
    }
  }
}
