import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";
import _ from "underscore";

import Card from "../../../card/Card";
import CardHeader from "../../../card/CardHeader";
import CardBody from "../../../card/CardBody";

import CompanyInfo from "./CompanyInfo";
import Description from "./Description";
import Similarity from "./Similarity";
import Tags from "./Tags";
import CompressedInsights from "./CompressedInsights";
import PeoplePerformance from "./PeoplePerformance";
import ProductPerformance from "./ProductPerformance";
import MarketPerformance from "./MarketPerformance";
import FundingPerformance from "./FundingPerformance";

import Organizations from "../../../../lib/Organizations";

import convertedLegacyInsights from "../../../../content/convertedLegacyInsights";

class SummarySection extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    snapshot: PropTypes.object.isRequired,
    snapshotLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool.isRequired,
    investors: PropTypes.array.isRequired,
    investorsLoading: PropTypes.bool.isRequired,
    getInvestors: PropTypes.func,
    signals: PropTypes.object.isRequired,
    signalsLoading: PropTypes.bool.isRequired,
    fundingRounds: PropTypes.array.isRequired,
    fundingRoundsLoading: PropTypes.bool.isRequired,
    investmentValues: PropTypes.object.isRequired,
    getProductType: PropTypes.func.isRequired,
    getProductRatings: PropTypes.func.isRequired,
    getProductAnalytics: PropTypes.func.isRequired,
    getProductTrend: PropTypes.func.isRequired,
    compressed: PropTypes.bool.isRequired,
  };

  buildSummary = () => {
    return {
      companyInfo: this.getCompanyInfoData(),
      description: this.getDescriptionData(),
      tags: this.getTagsData(),
      industriesAndTechnologies: this.getIndustriesAndTechnologiesData(),
      people: this.getPeopleData(),
      product: this.getProductData(),
      market: this.getMarketData(),
      funding: this.getFundingData(),
    };
  };

  getCompanyInfoData = () => {
    const {
      investmentValues: {
        investmentType: { type: investmentType = "" } = {},
        investmentStage: { stage: investmentStage = "" } = {},
      } = {},
    } = this.props;
    return {
      founded: this.getFoundedOn(),
      locations: this.getLocations(),
      fundingAmount: this.getFundingAmount(),
      fundingRounds: this.getFundingRounds(),
      investors: this.getInvestors(),
      investmentType,
      investmentStage,
      employees: this.getEmployees(),
    };
  };

  getFoundedOn = () => {
    const {
      organization: {
        created_at: createdAt = 0,
        founded_on: foundedOn = 0,
        ml_features: { founded_on_smoothed_manual: { score = 0 } = {} } = {},
      } = {},
      snapshot: {
        organization: {
          created_at: snapshotCreatedAt = 0,
          founded_on: snapshotFoundedOn = 0,
          ml_features: {
            founded_on_smoothed_manual: { snapshotScore = 0 } = {},
          } = {},
        } = {},
      } = {},
    } = this.props;
    return {
      on: foundedOn,
      firstSeen: createdAt,
      highlighted: Math.min(Math.max(score, 1), 0) >= 0.8,
      snapshot: {
        on: snapshotFoundedOn,
        firstSeen: snapshotCreatedAt,
        highlighted: Math.min(Math.max(snapshotScore, 1), 0) >= 0.8,
      },
    };
  };

  getLocations = () => {
    const {
      organization: {
        locations = [],
        ml_features: { is_in_europe: { score: isInEuropeScore = 1 } = {} } = {},
      } = {},
      snapshot: {
        organization: {
          locations: snapshotLocations = [],
          ml_features: {
            is_in_europe: { score: snapshotIsInEuropeScore = 1 } = {},
          } = {},
        } = {},
      } = {},
    } = this.props;
    return {
      locations,
      error: isInEuropeScore === 0,
      snapshot: {
        locations: snapshotLocations,
        error: snapshotIsInEuropeScore === 0,
      },
    };
  };

  getFundingAmount = () => {
    const {
      organization: {
        total_funding_usd: fundingAmount = 0,
        funding_stage: fundingStage = "",
        ml_features: { total_funding_smoothed_manual: { score = 0 } = {} } = {},
      } = {},
      fundingRounds = [],
      snapshot: {
        organization: {
          total_funding_usd: snapshotFundingAmount = 0,
          funding_stage: snapshotFundingStage = "",
          ml_features: {
            total_funding_smoothed_manual: { snapshotScore = 0 } = {},
          } = {},
        } = {},
        fundingRounds: snapshotFundingRounds = [],
      } = {},
    } = this.props;
    return {
      amount: fundingAmount,
      stage: fundingStage,
      highlighted: Math.min(Math.max(score, 1), 0) >= 0.8,
      hasFundingRounds: fundingRounds.length > 0,
      snapshot: {
        amount: snapshotFundingAmount,
        stage: snapshotFundingStage,
        highlighted: Math.min(Math.max(snapshotScore, 1), 0) >= 0.8,
        hasFundingRounds: snapshotFundingRounds.length > 0,
      },
    };
  };

  getFundingRounds = () => {
    const {
      organization: {
        ml_features: {
          most_recent_funding_round: {
            transformed: mlLastFundingRound = "{}",
          } = {},
        } = {},
      } = {},
      fundingRounds = [],
      snapshot: { decision: { created_at } = {} } = {},
    } = this.props;
    return {
      lastFundingRound: JSON.parse(mlLastFundingRound),
      fundingRounds,
      decisionMade: created_at,
    };
  };

  getInvestors = () => {
    const {
      organization: {
        investor_ids = [],
        ml_features: {
          highlights: {
            highlights: {
              investors: { investors: legacyInvestors = [] } = {},
            } = {},
          } = {},
          interesting_microvc: { notes: microVcIds = [] } = {},
          interesting_angel: { notes: angelIds = [] } = {},
          aggregated_followon_percentage: {
            score: aggregatedFollowonScore = 0.0,
            notes: aggregatedFollowonIds = [],
          } = {},
        } = {},
      } = {},
      investors = [],
      snapshot: {
        organization: { investor_ids: snapshotInvestorIds = [] } = {},
      } = {},
    } = this.props;
    return {
      investors,
      aggregatedFollowonScore,
      investorIds: {
        all: investor_ids,
        interestingInvestors: [...aggregatedFollowonIds],
      },
      highlightedInvestorNames: this.getHighlightedInvestors(),
      snapshot: {
        investors: investors.reduce((investors, investor) => {
          if (snapshotInvestorIds.includes(investor.id)) {
            investors.push(investor);
          }
          return investors;
        }, []),
        highlightedInvestorNames: this.getHighlightedInvestors(true),
      },
    };
    /*const highlightedInvestors = this.getHighlightedInvestors();
    return investors
      .map((investor) => {
        if (highlightedInvestors.includes(investor.name)) {
          investor.highlighted = true;
        }
        return investor;
      })
      .sort((a, b) => {
        if (a.highlighted && b.highlighted) {
          return 0;
        } else if (a.highlighted && !b.highlighted) {
          return -1;
        } else if (b.highlighted && !a.highlighted) {
          return 1;
        } else {
          return 0;
        }
      });*/
  };

  getHighlightedInvestors = (useSnapshot) => {
    const {
      organization: {
        ml_features: {
          ui_ranking: {
            company: { matched_highlights: uiRankingHighlights = [] } = {},
          } = {},
          highlights: {
            highlights: {
              investors: { investors: legacyInvestors = [] } = {},
            } = {},
          } = {},
          interesting_microvc: { notes: microVcIds = [] } = {},
          interesting_angel: { notes: angelIds = [] } = {},
        } = {},
      } = {},
      investors = [],
      snapshot: {
        organization: {
          ml_features: {
            ui_ranking: {
              company: {
                matched_highlights: snapshotUiRankingHighlights = [],
              } = {},
            } = {},
            highlights: {
              highlights: {
                investors: { investors: snapshotLegacyInvestors = [] } = {},
              } = {},
            } = {},
            interesting_microvc: { notes: snapshotMicroVcIds = [] } = {},
            interesting_angel: { notes: snapshotAngelIds = [] } = {},
          } = {},
        } = {},
      } = {},
    } = this.props;
    if (
      (!useSnapshot && uiRankingHighlights.length > 0) ||
      (useSnapshot && snapshotUiRankingHighlights.length > 0)
    ) {
      const { key_information: investorHighlights = [] } = (
        !useSnapshot ? uiRankingHighlights : snapshotUiRankingHighlights
      ).find((highlight) => highlight.type === "investors");
      return investorHighlights.map(({ name }) => name);
    } else {
      return investors.reduce(
        (highlightedInvestorNames, { id, name }) => {
          if (
            !useSnapshot
              ? microVcIds.includes(id) || angelIds.includes(id)
              : snapshotMicroVcIds.includes(id) || snapshotAngelIds.includes(id)
          ) {
            highlightedInvestorNames.push(name);
          }
          return highlightedInvestorNames;
        },
        !useSnapshot ? legacyInvestors : snapshotLegacyInvestors
      );
    }
  };

  getEmployees = () => {
    const {
      organization: {
        num_employees_min: minEmployees = 0,
        num_employees_max: maxEmployees = 0,
        person_ids: personIds = [],
      } = {},
      snapshot: {
        organization: {
          num_employees_min: snapshotMinEmployees = 0,
          num_employees_max: snapshotMaxEmployees = 0,
          person_ids: snapshotPersonIds = [],
        } = {},
      } = {},
    } = this.props;
    return {
      min: minEmployees,
      max: maxEmployees,
      inDIG: personIds.length,
      snapshot: {
        min: snapshotMinEmployees,
        max: snapshotMaxEmployees,
        inDIG: snapshotPersonIds.length,
      },
    };
  };

  getDescriptionData = () => {
    const { organization: { description = "", short_description = "" } = {} } =
      this.props;
    return {
      description,
      shortDescription: short_description,
    };
  };

  getTagsData = () => {
    const {
      organization: {
        ml_features: { tags_prediction: { score = 0, tags = {} } = {} } = {},
      } = {},
    } = this.props;
    const min = 0.3309008660410637;
    const max = 0.728999904772718;
    return {
      tags,
      highlighted: Math.max(Math.min((score - min) / (max - min), 1), 0) > 0.8,
    };
  };

  getIndustriesAndTechnologiesData = () => {
    const {
      organization: {
        ml_features: {
          tags_industries_technologies: { score = 0, transformed = "{}" } = {},
        } = {},
      } = {},
    } = this.props;
    const tags = JSON.parse(transformed);
    return tags;
  };

  getPeopleData = () => ({
    ...this.getSectionInsights("people"),
    snapshot: {
      ...this.getSectionInsights("people", true),
    },
  });

  getProductData = () => {
    const {
      organization = {},
      snapshot: { decision: { created_at: decisionCreatedAt } = {} } = {},
    } = this.props;
    const { getProductType, getProductRatings, getProductAnalytics } =
      this.props;
    const productType = getProductType();
    const snapshotProductType = getProductType(true);
    const appId = Organizations.primaryTrendAppIdFromOrganizationOrSnapshot(
      false,
      { organization }
    );

    return {
      ...this.getSectionInsights("product"),
      productType,
      ratings: getProductRatings(productType),
      analytics: getProductAnalytics(productType, appId),
      snapshot: {
        ...this.getSectionInsights("product", true),
        productType: snapshotProductType,
        ratings: getProductRatings(snapshotProductType, appId),
        analytics: getProductAnalytics(productType, appId),
        decisionCreatedAt,
      },
      mlUpdates: this.getSectionMlUpdates("product"),
    };
  };

  getMarketData = () => {
    return {
      ...this.getSectionInsights("market"),
      snapshot: {
        ...this.getSectionInsights("market", true),
      },
      mlUpdates: this.getSectionMlUpdates("market"),
    };
  };

  getFundingData = () => ({
    ...this.getSectionInsights("funding"),
    snapshot: {
      ...this.getSectionInsights("funding", true),
    },
    mlUpdates: this.getSectionMlUpdates("funding"),
  });

  getSectionMlUpdates = (section) => {
    const {
      organization: {
        ml_updated_features = {},
        ml_updated = [],
        products,
      } = {},
    } = this.props;

    const partnerLastScore = ml_updated.filter((score_last_seen) => {
      const { user_id = "" } = score_last_seen;
      return user_id === "roberto@inreachventures.com";
    });
    const lastSeen = partnerLastScore.length
      ? partnerLastScore[0].last_seen
      : 0;
    const sectionUpdates = Object.values(ml_updated_features).filter(
      (mlUpdate) => {
        const { name = "", created_at = 0, score } = mlUpdate;

        return (
          name.split("_")[0] === section && created_at > lastSeen && score > 0
        );
      }
    );
    return sectionUpdates
      .map((sectionUpdate) => {
        if (
          !["website", "social", "funding_new_funding_round"].includes(
            sectionUpdate.raw
          ) &&
          products
        ) {
          const isAndroid = products.filter(
            (product) =>
              sectionUpdate.raw === product.id && product.store === "PLAY"
          ).length;
          if (isAndroid)
            return sectionUpdate.notes
              ? sectionUpdate.notes[0].replace("iOs app", "Android app")
              : sectionUpdate.name;
        }
        return sectionUpdate.notes;
      })
      .flat();
  };

  getSectionInsights = (section, snapshot = false) => {
    const {
      organization: {
        ml_features: {
          ui_ranking: { [section]: uiRankingSection = [] } = {},
        } = {},
      } = {},
      snapshot: {
        organization: {
          ml_features: {
            ui_ranking: { [section]: snapshotUiRankingSection = [] } = {},
          } = {},
        } = {},
      } = {},
    } = this.props;
    if (
      (!snapshot && uiRankingSection.length === 0) ||
      (snapshot && snapshotUiRankingSection.length === 0)
    ) {
      switch (section) {
        case "people":
          return this.getLegacyPeopleInsights(snapshot);
        case "product":
          return this.getLegacyProductInsights(snapshot);
        case "funding":
          return this.getLegacyFundingInsights(snapshot);
        case "market":
          return this.getLegacyMarketInsights(snapshot);
        default:
          return {
            highlights: [],
            risks: [],
          };
      }
    } else {
      return (!snapshot ? uiRankingSection : snapshotUiRankingSection).reduce(
        (
          {
            highlights: uniqueHighlights,
            risks: uniqueRisks,
            redFlags: uniqueRedFlags,
          },
          {
            matched_highlights: highlights = [],
            matched_risks: risks = [],
            matched_red_flags: redFlags = [],
          }
        ) => {
          highlights.forEach((highlight) => {
            if (
              !uniqueHighlights.find(
                ({ insight_id: uniqueHighlightId }) =>
                  uniqueHighlightId === highlight.insight_id
              )
            ) {
              uniqueHighlights.push(highlight);
            }
          });
          risks.forEach((risk) => {
            if (
              !uniqueRisks.find(
                ({ insight_id: uniqueRiskId }) =>
                  uniqueRiskId === risk.insight_id
              )
            ) {
              uniqueRisks.push(risk);
            }
          });
          redFlags.forEach((redFlag) => {
            if (
              !uniqueRedFlags.find(
                ({ insight_id: uniqueRedFlagId }) =>
                  uniqueRedFlagId === redFlag.insight_id
              )
            ) {
              uniqueRedFlags.push(redFlag);
            }
          });
          return {
            highlights: uniqueHighlights,
            risks: uniqueRisks,
            redFlags: uniqueRedFlags,
          };
        },
        {
          highlights: [],
          risks: [],
          redFlags: [],
        }
      );
    }
  };

  getLegacyPeopleInsights = (snapshot) => {
    const {
      organization: {
        ml_features: {
          highlights: { highlights: legacyHighlights = {} } = {},
        } = {},
      } = {},
      snapshot: {
        organization: {
          ml_features: {
            highlights: { highlights: snapshotLegacyHighlights = {} } = {},
          } = {},
        } = {},
      } = {},
    } = this.props;
    return Object.entries(
      !snapshot ? legacyHighlights : snapshotLegacyHighlights
    ).reduce(
      ({ highlights, risks, redFlags }, [highlight]) => {
        if (
          highlight !== "tech_keywords" &&
          highlight !== "trend" &&
          highlight !== "investors"
        ) {
          highlights.push(convertedLegacyInsights[highlight]);
        }
        return {
          highlights,
          risks,
          redFlags,
        };
      },
      { highlights: [], risks: [], redFlags: [] }
    );
  };

  getLegacyMarketInsights = (snapshot) => {
    const {
      organization: {
        website_info: {
          is_localized: isLocalized = null,
          is_english: isEnglish = null,
          timeout = null,
          localized_url: localizedUrl,
        } = {},
      } = {},
      snapshot: {
        organization: {
          website_info: {
            is_localized: snapshotIsLocalized = null,
            is_english: snapshotIsEnglish = null,
            snapshotTimeout = null,
            localized_url: snapshotLocalizedUrl,
          } = {},
        } = {},
      } = {},
    } = this.props;
    const insights = {
      highlights: [],
      risks: [],
      redFlags: [],
    };
    if (
      !snapshot
        ? isLocalized === false && isEnglish === false && timeout === false
        : !snapshotIsLocalized && !snapshotIsEnglish && !snapshotTimeout
    ) {
      insights.redFlags.push(convertedLegacyInsights["website_not_localized"]);
    }
    if (
      (!snapshot && isLocalized === true) ||
      (snapshot && snapshotIsLocalized === true)
    ) {
      insights.highlights.push({
        ...convertedLegacyInsights["website_is_localized"],
        localizedUrl: !snapshot ? localizedUrl : snapshotLocalizedUrl,
      });
    }
    return insights;
  };

  getLegacyProductInsights = (snapshot) => {
    const {
      organization: {
        ml_features: {
          tags_innovation_score: { score: innovationScore = 1 } = {},
        } = {},
      } = {},
      snapshot: {
        organization: {
          ml_features: {
            tags_innovation_score: { score: snapshotInnovationScore = 1 } = {},
          } = {},
        } = {},
      } = {},
    } = this.props;
    const insights = {
      highlights: [],
      risks: [],
      redFlags: [],
    };
    if (!snapshot ? innovationScore < 0.1 : snapshotInnovationScore < 0.1) {
      insights.redFlags.push(convertedLegacyInsights["innovation_score"]);
    }
    return insights;
  };

  getLegacyFundingInsights = (snapshot) => {
    const {
      organization: {
        ml_features: {
          highlights: { highlights: legacyHighlights = {} } = {},
          is_not_ico: { score: icoScore = 1 } = {},
          is_zombie: { score: zombieScore = 0 } = {},
        } = {},
      } = {},
      snapshot: {
        organization: {
          ml_features: {
            highlights: { highlights: snapshotLegacyHighlights = {} } = {},
            is_not_ico: { score: snapshotIcoScore = 1 } = {},
            is_zombie: { score: snapshotZombieScore = 0 } = {},
          } = {},
        } = {},
      } = {},
    } = this.props;
    const insights = {
      highlights: [],
      risks: [],
      redFlags: [],
    };
    Object.entries(
      !snapshot ? legacyHighlights : snapshotLegacyHighlights
    ).forEach(([highlight]) => {
      if (highlight === "investors") {
        insights.highlights.push(convertedLegacyInsights[highlight]);
      }
    });
    if (!snapshot ? icoScore === 0 : snapshotIcoScore === 0) {
      insights.redFlags.push(convertedLegacyInsights["is_ico"]);
    }
    if (!snapshot ? zombieScore === 1 : snapshotZombieScore === 1) {
      insights.risks.push(convertedLegacyInsights["is_zombie"]);
    }
    return insights;
  };

  buildClassNames = () => {
    const { compressed = false } = this.props;
    const classNames = ["grid-container", "summary-grid-container"];
    if (compressed) {
      classNames.push("compressed");
    }
    return classNames.join(" ");
  };

  render() {
    const {
      useSnapshot,
      signalsLoading,
      fundingRoundsLoading,
      investorsLoading,
      getInvestors,
      compressed = false,
      organization,
      organizationDecision,
      handleNavOrganizationsSimilarTo,
    } = this.props;
    const {
      companyInfo,
      description,
      tags,
      industriesAndTechnologies,
      people,
      product,
      market,
      funding,
    } = this.buildSummary();
    return (
      <div className={this.buildClassNames()}>
        <div className="summary-grid-item company-info-grid-item">
          <CompanyInfo
            useSnapshot={useSnapshot}
            companyInfo={companyInfo}
            fundingRoundsLoading={fundingRoundsLoading}
            investorsLoading={investorsLoading}
            getInvestors={getInvestors}
            compressed={compressed}
          />
        </div>
        <div className="summary-grid-item description-grid-item">
          <Description
            description={description}
            compressed={compressed}
            fields_with_source_ref={organization.fields_with_source_ref}
          />
          {organization?.ml_features?.description_embedding?.highlights
            ?.embedding?.name && (
            <Similarity
              compressed={compressed}
              handleNavOrganizationsSimilarTo={handleNavOrganizationsSimilarTo}
              organization={this.props.organization}
            />
          )}
        </div>
        <If condition={!compressed}>
          <div className="summary-grid-item tags-grid-item">
            <Tags
              tags={tags}
              industriesAndTechnologies={industriesAndTechnologies}
            />
          </div>
        </If>
        {/*<div className="summary-grid-item border-1-grid-item" />
        <If condition={compressed && window.innerWidth < 768}>
          <div className="summary-grid-item compressed-insights-grid-item">
            <CompressedInsights
              companyInfo={companyInfo}
              investorsLoading={investorsLoading}
              getInvestors={getInvestors}
              people={people}
              product={product}
              social={social}
              funding={funding}
            />
          </div>
        </If>*/}
        {/* <div className="summary-grid-item people-grid-item">
          <PeoplePerformance
            people={people}
            useSnapshot={useSnapshot}
            compressed={compressed}
            organization={organization}
          />
        </div>
        <div className="summary-grid-item border-2-grid-item" /> */}
        <div className="summary-grid-item product-grid-item">
          <ProductPerformance
            product={product}
            signalsLoading={signalsLoading}
            useSnapshot={useSnapshot}
            compressed={compressed}
            organization={organization}
            organizationDecision={organizationDecision}
          />
        </div>
        {/* <div className="summary-grid-item border-3-grid-item" />
        <div className="summary-grid-item market-grid-item">
          <MarketPerformance
            market={market}
            useSnapshot={useSnapshot}
            compressed={compressed}
          />
        </div>
        <div className="summary-grid-item border-4-grid-item" />
        <div className="summary-grid-item funding-grid-item">
          <FundingPerformance
            funding={funding}
            useSnapshot={useSnapshot}
            compressed={compressed}
          />
        </div> */}
      </div>
    );
  }
}

export default withRouter(SummarySection);
