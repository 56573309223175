import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import insightIcons from "../../../content/insights";
import Tooltip from "../../Tooltip";

class Insight extends React.Component {
  static propTypes = {
    insight: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    hasHeader: PropTypes.bool.isRequired,
    source: PropTypes.string,
    compressed: PropTypes.bool.isRequired,
  };

  state = {
    showTooltip: false,
  };

  getInsightClassNames = () => {
    const { hasHeader = false } = this.props;
    const classNames = ["data-content-list-item", "component"];
    if (hasHeader) {
      classNames.push("has-header");
    }
    return classNames.join(" ");
  };

  getTooltipNote = () => {
    const { insight: { insight_id, notes, localizedUrl = "" } = {} } =
      this.props;
    return insight_id === 7 && localizedUrl !== ""
      ? `${notes}. Click to view localized version`
      : notes;
  };

  getIconTextComponentClassNames = () => {
    const {
      insight: { insight_id, localizedUrl = "" },
      type,
    } = this.props;
    const classNames = ["icon-text-component", type];
    if (insight_id === 7 && localizedUrl !== "") {
      classNames.push("clickable");
    }
    return classNames.join(" ");
  };

  handleIconTextComponentClick = () => {
    const {
      insight: { insight_id, localizedUrl = "" },
    } = this.props;
    if (insight_id === 7 && localizedUrl !== "") {
      window.open(localizedUrl, "_blank");
    }
  };

  render() {
    const {
      insight: { name, notes, icon_id },
      type,
      source = "",
      compressed = false,
    } = this.props;

    const { showTooltip } = this.state;
    return (
      <div
        className={this.getInsightClassNames()}
        key={name}
        onMouseLeave={() => {
          this.setState({ showTooltip: false });
        }}
        onMouseEnter={() => {
          this.setState({ showTooltip: true });
        }}
        rel={(el) => (this._parent = el)}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipNote()}
          customStyles={
            source === "funding"
              ? {
                  left: "-50px",
                }
              : {}
          }
        />
        <div
          className={this.getIconTextComponentClassNames()}
          onClick={this.handleIconTextComponentClick}
        >
          {/*<If condition={!compressed}>
            <div className="icon">{(insightIcons[icon_id] || {}).icon}</div>
          </If>*/}
          <div
            className={`text ${
              type === "highlight" ? "highlighted-text" : type
            }`}
          >
            {name}
          </div>
        </div>
      </div>
    );
  }
}

class Insights extends React.Component {
  static propTypes = {
    highlights: PropTypes.array,
    risks: PropTypes.array,
    redFlags: PropTypes.array,
    mlUpdates: PropTypes.array,
    hasHeader: PropTypes.bool,
    compressed: PropTypes.bool,
  };

  render() {
    const {
      highlights = [],
      risks = [],
      redFlags = [],
      mlUpdates = [],
      hasHeader = false,
      compressed = false,
    } = this.props;
    return (
      <Choose>
        <When
          condition={
            highlights.length === 0 &&
            risks.length === 0 &&
            redFlags.length === 0 &&
            mlUpdates.length === 0
          }
        >
          <div className="data-content text empty">No insights found</div>
        </When>
        <Otherwise>
          <div className="data-content list">
            {redFlags.map((insight) => (
              <Insight
                insight={insight}
                type="red-flag"
                hasHeader={hasHeader}
                key={insight.name}
                compressed={compressed}
              />
            ))}
            {risks.map((insight) => (
              <Insight
                insight={insight}
                type="risk"
                hasHeader={hasHeader}
                key={insight.name}
                compressed={compressed}
              />
            ))}
            {highlights.map((insight) => (
              <Insight
                insight={insight}
                type="highlight"
                hasHeader={hasHeader}
                key={insight.name}
                compressed={compressed}
              />
            ))}
            {mlUpdates.map((insight) => {
              return (
                <Insight
                  insight={{ name: "+ " + insight }}
                  type="highlight"
                  hasHeader={hasHeader}
                  key={insight}
                  compressed={compressed}
                />
              );
            })}
          </div>
        </Otherwise>
      </Choose>
    );
  }
}

export default withRouter(Insights);
