import React, { useState } from "react";
import PropTypes from "prop-types";
import TaskStore from "../../storage/TaskStore";
import DialogWrapper from "../components/DialogWrapper";
import { RaisedButton } from "material-ui";
import auth from "../../storage/Auth";
import Config from "../../config";
import request from "superagent";

const taskStore = new TaskStore();

const successMessage = "Sync completed successfully!";

function hasLinkedInSynced(person) {
  return (
    person.source_refs &&
    person.source_refs.CORESIGNAL_DB &&
    person.source_refs.CORESIGNAL_DB.length > 0
  );
}

function wasSuccessful(syncStatus) {
  return syncStatus === successMessage;
}

function SyncWithLinkedIn({ person, onSyncComplete }) {
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncStatus, setSyncStatus] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    if (wasSuccessful(syncStatus)) {
      onSyncComplete();
    }
    setOpenModal(false);
  };

  const handleSync = () => {
    setIsSyncing(true);
    setOpenModal(true);
    setSyncStatus("Syncing...");

    let intervalId;
    const timeoutId = setTimeout(() => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      setIsSyncing(false);
      setSyncStatus("Sync timed out.");
    }, 3 * 60 * 1000);

    triggerSyncWithLinkedIn(person.id)
      .then(() => {
        intervalId = setInterval(() => {
          checkSyncStatus(person.id)
            .then((status) => {
              if (status) {
                clearInterval(intervalId);
                clearInterval(timeoutId);
                setIsSyncing(false);
                setSyncStatus(successMessage);
              } else {
                setSyncStatus((previousSyncStatus) => previousSyncStatus + ".");
              }
            })
            .catch((error) => {
              console.error(`Problem retrieving person: ${person.id}`, error);
              clearInterval(intervalId);
              clearInterval(timeoutId);
              setIsSyncing(false);
              setSyncStatus("Error: " + error.message);
            });
        }, 2000);
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        setIsSyncing(false);
        setSyncStatus("Error: " + error.message);
      });
  };
  if (!person.linkedin_url) {
    return (
      <div>
        <button disabled={true}>Add LinkedIn url to sync</button>
      </div>
    );
  }

  return (
    <div>
      <button onClick={handleSync} disabled={isSyncing}>
        {isSyncing
          ? "Syncing..."
          : hasLinkedInSynced(person)
          ? "Re-sync with LinkedIn"
          : "Sync with LinkedIn"}
      </button>
      <DialogWrapper
        open={openModal}
        onClose={handleClose}
        modal={true}
        actions={[
          <RaisedButton onClick={handleClose} color="primary" label="close" />,
        ]}
        repositionOnUpdate={true}
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
      >
        <h3>{syncStatus}</h3>
        {wasSuccessful(syncStatus) && (
          <p>
            You may have to wait a few minutes for this person to be rescored.
          </p>
        )}
      </DialogWrapper>
    </div>
  );
}

SyncWithLinkedIn.propTypes = {
  person: PropTypes.object.isRequired,
  onSyncComplete: PropTypes.func.isRequired,
};

const triggerSyncWithLinkedIn = (personId) => {
  return new Promise((resolve, reject) => {
    taskStore
      .startTask("retrieve_core_signal_db_person_by_handle", {
        person_id: personId,
        no_verify: true,
        synchronously: true,
        bypass_validation: true,
      })
      .then((r) => resolve(r))
      .catch((error) => {
        if (error.message.includes("timeout")) {
          resolve({});
        } else {
          reject(error);
        }
      });
  });
};

const checkSyncStatus = (personId) => {
  return auth
    .getAuthData()
    .then(({ token, client }) =>
      request
        .get(`${Config.api.host}/people/${personId}`)
        .set("Content-Type", "application/json")
        .query({ access_token: token, client })
    )
    .then((response) => {
      return hasLinkedInSynced(response.body);
    });
};

export default SyncWithLinkedIn;
