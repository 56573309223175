import React from "react";
import { browserHistory, withRouter } from "react-router-v4";

import moment from "moment";
import _ from "underscore";
import diff from "deep-diff";

import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import Checkbox from "material-ui/Checkbox";
import Dialog from "material-ui/Dialog";

import AddCircleOutline from "material-ui/svg-icons/content/add-circle";

import Card from "../../card/Card";
import CardBody from "../../card/CardBody";
import Loading from "../../loading/Loading";
import EditOrganizationHeader from "./EditOrganizationHeader";
import People from "../People";
import DeleteOrganization from "./DeleteOrganization";
import EditPersonDialog from "../EditPersonDialog";
import AddPersonDialog from "../AddPersonDialog";
import EditDomains from "./EditDomains";
import EditUrls from "./EditUrls";
import EditAlsoKnownAs from "./EditAlsoKnownAs";
import EditFundingStage from "./EditFundingStage";
import EditPhoneNumbers from "./EditPhoneNumbers";
import EditBasicInfo from "./EditBasicInfo";
import EditOrganizationLocations from "./EditOrganizationLocations";
import EditOrganizationDescription from "./EditOrganizationDescription";
import EditOrganizationWebsite from "./EditOrganizationWebsite";
import EditOrganizationFoundedOn from "./EditOrganizationFoundedOn";
import EditOrganizationTotalFunding from "./EditOrganizationTotalFunding";

import CreateOrganizationHelper from "../../../lib/CreateOrganizationHelper";
import PatchHelper from "../../../lib/PatchHelper";

import OrganizationStore from "../../../storage/OrganizationStore";
import auth from "../../../storage/Auth";
import EditOrganizationPeopleEmployeesRange from "./EditOrganizationPeopleEmployeesRange";
import EditInvestors from "./EditInvestors";
import EditInvestmentType from "./EditInvestmentType";
import EditInvestmentStage from "./EditInvestmentStage";
import DialogWrapper from "../../components/DialogWrapper";
import SyncWithLinkedIn from "./SyncWithLinkedIn";

class EditOrganization extends React.Component {
  state = {
    isLoading: false,
    isSaving: false,
    saveSuccess: false,
    userHasFullAccess: false,
    initialOrganization: null,
    organization: null,
    errors: {},
    modifiedFields: [],
    showErrorDialog: false,
    errorMessage: null,
    showEditPersonDialog: false,
    editPersonId: null,
    refreshPeopleData: false,
    showAddPersonDialog: false,
  };

  createOrganizationHelper = new CreateOrganizationHelper();
  organizationStore = new OrganizationStore(this.props.params.id);

  componentWillMount() {
    this.setUpdatedFields = _.debounce(this.setUpdatedFields, 500);
    this.organizationStore
      .getModel()
      .then((organization) => {
        this.setState({
          initialOrganization: JSON.parse(JSON.stringify(organization)),
          organization,
        });
      })
      .catch((err) => console.log("Unable to retrieve Organization", err));

    auth
      .getAuthData()
      .then(({ accessType, roles, client }) => {
        this.setState({
          userHasFullAccess: accessType === "full",
          userRoles: roles,
          client,
        });
      })
      .catch((err) => console.error(err));
  }

  handleOrganizationChange = (organization) => {
    this.setState({ organization });
    this.setUpdatedFields(organization);
  };

  handleDomainsChange = (domains) => {
    const organizationCopy = Object.assign({}, this.state.organization);
    organizationCopy.domains = domains;
    this.handleOrganizationChange(organizationCopy);
  };

  handleUrlsChange = (urls) => {
    const organizationCopy = Object.assign({}, this.state.organization);
    organizationCopy.urls = urls;
    this.handleOrganizationChange(organizationCopy);
  };

  handlePhoneNumbersChange = (phone_numbers) => {
    const organizationCopy = Object.assign({}, this.state.organization);
    organizationCopy.phone_numbers = phone_numbers;
    this.handleOrganizationChange(organizationCopy);
  };

  handleAlsoKnownAsChange = (alsoKnownAs) => {
    const organizationCopy = Object.assign({}, this.state.organization);
    organizationCopy.also_known_as = alsoKnownAs;
    this.handleOrganizationChange(organizationCopy);
  };

  handleFundingStageChange = (fundingStage) => {
    const organizationCopy = Object.assign({}, this.state.organization);
    organizationCopy.funding_stage = fundingStage;
    this.handleOrganizationChange(organizationCopy);
  };

  handleInvestmentTypeChange = (investmentType) => {
    const organizationCopy = Object.assign({}, this.state.organization);
    organizationCopy.investment_type = investmentType;
    this.handleOrganizationChange(organizationCopy);
  };

  handleInvestmentStageChange = (investmentStage) => {
    const organizationCopy = Object.assign({}, this.state.organization);
    organizationCopy.investment_stage = investmentStage;
    this.handleOrganizationChange(organizationCopy);
  };

  cancelEdit = () => {
    const { router, location } = this.props;
    const { organization: { id } = {} } = this.state;
    router.push({
      ...location,
      ...{
        pathname: `/organizations/${id}`,
      },
    });
  };

  saveEdit = () => {
    if (
      !this.state.initialOrganization ||
      !this.state.organization ||
      this.state.isSaving ||
      this.state.saveSuccess
    )
      return;
    this.setState({ isSaving: true });
    return this.organizationStore
      .updateModel(
        PatchHelper.getPatchDataFromDiffData(
          this.state.initialOrganization,
          this.state.organization
        )
      )
      .then((organization) => {
        this.setState({
          isSaving: false,
          saveSuccess: true,
          initialOrganization: JSON.parse(JSON.stringify(organization)),
          organization: JSON.parse(JSON.stringify(organization)),
          modifiedFields: [],
        });
        setTimeout(() => {
          this.setState({
            saveSuccess: false,
          });
        }, 3000);
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({
          saveSuccess: false,
          isSaving: false,
          showErrorDialog: true,
          errorMessage: err.toString(),
        });
      });
  };

  getModifiedFields = (initialOrganization, modifiedOrganization) => {
    const orgDiff = diff(initialOrganization, modifiedOrganization);

    return orgDiff
      ? orgDiff.map((diffEntry) => (diffEntry.path ? diffEntry.path[0] : null))
      : [];
  };

  setUpdatedFields = (organization) => {
    const modifiedFields = this.getModifiedFields(
      this.state.initialOrganization,
      organization
    );

    this.setState({
      modifiedFields,
    });
  };

  updateOrganizationField = (field, value) => {
    let updatedOrganization = Object.assign({}, this.state.organization, {
      [field]: value,
    });
    this.setUpdatedFields(updatedOrganization);
    this.handleOrganizationChange(updatedOrganization);
  };

  updateOrganizationFields = (data) => {
    const { organization = {} } = this.state;
    data.forEach(({ field, value }) => {
      organization[field] = value;
    });
    this.setUpdatedFields(organization);
    this.handleOrganizationChange(organization);
  };

  validateOrganizationField = (field, value, type) => {
    const errors = Object.assign({}, this.state.errors);
    errors[field] = this.createOrganizationHelper.validateField(
      null,
      value,
      type
    );

    this.setState({ errors });
  };

  checkIfErrors = (errors) => {
    return Object.keys(errors).filter((field) => !!errors[field]).length > 0;
  };

  checkIfFieldsHaveBeenEdited = (fields) => {
    return (
      fields.filter((field) => this.state.modifiedFields.indexOf(field) !== -1)
        .length > 0
    );
  };

  handleErrorDialogClose = () => {
    this.setState({
      errorMessage: null,
      showErrorDialog: false,
    });
  };

  handleDeleteOrganization = () => {
    this.organizationStore.deleteModel(() =>
      browserHistory.push(`/organizations`)
    );
  };

  handleEditPerson = (editPersonId) => {
    this.setState({
      showEditPersonDialog: true,
      editPersonId,
    });
  };

  handleCloseEditPersonDialog = () => {
    this.setState({
      showEditPersonDialog: false,
      editPersonId: null,
    });
  };

  handlePersonSave = () => {
    this.setState({
      showEditPersonDialog: false,
      editPersonId: null,
      refreshPeopleData: true,
    });
  };

  clearRefreshPeopleData = () => {
    this.setState({
      refreshPeopleData: false,
    });
  };

  handleCloseAddPersonDialog = () => {
    this.setState({
      showAddPersonDialog: false,
    });
  };

  handlePersonAdd = (personId) => {
    const organization = Object.assign({}, this.state.organization, {
      person_ids: this.state.organization.person_ids
        ? this.state.organization.person_ids.concat(personId)
        : [personId],
    });

    this.setState({
      organization,
      showAddPersonDialog: false,
      refreshPeopleData: true,
    });
  };

  removePerson = (personId) => {
    const { organization } = this.state;
    organization.person_ids = (organization.person_ids || []).filter(
      (id) => id !== personId
    );

    this.organizationStore
      .removePerson(personId)
      .then(() =>
        this.setState({
          organization,
          refreshPeopleData: true,
        })
      )
      .catch((err) => {
        console.log("err", err);
        this.setState({
          saveSuccess: false,
          isSaving: false,
          showErrorDialog: true,
          errorMessage: err.toString(),
        });
      });
  };

  render() {
    const { organization, client, initialOrganization } = this.state;

    const displayEditedFields = (fields) => {
      return (
        <If condition={this.checkIfFieldsHaveBeenEdited(fields)}>
          <p className="edited-field">EDITED</p>
        </If>
      );
    };

    const actions = [
      <FlatButton
        label="Close"
        primary={true}
        onClick={this.handleErrorDialogClose}
      />,
    ];

    if (!organization || !client || this.state.isLoading) {
      return <Loading />;
    }

    const {
      locations = [],
      description = "",
      short_description: shortDescription = "",
      homepage_url: website = "",
      founded_on: foundedOn = 0,
      created_at: createdAt = 0,
      total_funding_usd: totalFundingUSD = 0,
      funding_stage: fundingStage = "",
      investment_type: investmentType = "",
      investment_stage: investmentStage = "",
      num_employees_min: minEmployees = 0,
      person_ids: personIds = [],
      investor_ids: investorIds = [],
      primary_contact_id: primaryContact = "",
      domain = "",
      linkedin = "",
      urls = [],
      domains = [],
      also_known_as: alsoKnownAs = [],
      email_address: emailAddress = "",
      phone_numbers: phoneNumbers = [],
    } = organization;

    return (
      <div className="edit-view">
        <EditOrganizationHeader
          isSaving={this.state.isSaving}
          saveSuccess={this.state.saveSuccess}
          cancel={this.cancelEdit}
          confirm={this.saveEdit}
          disable={
            this.checkIfErrors(this.state.errors) ||
            this.state.modifiedFields.length === 0
          }
          title={`Edit: ${this.state.organization.name}`}
          source="edit"
        />
        <div className="edit-body">
          <Card>
            <CardBody>
              <div className="edit-section">
                <EditBasicInfo
                  source="incomplete"
                  data={organization}
                  updateFields={this.updateOrganizationFields}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  SHORT DESCRIPTION
                  {displayEditedFields(["short_description"])}
                </div>
                <TextField
                  fullWidth={true}
                  name="edit-short-description"
                  className="edit-short-description"
                  value={shortDescription}
                  onChange={(event) =>
                    this.updateOrganizationField(
                      "short_description",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  DESCRIPTION*
                  {displayEditedFields(["description"])}
                </div>
                <EditOrganizationDescription
                  description={description}
                  updateOrganizationFields={this.updateOrganizationFields}
                  source="edit"
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  WEBSITE*
                  {displayEditedFields(["website"])}
                </div>
                <EditOrganizationWebsite
                  website={website}
                  updateOrganizationFields={this.updateOrganizationFields}
                  source="edit"
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  LINKEDIN URL
                  {displayEditedFields(["linkedin"])}
                </div>
                <TextField
                  fullWidth={true}
                  className="edit-linkedin"
                  name="edit-linkedin"
                  value={linkedin}
                  errorText={this.state.errors.linkedin}
                  onChange={(event) => {
                    this.validateOrganizationField(
                      "linkedin",
                      event.target.value,
                      "URL"
                    );
                    this.updateOrganizationField(
                      "linkedin",
                      event.target.value
                    );
                  }}
                />
                <SyncWithLinkedIn
                  organization={initialOrganization}
                  linkedin={linkedin}
                  onSyncComplete={() => window.location.reload()}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  LOCATIONS*
                  {displayEditedFields(["locations"])}
                </div>
                <EditOrganizationLocations
                  locations={locations}
                  updateOrganizationFields={this.updateOrganizationFields}
                  source="edit"
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  FOUNDED ON*
                  {displayEditedFields(["founded_on"])}
                </div>
                <EditOrganizationFoundedOn
                  founded={foundedOn}
                  createdAt={createdAt}
                  updateOrganizationFields={this.updateOrganizationFields}
                  source="edit"
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  FUNDING
                  {displayEditedFields(["total_funding_usd"])}
                </div>
                <EditOrganizationTotalFunding
                  totalFunding={totalFundingUSD}
                  updateOrganizationFields={this.updateOrganizationFields}
                  source="edit"
                />
              </div>

              <div className="edit-section">
                <div className="edit-section-header">
                  FUNDING STAGE
                  {displayEditedFields(["funding_stage"])}
                </div>
                <EditFundingStage
                  fundingStage={fundingStage}
                  onChangeFundingStage={this.handleFundingStageChange}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  INVESTMENT TYPE
                  {displayEditedFields(["investment_type"])}
                </div>
                <EditInvestmentType
                  investmentType={investmentType}
                  onChangeInvestmentType={this.handleInvestmentTypeChange}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  INVESTMENT STAGE
                  {displayEditedFields(["investment_stage"])}
                </div>
                <EditInvestmentStage
                  investmentStage={investmentStage}
                  onChangeInvestmentStage={this.handleInvestmentStageChange}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  PEOPLE
                  {displayEditedFields([
                    "num_employees_min",
                    "num_employees_max",
                    "primary_contact_id",
                    "person_ids",
                  ])}
                </div>
                <EditOrganizationPeopleEmployeesRange
                  minEmployees={minEmployees}
                  updateOrganizationFields={this.updateOrganizationFields}
                  source="edit"
                />
                <div className="edit-team">
                  <If condition={personIds.length > 0}>
                    <People
                      person_ids={personIds}
                      onSetAsPrimaryContact={(personId) =>
                        this.updateOrganizationField(
                          "primary_contact_id",
                          personId
                        )
                      }
                      editMode={true}
                      refreshData={this.state.refreshPeopleData}
                      onCompleteRefreshData={this.clearRefreshPeopleData}
                      onEditPerson={this.handleEditPerson}
                      organization={organization}
                      primaryContactId={primaryContact}
                      removePerson={this.removePerson}
                    />
                  </If>
                  <If condition={personIds.length === 0}>
                    <p>No people have been added yet to this company</p>
                  </If>
                  <FlatButton
                    label="Add person"
                    onClick={() => this.setState({ showAddPersonDialog: true })}
                    icon={<AddCircleOutline color={"#5DAB49"} />}
                  />
                </div>
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  INVESTORS
                  {displayEditedFields(["investor_ids"])}
                </div>
                <div className="edit-team">
                  <EditInvestors
                    organization={organization}
                    addInvestor={(investor) => {
                      if (!organization.investor_ids) {
                        organization.investor_ids = [];
                      }
                      organization.investor_ids.push(investor.id);
                      this.updateOrganizationField(
                        "investor_ids",
                        organization.investor_ids
                      );
                    }}
                    removeInvestor={(investor) => {
                      this.updateOrganizationField(
                        "investor_ids",
                        organization.investor_ids.filter(
                          (id) => id !== investor.id
                        )
                      );
                    }}
                  />
                </div>
              </div>

              <div className="edit-section">
                <div className="edit-section-header">
                  ADDITIONAL INFO
                  {displayEditedFields([
                    "is_closed",
                    "insufficient_info",
                    "requires_more_info",
                    "is_complete",
                  ])}
                </div>
                <div className="edit-additional-info">
                  <Checkbox
                    label="IS CLOSED"
                    checked={organization.is_closed}
                    onClick={() =>
                      this.updateOrganizationField(
                        "is_closed",
                        !organization.is_closed
                      )
                    }
                  />
                  <Checkbox
                    label="INSUFFICIENT INFO"
                    disabled={organization.is_complete}
                    checked={organization.insufficient_info}
                    onClick={() =>
                      this.updateOrganizationField(
                        "insufficient_info",
                        !organization.insufficient_info
                      )
                    }
                  />
                  <Checkbox
                    label="REQUIRES MORE INFO"
                    disabled={organization.is_complete}
                    checked={organization.requires_more_info}
                    onClick={() =>
                      this.updateOrganizationField(
                        "requires_more_info",
                        !organization.requires_more_info
                      )
                    }
                  />
                  <Checkbox
                    label="IS COMPLETE"
                    disabled={
                      organization.requires_more_info ||
                      organization.insufficient_info
                    }
                    checked={organization.is_complete}
                    onClick={() =>
                      this.updateOrganizationField(
                        "is_complete",
                        !organization.is_complete
                      )
                    }
                  />
                </div>
              </div>

              <div className="edit-section">
                <div className="edit-section-header">
                  DOMAIN
                  {displayEditedFields(["domain"])}
                </div>
                <TextField
                  fullWidth={true}
                  className="edit-domain"
                  name="edit-domain"
                  value={domain}
                  errorText={this.state.errors.domain}
                  onChange={(event) => {
                    this.updateOrganizationField("domain", event.target.value);
                  }}
                />
              </div>

              <div className="edit-section">
                <div className="edit-section-header">
                  URLs
                  {displayEditedFields(["urls"])}
                </div>
                <EditUrls
                  urls={urls}
                  handleUrlsChange={this.handleUrlsChange}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  DOMAINS
                  {displayEditedFields(["domains"])}
                </div>
                <EditDomains
                  domains={domains}
                  handleDomainsChange={this.handleDomainsChange}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  ALSO KNOWN AS
                  {displayEditedFields(["also_known_as"])}
                </div>
                <EditAlsoKnownAs
                  alsoKnownAs={alsoKnownAs}
                  handleAlsoKnownAsChange={this.handleAlsoKnownAsChange}
                />
              </div>

              <div className="edit-section">
                <div className="edit-section-header">
                  EMAIL
                  {displayEditedFields(["email_address"])}
                </div>
                <TextField
                  fullWidth={true}
                  className={`edit-email-address`}
                  name={`edit-email-address`}
                  value={emailAddress}
                  errorText={this.state.errors["email_address"]}
                  onChange={(event) => {
                    this.validateOrganizationField(
                      "email_address",
                      event.target.value,
                      "email"
                    );
                    this.updateOrganizationField(
                      "email_address",
                      event.target.value
                    );
                  }}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  PHONE NUMBERS
                  {displayEditedFields(["phone_numbers"])}
                </div>
                <EditPhoneNumbers
                  phoneNumbers={phoneNumbers}
                  handlePhoneNumbersChange={this.handlePhoneNumbersChange}
                />
              </div>

              <div className="edit-section">
                {["crunchbase", "twitter", "facebook", "angellist"].map(
                  (key) => (
                    <div className="edit-section" key={key}>
                      <div className="edit-section-header">
                        {key.toUpperCase()}
                        {displayEditedFields([key])}
                      </div>
                      <TextField
                        fullWidth={true}
                        className={`edit-${key}`}
                        name={`edit-${key}`}
                        value={organization[key] || ""}
                        errorText={this.state.errors[key]}
                        onChange={(event) => {
                          this.validateOrganizationField(
                            key,
                            event.target.value,
                            "URL"
                          );
                          this.updateOrganizationField(key, event.target.value);
                        }}
                      />
                    </div>
                  )
                )}
              </div>
              <DeleteOrganization
                onDeleteOrganization={this.handleDeleteOrganization}
              />
            </CardBody>
          </Card>
        </div>
        <EditPersonDialog
          onCloseDialog={this.handleCloseEditPersonDialog}
          onSavePerson={this.handlePersonSave}
          show={this.state.showEditPersonDialog}
          personId={this.state.editPersonId}
          organizationId={organization.id}
        />
        <AddPersonDialog
          onCloseDialog={this.handleCloseAddPersonDialog}
          onAddPerson={this.handlePersonAdd}
          show={this.state.showAddPersonDialog}
          organizationId={organization.id}
        />
        <DialogWrapper
          title="Error while saving edited organization"
          actions={actions}
          modal={false}
          open={this.state.showErrorDialog}
          onRequestClose={this.handleErrorDialogClose}
        >
          {this.state.errorMessage}
        </DialogWrapper>
      </div>
    );
  }
}

export default withRouter(EditOrganization);
