import React from "react";

import OrganizationsSearch from "./organization/OrganizationsSearch";
import Loading from "./loading/Loading";
import auth from "../storage/Auth";

export default class OrganizationsRoute extends React.Component {
  state = {
    client: null,
    customisedFeedParams: null,
    loading: false,
  };

  componentWillMount() {
    if (window.mixpanel) {
      mixpanel.track("Visited organizations list", {
        filters: this.getInitSearchParams(),
      });
    }
    this.setState({
      loading: true,
    });
    auth
      .getAuthData()
      .then(
        ({
          client,
          profile: {
            [auth.namespace]: { filters },
          },
        }) => {
          this.setState({
            customizedFeedParams: filters,
            client,
            loading: false,
          });
        }
      )
      .catch((err) => {
        console.log("Unable to retrieve customisedFeedParams", err);
        this.setState({
          loading: false,
        });
      });
  }

  getInitSearchParams = () => {
    const {
      location: { query },
    } = this.props;
    const { customisedFeedParams } = this.state;
    return Object.keys(query).length > 0
      ? query
      : customisedFeedParams || {
          based_in_continent: "Europe",
          classification_not: "never,missed_opportunity",
          requires_more_info: false,
          has_predictions: true,
        };
  };

  render() {
    const { location, context, route } = this.props;
    const { client, loading } = this.state;
    if (loading) {
      return <Loading />;
    }
    const initSearchParams = this.getInitSearchParams();
    return (
      <OrganizationsSearch
        initSearchParams={initSearchParams}
        client={client}
        location={location}
        context={context}
        route={route}
      />
    );
  }
}
