import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import Bio from "./Bio";
import EducationExperience from "./EducationExperience";

import _ from "underscore";

class KeyInformation extends React.Component {
  static propTypes = {
    info: PropTypes.object.isRequired,
  };

  render() {
    const { info } = this.props;
    return (
      <div className="key-information">
        <div className="section-title">Key Information</div>
        <div className="key-information-container">
          {Object.entries(info).map(([section, content = []], index) => (
            <Choose>
              <When condition={section === "bio"}>
                <Bio bio={content[0].bio} key={index} />
              </When>
              <When condition={section === "experience"}>
                <EducationExperience
                  content={content}
                  type="experience"
                  key={index}
                />
              </When>
              <When condition={section === "education"}>
                <EducationExperience
                  content={content}
                  type="education"
                  key={index}
                />
              </When>
            </Choose>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(KeyInformation);
