import React from "react";
import OrganizationsSearch from "./organization/OrganizationsSearch";
import Loading from "./loading/Loading";
import auth from "../storage/Auth";

export default class IncompleteRoute extends React.Component {
  state = {
    client: null,
  };

  componentDidMount() {
    if (window.mixpanel) {
      mixpanel.track("Visited incomplete list");
    }
    auth
      .getAuthData()
      .then(({ client }) => {
        this.setState({ client });
      })
      .catch((err) => {
        console.log("Unable to retrieve client", err);
      });
  }

  render() {
    const { client } = this.state;
    if (!client) {
      return <Loading />;
    }
    return (
      <OrganizationsSearch
        initSearchParams={{
          classification_not: "never,missed_opportunity",
          is_closed: false,
          has_missing_data: true,
          freshness_requested_gt: 0,
          freshness_category: "organization",
          freshness_source: "CORESIGNAL_DB",
          order_by:
            "freshness_ranking_extractor.organization.CORESIGNAL_DB.rank",
          not_in_user_inbox:
            "roberto@inreachventures.com,amanda@inreachventures.com,ben@inreachventures.com",
          state: "unseen",
          user_state_not: "never",
        }}
        client={client}
        location={this.props.location}
        context={this.props.context}
        route={this.props.route}
        source="incomplete"
      />
    );
  }
}
