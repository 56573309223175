import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-v4";
import moment from "moment";

import Avatar from "material-ui/Avatar";
import MenuItem from "material-ui/MenuItem";
import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import SelectField from "material-ui/SelectField";
import IconButton from "material-ui/IconButton/IconButton";

import NavigationClose from "material-ui/svg-icons/navigation/close";
import NavigationArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import PhoneForwarded from "material-ui/svg-icons/notification/phone-forwarded";
import ActionAssignmentLate from "material-ui/svg-icons/action/assignment-late";
import ActionAssignmentTurnedIn from "material-ui/svg-icons/action/assignment-turned-in";
import Build from "material-ui/svg-icons/action/build";

import State from "../State";
import StateSummary from "../StateSummary";
import TemporaryUserStore from "../../storage/TemporaryUserStore";
import ShareLinkDialog from "./ShareLinkDialog";
import IsNotSpam from "../IsNotSpam";
import AssignedTo from "./AssignedTo";
import Organizer from "./Organizer";
import DoNotContact from "../DoNotContact";
import OrganizationStage from "../components/OrganizationStage";

import Organizations from "../../lib/Organizations";
import imageUrl from "../../lib/imageUrl";
import StageChange from "../assign/StageChange";
import EvaluationBar from "../assign/EvaluationBar";
import PassCustomizeCampaign from "../assign/PassCustomizeCampaign";
import TopBarActions from "./TopBarActions";
import inboxHelper from "../../lib/InboxHelper";
import StructuredFeedback from "./structured_feedback/StructuredFeedback";
import WorkflowSync from "./workflow_sync/WorkflowSync";

function hasForm(organization) {
  const { source_refs: sourceRefs = {} } = organization;
  return (
    sourceRefs.hasOwnProperty("FUNDING") ||
    sourceRefs.hasOwnProperty("V2FUNDING") ||
    sourceRefs.hasOwnProperty("V3FUNDING")
  );
}

class OrganizationHeader extends React.Component {
  static propTypes = {
    userRoles: PropTypes.array.isRequired,
    toggleManualHelp: PropTypes.func.isRequired,
    handleTogglePriority: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired,
    organizationDecision: PropTypes.object.isRequired,
    showAsPublicProfile: PropTypes.bool,
    handleOrganizationChange: PropTypes.func.isRequired,
    returnUrl: PropTypes.string.isRequired,
    createQualificationChecklist: PropTypes.func.isRequired,
    structuredReasons: PropTypes.object.isRequired,
    handleSendFeedbackMenuItemClick: PropTypes.func.isRequired,
    filterSource: PropTypes.object.isRequired,
    fromCreatedAt: PropTypes.number,
  };

  state = {
    shareLink: null,
    openShareDialog: false,
    openMergeDialog: false,
    isEditingReconnectCampaign: false,
    isEditingPassCampaign: false,
    isChangingStage: false,
    showStageReason: false,
    structuredFeedbackType: false,
    openSync: false,
  };

  handleShareDialogOpen = () => {
    this.setState({
      openShareDialog: true,
    });
  };

  handleShareDialogClose = () => {
    this.setState({ openShareDialog: false });
  };

  handleMergeDialogClose = () => {
    this.setState({ openMergeDialog: false });
  };

  openStructuredFeedbackType = (type) => {
    this.setState({ structuredFeedbackType: type });
  };

  closeStructuredFeedback = () => {
    this.setState({ structuredFeedbackType: false });
  };

  openWorkflowSync = () => {
    this.setState({ openSync: true });
  };

  closeWorkflowSync = () => {
    this.setState({ openSync: false });
  };

  share = () => {
    this.createShareLink().then(this.handleShareDialogOpen);
  };

  createShareLink = (includeScorecard) => {
    const temporaryUserStore = new TemporaryUserStore(
      this.props.organization.id
    );
    return temporaryUserStore
      .createShareLink(includeScorecard)
      .then((shareLink) => {
        this.setState({ shareLink });
        return shareLink;
      });
  };

  toggleStageChange = (isChangingStage) => {
    this.setState({ isChangingStage: isChangingStage });
  };

  toggleEditPassCampaign = (isEditingPassCampaign) => {
    this.setState({ isEditingPassCampaign: isEditingPassCampaign });
  };

  submitPass = (assignCampaign) => {
    const { onStateChange } = this.props;
    return onStateChange(assignCampaign).then(() =>
      this.toggleEditPassCampaign(false)
    );
  };

  changeStageAndClose = (stage) => {
    return this.props
      .onStageChange(stage)
      .then(() => this.toggleStageChange(false));
  };

  goToPage = (page) => {
    const { router, location, organization: { id } = {} } = this.props;
    router.push({
      ...location,
      ...{
        pathname: `/organizations/${id}/${page}`,
      },
    });
  };

  submitStateAndTriggerFeedback = (assignCampaign) => {
    const { onStateChange } = this.props;
    const {
      decision: { state },
    } = assignCampaign;
    return onStateChange(assignCampaign).then(() =>
      this.openStructuredFeedbackType(state)
    );
  };

  render() {
    const {
      organization,
      client,
      organizationDecision,
      showAsPublicProfile,
      userHasFullAccess,
      deleteSignals,
      userRoles,
      toggleManualHelp,
      handleTogglePriority,
      onAssignedToChange,
      onOrganizerChange,
      onIsNotSpamChange,
      returnUrl,
      recentMessages,
      showErrorDialog,
      handleFormEvaluationChange,
      createQualificationChecklist,
      structuredReasons,
      handleSendFeedbackMenuItemClick,
      currentUser,
      addToInbox,
      removeFromInbox,
      showInboxModal,
      isUpdatingInbox,
      fromCreatedAt,
    } = this.props;

    const {
      isChangingStage,
      isEditingPassCampaign,
      structuredFeedbackType,
      showStageReason = false,
      openSync = false,
    } = this.state;

    const decision =
      Organizations.getOrganizationState(organization, client) || {};

    const { form: inbound = false, priority } = decision;

    const inboxData = {
      currentUserInbox: inboxHelper.getInbox(organization, client, currentUser),
      userInboxes: inboxHelper
        .getUserInboxes(organization, client, currentUser)
        .filter(({ inbox }) => !!inbox)
        .map(({ assigned_to }) => assigned_to),
      activeUserInbox: inboxHelper.getInbox(organization, client, ""),
      form: {
        hasForm: hasForm(organization),
        inbound,
      },
    };

    return (
      <React.Fragment>
        <Toolbar className="top-bar-tool-bar">
          <div className="top-bar">
            <ToolbarGroup
              className="top-bar-first-row"
              style={priority === 1 ? { backgroundColor: "#ffdf87" } : {}}
            >
              <div className="organization-title">
                <Link to={returnUrl}>
                  <IconButton
                    tooltip="Back to organizations list"
                    className="organization-back"
                  >
                    <NavigationArrowBack />
                  </IconButton>
                </Link>
                <Avatar
                  className="organization-avatar"
                  src={imageUrl({ url: organization.image_url })}
                />
                <ToolbarTitle
                  className="organization-name"
                  text={organization.name}
                />
                <Choose>
                  <When condition={fromCreatedAt}>
                    <span>
                      (As of {moment(fromCreatedAt, "x").format("Do MMMM YYYY")}
                      )
                    </span>
                  </When>
                </Choose>
              </div>
              <TopBarActions
                organization={organization}
                email={currentUser}
                inboxData={inboxData}
                addToInbox={addToInbox}
                removeFromInbox={removeFromInbox}
                showInboxModal={showInboxModal}
                isUpdatingInbox={isUpdatingInbox}
                userRoles={userRoles}
                userHasFullAccess={userHasFullAccess}
                decision={decision}
                handleSendFeedbackMenuItemClick={
                  handleSendFeedbackMenuItemClick
                }
                share={this.share}
                toggleStageChange={this.toggleStageChange}
                toggleEditPassCampaign={this.toggleEditPassCampaign}
                goToPage={this.goToPage}
                deleteSignals={deleteSignals}
                toggleManualHelp={toggleManualHelp}
                handleTogglePriority={handleTogglePriority}
                createQualificationChecklist={createQualificationChecklist}
                openStructuredFeedback={this.openStructuredFeedbackType}
                openWorkflowSync={this.openWorkflowSync}
                source="profile"
              />
            </ToolbarGroup>
            <ToolbarGroup className="top-bar-second-row">
              <div className="top-bar-selectors">
                <If
                  condition={
                    (organizationDecision.assigned_to ||
                      organizationDecision.state === "contact") &&
                    userHasFullAccess
                  }
                >
                  <AssignedTo
                    assignedTo={organizationDecision.assigned_to}
                    handleAssignedToChange={onAssignedToChange}
                  />
                </If>

                <If
                  condition={
                    organizationDecision.assigned_to && userHasFullAccess
                  }
                >
                  <Organizer
                    organizer={organizationDecision.organizer}
                    onOrganizerChange={onOrganizerChange}
                  />
                </If>
                <div className="spacer top-bar-spacer" />
                <If condition={organizationDecision.stage && userHasFullAccess}>
                  <OrganizationStage
                    decision={organizationDecision}
                    showStage={false}
                    compressed={false}
                  />
                </If>
              </div>
              <div className="organization-status">
                <div style={{ marginTop: "4px" }}>
                  <State
                    showIcons={true}
                    state={(organizationDecision || {}).state || "unseen"}
                    handleError={showErrorDialog}
                    onFormEvaluationChange={handleFormEvaluationChange}
                    {...this.props}
                    onStateChange={this.submitStateAndTriggerFeedback}
                  />
                </div>
                <If condition={organizationDecision.fallback_to_call}>
                  <IconButton tooltip="Fallback to Call">
                    <PhoneForwarded color="#337ab7" />
                  </IconButton>
                </If>
                <If condition={organizationDecision.requires_manual_help}>
                  <IconButton tooltip="Requires Manual Help">
                    <Build color="#337ab7" />
                  </IconButton>
                </If>
                <If condition={organizationDecision.state}>
                  <StateSummary
                    state={organizationDecision.state}
                    organization={organization}
                  />
                </If>
              </div>
              <If condition={currentUser && userHasFullAccess}>
                <StructuredFeedback
                  organization={organization}
                  userId={currentUser}
                  structuredFeedbackType={structuredFeedbackType}
                  handleOpen={() => this.openStructuredFeedbackType(true)}
                  handleClose={this.closeStructuredFeedback}
                  compressed={true}
                  handleError={showErrorDialog}
                />
              </If>
              <If condition={currentUser && userHasFullAccess}>
                <WorkflowSync
                  handleClose={this.closeWorkflowSync}
                  handleOpen={this.openWorkflowSync}
                  open={openSync}
                  organization={organization}
                  userId={currentUser}
                  handleError={showErrorDialog}
                />
              </If>
            </ToolbarGroup>
            <ToolbarGroup className="top-bar-third-row">
              <If condition={organization.is_closed}>
                <IconButton>
                  <NavigationClose />
                </IconButton>
                <ToolbarTitle text="Closed" />
              </If>
              <If condition={!!organization.do_not_contact}>
                <DoNotContact />
              </If>
              <If
                condition={
                  organizationDecision.state !== "contact" &&
                  organization.combined_classification === "never" &&
                  !(
                    !!organizationDecision.state && !organization.is_not_spam
                  ) &&
                  userHasFullAccess
                }
              >
                <IsNotSpam
                  isNotSpam={!!organization.is_not_spam}
                  organization_id={organization.id}
                  handleIsNotSpamChange={onIsNotSpamChange}
                />
              </If>
              <div className="spacer" />
              <If condition={this.props.formStatus}>
                <If condition={this.props.formSubmittedDate}>
                  <IconButton tooltip={this.props.formStatus}>
                    {this.props.formStatus === "Complete" ? (
                      <ActionAssignmentTurnedIn />
                    ) : (
                      <ActionAssignmentLate />
                    )}
                  </IconButton>
                  <ToolbarTitle
                    text={`${moment(
                      this.props.formSubmittedDate
                    ).fromNow()} — ${inbound ? "Outbound" : "Inbound"}`}
                    style={{ fontSize: "14px", lineHeight: "20px" }}
                  />
                </If>
              </If>
              <If
                condition={
                  this.props.formEntries &&
                  this.props.selectedFormEntry &&
                  this.props.formEntries.length > 1
                }
              >
                <div>
                  <SelectField
                    floatingLabelText="Form entries"
                    selectedMenuItemStyle={{ color: "#5DAB49" }}
                    value={this.props.selectedFormEntry}
                    onChange={(event, key, value) =>
                      this.props.handleFormEntryChange(value)
                    }
                  >
                    {this.props.formEntries.map((formEntry) => (
                      <MenuItem
                        key={formEntry}
                        value={formEntry}
                        primaryText={formEntry}
                      />
                    ))}
                  </SelectField>
                </div>
              </If>
            </ToolbarGroup>
            <If condition={userHasFullAccess}>
              <ToolbarGroup className="top-bar-fourth-row">
                <EvaluationBar
                  openStructuredFeedback={this.openStructuredFeedbackType}
                  {...this.props}
                />
              </ToolbarGroup>
            </If>
          </div>
        </Toolbar>
        <ShareLinkDialog
          link={this.state.shareLink}
          open={this.state.openShareDialog}
          createShareLink={this.createShareLink}
          handleOpen={this.handleShareDialogOpen}
          handleClose={this.handleShareDialogClose}
        />
        <If condition={isChangingStage}>
          <StageChange
            isChangingStage={isChangingStage}
            onDone={this.toggleStageChange}
            stage={organizationDecision.stage}
            reduced={true}
            onStageChange={this.changeStageAndClose}
            organizationDecision={organizationDecision}
          />
        </If>
        <If condition={isEditingPassCampaign}>
          <PassCustomizeCampaign
            organization={organization}
            client={client}
            organizationDecision={organizationDecision}
            open={isEditingPassCampaign}
            handleClose={this.toggleEditPassCampaign}
            handleSubmit={this.submitPass}
            recentMessages={recentMessages}
            structuredReasons={structuredReasons}
          />
        </If>
      </React.Fragment>
    );
  }
}

export default withRouter(OrganizationHeader);
