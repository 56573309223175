import React from "react";
import RaisedButtonWithIcon from "./organization/RaisedButtonWithIcon";

import linkedinBuilding from "../../images/linkedin_building.png";
import linkedinIcon from "../../images/linkedin_icon.png";
import linkedinPerson from "../../images/linkedin_person.png";

class LinkedIn extends React.Component {
  searchUrl() {
    let resource, query;

    if (this.props.name && this.props.organizationName) {
      resource = "people";
      query = `${this.props.name} ${this.props.organizationName}`;
    } else if (this.props.name) {
      resource = "people";
      query = this.props.name;
    } else if (this.props.organizationName) {
      resource = "companies";
      query = this.props.organizationName;
    }

    window.open(
      `https://www.linkedin.com/search/results/${resource}/?keywords=${encodeURIComponent(
        query
      )}`
    );
  }

  render() {
    if (this.props.organizationName) {
      if (this.props.name) {
        return (
          <RaisedButtonWithIcon
            label="Company Profile"
            iconURL={linkedinBuilding}
            onClick={() => this.searchUrl()}
          />
        );
      } else {
        return (
          <RaisedButtonWithIcon
            label="LinkedIn"
            iconURL={linkedinIcon}
            onClick={() => this.searchUrl()}
          />
        );
      }
    } else if (!this.props.organizationName) {
      return (
        <RaisedButtonWithIcon
          label="Profile"
          iconURL={linkedinPerson}
          onClick={() => this.searchUrl()}
        />
      );
    }
    return (
      <RaisedButtonWithIcon
        label={
          this.props.organizationName && this.props.name
            ? "Person in Company"
            : "Person"
        }
        iconURL={
          this.props.organizationName && this.props.name
            ? linkedinBuilding
            : linkedinPerson
        }
        onClick={() => this.searchUrl()}
      />
    );
  }
}

export default LinkedIn;
