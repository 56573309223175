export default [
  {
    key: "interesting_people",
    name: "Interesting People",
    section: "people",
    contact_name: "People",
    feedback_type: "people",
  },
  {
    key: "not_interesting_people",
    name: "Not Interesting People",
    section: "people",
    not_now_name: "People",
    feedback_type: "people",
  },
  {
    key: "relevant_founder_background",
    name: "Relevant Founder Background",
    section: "people",
    feedback_type: "founder_background",
  },
  {
    key: "interesting_space",
    name: "Interesting Space",
    section: "product",
    sub_section: "space",
    contact_name: "Space",
    feedback_type: "space",
  },
  {
    key: "not_indicative_space",
    name: "Not Indicative Space",
    section: "product",
    sub_section: "space",
    feedback_type: "space",
  },
  {
    key: "not_interesting_space",
    name: "Not Interesting Space",
    section: "product",
    sub_section: "space",
    not_now_name: "Space",
    feedback_type: "space",
  },
  {
    key: "not_relevant_space",
    name: "Not Relevant Space",
    section: "product",
    sub_section: "space",
    never_name: "Space",
    feedback_type: "space",
  },
  {
    key: "not_relevant_location",
    name: "Not Relevant Location",
    section: "market",
    never_name: "Location",
    feedback_type: "location",
  },
  {
    key: "not_localized",
    name: "Not Localized",
    section: "market",
    never_name: "Not Localized",
    feedback_type: "website_localization",
  },
  {
    key: "investment_type_saas",
    name: "SaaS",
    section: "product",
    sub_section: "investment_type",
    feedback_type: "investment_type",
  },
  {
    key: "investment_type_marketplace",
    name: "Marketplace",
    section: "product",
    sub_section: "investment_type",
    feedback_type: "investment_type",
  },
  {
    key: "investment_type_consumer_internet",
    name: "Consumer Internet",
    section: "product",
    sub_section: "investment_type",
    feedback_type: "investment_type",
  },
  {
    key: "interesting_traffic",
    name: "Interesting Traffic",
    section: "product",
    sub_section: "traffic",
    contact_name: "Traffic",
    feedback_type: "traffic",
  },
  {
    key: "not_interesting_traffic",
    name: "Not Interesting Traffic",
    section: "product",
    sub_section: "traffic",
    not_now_name: "Traffic",
    feedback_type: "traffic",
  },
  {
    key: "too_early",
    name: "Too Early",
    section: "funding",
    not_now_name: "Too Early",
    missed_opportunity_name: "Too Early",
    feedback_type: "timing",
  },
  {
    key: "too_late",
    name: "Too Late",
    section: "funding",
    not_now_name: "Too Late",
    never_name: "Too Late",
    missed_opportunity_name: "Too Late",
    feedback_type: "timing",
  },
  {
    key: "exclude_from_ml_training",
    name: "Exclude from ML",
    section: "wrong_data",
    sub_section: "company",
    feedback_type: "wrong_data",
  },
  {
    key: "wrong_description",
    name: "Wrong Description",
    section: "wrong_data",
    sub_section: "company",
    feedback_type: "wrong_data",
  },
  {
    key: "wrong_location",
    name: "Wrong Location",
    section: "wrong_data",
    sub_section: "company",
    feedback_type: "wrong_data",
  },
  {
    key: "wrong_age",
    name: "Wrong Age",
    section: "wrong_data",
    sub_section: "company",
    feedback_type: "wrong_data",
  },
  {
    key: "wrong_funding",
    name: "Wrong Funding",
    section: "wrong_data",
    sub_section: "company",
    feedback_type: "wrong_data",
  },
  {
    key: "missing_founder",
    name: "Missing Founder",
    section: "wrong_data",
    sub_section: "people",
    feedback_type: "wrong_data",
  },
  {
    key: "wrong_founder",
    name: "Wrong Founder",
    section: "wrong_data",
    sub_section: "people",
    feedback_type: "wrong_data",
  },
  {
    key: "undetected_insight",
    name: "Undetected Insight",
    section: "wrong_explainable_feature",
    feedback_type: "wrong_explainable_feature",
  },
  {
    key: "wrong_traffic_insight",
    name: "Wrong Traffic",
    section: "wrong_explainable_feature",
    feedback_type: "wrong_explainable_feature",
  },
];
