import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import _ from "underscore";

import AttachMoney from "material-ui/svg-icons/editor/attach-money";

import Loading from "../../../loading/Loading";
import NewTag from "../NewTag";
import Tooltip from "../../../Tooltip";

import { capitalizeString, getRoundedUpValue } from "../../../../lib/helpers";
import { getDataWrapperClassNames } from "../../../../lib/helpers";

class CompressedFundingData extends React.Component {
  static propTypes = {
    fundingAmount: PropTypes.object.isRequired,
    fundingRounds: PropTypes.object.isRequired,
    fundingRoundsLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool,
  };

  state = {
    showTooltip: false,
  };

  hasError = () => {
    const {
      fundingAmount: { amount, stage },
    } = this.props;
    return (
      (amount && amount >= 6000000) ||
      (stage && stage === "acquired") ||
      stage === "ico" ||
      stage === "ipo"
    );
  };

  getError = () => {
    const {
      fundingAmount: { amount, stage },
    } = this.props;
    if (!this.hasError()) {
      return null;
    } else {
      return amount >= 6000000 ? "amount" : "stage";
    }
  };

  getTooltipText = () => {
    const error = this.getError();
    const text = ["Funding Amount & Last Funding Round"];
    if (error) {
      text.push(
        error === "amount"
          ? "The company has received more than $5m in funding"
          : "The company has exited (acquired or IPO)"
      );
    }
    return text.join("<br/>");
  };

  getFundingDataClasses = () => {
    const {
      fundingAmount: { amount, highlighted = false },
    } = this.props;
    const classNames = [];
    if (this.hasError()) {
      classNames.push("red-flag");
    } else if (highlighted) {
      classNames.push("highlighted-text");
    } else if (!amount) {
      classNames.push("de-emphasised-text");
    }
    return classNames.join(" ");
  };

  getFundingAmountString = () => {
    const {
      fundingAmount: { amount, stage, hasFundingRounds = false },
    } = this.props;
    return `${
      hasFundingRounds && (!amount || amount === 0)
        ? `Undisclosed amount`
        : !amount
        ? "No data"
        : `$${getRoundedUpValue(amount)}`
    }${
      stage === "acquired"
        ? " - Acquired"
        : stage === "ico"
        ? " - ICO"
        : stage === "ipo"
        ? " - IPO"
        : ""
    }`;
  };

  getLastFundingRoundString = () =>
    `${this.getLatestFundingRoundAmount()}: ${this.getLatestFundingRoundType()} - ${this.getLatestFundingRoundDate()}`;

  getLatestFundingRoundAmount = () => {
    const {
      fundingRounds: { lastFundingRound = {}, fundingRounds = [] } = {},
    } = this.props;
    const { money_raised_usd = null } = !_.isEmpty(lastFundingRound)
      ? lastFundingRound
      : fundingRounds[0] || {};
    return `${
      money_raised_usd === null
        ? "Undisclosed amount"
        : `$${getRoundedUpValue(money_raised_usd)}`
    }`;
  };

  getLatestFundingRoundDate = () => {
    const {
      fundingRounds: { lastFundingRound = {}, fundingRounds = [] } = {},
    } = this.props;
    const { announced_on } = !_.isEmpty(lastFundingRound)
      ? lastFundingRound
      : fundingRounds[0] || {};
    const announcedDate = new Date(announced_on);
    return `${announcedDate.toLocaleString("default", {
      month: "short",
    })}, ${announcedDate.getFullYear()}`;
  };

  getLatestFundingRoundType = () => {
    const {
      fundingRounds: { lastFundingRound = {}, fundingRounds = [] } = {},
    } = this.props;
    const { funding_type = "", series } = !_.isEmpty(lastFundingRound)
      ? lastFundingRound
      : fundingRounds[0] || {};
    return funding_type === "ventures" && !!series
      ? `Series ${series}`
      : capitalizeString(funding_type.replace(/_/g, " "));
  };

  render() {
    const {
      fundingAmount: { snapshot = {} } = {},
      fundingRounds: { lastFundingRound = {}, fundingRounds = [] } = {},
      fundingRoundsLoading,
      compressed = false,
    } = this.props;
    const { showTooltip } = this.state;
    return (
      <div
        className="data-wrapper compressed-funding-data compressed"
        onMouseLeave={() => {
          this.setState({ showTooltip: false });
        }}
        onMouseEnter={() => {
          this.setState({ showTooltip: true });
        }}
        rel={(el) => (this._parent = el)}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
        />
        <div className="data-icon">
          <AttachMoney className="data-icon-svg" />
        </div>
        <div className="data-content text">
          <div className={this.getFundingDataClasses()}>
            {this.getFundingAmountString()}
          </div>
          <Choose>
            <When condition={fundingRoundsLoading}>
              <Loading small={true} align="left" />
            </When>
            <Otherwise>
              <Choose>
                <When
                  condition={
                    _.isEmpty(lastFundingRound) &&
                    (fundingRounds.length === 0 ||
                      Object.keys(fundingRounds[0] || {}).length === 0)
                  }
                >
                  <div className="data-content text empty">No data</div>
                </When>
                <Otherwise>{this.getLastFundingRoundString()}</Otherwise>
              </Choose>
            </Otherwise>
          </Choose>
        </div>
      </div>
    );
  }
}

export default withRouter(CompressedFundingData);
