import React from "react";
import PropTypes from "prop-types";

export default class DecisionRecommendationDisplay extends React.Component {
  static propTypes = {
    outcome: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  };

  buildClassName = (outcome, type) => {
    return outcome
      ? ["decision-recommendation-display", outcome.toLowerCase(), type].join(
          " "
        )
      : ["decision-recommendation-display", "comment", type].join(" ");
  };

  render() {
    const { outcome = "", type } = this.props;
    return (
      <Choose>
        <When condition={outcome}>
          <div className={this.buildClassName(outcome, type)}>
            {outcome.replace(/[-_]/g, " ")}
          </div>
        </When>
        <Otherwise>
          <div className={this.buildClassName(outcome, type)}>COMMENT</div>
        </Otherwise>
      </Choose>
    );
  }
}
