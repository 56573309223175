import PropTypes from "prop-types";
import React from "react";
import QualificationStore from "../../../storage/QualificationStore";
import LinearProgress from "material-ui/LinearProgress";
import AppBar from "material-ui/AppBar";
import EditorInsertDriveFile from "material-ui/svg-icons/editor/insert-drive-file";
import { List, ListItem } from "material-ui/List";
import Paper from "material-ui/Paper";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import { uploadModes } from "../../../content/qualification";
import FilesStore from "../../../storage/FilesStore";
import xlsIcon from "../../../../images/xls-file-64px.png";
import docIcon from "../../../../images/doc-file-64px.png";
import pptIcon from "../../../../images/ppt-file-64px.png";
import pdfIcon from "../../../../images/pdf-file-64px.png";
import jpegIcon from "../../../../images/jpeg-file-64px.png";
import blankIcon from "../../../../images/blank-file-64px.png";

function fileId(s) {
  const docsRe =
    /https:\/\/docs.google.com\/(?:document|spreadsheets|presentation|drawings)\/d\/([a-zA-Z0-9-_]+)\//;
  const driveRe = /https:\/\/drive.google.com\/file\/d\/([a-zA-Z0-9-_]+)\//;

  if (docsRe.test(s)) {
    const [, id] = s.match(docsRe);
    return id;
  }

  if (driveRe.test(s)) {
    const [, id] = s.match(driveRe);
    return id;
  }

  return null;
}

function fileIds(s) {
  return s.split("\n").map(fileId).filter(Boolean);
}

export default class AddFile extends React.Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    task: PropTypes.object.isRequired,
    onNewQualification: PropTypes.func.isRequired,
    onFileUploaded: PropTypes.func.isRequired,
    onUploadingChange: PropTypes.func.isRequired,
    assignedFiles: PropTypes.array.isRequired,
    driveFiles: PropTypes.array,
    driveFolder: PropTypes.string,
  };

  state = {
    error: null,
    uploadFromDesktop: true,
    uploadMode: "add_folder",
    fileIds: [],
  };

  qualificationStore = new QualificationStore(this.props.organizationId);
  filesStore = new FilesStore(this.props.organization.id);

  handleUpload = () => {
    const { task, onUploadingChange, onFileUploaded, onNewQualification } =
      this.props;
    onUploadingChange(true);
    this.qualificationStore
      .uploadFile(task.category, task.id, this._fileUpload.files[0])
      .then((checklist) => {
        onNewQualification(checklist);
        onFileUploaded();
        onUploadingChange(false);
      })
      .catch((error) => {
        console.error(error);
        onUploadingChange(false);
      });
  };

  addFileToTask = (fileId) => {
    const { task, onUploadingChange, onFileUploaded, onNewQualification } =
      this.props;
    onUploadingChange(true);
    this.qualificationStore
      .addFileToTask(task.category, task.id, fileId)
      .then((checklist) => {
        onNewQualification(checklist);
        onFileUploaded();
        onUploadingChange(false);
      })
      .catch((error) => {
        console.error(error);
        onUploadingChange(false);
      });
  };

  findCopiedFiles = (files) => {
    const driveIds = this.props.driveFiles.reduce(
      (ids, file) => ids.concat(file.id),
      []
    );

    return [...files.files].filter((x) => !driveIds.includes(x.id));
  };

  addFiles = () => {
    const { fileIds, uploadMode } = this.state;
    const { onUploadingChange, onFileUploaded } = this.props;
    if (this.state.fileIds.length < 1) {
      return;
    }

    onUploadingChange(true);

    Promise.all(
      fileIds.map((fileId) =>
        this.filesStore.copyOrAddFile(fileId, uploadMode.split("_")[0])
      )
    )
      .then((responses) =>
        responses.reduce((a, b) => (a.files.length >= b.files.length ? a : b))
      )
      .then((files) => {
        if (uploadMode.split("_")[0] === "copy") {
          const addedFiles = this.findCopiedFiles(files);
          addedFiles.map((file) => this.addFileToTask(file.id));
        } else {
          fileIds.map((fileId) => this.addFileToTask(fileId));
        }

        onUploadingChange(false);
        onFileUploaded();
      })
      .catch(console.error);
  };

  handleChangeUploadMode = (uploadMode) => {
    this.setState({ uploadMode });
  };

  onInputChange = (e) => {
    if (e.target.value === "") {
      return;
    }
    this.setState({ fileIds: fileIds(e.target.value) });
  };

  getIcon = (file) => {
    if (file.mime_type === "application/vnd.google-apps.spreadsheet")
      return xlsIcon;
    if (file.mime_type === "application/vnd.google-apps.document")
      return docIcon;
    if (file.mime_type === "application/vnd.google-apps.presentation")
      return pptIcon;
    if (file.mime_type === "application/pdf") return pdfIcon;
    if (file.mime_type === "application/png") return jpegIcon;
    else {
      return blankIcon;
    }
  };

  setRef = (el) => (this._fileUpload = el);

  render() {
    const { uploadMode, fileIds } = this.state;
    const { uploading, driveFiles, assignedFiles } = this.props;
    return (
      <div className="task-upload-dialog">
        <div className="upload-type-buttons">
          <RadioButtonGroup
            name="uploadMode"
            valueSelected={uploadMode}
            style={{ display: "flex" }}
          >
            {Object.entries(uploadModes).map(([key, values]) => {
              return (
                <RadioButton
                  key={key}
                  value={key}
                  label={values.title}
                  className={
                    uploadMode === key
                      ? "selectedUploadMode"
                      : "unselectedUploadMode"
                  }
                  checkedIcon={values.icon}
                  uncheckedIcon={values.icon}
                  onClick={() => this.handleChangeUploadMode(key)}
                />
              );
            })}
          </RadioButtonGroup>
        </div>
        <Choose>
          <When condition={uploading}>
            <h4>Adding files to Google Drive...</h4>
            <LinearProgress />
          </When>
          <Otherwise>
            <Choose>
              <When condition={uploadMode === "copy_computer"}>
                <p>
                  Select a file from your computer to add it to the task's
                  files.
                </p>
                <input
                  ref={this.setRef}
                  onChange={this.handleUpload}
                  multiple
                  type="file"
                />
              </When>
              <When
                condition={
                  uploadMode === "copy_drive" || uploadMode === "add_drive"
                }
              >
                <If condition={uploadMode === "copy_drive"}>
                  <p>
                    Duplicate the original(s) into this Google Drive folder.
                  </p>
                  <p>
                    Edits to the duplicate(s) will not affect the original
                    file(s).
                  </p>
                </If>
                <If condition={uploadMode === "add_drive"}>
                  <p>
                    Create a link to the original(s) in this Google Drive
                    folder.
                  </p>
                  <p>
                    Edits will be reflected in all locations in which a link
                    exists.
                  </p>
                  <p>
                    Deleting a link does not delete the original file. However,
                    if the original file is deleted so are all of the links to
                    it.
                  </p>
                </If>
                <TextField
                  floatingLabelText="File URL(s)"
                  floatingLabelFixed={true}
                  hintText="https://docs.google.com/document/d/18tNd8r5DV0yluCR7tPvkMTsWD_lYcRO7NhpNSDymRr8/edit"
                  fullWidth={true}
                  multiLine={true}
                  onChange={this.onInputChange}
                  disabled={uploading}
                />
                <FlatButton
                  label={uploadMode === "copy_drive" ? "Copy" : "Add"}
                  onClick={this.addFiles}
                  disabled={fileIds == null || uploading}
                />
              </When>
              <Otherwise>
                <p>
                  Select a file from the main Drive to add it to the task's
                  files. (Only the unassigned files are selectable)
                </p>
                <AppBar
                  className="appBar light"
                  title="Drive files"
                  iconElementLeft={
                    <div className="icon-container">
                      <EditorInsertDriveFile style={{ color: "white" }} />
                    </div>
                  }
                />
                <div className="section-container">
                  <Paper
                    zDepth={1}
                    style={{ maxHeight: "250px", overflow: "auto" }}
                  >
                    <List className={"add-file-to-task-list"}>
                      {driveFiles.map((file) => {
                        if (!assignedFiles.includes(file.id)) {
                          return (
                            <ListItem
                              key={file.id}
                              primaryText={file.name}
                              leftAvatar={
                                <img
                                  src={this.getIcon(file)}
                                  alt="Logo"
                                  style={{ height: "32px", width: "32px" }}
                                />
                              }
                              onClick={() => this.addFileToTask(file.id)}
                            />
                          );
                        }
                      })}
                    </List>
                  </Paper>
                </div>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
