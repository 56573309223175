import React from "react";
import PropTypes from "prop-types";

export default class Card extends React.Component {
  static propTypes = {
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
  };

  state = {
    expanded: this.props.expanded || true,
  };

  buildClassName = () => {
    const { expandable } = this.props;
    const { expanded } = this.state;
    const classNames = ["card"];
    if (expandable) classNames.push("expandable");
    if (expanded) classNames.push("expanded");
    return classNames.join(" ");
  };

  toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    const { children, expandable } = this.props;
    const { expanded } = this.state;
    const mappedChildren = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        expandable: typeof expandable !== "undefined" ? expandable : false,
        expanded: typeof expanded !== "undefined" ? expanded : true,
        toggleExpanded: this.toggleExpanded,
      })
    );
    return <div className={this.buildClassName()}>{mappedChildren}</div>;
  }
}
