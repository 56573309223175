import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import _ from "underscore";

import Loading from "../../../loading/Loading";
import NewTag from "../NewTag";

import { capitalizeString, getRoundedUpValue } from "../../../../lib/helpers";

class LastFundingRound extends React.Component {
  static propTypes = {
    fundingRounds: PropTypes.object.isRequired,
    fundingRoundsLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool,
  };

  hasChanged = () => {
    const {
      fundingRounds: {
        lastFundingRound = {},
        fundingRounds = [],
        decisionMade,
      } = {},
      useSnapshot = false,
      previous = false,
    } = this.props;
    const { announced_on } = !_.isEmpty(lastFundingRound)
      ? lastFundingRound
      : fundingRounds[0] || {};
    return useSnapshot && !previous
      ? new Date(announced_on).getTime() > decisionMade
      : false;
  };

  getFundingRoundWhenDecisionMade = () => {
    const { fundingRounds: { fundingRounds = [], decisionMade } = {} } =
      this.props;
    return (
      fundingRounds.find(
        ({ announced_on }) => new Date(announced_on).getTime() < decisionMade
      ) || {}
    );
  };

  getLatestFundingRoundAmount = () => {
    const {
      fundingRounds: { lastFundingRound = {}, fundingRounds = [] } = {},
    } = this.props;
    const { money_raised_usd = null } = !_.isEmpty(lastFundingRound)
      ? lastFundingRound
      : fundingRounds[0] || {};
    return `${
      money_raised_usd === null
        ? "Undisclosed amount"
        : `$${getRoundedUpValue(money_raised_usd)}`
    }`;
  };

  getLatestFundingRoundDate = () => {
    const {
      fundingRounds: { lastFundingRound = {}, fundingRounds = [] } = {},
    } = this.props;
    const { announced_on } = !_.isEmpty(lastFundingRound)
      ? lastFundingRound
      : fundingRounds[0] || {};
    const announcedDate = new Date(announced_on);
    return `${announcedDate.toLocaleString("default", {
      month: "short",
    })}, ${announcedDate.getFullYear()}`;
  };

  getLatestFundingRoundType = () => {
    const {
      fundingRounds: { lastFundingRound = {}, fundingRounds = [] } = {},
    } = this.props;
    const { funding_type = "", series } = !_.isEmpty(lastFundingRound)
      ? lastFundingRound
      : fundingRounds[0] || {};
    return funding_type === "ventures" && !!series
      ? `Series ${series}`
      : capitalizeString(funding_type.replace(/_/g, " "));
  };

  render() {
    const {
      fundingRounds: { lastFundingRound = {}, fundingRounds = [] } = {},
      fundingRoundsLoading,
      previous = false,
    } = this.props;
    const hasChanged = this.hasChanged();
    return (
      <div className="data-wrapper last-funding-round">
        <div>
          <If condition={hasChanged}>
            <NewTag
              source="last-funding-round"
              snapshot={{
                fundingRounds: [this.getFundingRoundWhenDecisionMade()],
              }}
            />
          </If>
          <div className="data-header">
            <If condition={previous}>Previous&nbsp;</If>
            Last Funding Round
          </div>
        </div>
        <Choose>
          <When condition={fundingRoundsLoading}>
            <Loading small={true} align="left" />
          </When>
          <Otherwise>
            <Choose>
              <When
                condition={
                  _.isEmpty(lastFundingRound) &&
                  (fundingRounds.length === 0 ||
                    Object.keys(fundingRounds[0] || {}).length === 0)
                }
              >
                <div className="data-content text empty">No data</div>
              </When>
              <Otherwise>
                <div className="data-content text">
                  {this.getLatestFundingRoundAmount()}
                  <br />
                  {`${this.getLatestFundingRoundType()} - ${this.getLatestFundingRoundDate()}`}
                </div>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(LastFundingRound);
