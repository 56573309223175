import { compactInteger } from "humanize-plus";
import React from "react";
import PropTypes from "prop-types";

import _ from "underscore";

import scorecardFormFormat from "../../../../content/scorecardFormFormat";

import config from "../../../../config";

function FormObject(props) {
  const { formSectionFormat, answer } = props;
  const { format = "short" } = formSectionFormat;

  return (
    <div className={`form-aspect-answer-wrapper ${format}`}>
      {Object.entries(answer).map(([subQuestion, subAnswer]) => {
        const parsedSubAnswer = parseInt(subAnswer);
        const { form_subtitle: subtitle = null } =
          formSectionFormat[subQuestion] || {};
        return (
          <div className="form-aspect-answer" key={subQuestion}>
            <If condition={subtitle}>
              <div className="form-subtitle">{subtitle}</div>
            </If>
            <If condition={parsedSubAnswer}>
              <div className="form-subtitle">
                {compactInteger(parsedSubAnswer)}
              </div>
            </If>
            <If condition={typeof answer === "string"}>
              <div className="form-aspect-answer-value">{subAnswer}</div>
            </If>
          </div>
        );
      })}
    </div>
  );
}

function extractInt(str) {
  const parsedAnswer = parseInt(str);
  if (parsedAnswer && str.length > parsedAnswer.toString().length + 2) {
    return null;
  }
  return parsedAnswer;
}

function FormArrayOrString(props) {
  const {
    formSectionFormat: { form_subtitle: subtitle = null, format = "long" },
    answer,
  } = props;

  const parsedAnswer = extractInt(answer);

  return (
    <React.Fragment>
      <If condition={subtitle}>
        <div className="form-subtitle">{subtitle}</div>
      </If>
      <div className={`form-aspect-answer-wrapper ${format}`}>
        <Choose>
          <When condition={parsedAnswer}>
            <div className="form-aspect-answer">
              {compactInteger(parsedAnswer)}
            </div>
          </When>
          <When condition={typeof answer === "string"}>
            <div className="form-aspect-answer">{answer}</div>
          </When>
          <When condition={Array.isArray(answer)}>
            <If condition={typeof answer[0]?.name && !subtitle}>
              {answer.map((subAnswer) => (
                <div
                  className="form-aspect-answer"
                  key={`${subAnswer.name || subAnswer.first_name}-${
                    subAnswer.last_name
                  }-${subAnswer.title}`}
                >
                  <FormFounderOrKeyEmployee person={subAnswer} />
                </div>
              ))}
            </If>
            <If
              condition={
                typeof answer[0] === "string" &&
                subtitle === "Supporting documents"
              }
            >
              <ul>
                {answer.map((subAnswer, i) => {
                  const filename = subAnswer.split("/").slice(-1)[0];
                  const url = new URL(subAnswer);
                  const params = new URLSearchParams({
                    apiKey: config.jotformApiKey,
                  });
                  return (
                    <li>
                      <a
                        key={i}
                        target="_blank"
                        href={`${url.toString()}?${params.toString()}`}
                      >
                        {filename}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </If>
            <If
              condition={
                typeof answer[0] === "string" && subtitle === "Supporting links"
              }
            >
              <ul>
                {answer.map((subAnswer, i) => {
                  try {
                    const url = new URL(subAnswer);
                    return (
                      <li>
                        <a target="_blank" href={subAnswer}>
                          {subAnswer}
                        </a>
                      </li>
                    );
                  } catch {
                    return <li>{subAnswer}</li>;
                  }
                })}
              </ul>
            </If>
          </When>
        </Choose>
      </div>
    </React.Fragment>
  );
}

function FormFounderOrKeyEmployee(props) {
  const {
    person: {
      name,
      first_name = "",
      last_name = "",
      title = "",
      linkedin = "",
      percentage = "",
      ownership = "",
    },
  } = props;
  return (
    <div className="founder-key-employee">
      <div className="form-subtitle">{`${
        name || first_name
      } ${last_name}`}</div>
      <div className="founder-key-employee-title-ownership">
        <If condition={title !== ""}>
          <span className="title">{`${title}`}</span>
        </If>
        <If condition={percentage !== ""}>
          <span className="ownership">{` - ${percentage}%`}</span>
        </If>
        <If condition={ownership !== ""}>
          <span className="ownership">{` - ${ownership}%`}</span>
        </If>
      </div>
    </div>
  );
}

export default class ScorecardModalSectionContentForm extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
  };

  getAnswerType = (answer) =>
    Array.isArray(answer)
      ? "array"
      : typeof answer === "object"
      ? "object"
      : "string";

  removeUndefinedAnswers = (answer, type) => {
    if (type === "object") {
      answer = _.omit(answer, _.isUndefined);
    }
    return answer;
  };

  questionHasAnswer = (answer, type) =>
    (type === "array" && answer.length > 0) ||
    (type === "object" && Object.keys(answer).length !== 0) ||
    (type === "string" && typeof answer !== "undefined" && answer !== "") ||
    type === "number";

  render() {
    const { section, content } = this.props;
    const { [section]: formSectionFormat = {} } = scorecardFormFormat;

    return (
      <div className="scorecard-modal-section-content-form">
        {Object.entries(content).map(([question, answer]) => {
          const answerType = this.getAnswerType(answer);
          answer = this.removeUndefinedAnswers(answer, answerType);
          if (!this.questionHasAnswer(answer, answerType)) {
            return null;
          }
          const { form_title: title = null } =
            formSectionFormat[question] || {};
          return (
            <div className="form-aspect" key={question}>
              <If condition={title}>
                <div className="form-title">{title}</div>
              </If>
              <Choose>
                <When
                  condition={
                    typeof answer === "object" && answer.constructor === Object
                  }
                >
                  <FormObject
                    formSectionFormat={formSectionFormat[question] || {}}
                    answer={answer}
                  />
                </When>
                <Otherwise>
                  <FormArrayOrString
                    formSectionFormat={formSectionFormat[question] || {}}
                    answer={answer}
                  />
                </Otherwise>
              </Choose>
            </div>
          );
        })}
      </div>
    );
  }
}
