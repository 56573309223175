import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

class NumberOfLeadInvestors extends React.Component {
  static propTypes = {
    fundingRounds: PropTypes.array.isRequired,
  };

  getNumberOfLeadInvestors = () => {
    const { fundingRounds = [] } = this.props;
    let leadInvestors = 0;
    for (const { participations = [] } of fundingRounds) {
      for (const { is_lead = false } of participations) {
        if (is_lead) {
          leadInvestors++;
        }
      }
    }
    return leadInvestors;
  };

  render() {
    return (
      <div className="data-wrapper number-of-funding-rounds">
        <div className="data-header">No. of Lead Investors</div>
        <div className="data-content text">
          {this.getNumberOfLeadInvestors()}
        </div>
      </div>
    );
  }
}

export default withRouter(NumberOfLeadInvestors);
