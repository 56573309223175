import React from "react";
import PropTypes from "prop-types";

import xss from "xss";

export default class TimelineEventInboxBody extends React.Component {
  static propTypes = {
    createdAt: PropTypes.string,
    notes: PropTypes.string,
    section: PropTypes.string.isRequired,
  };

  render() {
    const { createdAt, notes, section } = this.props;
    return (
      <div className="timeline-event-body">
        <If condition={createdAt !== null && section !== "past"}>
          <p className="timeline-event-body-created-at">{createdAt}</p>
        </If>
        <If condition={notes !== null}>
          <p className="timeline-event-body-content-header">
            <b>Notes</b>
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: xss(notes.replace(/(?:\r\n|\r|\n)/g, "<br>")),
            }}
          />
        </If>
      </div>
    );
  }
}
