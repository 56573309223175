import PropTypes from "prop-types";
import React from "react";

class BioCell extends React.Component {
  render() {
    const bio = this.props.person.bio || "";
    return (
      <div>
        <span className="text-justify trunc-cell-text">{bio}</span>
      </div>
    );
  }
}

BioCell.propTypes = {
  person: PropTypes.object.isRequired,
};

export default BioCell;
