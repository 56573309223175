import React from "react";
import PropTypes from "prop-types";

import Avatar from "material-ui/Avatar";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import Subheader from "material-ui/Subheader";

import ToggleStarBorder from "material-ui/svg-icons/toggle/star-border";
import ToggleStar from "material-ui/svg-icons/toggle/star";
import ContentInbox from "material-ui/svg-icons/content/inbox";

import { reasons } from "../../content/inbox";
import { activeInReachTeam } from "../../lib/helpers";

const ACTIVE_TEAM = activeInReachTeam();
const buttonStyle = { width: "24px", height: "24px", padding: "0" };
const iconStyle = { fill: "#337ab7" };

function Star({ onClick, disabled, active }) {
  return (
    <IconButton
      className="inbox-controls-star"
      data-inboxed={`${active}`}
      style={buttonStyle}
      iconStyle={iconStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {active ? <ToggleStar /> : <ToggleStarBorder />}
    </IconButton>
  );
}

class Inbox extends React.Component {
  handleInboxMenuItemClick = (e, menuItem) => {
    const assignedTo = menuItem.props.value;

    if (this.props.userInboxes.includes(assignedTo)) {
      this.props.removeFromInbox(this.props.organization, assignedTo);
    } else {
      this.props.addToInbox(assignedTo);
    }
  };

  render() {
    const { disabled, userInboxes } = this.props;

    return (
      <IconMenu
        className="inbox-controls-inbox"
        iconButtonElement={
          <IconButton disabled={disabled} style={buttonStyle}>
            <ContentInbox />
          </IconButton>
        }
        iconStyle={iconStyle}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        targetOrigin={{ horizontal: "left", vertical: "top" }}
        useLayerForClickAway={true}
        onItemClick={this.handleInboxMenuItemClick}
      >
        <Subheader>Whose inbox?</Subheader>
        {ACTIVE_TEAM.map((tm) => (
          <MenuItem
            key={tm.email}
            primaryText={tm.name}
            rightIcon={<Avatar src={tm.imageUrl} />}
            value={tm.email}
            checked={userInboxes.includes(tm.email)}
            data-inbox={tm.email}
          />
        ))}
      </IconMenu>
    );
  }
}

export default class InboxControls extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    currentUser: PropTypes.string.isRequired,
    inbox: PropTypes.object,
    userInboxes: PropTypes.arrayOf(PropTypes.string).isRequired,
    organization: PropTypes.object,
    addToInbox: PropTypes.func.isRequired,
    removeFromInbox: PropTypes.func.isRequired,
    showInboxModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    addToInbox: () => {},
    removeFromInbox: () => {},
    showInboxModal: () => {},
    userInboxes: [],
  };

  addToOwnInbox = () => {
    this.props.addToInbox(this.props.organization, this.props.currentUser);
  };

  removeFromOwnInbox = () => {
    this.props.removeFromInbox(this.props.organization, this.props.currentUser);
  };

  showInboxModal = (assignedTo) => {
    this.props.showInboxModal(this.props.organization, assignedTo);
  };

  render() {
    const {
      disabled,
      addToInbox,
      inbox,
      userInboxes,
      organization,
      removeFromInbox,
    } = this.props;

    return (
      <React.Fragment>
        <Star
          disabled={disabled}
          onClick={
            inbox && inbox.inbox ? this.removeFromOwnInbox : this.addToOwnInbox
          }
          active={!!inbox && inbox.inbox}
        />

        <Inbox
          disabled={disabled}
          organization={organization}
          addToInbox={this.showInboxModal}
          removeFromInbox={removeFromInbox}
          userInboxes={userInboxes}
        />
      </React.Fragment>
    );
  }
}
