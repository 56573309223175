import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";
import Loading from "../loading/Loading";
import InvestorSummarySection from "./InvestorSummarySection";
import PeopleSection from "../organization/profile/people/PeopleSection";
import _ from "underscore";

class InvestorProfileTab extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    showAsPublicProfile: PropTypes.bool.isRequired,
    userRoles: PropTypes.array.isRequired,
    peopleLoading: PropTypes.bool.isRequired,
    editPerson: PropTypes.func.isRequired,
    tabsContent: PropTypes.object.isRequired,
  };

  render() {
    const {
      investor = {},
      organization = {},
      peopleLoading,
      tabsContent: { workflow = [] } = {},
      editPerson,
    } = this.props;
    return (
      <div className="investor-profile-tab">
        <Choose>
          <When condition={_.isEmpty(investor)}>
            <Loading />
          </When>
          <Otherwise>
            <InvestorSummarySection {...this.props} />
            <hr className="summary-hr" />
            <If condition={!_.isEmpty(organization)}>
              <PeopleSection
                organization={organization}
                people={workflow}
                peopleLoading={peopleLoading}
                snapshot={{}}
                snapshotLoading={false}
                useSnapshot={false}
                editPerson={editPerson}
                investor={investor}
              />
            </If>
            <hr className="summary-hr" />
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(InvestorProfileTab);
