import React from "react";
import { browserHistory } from "react-router-v4";

import EditOrganizationHeader from "./EditOrganizationHeader";
import Loading from "../../loading/Loading";
import EditPerson from "../../person/EditPerson";

import peopleStore from "../../../storage/PeopleStore";
import OrganizationStore from "../../../storage/OrganizationStore";
import auth from "../../../storage/Auth";

import FlatButton from "material-ui/FlatButton";

import diff from "deep-diff";
import DialogWrapper from "../../components/DialogWrapper";
import PersonStore from "../../../storage/PersonStore";

export default class AddPerson extends React.Component {
  state = {
    person: {},
    isLoading: false,
    isSaving: false,
    saveSuccess: false,
    userHasFullAccess: false,
    organization: null,
    errors: {},
    modifiedFields: [],
    showErrorDialog: false,
    errorMessage: null,
  };

  componentDidMount() {
    this.organizationId =
      this.props.params && this.props.params.id
        ? this.props.params.id
        : this.props.organizationId;
    this.organizationStore = new OrganizationStore(this.organizationId);
    this.organizationStore
      .getModel()
      .then((organization) => {
        this.setState({
          organization,
        });
      })
      .catch((err) => console.log("Unable to retrieve Organization", err));

    auth
      .getAuthData()
      .then(({ accessType, roles, client }) => {
        this.setState({
          userHasFullAccess: accessType === "full",
          userRoles: roles,
          client,
        });
      })
      .catch((err) => console.error(err));
  }

  personCreated = () => {};

  setField = (e) => {
    const person = this.state.person;
    person[e.target.name] = e.target.value;
    this.setState({ person: person });
  };

  checkIfErrors = (errors) => {
    return Object.keys(errors).filter((field) => !!errors[field]).length > 0;
  };

  handleCancel = () => {
    if (this.props.isInsideDialog) {
      this.props.onCancelAdd();
    } else {
      browserHistory.push(`/organizations/${this.state.organization.id}/edit`);
    }
  };

  handleAddPerson = () => {
    this.setState({
      isSaving: true,
    });
    const person = this.state.person;
    person.organizations = [this.organizationId];

    return peopleStore.create(person).then(this.handleCreatedPerson);
  };

  handleCreatedPerson = (person) => {
    this.setState({ person });
    return this.organizationStore
      .waitForNewPerson(person.id)
      .then((personId) => {
        this.setState({
          isSaving: false,
          saveSuccess: true,
        });
        setTimeout(() => {
          this.setState({
            saveSuccess: false,
            modifiedFields: [],
          });
          if (this.props.isInsideDialog) {
            this.props.onAddPerson(personId);
          } else {
            browserHistory.push(`/people/${personId}`);
          }
        }, 1000);
      })
      .then(this.personCreated)
      .catch((error) => {
        this.setState({
          saveSuccess: false,
          isSaving: false,
          showErrorDialog: true,
          errorMessage: error,
        });
        console.log("err", error);
      });
  };

  updatePersonField = (field, value) => {
    const { person = {} } = this.state;
    this.setUpdatedFields(
      Object.assign({}, person, {
        [field]: value,
      })
    );
  };

  updatePersonFields = (data) => {
    const { person = {} } = this.state;
    this.setUpdatedFields(
      Object.assign(
        {},
        person,
        data.reduce((changes, obj) => {
          changes[obj.field] = obj.value;
          return changes;
        }, {})
      )
    );
  };

  setUpdatedFields = (person) => {
    const { person: statePerson } = this.state;
    this.setState({
      modifiedFields: this.getModifiedFields(statePerson, person),
      person,
    });
  };

  getModifiedFields = (initialPerson, modifiedPerson) => {
    const personDiff = diff(initialPerson, modifiedPerson);

    return personDiff
      ? personDiff.map((diffEntry) =>
          diffEntry.path ? diffEntry.path[0] : null
        )
      : [];
  };

  handleErrorDialogClose = () => {
    this.setState({
      errorMessage: null,
      showErrorDialog: false,
    });
  };

  mergePerson = (person) => {
    const mergedPerson = Object.assign({}, this.state.person, person),
      modifiedFields = this.state.modifiedFields.concat(Object.keys(person));

    this.setState({
      person: mergedPerson,
      modifiedFields,
    });
  };

  render() {
    const person = this.state.person,
      organization = this.state.organization,
      client = this.state.client;

    const actions = [
      <FlatButton
        label="Close"
        primary={true}
        onClick={this.handleErrorDialogClose}
      />,
    ];

    if (!organization || !client || this.state.isLoading) {
      return <Loading />;
    }

    return (
      <div className="add-view">
        <EditOrganizationHeader
          isInsideDialog={this.props.isInsideDialog}
          isSaving={this.state.isSaving}
          saveSuccess={this.state.saveSuccess}
          cancel={this.handleCancel}
          confirm={this.handleAddPerson}
          disable={
            this.checkIfErrors(this.state.errors) ||
            this.state.modifiedFields.length === 0 ||
            !this.state.person.name
          }
          title={`Add person to ${this.state.organization.name}`}
          source="add-person"
        />
        <EditPerson
          isInsideDialog={this.props.isInsideDialog}
          modifiedFields={this.state.modifiedFields}
          initialPerson={{}}
          person={person}
          mergePerson={this.mergePerson}
          updatePersonField={this.updatePersonField}
          updatePersonFields={this.updatePersonFields}
          onCreateComplete={() => window.location.reload()}
          organizationId={this.props.organizationId}
        />
        <DialogWrapper
          title="Error while adding new person"
          actions={actions}
          modal={false}
          open={this.state.showErrorDialog}
          onRequestClose={this.handleErrorDialogClose}
        >
          {this.state.errorMessage}
        </DialogWrapper>
      </div>
    );
  }
}
