import request from "superagent";
import auth from "./Auth";
import Config from "../config";

function handleResponse(response) {
  if (!response || !response.ok) {
    throw new Error("Request failed");
  }

  if (response.body.models && Array.isArray(response.body.models)) {
    return response.body.models;
  } else {
    return [response.body];
  }
}

export default class ModelStore {
  constructor(id) {
    this.id = id;
  }

  url() {
    const id = Array.isArray(this.id) ? this.id.join(",") : this.id;
    return Config.api.host + this.path.replace("{id}", id);
  }

  request(url, query) {
    if (this.id === undefined) {
      return Promise.resolve([]);
    }
    return request
      .get(url)
      .query(query)
      .then(handleResponse)
      .catch((error) => {
        if (error.status === 404) {
          return [];
        }
        console.error(`Unable to retrieve ${url} — ${error}`);
        throw error;
      });
  }

  getModels(expiration, signature, showNotes, organizationId) {
    const url = this.url();

    if (expiration && signature) {
      return this.request(url, {
        e: expiration,
        s: signature,
        n: !!showNotes,
        o: organizationId,
      });
    }

    return auth
      .getAuthData()
      .then(({ token, client }) =>
        this.request(url, { access_token: token, client })
      );
  }

  getModel(expiration, signature, showNotes, organizationId) {
    return this.getModels(
      expiration,
      signature,
      showNotes,
      organizationId
    ).then((models) => {
      if (models.length < 1) {
        throw new Error(`Model ${this.id} not found`);
      }
      return models[0];
    });
  }
}
