import request from "superagent";
import auth from "./Auth";
import Config from "../config";

export default class AlertStore {
  getAlert(id) {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(`${Config.api.host}/alerts/${id}`).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then(({ body = {} }) => {
        return body;
      });
  }

  createAlert(investorId, investorName, userId) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(`${Config.api.host}/alerts`)
          .send({
            client: authData.client,
            user_id: userId,
            title: investorName,
            type: "INVESTOR",
            investor_id: investorId,
          })
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then(({ body = {} }) => {
        return body;
      });
  }

  deleteAlert(id) {
    return auth
      .getAuthData()
      .then((authData) =>
        request.delete(`${Config.api.host}/alerts/${id}`).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then(({ body = {} }) => {
        return body;
      });
  }
}
