import React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import ContentContentCopy from "material-ui/svg-icons/content/content-copy";
import ActionCheckCircle from "material-ui/svg-icons/action/check-circle";
import CopyToClipboard from "react-copy-to-clipboard";
import InReachToggle from "../InReachToggle";
import DialogWrapper from "../components/DialogWrapper";
class ShareLinkDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      includeScorecard: false,
    };
    this.handleCopy = this.handleCopy.bind(this);
  }

  handleFocus(event) {
    event.target.select();
  }

  handleCopy() {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 2000);
  }

  render() {
    const actions = [
      <FlatButton
        label="Close"
        primary={true}
        onClick={this.props.handleClose}
      />,
    ];
    return (
      <DialogWrapper
        title="Link to be shared"
        actions={actions}
        modal={false}
        open={this.props.open}
        onRequestClose={this.props.handleClose}
      >
        <div className="share-link-dialog">
          <div className="input-container">
            <input
              type="url"
              value={this.props.link}
              onFocus={this.handleFocus}
              readOnly
            />
            <CopyToClipboard text={this.props.link} onCopy={this.handleCopy}>
              <FlatButton
                label={this.state.copied ? "Copied!" : "Copy link"}
                primary={true}
                icon={
                  this.state.copied ? (
                    <ActionCheckCircle color="#5DAB49" />
                  ) : (
                    <ContentContentCopy />
                  )
                }
              />
            </CopyToClipboard>
          </div>
          <div className="include-scorecard">
            <label> Include scorecard </label>
            <InReachToggle
              toggled={this.state.includeScorecard}
              onToggle={(e, includeScorecard) => {
                this.setState({ includeScorecard });
                this.props.createShareLink(!this.state.includeScorecard);
              }}
            />
          </div>
        </div>
      </DialogWrapper>
    );
  }
}

export default ShareLinkDialog;
