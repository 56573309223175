import auth from "../storage/Auth";
import Config from "../config";
import request from "superagent";

export default class CampaignStore {
  constructor(organizationId) {
    this.campaignUrl = `${Config.api.host}/workflow/sequences`;
    this.organizationCampaignsUrl = `${Config.api.host}/organizations/${organizationId}/campaigns`;
    this.organizationSequencesUrl = `${Config.api.host}/organizations/${organizationId}/workflow/sequences`;
  }

  getCampaign = (campaignKey) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(this.campaignUrl + "/" + campaignKey).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then((response) => response.body);
  };

  getOrganizationCampaign = () => {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(this.organizationCampaignsUrl).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then((response) => response.body.campaign);
  };

  sendCampaign = (campaignCustomization) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(this.organizationCampaignsUrl)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .send(campaignCustomization)
          .set("Accept", "application/json")
      )
      .then((response) => response.body);
  };

  cancelOrganizationCampaign = () => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .delete(this.organizationCampaignsUrl)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .send()
          .set("Accept", "application/json")
      )
      .then((response) => response.body);
  };

  retryOrganizationCampaign = () => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(`${this.organizationCampaignsUrl}/retry`)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .send()
          .set("Accept", "application/json")
      )
      .then((response) => response.body);
  };

  stopAllCampaigns = () => {
    return auth.getAuthData().then((authData) =>
      request
        .delete(this.organizationSequencesUrl)
        .query({
          access_token: authData.token,
          client: authData.client,
        })
        .send()
        .set("Accept", "application/json")
    );
  };
}
