export function isValid(format = "", content = "") {
  switch (format) {
    case "integer":
      return testInteger(content);
    case "integer-growth":
      return testIntegerGrowth(content);
    case "money":
      return testMoney(content);
    case "money-growth":
      return testMoneyGrowth(content);
  }
}

export function getExampleLabel(format) {
  switch (format) {
    case "integer":
      return "100 users";
    case "integer-growth":
      return "Jan 19: 10k users // Feb 19: 20k users // Mar 19: 30k users";
    case "money":
      return "£100k";
    case "money-growth":
      return "Jan 19: £10k // Feb 19: £20k // Mar 19: £30k";
  }
}

function testInteger(str) {
  return /^[0-9]+[kKmMbB]?(?:%| .*)?$/.test(str);
}

function testIntegerGrowth(str) {
  return /^(?:[a-zA-Z]{3,9}[- ](?:[0-9]{2}|[0-9]{4})[=:]? ?[0-9]+[kKmMbB]?.*?(?:(?:\/\/|,)? ?|$)){3}$/.test(
    str
  );
}

function testMoney(str) {
  return /^[$£€¥₡č₵₪₩₦₱ł₲฿₴₫][0-9.,]+[kKmMbB]?.*$/.test(str);
}

function testMoneyGrowth(str) {
  return /^(?:[a-zA-Z]{3,9}[- ](?:[0-9]{2}|[0-9]{4})[=:]? ?[$£€¥₡č₵₪₩₦₱ł₲฿₴₫][0-9.,]+[kKmMbB]?.*?(?:(?:\/\/|,)? ?|$)){3}.*?$/.test(
    str
  );
}
