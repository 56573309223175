import React from "react";
import EditPersonRoute from "../person/EditPersonRoute";
import Dialog from "material-ui/Dialog";
import DialogWrapper from "../components/DialogWrapper";
const dialogStyles = {
  dialogRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
  },
  dialogContent: {
    position: "relative",
    width: "80vw",
    maxWidth: "1024px",
    transform: "",
  },
  dialogBody: {
    paddingBottom: 0,
  },
};

class EditPersonDialog extends React.Component {
  render() {
    return (
      <DialogWrapper
        title=""
        bodyClassName="edit-person-dialog-root"
        actionsContainerClassName="actions-container"
        modal={false}
        open={this.props.show}
        onRequestClose={this.props.onCloseDialog}
        contentStyle={dialogStyles.dialogContent}
        bodyStyle={dialogStyles.dialogBody}
        style={dialogStyles.dialogRoot}
        repositionOnUpdate={false}
      >
        <EditPersonRoute
          isInsideDialog={true}
          organizationId={this.props.organizationId}
          personId={this.props.personId}
          onCancelEdit={this.props.onCloseDialog}
          onSavePerson={this.props.onSavePerson}
        />
      </DialogWrapper>
    );
  }
}

export default EditPersonDialog;
