import PropTypes from "prop-types";
import React from "react";
import Signals from "../Signals";
import SignalStore from "../../storage/SignalStore";
import moment from "moment";
import Loading from "../loading/Loading";

class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.person.id;
    this.signalStore = new SignalStore();

    this.state = {};

    this.updateSignals = this.updateSignals.bind(this);
  }

  componentWillMount() {
    const from = moment().subtract(1, "years"),
      to = moment();

    this.signalStore
      .fetchAllSignals("people", this.id, from.format("x"), to.format("x"))
      .then(this.updateSignals);
  }

  updateSignals(signals) {
    this.setState({ signals });
  }

  render() {
    const signals = this.state.signals;

    return (
      <div className="signals-container">
        <If condition={signals === undefined}>
          <Loading />
        </If>
        <If condition={signals}>
          <Signals
            title="Twitter Followers"
            signals={signals["TWITTER-TWITTER_FOLLOWERS"] || []}
            expanded={true}
          />
          <Signals
            title="Twitter Listed"
            signals={signals["TWITTER-TWITTER_LISTED"] || []}
          />
          <Signals
            title="Github Followers"
            signals={signals["GITHUB-GITHUB_FOLLOWERS"] || []}
          />
        </If>
      </div>
    );
  }
}

Analytics.propTypes = {
  person: PropTypes.object.isRequired,
};

export default Analytics;
