import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

import RefreshIndicator from "material-ui/RefreshIndicator";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import getMuiTheme from "material-ui/styles/getMuiTheme";

import Loading from "../loading/Loading";
import CompletedEvaluation from "./CompletedEvaluation";
import DIGDownDialog from "./DIGDownDialog";
import InboxModal from "./InboxModal";

import organizationsStore from "../../storage/OrganizationsStore";
import OrganizationStore from "../../storage/OrganizationStore";
import auth from "../../storage/Auth";
import MuiTheme from "../../lib/MuiTheme";
import SearchParamsHelper from "../../lib/SearchParamsHelper";
import inboxHelper from "../../lib/InboxHelper";
import FeedbackStore from "../../storage/FeedbackStore";

import PreviewOrganization from "./preview/PreviewOrganization";
import StructuredReasonsStore from "../../storage/StructuredReasonsStore";
import DialogWrapper from "../components/DialogWrapper";

const MAX_BROWSER_WIDTH = 1024;

const topOfElement = function (element) {
  if (!element) {
    return 0;
  }
  return element.offsetTop + topOfElement(element.offsetParent);
};

const getReturnUrl = (route, params) =>
  `/${route}?${Object.entries(params)
    .reduce((paramsArray, [param, value]) => {
      paramsArray.push(`${param}=${value}`);
      return paramsArray;
    }, [])
    .join("&")}`;

export default class Organizations extends React.Component {
  state = {
    organizations: [],
    loading: false,
    loadingMore: false,
    displayError: false,
    error: null,
    userHasFullAccess: false,
    openDIGDownDialog: false,
    errorMessageDIGDown: null,
    isUpdatingInbox: false,
    inboxAssignedTo: null,
    inboxOrganization: null,
    feedbackCategories: [],
    structuredReasons: {},
    structuredFeedbackTypes: {},
    workflowSyncs: {},
  };

  componentWillUpdate(newProps) {
    if (
      !SearchParamsHelper.arePropsEqual(
        organizationsStore.searchParams,
        newProps.searchParams
      )
    ) {
      this.offset = 0;
      organizationsStore.initialize(newProps);
      this.fetchNextPage(newProps);
    }
  }

  componentDidMount() {
    const organizations = this.filterOrganizationsForInbox(
      organizationsStore.getAll()
    );

    if (organizations.length === 0) {
      this.offset = 0;
      organizationsStore.initialize(this.props);
      this.fetchNextPage(this.props);
    } else {
      this.setState({
        organizations,
      });

      this.offset = organizations.length;
    }

    auth
      .getAuthData()
      .then(({ accessType, roles }) => {
        this.setState({
          userHasFullAccess: accessType === "full",
          userRoles: roles,
        });
        auth.registerUser();
      })
      .catch((err) => {
        console.error(err);
      });

    new FeedbackStore().getFeedback().then(({ categories = [] }) => {
      this.setState({
        feedbackCategories: categories,
      });
    });

    new StructuredReasonsStore().getAllReasons().then((reasons) => {
      this.setState({
        structuredReasons: {
          pass: reasons.find(({ id }) => id === "passed"),
          reconnect: reasons.find(({ id }) => id === "in_tracking"),
        },
      });
    });

    this._mounted = true;
  }

  componentWillUnmount() {
    this.detachScrollListener();
    this._mounted = false;
  }

  componentDidUpdate() {
    if (
      !this.state.loading &&
      !this.state.loadingMore &&
      this.state.organizations &&
      this.state.organizations.length < organizationsStore.getSize() &&
      this._el &&
      parseInt(this._el.attributes["data-organizations-num"].value) ===
        this.state.organizations.length &&
      !organizationsStore.isLoading()
    ) {
      this.attachScrollListener();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.exportingCSV && newProps.limitCSV) {
      this.exportToCSV(newProps.limitCSV);
    }
  }

  getChildContext = () => {
    return {
      muiTheme: getMuiTheme(MuiTheme),
    };
  };

  exportToCSV = (limit) => {
    return organizationsStore
      .exportToCSV(limit)
      .then((result) =>
        this.props.exportCSVCallback(result && result.text ? result.text : null)
      );
  };

  fetchNextPage = (options, loadMore) => {
    let currentOffset = Math.max(this.offset, options.offset),
      params = _.extend({ offset: currentOffset }, options.searchParams);
    if (loadMore) {
      this.setState({ loadingMore: true });
      if (typeof mixpanel !== "undefined") {
        mixpanel.track("Scrolled down");
      }
      params.offset = currentOffset;
    } else {
      this.setState({ loading: true });
    }
    //this.props.changeUrl(`${this.props.location.pathname}`, params, loadMore);

    return organizationsStore
      .fetch(this.offset)
      .then((offset) => {
        this.offset = offset;
        this.handleOrganizationsChange();
      })
      .catch((error) => {
        console.log("Server is down: ", error);
        this.setState({
          organizations: [],
          total: 0,
          loading: false,
          loadingMore: false,
          openDIGDownDialog: true,
          errorMessageDIGDown: JSON.stringify(error),
        });
      });
  };

  computeItemHeight = () => {
    let browserWidth;
    if (self.innerHeight) {
      browserWidth = self.innerWidth;
    } else if (
      document.documentElement &&
      document.documentElement.clientWidth
    ) {
      browserWidth = document.documentElement.clientWidth;
    } else if (document.body) {
      browserWidth = document.body.clientWidth;
    }

    if (browserWidth > MAX_BROWSER_WIDTH) {
      browserWidth = MAX_BROWSER_WIDTH;
    }

    let staticHeight = 136;

    if (this.props.pathname === "inbox") {
      staticHeight = 200;
    }

    const height =
      ((browserWidth - 340) * (staticHeight - 235)) /
        (MAX_BROWSER_WIDTH - 340) +
      235;

    return height;
  };

  handleOrganizationsChange = () => {
    this.setState({
      organizations: this.filterOrganizationsForInbox(
        organizationsStore.getAll()
      ),
      total: organizationsStore.getSize(),
      loading: false,
      loadingMore: false,
    });
    this.props.updateOrgsNumber(organizationsStore.getSize());
  };

  reloadOrganization = (organizationId) => {
    this.setState({
      loading: true,
    });

    this.organizationStore = new OrganizationStore(organizationId);
    this.organizationStore
      .getModel()
      .then((organization) => {
        this.setState({
          loading: false,
        });
        this.handleOrganizationChange(organization);
      })
      .catch((err) => {
        const errorMessage = `Problem retrieving organization ${organizationId}: ${err}`;
        console.error(errorMessage, err);
        this.setState({
          openDIGDownDialog: true,
          errorMessageDIGDown: errorMessage,
          loading: false,
        });
      });
  };

  handleOpenStructuredFeedback = (type, organizationId) => {
    const newTypes = Object.assign({}, this.state.structuredFeedbackTypes);
    newTypes[organizationId] = type;
    this.setState({ structuredFeedbackTypes: newTypes });
  };

  handleCloseStructuredFeedback = () => {
    this.setState({ structuredFeedbackTypes: {} });
  };

  handleOpenWorkflowSync = (organizationId) => {
    const newSyncs = Object.assign({}, this.state.workflowSyncs);
    newSyncs[organizationId] = true;
    this.setState({ workflowSyncs: newSyncs });
  };

  handleCloseWorkflowSync = () => {
    this.setState({ workflowSyncs: {} });
  };

  scrollListener = () => {
    let el, scrollTop;
    if (this._mounted) {
      el = this._el;
      if (!el) return;
      scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;
      //load more when you reached the bottom 20% of the page height
      if (
        topOfElement(el) + el.offsetHeight - scrollTop - window.innerHeight <=
          el.offsetHeight * 0.2 &&
        !this.state.loading &&
        !this.state.loadingMore
      ) {
        this.detachScrollListener();
        this.fetchNextPage(this.props, true);
      }
    }
  };

  attachScrollListener = () => {
    window.addEventListener("scroll", this.scrollListener);
    window.addEventListener("resize", this.scrollListener);
    this.scrollListener();
  };

  detachScrollListener = () => {
    window.removeEventListener("scroll", this.scrollListener);
    window.removeEventListener("resize", this.scrollListener);
  };

  filterOrganizationsForInbox = (organizations = []) => {
    const { user_inbox } = this.props.searchParams;

    if (!this.props.searchParams.inbox) {
      return organizations;
    }

    for (const organization of organizations) {
      const inbox = inboxHelper.getInbox(
        organization,
        this.props.client,
        user_inbox
      );

      if (!inbox || !inbox.inbox) {
        return organizationsStore.removeModel(organization.id);
      }
    }

    return organizations;
  };

  handleOrganizationChange = (organization) => {
    const organizations = organizationsStore.replaceModel(organization);
    const filteredOrgs = this.filterOrganizationsForInbox(organizations);
    this.setState({ organizations: filteredOrgs });
  };

  handleErrorOpen = (error, organization) => {
    this.handleOrganizationChange(organization);
    this.setState({
      error,
      displayError: true,
    });
  };

  handleErrorOpenDialog = (error) => {
    this.setState({
      error: error.message,
      displayError: true,
    });
  };

  handleErrorClose = () => {
    this.setState({
      error: null,
      displayError: false,
    });
  };

  handleCloseDIGDownDialog = () => {
    this.setState({
      openDIGDownDialog: false,
      errorMessageDIGDown: null,
    });
  };

  handleOrganizationIsNotSpamChange = (id, isNotSpam) => {
    return new OrganizationStore(id)
      .updateIsNotSpam(isNotSpam)
      .then(this.handleOrganizationsChange)
      .catch((error) =>
        this.setState({
          error: error.message,
          displayError: true,
        })
      );
  };

  handleOrganizationInboxChange = (organization) => {
    const organizations = organizationsStore.replaceModel(organization);
    const filteredOrgs = this.filterOrganizationsForInbox(organizations);

    this.setState({
      organizations: filteredOrgs,
      total: filteredOrgs.length,
      loadingMore: false,
      loading: false,
    });
    this.props.updateOrgsNumber(filteredOrgs.length);
  };

  addToInbox = (organization, assignedTo, notes, reason) => {
    const {
      profile: { email },
    } = this.props;

    const store = new OrganizationStore(organization.id);

    this.setState(
      {
        isUpdatingInbox: true,
        inboxAssignedTo: null,
        inboxOrganization: null,
      },
      () => {
        store
          .addToInbox(assignedTo, notes, reason)
          .then((organization) => {
            this.handleOrganizationInboxChange(organization);
            this.setState({ isUpdatingInbox: false });

            if (assignedTo === email) {
              this.emitInboxUpdateEvent(true);
            }
          })
          .catch((error) => {
            this.setState({
              isUpdatingInbox: false,
              error: error.message,
              displayError: true,
            });
          });
      }
    );
  };

  removeFromInbox = (organization, assignedTo) => {
    const {
      profile: { email },
    } = this.props;

    const store = new OrganizationStore(organization.id);

    this.setState({ isUpdatingInbox: true }, () => {
      store
        .removeFromInbox(organization, assignedTo)
        .then((organization) => {
          this.handleOrganizationInboxChange(organization);
          this.setState({ isUpdatingInbox: false });
          if (assignedTo === email) {
            this.emitInboxUpdateEvent(false);
          }
        })
        .catch((error) => {
          this.setState({
            isUpdatingInbox: false,
            error: error.message,
            displayError: true,
          });
        });
    });
  };

  showInboxModal = (organization, assignedTo) => {
    this.setState({
      inboxOrganization: organization,
      inboxAssignedTo: assignedTo,
    });
  };

  closeInboxModal = () => {
    this.setState({ inboxOrganization: null, inboxAssignedTo: null });
  };

  emitInboxUpdateEvent = (detail) => {
    const inboxUpdateEvent = new CustomEvent("inbox_update", { detail });
    window.dispatchEvent(inboxUpdateEvent);
  };

  render() {
    const {
      pathname = "",
      searchParams = {},
      filterSource,
      profile,
      client,
      route: { path: source = "" } = {},
    } = this.props;
    const {
      organizations = [],
      loading,
      isUpdatingInbox,
      userHasFullAccess = false,
      userRoles = [],
      feedbackCategories = [],
      structuredReasons = {},
      structuredFeedbackTypes = {},
      workflowSyncs = {},
    } = this.state;
    const itemHeight = this.computeItemHeight();

    if (loading) {
      return <Loading fixed={true} />;
    }

    const errorDialogActions = [
      <FlatButton
        key="close"
        label="Close"
        primary={true}
        onClick={this.handleErrorClose}
      />,
    ];

    const renderErrorDialog = (errorMessage) => {
      return (
        <DialogWrapper
          actions={errorDialogActions}
          modal={false}
          open={this.state.displayError}
          onRequestClose={this.handleErrorClose}
        >
          {errorMessage}
        </DialogWrapper>
      );
    };

    if (organizations.length === 0 && searchParams.inbox) {
      return <CompletedEvaluation />;
    }

    const organizationsStoreParams = {
      cache: organizationsStore.getAll().map(({ id = "", name = "" }) => ({
        name,
        id,
      })),
      searchParams: organizationsStore.searchParams,
      offset: this.offset,
    };

    return (
      <div className="organizations-view">
        {renderErrorDialog(this.state.error)}

        <div
          ref={(el) => (this._el = el)}
          data-organizations-num={organizations.length}
        >
          {organizations.map((organization, index) => {
            return (
              <PreviewOrganization
                key={organization.id}
                organization={organization}
                profile={profile}
                client={client}
                addToInbox={this.addToInbox}
                removeFromInbox={this.removeFromInbox}
                showInboxModal={this.showInboxModal}
                organizationsStoreParams={organizationsStoreParams}
                filterSource={filterSource}
                returnUrl={getReturnUrl(pathname, searchParams)}
                isUpdatingInbox={isUpdatingInbox}
                showPredictions={false}
                activeInboxUser={searchParams.user_inbox || ""}
                handleErrorOpen={this.handleErrorOpen}
                userHasFullAccess={userHasFullAccess}
                userRoles={userRoles}
                handleErrorOpenDialog={this.handleErrorOpenDialog}
                reloadOrganization={this.reloadOrganization}
                source={source}
                feedbackCategories={feedbackCategories}
                structuredReasons={structuredReasons}
                handleOrganizationChange={this.handleOrganizationChange}
                openStructuredFeedback={(type) =>
                  this.handleOpenStructuredFeedback(type, organization.id)
                }
                closeStructuredFeedback={this.handleCloseStructuredFeedback}
                structuredFeedbackType={
                  structuredFeedbackTypes[organization.id] || false
                }
                openWorkflowSync={() =>
                  this.handleOpenWorkflowSync(organization.id)
                }
                closeWorkflowSync={this.handleCloseWorkflowSync}
              />
            );
          })}
        </div>

        <If condition={this.state.loadingMore}>
          <RefreshIndicator
            size={30}
            loadingColor="#337ab7"
            status="loading"
            left={0}
            top={0}
            style={{ position: "relative", margin: "24px auto" }}
          />
        </If>

        <InboxModal
          assignedTo={this.state.inboxAssignedTo}
          organization={this.state.inboxOrganization}
          handleClose={this.closeInboxModal}
          handleSubmit={this.addToInbox}
        />

        <DIGDownDialog
          open={this.state.openDIGDownDialog}
          errorMessage={this.state.errorMessageDIGDown}
          handleClose={this.handleCloseDIGDownDialog}
        />
      </div>
    );
  }
}

Organizations.propTypes = {
  searchParams: PropTypes.object.isRequired,
  changeUrl: PropTypes.func.isRequired,
};

Organizations.childContextTypes = {
  muiTheme: PropTypes.object.isRequired,
};
