import React from "react";
import PropTypes from "prop-types";
import IncompleteStore from "../../storage/IncompleteStore";
import moment from "moment";
import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import LinearProgress from "material-ui/LinearProgress";
import TextField from "material-ui/TextField";
import DialogWrapper from "../components/DialogWrapper";

export class ExportNewBatch extends React.Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    existingBatchNames: PropTypes.array.isRequired,
  };

  state = {
    batchName: null,
    limit: 500,
    generating: false,
    nameError: null,
    downloadError: null,
  };

  incompleteStore = new IncompleteStore();

  componentDidMount() {
    this.updateName(moment().format("YYYY-MM-DD"));
  }

  exportNewBatch = () => {
    const { batchName, limit, downloadError, nameError } = this.state,
      { close } = this.props;
    if (nameError) {
      return;
    }

    this.incompleteStore
      .exportIncomplete(batchName, limit)
      .then(() => {
        this.setState({ generating: true });
        close();
      })
      .catch((error) => {
        console.error(error);
        this.setState({ generating: false, errors: { download: error } });
      });
  };

  updateName = (name) => {
    const { existingBatchNames = [] } = this.props;

    let nameError = null;
    if (!name) {
      nameError = "You must provide a batch name";
    } else if (name.length < 10 || !moment(name, "YYYY-MM-DD")) {
      nameError = "Name must start with a date";
    } else if (existingBatchNames.indexOf(name) > -1) {
      nameError = "Batch name already exists";
    }

    this.setState({ batchName: name, nameError });
  };

  render() {
    const { close } = this.props,
      { batchName, limit, generating, nameError, downloadError } = this.state;

    return (
      <DialogWrapper
        title={generating ? "Generating..." : "Export a new batch"}
        actions={
          generating
            ? [
                <RaisedButton
                  key="close"
                  label="Close"
                  primary={false}
                  onClick={close}
                  style={{ marginRight: "12px" }}
                  disabled={false}
                />,
              ]
            : [
                <RaisedButton
                  key="cancel"
                  label="Cancel"
                  primary={false}
                  onClick={close}
                  style={{ marginRight: "12px" }}
                  disabled={false}
                />,
                <RaisedButton
                  key="export"
                  label="Export"
                  primary={true}
                  onClick={this.exportNewBatch}
                  style={{ marginRight: "12px" }}
                  disabled={false}
                />,
              ]
        }
        modal={true}
        className="quick-add"
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        repositionOnUpdate={true}
        open={true}
      >
        <Choose>
          <When condition={generating}>
            <p>This will take a while. Please refresh in 10mins.</p>
            <LinearProgress />
          </When>

          <When condition={downloadError}>
            <p>Error while downloading: {downloadError}</p>
          </When>

          <Otherwise>
            <form className="form" onSubmit={this.exportNewBatch}>
              <div className="organization-create-fields">
                <TextField
                  id="name"
                  name="name"
                  value={batchName}
                  floatingLabelText="Batch Name (required)"
                  floatingLabelFixed={true}
                  onChange={(e) => this.updateName(e.target.value)}
                  errorText={nameError}
                  required={true}
                  fullWidth={false}
                />
                <TextField
                  id="limit"
                  name="limit"
                  value={limit}
                  type="number"
                  floatingLabelText="Limit (required)"
                  floatingLabelFixed={true}
                  onChange={(value) => this.setState({ limit: value })}
                  required={true}
                  fullWidth={false}
                />
              </div>
            </form>
          </Otherwise>
        </Choose>
      </DialogWrapper>
    );
  }
}
