import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import Autosuggest from "react-autosuggest";

const getSuggestionValue = ({ key }) => key;

const renderSuggestion = ({ title }) => (
  <div className="structured-content-suggestion">{title}</div>
);

export default class SelectStructuredContent extends React.Component {
  static propTypes = {
    content: PropTypes.array.isRequired,
    cancel: PropTypes.func.isRequired,
    selected: PropTypes.func.isRequired,
  };

  state = {
    value: "",
    suggestions: [],
  };

  handleFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  getSuggestions = (value) => {
    const { content = [] } = this.props;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return content.reduce((suggestions, { key, question, type }) => {
      if (
        type !== "header" &&
        (key.toLowerCase().indexOf(inputValue) > -1 ||
          question.toLowerCase().indexOf(inputValue) > -1 ||
          inputLength === 0)
      ) {
        suggestions.push({
          key,
          title: question,
        });
      }
      return suggestions;
    }, []);
  };

  handleClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleSuggestionSelected = (event, { suggestionValue }) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
    this.props.selected(suggestionValue);
  };

  handleChange = (value = "") => {
    this.setState({
      value,
    });
  };

  handleKeyDown = (event) => {
    if (
      (event.nativeEvent.key === "Backspace" && this.state.value === "") ||
      event.nativeEvent.key === "Escape"
    ) {
      event.nativeEvent.preventDefault();
      this.props.cancel();
    }
  };

  handleBlur = () => {
    this.props.cancel();
  };

  render() {
    const { value = "", suggestions = [] } = this.state;
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.handleFetchRequested}
        onSuggestionsClearRequested={this.handleClearRequested}
        onSuggestionSelected={this.handleSuggestionSelected}
        onSuggestionHighlighted={({ suggestion }) => {
          this.handleChange(suggestion !== null ? suggestion.key : "");
        }}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        alwaysRenderSuggestions={true}
        inputProps={{
          value,
          onChange: (event, { newValue }) => {
            this.handleChange(newValue);
          },
          onKeyDown: this.handleKeyDown,
          onBlur: this.handleBlur,
          id: "select-structured-content-input-field",
          name: "select-structured-content-input-field",
        }}
        theme={{
          container: "autosuggest-container",
          containerOpen: "autosuggest-container-open",
          input: "autosuggest-input",
          inputOpen: "autosuggest-input-open",
          inputFocused: "autosuggest-input-focused",
          suggestionsContainer: "autosuggest-suggestions-container",
          suggestionsContainerOpen: "autosuggest-suggestions-container-open",
          suggestionsList: "autosuggest-suggestions-list",
          suggestion: "autosuggest-suggestion",
          suggestionFirst: "autosuggest-suggestion-first",
          suggestionHighlighted: "autosuggest-suggestion-highlighted",
          sectionContainer: "autosuggest-section-container",
          sectionContainerFirst: "autosuggest-section-container-first",
          sectionTitle: "autosuggest-section-title",
        }}
      />
    );
  }
}
