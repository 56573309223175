export default {
  backend_developer: "Backend developer",
  business_developer: "Business developer",
  ceo: "CEO",
  cfo: "CFO",
  cmo: "CMO",
  coo: "COO",
  cpo: "CPO",
  cto: "CTO",
  data_scientist: "Data scientist",
  director: "Director",
  frontend_developer: "Frontend developer",
  fullstack_developer: "Fullstack developer",
  head_of_marketing: "Head of marketing",
  head_of_sales: "Head of sales",
  marketing_manager: "Marketing manager",
  marketing_specialist: "Marketing specialist",
  president: "President",
  product_manager: "Product manager",
  product_owner: "Product owner",
  sales_manager: "Sales manager",
  software_engineer: "Software engineer",
  ux_designer: "UX designer",
  vp_of_engineering: "VP of engineering",
  vp_of_growth: "VP of growth",
  vp_of_marketing: "VP of marketing",
  vp_of_operations: "VP of operations",
  vp_of_sales: "VP of sales",
  other: "Other",
};
