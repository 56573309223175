import React from "react";
import { withRouter } from "react-router-v4";
import { capitalizeString } from "../../../lib/helpers";

class PortfolioTagLink extends React.Component {
  parseFilters = (filters) => {
    let filtersText = "";
    for (let key in filters) {
      if (filtersText !== "") {
        filtersText += "&";
      }
      filtersText += key + "=" + filters[key];
    }
    return filtersText;
  };

  onCompaniesSelection = (filters) => {
    const {
      params: { id },
      updateSelectedTab,
    } = this.props;
    const filtersText = this.parseFilters(filters);
    updateSelectedTab("portfolio");
    this.props.router.push({
      pathname: `/investors/${id}/portfolio`,
      search: `?${filtersText}`,
    });
  };

  getTagClassNames = (confidence, highlighted) => {
    const classNames = ["tag"];
    if (confidence > 0.6) {
      classNames.push("high");
    }
    if (highlighted) {
      classNames.push("highlighted");
    }
    return classNames.join(" ");
  };

  render() {
    const { text, confidence, highlighted, filters, seriesKey } = this.props;
    const classNames = this.getTagClassNames(confidence, highlighted);
    return (
      <a
        style={{ cursor: "pointer" }}
        className={classNames}
        key={seriesKey + text + "link"}
        onClick={() => this.onCompaniesSelection(filters)}
      >
        {capitalizeString(text)}
      </a>
    );
  }
}

export default withRouter(PortfolioTagLink);
