import React from "react";
import PropTypes from "prop-types";
import EditOrganizationHeader from "./EditOrganizationHeader";
import InvestorPicker from "../../investor/InvestorPicker";
import LinearProgress from "material-ui/LinearProgress";
import { List, ListItem } from "material-ui/List";
import Avatar from "material-ui/Avatar";
import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import DialogWrapper from "../../components/DialogWrapper";
const dialogStyles = {
  dialogRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
  },
  dialogContent: {
    position: "relative",
    width: "80vw",
    maxWidth: "1024px",
    transform: "",
  },
  dialogBody: {
    paddingBottom: 0,
  },
};

export default class AddInvestor extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleAddInvestor: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  state = {
    investor: {},
  };

  handleNewInvestor = (investor) => {
    this.setState({ investor });
  };

  handleCancel = () => {
    this.setState({
      investor: {},
    });
    this.props.handleCancel();
  };

  handleAddInvestor = () => {
    this.props.handleAddInvestor(this.state.investor);
  };

  render() {
    const { investor: { id: newInvestorId } = {} } = this.state,
      {
        organization: { name: organizationName },
        open,
      } = this.props;
    return (
      <DialogWrapper
        title={`Add investor to ${organizationName}`}
        bodyClassName="edit-person-dialog-root"
        actionsContainerClassName="actions-container"
        modal={false}
        open={open}
        onRequestClose={this.handleCancel}
        contentStyle={dialogStyles.dialogContent}
        bodyStyle={dialogStyles.dialogBody}
        style={dialogStyles.dialogRoot}
        repositionOnUpdate={false}
        actions={[
          <RaisedButton
            className="assign-action cancel"
            label="Cancel"
            style={{
              marginRight: "10px",
            }}
            onClick={this.handleCancel}
          />,
          <RaisedButton
            label="Add"
            className="assign-action submit"
            backgroundColor="#5DAB49"
            labelStyle={{
              color: "white",
            }}
            disabled={!newInvestorId}
            onClick={() => {
              this.handleAddInvestor();
            }}
          />,
        ]}
      >
        <div className="add-view">
          <InvestorPicker>
            {({ loadingSuggestions, investors }) => (
              <Choose>
                <When condition={loadingSuggestions}>
                  <LinearProgress />
                </When>

                <Otherwise>
                  <List>
                    {investors.map((investor) => (
                      <ListItem
                        key={investor.id}
                        primaryText={`${investor.name}`}
                        secondaryText={`id: ${investor.id}`}
                        leftAvatar={<Avatar src={investor.image_url} />}
                        selected={newInvestorId === investor.id}
                        style={
                          newInvestorId === investor.id
                            ? { backgroundColor: "grey" }
                            : {}
                        }
                        onClick={() => this.handleNewInvestor(investor)}
                      />
                    ))}
                  </List>
                </Otherwise>
              </Choose>
            )}
          </InvestorPicker>
        </div>
      </DialogWrapper>
    );
  }
}
