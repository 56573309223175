import _import from "../../images/import100x100.png";
import a10x_group from "../../images/a10x_group100x100.png";
import a16z from "../../images/a16z100x100.png";
import a1kubator from "../../images/a1kubator100x100.png";
import a1kx from "../../images/a1kx100x100.png";
import a212_vc from "../../images/a212_vc100x100.png";
import a360capital from "../../images/a360capital100x100.png";
import a365_fintech from "../../images/a365_fintech100x100.png";
import a3ts_capital from "../../images/a3ts_capital100x100.png";
import a42cap from "../../images/a42cap100x100.png";
import a6am from "../../images/a6am100x100.png";
import a7percent from "../../images/a7percent100x100.png";
import a83north from "../../images/a83north100x100.png";
import a8capita from "../../images/a8capita100x100.png";
import absl_startup_challenge from "../../images/absl_startup_challenge100x100.png";
import accel from "../../images/accel100x100.png";
import accelerace from "../../images/accelerace100x100.png";
import accenture_ventures from "../../images/accenture_ventures100x100.png";
import acme from "../../images/acme100x100.png";
import ada_ventures from "../../images/ada_ventures100x100.png";
import adara_ventures from "../../images/adara_ventures100x100.png";
import aerospace_xelerated from "../../images/aerospace_xelerated100x100.png";
import agoranov from "../../images/agoranov100x100.png";
import ahren from "../../images/ahren100x100.png";
import albion from "../../images/albion100x100.png";
import aligo from "../../images/aligo100x100.png";
import all_startups from "../../images/all_startups100x100.png";
import allegion from "../../images/allegion100x100.png";
import almaz_capital from "../../images/almaz_capital100x100.png";
import almi_invest from "../../images/almi_invest100x100.png";
import alven from "../../images/alven100x100.png";
import am_ventures from "../../images/am_ventures100x100.png";
import amadeus_capital from "../../images/amadeus_capital100x100.png";
import amplify_la from "../../images/amplify_la100x100.png";
import angel_academe from "../../images/angel_academe100x100.png";
import angellist from "../../images/angellist100x100.png";
import animoca_brands from "../../images/animoca_brands100x100.png";
import anthemis from "../../images/anthemis100x100.png";
import antler from "../../images/antler100x100.png";
import apollo_io from "../../images/apollo_io100x100.png";
import apx from "../../images/apx100x100.png";
import armilar from "../../images/armilar100x100.png";
import asabys from "../../images/asabys100x100.png";
import atlantic_bridge from "../../images/atlantic_bridge100x100.png";
import atlanticlabs from "../../images/atlanticlabs100x100.png";
import atomico from "../../images/atomico100x100.png";
import axa_vp from "../../images/axa_vp100x100.png";
import azertyjobs from "../../images/azertyjobs100x100.png";
import b10 from "../../images/b10100x100.png";
import bacb from "../../images/bacb100x100.png";
import backed from "../../images/backed100x100.png";
import bain_capital_ventures from "../../images/bain_capital_ventures100x100.png";
import balderton from "../../images/balderton100x100.png";
import barcelonatechcity from "../../images/barcelonatechcity100x100.png";
import bcn_startup_map from "../../images/bcn_startup_map100x100.png";
import bcnstartupmap from "../../images/bcnstartupmap100x100.png";
import berlin_startup_jobs from "../../images/berlin_startup_jobs100x100.png";
import besthorizon from "../../images/besthorizon100x100.png";
import beta_list from "../../images/beta_list100x100.png";
import black_founders_fund from "../../images/black_founders_fund100x100.png";
import black_pearls from "../../images/black_pearls100x100.png";
import blackpeak_capital from "../../images/blackpeak_capital100x100.png";
import bloccelerate from "../../images/bloccelerate100x100.png";
import blockchain_capital from "../../images/blockchain_capital100x100.png";
import blockchainstartups from "../../images/blockchainstartups100x100.png";
import blockstart from "../../images/blockstart100x100.png";
import blockwall from "../../images/blockwall100x100.png";
import bonnier_capital from "../../images/bonnier_capital100x100.png";
import bonsai_partners from "../../images/bonsai_partners100x100.png";
import born2grow from "../../images/born2grow100x100.png";
import breega from "../../images/breega100x100.png";
import breyer_capital from "../../images/breyer_capital100x100.png";
import brightcap_vc from "../../images/brightcap_vc100x100.png";
import brighteyes from "../../images/brighteyes100x100.png";
import brunnur_ventures from "../../images/brunnur_ventures100x100.png";
import btov from "../../images/btov100x100.png";
import bvalue from "../../images/bvalue100x100.png";
import bvp from "../../images/bvp100x100.png";
import byfounders from "../../images/byfounders100x100.png";
import caixa_capital_risc from "../../images/caixa_capital_risc100x100.png";
import cap_horn from "../../images/cap_horn100x100.png";
import caphub_io from "../../images/caphub_io100x100.png";
import capnamicventures from "../../images/capnamicventures100x100.png";
import capterra from "../../images/capterra100x100.png";
import cavalry_vc from "../../images/cavalry_vc100x100.png";
import cg_partners from "../../images/cg_partners100x100.png";
import chalmers_ventures from "../../images/chalmers_ventures100x100.png";
import change_ventures from "../../images/change_ventures100x100.png";
import charlotte_street_capital from "../../images/charlotte_street_capital100x100.png";
import cherry_vc from "../../images/cherry_vc100x100.png";
import cic from "../../images/cic100x100.png";
import clearbit from "../../images/clearbit100x100.png";
import coinix from "../../images/coinix100x100.png";
import cointelligence from "../../images/cointelligence100x100.png";
import collision from "../../images/collision100x100.png";
import concentric from "../../images/concentric100x100.png";
import connect_ventures from "../../images/connect_ventures100x100.png";
import conorfund from "../../images/conorfund100x100.png";
import contrarian_ventures from "../../images/contrarian_ventures100x100.png";
import coparion from "../../images/coparion100x100.png";
import creandum from "../../images/creandum100x100.png";
import creathor from "../../images/creathor100x100.png";
import creative_destruction_lab from "../../images/creative_destruction_lab100x100.png";
import credoventures from "../../images/credoventures100x100.png";
import crowdsurfer from "../../images/crowdsurfer100x100.png";
import crunchbase from "../../images/crunchbase100x100.png";
import cryptoslate from "../../images/cryptoslate100x100.png";
import csv from "../../images/csv100x100.png";
import cube_five from "../../images/cube_five100x100.png";
import cylonlab from "../../images/cylonlab100x100.png";
import czechstartups from "../../images/czechstartups100x100.png";
import daphni from "../../images/daphni100x100.png";
import day_one_capital from "../../images/day_one_capital100x100.png";
import dealroom from "../../images/dealroom100x100.png";
import dealroom_transactions from "../../images/dealroom_transactions100x100.png";
import deeptechforgood from "../../images/deeptechforgood100x100.png";
import defiance_capital from "../../images/defiance_capital100x100.png";
import deltapartners from "../../images/deltapartners100x100.png";
import der_brutkasten from "../../images/der_brutkasten100x100.png";
import dgc from "../../images/dgc100x100.png";
import digital_grenoble from "../../images/digital_grenoble100x100.png";
import digital_health_london from "../../images/digital_health_london100x100.png";
import djakne from "../../images/djakne100x100.png";
import dncapital from "../../images/dncapital100x100.png";
import downing from "../../images/downing100x100.png";
import draperesprit from "../../images/draperesprit100x100.png";
import drapergorenholm from "../../images/drapergorenholm100x100.png";
import dublin_tech_summit from "../../images/dublin_tech_summit100x100.png";
import dutch_founders_fund from "../../images/dutch_founders_fund100x100.png";
import dutchstartups from "../../images/dutchstartups100x100.png";
import e27 from "../../images/e27100x100.png";
import earlybird from "../../images/earlybird100x100.png";
import edp_ventures from "../../images/edp_ventures100x100.png";
import eipp from "../../images/eipp100x100.png";
import elaia from "../../images/elaia100x100.png";
import elevator_ventures from "../../images/elevator_ventures100x100.png";
import eleven from "../../images/eleven100x100.png";
import email_hunter from "../../images/email_hunter100x100.png";
import emerald_ventures from "../../images/emerald_ventures100x100.png";
import endeit from "../../images/endeit100x100.png";
import enhjorning from "../../images/enhjorning100x100.png";
import enterprise_ethereum_alliance from "../../images/enterprise_ethereum_alliance100x100.png";
import entree from "../../images/entree100x100.png";
import entrepreneur_first from "../../images/entrepreneur_first100x100.png";
import episode1 from "../../images/episode1100x100.png";
import erasmus_centre from "../../images/erasmus_centre100x100.png";
import estonia_e_residency from "../../images/estonia_e_residency100x100.png";
import estonianmafia from "../../images/estonianmafia100x100.png";
import eu_startups from "../../images/eu_startups100x100.png";
import eurazeo from "../../images/eurazeo100x100.png";
import eventmanagerblog from "../../images/eventmanagerblog100x100.png";
import eventures from "../../images/eventures100x100.png";
import experior_venture_fund from "../../images/experior_venture_fund100x100.png";
import f10 from "../../images/f10100x100.png";
import f6s from "../../images/f6s100x100.png";
import faber from "../../images/faber100x100.png";
import fairpoint_capital from "../../images/fairpoint_capital100x100.png";
import fantastic_beasts from "../../images/fantastic_beasts100x100.png";
import fasttrackmalmo from "../../images/fasttrackmalmo100x100.png";
import felix from "../../images/felix100x100.png";
import female_founders_grow_f from "../../images/female_founders_grow_f100x100.png";
import ffoh from "../../images/ffoh100x100.png";
import finch_capital from "../../images/finch_capital100x100.png";
import finlab from "../../images/finlab100x100.png";
import finleap from "../../images/finleap100x100.png";
import finsj from "../../images/finsj100x100.png";
import finsmes from "../../images/finsmes100x100.png";
import fintastico from "../../images/fintastico100x100.png";
import firestartr from "../../images/firestartr100x100.png";
import firstminute_capital from "../../images/firstminute_capital100x100.png";
import flashpoint_vc from "../../images/flashpoint_vc100x100.png";
import fly_ventures from "../../images/fly_ventures100x100.png";
import fomcap from "../../images/fomcap100x100.png";
import forwardpartners from "../../images/forwardpartners100x100.png";
import freigeist from "../../images/freigeist100x100.png";
import french_partners from "../../images/french_partners100x100.png";
import frontline_ventures from "../../images/frontline_ventures100x100.png";
import frst from "../../images/frst100x100.png";
import ft_1000 from "../../images/ft_1000100x100.png";
import funderbeam from "../../images/funderbeam100x100.png";
import funding from "../../images/funding100x100.png";
import general_catalyst from "../../images/general_catalyst100x100.png";
import get_latka from "../../images/get_latka100x100.png";
import getinthering from "../../images/getinthering100x100.png";
import giant_ventures from "../../images/giant_ventures100x100.png";
import github from "../../images/github100x100.png";
import github_trending from "../../images/github_trending100x100.png";
import global_founders_capital from "../../images/global_founders_capital100x100.png";
import googlecse from "../../images/googlecse100x100.png";
import granatus_ventures from "../../images/granatus_ventures100x100.png";
import greenfield_one from "../../images/greenfield_one100x100.png";
import gruenderszene from "../../images/gruenderszene100x100.png";
import gullspang from "../../images/gullspang100x100.png";
import haatch from "../../images/haatch100x100.png";
import hambro_perks from "../../images/hambro_perks100x100.png";
import hamburg_startups from "../../images/hamburg_startups100x100.png";
import hardwareclub from "../../images/hardwareclub100x100.png";
import hashed from "../../images/hashed100x100.png";
import headline from "../../images/headline100x100.png";
import heartcore from "../../images/heartcore100x100.png";
import henq from "../../images/henq100x100.png";
import hexa from "../../images/hexa100x100.png";
import high_tech_gruenderfonds from "../../images/high_tech_gruenderfonds100x100.png";
import highland_europe from "../../images/highland_europe100x100.png";
import hinrichs from "../../images/hinrichs100x100.png";
import hired from "../../images/hired100x100.png";
import hiventures from "../../images/hiventures100x100.png";
import hoxton_ventures from "../../images/hoxton_ventures100x100.png";
import hv_capital from "../../images/hv_capital100x100.png";
import hyperledger_foundation from "../../images/hyperledger_foundation100x100.png";
import i2bf from "../../images/i2bf100x100.png";
import ibb_bet from "../../images/ibb_bet100x100.png";
import id4 from "../../images/id4100x100.png";
import illuminate_financial from "../../images/illuminate_financial100x100.png";
import impact_startup from "../../images/impact_startup100x100.png";
import imperialenterpriselab from "../../images/imperialenterpriselab100x100.png";
import inc_5000_eu from "../../images/inc_5000_eu100x100.png";
import index_ventures from "../../images/index_ventures100x100.png";
import industrifonden from "../../images/industrifonden100x100.png";
import industryinvestment from "../../images/industryinvestment100x100.png";
import inflection from "../../images/inflection100x100.png";
import initial_capital from "../../images/initial_capital100x100.png";
import innovation_dock from "../../images/innovation_dock100x100.png";
import innovestor from "../../images/innovestor100x100.png";
import inreach from "../../images/inreach100x100.png";
import inventure from "../../images/inventure100x100.png";
import inveready from "../../images/inveready100x100.png";
import investiere from "../../images/investiere100x100.png";
import iq_capital from "../../images/iq_capital100x100.png";
import isai_ventures from "../../images/isai_ventures100x100.png";
import isomer_capital from "../../images/isomer_capital100x100.png";
import j_and_t_ventures from "../../images/j_and_t_ventures100x100.png";
import jaimelesstartups from "../../images/jaimelesstartups100x100.png";
import jme_ventures from "../../images/jme_ventures100x100.png";
import jobfluent from "../../images/jobfluent100x100.png";
import jobs_stationf from "../../images/jobs_stationf100x100.png";
import join_capital from "../../images/join_capital100x100.png";
import karma_vc from "../../images/karma_vc100x100.png";
import kaya from "../../images/kaya100x100.png";
import kerala_ventures from "../../images/kerala_ventures100x100.png";
import kfund from "../../images/kfund100x100.png";
import kima_ventures from "../../images/kima_ventures100x100.png";
import korelya_capital from "../../images/korelya_capital100x100.png";
import la_famiglia from "../../images/la_famiglia100x100.png";
import labs_of_latvia from "../../images/labs_of_latvia100x100.png";
import lafrenchtech_visas from "../../images/lafrenchtech_visas100x100.png";
import lakestar from "../../images/lakestar100x100.png";
import landingfolio from "../../images/landingfolio100x100.png";
import lanzame from "../../images/lanzame100x100.png";
import launched from "../../images/launched100x100.png";
import launchingnext from "../../images/launchingnext100x100.png";
import launchpoollabs from "../../images/launchpoollabs100x100.png";
import launchub from "../../images/launchub100x100.png";
import lea_partners from "../../images/lea_partners100x100.png";
import leadblock_partners from "../../images/leadblock_partners100x100.png";
import lemonade_stand from "../../images/lemonade_stand100x100.png";
import les_pepites_tech from "../../images/les_pepites_tech100x100.png";
import lhv from "../../images/lhv100x100.png";
import linkedin from "../../images/linkedin100x100.png";
import linkedinweb from "../../images/linkedinweb100x100.png";
import lisbon_challenge from "../../images/lisbon_challenge100x100.png";
import loyal_vc from "../../images/loyal_vc100x100.png";
import lunar_ventures from "../../images/lunar_ventures100x100.png";
import lupa from "../../images/lupa100x100.png";
import lventure from "../../images/lventure100x100.png";
import lvp from "../../images/lvp100x100.png";
import magma_vc from "../../images/magma_vc100x100.png";
import mainport_innovation_fund from "../../images/mainport_innovation_fund100x100.png";
import mairdumont_ventures from "../../images/mairdumont_ventures100x100.png";
import maki_vc from "../../images/maki_vc100x100.png";
import mam_startup from "../../images/mam_startup100x100.png";
import mangrove from "../../images/mangrove100x100.png";
import manta_ray from "../../images/manta_ray100x100.png";
import marathon_vc from "../../images/marathon_vc100x100.png";
import maven_11 from "../../images/maven_11100x100.png";
import maze_x from "../../images/maze_x100x100.png";
import merian_ventures from "../../images/merian_ventures100x100.png";
import mmc_ventures from "../../images/mmc_ventures100x100.png";
import moeller_ventures from "../../images/moeller_ventures100x100.png";
import momentum from "../../images/momentum100x100.png";
import monacotech from "../../images/monacotech100x100.png";
import moonfire from "../../images/moonfire100x100.png";
import mountain_partners from "../../images/mountain_partners100x100.png";
import mouro_capital from "../../images/mouro_capital100x100.png";
import msm_vc from "../../images/msm_vc100x100.png";
import multichain from "../../images/multichain100x100.png";
import multicoin_capital from "../../images/multicoin_capital100x100.png";
import munich_startup from "../../images/munich_startup100x100.png";
import national_business_awards_uk from "../../images/national_business_awards_uk100x100.png";
import nautacapital from "../../images/nautacapital100x100.png";
import neulogy from "../../images/neulogy100x100.png";
import neveq from "../../images/neveq100x100.png";
import newfund from "../../images/newfund100x100.png";
import next47 from "../../images/next47100x100.png";
import ngp_capital from "../../images/ngp_capital100x100.png";
import no_fluff_jobs from "../../images/no_fluff_jobs100x100.png";
import nordic_ninja from "../../images/nordic_ninja100x100.png";
import nordictechlist from "../../images/nordictechlist100x100.png";
import northzone from "../../images/northzone100x100.png";
import notion_capital from "../../images/notion_capital100x100.png";
import nova from "../../images/nova100x100.png";
import numa_hub from "../../images/numa_hub100x100.png";
import octopus_ventures from "../../images/octopus_ventures100x100.png";
import oktogon_vc from "../../images/oktogon_vc100x100.png";
import oltreventure from "../../images/oltreventure100x100.png";
import omnescapital from "../../images/omnescapital100x100.png";
import oneragtime from "../../images/oneragtime100x100.png";
import open_ocean from "../../images/open_ocean100x100.png";
import openfuture from "../../images/openfuture100x100.png";
import opengraph from "../../images/opengraph100x100.png";
import openwebcollective from "../../images/openwebcollective100x100.png";
import otb_ventures from "../../images/otb_ventures100x100.png";
import outlier_ventures from "../../images/outlier_ventures100x100.png";
import outward from "../../images/outward100x100.png";
import oxford_capital from "../../images/oxford_capital100x100.png";
import oxyleads from "../../images/oxyleads100x100.png";
import p101 from "../../images/p101100x100.png";
import paladin from "../../images/paladin100x100.png";
import pando_ventures from "../../images/pando_ventures100x100.png";
import pantera_capital from "../../images/pantera_capital100x100.png";
import paradigm from "../../images/paradigm100x100.png";
import parkwalk_advisors from "../../images/parkwalk_advisors100x100.png";
import partech from "../../images/partech100x100.png";
import passion from "../../images/passion100x100.png";
import paua_ventures from "../../images/paua_ventures100x100.png";
import pentech from "../../images/pentech100x100.png";
import picus_capital from "../../images/picus_capital100x100.png";
import pilabs from "../../images/pilabs100x100.png";
import pinksaltventures from "../../images/pinksaltventures100x100.png";
import pithia from "../../images/pithia100x100.png";
import pj_tech_catalyst from "../../images/pj_tech_catalyst100x100.png";
import playfair from "../../images/playfair100x100.png";
import plug_and_play from "../../images/plug_and_play100x100.png";
import polar_light_ventures from "../../images/polar_light_ventures100x100.png";
import polytech_ventures from "../../images/polytech_ventures100x100.png";
import portfolion from "../../images/portfolion100x100.png";
import portugalventures from "../../images/portugalventures100x100.png";
import postmake from "../../images/postmake100x100.png";
import predictleads from "../../images/predictleads100x100.png";
import predictleads_post from "../../images/predictleads_post100x100.png";
import presto_ventures from "../../images/presto_ventures100x100.png";
import producthunt from "../../images/producthunt100x100.png";
import project_a from "../../images/project_a100x100.png";
import promus_ventures from "../../images/promus_ventures100x100.png";
import propel from "../../images/propel100x100.png";
import proptech1 from "../../images/proptech1100x100.png";
import pyratzlabs from "../../images/pyratzlabs100x100.png";
import rbvc from "../../images/rbvc100x100.png";
import reaktorx from "../../images/reaktorx100x100.png";
import redalpine from "../../images/redalpine100x100.png";
import revo from "../../images/revo100x100.png";
import rga_iot from "../../images/rga_iot100x100.png";
import rhodium from "../../images/rhodium100x100.png";
import rockaway_capital from "../../images/rockaway_capital100x100.png";
import rockstart from "../../images/rockstart100x100.png";
import romanianstartups from "../../images/romanianstartups100x100.png";
import rtp_global from "../../images/rtp_global100x100.png";
import runacap from "../../images/runacap100x100.png";
import s_ubg_group from "../../images/s_ubg_group100x100.png";
import saas1000 from "../../images/saas1000100x100.png";
import samaipata from "../../images/samaipata100x100.png";
import samos from "../../images/samos100x100.png";
import sampo_accelerator from "../../images/sampo_accelerator100x100.png";
import sap_io from "../../images/sap_io100x100.png";
import schibsted_growth from "../../images/schibsted_growth100x100.png";
import script_capital from "../../images/script_capital100x100.png";
import seayaventures from "../../images/seayaventures100x100.png";
import seed_db from "../../images/seed_db100x100.png";
import seedandspeed from "../../images/seedandspeed100x100.png";
import seedcamp from "../../images/seedcamp100x100.png";
import seedcaptial_dk from "../../images/seedcaptial_dk100x100.png";
import sensortower from "../../images/sensortower100x100.png";
import serena from "../../images/serena100x100.png";
import seventure from "../../images/seventure100x100.png";
import shift_invest from "../../images/shift_invest100x100.png";
import signalfire from "../../images/signalfire100x100.png";
import signals_vc from "../../images/signals_vc100x100.png";
import simpact from "../../images/simpact100x100.png";
import smith_and_crown from "../../images/smith_and_crown100x100.png";
import smithandcrown from "../../images/smithandcrown100x100.png";
import sno from "../../images/sno100x100.png";
import sosv from "../../images/sosv100x100.png";
import south_central_ventures from "../../images/south_central_ventures100x100.png";
import speedinvest from "../../images/speedinvest100x100.png";
import speedupgroup from "../../images/speedupgroup100x100.png";
import spider_capital from "../../images/spider_capital100x100.png";
import spintop_ventures from "../../images/spintop_ventures100x100.png";
import start_green from "../../images/start_green100x100.png";
import startbase from "../../images/startbase100x100.png";
import starterstory from "../../images/starterstory100x100.png";
import startup88 from "../../images/startup88100x100.png";
import startup_brett from "../../images/startup_brett100x100.png";
import startup_chile from "../../images/startup_chile100x100.png";
import startup_estonia from "../../images/startup_estonia100x100.png";
import startup_grind_jobs from "../../images/startup_grind_jobs100x100.png";
import startup_hubs from "../../images/startup_hubs100x100.png";
import startup_inspire from "../../images/startup_inspire100x100.png";
import startup_lab from "../../images/startup_lab100x100.png";
import startup_navigator from "../../images/startup_navigator100x100.png";
import startup_ole from "../../images/startup_ole100x100.png";
import startup_only from "../../images/startup_only100x100.png";
import startup_pirate_jobs from "../../images/startup_pirate_jobs100x100.png";
import startup_poland from "../../images/startup_poland100x100.png";
import startup_tartu from "../../images/startup_tartu100x100.png";
import startup_wiseguys from "../../images/startup_wiseguys100x100.png";
import startup_xplore from "../../images/startup_xplore100x100.png";
import startupbase from "../../images/startupbase100x100.png";
import startupbenchmarks from "../../images/startupbenchmarks100x100.png";
import startupberlin from "../../images/startupberlin100x100.png";
import startupblink from "../../images/startupblink100x100.png";
import startupbuffer from "../../images/startupbuffer100x100.png";
import startupbutton from "../../images/startupbutton100x100.png";
import startupcollections from "../../images/startupcollections100x100.png";
import startupexplore from "../../images/startupexplore100x100.png";
import startupjobs_cz from "../../images/startupjobs_cz100x100.png";
import startuplift from "../../images/startuplift100x100.png";
import startuplisboa from "../../images/startuplisboa100x100.png";
import startupmatcher from "../../images/startupmatcher100x100.png";
import startupnorway from "../../images/startupnorway100x100.png";
import startuppoland from "../../images/startuppoland100x100.png";
import startupranking from "../../images/startupranking100x100.png";
import startuproulette from "../../images/startuproulette100x100.png";
import startups_be from "../../images/startups_be100x100.png";
import startups_co_uk from "../../images/startups_co_uk100x100.png";
import startups_hu from "../../images/startups_hu100x100.png";
import startups_list from "../../images/startups_list100x100.png";
import startupsbe from "../../images/startupsbe100x100.png";
import startupslist from "../../images/startupslist100x100.png";
import startus from "../../images/startus100x100.png";
import sting from "../../images/sting100x100.png";
import streak from "../../images/streak100x100.png";
import stride_vc from "../../images/stride_vc100x100.png";
import sup46 from "../../images/sup46100x100.png";
import superangel from "../../images/superangel100x100.png";
import target_global from "../../images/target_global100x100.png";
import tav from "../../images/tav100x100.png";
import team_global from "../../images/team_global100x100.png";
import tech_ireland from "../../images/tech_ireland100x100.png";
import tech_london from "../../images/tech_london100x100.png";
import tech_pitch_4pt5 from "../../images/tech_pitch_4pt5100x100.png";
import techlondon from "../../images/techlondon100x100.png";
import technopark_liechtenstein from "../../images/technopark_liechtenstein100x100.png";
import techstars_airtable from "../../images/techstars_airtable100x100.png";
import techstars_demoday_airtable from "../../images/techstars_demoday_airtable100x100.png";
import tera_vc from "../../images/tera_vc100x100.png";
import the_factory from "../../images/the_factory100x100.png";
import the_fse_group from "../../images/the_fse_group100x100.png";
import the_hub from "../../images/the_hub100x100.png";
import the_nordic_web from "../../images/the_nordic_web100x100.png";
import the_startup_pitch from "../../images/the_startup_pitch100x100.png";
import the_venture_city from "../../images/the_venture_city100x100.png";
import theblox from "../../images/theblox100x100.png";
import thehub from "../../images/thehub100x100.png";
import three_arrows_capital from "../../images/three_arrows_capital100x100.png";
import tioga_capital from "../../images/tioga_capital100x100.png";
import tlab from "../../images/tlab100x100.png";
import token_data from "../../images/token_data100x100.png";
import tokendata from "../../images/tokendata100x100.png";
import tomorrow_today from "../../images/tomorrow_today100x100.png";
import top100startup from "../../images/top100startup100x100.png";
import truegrowth from "../../images/truegrowth100x100.png";
import twitter from "../../images/twitter100x100.png";
import unicorn_hunt from "../../images/unicorn_hunt100x100.png";
import unicorns_lt from "../../images/unicorns_lt100x100.png";
import union_square_ventures from "../../images/union_square_ventures100x100.png";
import uniqa_ventures from "../../images/uniqa_ventures100x100.png";
import unusual from "../../images/unusual100x100.png";
import uvc from "../../images/uvc100x100.png";
import v2funding from "../../images/v2funding100x100.png";
import vcee_startups from "../../images/vcee_startups100x100.png";
import venista_ventures from "../../images/venista_ventures100x100.png";
import ventech from "../../images/ventech100x100.png";
import venture_stars from "../../images/venture_stars100x100.png";
import venturefriends from "../../images/venturefriends100x100.png";
import visinnnovasjon from "../../images/visinnnovasjon100x100.png";
import visionaries_vc from "../../images/visionaries_vc100x100.png";
import vista_equity_partners from "../../images/vista_equity_partners100x100.png";
import vito_ventures from "../../images/vito_ventures100x100.png";
import wayra_uk from "../../images/wayra_uk100x100.png";
import web_summit from "../../images/web_summit100x100.png";
import welcome_to_the_jungle from "../../images/welcome_to_the_jungle100x100.png";
import white_star_capital from "../../images/white_star_capital100x100.png";
import workinstartups from "../../images/workinstartups100x100.png";
import xange from "../../images/xange100x100.png";
import xtx_markets from "../../images/xtx_markets100x100.png";
import y_combinator from "../../images/y_combinator100x100.png";
import ycombinator from "../../images/ycombinator100x100.png";
import zobito from "../../images/zobito100x100.png";
import zoom_marketplace from "../../images/zoom_marketplace100x100.png";

const sources = {
  _import,
  a10x_group,
  a16z,
  a1kubator,
  a1kx,
  a212_vc,
  a360capital,
  a365_fintech,
  a3ts_capital,
  a42cap,
  a6am,
  a7percent,
  a83north,
  a8capita,
  absl_startup_challenge,
  accel,
  accelerace,
  accenture_ventures,
  acme,
  ada_ventures,
  adara_ventures,
  aerospace_xelerated,
  agoranov,
  ahren,
  albion,
  aligo,
  all_startups,
  allegion,
  almaz_capital,
  almi_invest,
  alven,
  am_ventures,
  amadeus_capital,
  amplify_la,
  angel_academe,
  angellist,
  animoca_brands,
  anthemis,
  antler,
  apollo_io,
  apx,
  armilar,
  asabys,
  atlantic_bridge,
  atlanticlabs,
  atomico,
  axa_vp,
  azertyjobs,
  b10,
  bacb,
  backed,
  bain_capital_ventures,
  balderton,
  barcelonatechcity,
  bcn_startup_map,
  bcnstartupmap,
  berlin_startup_jobs,
  besthorizon,
  beta_list,
  black_founders_fund,
  black_pearls,
  blackpeak_capital,
  bloccelerate,
  blockchain_capital,
  blockchainstartups,
  blockstart,
  blockwall,
  bonnier_capital,
  bonsai_partners,
  born2grow,
  breega,
  breyer_capital,
  brightcap_vc,
  brighteyes,
  brunnur_ventures,
  btov,
  bvalue,
  bvp,
  byfounders,
  caixa_capital_risc,
  cap_horn,
  caphub_io,
  capnamicventures,
  capterra,
  cavalry_vc,
  cg_partners,
  chalmers_ventures,
  change_ventures,
  charlotte_street_capital,
  cherry_vc,
  cic,
  clearbit,
  coinix,
  cointelligence,
  collision,
  concentric,
  connect_ventures,
  conorfund,
  contrarian_ventures,
  coparion,
  creandum,
  creathor,
  creative_destruction_lab,
  credoventures,
  crowdsurfer,
  crunchbase,
  cryptoslate,
  csv,
  cube_five,
  cylonlab,
  czechstartups,
  daphni,
  day_one_capital,
  dealroom,
  dealroom_transactions,
  deeptechforgood,
  defiance_capital,
  deltapartners,
  der_brutkasten,
  dgc,
  digital_grenoble,
  digital_health_london,
  djakne,
  dncapital,
  downing,
  draperesprit,
  drapergorenholm,
  dublin_tech_summit,
  dutch_founders_fund,
  dutchstartups,
  e27,
  earlybird,
  edp_ventures,
  eipp,
  elaia,
  elevator_ventures,
  eleven,
  email_hunter,
  emerald_ventures,
  endeit,
  enhjorning,
  enterprise_ethereum_alliance,
  entree,
  entrepreneur_first,
  episode1,
  erasmus_centre,
  estonia_e_residency,
  estonianmafia,
  eu_startups,
  eurazeo,
  eventmanagerblog,
  eventures,
  experior_venture_fund,
  f10,
  f6s,
  faber,
  fairpoint_capital,
  fantastic_beasts,
  fasttrackmalmo,
  felix,
  female_founders_grow_f,
  ffoh,
  finch_capital,
  finlab,
  finleap,
  finsj,
  finsmes,
  fintastico,
  firestartr,
  firstminute_capital,
  flashpoint_vc,
  fly_ventures,
  fomcap,
  forwardpartners,
  freigeist,
  french_partners,
  frontline_ventures,
  frst,
  ft_1000,
  funderbeam,
  funding,
  general_catalyst,
  get_latka,
  getinthering,
  giant_ventures,
  github,
  github_trending,
  global_founders_capital,
  googlecse,
  granatus_ventures,
  greenfield_one,
  gruenderszene,
  gullspang,
  haatch,
  hambro_perks,
  hamburg_startups,
  hardwareclub,
  hashed,
  headline,
  heartcore,
  henq,
  hexa,
  high_tech_gruenderfonds,
  highland_europe,
  hinrichs,
  hired,
  hiventures,
  hoxton_ventures,
  hv_capital,
  hyperledger_foundation,
  i2bf,
  ibb_bet,
  id4,
  illuminate_financial,
  impact_startup,
  imperialenterpriselab,
  inc_5000_eu,
  index_ventures,
  industrifonden,
  industryinvestment,
  inflection,
  initial_capital,
  innovation_dock,
  innovestor,
  inreach,
  inventure,
  inveready,
  investiere,
  iq_capital,
  isai_ventures,
  isomer_capital,
  j_and_t_ventures,
  jaimelesstartups,
  jme_ventures,
  jobfluent,
  jobs_stationf,
  join_capital,
  karma_vc,
  kaya,
  kerala_ventures,
  kfund,
  kima_ventures,
  korelya_capital,
  la_famiglia,
  labs_of_latvia,
  lafrenchtech_visas,
  lakestar,
  landingfolio,
  lanzame,
  launched,
  launchingnext,
  launchpoollabs,
  launchub,
  lea_partners,
  leadblock_partners,
  lemonade_stand,
  les_pepites_tech,
  lhv,
  linkedin,
  linkedinweb,
  lisbon_challenge,
  loyal_vc,
  lunar_ventures,
  lupa,
  lventure,
  lvp,
  magma_vc,
  mainport_innovation_fund,
  mairdumont_ventures,
  maki_vc,
  mam_startup,
  mangrove,
  manta_ray,
  marathon_vc,
  maven_11,
  maze_x,
  merian_ventures,
  mmc_ventures,
  moeller_ventures,
  momentum,
  monacotech,
  moonfire,
  mountain_partners,
  mouro_capital,
  msm_vc,
  multichain,
  multicoin_capital,
  munich_startup,
  national_business_awards_uk,
  nautacapital,
  neulogy,
  neveq,
  newfund,
  next47,
  ngp_capital,
  no_fluff_jobs,
  nordic_ninja,
  nordictechlist,
  northzone,
  notion_capital,
  nova,
  numa_hub,
  octopus_ventures,
  oktogon_vc,
  oltreventure,
  omnescapital,
  oneragtime,
  open_ocean,
  openfuture,
  opengraph,
  openwebcollective,
  otb_ventures,
  outlier_ventures,
  outward,
  oxford_capital,
  oxyleads,
  p101,
  paladin,
  pando_ventures,
  pantera_capital,
  paradigm,
  parkwalk_advisors,
  partech,
  passion,
  paua_ventures,
  pentech,
  picus_capital,
  pilabs,
  pinksaltventures,
  pithia,
  pj_tech_catalyst,
  playfair,
  plug_and_play,
  polar_light_ventures,
  polytech_ventures,
  portfolion,
  portugalventures,
  postmake,
  predictleads,
  predictleads_post,
  presto_ventures,
  producthunt,
  project_a,
  promus_ventures,
  propel,
  proptech1,
  pyratzlabs,
  rbvc,
  reaktorx,
  redalpine,
  revo,
  rga_iot,
  rhodium,
  rockaway_capital,
  rockstart,
  romanianstartups,
  rtp_global,
  runacap,
  s_ubg_group,
  saas1000,
  samaipata,
  samos,
  sampo_accelerator,
  sap_io,
  schibsted_growth,
  script_capital,
  seayaventures,
  seed_db,
  seedandspeed,
  seedcamp,
  seedcaptial_dk,
  sensortower,
  serena,
  seventure,
  shift_invest,
  signalfire,
  signals_vc,
  simpact,
  smith_and_crown,
  smithandcrown,
  sno,
  sosv,
  south_central_ventures,
  speedinvest,
  speedupgroup,
  spider_capital,
  spintop_ventures,
  start_green,
  startbase,
  starterstory,
  startup88,
  startup_brett,
  startup_chile,
  startup_estonia,
  startup_grind_jobs,
  startup_hubs,
  startup_inspire,
  startup_lab,
  startup_navigator,
  startup_ole,
  startup_only,
  startup_pirate_jobs,
  startup_poland,
  startup_tartu,
  startup_wiseguys,
  startup_xplore,
  startupbase,
  startupbenchmarks,
  startupberlin,
  startupblink,
  startupbuffer,
  startupbutton,
  startupcollections,
  startupexplore,
  startupjobs_cz,
  startuplift,
  startuplisboa,
  startupmatcher,
  startupnorway,
  startuppoland,
  startupranking,
  startuproulette,
  startups_be,
  startups_co_uk,
  startups_hu,
  startups_list,
  startupsbe,
  startupslist,
  startus,
  sting,
  streak,
  stride_vc,
  sup46,
  superangel,
  target_global,
  tav,
  team_global,
  tech_ireland,
  tech_london,
  tech_pitch_4pt5,
  techlondon,
  technopark_liechtenstein,
  techstars_airtable,
  techstars_demoday_airtable,
  tera_vc,
  the_factory,
  the_fse_group,
  the_hub,
  the_nordic_web,
  the_startup_pitch,
  the_venture_city,
  theblox,
  thehub,
  three_arrows_capital,
  tioga_capital,
  tlab,
  token_data,
  tokendata,
  tomorrow_today,
  top100startup,
  truegrowth,
  twitter,
  unicorn_hunt,
  unicorns_lt,
  union_square_ventures,
  uniqa_ventures,
  unusual,
  uvc,
  v2funding,
  vcee_startups,
  venista_ventures,
  ventech,
  venture_stars,
  venturefriends,
  visinnnovasjon,
  visionaries_vc,
  vista_equity_partners,
  vito_ventures,
  wayra_uk,
  web_summit,
  welcome_to_the_jungle,
  white_star_capital,
  workinstartups,
  xange,
  xtx_markets,
  y_combinator,
  ycombinator,
  zobito,
  zoom_marketplace,
};

export default (source) => {
  const name = source.toLowerCase();
  return name === "import" ? sources._import : sources[name];
};
