import Config from "../config";
import auth from "./Auth";
import request from "superagent";

export default class IncompleteStore {
  constructor() {
    this.url = `${Config.api.host}/incompletes`;
  }

  importCompleted(batchName) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(`${this.url}/import`)
          .query({
            access_token: authData.token,
            client: authData.client,
            batch_name: batchName,
          })
          .set("Accept", "application/json")
          .set("Content-type", "application/json")
          .timeout(600000)
          .send({})
      )
      .then((response) => response.body);
  }

  uploadCompleted(batchName, csv) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(`${this.url}/complete`)
          .query({
            access_token: authData.token,
            client: authData.client,
            batch_name: batchName,
          })
          .set("Accept", "application/json")
          .set("Content-type", "text/csv")
          .send(csv)
      )
      .then((response) => response.body);
  }

  exportIncomplete(batchName, limit = 500) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(`${this.url}/export`)
          .query({
            access_token: authData.token,
            client: authData.client,
            batch_name: batchName,
            limit: limit,
          })
          .set("Accept", "application/json")
          .set("Content-type", "application/json")
          .send({})
      )
      .then((response) => response.body);
  }

  incompleteBatches() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.url)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .set("Accept", "application/json")
      )
      .then(({ body: { incompletes = [] } = {} }) => incompletes);
  }

  compare(batchName) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(`${this.url}/${batchName}`)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .set("Accept", "application/json")
      )
      .then((response) => response.body);
  }

  download(batchName) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(`${this.url}/${batchName}`)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .set("Accept", "text/csv")
      )
      .then((response) => response.text);
  }
}
