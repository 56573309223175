export const evaluationsStages = {
  unseen: {
    not_final: {
      longLabel: null,
      shortLabel: null,
      goTo: null,
      actions: null,
    },
    inbound_form: {
      longLabel: "Inbound Form Received. Review Form.",
      shortLabel: "Inbound Form Received. Review Form.",
      goTo: "scorecard",
      actions: ["pass", "make_contact"],
      color: "#f44336",
      afterForm: true,
      isComplete: true,
    },
    inbound_partial_form: {
      longLabel: "Inbound Partial Form Received. Review Form.",
      shortLabel: "Inbound Partial Form Received. Review Form.",
      goTo: "scorecard",
      actions: ["pass", "make_contact"],
      color: "#f44336",
      afterForm: true,
      isComplete: false,
    },
    none: {
      longLabel: null,
      shortLabel: null,
      goTo: null,
      actions: null,
      color: null,
    },
  },
  not_now_never: {
    none: {
      longLabel: null,
      shortLabel: null,
      goTo: null,
      actions: null,
    },
    ml_updated: {
      longLabel: "Ml Updated: Re-Evaluate.",
      shortLabel: "Ml Updated: Re-Evaluate. ",
      goTo: "profile",
      actions: ["stop_ml_updated", "keep"],
      color: "#f44336",
    },
    inbound_form: {
      longLabel: "Inbound Form Received. Make a Decision.",
      shortLabel: "Inbound Form Received.",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "make_contact"],
      color: "#f44336",
      afterForm: true,
      isComplete: true,
    },
    inbound_partial_form: {
      longLabel: "Inbound Partial Form Received. Make a Decision.",
      shortLabel: "Inbound Partial Form Received.",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "make_contact"],
      color: "#f44336",
      afterForm: true,
      isComplete: false,
    },
  },
  in_progress: {
    not_final_none: {
      longLabel: null,
      shortLabel: null,
      goTo: null,
      actions: null,
    },
  },
  more_info: {
    none: {
      longLabel: "Send Form Campaign Started. Waiting for Entrepreneur.",
      shortLabel: "Send Form Campaign Started.",
      goTo: "workflow",
      actions: ["pass", "reconnect", "restart", "email", "make_contact"],
    },
    fall_back: {
      longLabel: "Send Form Campaign Finished. Move to Make Contact.",
      shortLabel: "Move to Make Contact.",
      goTo: "workflow",
      actions: ["pass", "reconnect", "restart", "email", "make_contact"],
      color: "#f44336",
    },
    outbound_form: {
      longLabel: "Outbound Form Received. Review Form.",
      shortLabel: "Outbound Form Received.",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "make_contact"],
      color: "#f44336",
      afterForm: true,
      isComplete: true,
    },
    outbound_partial_form: {
      longLabel: "Outbound Partial Form Received. Review Form.",
      shortLabel: "Outbound Partial Form Received.",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "make_contact"],
      color: "#f44336",
      afterForm: true,
      isComplete: false,
    },
    post_outbound_complete_form: {
      longLabel: "Entrepreneur completed the Form. Review it.",
      shortLabel: "Form completed. Review it.",
      goTo: "scorecard",
      actions: ["pass", "make_contact"],
      color: "#f44336",
      afterForm: true,
      isComplete: false,
    },
    manual_help_issues: {
      longLabel: "Requires Manual Help. No Emails Sent.",
      shortLabel: "Requires Manual Help. No Emails Sent.",
      goTo: "workflow",
      actions: ["pass", "reconnect", "restart", "email", "make_contact"],
      color: "#f44336",
    },
  },
  make_contact: {
    checklist_ongoing: {
      actions: [
        "pass",
        "unable_to_contact",
        "reconnect",
        "restart",
        "schedule_call",
        "call_meeting",
      ],
      color: "#f44336",
    },
    checklist_done: {
      actions: [
        "pass",
        "unable_to_contact",
        "reconnect",
        "restart",
        "schedule_call",
        "call_meeting",
      ],
    },
    call_scheduled: {
      longLabel: "Call Scheduled.",
      shortLabel: "Call Scheduled.",
      goTo: "workflow",
      actions: ["pass", "email", "call_meeting"],
      type: "warning",
    },
  },
  call_meeting: {
    call_scheduled: {
      longLabel: "Call Scheduled: ",
      shortLabel: "Call Scheduled: ",
      actions: ["pass", "reconnect", "email", "pre_qualification"],
      requires_call_date: true,
      color: "#ffc107",
    },
    after_call_no_show: {
      longLabel: "Call was a No Show, reschedule the call",
      shortLabel: "Make a Recommendation.",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "pre_qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_recommendation: {
      longLabel: "Call Completed. Waiting for a Recommendation.",
      shortLabel: "Make a Recommendation.",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "pre_qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_decision: {
      longLabel: "Call Completed. Waiting For a Decision.",
      shortLabel: "Make a Decision",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "pre_qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_completed_positive_form_call: {
      longLabel: "Form Call Completed. Schedule Recommendation call",
      shortLabel: "Schedule Recommendation call",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "pre_qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_completed_positive: {
      longLabel: "Call Completed. Decision: Move to Pre-Qualification ",
      shortLabel: "Go to Pre-Qualification ",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "pre_qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_completed_pass: {
      longLabel: "Call Completed. Decision: Move to Pass ",
      shortLabel: "Move to Pass ",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "pre_qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_completed_reconnect: {
      longLabel: "Call Completed. Decision: Move to Reconnect ",
      shortLabel: "Move to Reconnect",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "pre_qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_completed_wrong_call: {
      longLabel: "Call Completed. Decision: Move to Not Now ",
      shortLabel: "Move to Not Now ",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "pre_qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_completed_email: {
      longLabel: "Call Completed. Decision: Send Email",
      shortLabel: "Send Email",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "pre_qualification"],
      afterCall: true,
    },
    call_event_failure: {
      longLabel: "",
      shortLabel: "",
      goTo: null,
      actions: ["pass", "reconnect", "email", "pre_qualification"],
      afterCall: true,
    },
  },
  pre_qualification: {
    call_scheduled: {
      longLabel: "Call Scheduled: ",
      shortLabel: "Call Scheduled: ",
      actions: ["pass", "reconnect", "email", "qualification"],
      requires_call_date: true,
      color: "#ffc107",
    },
    after_call_no_show: {
      longLabel: "Call was a No Show, reschedule the call",
      shortLabel: "Make a Recommendation.",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_recommendation: {
      longLabel: "Call Completed. Waiting for a Recommendation.",
      shortLabel: "Make a Recommendation.",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_decision: {
      longLabel: "Call Completed. Waiting For a Decision.",
      shortLabel: "Make a Decision",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_completed_positive: {
      longLabel: "Call Completed. Decision: Move to Qualification ",
      shortLabel: "Go to Pre-Qualification ",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_completed_pass: {
      longLabel: "Call Completed. Decision: Move to Pass ",
      shortLabel: "Move to Pass ",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_completed_reconnect: {
      longLabel: "Call Completed. Decision: Move to Reconnect ",
      shortLabel: "Move to Reconnect",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_completed_wrong_call: {
      longLabel: "Call Completed. Decision: Move to Not Now ",
      shortLabel: "Move to Not Now ",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "qualification"],
      color: "#f44336",
      afterCall: true,
    },
    after_call_completed_email: {
      longLabel: "Call Completed. Decision: Send Email",
      shortLabel: "Send Email",
      goTo: "scorecard",
      actions: ["pass", "reconnect", "email", "qualification"],
      afterCall: true,
    },
    call_event_failure: {
      longLabel: "",
      shortLabel: "",
      goTo: null,
      actions: ["pass", "reconnect", "email", "qualification"],
      afterCall: true,
    },
  },
  qualification: {
    checklist_ongoing: {
      longLabel: "Checklist Progress: ",
      shortLabel: "Checklist Progress: ",
      goTo: "qualification",
      actions: ["pass", "reconnect", "email"],
      requires_checklist: true,
      color: "#ffc107",
    },
    checklist_done: {
      longLabel: "Checklist Completed. Make a Decision.",
      shortLabel: "Checklist Completed.",
      goTo: "qualification",
      actions: ["pass", "reconnect", "email", "term_sheet"],
      requires_checklist: true,
      color: "#ffc107",
    },
  },
  passed: {
    none: {
      longLabel: null,
      shortLabel: null,
      goTo: null,
      actions: ["reconnect", "email", "make_contact"],
      color: "#5dab49",
    },
    ml_updated: {
      longLabel: "ML Updated. Make a Decision.",
      shortLabel: "ML Updated. Make a Decision.",
      goTo: "profile",
      actions: ["stop_ml_updated", "keep", "reconnect", "make_contact"],
      color: "#f44336",
    },
  },
  in_tracking: {
    waiting_period: {
      longLabel: "Reconnecting on: ",
      shortLabel: "Reconnecting on: ",
      goTo: "workflow",
      actions: ["pass", "reconnect", "email", "make_contact"],
      requires_reconnect_date: true,
      color: "#5dab49",
    },
    reconnect_campaign: {
      longLabel: "Reconnect Campaign Started.",
      shortLabel: "Reconnect Campaign Started. ",
      goTo: "workflow",
      actions: ["pass", "reconnect", "email", "make_contact"],
      color: "#5dab49",
    },
    reply_received: {
      longLabel: "Entrepreneur Replied. Make a Decision.",
      shortLabel: "Entrepreneur Replied.",
      goTo: "workflow",
      actions: ["pass", "reconnect", "email", "make_contact"],
      color: "#f44336",
    },
    reconnect_campaign_done: {
      longLabel: "Reconnect Campaign Finished. Make a Decision.",
      shortLabel: "Make a Decision.",
      goTo: "workflow",
      actions: ["pass", "reconnect", "email", "make_contact"],
      color: "#f44336",
    },
    reconnect_not_found: {
      longLabel: "Reconnect Campaign Finished. Make a Decision.",
      shortLabel: "Make a Decision.",
      goTo: "workflow",
      actions: ["pass", "reconnect", "email", "make_contact"],
      color: "#f44336",
    },
    manual_help_issues: {
      longLabel: "Requires Manual Help. No Emails Sent.",
      shortLabel: "Requires Manual Help.",
      goTo: "workflow",
      actions: ["pass", "reconnect", "email", "make_contact"],
      color: "#f44336",
    },
  },
  unable_to_contact: {
    checklist_ongoing: {
      longLabel: "Next Organizer Task: ",
      shortLabel: "Next Organizer Task: ",
      goTo: "workflow",
      actions: ["pass", "reconnect", "restart", "make_contact"],
      requires_checklist: true,
      color: "#ffc107",
    },
    checklist_done: {
      longLabel: "Organizer Tasks Completed. Make a Decision.",
      shortLabel: "Organizer Tasks Completed.",
      goTo: "workflow",
      actions: ["pass", "reconnect", "restart", "make_contact"],
      color: "#f44336",
    },
  },
};
