import React from "react";
import PropTypes from "prop-types";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";

import ReasonInput from "../../../components/ReasonInput";

import { capitalizeString } from "../../../../lib/helpers";
import feedbackReasons from "../../../../content/callFeedbackReasons";
import { Toggle } from "material-ui";

export default class MakeRecommendationDecision extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    submitRecommendationOrDecision: PropTypes.func.isRequired,
    content: PropTypes.object,
    hasPreviousRecommendation: PropTypes.bool,
    requiredCallDetailsSelected: PropTypes.bool.isRequired,
    showErrorDialog: PropTypes.func.isRequired,
    structuredReasons: PropTypes.object.isRequired,
    reloadOrganization: PropTypes.func.isRequired,
    commentOnly: PropTypes.bool.isRequired,
    setCommentOnly: PropTypes.func.isRequired,
    callType: PropTypes.string.isRequired,
  };

  state = {
    choice: "",
    notes: "",
    savingRecommendationOrDecision: false,
    savingError: false,
    errorMessage: "",
    createdStructuredReasons: {},
  };

  componentWillMount() {
    const { type, content: { [type]: choice = "", notes = "" } = {} } =
      this.props;
    if (Object.keys(choice).length > 0) {
      this.setState({
        choice,
        notes,
      });
    }
  }

  handleChoiceChange = (choice) => {
    this.setState({
      choice,
    });
    this.props.setModalRecommendationDecisionSaved(false);
  };

  handleRecommendationNotesChange = (notes = "") => {
    this.setState({
      notes,
    });
    this.props.setModalRecommendationDecisionSaved(false);
  };

  setRecommendationOrDecisionSaving = (saving) => {
    if (saving !== this.state.savingRecommendationOrDecision) {
      this.setState({
        savingRecommendationOrDecision: saving,
      });
    }
  };

  setErrorSaving = (error = false, message = "") => {
    const { savingError, errorMessage } = this.state;
    if (error !== savingError || message !== errorMessage) {
      this.setState({
        savingError: error,
        errorMessage: message,
      });
    }
  };

  handleAddStructuredReason = (structuredReason) => {
    this.setState({
      createdStructuredReasons: {
        ...this.state.createdStructuredReasons,
        [structuredReason.id]: structuredReason,
      },
    });
  };

  handleDeleteStructuredReason = (reasonId) => {
    const createdStructuredReasonsCopy = Object.assign(
      {},
      this.state.createdStructuredReasons
    );
    delete createdStructuredReasonsCopy[reasonId];
    this.setState({
      createdStructuredReasons: createdStructuredReasonsCopy,
    });
  };

  handleSubmitRecommendationOrDecision = () => {
    const {
      organization: { id = "" } = {},
      type,
      user,
      submitRecommendationOrDecision,
      requiredCallDetailsSelected,
      hasPreviousRecommendation = false,
      showErrorDialog,
      reloadOrganization,
      commentOnly,
      callType,
    } = this.props;
    const { choice, notes, createdStructuredReasons = {} } = this.state;
    if (requiredCallDetailsSelected) {
      if (
        (hasPreviousRecommendation && choice !== "") ||
        (!hasPreviousRecommendation && choice !== "" && notes !== "") ||
        (hasPreviousRecommendation && choice === "" && commentOnly) ||
        (!hasPreviousRecommendation &&
          choice === "" &&
          notes !== "" &&
          commentOnly)
      ) {
        if (
          (choice.toLowerCase() === "pass" ||
            choice.toLowerCase() === "reconnect") &&
          (type === "recommendation" ||
            (type === "decision" && !hasPreviousRecommendation)) &&
          Object.keys(createdStructuredReasons).length === 0
        ) {
          const message = `You must add at least one structured reason for ${choice.toLowerCase()}. Type # to add a structured reason`;
          showErrorDialog({ message });
          this.setErrorSaving(true, message);
        } else {
          this.setErrorSaving(false);
          this.setRecommendationOrDecisionSaving(true);
          if (choice == "") {
            submitRecommendationOrDecision(type, {
              notes,
              user_id: user,
              reasons: createdStructuredReasons,
            });
          } else {
            submitRecommendationOrDecision(type, {
              [type]: choice,
              notes,
              user_id: user,
              reasons: createdStructuredReasons,
            });
          }
        }
      } else {
        if (hasPreviousRecommendation) {
          const message = `Please select your ${type}`;
          showErrorDialog({ message });
          this.setErrorSaving(true, message);
        } else {
          const message = `Please select your ${type} and type your reason`;
          showErrorDialog({ message });
          this.setErrorSaving(true, message);
        }
      }
      // reloadOrganization(id);
    } else {
      let message;
      switch (callType) {
        case "adhoc":
          message = `Please define the: Call With and Call Title`;
          break;
        case "customerreferences":
        case "productworkshop":
        case "enterpreneurreferences":
        case "investorreferences":
        case "peopleworkshop":
        case "technologyworkshop":
        case "commercialworkshop":
          message = `Please define the: Call With`;
          break;
          break;
        default:
          message = `Please define the: Investment Stage, Call With, Investment Type and Revenue Models`;
          break;
      }
      showErrorDialog({ message });
      this.setErrorSaving(true, message);
    }
  };

  render() {
    const {
      organization,
      type,
      structuredReasons = {},
      commentOnly,
      setCommentOnly,
    } = this.props;
    const {
      choice,
      notes,
      savingRecommendationOrDecision,
      savingError,
      errorMessage,
    } = this.state;
    const selectedStructuredReasons =
      (structuredReasons[choice.toLowerCase()] || {}).reasons || [];
    return (
      <div className="scorecard-decision-recommendation">
        <Toggle
          label={"Comment Only"}
          onToggle={(e, value) => {
            setCommentOnly(value);
            this.handleChoiceChange("");
          }}
          style={{ width: "15%" }}
        />
        <If condition={!commentOnly}>
          <SelectField
            value={choice}
            onChange={(e, i, value) => {
              this.handleChoiceChange(value);
            }}
            floatingLabelText={capitalizeString(type)}
            floatingLabelFixed={true}
            fullWidth={true}
            className="scorecard-decision-recommendation-selector"
            underlineStyle={{ border: "none" }}
            hintText={`Choose your ${type}`}
            hintStyle={{
              fontSize: "14px",
              color: "#939598",
            }}
          >
            {Object.entries(feedbackReasons).map(([reason, text]) => (
              <MenuItem key={reason} value={reason} primaryText={text} />
            ))}
          </SelectField>
        </If>
        <ReasonInput
          organization={organization}
          structuredReasons={selectedStructuredReasons}
          reason={notes}
          handleReasonChange={this.handleRecommendationNotesChange}
          handleAddStructuredReason={this.handleAddStructuredReason}
          handleDeleteStructuredReason={this.handleDeleteStructuredReason}
          useStructured={selectedStructuredReasons.length > 0}
          source="scorecard"
          scorecardReasonType={type}
          commentOnly={commentOnly}
        />
        {/*<textarea
          value={notes}
          onChange={this.handleRecommendationNotesChange}
          className="scorecard-decision-recommendation-notes"
          placeholder={`Why are you ${
            type === 'recommendation' ? 'recommending' : 'deciding'
          } this?`}
        />*/}
        <If condition={savingError}>
          <div className="error saving-error">{errorMessage}</div>
        </If>
        <Choose>
          <When condition={!savingRecommendationOrDecision}>
            <RaisedButton
              className="scorecard-button scorecard-modal-primary-btn submit-recommendation-decision"
              label="SUBMIT"
              backgroundColor="#5dab49"
              labelColor="#ffffff"
              onClick={this.handleSubmitRecommendationOrDecision}
            />
          </When>
          <Otherwise>
            <RaisedButton
              className="scorecard-button scorecard-modal-primary-btn saving-recommendation-decision"
              label="SAVING"
              disabled={true}
              backgroundColor="#7f7f7f"
              labelColor="#ffffff"
            />
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
