import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";
import Tooltip from "../../Tooltip";
import LabelIcon from "material-ui/svg-icons/action/label";
import _ from "underscore";
import { capitalizeString } from "../../../lib/helpers";

class InvestmentTypePreview extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
  };
  state = {
    showTooltip: false,
  };

  hasError = () => {
    return true;
  };

  render() {
    const {
      investor: {
        aggregations,
        aggregations: { most_invested_investment_type = "" } = {},
      } = {},
    } = this.props;
    const { showTooltip } = this.state;
    return (
      <div
        className="data-wrapper investment-type compressed"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: false });
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: true });
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text="Investment Type"
        />
        <div className="data-icon">
          <LabelIcon className="data-icon-svg" />
        </div>
        <Choose>
          <When
            condition={
              _.isEmpty(aggregations) || most_invested_investment_type === ""
            }
          >
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="preview-box">
              <div className="invesor-profile-row">
                <div className="data-content text invesor-profile-div">
                  Most Invested:
                </div>
                <div className="spacer" />
                <div style={{ lineHeight: "1.8" }}>
                  {capitalizeString(most_invested_investment_type)}
                </div>
              </div>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(InvestmentTypePreview);
