import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { CircularProgress, Dialog, RaisedButton } from "material-ui";
import CustomizeCampaign from "./CustomizeCampaign";
import inreachTeam from "../../config/inreach_team";
import Organizations from "../../lib/Organizations";
import AssignCampaigns from "../../lib/AssignCampaigns";

import DialogWrapper from "../components/DialogWrapper";

const LOADING_INDICATOR = (
  <div className="loading-indicator">
    <CircularProgress size={20} />
  </div>
);

function buttonClass(value, state) {
  return classNames("button", {
    "button-left": value === "send_form",
    "button-right": value === "make_contact",
    "state-active": value === state,
  });
}

export default class InProgressCustomizeCampaign extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    recentMessages: PropTypes.object,
  };

  state = {
    loading: false,
    isEditingCampaign: false,
    campaignSelected: null,
    campaignPrefix: null,
    from: null,
    assignedTo: null,
    organizer: null,
    fallbackToCall: null,
  };

  campaignName = (campaignPrefix, from, assignedTo, organizer) => {
    switch (campaignPrefix) {
      case "send_form":
        return "send_form";
      case "make_contact":
        if (assignedTo && assignedTo !== from) {
          if (organizer && organizer !== assignedTo) {
            return campaignPrefix + "_with_assignee_and_organizer";
          }
          return campaignPrefix + "_with_assignee";
        }
        if (organizer && organizer !== assignedTo && organizer !== from) {
          return campaignPrefix + "_with_organizer";
        }
        return campaignPrefix;
    }
  };

  selectCampaign = (campaign, fallbackToCall = false) => {
    const {
      campaignSelected,
      campaignPrefix,
      from: stateFrom,
      assignedTo: stateAssignedTo,
      organizer: stateOrganizer,
    } = this.state;
    if (campaignSelected && campaignPrefix === campaign) {
      this.setState({
        isEditingCampaign: true,
      });
      return;
    }
    const { organization, client, userId, userRole } = this.props;
    const {
      assigned_to: decisionAssignedTo = userRole === "investor"
        ? userId
        : "amanda@inreachventures.com",
      organizer: decisionOrganizer = userRole === "investor"
        ? userId
        : "amanda@inreachventures.com",
    } = Organizations.latestFinalOrInvestorDecision(organization, client);
    const from =
      stateFrom ||
      (process.env.NODE_ENV === "test" || process.env.NODE_ENV === "codeship"
        ? "test.partner@inreachventures.com"
        : "amanda@inreachventures.com");
    const assignedTo = stateAssignedTo || decisionAssignedTo;
    const organizer = stateOrganizer || decisionOrganizer;

    this.setState({
      from,
      assignedTo,
      organizer,
      fallbackToCall,
      campaignSelected: this.campaignName(
        campaign,
        from,
        assignedTo,
        organizer
      ),
      campaignPrefix: campaign,
      isEditingCampaign: true,
    });
  };

  campaignToStage = (campaign) => {
    switch (campaign) {
      case "send_form":
        return "more_info";
      default:
        return campaign;
    }
  };

  campaignToForm = (campaign) => {
    switch (campaign) {
      case "make_contact":
        return false;
      default:
        return true;
    }
  };

  handleAssignedToChange = (assignedTo) => {
    const { campaignPrefix, from, organizer } = this.state;
    this.setState({
      assignedTo,
      campaignSelected: this.campaignName(
        campaignPrefix,
        from,
        assignedTo,
        organizer
      ),
    });
  };

  handleOrganizerChange = (organizer) => {
    const { campaignPrefix, from, assignedTo } = this.state;
    this.setState({
      organizer,
      campaignSelected: this.campaignName(
        campaignPrefix,
        from,
        assignedTo,
        organizer
      ),
    });
  };

  handleFromChange = (from) => {
    const { campaignPrefix, organizer, assignedTo } = this.state;
    this.setState({
      from,
      campaignSelected: this.campaignName(
        campaignPrefix,
        from,
        assignedTo,
        organizer
      ),
    });
  };

  toggleEditCampaign = (isEditingCampaign) => {
    this.setState({ isEditingCampaign: isEditingCampaign });
  };

  submitCampaign = (
    campaignSelected,
    campaignConfiguration,
    evaluationStage
  ) => {
    const { client, organization, handleSubmit } = this.props;
    const { campaignPrefix, fallbackToCall, from, assignedTo, organizer } =
      this.state;
    this.setState({ loading: true });

    const existingDecision = Object.assign(
      {
        client: client,
        organization_id: organization.id,
      },
      {}
    );
    const assignCampaign = AssignCampaigns.processCampaign(
      campaignSelected,
      campaignConfiguration,
      existingDecision,
      from,
      assignedTo,
      organizer,
      "contact",
      this.campaignToStage(campaignPrefix),
      this.campaignToForm(campaignPrefix)
    );
    assignCampaign.decision.fallback_to_call = fallbackToCall;

    if (
      (this.props.userRole === "investor" ||
        this.props.userRole === "partner") &&
      !!this.props.userId
    ) {
      assignCampaign.decision.user_id = this.props.userId;
    }

    return handleSubmit(assignCampaign);
  };

  decision = () => {
    const { organization, client } = this.props,
      { campaignPrefix } = this.state;
    const oldestTransitionalInProgressDecision =
      Organizations.getOldestTransitionalInProgressDecision(
        organization,
        client
      );

    const decision = {
      state: "contact",
      stage: this.campaignToStage(campaignPrefix),
      organizer: "amanda@inreachventures.com",
      assigned_to: "amanda@inreachventures.com",
    };

    if (oldestTransitionalInProgressDecision.user_id) {
      const userId = oldestTransitionalInProgressDecision.user_id;

      if (inreachTeam.some((el) => el.email === userId)) {
        decision.assigned_to = userId;
      }
    }

    return decision;
  };

  render() {
    const { organization, client, open, handleClose, recentMessages } =
      this.props;
    const {
      loading,
      isEditingCampaign,
      campaignSelected,
      fallbackToCall,
      from,
      assignedTo,
      organizer,
    } = this.state;

    const actions = [
      <RaisedButton
        key="cancel"
        className="edit-email-close"
        backgroundColor={"red"}
        labelColor={"#ffffff"}
        style={{ marginRight: "16px" }}
        onClick={() => {
          this.setState(
            {
              isEditingCampaign: false,
              campaignSelected: null,
            },
            handleClose
          );
        }}
        label={"Close"}
      />,
    ];
    const decision = this.decision();

    return (
      <React.Fragment>
        <DialogWrapper
          className="customize-campaign-modal"
          title={`In Progress`}
          actions={actions}
          modal={true}
          open={!isEditingCampaign && open}
          repositionOnUpdate={true}
          autoDetectWindowHeight={true}
          autoScrollBodyContent={true}
          contentStyle={{
            width: "98%",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div style={{ padding: "5px 0px 0px 0px", whiteSpace: "pre-wrap" }}>
            {this.props.userRole === "investor"
              ? "⚠️ You are about to make an investor decision.\nShift+Click on In Progress to simply send the company to Roberto's inbox."
              : ""}
          </div>
          <div className="inprogress-modal">
            <RaisedButton
              value="send_form"
              label={loading ? LOADING_INDICATOR : "Form Only"}
              className={buttonClass("send_form") + " inprogress-button"}
              onClick={() => this.selectCampaign("send_form")}
            />
            <RaisedButton
              value="fallback"
              label={loading ? LOADING_INDICATOR : "Form Call"}
              className={buttonClass("fallback") + " inprogress-button"}
              onClick={() => this.selectCampaign("send_form", true)}
            />
            <RaisedButton
              value="make_contact"
              label={loading ? LOADING_INDICATOR : "Make Contact"}
              className={buttonClass("make_contact") + " inprogress-button"}
              onClick={() => this.selectCampaign("make_contact")}
            />
          </div>
        </DialogWrapper>
        <If condition={campaignSelected}>
          <CustomizeCampaign
            selectAssigned={true}
            scheduleCall={campaignSelected === "make_contact"}
            defaultMailingEnabled={campaignSelected === "make_contact"}
            organization={organization}
            from={from}
            assignedTo={assignedTo}
            organizer={organizer}
            isEditingCampaign={isEditingCampaign}
            campaignSelected={campaignSelected}
            onDone={this.toggleEditCampaign}
            submitCampaign={this.submitCampaign}
            editingEnabled={true}
            client={client}
            decision={decision}
            onChangeAssignedTo={this.handleAssignedToChange}
            onChangeOrganizer={this.handleOrganizerChange}
            onChangeFrom={this.handleFromChange}
            fallbackToCall={fallbackToCall}
            recentMessages={recentMessages}
          />
        </If>
      </React.Fragment>
    );
  }
}
