export default {
  accelerator: {
    name: "Accelerator",
  },
  incubator: {
    name: "Incubator",
  },
  angel: {
    name: "Angel",
  },
  investment_partner: {
    name: "Investment Partner",
  },
  angel_group: {
    name: "Angel Group",
  },
  syndicate: {
    name: "Syndicate",
  },
  seed: {
    name: "Seed",
  },
  micro_vc: {
    name: "Micro Vc",
  },
  entrepreneurship_program: {
    name: "Entrepreneurship Program",
  },
  venture_capital: {
    name: "Venture Capital",
  },
  early_growth: {
    name: "Early Growth",
  },
  late_growth: {
    name: "Late Growth",
  },
  venture_debt: {
    name: "Venture Debt",
  },
  co_working_space: {
    name: "Co Working Space",
  },
  corporate_venture_capital: {
    name: "Corporate Venture Capital",
  },
  family_investment_office: {
    name: "Family Investment Office",
  },
  fund_of_funds: {
    name: "Fund Of Funds",
  },
  government_office: {
    name: "Government Office",
  },
  hedge_fund: {
    name: "Hedge Fund",
  },
  investment_bank: {
    name: "Investment Bank",
  },
  mature: {
    name: "Mature",
  },
  pension_funds: {
    name: "Pension Funds",
  },
  private_equity_firm: {
    name: "Private Equity Firm",
  },
  secondary_purchaser: {
    name: "Secondary Purchaser",
  },
  startup_competition: {
    name: "Startup Competition",
  },
  university_program: {
    name: "University Program",
  },
};
