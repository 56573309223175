import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import Sources from "./Sources";
import FirstSeen from "./FirstSeen";
import LastUpdated from "./LastUpdated";
import Domain from "./Domain";

class OtherInfoCompany extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
  };

  render() {
    const { organization = {} } = this.props;
    return (
      <div className="other-info-company">
        <div className="section-title">Company</div>
        <Sources organization={organization} />
        <FirstSeen organization={organization} />
        <LastUpdated organization={organization} />
        <Domain organization={organization} />
      </div>
    );
  }
}

export default withRouter(OtherInfoCompany);
