import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-v4";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import auth from "../../storage/Auth";

import OrganizationStore from "../../storage/OrganizationStore";
import OrganizationSnapshotStore from "../../storage/OrganizationSnapshotStore";
import ReconnectStore from "../../storage/ReconnectStore";
import SignalStore from "../../storage/SignalStore";
import FormOrganizationStore from "../../storage/FormOrganizationStore";
import PreQualificationStore from "../../storage/PreQualificationStore";
import QualificationStore from "../../storage/QualificationStore";
import organizationsStore from "../../storage/OrganizationsStore";
import FundingRoundsStore from "../../storage/FundingRoundsStore";
import FeedbackStore from "../../storage/FeedbackStore";

import _ from "underscore";
import Organizations from "../../lib/Organizations";

import Loading from "../loading/Loading";
import InboxModal from "./InboxModal";
import EditPersonDialog from "./EditPersonDialog";
import AddPersonDialog from "./AddPersonDialog";
import SendFeedbackDialog from "./SendFeedbackDialog";
import DIGDownDialog from "./DIGDownDialog";
import OrganizationsNavigator from "./OrganizationsNavigator";
import OrganizationTabsContent from "./OrganizationTabsContent";
import OrganizationTopBar from "./OrganizationTopBar";

import inboxHelper from "../../lib/InboxHelper";
import CampaignStore from "../../storage/CampaignsStore";
import AssignCampaigns from "../../lib/AssignCampaigns";
import SequenceStateStore from "../../storage/SequenceStateStore";
import OrganizationsNavigatorFetcher from "./OrganizationsNavigatorFetcher";
import SocialMessagesStore from "../../storage/SocialMessagesStore";
import InvestorsStore from "../../storage/InvestorsStore";
import StructuredReasonsStore from "../../storage/StructuredReasonsStore";

import spotifyDummyData from "../../dummy/new_spotify_organization_data";
import allInsightsDummyData from "../../dummy/all_insights_dummy_data";
import DialogWrapper from "../components/DialogWrapper";
import WorkflowSyncStore from "../../storage/WorkflowSyncStore";
class ErrorMessage extends React.Component {
  static propTypes = {
    error: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    closeErrorDialog: PropTypes.func.isRequired,
  };

  render() {
    const { error, open, closeErrorDialog } = this.props;

    if (!error) {
      return false;
    }

    return (
      <DialogWrapper
        actions={[
          <FlatButton
            label="Close"
            primary={true}
            onClick={closeErrorDialog}
          />,
        ]}
        modal={false}
        open={open}
        onRequestClose={closeErrorDialog}
      >
        {error
          .split(/(\r\n|\n|\r)/)
          .filter((line) => !!line)
          .map((line, i) => (
            <p key={i}>{line}</p>
          ))}
      </DialogWrapper>
    );
  }
}

class Organization extends React.Component {
  useDummyData = false;

  state = {
    organization: null,
    snapshot: {},
    snapshotLoading: true,
    useSnapshot: false,
    investors: [],
    investorsLoading: true,
    forms: [],
    selectedForm: null,
    signals: {},
    signalsLoading: false,
    fundingRounds: [],
    fundingRoundsLoading: true,
    loading: false,
    updatingInboxModal: false,
    error: null,
    displayError: false,
    userHasFullAccess: false,
    client: null,
    profile: {},
    userId: null,
    openDIGDownDialog: false,
    errorMessageDIGDown: null,
    showAsPublicProfile: false,
    inboxOrganization: null,
    inboxAssignedTo: null,
    selectedTab: this.props.params.tab || "profile",
    isUpdatingInbox: false,
    showEditPersonDialog: false,
    editPersonId: null,
    showAddPersonDialog: false,
    reconnectActive: false,
    reconnect: null,
    tabsContent: {},
    existingCampaign: null,
    existingSequenceStates: null,
    socialMessages: null,
    structuredReasons: {},
    showSendFeedbackDialog: false,
    feedbackCategories: [],
    originalLocation: null,
    scorecard: {},
  };

  componentWillMount() {
    const tempData = this.retrieveTemporaryAuthData();
    if (tempData) {
      this.temporaryExpirationDate = tempData.e;
      this.temporarySignature = tempData.s;
      this.temporaryShowNote = tempData.n;
      this.setState({ showAsPublicProfile: true });
    }
    this.initComponent(this.props.params.id);
  }

  retrieveTemporaryAuthData = () => {
    if (
      this.props.location &&
      this.props.location.query &&
      this.props.location.query.e &&
      this.props.location.query.s
    ) {
      return {
        e: this.props.location.query.e,
        s: this.props.location.query.s,
        n: !!this.props.location.query.n,
      };
    }

    return false;
  };

  deleteSignals = () => {
    this.organizationStore.deleteSignals(() => {
      window.location.reload();
    });
  };

  handleFormEvaluationChange = (assignCampaign) => {
    return new OrganizationStore(this.state.organization.id)
      .setIsInteresting(assignCampaign)
      .then(this.handleOrganizationChange)
      .catch((error) => {
        console.log("error", error);
        this.showErrorDialog(error);
      });
  };

  getOrganizationData = (apiOrganization) => {
    // Temporary function to handle using dummy data for certain companies
    if (
      ["1", "487985"].filter((dummyId) => apiOrganization.id === dummyId)
        .length > 0 &&
      this.useDummyData
    ) {
      switch (apiOrganization.id) {
        case "1":
          return allInsightsDummyData;
        case "487985": // Spotify
          return spotifyDummyData;
      }
    } else {
      return apiOrganization;
    }
  };

  getOrganization = (organizationId) => {
    const organizationStore = new OrganizationStore(organizationId);
    const {
      location: {
        query: { created_at: decisionCreatedAt, user_id: decisionUserId } = {},
      } = {},
    } = this.props;

    if (decisionCreatedAt && decisionUserId) {
      return organizationStore
        .getOrganizationAtDecision(decisionUserId, decisionCreatedAt)
        .then((organization) => {
          console.log(
            `Organization: ${organization.id} at ${decisionUserId}'s decision ${decisionCreatedAt} retrieved`
          );
          return organization;
        });
    }
    return organizationStore.getModel(
      this.temporaryExpirationDate,
      this.temporarySignature,
      this.temporaryShowNote
    );
  };

  getSignals = (organizationId) => {
    const { location: { query: { created_at: decisionCreatedAt } = {} } = {} } =
      this.props;

    const signalStore = new SignalStore();
    if (decisionCreatedAt) {
      return signalStore.fetchAllSignals(
        "organizations",
        organizationId,
        decisionCreatedAt - 1000 * 60 * 60 * 24 * (365 + 60),
        decisionCreatedAt - 1000 * 60 * 60 * 24 * 60,
        null,
        [
          "SIMILAR_WEB-TOTAL_VISIT",
          "SENSORTOWER-SENSORTOWER_DOWNLOADS",
          "SENSORTOWER-SENSORTOWER_REVENUE",
        ]
      );
    }
    return signalStore.fetchAllSignals(
      "organizations",
      organizationId,
      null,
      null,
      this.getSignalsPeriod(),
      [
        "SIMILAR_WEB-TOTAL_VISIT",
        "SENSORTOWER-SENSORTOWER_DOWNLOADS",
        "SENSORTOWER-SENSORTOWER_REVENUE",
      ]
    );
  };

  getFundingRounds = (organizationId) => {
    const { location: { query: { created_at: decisionCreatedAt } = {} } = {} } =
      this.props;
    const fundingRoundsStore = new FundingRoundsStore(organizationId);
    return fundingRoundsStore.fundingRounds().then((fundingRounds) => {
      if (decisionCreatedAt) {
        return fundingRounds.filter(
          ({ announced_on: announcedOn }) => announcedOn < decisionCreatedAt
        );
      }
      return fundingRounds;
    });
  };

  initComponent = (orgId) => {
    const { location } = this.props;
    const { state: { organizationsStoreParams = {} } = {} } = location;
    this.setState({
      loading: true,
      originalLocation: location,
    });
    organizationsStore.initialize(organizationsStoreParams);
    this.organizationsNavigatorFetcher = new OrganizationsNavigatorFetcher(
      organizationsStore
    );
    this.organizationStore = new OrganizationStore(orgId);
    this.workflowSyncStore = new WorkflowSyncStore();
    this.organizationSnapshotStore = new OrganizationSnapshotStore(orgId);
    this.reconnectStore = new ReconnectStore(orgId);
    this.campaignStore = new CampaignStore(orgId);
    this.sequenceStateStore = new SequenceStateStore(orgId);
    this.preQualificationStore = new PreQualificationStore(orgId);
    this.socialMessagesStore = new SocialMessagesStore(orgId);
    this.investorsStore = new InvestorsStore(orgId);
    this.qualificationStore = new QualificationStore(orgId);
    this.structuredReasonsStore = new StructuredReasonsStore();
    this.feedbackStore = new FeedbackStore();
    this.checkReconnect();
    this.getOrganization(orgId)
      .then((apiOrganization) => {
        const organization = this.getOrganizationData(apiOrganization);
        this.handleOrganizationChange(organization);
        this.setState({
          loading: false,
        });
        if (window.mixpanel) {
          mixpanel.track("Visited organization profile", {
            organizationId: organization.id,
            organizationName: organization.name,
          });
        }
        this.getSignals(organization.id)
          .then((signals) => this.setState({ signals, signalsLoading: false }))
          .catch((err) =>
            console.error("Unable to load signals for organization", err)
          );
        new FormOrganizationStore(organization)
          .getAllForms()
          .then((response) => {
            this.setState({
              forms: response.forms,
              selectedForm: response.best_entry_id,
            });
          });

        this.organizationSnapshotStore
          .getOrganizationSnapshot()
          .then((snapshot = {}) => {
            this.setState({
              snapshot,
              snapshotLoading: false,
              useSnapshot: this.getShouldUseSnapshot(snapshot),
            });
          })
          .catch(() => {
            this.setState({
              snapshotLoading: false,
            });
          });

        if (!!organization.investor_ids) {
          this.investorsStore
            .getInvestors(organization.investor_ids)
            .then((investors) => {
              this.setState({
                investors,
                investorsLoading: false,
              });
            })
            .catch((err) => {
              this.setState({
                investorsLoading: false,
              });
            });
        } else {
          this.setState({
            investorsLoading: false,
          });
        }

        return organization;
      })
      .catch((err) => {
        const errorMessage = `Problem retrieving organization ${orgId}: ${err}`;
        console.error(errorMessage, err);
        this.setState({
          openDIGDownDialog: true,
          errorMessageDIGDown: errorMessage,
          loading: false,
        });
      });

    this.socialMessagesStore
      .getSocialMessages()
      .then(({ social_messages: socialMessages = [] }) =>
        this.setState({
          socialMessages,
        })
      );
    this.getFundingRounds(orgId)
      .then((fundingRounds = []) => {
        this.setState({ fundingRounds, fundingRoundsLoading: false });
      })
      .catch((err) => {
        this.setState({ fundingRoundsLoading: false });
      });

    this.structuredReasonsStore.getAllReasons().then((reasons) => {
      this.setState({
        structuredReasons: {
          pass: reasons.find(({ id }) => id === "passed"),
          reconnect: reasons.find(({ id }) => id === "in_tracking"),
        },
      });
    });

    this.feedbackStore.getFeedback().then(({ categories = [] }) => {
      this.setState({
        feedbackCategories: categories,
      });
    });

    auth
      .getAuthData()
      .then(({ client, profile, accessType, roles }) => {
        this.setState({
          userHasFullAccess: accessType === "full",
          userRoles: roles,
          client,
          profile,
          showAsPublicProfile: false,
        });
      })
      .catch((err) => {
        if (this.temporaryExpirationDate && this.temporarySignature) {
          this.setState({
            userHasFullAccess: false,
            userRoles: ["browse_share"],
          });
        } else {
          console.error(err);
        }
      });
  };

  getSignalsPeriod = () => {
    const {
      organization: { user_decisions: { inreachventures = {} } = {} } = {},
    } = this.state;

    const latestFinalDecision = Object.keys(inreachventures)
      .map((user) => inreachventures[user])
      .find((decision) => decision.is_final);

    const decision = latestFinalDecision;
    const oneYear = 1000 * 60 * 60 * 24 * 365;
    if (typeof decision !== "undefined") {
      const decisionTimestamp = new Date(
        decision.originally_created_at
      ).getTime();
      const currentDateTimestamp = new Date().getTime();
      if (currentDateTimestamp - decisionTimestamp >= oneYear) {
        return currentDateTimestamp - decisionTimestamp + oneYear;
      } else {
        return oneYear;
      }
    } else {
      return oneYear;
    }
  };

  getShouldUseSnapshot = (snapshot = {}) => {
    const {
      organization: { user_decisions: { inreachventures = {} } = {} } = {},
    } = this.state;
    return (
      typeof inreachventures["roberto@inreachventures.com"] !== undefined &&
      !_.isEmpty(snapshot)
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (typeof window.mixpanel === "function") {
      mixpanel.time_event("Evaluated organization");
    }
    this.getExistingAndStateCampaign();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.params &&
      this.props.params.id &&
      nextProps.params &&
      nextProps.params.id &&
      this.props.params.id !== nextProps.params.id
    ) {
      this.initComponent(nextProps.params.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, tab } = this.props;
    const { tab: prevTab } = prevProps;

    if (window.mixpanel && prevTab !== "history" && tab === "history") {
      mixpanel.track("Viewed organization history", {
        id,
      });
    }
  }

  reloadOrganization = (organizationId) => {
    this.setState({
      loading: true,
    });
    this.checkReconnect();
    this.organizationStore = new OrganizationStore(organizationId);
    this.getOrganization(organizationId)
      .then((organization) => {
        this.setState({
          loading: false,
        });
        this.handleOrganizationChange(organization);
        if (window.mixpanel) {
          mixpanel.track("Visited organization profile", {
            organizationId: organization.id,
            organizationName: organization.name,
          });
        }
      })
      .catch((err) => {
        const errorMessage = `Problem retrieving organization ${organizationId}: ${err}`;
        console.error(errorMessage, err);
        this.setState({
          openDIGDownDialog: true,
          errorMessageDIGDown: errorMessage,
          loading: false,
        });
      });
  };

  handleOrganizationChange = (organization) => {
    const { inboxState } = this.props.route;

    inboxState?.setForceUpdate(true);

    this.setState({
      organization,
    });

    document.title = `${organization.name} | InReach`;
  };

  checkReconnect = () => {
    this.setState({
      reconnectActive: false,
      reconnect: null,
    });
    this.reconnectStore.getReconnect().then((reconnect) => {
      if (reconnect.when) {
        this.setState({
          reconnectActive: true,
          reconnect: reconnect,
        });
      }
    });
  };

  handleOrganizationStateChange = (assignCampaign) => {
    const { decision: { state } = {} } = assignCampaign;
    if (mixpanel) {
      try {
        mixpanel.track("Evaluated organization", {
          organizationId: this.state.organization.id,
          organizationName: this.state.organization.name,
          state: state,
          page: "profile",
        });
      } catch (e) {
        console.error("Failure sending event to mixpanel", e);
      }
    }

    return this.organizationStore
      .setDecision(assignCampaign)
      .then((organization) => {
        if ((state || "").toLowerCase() === "contact") {
          return this.workflowSyncStore.syncOrganization(organization);
        }
        return Promise.resolve(organization);
      })
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  handleOrganizationStageChange = (stage, notes) => {
    return this.organizationStore
      .updateDecisionStage(stage, notes)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  handleOrganizationIsNotSpamChange = (orgId, isNotSpam) => {
    return this.organizationStore
      .updateIsNotSpam(isNotSpam)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  handleOrganizationStopChange = (stop) => {
    return this.organizationStore
      .updateDecisionStop(stop)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  handleOrganizationStopAllCampaigns = () => {
    return this.campaignStore
      .stopAllCampaigns()
      .then(() => this.reloadOrganization(this.state.organization.id))
      .catch((error) =>
        this.showErrorDialog(AssignCampaigns.processError(error))
      );
  };

  handleReconnectCancel = () => {
    return this.reconnectStore
      .removeReconnect()
      .then(() => this.reloadOrganization(this.state.organization.id))
      .catch((err) => this.showErrorDialog(err));
  };

  handleAssignCampaignCancel = () => {
    return this.campaignStore
      .cancelOrganizationCampaign()
      .then(() => this.reloadOrganization(this.state.organization.id))
      .catch((error) =>
        this.showErrorDialog(AssignCampaigns.processError(error))
      );
  };

  handleAssignCampaignRetry = () => {
    return this.campaignStore
      .retryOrganizationCampaign()
      .then(() => this.reloadOrganization(this.state.organization.id))
      .catch((error) =>
        this.showErrorDialog(AssignCampaigns.processError(error))
      );
  };

  handleOrganizationStatusChange = (status) => {
    return this.organizationStore
      .updateDecisionStatus(status)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  handleOrganizationStatusesChange = (statuses) => {
    return this.organizationStore
      .updateDecisionStatuses(statuses)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  handleOrganizationFollowUpContactChange = (followUpContact) => {
    return this.organizationStore
      .updateDecisionFollowUpContact(followUpContact)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  handleOrganizationAssignedToChange = (assignedTo) => {
    return this.organizationStore
      .updateDecisionAssignedTo(assignedTo)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  handleOrganizationOrganizerChange = (organizer) => {
    return this.organizationStore
      .updateDecisionOrganizer(organizer)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  handleMakeContactChecklistChange = (makeContactChecklist) => {
    return this.organizationStore
      .updateDecisionMakeContactChecklist(makeContactChecklist)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  resetSocialMessagesReminders = () => {
    return this.socialMessagesStore
      .resetSocialMessagesReminders()
      .then(({ social_messages: socialMessages = [] }) =>
        this.setState({ socialMessages })
      )
      .catch((err) => this.showErrorDialog(err));
  };

  sendSocialMessage = (person, medium, type) => {
    return this.socialMessagesStore
      .sendSocialMessageType(person, medium, type)
      .then((socialMessage) => {
        const { socialMessages = [] } = this.state;
        socialMessages.push(socialMessage);
        this.setState({ socialMessages });
      })
      .catch((err) => this.showErrorDialog(err));
  };

  editPerson = (personId) => {
    this.setState({
      showEditPersonDialog: true,
      editPersonId: personId,
    });
  };

  handleSaveEditPersonDialog = () => {
    this.reloadOrganization(this.state.organization.id);
    this.setState({
      showEditPersonDialog: false,
      editPersonId: null,
    });
  };

  handleCloseEditPersonDialog = () => {
    this.setState({
      showEditPersonDialog: false,
      editPersonId: null,
    });
  };

  addPerson = () => {
    this.setState({
      showAddPersonDialog: true,
    });
  };

  handleCloseAddPersonDialog = () => {
    this.setState({
      showAddPersonDialog: false,
    });
  };

  handlePersonAdd = (personId) => {
    const organization = Object.assign({}, this.state.organization, {
      person_ids: this.state.organization.person_ids
        ? this.state.organization.person_ids.concat(personId)
        : [personId],
    });
    this.setState({
      organization,
      showAddPersonDialog: false,
    });
  };

  handleToggleRequiresManualHelpChange = (requiresManualHelp) => {
    return this.organizationStore
      .toggleRequiresManualHelp(requiresManualHelp)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  handleTogglePriority = (urgent) => {
    return this.organizationStore
      .handlePriorityChange(urgent ? 1 : null)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  showErrorDialog = (error) => {
    const { response: { body: { message = error.message } = {} } = {} } = error;
    this.setState({
      error: message,
      displayError: true,
    });
  };

  handleErrorClose = () => {
    this.setState({
      error: null,
      displayError: false,
    });
  };

  addToInbox = (organization, assignedTo, notes, reason) => {
    const {
      profile: { email },
    } = this.state;

    const { inboxState } = this.props.route;

    inboxState?.setForceUpdate(true);
    inboxState?.incrementCounter("inbox");

    const store = new OrganizationStore(organization.id);

    this.setState(
      {
        isUpdatingInbox: true,
        inboxAssignedTo: null,
        inboxOrganization: null,
      },
      () => {
        store
          .addToInbox(assignedTo, notes, reason)
          .then((organization) => {
            this.setState({ isUpdatingInbox: false, organization });

            if (assignedTo === email) {
              this.emitInboxUpdateEvent(true);
            }
          })
          .catch((error) => {
            this.setState({
              isUpdatingInbox: false,
              error: error.message,
              displayError: true,
            });
          });
      }
    );
  };

  createQualificationChecklist = (paramId = null) => {
    const { organization: { id = paramId } = {} } = this.state;
    return this.qualificationStore
      .createQualificationChecklist()
      .then(() => this.reloadOrganization(id))
      .catch((error) => this.showErrorDialog(error));
  };

  removeFromInbox = (organization, assignedTo) => {
    const {
      profile: { email },
    } = this.state;

    const { inboxState } = this.props.route;

    inboxState?.setForceUpdate(true);
    inboxState?.decreaseCounter("inbox");

    this.setState({ isUpdatingInbox: true }, () => {
      this.organizationStore
        .removeFromInbox(organization, assignedTo)
        .then((organization) => {
          this.setState({ isUpdatingInbox: false, organization });
          if (assignedTo === email) {
            this.emitInboxUpdateEvent(false);
          }
        })
        .catch((error) => {
          this.setState({
            isUpdatingInbox: false,
            error: error.message,
            displayError: true,
          });
        });
    });
  };

  showInboxModal = (organization, assignedTo) => {
    this.setState({
      inboxOrganization: organization,
      inboxAssignedTo: assignedTo,
    });
  };

  closeInboxModal = () => {
    this.setState({ inboxOrganization: null, inboxAssignedTo: null });
  };

  emitInboxUpdateEvent(detail) {
    const inboxUpdateEvent = new CustomEvent("inbox_update", { detail });
    window.dispatchEvent(inboxUpdateEvent);
  }

  updateSelectedTab = (selectedTab) => {
    this.setState({ selectedTab });
  };

  componentWillUnmount() {
    window.document.title = document.title.split("|")[1]
      ? document.title.split("|")[1]
      : "InReach";
  }

  handleCloseDIGDownDialog = () => {
    this.setState({
      openDIGDownDialog: false,
      errorMessageDIGDown: null,
    });
  };

  getCommunications = () => {
    return this.organizationStore.getCommunications();
  };

  getDecisions = () => {
    return this.organizationStore.getDecisions();
  };

  goBack = () => {
    const { location, router } = this.props;
    const { originalLocation = location } = this.state;
    if (originalLocation.state && originalLocation.state.returnTo) {
      return router.push(originalLocation.state.returnTo);
    }

    const query = ((originalLocation.state || {}).filterSource || {}).params;

    router.push({ pathname: "/organizations", query });
  };

  updateSelectedForm = (formId) => {
    this.setState({ selectedForm: formId });
  };

  setScorecard = (scorecard) => {
    this.setState({ scorecard: scorecard });
  };

  getLatestCall = (callId) => {
    const { scorecard: { calls = [] } = {} } = this.state;
    if (callId) return calls.find((call) => call.id === callId) || { id: null };
    else return calls.length ? calls[0] : { id: null };
  };

  getLatestForm = (formId) => {
    const { forms = {} } = this.state;
    if (formId) return forms.find((form) => form.id === formId) || { id: null };
    else return forms.length ? forms[0] : { id: null };
  };

  setTabContent = (key, data) => {
    if (data) {
      const storedData = this.state.tabsContent;
      storedData[key] = data;
      this.setState({ tabsContent: storedData });
    }
  };

  getExistingAndStateCampaign = () => {
    const { existingCampaign, existingSequenceStates } = this.state;
    if (existingCampaign === null) {
      this.campaignStore
        .getOrganizationCampaign()
        .then((existingCampaign) => this.setState({ existingCampaign }))
        .catch((error) => {
          if (error.status === 404) {
            this.setState({ existingCampaign: {} });
          } else {
            console.error(error);
          }
        });
    }
    if (existingSequenceStates === null) {
      this.sequenceStateStore
        .sequenceStates()
        .then((existingSequenceStates) => {
          this.setState({ existingSequenceStates });
        })
        .catch(console.error);
    }
  };

  handleOrganizationInvestmentTypeChange = (investmentType) => {
    return this.organizationStore
      .updateInvestmentType(investmentType)
      .then(this.props.handleOrganizationChange)
      .catch((err) => this.props.showErrorDialog(err));
  };

  handleOrganizationInvestmentStageChange = (investmentStage) => {
    return this.organizationStore
      .updateInvestmentStage(investmentStage)
      .then(this.props.handleOrganizationChange)
      .catch((err) => this.props.showErrorDialog(err));
  };

  handlePreQualificationGoToQualificationClick = (
    investmentType,
    investmentStage
  ) => {
    const { organization } = this.state;
    this.handleOrganizationInvestmentTypeChange(investmentType);
    this.handleOrganizationInvestmentStageChange(investmentStage);
    return this.preQualificationStore
      .goToQualification(investmentType, investmentStage)
      .then(() => {
        this.reloadOrganization(organization.id);
      })
      .catch((error) => console.error(error));
  };

  parseGmailAddress = (emailAddress = "") =>
    emailAddress.includes("<")
      ? emailAddress
          .substr(emailAddress.indexOf("<") + 1, emailAddress.length - 1)
          .replace(">", "")
      : emailAddress;

  getEmailToString = (recipients = []) => {
    if (recipients.length < 1) {
      return "";
    }
    return recipients.map((recipient) => this.parseGmailAddress(recipient));
  };

  findEmailsUsedInMessages = () => {
    const { tabsContent = {} } = this.state;
    const contentKeys = Object.keys(tabsContent) || [];
    if (contentKeys.includes("people") && contentKeys.includes("workflow")) {
      const { events = [] } = tabsContent.workflow;
      const filteredEvents = events.filter((event) =>
        ["gmail_messages", "mailings", "communications"].includes(
          event.collection
        )
      );
      const primaryContact =
        tabsContent.people.find(
          (person) =>
            !!this.state.organization &&
            !!this.state.organization.primary_contact_id &&
            person.id === this.state.organization.primary_contact_id
        ) || {};
      const gmailTimes =
        filteredEvents
          .filter((event) => event.collection === "gmail_messages")
          .map((event) => event.message.sent) || [];

      const emails = filteredEvents
        .filter(
          (event) =>
            event.collection === "gmail_messages" ||
            (event.collection === "mailings" &&
              !gmailTimes.includes(
                new Date(event.mailing.delivered_at).getTime()
              )) ||
            (event.collection === "communications" &&
              event.communication.status === "DELIVERED" &&
              event.communication.sent.length &&
              !gmailTimes.includes(
                parseInt(
                  (
                    event.communication.sent.filter((c) =>
                      ["delivered", "opened"].includes(c.status)
                    )[0] || {}
                  ).sent / 1000
                ) * 1000
              ))
        )
        .map((event) => {
          switch (event.collection) {
            case "gmail_messages":
              const { message: gmailMessage = {} } = event;
              return {
                from: this.parseGmailAddress(gmailMessage.from),
                to: this.getEmailToString(gmailMessage.recipients),
                time: gmailMessage.sent,
                event: event,
              };
            case "mailings":
              const { mailing = {}, person = {} } = event;
              const mailingTime = new Date(mailing.delivered_at).getTime();
              return {
                from: mailing.mailbox_address,
                to: this.getEmailToString([person.email]),
                time: mailingTime,
                event: event,
              };
            case "communications":
              const { message = {}, sent = [{}] } = event.communication;
              const communicationTime =
                parseInt(
                  (
                    sent.filter((c) =>
                      ["delivered", "opened"].includes(c.status)
                    )[0] || {}
                  ).sent / 1000
                ) * 1000;
              return {
                from: message.from_address,
                to: message.to_address,
                time: communicationTime,
                event: event,
              };
          }
        });

      emails.sort((a, b) => a.time > b.time);
      const filteredEmails = emails.filter(
        ({ from: emailFrom = "" }) =>
          emailFrom !== "mailer-daemon@googlemail.com"
      );
      const lastAddressAnswered = filteredEmails.find(
        ({ from: emailFrom = "" }) =>
          !emailFrom.includes("@inreachventures.com")
      );
      const matchesPrimary =
        (lastAddressAnswered || {}).from === primaryContact.email;
      const emailCounts = tabsContent.people.map((person) => {
        const sent = emails.filter(({ to = "" }) =>
          to.includes(person.email)
        ).length;
        const replied = emails.filter(
          ({ from: emailFrom = "" }) => emailFrom === person.email
        ).length;
        return { id: person.id, sent: sent, replied: replied };
      });
      const lastEmail = emails.find((email) => email.from) || null;
      const lastEmailFromEntrepreneur = lastEmail
        ? !(lastEmail.from || "").includes("@inreachventures.com")
        : false;
      return {
        emails: filteredEmails,
        matchesPrimary: matchesPrimary,
        lastEmailFrom: (lastAddressAnswered || {}).from,
        lastEmailFromEntrepreneur,
        emailCounts,
      };
    }
  };

  handleSendFeedbackMenuItemClick = () => {
    this.handleToggleSendFeedbackDialog(true);
  };

  handleToggleSendFeedbackDialog = (
    show = !this.state.showSendFeedbackDialog
  ) => {
    this.setState({
      showSendFeedbackDialog: show,
    });
  };

  handleSendFeedbackClick = (categories = [], notes = "") => {
    const {
      profile: { email = "" },
    } = this.state;
    const feedback = {
      user_id: email,
    };
    if (categories.length > 0) {
      feedback.categories = categories;
    }
    if (notes !== "") {
      feedback.notes = notes;
    }
    return this.organizationStore.sendFeedback(feedback);
  };

  render() {
    const { location, params } = this.props;
    const { mountParcel } = this.props.route;
    const {
      organization,
      snapshot,
      snapshotLoading,
      useSnapshot,
      investors,
      investorsLoading,
      forms,
      selectedForm,
      client,
      profile,
      signals,
      signalsLoading,
      fundingRounds,
      fundingRoundsLoading,
      displayError,
      loading,
      userRoles,
      showAsPublicProfile,
      inboxOrganization,
      inboxAssignedTo,
      error,
      userHasFullAccess,
      isUpdatingInbox,
      openDIGDownDialog,
      errorMessageDIGDown,
      selectedTab,
      reconnectActive,
      reconnect,
      showEditPersonDialog,
      editPersonId,
      showAddPersonDialog,
      tabsContent,
      existingSequenceStates,
      existingCampaign,
      socialMessages,
      structuredReasons = {},
      showSendFeedbackDialog = false,
      feedbackCategories = [],
      originalLocation,
      scorecard,
    } = this.state;

    const recentMessages = this.findEmailsUsedInMessages();

    const currentForm =
      selectedForm && forms
        ? forms.find((form) => form.id === selectedForm)
        : null;

    const organizationDecision =
      Organizations.getOrganizationState(organization, client) || {};

    if (
      loading ||
      !organization ||
      !userRoles ||
      (!showAsPublicProfile && (!profile || !client))
    ) {
      return <Loading fixed={true} />;
    }

    const inbox =
      profile && client
        ? inboxHelper.getInbox(organization, client, profile.email)
        : null;

    const userInboxes =
      profile && client
        ? inboxHelper
            .getUserInboxes(organization, client, profile.email)
            .filter((i) => !!i.inbox)
            .map((i) => i.assigned_to)
        : null;

    const {
      state: { returnUrl = "", filterSource: filters = {} } = {},
      pathname = "",
    } = originalLocation || location;

    const filterSource = {
      route: pathname || null,
      ...filters,
    };
    const investmentValues = Organizations.getInvestmentValues(organization);

    return (
      <div className="organization-view">
        <OrganizationsNavigator
          organizationsNavigatorFetcher={this.organizationsNavigatorFetcher}
          currentId={organization.id}
        />

        <InboxModal
          organization={inboxOrganization}
          assignedTo={inboxAssignedTo}
          handleClose={this.closeInboxModal}
          handleSubmit={this.addToInbox}
        />

        <EditPersonDialog
          onCloseDialog={this.handleCloseEditPersonDialog}
          onSaveDialog={this.handleSaveEditPersonDialog}
          show={showEditPersonDialog}
          personId={editPersonId}
          organizationId={organization.id}
        />

        <AddPersonDialog
          onCloseDialog={this.handleCloseAddPersonDialog}
          show={showAddPersonDialog}
          organizationId={organization.id}
          onAddPerson={this.handlePersonAdd}
        />

        <SendFeedbackDialog
          onCloseDialog={() => this.handleToggleSendFeedbackDialog(false)}
          show={showSendFeedbackDialog}
          handleSendFeedbackClick={this.handleSendFeedbackClick}
          feedbackCategories={feedbackCategories}
        />

        <If condition={error}>
          <ErrorMessage
            error={error || ""}
            open={displayError}
            closeErrorDialog={this.handleErrorClose}
          />
        </If>

        <If condition={organization && this.handleOrganizationIsNotSpamChange}>
          <div className="page-width organization-container">
            <OrganizationTopBar
              formStatus={currentForm ? currentForm.status : null}
              formSubmittedDate={
                currentForm ? currentForm.date_submitted : null
              }
              selectedFormEntry={currentForm ? currentForm.id : null}
              organization={organization}
              userHasFullAccess={userHasFullAccess}
              onStateChange={this.handleOrganizationStateChange}
              onStageChange={this.handleOrganizationStageChange}
              onStatusChange={this.handleOrganizationStatusChange}
              onIsNotSpamChange={this.handleOrganizationIsNotSpamChange}
              temporaryShowNote={this.temporaryShowNote}
              onFollowUpContactChange={
                this.handleOrganizationFollowUpContactChange
              }
              deleteSignals={this.deleteSignals}
              client={client}
              showAsPublicProfile={showAsPublicProfile}
              showErrorDialog={this.showErrorDialog}
              getCommunications={this.getCommunications}
              getDecisions={this.getDecisions}
              handleMergeOrganization={
                this.organizationStore
                  ? this.organizationStore.mergeOrganizations
                  : null
              }
              handleUpdateOrganization={this.initComponent}
              userRoles={userRoles}
              onAssignedToChange={this.handleOrganizationAssignedToChange}
              onOrganizerChange={this.handleOrganizationOrganizerChange}
              onMakeContactChecklistChange={
                this.handleMakeContactChecklistChange
              }
              returnUrl={returnUrl}
              filterSource={filterSource}
              currentUser={profile.email}
              isUpdatingInbox={isUpdatingInbox}
              addToInbox={this.addToInbox}
              removeFromInbox={this.removeFromInbox}
              showInboxModal={this.showInboxModal}
              inbox={inbox}
              userInboxes={userInboxes}
              toggleManualHelp={this.handleToggleRequiresManualHelpChange}
              handleTogglePriority={this.handleTogglePriority}
              organizationDecision={organizationDecision}
              handleFormEvaluationChange={this.handleFormEvaluationChange}
              reloadOrganization={this.reloadOrganization}
              updateSelectedTab={this.updateSelectedTab}
              selectedTab={selectedTab}
              tabsContent={tabsContent}
              forms={forms}
              existingCampaign={existingCampaign}
              existingSequenceStates={existingSequenceStates}
              reconnect={reconnect}
              handlePreQualificationGoToQualificationClick={
                this.handlePreQualificationGoToQualificationClick
              }
              investmentValues={investmentValues}
              getLatestCall={this.getLatestCall}
              handleOrganizationChange={this.handleOrganizationChange}
              recentMessages={recentMessages}
              resetSocialMessagesReminders={this.resetSocialMessagesReminders}
              createQualificationChecklist={this.createQualificationChecklist}
              structuredReasons={structuredReasons}
              handleSendFeedbackMenuItemClick={
                this.handleSendFeedbackMenuItemClick
              }
              mountParcel={mountParcel}
              scorecard={scorecard}
              fromCreatedAt={
                ((this.props.location || {}).query || {}).created_at
              }
            />
            <OrganizationTabsContent
              organization={organization}
              snapshot={snapshot}
              snapshotLoading={snapshotLoading}
              useSnapshot={useSnapshot}
              investors={investors}
              investorsLoading={investorsLoading}
              forms={forms}
              selectedForm={selectedForm}
              updateSelectedForm={this.updateSelectedForm}
              signals={signals}
              signalsLoading={signalsLoading}
              fundingRounds={fundingRounds}
              fundingRoundsLoading={fundingRoundsLoading}
              client={client}
              userHasFullAccess={userHasFullAccess}
              temporaryExpirationDate={this.temporaryExpirationDate}
              temporarySignature={this.temporarySignature}
              temporaryShowNote={this.temporaryShowNote}
              showAsPublicProfile={showAsPublicProfile}
              userRoles={userRoles}
              currentUser={profile.email}
              reloadOrganization={this.reloadOrganization}
              showErrorDialog={this.showErrorDialog}
              organizationStore={this.organizationStore}
              params={params}
              handleOrganizationChange={this.handleOrganizationChange}
              handleOrganizationStageChange={this.handleOrganizationStageChange}
              organizationDecision={organizationDecision}
              selectedTab={selectedTab}
              handleMakeContactChecklistChange={
                this.handleMakeContactChecklistChange
              }
              editPerson={this.editPerson}
              addPerson={this.addPerson}
              onStatusChange={this.handleOrganizationStatusChange}
              onStatusesChange={this.handleOrganizationStatusesChange}
              handleStopAllCampaigns={this.handleOrganizationStopAllCampaigns}
              handleStopEmails={this.handleOrganizationStopChange}
              handleReconnectCancel={this.handleReconnectCancel}
              reconnectActive={reconnectActive}
              reconnect={reconnect}
              handleAssignCampaignCancel={this.handleAssignCampaignCancel}
              handleAssignCampaignRetry={this.handleAssignCampaignRetry}
              setTabContent={this.setTabContent}
              handleOrganizationInvestmentTypeChange={
                this.handleOrganizationInvestmentTypeChange
              }
              handleOrganizationInvestmentStageChange={
                this.handleOrganizationInvestmentStageChange
              }
              investmentValues={investmentValues}
              getLatestCall={this.getLatestCall}
              getLatestForm={this.getLatestForm}
              recentMessages={recentMessages}
              socialMessages={socialMessages}
              sendSocialMessage={this.sendSocialMessage}
              structuredReasons={structuredReasons}
              mountParcel={mountParcel}
              setScorecard={this.setScorecard}
              scorecard={scorecard}
            />
          </div>
        </If>
        <DIGDownDialog
          open={openDIGDownDialog}
          errorMessage={errorMessageDIGDown}
          handleClose={this.handleCloseDIGDownDialog}
        />
      </div>
    );
  }
}

export default withRouter(Organization);
