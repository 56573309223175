import config from "../../config";

export function update(client, id, token, metadata) {
  if (!id) {
    return Promise.reject(new Error("User ID is required"));
  }

  const params = new URLSearchParams({ client });

  return fetch(`${config.api.host}/users/${id}/user_metadata?${params}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(metadata),
  }).then((res) =>
    res.ok
      ? res.json()
      : res.json().then(({ message }) => Promise.reject(message))
  );
}
