import React from "react";
import PropTypes from "prop-types";

import TimelineSectionContent from "./TimelineSectionContent.js";

export default class TimelineSectionToDo extends React.Component {
  static propTypes = {
    todo: PropTypes.array.isRequired,
  };

  render() {
    const { todo } = this.props;
    return (
      <div className="timeline-section todo-section">
        <h3>Todo</h3>
        <Choose>
          <When condition={todo.length === 0}>
            <p className="no-content">Nothing to do</p>
          </When>
          <Otherwise>
            <TimelineSectionContent
              section="todo"
              content={todo}
              {...this.props}
            />
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
