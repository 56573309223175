import PropTypes from "prop-types";
import React from "react";
import IconMenu from "material-ui/IconMenu";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import IconButton from "material-ui/IconButton/IconButton";
import MenuItem from "material-ui/MenuItem";
import RaisedButtonWithIcon from "../organization/RaisedButtonWithIcon";
import CommunicationLocationOn from "material-ui/svg-icons/communication/location-on";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import Image from "../Image";
import DoNoContactStore from "../../storage/DoNotContactStore";
import { ToolbarTitle } from "material-ui/Toolbar";
import DoNotContact from "../DoNotContact";

import sourceImage from "../../lib/sourceImage";
import { Snackbar } from "material-ui";
import JsonDev from "../JsonDev";
import auth from "../../storage/Auth";
import request from "superagent";

export default class PersonHeader extends React.Component {
  static childContextTypes = {
    muiTheme: PropTypes.object,
  };
  static propTypes = {
    person: PropTypes.object.isRequired,
    onPersonChange: PropTypes.func.isRequired,
  };

  getChildContext() {
    return {
      muiTheme: getMuiTheme(),
    };
  }

  state = {
    deleted: false,
    userRoles: [],
  };

  goTo = (website) => {
    window.open(website);
    return;
  };

  doNotContactStore = new DoNoContactStore(this.props.person.id);

  componentWillMount() {
    return auth.getAuthData().then(({ roles }) => {
      this.setState({ userRoles: roles });
    });
  }

  doNotContact = (shouldDelete) => {
    this.doNotContactStore.doNotContact(shouldDelete).then((dnc) => {
      if (dnc.delete) {
        this.setState({
          deleted: true,
        });
      } else {
        this.props.onPersonChange();
      }
    });
  };

  render() {
    const person = this.props.person;
    const { userRoles = [] } = this.state;

    return (
      <div className="person-header">
        <Snackbar
          open={this.state.deleted}
          action="dismiss"
          message="This Person has been DELETED"
          onRequestClose={() => this.setState({ deleted: false })}
          onActionClick={() => this.setState({ deleted: false })}
        />
        <IconMenu
          className="more-menu"
          iconButtonElement={
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          }
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          targetOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <MenuItem
            primaryText="Edit"
            onClick={() => this.goTo(`/people/${person.id}/edit`)}
          />
          <MenuItem
            primaryText="Do Not Contact"
            onClick={() => this.doNotContact(false)}
          />
          <MenuItem
            primaryText="Do Not Contact and Delete"
            onClick={() => this.doNotContact(true)}
          />
        </IconMenu>
        <div className="full-width main-info flex-row">
          <div>
            <Image width={80} className="avatar" url={person.image_url} />
            <div>
              <div className="name">
                <h5>{person.name}</h5>
              </div>
              <div>
                {Object.keys(
                  person.source_references || person.source_refs || {}
                ).map(function (provider) {
                  return (
                    <img
                      key={provider}
                      className="source-person-logo"
                      src={sourceImage(provider)}
                    />
                  );
                })}
              </div>
              <If condition={person.experience && person.experience.length > 0}>
                <p className="position">
                  {person.experience[0].title} at {person.experience[0].company}
                  <If
                    condition={
                      person.location &&
                      person.location.city &&
                      person.location.country
                    }
                  >
                    &nbsp; | &nbsp;
                    <CommunicationLocationOn
                      style={{ width: "18px", height: "18px" }}
                      color="darkgray"
                    />
                    {person.location.city}, {person.location.country}
                  </If>
                </p>
              </If>
              <If condition={!!person.do_not_contact}>
                <DoNotContact />
              </If>
            </div>
          </div>
        </div>
        <div className="full-width actions flex-row">
          <div className="main-person-info flex-row">
            <div className="button-container">
              <If condition={person.url}>
                <RaisedButtonWithIcon
                  iconOnly={true}
                  label="Website"
                  icon="website primary"
                  tooltip={person.url}
                  onClick={() => this.goTo(person.url)}
                />
              </If>
              <If condition={person.twitter_url}>
                <RaisedButtonWithIcon
                  iconOnly={true}
                  label="Twitter"
                  icon="twitter primary"
                  onClick={() => this.goTo(person.twitter_url)}
                />
              </If>
              <If condition={person.angellist_url}>
                <RaisedButtonWithIcon
                  iconOnly={true}
                  label="Angellist"
                  icon="angellist primary"
                  onClick={() => this.goTo(person.angellist_url)}
                />
              </If>
              <If condition={person.facebook_url}>
                <RaisedButtonWithIcon
                  iconOnly={true}
                  label="Facebook"
                  icon="facebook primary"
                  onClick={() => this.goTo(person.facebook_url)}
                />
              </If>
              <If condition={person.github_url}>
                <RaisedButtonWithIcon
                  iconOnly={true}
                  label="Github"
                  icon="github primary"
                  onClick={() => this.goTo(person.github_url)}
                />
              </If>
              <If condition={person.linkedin_url}>
                <RaisedButtonWithIcon
                  iconOnly={true}
                  label="Linkedin"
                  icon="linkedin primary"
                  onClick={() => this.goTo(person.linkedin_url)}
                />
              </If>
              <If condition={person.email}>
                <RaisedButtonWithIcon
                  iconOnly={true}
                  label="Mail"
                  icon="mail primary"
                  onClick={() => this.goTo(`mailto:${person.email}`)}
                />
              </If>
              <If condition={userRoles.includes("admin")}>
                <span className="organization-link top-bar-icons">
                  <JsonDev
                    person={person}
                    objectType={"people"}
                    className="json-icon"
                  />
                </span>
              </If>
            </div>
            {/*<Tags data={person.skills}/>*/}
          </div>
        </div>
      </div>
    );
  }
}
