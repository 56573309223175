import React from "react";
import PropTypes from "prop-types";

import Loading from "../loading/Loading";

import Checkbox from "material-ui/Checkbox";
import { FlatButton } from "material-ui";

export default class SendEmails extends React.Component {
  static propTypes = {
    stop: PropTypes.bool,
    handleStopAllCampaigns: PropTypes.func.isRequired,
    handleStopEmails: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  handleStopAll = () => {
    const { handleStopAllCampaigns } = this.props;
    this.setState({
      loading: true,
    });
    handleStopAllCampaigns().then(() => {
      this.setState({ loading: false });
    });
  };

  handleEnableEmails = () => {
    const { handleStopEmails } = this.props;
    this.setState({
      loading: true,
    });
    handleStopEmails(false).then(() => {
      this.setState({ loading: false });
    });
  };

  render() {
    const { stop } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <Choose>
          <When condition={loading}>
            <Loading small={true} dontFill={true} align="left" size={24} />
          </When>

          <When condition={!!stop}>
            <Checkbox
              label={"Emails disabled"}
              checked={false}
              onClick={this.handleEnableEmails}
              iconStyle={{
                color: "#939598",
                fill: "#939598",
              }}
              labelStyle={{
                color: "#939598",
                margin: 0,
                fontWeight: "normal",
              }}
            />
          </When>

          <Otherwise>
            <FlatButton
              onClick={this.handleStopAll}
              label={"Stop all running campaigns"}
              style={{
                backgroundColor: "#337ab7",
                height: "35px",
              }}
              labelStyle={{
                color: "#ffffff",
                fontSize: "10px",
                padding: "5px",
              }}
            />
          </Otherwise>
        </Choose>
      </React.Fragment>
    );
  }
}
