import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import People from "material-ui/svg-icons/social/people";

import NewTag from "../NewTag";

import { getDataWrapperClassNames } from "../../../../lib/helpers";
import Tooltip from "../../../Tooltip";

class Employees extends React.Component {
  static propTypes = {
    employees: PropTypes.object.isRequired,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool,
    compressed: PropTypes.bool.isRequired,
  };

  state = {
    showTooltip: false,
  };

  hasChanged = () => {
    const {
      employees: {
        min = 0,
        max = 0,
        inDIG = 0,
        snapshot: {
          min: snapshotMin = 0,
          max: snapshotMax = 0,
          inDIG: snapshotInDIG = 0,
        } = {},
      },
      useSnapshot = false,
      previous = false,
    } = this.props;
    return useSnapshot && !previous
      ? min !== snapshotMin || max !== snapshotMax || inDIG !== snapshotInDIG
      : false;
  };

  getTooltipText = () => {
    const { compressed = false } = this.props;
    const text = [];
    if (compressed) {
      text.push("No. of Employees");
    }
    return text.join("<br/>");
  };

  getEmployeesString = () => {
    const { employees: { min = 0, max = 0, inDIG = 0 } = {} } = this.props;
    return `${min} - ${max} (${inDIG} in DIG)`;
  };

  render() {
    const {
      employees: { snapshot = {} } = {},
      previous,
      compressed = false,
    } = this.props;
    const { showTooltip = false } = this.state;
    const hasChanged = this.hasChanged();
    return (
      <div
        className={getDataWrapperClassNames(compressed, ["employees"])}
        onMouseLeave={() => {
          if (compressed) {
            this.setState({ showTooltip: false });
          }
        }}
        onMouseEnter={() => {
          if (compressed) {
            this.setState({ showTooltip: true });
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <If condition={hasChanged}>
          <NewTag source="employees" snapshot={snapshot} />
        </If>
        <div className="data-header">
          <If condition={previous}>Previous&nbsp;</If>
          Employees
        </div>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
        />
        <div className="data-icon">
          <People className="data-icon-svg" />
        </div>
        <div className="data-content text">{this.getEmployeesString()}</div>
      </div>
    );
  }
}

export default withRouter(Employees);
