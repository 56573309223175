import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import Locations from "./summary/Locations";
import FundingAmount from "./FundingAmount";
import LastFundingRound from "./summary/LastFundingRound";
import Investors from "./summary/Investors";
import Employees from "./summary/Employees";
import Person from "./people/person/Person";
import AppProduct from "./product/product/AppProduct";
import WebProduct from "./product/product/WebProduct";
import _ from "underscore";

class NewTag extends React.Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
    snapshot: PropTypes.object.isRequired,
    organization: PropTypes.object,
  };

  state = {
    showPreviousState: false,
  };

  render() {
    const { source, snapshot, organization = {} } = this.props;
    const { showPreviousState } = this.state;
    return (
      <div
        className={`new-tag-wrapper ${source} section`}
        onMouseLeave={() => {
          this.setState({ showPreviousState: false });
        }}
        onMouseEnter={() => {
          this.setState({ showPreviousState: true });
        }}
      >
        <div className={`new-tag${showPreviousState ? " hover" : ""}`}>New</div>
        <If condition={showPreviousState}>
          <div className="new-tag-previous-state">
            <Choose>
              <When condition={source === "locations"}>
                <Locations locations={snapshot} previous={true} />
              </When>
              <When condition={source === "funding-amount"}>
                <FundingAmount fundingAmount={snapshot} previous={true} />
              </When>
              <When condition={source === "last-funding-round"}>
                <LastFundingRound fundingRounds={snapshot} previous={true} />
              </When>
              <When condition={source === "investors"}>
                <Investors investors={snapshot} previous={true} />
              </When>
              <When condition={source === "employees"}>
                <Employees employees={snapshot} previous={true} />
              </When>
              <When condition={source === "person"}>
                <Choose>
                  <When condition={Object.keys(snapshot).length > 0}>
                    <Person
                      person={snapshot}
                      previous={true}
                      organization={organization}
                      source="new"
                    />
                  </When>
                  <Otherwise>
                    <div className="data-wrapper">
                      <div
                        className="data-content text empty"
                        style={{ width: "200px" }}
                      >
                        This person was not in DIG when the last decision was
                        made
                      </div>
                    </div>
                  </Otherwise>
                </Choose>
              </When>
              <When condition={source === "web-product"}>
                <If condition={_.isEmpty(snapshot)}>
                  <div className="data-wrapper">
                    <div className="data-content text empty">
                      This product was not in DIG when the last decision was
                      made
                    </div>
                  </div>
                </If>
              </When>
              <When condition={source === "app-product"}>
                <If
                  condition={
                    Object.keys(snapshot).filter(
                      (key) =>
                        ![
                          "analytics",
                          "businessModel",
                          "decisionCreatedAt",
                          "revenue",
                        ].includes(key)
                    ).length === 0
                  }
                >
                  <div className="data-wrapper">
                    <div className="data-content text empty">
                      This product was not in DIG when the last decision was
                      made
                    </div>
                  </div>
                </If>
              </When>
            </Choose>
          </div>
        </If>
      </div>
    );
  }
}

export default withRouter(NewTag);
