import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import NumberOfFundingRounds from "./NumberOfFundingRounds";
import FundingAmount from "../FundingAmount";
import FundingRoundsTable from "./FundingRoundsTable";

class FundingRounds extends React.Component {
  static propTypes = {
    fundingRounds: PropTypes.object.isRequired,
    fundingRoundsLoading: PropTypes.bool.isRequired,
    investors: PropTypes.array.isRequired,
    investorsLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool.isRequired,
  };

  render() {
    const {
      fundingRounds: { numberOfFundingRounds, fundingAmount, fundingRounds },
      fundingRoundsLoading,
      investors = [],
      investorsLoading,
      useSnapshot = false,
    } = this.props;
    return (
      <div className="funding-rounds-wrapper">
        <div className="grid-container funding-rounds-grid-container">
          <div className="funding-rounds-grid-item number-of-funding-rounds-grid-item">
            <NumberOfFundingRounds
              numberOfFundingRounds={numberOfFundingRounds}
            />
          </div>
          <div className="funding-rounds-grid-item border-1-grid-item" />
          <div className="funding-rounds-grid-item funding-amount-grid-item">
            <FundingAmount
              fundingAmount={fundingAmount}
              useSnapshot={useSnapshot}
            />
          </div>
          <div className="funding-rounds-grid-item funding-rounds-table-grid-item">
            <FundingRoundsTable
              fundingRounds={fundingRounds}
              fundingRoundsLoading={fundingRoundsLoading}
              investors={investors}
              investorsLoading={investorsLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FundingRounds);
