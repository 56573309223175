import PropTypes from "prop-types";
import React from "react";
import RaisedButton from "material-ui/RaisedButton";

import State from "./State";

export default class StateSummary extends State {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    state: PropTypes.string.isRequired,
  };

  render() {
    const buttonsLabel = {
        never: "Never",
        missed_opportunity: "Missed Opportunity",
        not_now: "Not Now",
        contact: "In Progress",
      },
      organization = this.props.organization,
      decision = this.organizationDecisions(organization);

    if (this.state.needsEvaluationAfterTheForm && decision) {
      return (
        <div className="summary-state-group disable-buttons">
          <If condition={decision.state === "never"}>
            <RaisedButton
              value="never"
              disabled={true}
              label={buttonsLabel.never}
              icon={<div className="icon thumb-down" />}
              className={this.buttonClass("never")}
            />
          </If>
          <If condition={decision.state === "missed_opportunity"}>
            <RaisedButton
              value="missed_opportunity"
              disabled={true}
              label={buttonsLabel.missed_opportunity}
              icon={<div className="icon rocket" />}
              className={this.buttonClass("missed_opportunity")}
            />
          </If>
          <If condition={decision.state === "not_now"}>
            <RaisedButton
              value="not_now"
              disabled={true}
              label={buttonsLabel.not_now}
              icon={<div className="icon thumb-stop" />}
              className={this.buttonClass("not_now")}
            />
          </If>
          <If condition={decision.state === "contact"}>
            <RaisedButton
              value="contact"
              disabled={true}
              label={buttonsLabel.contact}
              icon={<div className="icon thumb-up" />}
              className={this.buttonClass("contact")}
            />
          </If>
        </div>
      );
    }

    return false;
  }
}
