import React from "react";
import TextField from "material-ui/TextField";
import DatePicker from "material-ui/DatePicker";
import ActionDelete from "material-ui/svg-icons/action/delete";
import FlatButton from "material-ui/FlatButton";

import moment from "moment";

class EditExperience extends React.Component {
  constructor(props) {
    super(props);
    this.computeDuration = this.computeDuration.bind(this);
  }

  goTo(website) {
    window.open(website);
    return;
  }

  toMoment(epoch) {
    return epoch ? moment(parseInt(epoch))._d : null;
  }

  createMomentDateFromString(str) {
    if (str) {
      if (str === "Present") {
        return moment();
      }

      const strSplit = str.split(" "),
        month =
          strSplit.length > 0
            ? parseInt(moment().month(strSplit[0]).format("M"))
            : null,
        year = strSplit.length > 1 ? parseInt(strSplit[1]) : null;

      if (month && year) {
        return moment().set({ year: year, month: month - 1, date: 1 });
      }
    }
    return null;
  }

  computeDuration(startMoment, endMoment) {
    if (startMoment && endMoment) {
      const diff = moment.duration(endMoment.diff(startMoment)),
        years = diff.asYears() ? parseInt(diff.asYears()) : 0,
        months = diff.asMonths() ? parseInt(diff.asMonths()) % 12 : 0;

      return years && months
        ? `${years} years ${months} months`
        : months
        ? `${months} months`
        : null;
    }

    return null;
  }

  render() {
    const startMoment = this.createMomentDateFromString(this.props.start),
      endMoment = this.createMomentDateFromString(this.props.end);

    return (
      <div className="edit-experience">
        <div className="experience-row">
          <TextField
            name={`edit-experience-title${this.props.index}`}
            className="edit-experience-title"
            floatingLabelText="Title"
            value={this.props.title || ""}
            onChange={(event) =>
              this.props.onChangeExperience(
                this.props.index,
                event.target.value,
                this.props.start,
                this.props.end,
                this.props.duration,
                this.props.company
              )
            }
          />
          <TextField
            className="edit-experience-company"
            name={`edit-experience-company${this.props.index}`}
            floatingLabelText="Company"
            value={this.props.company || ""}
            onChange={(event) =>
              this.props.onChangeExperience(
                this.props.index,
                this.props.title,
                this.props.start,
                this.props.end,
                this.props.duration,
                event.target.value
              )
            }
          />
        </div>
        <div className="experience-row">
          <DatePicker
            floatingLabelText="Starting date"
            hintText="starting date"
            value={startMoment ? startMoment._d : null}
            onChange={(defaultParam, momentDate) => {
              const momentValue = momentDate
                  ? moment(momentDate.valueOf())
                  : null,
                strMoment = `${momentValue.format(
                  "MMMM"
                )} ${momentValue.year()}`;
              this.props.onChangeExperience(
                this.props.index,
                this.props.title,
                strMoment,
                this.props.end,
                this.computeDuration(momentValue, endMoment),
                this.props.company
              );
            }}
            autoOk={true}
          />
          <DatePicker
            floatingLabelText="Ending date"
            hintText="ending date"
            value={endMoment ? endMoment._d : null}
            onChange={(defaultParam, momentDate) => {
              const momentValue = momentDate
                  ? moment(momentDate.valueOf())
                  : null,
                strMoment = `${momentValue.format(
                  "MMMM"
                )} ${momentValue.year()}`;
              this.props.onChangeExperience(
                this.props.index,
                this.props.title,
                this.props.start,
                strMoment,
                this.computeDuration(startMoment, momentValue),
                this.props.company
              );
            }}
            autoOk={true}
          />
          <div className="experience-duration">
            {this.props.duration ? `Duration: ${this.props.duration}` : false}
          </div>
        </div>
        <FlatButton
          style={{ alignSelf: "flex-start" }}
          label="Delete"
          labelStyle={{ color: "#C30000" }}
          icon={<ActionDelete color={"#C30000"} />}
          onClick={() => this.props.onDeleteExperience(this.props.index)}
        />
      </div>
    );
  }
}

export default EditExperience;
