import PropTypes from "prop-types";
import React from "react";
import Loading from "../loading/Loading";
import RaisedButtonWithIcon from "../organization/RaisedButtonWithIcon";
import Divider from "material-ui/Divider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import ExternalLink from "../ExternalLink";
import Image from "../Image";

import ClearbitPersonStore from "../../storage/ClearbitPersonStore";

class Location extends React.Component {
  render() {
    if (this.props.person.location) {
      return <span>{this.props.person.location}</span>;
    }
    if (this.props.person.geo) {
      const geo = [];
      if (this.props.person.geo.city) {
        geo.push(this.props.person.geo.city);
      }
      if (this.props.person.geo.state) {
        geo.push(this.props.person.geo.state);
      }
      if (this.props.person.geo.country) {
        geo.push(this.props.person.geo.country);
      }
      return <span>{geo.join(", ")}</span>;
    }
    return false;
  }
}

Location.propTypes = {
  person: PropTypes.object.isRequired,
};

class Employment extends React.Component {
  render() {
    const employment = this.props.person.employment;
    if (!employment) {
      return <span />;
    }

    return (
      <div className="employment">
        <label>Employment</label>
        <div className="fields-container">
          <If condition={employment.name}>
            <div>
              <label>Name</label>
              <p>{employment.name}</p>
            </div>
          </If>
          <If condition={employment.title}>
            <div>
              <label>Title</label>
              <p>{employment.title}</p>
            </div>
          </If>
          <If condition={employment.role}>
            <div>
              <label>Role</label>
              <p>{employment.role}</p>
            </div>
          </If>
          <If condition={employment.seniority}>
            <div>
              <label>Seniority</label>
              <p>{employment.seniority}</p>
            </div>
          </If>
          <If condition={employment.domain}>
            <div>
              <label>Domain</label>
              <p>{employment.domain}</p>
            </div>
          </If>
        </div>
      </div>
    );
  }
}

Employment.propTypes = {
  person: PropTypes.object.isRequired,
};

class Github extends React.Component {
  goTo(website) {
    window.open(website);
    return;
  }

  render() {
    const github = this.props.person.github;
    if (!github) {
      return false;
    }

    return (
      <div className="github">
        <label> Github </label>
        <div className="github-user fields-container">
          <div className="main-info">
            <Image url={github.avatar} />
            <div className="info">
              <h4
                onClick={() => {
                  this.goTo(`https://github.com/${github.handle}`);
                }}
              >
                Github profile
              </h4>
            </div>
          </div>
          <div className="stats-info">
            <div className="followers_count">
              <label> Followers </label>
              <p> {github.followers} </p>
            </div>
            <div className="friends_count">
              <label> Following </label>
              <p> {github.following} </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Github.propTypes = {
  person: PropTypes.object.isRequired,
};

class Twitter extends React.Component {
  goTo(website) {
    window.open(website);
    return;
  }

  render() {
    const twitter = this.props.person.twitter;
    if (!twitter) {
      return <span />;
    }

    return (
      <div className="github">
        <label> Twitter </label>
        <div className="twitter-user">
          <div className="main-info">
            <Image url={twitter.avatar} />
            <div className="info">
              <h4
                onClick={() => {
                  this.goTo(`https://twitter.com/${twitter.handle}`);
                }}
              >
                Twitter profile
              </h4>
            </div>
          </div>
          <div className="stats-info">
            <div className="followers_count">
              <label> Followers </label>
              <p> {twitter.followers} </p>
            </div>
            <div className="friends_count">
              <label> Following </label>
              <p> {twitter.following} </p>
            </div>
            <div className="statuses">
              <label> Statuses </label>
              <p> {twitter.statuses} </p>
            </div>
            <div className="favorites">
              <label> Favorites </label>
              <p> {twitter.favorites} </p>
            </div>
            <If condition={twitter.site}>
              <RaisedButtonWithIcon
                iconOnly={true}
                label="Website"
                icon="website primary"
                onClick={() => this.goTo(twitter.site)}
              />
            </If>
          </div>
        </div>
      </div>
    );
  }
}

Twitter.propTypes = {
  person: PropTypes.object.isRequired,
};

class Angellist extends React.Component {
  render() {
    const angellist = this.props.person.angellist;
    if (!angellist) {
      return <span />;
    }

    return (
      <div>
        <h3>Angellist</h3>
        <dl>
          <dt>Link</dt>
          <dd>
            <ExternalLink to={`https://angellist.com/${angellist.handle}`} />
          </dd>
          <dt>Avatar</dt>
          <dd>
            <Image url={angellist.avatar} />
          </dd>
          <dt>Bio</dt>
          <dd>{angellist.bio}</dd>
          <dt>Blog</dt>
          <dd>
            <ExternalLink to={angellist.blog} />
          </dd>
          <dt>Site</dt>
          <dd>
            <ExternalLink to={angellist.site} />
          </dd>
          <dt>Followers</dt>
          <dd>{twitter.followers}</dd>
        </dl>
      </div>
    );
  }
}

Angellist.propTypes = {
  person: PropTypes.object.isRequired,
};

class ClearbitPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person: null,
    };

    this.handlePersonChange = this.handlePersonChange.bind(this);
  }

  componentWillMount() {
    this.personstore = new ClearbitPersonStore(this.props.id);
    this.personstore.getModel().then(this.handlePersonChange);
  }

  handlePersonChange(person) {
    this.setState({ person: person });
  }

  goTo(website) {
    window.open(website);
    return;
  }

  render() {
    const person = this.state.person;
    if (!person) {
      return <Loading />;
    }

    return (
      <div className="clearbit-user">
        <div className="main-info">
          <Image url={person.avatar} />
          <div className="info">
            <h4
              onClick={() => {
                this.goTo(person.site);
              }}
            >
              {person.name ? person.name.fullName : ""}
            </h4>
            <p className="description">{person.bio}</p>
          </div>
        </div>
        <div className="button-container">
          <If condition={person.email}>
            <RaisedButtonWithIcon
              iconOnly={true}
              label="Mail"
              icon="mail primary"
              onClick={() => this.goTo(`mailto:${person.email}`)}
            />
          </If>
          <If condition={person.facebook && person.facebook.handle}>
            <RaisedButtonWithIcon
              iconOnly={true}
              label="Facebook"
              icon="facebook primary"
              onClick={() =>
                this.goTo(`https://facebook.com/${person.facebook.handle}`)
              }
            />
          </If>
          <If condition={person.linkedin && person.linkedin.handle}>
            <RaisedButtonWithIcon
              iconOnly={true}
              label="Linkedin"
              icon="linkedin primary"
              onClick={() =>
                this.goTo(`https://linkedin.com/${person.linkedin.handle}`)
              }
            />
          </If>
        </div>
        <Divider style={{ width: "100%", marginTop: "10px" }} />
        <Employment person={person} />
        <Divider style={{ width: "100%", marginTop: "10px" }} />
        <Github person={person} />
        <Divider style={{ width: "100%", marginTop: "10px" }} />
        <Twitter person={person} />
        <Divider style={{ width: "100%", marginTop: "10px" }} />
        <Angellist person={person} />
      </div>
    );
  }
}

ClearbitPerson.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ClearbitPerson;
