import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import ToggleShowMoreButton from "../ToggleShowMoreButton";
import WebProduct from "./product/WebProduct";
import AppProduct from "./product/AppProduct";

class OtherProducts extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    signalsLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool.isRequired,
  };

  state = {
    showProducts: false,
  };

  toggleShowMoreProductsClickHandler = () => {
    this.setState({
      showProducts: !this.state.showProducts,
    });
  };

  render() {
    const {
      organization,
      products = [],
      signalsLoading,
      useSnapshot = false,
      mlUpdates,
      organizationDecision,
    } = this.props;
    const { showProducts } = this.state;
    return (
      <div className="other-products">
        <ToggleShowMoreButton
          text={
            showProducts
              ? `Hide More Products (${products.length})`
              : `Show More Products (${products.length})`
          }
          clickHandler={this.toggleShowMoreProductsClickHandler}
          show={!showProducts}
        />
        <If condition={showProducts}>
          <div className="section-title main">Other Products</div>
          <Choose>
            <When condition={products.length === 0}>
              <div className="section-no-content">No Other Products</div>
            </When>
            <Otherwise>
              <div className="products-container">
                {products.map((product) => (
                  <Choose>
                    <When condition={product.type === "web"}>
                      <WebProduct
                        organization={organization}
                        product={product}
                        source="other"
                        key={product.id}
                        signalsLoading={signalsLoading}
                        useSnapshot={useSnapshot}
                        mlUpdates={mlUpdates}
                        organizationDecision={organizationDecision}
                      />
                    </When>
                    <Otherwise>
                      <AppProduct
                        organization={organization}
                        product={product}
                        source="other"
                        key={product.id}
                        signalsLoading={signalsLoading}
                        useSnapshot={useSnapshot}
                        mlUpdates={mlUpdates}
                        organizationDecision={organizationDecision}
                      />
                    </Otherwise>
                  </Choose>
                ))}
              </div>
              <div className="bottom-other-section-hide-button">
                <ToggleShowMoreButton
                  text={`Hide More Products (${products.length})`}
                  clickHandler={this.toggleShowMoreProductsClickHandler}
                  show={false}
                />
              </div>
            </Otherwise>
          </Choose>
        </If>
      </div>
    );
  }
}

export default withRouter(OtherProducts);
