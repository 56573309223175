import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import { capitalizeString } from "../../../../lib/helpers";
import Tooltip from "../../../Tooltip";

class InvestmentStage extends React.Component {
  static propTypes = {
    investmentStage: PropTypes.string.isRequired,
  };

  state = {
    showTooltip: false,
  };

  hasError = () => {
    const { investmentStage } = this.props;
    return (
      !/pre[-_ ]product/g.test(investmentStage) &&
      !/pre[-_ ]product[-_ ]market[-_ ]fit/g.test(investmentStage) &&
      !/pre[-_ ]marketing[-_ ]at[-_ ]scale/g.test(investmentStage)
    );
  };

  getInvestmentStageClasses = () => {
    const classNames = ["data-content", "text"];
    if (this.hasError()) {
      classNames.push("red-flag");
    }
    return classNames.join(" ");
  };

  getInvestmentStageString = () => {
    const { investmentStage } = this.props;
    return `${capitalizeString(investmentStage.replace(/[-_]/g, " "))}`;
  };

  render() {
    const { investmentStage = "" } = this.props;
    const { showTooltip } = this.state;
    return (
      <div
        className="data-wrapper investment-stage"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: false });
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: true });
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-header">Investment Stage</div>
        <Choose>
          <When condition={investmentStage === ""}>
            <div className="data-content text empty">Unknown</div>
          </When>
          <Otherwise>
            <Tooltip
              parent={this._parent}
              showTooltip={showTooltip}
              text="Stage is not pre-product, pre-product-market-fit or pre-marketing-at-scale"
            />
            <div className={this.getInvestmentStageClasses()}>
              {this.getInvestmentStageString()}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(InvestmentStage);
