import React from "react";
import SourceScorer from "../storage/SourceScorer";
import Loading from "./loading/Loading";

export default class SourceScores extends React.Component {
  sourceScorer = new SourceScorer();

  state = {
    uploading: false,
    error: null,
    score: null,
  };

  handleUpload() {
    this.setState({ uploading: true });
    const reader = new FileReader();
    reader.onload = (event) => {
      this.sourceScorer
        .scoreCSV(event.target.result)
        .then((score) => {
          this.setState({
            uploading: false,
            score,
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ error });
        });
    };
    reader.onerror = (error) => {
      console.error(error);
      this.setState({ error });
    };
    reader.readAsText(this._sourceScore.files[0]);
  }

  setRef(el) {
    this._sourceScore = el;
  }

  render() {
    if (this.state.uploading) {
      return <Loading fixed={true} />;
    }
    if (this.state.score) {
      return (
        <div>
          <h1>Source Score</h1>
          <dl>
            <dt>Total</dt>
            <dd>{parseInt(this.state.score.total, 10)}</dd>
            <dt>Coverage</dt>
            <dd>{parseFloat(this.state.score.coverage).toFixed(1)}% exist</dd>
            <dt>Quality</dt>
            <dd>
              {parseFloat(this.state.score.quality).toFixed(1)}% of those are
              interesting
            </dd>
          </dl>
        </div>
      );
    }
    if (this.state.error) {
      return (
        <div>
          <h1>Error</h1>
          <p>{this.state.error}</p>
        </div>
      );
    }

    return (
      <input
        ref={(el) => this.setRef(el)}
        type="file"
        onChange={() => this.handleUpload()}
        accept=".csv"
      />
    );
  }
}
