import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import Tooltip from "../../../Tooltip";

import sourceImage from "../../../../lib/sourceImage";

const IGNORED_SOURCES = [
  "INREACH",
  "PRIORI",
  "GOOGLECSE",
  "STREAK",
  "CALLFEEDBACK",
  "RECONNECT",
  "QUALIFICATION",
  "PREQUALIFICATION",
  "OUTREACH",
  "ASSIGN_CAMPAIGNS",
];

class Source extends React.Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
  };

  state = {
    showTooltip: false,
  };

  render() {
    const { source } = this.props;
    const { showTooltip } = this.state;

    return (
      <div
        className="source"
        ref={(el) => (this._parent = el)}
        onMouseLeave={() => {
          this.setState({ showTooltip: false });
        }}
        onMouseEnter={() => {
          this.setState({ showTooltip: true });
        }}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={source}
          customStyles={{}}
        />
        <div
          className="source-image"
          style={{
            backgroundImage: `url(${sourceImage(source)})`,
          }}
        />
      </div>
    );
  }
}

class Sources extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
  };

  render() {
    const {
      organization: {
        source_references,
        source_refs: sources = source_references,
      } = {},
    } = this.props;

    const filteredSources =
      typeof sources !== "undefined"
        ? Object.keys(sources).filter(
            (provider) => !IGNORED_SOURCES.includes(provider)
          )
        : [];

    return (
      <div className="data-wrapper sources">
        <div className="data-header">Sources</div>
        <Choose>
          <When condition={filteredSources.length === 0}>
            <div className="data-content text empty">No sources found</div>
          </When>
          <Otherwise>
            <div className="data-content list horizontal">
              {filteredSources.map((source) => (
                <Source source={source} key={source} />
              ))}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(Sources);
