import React from "react";
import PropTypes from "prop-types";

import TimelineEventCallFeedbackBody from "./TimelineEventCallFeedbackBody";

import callFeedbackReasonsConfig from "../../../../../content/callFeedbackReasons";
import { truncateString } from "../../../../../lib/helpers";

import xss from "xss";

export default class TimelineEventCallFeedback extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    getUserName: PropTypes.func.isRequired,
  };

  maxLabelLength = 100;

  buildItem = (item = this.props.item) => {
    return this.getCallFeedbackLabels(item);
  };

  getCallFeedbackLabels = (item) => {
    const callFeedback = item.call_feedback;
    return "decision" in callFeedback
      ? this.getDecisionLabels(callFeedback.partner_id, callFeedback)
      : this.getRecommendationLabels(callFeedback.analyst_id, callFeedback);
  };

  getDecisionLabels = (partnerEmail, callFeedback) => {
    const decision = this.getDecisionOrRecommendation(callFeedback, "decision");
    const label = `${this.props.getUserName(
      partnerEmail
    )}'s Decision: <b>${this.getCallFeedbackReasonText(decision.decision)}</b>`;
    const notes = decision.notes || null;
    return {
      label: label,
      subLabel: this.getSubLabel(notes, label.length),
      fullNotes: notes,
    };
  };

  getDecisionOrRecommendation = (callFeedback, type) => {
    return typeof callFeedback[type] === "string"
      ? {
          recommendation: callFeedback[type],
          decision: callFeedback[type],
          notes: callFeedback.notes,
        }
      : callFeedback[type];
  };

  getCallFeedbackReasonText = (reason) => {
    return callFeedbackReasonsConfig[reason];
  };

  getRecommendationLabels = (analystEmail, callFeedback) => {
    const recommendation = this.getDecisionOrRecommendation(
      callFeedback,
      "recommendation"
    );
    const label = `${this.props.getUserName(
      analystEmail
    )}'s Recommendation: <b>${this.getCallFeedbackReasonText(
      recommendation.recommendation
    )}</b>`;
    const notes = recommendation.notes || null;
    return {
      label: label,
      subLabel: this.getSubLabel(notes, label.length),
      fullNotes: notes,
    };
  };

  getSubLabel = (notes = null, labelLength) => {
    return notes !== null
      ? `- ${truncateString(notes, this.maxLabelLength, labelLength)}`
      : null;
  };

  render() {
    const { label, subLabel, fullNotes } = this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <h5
          className="timeline-event-label"
          dangerouslySetInnerHTML={{ __html: xss(label) }}
        />
        <If condition={subLabel !== null}>
          <p className="timeline-event-sub-label">{subLabel}</p>
        </If>
        <div className="spacer" />
        <If condition={fullNotes !== null}>
          <TimelineEventCallFeedbackBody text={fullNotes} />
        </If>
      </div>
    );
  }
}
