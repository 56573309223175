export default [
  {
    active: 0,
    tasks: [
      "send_website_messages",
      "connect_management_linkedin",
      "send_social",
    ],
  },
  {
    active: 2,
    tasks: ["send_management_linkedin"],
  },
  {
    active: 2,
    tasks: ["final_go", "connect_network"],
  },
];
