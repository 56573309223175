import PropTypes from "prop-types";
import React from "react";

import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import AddCircleOutline from "material-ui/svg-icons/content/add-circle";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import AutoComplete from "material-ui/AutoComplete";
import Checkbox from "material-ui/Checkbox";

import CreateOrganizationHelper from "../../lib/CreateOrganizationHelper";
import MuiTheme from "../../lib/MuiTheme";

import EditExperience from "./EditExperience";
import EditPersonOrganizations from "./EditPersonOrganizations";
import titlesSuggestion from "../../content/titlesSuggestions";
import ValidateEmail from "../organization/ValidateEmail";
import FindEmail from "../organization/FindEmail";
import Card from "../card/Card";
import CardBody from "../card/CardBody";
import EditBasicInfo from "../organization/edit/EditBasicInfo";
import SyncWithLinkedIn from "./SyncWithLinkedIn";
import CreateFromLinkedIn from "./CreateFromLinkedIn";

export default class EditPerson extends React.Component {
  state = {
    errors: {},
  };

  componentWillMount() {
    this.createOrganizationHelper = new CreateOrganizationHelper();
  }

  getChildContext = () => {
    const palette = Object.assign({}, MuiTheme.palette, {
      accent1Color: "#337ab7",
    });
    return {
      muiTheme: getMuiTheme(Object.assign({}, MuiTheme, { palette })),
    };
  };

  checkIfFieldsHaveBeenEdited = (fields) => {
    return (
      fields.filter(
        (field) =>
          this.props.modifiedFields &&
          this.props.modifiedFields.indexOf(field) !== -1
      ).length > 0
    );
  };

  validatePersonField = (field, value, type) => {
    const errors = Object.assign({}, this.state.errors);
    errors[field] = this.createOrganizationHelper.validateField(
      null,
      value,
      type
    );
    this.setState({ errors });
  };

  handleExperienceChange = (index, title, start, end, duration, company) => {
    const personCopy = Object.assign({}, this.props.person),
      experiences = personCopy.experience,
      experience = experiences[index];
    if (experience) {
      experience.title = title || null;
      experience.start = start || null;
      experience.end = end || null;
      experience.duration = duration || null;
      experience.company = company || null;

      this.props.updatePersonField("experience", experiences);
    }
  };

  handleExperienceDelete = (index) => {
    const personCopy = Object.assign({}, this.props.person),
      experiences = personCopy.experience,
      experience = experiences[index];
    if (experience) {
      experiences.splice(index, 1);
      this.props.updatePersonField("experience", experiences);
    }
  };

  handleAddExperience = () => {
    const personCopy = Object.assign({}, this.props.person);

    if (!personCopy.experience) {
      personCopy.experience = [];
    }

    personCopy.experience.push({});
    this.props.updatePersonField("experience", personCopy.experience);
  };

  handleAddOrganization = (organizationId) => {
    const personCopy = Object.assign({}, this.props.person);

    if (!personCopy.organizations) {
      personCopy.organizations = [];
    }

    personCopy.organizations.push(organizationId);
    this.props.updatePersonField("organizations", personCopy.organizations);
  };

  handleOrganizationRemove = (index) => {
    const personCopy = Object.assign({}, this.props.person),
      organizations = personCopy.organizations,
      organization = organizations[index];
    if (organization) {
      organizations.splice(index, 1);
      this.props.updatePersonField("organizations", organizations);
    }
  };

  render() {
    const {
      person = {},
      initialPerson = {},
      updatePersonFields,
      updatePersonField,
      organizationId,
      onCreateComplete,
    } = this.props;
    const displayEditedFields = (fields) => {
      return (
        <If condition={this.checkIfFieldsHaveBeenEdited(fields)}>
          <p className="edited-field">EDITED</p>
        </If>
      );
    };

    if (person) {
      return (
        <div className="edit-body edit-person">
          <Card>
            <CardBody>
              <div className="edit-section">
                <EditBasicInfo
                  source="edit-person"
                  data={person}
                  updateFields={updatePersonFields}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  TITLE
                  {displayEditedFields(["title"])}
                </div>
                <AutoComplete
                  filter={AutoComplete.fuzzyFilter}
                  name="edit-title"
                  className="edit-title"
                  searchText={person.title || ""}
                  fullWidth={true}
                  onUpdateInput={(value) => updatePersonField("title", value)}
                  dataSource={titlesSuggestion}
                  maxSearchResults={5}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  ROLE
                  {displayEditedFields(["role"])}
                </div>
                <SelectField
                  name="edit-role"
                  className="edit-role"
                  value={person.role}
                  onChange={(event, index, value) =>
                    updatePersonField("role", value)
                  }
                >
                  <MenuItem
                    className="role-entry"
                    value="founder"
                    primaryText="Founder"
                  />
                  <MenuItem
                    className="role-entry"
                    value="co-founder"
                    primaryText="Co-founder"
                  />
                  <MenuItem
                    className="role-entry"
                    value="employee"
                    primaryText="Employee"
                  />
                  <MenuItem
                    className="role-entry"
                    value="investor"
                    primaryText="Investor"
                  />
                  <MenuItem
                    className="role-entry"
                    value="past investor"
                    primaryText="Past investor"
                  />
                  <MenuItem
                    className="role-entry"
                    value="board member"
                    primaryText="Board member"
                  />
                  <MenuItem
                    className="role-entry"
                    value="advisor"
                    primaryText="Advisor"
                  />
                </SelectField>
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  EMAIL
                  {displayEditedFields(["email"])}
                </div>
                <TextField
                  fullWidth={true}
                  name="edit-email"
                  className="edit-email"
                  value={person.email}
                  errorText={this.state.errors.email}
                  onChange={(event) => {
                    this.validatePersonField(
                      "email",
                      event.target.value,
                      "email"
                    );
                    updatePersonField("email", event.target.value);
                  }}
                />
                <If condition={person.email && !this.state.errors.email}>
                  <div className="edit-person-validate-verified">
                    <ValidateEmail
                      email={person.email}
                      handleVerifiedEmail={(verified) =>
                        updatePersonField("email_verified", verified)
                      }
                    />
                    <Checkbox
                      label="EMAIL VERIFIED"
                      checked={person.email_verified}
                      className="email-verified-checkbox"
                      onClick={() =>
                        updatePersonField(
                          "email_verified",
                          !person.email_verified
                        )
                      }
                    />
                  </div>
                </If>
                <If
                  condition={
                    person.id &&
                    !person.email &&
                    !this.state.errors.email &&
                    organizationId
                  }
                >
                  <FindEmail
                    organizationId={organizationId}
                    personId={person.id}
                    handleUpdateEmail={(email) => {
                      updatePersonFields([
                        {
                          field: "email",
                          value: email,
                        },
                        {
                          field: "email_verified",
                          value: true,
                        },
                      ]);
                    }}
                  />
                </If>
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  LINKEDIN
                  {displayEditedFields(["linkedin"])}
                </div>
                <TextField
                  fullWidth={true}
                  name="edit-linkedin-url"
                  className="edit-linkedin-url"
                  value={person.linkedin_url}
                  errorText={this.state.errors.linkedin_url}
                  onChange={(event) => {
                    this.validatePersonField(
                      "linkedin_url",
                      event.target.value,
                      "URL"
                    );
                    updatePersonField("linkedin_url", event.target.value);
                  }}
                />
                <Choose>
                  <When condition={!person.id && onCreateComplete}>
                    <CreateFromLinkedIn
                      linkedin={person.linkedin_url}
                      onCreateComplete={onCreateComplete}
                      organizationId={organizationId}
                    />
                  </When>
                  <Otherwise>
                    <SyncWithLinkedIn
                      person={initialPerson}
                      onSyncComplete={() => window.location.reload()}
                    />
                  </Otherwise>
                </Choose>
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  BIO
                  {displayEditedFields(["bio"])}
                </div>
                <TextField
                  multiLine={true}
                  rows={1}
                  rowsMax={10}
                  fullWidth={true}
                  name="edit-bio"
                  className="edit-bio"
                  value={person.bio}
                  onChange={(event) =>
                    updatePersonField("bio", event.target.value)
                  }
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  TWITTER
                  {displayEditedFields(["twitter"])}
                </div>
                <TextField
                  fullWidth={true}
                  name="edit-twitter-url"
                  className="edit-twitter-url"
                  value={person.twitter_url}
                  errorText={this.state.errors.twitter_url}
                  onChange={(event) => {
                    this.validatePersonField(
                      "twitter_url",
                      event.target.value,
                      "URL"
                    );
                    updatePersonField("twitter_url", event.target.value);
                  }}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  ANGELLIST
                  {displayEditedFields(["angellist"])}
                </div>
                <TextField
                  fullWidth={true}
                  name="edit-angellist-url"
                  className="edit-angellist-url"
                  value={person.angellist_url}
                  errorText={this.state.errors.angellist_url}
                  onChange={(event) => {
                    this.validatePersonField(
                      "angellist_url",
                      event.target.value,
                      "URL"
                    );
                    updatePersonField("angellist_url", event.target.value);
                  }}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  FACEBOOK
                  {displayEditedFields(["facebook"])}
                </div>
                <TextField
                  fullWidth={true}
                  name="edit-facebook-url"
                  className="edit-facebook-url"
                  value={person.facebook_url}
                  errorText={this.state.errors.facebook_url}
                  onChange={(event) => {
                    this.validatePersonField(
                      "facebook_url",
                      event.target.value,
                      "URL"
                    );
                    updatePersonField("facebook_url", event.target.value);
                  }}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  EXPERIENCE
                  {displayEditedFields(["experience"])}
                </div>
                <If condition={person.experience}>
                  {person.experience.map((experience, index) => (
                    <EditExperience
                      onChangeExperience={this.handleExperienceChange}
                      onDeleteExperience={this.handleExperienceDelete}
                      index={index}
                      key={index}
                      title={experience.title}
                      start={experience.start}
                      end={experience.end}
                      duration={experience.duration}
                      company={experience.company}
                    />
                  ))}
                </If>
                <If
                  condition={
                    !person.experience || person.experience.length === 0
                  }
                >
                  <p> No experience has been added yet </p>
                </If>
                <FlatButton
                  className="add-experience-button"
                  label="Add experience"
                  onClick={this.handleAddExperience}
                  icon={<AddCircleOutline color={"#5DAB49"} />}
                />
              </div>
              <div className="edit-section">
                <div className="edit-section-header">
                  ORGANIZATIONS
                  {displayEditedFields(["organizations"])}
                </div>
                <If condition={person.organizations}>
                  <EditPersonOrganizations
                    onAddOrganization={this.handleAddOrganization}
                    onRemoveOrganization={this.handleOrganizationRemove}
                    organizationsIDs={person.organizations}
                  />
                </If>
                <If
                  condition={
                    !person.organizations || person.organizations.length === 0
                  }
                >
                  <p> No organization is associated to this person </p>
                </If>
              </div>
            </CardBody>
          </Card>
        </div>
      );
    }

    return false;
  }
}

EditPerson.childContextTypes = {
  muiTheme: PropTypes.object.isRequired,
};
