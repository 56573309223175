import React, { Component } from "react";
import PropTypes from "prop-types";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import STREAK_STAGES from "../config/streak_stages";

export default class StagesStreak extends Component {
  static propTypes = {
    handleStageChange: PropTypes.func.isRequired,
    ignoreDisabled: PropTypes.bool,
  };

  static defaultProps = {
    ignoreDisabled: false,
  };

  handleChange = (event, index, value) => {
    this.props.handleStageChange(value);
  };

  render() {
    return (
      <SelectField
        value={this.props.stage}
        onChange={this.handleChange}
        floatingLabelText="Stage"
        floatingLabelFixed={true}
        fullWidth={true}
        className="top-bar-selector stages-streak"
        menuStyle={{
          backgroundColor: "#ffffff",
          height: "70%",
          paddingLeft: "8px",
        }}
        floatingLabelStyle={{ paddingLeft: "8px" }}
      >
        {STREAK_STAGES.map((stage) => (
          <MenuItem
            key={stage.key}
            value={stage.key}
            disabled={!this.props.ignoreDisabled && stage.disabled}
            primaryText={stage.name}
          />
        ))}
      </SelectField>
    );
  }
}
