import React from "react";
import PropTypes from "prop-types";

import RefreshIndicator from "material-ui/RefreshIndicator";
import Dialog from "material-ui/Dialog";
import { FlatButton } from "material-ui";

import Organizations from "../lib/Organizations";
import AssignCampaigns from "../lib/AssignCampaigns";

import auth from "../storage/Auth";
import ReconnectStore from "../storage/ReconnectStore";

import _ from "underscore";

import StateButton from "./StateButton";
import StateNotesDialog from "./StateNotesDialog";
import InProgressCustomizeCampaign from "./assign/InProgressCustomizeCampaign";

import DialogWrapper from "./components/DialogWrapper";

const routeNames = {
  inbox: "Inbox",
  organization: "Organization (direct)",
  organizations: "Organizations",
  spam: "Spam",
};

const formatFilterSource = (source = {}) => ({
  ...source,
  // If there is a document.referrer include it in the key `referrer` otherwise
  // omit that key
  ...(document.referrer ? { referrer: document.referrer } : {}),
  name: routeNames[source.route] || "Direct",
});

class Loading extends React.Component {
  render() {
    return (
      <div className="refresh-indicator">
        <RefreshIndicator
          size={30}
          loadingColor={"#337ab7"}
          status="loading"
          left={0}
          top={0}
          style={{ display: "flex", alignItems: "center", boxShadow: "none" }}
        />
      </div>
    );
  }
}

function predictionsToBeShown(state, predictions, showPredictions) {
  return (
    showPredictions ||
    (state && predictions && Object.keys(predictions).length !== 0)
  );
}

function buttonLabels(
  state,
  decisionState,
  userId,
  userRole,
  predictions = {},
  showPredictions = false
) {
  const defaultLabels = {
    never: "Never",
    not_now: "Not Now",
    missed_opportunity: "Missed Opportunity",
    contact: "In Progress",
  };

  if (state.updating && !state.needsEvaluationAfterTheForm) {
    return Object.entries(defaultLabels).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: state.nextState === key ? <Loading /> : value,
      }),
      {}
    );
  }

  return defaultLabels;
}

export default class State extends React.Component {
  static propTypes = {
    onStateChange: PropTypes.func.isRequired,
    showIcons: PropTypes.bool,
    client: PropTypes.string.isRequired,
    organization: PropTypes.object.isRequired,
    onFormEvaluationChange: PropTypes.func.isRequired,
    filterSource: PropTypes.object.isRequired,
    reloadOrganization: PropTypes.func,
    handleError: PropTypes.func.isRequired,
    recentMessages: PropTypes.object,
    userRoles: PropTypes.array,
    showPredictions: PropTypes.bool,
  };

  state = {
    updating: false,
    showAssignModal: false,
    showStateNotesDialog: false,
    updatingAssignModal: false,
    updatingStateNotesDialog: false,
    organizationType: null,
    needsEvaluationAfterTheForm: false,
    proposedState: null,
    errorMessage: null,
    isReconnectExpired: false,
    userId: null,
    userRole: this.props.userRoles
      ? this.props.userRoles.indexOf("partner") !== -1
        ? "partner"
        : this.props.userRoles.indexOf("investor") !== -1
        ? "investor"
        : "analyst"
      : null,
  };

  reconnectStore = new ReconnectStore(this.props.organization.id);

  closeErrorModal = () => {
    this.setState({
      errorMessage: null,
    });
  };

  isUserPartner = () => {
    return this.props.userRoles
      ? this.props.userRoles.indexOf("partner") !== -1
      : false;
  };

  isUserInvestor = () => {
    return this.props.userRoles
      ? this.props.userRoles.indexOf("investor") !== -1
      : false;
  };

  unsetOrganizationState = () => {
    const {
      organization: { id } = {},
      filterSource,
      optionalState,
      onStateChange,
    } = this.props;
    const source_filters = formatFilterSource(filterSource);

    this.setState({
      updating: true,
    });

    if (optionalState) {
      onStateChange({ decision: { state: false, source_filters } });
    } else {
      onStateChange({
        decision: { state: this.optionalState, source_filters },
      });
    }
  };

  setOrganizationState = (state) => {
    const source_filters = formatFilterSource(this.props.filterSource);

    this.setState({
      updating: true,
      nextState: state,
    });

    this.props.onStateChange({
      decision: {
        state,
        source_filters,
        user_id: this.state.userId,
        user_role: this.state.userRole,
      },
    });
  };

  setStateNotesDialog(state) {
    this.setState({
      showStateNotesDialog: true,
      proposedState: state,
    });
  }

  enableAssignModal = () => {
    this.setState({
      showAssignModal: true,
    });
  };

  handleStateChange = (state, currentState, shiftKeyPressed) => {
    return auth
      .getAuthData()
      .then(({ profile: { email } }) => {
        const isUserPartner = this.isUserPartner();
        const isUserInvestor = this.isUserInvestor();
        const isUserAnalyst = isUserInvestor ? shiftKeyPressed : true;

        const isTrustedPartner =
          this.isUserPartner() && !this.props.userHasFullAccess;

        const existingDecision = Organizations.latestFinalOrInvestorDecision(
          this.props.organization,
          this.props.client
        );

        const userId = email,
          personState = Organizations.getPersonState(
            this.props.organization,
            this.props.client,
            userId
          );

        this.setState({
          userId: userId,
          userRole: isTrustedPartner
            ? "partner"
            : isUserPartner
            ? "partner"
            : isUserAnalyst
            ? "analyst"
            : isUserInvestor
            ? "investor"
            : null,
        });

        if (!this.state.updating) {
          if (
            !isTrustedPartner &&
            existingDecision &&
            (existingDecision.is_final ||
              existingDecision.user_role === "investor") &&
            existingDecision.state === "contact" &&
            existingDecision.state === state
          ) {
            this.setState({
              errorMessage:
                "This organization is already In Progress from a partner or an investor. " +
                "You probably should use the evaluation bar instead. In case of doubt please contact Ghyslain.",
            });
            return;
          }

          if (personState === state) {
            return this.unsetOrganizationState();
          }

          if (isTrustedPartner) {
            if (state === "contact") {
              return this.setStateNotesDialog(state);
            }
            return this.setOrganizationState(state);
          }

          if (isUserPartner) {
            if (state === "contact") {
              return this.enableAssignModal();
            }
            return this.setOrganizationState(state);
          }

          if (isUserInvestor && !isUserAnalyst) {
            if (state === "contact") {
              return this.enableAssignModal();
            }
            if (state === "not_now" || state === "never") {
              return this.setStateNotesDialog(state);
            }
            return this.setOrganizationState(state);
          }

          if (state === "contact" || state === "never") {
            return this.setStateNotesDialog(state);
          }
          return this.setOrganizationState(state);
        }
      })
      .catch((err) => {
        console.error("error while handling state change:", err);
      });
  };

  handleClose = () => {
    this.setState({
      showAssignModal: false,
      updatingAssignModal: false,
    });
  };

  handleSubmit = (assignCampaign) => {
    this.setState({
      updatingAssignModal: true,
    });
    const { decision = {} } = assignCampaign;
    decision.source_filters = formatFilterSource(this.props.filterSource);
    decision.user_role = this.state.userRole;
    assignCampaign.decision = decision;

    return this.props
      .onStateChange(assignCampaign)
      .then(this.handleClose)
      .catch((error) => {
        this.handleClose();
        this.props.handleError(AssignCampaigns.processError(error));
      });
  };

  handleStateNotesDialogSubmit = (notes, state) => {
    this.setState({
      updatingStateNotesDialog: true,
    });
    const source_filters = formatFilterSource(this.props.filterSource);
    const assignCampaign = {
      decision: {
        form: true,
        state,
        notes,
        source_filters,
        user_id: this.state.userId,
        user_role: this.state.userRole,
      },
    };
    this.props
      .onStateChange(assignCampaign)
      .then(this.handleStateNotesDialogClose);
  };

  handleStateNotesDialogClose = () => {
    this.setState({
      showStateNotesDialog: false,
      updatingStateNotesDialog: false,
    });
  };

  organizationDecisions = (organization) => {
    return Organizations.getOrganizationState(organization, this.props.client);
  };

  needsEvaluationAfterTheForm = (organization) => {
    const decision = this.organizationDecisions(organization);
    if (
      organization &&
      organization.source_refs &&
      (organization.source_refs.FUNDING ||
        organization.source_refs.V2FUNDING) &&
      typeof decision.interesting === "undefined"
    ) {
      if (!decision || !decision.form) {
        if (decision.stage) {
          if (decision.stage === "more_info") {
            this.setState({
              organizationType: "inbound",
              needsEvaluationAfterTheForm: true,
            });
          } else {
            this.setState({
              needsEvaluationAfterTheForm: false,
            });
          }
          return;
        }
        this.setState({
          organizationType: "inbound",
          needsEvaluationAfterTheForm: true,
        });
        return;
      }
      if (decision && decision.form && decision.stage === "more_info") {
        this.setState({
          organizationType: "outbound",
          needsEvaluationAfterTheForm: true,
        });
        return;
      }
    }
    this.setState({
      needsEvaluationAfterTheForm: false,
    });
    return;
  };

  getStatesClasses = (state) => {
    let classes = ["state-group"];
    if (!this.props.showIcons) {
      classes.push("hide-desktop");
    }
    if (
      this.props.showPredictions &&
      this.props.organization.combined_predictions &&
      Object.keys(this.props.organization.combined_predictions).length > 0 &&
      state === this.optionalState
    ) {
      classes.push("show-predictions");
    }
    if (
      predictionsToBeShown(
        state,
        this.props.organization.combined_predictions,
        this.props.showPredictions
      )
    ) {
      classes.push("evaluated");
    }

    return classes.join(" ");
  };

  componentDidMount() {
    this.needsEvaluationAfterTheForm(this.props.organization);
    this.reconnectExpired();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ updating: false });
    this.handleClose();

    this.optionalState = this.props.optionalState || "unseen";
    if (!_.isEqual(nextProps.organization, this.props.organization)) {
      this.needsEvaluationAfterTheForm(nextProps.organization);
    }
  }

  isInReconnectInbox = (organization, client) => {
    const { inboxes: { [client]: inboxes = [] } = {} } = organization;

    return inboxes.some(
      (inbox) => inbox.reason === "RECONNECT" && inbox.inbox === true
    );
  };

  reconnectExpired = () => {
    const { organization, client } = this.props;
    const decision = this.organizationDecisions(organization) || {};
    if (
      decision.stage === "in_tracking" &&
      this.isInReconnectInbox(organization, client)
    ) {
      const now = Date.now();
      this.reconnectStore.getReconnect().then(({ when: reconnectAt = now }) => {
        this.setState({ isReconnectExpired: reconnectAt < reconnectAt });
      });
    }
  };

  render() {
    const {
      organization,
      showPredictions,
      client,
      userHasFullAccess,
      recentMessages,
    } = this.props;

    const decision = this.organizationDecisions(organization) || {};

    const buttonsLabel = buttonLabels(
      this.state,
      decision.state,
      decision.user_id,
      decision.user_role,
      organization.combined_predictions || {},
      showPredictions
    );

    return (
      <React.Fragment>
        <div className={this.getStatesClasses(decision.state)}>
          <StateButton
            value="never"
            label={buttonsLabel.never}
            user_id={decision.user_id}
            user_role={decision.user_role}
            state={decision.state}
            activity={decision.activity.never}
            icon={<div className="icon thumb-down" />}
            handleClick={this.handleStateChange}
          />
          <StateButton
            value="missed_opportunity"
            label={buttonsLabel.missed_opportunity}
            user_id={decision.user_id}
            user_role={decision.user_role}
            state={decision.state}
            activity={decision.activity.missed_opportunity}
            icon={<div className="icon rocket" />}
            handleClick={this.handleStateChange}
          />
          <StateButton
            value="not_now"
            label={buttonsLabel.not_now}
            user_id={decision.user_id}
            user_role={decision.user_role}
            state={decision.state}
            activity={decision.activity.not_now}
            icon={<div className="icon thumb-stop" />}
            handleClick={this.handleStateChange}
          />
          <StateButton
            value="contact"
            label={buttonsLabel.contact}
            user_id={decision.user_id}
            user_role={decision.user_role}
            state={decision.state}
            activity={decision.activity.contact}
            icon={<div className="icon thumb-up" />}
            handleClick={this.handleStateChange}
          />
        </div>
        <DialogWrapper
          actions={[
            <FlatButton
              label="Close"
              primary={true}
              onClick={this.closeErrorModal}
            />,
          ]}
          modal={false}
          open={!!this.state.errorMessage}
          onRequestClose={this.closeErrorModal}
          className={"decision-error-message"}
        >
          {this.state.errorMessage}
        </DialogWrapper>
        <InProgressCustomizeCampaign
          organization={organization}
          client={client}
          open={this.state.showAssignModal}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          userId={this.state.userId}
          userRole={this.state.userRole}
          recentMessages={recentMessages}
        />
        <If condition={this.state.proposedState}>
          <StateNotesDialog
            userRole={this.state.userRole}
            updating={this.state.updatingStateNotesDialog}
            show={this.state.showStateNotesDialog}
            proposedState={this.state.proposedState}
            handleSubmit={this.handleStateNotesDialogSubmit}
            handleClose={this.handleStateNotesDialogClose}
            userHasFullAccess={userHasFullAccess}
            organization={organization}
          />
        </If>
      </React.Fragment>
    );
  }
}
