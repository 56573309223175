import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import NavigationArrowDropUp from "material-ui/svg-icons/navigation/arrow-drop-up";
import NavigationArrowDropDown from "material-ui/svg-icons/navigation/arrow-drop-down";

class ToggleShowMoreButton extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    clickHandler: PropTypes.func.isRequired,
    show: PropTypes.bool,
  };

  getToggleShowMoreButtonClassNames = () => {
    const { show = true } = this.props;
    const classNames = ["toggle-show-more-button"];
    if (show) {
      classNames.push("show-content");
    } else {
      classNames.push("hide-content");
    }
    return classNames.join(" ");
  };

  render() {
    const { text, clickHandler, show = true } = this.props;
    return (
      <div
        className={this.getToggleShowMoreButtonClassNames()}
        onClick={clickHandler}
      >
        <Choose>
          <When condition={show}>
            <div className="toggle-show-more-button-text">{text}</div>
            <NavigationArrowDropDown className="toggle-show-more-button-arrow" />
          </When>
          <Otherwise>
            <NavigationArrowDropUp className="toggle-show-more-button-arrow" />
            <div className="toggle-show-more-button-text">{text}</div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(ToggleShowMoreButton);
