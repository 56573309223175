import React from "react";
import PropTypes from "prop-types";

import EditOrganizationPeopleEmployeesRange from "./EditOrganizationPeopleEmployeesRange";
import EditOrganizationPeopleList from "./EditOrganizationPeopleList";

import ActionAlarm from "material-ui/svg-icons/alert/warning";

export default class EditOrganizationPeople extends React.Component {
  static propTypes = {
    minEmployees: PropTypes.number.isRequired,
    updateOrganizationFields: PropTypes.func.isRequired,
  };

  getErrors = () => {
    const { people, primaryContact } = this.props;
    const errors = {
      noPrimaryContact: primaryContact === "",
      noFounderOrCoFounder: true,
    };
    people.forEach((person) => {
      const { role = "", linkedin_url: linkedIn = "" } = person;
      const lowerRole = role.toLowerCase();
      if (
        (lowerRole === "founder" || lowerRole === "co-founder") &&
        linkedIn !== "" &&
        linkedIn.indexOf("https") > -1
      ) {
        errors.noFounderOrCoFounder = false;
      }
    });
    return Object.entries(errors).reduce((newErrors, [error, value]) => {
      if (value) newErrors.push(error);
      return newErrors;
    }, []);
  };

  getErrorString = (error) => {
    switch (error) {
      case "noPrimaryContact":
        return "No Primary Contact selected";
      case "noFounderOrCoFounder":
        return "No Founder or Co-Founder with a valid LinkedIn URL (must be https)";
      default:
        return error;
    }
  };

  render() {
    const { minEmployees, updateOrganizationFields } = this.props;
    const errors = this.getErrors();
    return (
      <div className="edit-organization-people">
        <div className="edit-section-header">PEOPLE</div>
        <EditOrganizationPeopleEmployeesRange
          minEmployees={minEmployees}
          updateOrganizationFields={updateOrganizationFields}
        />
        <If condition={errors.length > 0}>
          <div className="edit-organization-people-errors">
            {errors.map((error) => (
              <div
                className="edit-organization-people-errors-error"
                key={error}
              >
                <ActionAlarm color={"#f44336"} />
                <div className="error-text">{this.getErrorString(error)}</div>
              </div>
            ))}
          </div>
        </If>
        <EditOrganizationPeopleList {...this.props} />
      </div>
    );
  }
}
