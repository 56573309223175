import SearchParamsHelper from "../../lib/SearchParamsHelper";

import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import PersonSummary from "./PersonSummary";
import peopleStore from "../../storage/PeopleStore";
import _ from "underscore";

import Loading from "../loading/Loading";

var topOfElement = function (element) {
  if (!element) {
    return 0;
  }
  return element.offsetTop + topOfElement(element.offsetParent);
};

class People extends React.Component {
  static propTypes = {
    searchParams: PropTypes.object.isRequired,
    changeUrl: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    var people = peopleStore.getAll();
    if (
      SearchParamsHelper.arePropsEqual(
        props.searchParams,
        peopleStore.searchParams
      ) &&
      people.length > 0
    ) {
      this.state = {
        people: people,
        loading: false,
      };

      return;
    }

    this.state = {
      loading: true,
    };
  }

  fetchNextPage = (options) => {
    var currentOffset, params;
    this.setState({ loading: true });
    currentOffset = Math.max(this.offset, options.offset);
    peopleStore.fetch(this.offset).then(
      function (offset) {
        this.handlePeopleChange();
        this.offset = offset;
      }.bind(this)
    );
    params = _.extend({ offset: currentOffset }, options.searchParams);
    this.props.changeUrl("/people", params);
  };

  componentWillMount() {
    this.offset = 0;
    peopleStore.initialize(this.props);
    if (typeof this.state.people === "undefined") {
      this.fetchNextPage(this.props);
    } else {
      this.attachScrollListener();
      this.offset = peopleStore.getAll().length;
    }
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    this.detachScrollListener();
    this.setState({ isMounted: false });
  }

  componentWillUpdate(newProps) {
    if (
      !SearchParamsHelper.arePropsEqual(
        newProps.searchParams,
        peopleStore.searchParams
      )
    ) {
      this.offset = 0;
      peopleStore.initialize(newProps);
      this.fetchNextPage(newProps);
    }
  }

  componentDidUpdate() {
    if (
      !this.state.loading &&
      this.state.people &&
      this.state.people.length + 30 < peopleStore.getSize() &&
      this._el &&
      parseInt(this._el.attributes["data-people-num"].value) ===
        this.state.people.length &&
      !peopleStore.isLoading()
    ) {
      this.attachScrollListener();
    }
  }

  initializeStore = (options) => {
    this.offset = 0;
    peopleStore.initialize(options);
    if (this.state.people === undefined) {
      this.fetchNextPage(options);
    }
  };

  handlePeopleChange = () => {
    this.setState({
      people: peopleStore.getAll(),
      loading: false,
    });
  };

  scrollListener = () => {
    var el = this.el,
      scrollTop;
    if (this.state.isMounted) {
      scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;
      if (
        topOfElement(el) + el.offsetHeight - scrollTop - window.innerHeight <
        this.props.offset
      ) {
        this.detachScrollListener();
        this.fetchNextPage(this.props);
      }
    }
  };

  attachScrollListener = () => {
    window.addEventListener("scroll", this.scrollListener);
    window.addEventListener("resize", this.scrollListener);
    this.scrollListener();
  };

  detachScrollListener = () => {
    window.removeEventListener("scroll", this.scrollListener);
    window.removeEventListener("resize", this.scrollListener);
  };

  render() {
    var people = this.state.people || [];
    return (
      <div ref={(el) => (this.el = el)}>
        <div className="row visible-lg-block">
          <div className="col-lg-2 col-sm-4 col-xs-12" />
          <div className="col-lg-2 col-sm-4 col-xs-12" />
          <div className="col-lg-1 col-sm-4 col-xs-12" />
          <div className="col-lg-1 col-sm-2 col-xs-4 text-center">
            <strong>First Seen</strong>
          </div>
          <div className="col-lg-1 col-sm-2 col-xs-4 text-center">
            <strong>Updated At</strong>
          </div>
          <div className="col-lg-1 col-sm-2 col-xs-4 text-center">
            <strong>Trend</strong>
          </div>
          <div className="col-lg-4 col-sm-12 col-xs-12" />
        </div>
        <ol className="list-unstyled" data-people-num={people.length}>
          {people.map(
            function (person) {
              return (
                <li key={person.id}>
                  <PersonSummary person={person} />
                </li>
              );
            }.bind(this)
          )}
        </ol>
        {this.state.loading ? <Loading /> : <span />}
      </div>
    );
  }
}

export default People;
