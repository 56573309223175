import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-v4";

import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton/IconButton";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import MenuItem from "material-ui/MenuItem";

const MenuItems = {
  preview: ["edit"],
  profile: ["edit"],
};

export default class InvestorMenu extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    source: PropTypes.string.isRequired,
    actions: PropTypes.element.isRequired,
    goToPage: PropTypes.func.isRequired,
  };

  shouldShowMenuItem = (item) => {
    const { source } = this.props;
    return MenuItems[source].includes(item);
  };

  render() {
    const { source, actions, goToPage } = this.props;
    // console.log(
    //   source,
    //   window.innerWidth,
    //   source === 'profile' && window.innerWidth < 768,
    //   actions
    // );

    return (
      <IconMenu
        className="more-menu"
        iconButtonElement={
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        }
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        targetOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <If condition={source === "profile" && window.innerWidth < 768}>
          <div className="mobile-top-bar-actions">{actions}</div>
        </If>
        <If condition={this.shouldShowMenuItem("edit")}>
          <Link
            onClick={() => {
              goToPage("edit");
            }}
            style={{
              textDecoration: "none",
            }}
            className="edit-link"
          >
            <MenuItem primaryText="Edit" />
          </Link>
        </If>
      </IconMenu>
    );
  }
}
