import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-v4";

import Loading from "../../loading/Loading";

import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import ActionDelete from "material-ui/svg-icons/action/delete";
import ActionSearch from "material-ui/svg-icons/action/search";

import { capitalizeString } from "../../../lib/helpers";

import _ from "underscore";
import CreateFromLinkedIn from "../../person/CreateFromLinkedIn";

export default class EditOrganizationPeopleListPerson extends React.Component {
  static propTypes = {
    person: PropTypes.object.isRequired,
    primaryContact: PropTypes.string,
    updateOrganizationFields: PropTypes.func,
    savePerson: PropTypes.func,
    index: PropTypes.number,
    hasALinkedInUrl: PropTypes.bool,
    deletePerson: PropTypes.func,
    newPerson: PropTypes.bool,
    saveNewPerson: PropTypes.func,
    cancelAddingPerson: PropTypes.func,
    organizationId: PropTypes.string.isRequired,
  };

  state = {
    person: {},
    edit: false,
    saving: false,
    hasMadeChanges: false,
    errors: {
      name: false,
      role: false,
      linkedin_url: false,
    },
  };

  componentWillMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevProps.person, this.props.person)) {
      this.initialize();
    }
  }

  initialize = () => {
    const { person = {}, newPerson } = this.props;
    const { name = "", role = "", linkedin_url: linkedIn = "" } = person;
    this.setState({
      person,
      edit: newPerson,
      saving: false,
      hasMadeChanges: false,
      errors: {
        name: this.getError("name", name),
        role: this.getError("role", role),
        linkedin_url: this.getError("linkedin_url", linkedIn, role),
      },
    });
  };

  getError = (field, value, role = "") => {
    switch (field) {
      case "linkedin_url":
        return role.indexOf("founder") > -1
          ? !/https:\/\/(www\.)?linkedin\.com\/in\/[a-zA-ZÀ-ÖØ-öŠø-ÿ0-9-_%]+\/?(\?.*)?$/.test(
              value
            )
          : false;
      default:
        return value === "";
    }
  };

  buildImageClassName = () => {
    const { primaryContact } = this.props;
    const { person: { id = "" } = {} } = this.state;
    const className = ["edit-organization-person-image"];
    if (primaryContact === id) {
      className.push("primary");
    }
    return className.join(" ");
  };

  handleChange = (field, value) => {
    const { person = {}, errors = {} } = this.state;
    person[field] = value;
    errors[field] = this.getError(field, value, person.role);
    if (field === "role") {
      errors.linkedin_url = this.getError(
        "linkedin_url",
        person.linkedin_url,
        value
      );
    }
    this.setState({
      person,
      errors,
      hasMadeChanges: true,
    });
  };

  handleSearchLinkedIn = () => {
    const { person: { name = "" } = {} } = this.props;
    if (name !== "") {
      window.open(
        `https://www.linkedin.com/search/results/all/?keywords=${encodeURIComponent(
          name
        )}`
      );
    }
  };

  handleSetAsPrimary = () => {
    const { person: { id = "" } = {}, updateOrganizationFields } = this.props;
    if (id !== "" && typeof updateOrganizationFields !== "undefined") {
      updateOrganizationFields([{ field: "primary_contact_id", value: id }]);
    }
  };

  handleEdit = () => {
    this.setState({
      edit: true,
    });
  };

  handleSave = () => {
    const { savePerson, newPerson = false, saveNewPerson } = this.props;
    const { person = {}, hasMadeChanges, errors = {} } = this.state;
    if (
      hasMadeChanges &&
      !errors.name &&
      !errors.role &&
      !errors.linkedin_url
    ) {
      this.setState({
        saving: true,
      });
      if (newPerson) {
        saveNewPerson(person);
      } else {
        savePerson(person);
      }
    }
  };

  handleDelete = () => {
    const { person: { id = "" } = {} } = this.state;
    this.setState({
      saving: true,
    });
    this.props.deletePerson(id);
  };

  render() {
    const {
      primaryContact = "",
      hasALinkedInUrl,
      newPerson,
      cancelAddingPerson,
      organizationId,
    } = this.props;
    const {
      person: {
        id,
        image_url: imageUrl = "",
        name = "",
        role = "",
        linkedin_url: linkedIn = "",
      } = {},
      edit,
      saving,
      hasMadeChanges,
      errors: {
        name: nameError = false,
        role: roleError = false,
        linkedin_url: linkedInError = false,
      } = {},
    } = this.state;
    return (
      <div className="edit-organization-person">
        <div className={this.buildImageClassName()}>
          <img src={imageUrl} />
        </div>
        <Choose>
          <When condition={edit}>
            <TextField
              floatingLabelText="NAME"
              className="edit-organization-edit-person-name edit-organization-input-field medium"
              value={name}
              onChange={(event) =>
                this.handleChange("name", event.target.value)
              }
              errorText={nameError ? "Required" : false}
              name="edit-organization-edit-person-name"
            />
          </When>
          <Otherwise>
            <Link to={`/people/${id}`} target="_blank">
              <h4 className="edit-organization-person-name">{name}</h4>
            </Link>
          </Otherwise>
        </Choose>
        <Choose>
          <When condition={edit}>
            <SelectField
              className="edit-organization-edit-person-role edit-organization-input-field small"
              floatingLabelText="ROLE"
              value={role.toLowerCase()}
              onChange={(event, index, value) => {
                this.handleChange("role", value);
              }}
              labelStyle={{
                paddingRight: "24px",
              }}
              errorText={roleError ? "Required" : false}
            >
              {[
                "founder",
                "co-founder",
                "employee",
                "investor",
                "past investor",
                "board member",
                "advisor",
              ].map((menuRole) => (
                <MenuItem
                  key={menuRole}
                  value={menuRole}
                  primaryText={capitalizeString(menuRole)}
                />
              ))}
            </SelectField>
          </When>
          <Otherwise>
            <div className="edit-organization-person-role">
              Role: {capitalizeString(role)}
            </div>
          </Otherwise>
        </Choose>
        <Choose>
          <When condition={edit}>
            <TextField
              floatingLabelText="LINKEDIN URL"
              className="edit-organization-edit-person-linkedin edit-organization-input-field medium"
              value={linkedIn}
              onChange={(event) =>
                this.handleChange("linkedin_url", event.target.value)
              }
              errorText={linkedInError ? "Required" : false}
              name="edit-organization-edit-person-linkedin"
            />
            <Choose>
              <When condition={newPerson}>
                <CreateFromLinkedIn
                  onCreateComplete={() => window.location.reload()}
                  linkedin={linkedIn}
                  organizationId={organizationId}
                />
              </When>
            </Choose>
            <ActionSearch
              className="edit-organization-person-search-linkedin-icon"
              color="#444444"
              onClick={this.handleSearchLinkedIn}
            />
          </When>
          <Otherwise>
            <If
              condition={
                (!hasALinkedInUrl && role.indexOf("founder") > -1) ||
                (hasALinkedInUrl && linkedIn !== "")
              }
            >
              <Link to={linkedIn} target="_blank">
                <div
                  className={`edit-organization-person-linkedin-indicator${
                    !hasALinkedInUrl ? " error" : ""
                  }`}
                />
              </Link>
            </If>
          </Otherwise>
        </Choose>
        <div className="spacer" />
        <If condition={primaryContact !== id && !edit}>
          <RaisedButton
            className="scorecard-button new-call"
            label="SET AS PRIMARY"
            backgroundColor="#ffffff"
            labelColor="#000000"
            style={{
              marginRight: "12px",
            }}
            onClick={this.handleSetAsPrimary}
          />
        </If>
        <Choose>
          <When condition={edit}>
            <Choose>
              <When condition={saving}>
                <Loading small={true} />
              </When>
              <Otherwise>
                <RaisedButton
                  className="edit-organization-save-button"
                  label="SAVE"
                  backgroundColor="#5dab49"
                  labelColor="#ffffff"
                  onClick={this.handleSave}
                  disabled={!hasMadeChanges}
                />
              </Otherwise>
            </Choose>
          </When>
          <Otherwise>
            <RaisedButton
              className="edit-organization-person-edit-button"
              label="EDIT"
              backgroundColor="#ffffff"
              labelColor="#000000"
              onClick={this.handleEdit}
            />
          </Otherwise>
        </Choose>
        <div className="edit-organization-person-delete">
          <Choose>
            <When condition={saving}>
              <Loading small={true} />
            </When>
            <Otherwise>
              <ActionDelete
                className="edit-organization-person-delete-icon"
                color="#f44336"
                onClick={
                  !edit || (edit && !newPerson)
                    ? this.handleDelete
                    : cancelAddingPerson
                }
              />
            </Otherwise>
          </Choose>
        </div>
      </div>
    );
  }
}
