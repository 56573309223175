import React from "react";
import ImportStore from "../storage/ImportStore";
import Loading from "./loading/Loading";

export default class CSVImport extends React.Component {
  constructor(props) {
    super(props);

    this.importStore = new ImportStore();
    this.state = {
      uploading: false,
      uploaded: false,
      error: null,
    };

    this.handleImport = this.handleImport.bind(this);
  }

  handleImport() {
    this.setState({ uploading: true });
    const reader = new FileReader();
    reader.onload = (event) => {
      this.importStore
        .importCSV(event.target.result)
        .then((entries) => {
          this.setState({
            uploading: false,
            uploaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ error });
        });
    };
    reader.onerror = (error) => {
      console.error(error);
      this.setState({ error });
    };
    reader.readAsText(this._csvimport.files[0]);
  }

  setRef = (el) => (this._csvimport = el);

  render() {
    if (this.state.uploading) {
      return <Loading fixed={true} />;
    }
    if (this.state.uploaded) {
      return <h1>Upload Completed</h1>;
    }
    if (this.state.error) {
      return (
        <div>
          <h1>Error</h1>
          <p>{this.state.error}</p>
        </div>
      );
    }

    return (
      <input
        ref={this.setRef}
        type="file"
        onChange={this.handleImport}
        accept=".csv"
      />
    );
  }
}
