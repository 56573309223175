import React from "react";
import PropTypes from "prop-types";

import ExpandMore from "material-ui/svg-icons/navigation/expand-more";
import ExpandLess from "material-ui/svg-icons/navigation/expand-less";

export default class CardHeader extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
    toggleExpanded: PropTypes.func,
    icon: PropTypes.object,
  };

  render() {
    const { children, title, expandable, expanded, toggleExpanded, icon } =
      this.props;
    return (
      <div className="card-header">
        <If condition={icon}>
          <div className="card-header-icon">{icon}</div>
        </If>
        <div className="card-title">{title}</div>
        {children}
        <If condition={expandable}>
          {/*<div className="spacer" />*/}
          <div className="expand" onClick={toggleExpanded}>
            <Choose>
              <When condition={expanded}>
                <ExpandLess color="#7f7f7f" />
              </When>
              <Otherwise>
                <ExpandMore color="#7f7f7f" />
              </Otherwise>
            </Choose>
          </div>
        </If>
      </div>
    );
  }
}
