import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

class Price extends React.Component {
  static propTypes = {
    price: PropTypes.string.isRequired,
  };

  getPrice = () => {
    const { price = "" } = this.props;
    return price === ""
      ? ""
      : price === "0.0" || price === "0" || price === "free"
      ? "Free"
      : price;
  };

  render() {
    const price = this.getPrice();
    return (
      <div className="data-wrapper price">
        <div className="data-header">Price</div>
        <Choose>
          <When condition={price === ""}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="data-content text">{price}</div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(Price);
