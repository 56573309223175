import React from "react";
import PropTypes from "prop-types";
import OrganizationsSearch from "../organization/OrganizationsSearch";
import { getAllOrganizationsIds } from "./InvestorHelper";
import Loading from "../loading/Loading";
import _ from "underscore";

class PortfolioTab extends React.Component {
  static propTypes = {
    client: PropTypes.string.isRequired,
  };

  state = {};

  urlToFilters = () => {
    const filters = {};
    const {
      location: { search = "", pathname = "" },
    } = this.props;
    const filtersText = search.length ? search : pathname;
    const parameters = filtersText.includes("/portfolio")
      ? filtersText.split("/portfolio")[1]
      : filtersText;
    const parametersText = parameters.includes("?")
      ? parameters.split("?")[1]
      : parameters;
    const decodedUrl = decodeURIComponent(parametersText);
    const filtersArray = decodedUrl.includes("&")
      ? decodedUrl.split("&")
      : [decodedUrl];
    filtersArray.forEach((array) => {
      const [key, value] = array.split("=");
      if (key !== "") {
        filters[key] = value;
      }
    });
    return filters;
  };

  render() {
    const filters = this.urlToFilters();
    const { portfolioAggregationLoading } = this.props;
    return (
      <Choose>
        <When condition={portfolioAggregationLoading}>
          <Loading />
        </When>
        <Otherwise>
          <OrganizationsSearch
            initSearchParams={filters}
            searchType={"investors"}
            route={{ path: "investors" }}
            {...this.props}
          />
        </Otherwise>
      </Choose>
    );
  }
}

export default PortfolioTab;
