import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Dig from './dig.component';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Dig,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
  domElementGetter(props) {
    return document.getElementById('dig');
  }
});

export const {bootstrap, mount, unmount} = lifecycles;
