import React from "react";
import PropTypes from "prop-types";

import xss from "xss";

export default class TimelineEventReconnectBody extends React.Component {
  static propTypes = {
    reason: PropTypes.string.isRequired,
  };

  render() {
    const { reason } = this.props;
    return (
      <div className="timeline-event-body">
        <p className="timeline-event-body-content-header">
          <b>Reason</b>
        </p>
        <p
          dangerouslySetInnerHTML={{
            __html: xss(reason.replace(/(?:\r\n|\r|\n)/g, "<br>")),
          }}
        />
      </div>
    );
  }
}
