import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-v4";
import PreviewInvestor from "./preview/PreviewInvestor";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import Loading from "../loading/Loading";
import RefreshIndicator from "material-ui/RefreshIndicator";
import DIGDownDialog from "../organization/DIGDownDialog";
import _ from "underscore";
import InvestorStore from "../../storage/InvestorStore";
import SearchParamsHelper from "../../lib/SearchParamsHelper";
import DialogWrapper from "../components/DialogWrapper";
const topOfElement = function (element) {
  if (!element) {
    return 0;
  }
  return element.offsetTop + topOfElement(element.offsetParent);
};

const getReturnUrl = (route, params) =>
  `/${route}?${Object.entries(params)
    .reduce((paramsArray, [param, value]) => {
      paramsArray.push(`${param}=${value}`);
      return paramsArray;
    }, [])
    .join("&")}`;

class Investors extends React.Component {
  static propTypes = {
    searchParams: PropTypes.object.isRequired,
    updateOrgsNumber: PropTypes.func.isRequired,
    changeUrl: PropTypes.func.isRequired,
  };

  state = {
    investors: [],
    userHasFullAccess: false,
    client: null,
    loading: false,
    loadingMore: false,
    displayError: false,
    openDIGDownDialog: false,
    errorMessageDIGDown: null,
    error: null,
    investorsLoadingSize: undefined,
  };

  componentWillMount() {
    const { searchParams } = this.props;
    this.initComponent(searchParams);
  }

  componentWillUnmount() {
    this.detachScrollListener();
    this._mounted = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { loading, loadingMore, investors, investorsLoadingSize } =
      this.state;
    if (
      !loading &&
      !loadingMore &&
      investors &&
      investors.length < investorsLoadingSize &&
      this._el &&
      parseInt(this._el.attributes["data-investors-num"].value) ===
        investors.length
    ) {
      this.attachScrollListener();
    }
  }

  componentWillUpdate(newProps) {
    const { searchParams = {} } = this.props;
    if (
      !SearchParamsHelper.arePropsEqual(searchParams, newProps.searchParams)
    ) {
      this.offset = 0;
      this.fetchNextPage(newProps.searchParams, true, true);
    }
  }

  componentDidMount() {
    const { investors, loading, loadingMore } = this.state;
    const { searchParams } = this.props;

    if (!loading && !loadingMore) {
      this.offset = 0;
      this.fetchNextPage(searchParams, true, true);
    } else {
      this.offset = investors.length;
    }
    this._mounted = true;
  }

  parseFilters = (filters) => {
    let filtersText = "";
    for (let key in filters) {
      if (filtersText !== "") {
        filtersText += "&";
      }
      filtersText += key + "=" + filters[key];
    }
    return filtersText;
  };

  initComponent = (searchParams) => {
    this.investorStore = new InvestorStore();
    this.setState({
      loading: true,
    });
    this.investorStore
      .getInvestors(this.parseFilters(searchParams))
      .then(([investors, investorsLoadingSize]) => {
        this.handleInvestorsChange(investors, investorsLoadingSize, true);
      })
      .catch((err) => {
        const errorMessage = `Problem retrieving Investors ${searchParams}: ${err}`;
        console.log(errorMessage);
        console.log(err);
        console.error(errorMessage, err);
        this.setState({
          openDIGDownDialog: true,
          errorMessageDIGDown: errorMessage,
          loading: false,
        });
      });
  };

  handleInvestorsChange = (
    nextInvestors,
    investorsLoadingSize,
    filtersChanged
  ) => {
    const { investors = [] } = this.state;
    const { updateOrgsNumber } = this.props;
    const newInvestors = filtersChanged
      ? nextInvestors
      : investors.concat(nextInvestors);
    this.setState({
      investors: newInvestors,
      loading: false,
      loadingMore: false,
      investorsLoadingSize: investorsLoadingSize,
    });
    updateOrgsNumber(investorsLoadingSize);
  };

  fetchNextPage = (searchParams, loadMore, filtersChanged) => {
    const { investorsLoadingSize, investors } = this.state;
    if (!filtersChanged) {
      searchParams["offset"] = investors.length;
    } else {
      searchParams["offset"] = 0;
    }
    if (loadMore || loadMore) {
      if (filtersChanged) {
        this.setState({ loading: true });
      } else {
        this.setState({ loadingMore: true });
      }
      this.investorStore
        .getInvestors(this.parseFilters(searchParams))
        .then(([nextInvestors, investorsLoadingSize]) => {
          this.handleInvestorsChange(
            nextInvestors,
            investorsLoadingSize,
            filtersChanged
          );
        })
        .catch((err) => {
          const errorMessage = `Problem retrieving Investors ${searchParams}: ${err}`;
          console.log(errorMessage);
          console.log(err);
          console.error(errorMessage, err);
          this.setState({
            openDIGDownDialog: true,
            errorMessageDIGDown: errorMessage,
            loadingMore: false,
            loading: false,
          });
        });
    }
  };

  scrollListener = () => {
    const { searchParams } = this.props;
    let el, scrollTop;
    if (this._mounted) {
      el = this._el;
      if (!el) return;
      scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;

      //load more when you reached the bottom 20% of the page height
      if (
        topOfElement(el) + el.offsetHeight - scrollTop - window.innerHeight <=
          el.offsetHeight * 0.2 &&
        !this.state.loading &&
        !this.state.loadingMore
      ) {
        this.detachScrollListener();
        this.fetchNextPage(searchParams, true, false);
      }
    }
  };

  attachScrollListener = () => {
    window.addEventListener("scroll", this.scrollListener);
    window.addEventListener("resize", this.scrollListener);
    this.scrollListener();
  };

  detachScrollListener = () => {
    window.removeEventListener("scroll", this.scrollListener);
    window.removeEventListener("resize", this.scrollListener);
  };

  handleErrorOpen = (error, organization) => {
    this.setState({
      error,
      displayError: true,
    });
  };

  handleErrorOpenDialog = (error) => {
    this.setState({
      error: error.message,
      displayError: true,
    });
  };

  handleErrorClose = () => {
    this.setState({
      error: null,
      displayError: false,
    });
  };

  handleCloseDIGDownDialog = () => {
    this.setState({
      openDIGDownDialog: false,
      errorMessageDIGDown: null,
    });
  };

  getErrorDialog = (errorMessage) => {
    const { displayError } = this.state;
    const errorDialogActions = [
      <FlatButton
        key="close"
        label="Close"
        primary={true}
        onClick={this.handleErrorClose}
      />,
    ];
    return (
      <DialogWrapper
        actions={errorDialogActions}
        modal={false}
        open={displayError}
        onRequestClose={this.handleErrorClose}
      >
        {errorMessage}
      </DialogWrapper>
    );
  };

  render() {
    const {
      pathname = "",
      searchParams = {},
      filterSource,
      profile,
      client,
      userHasFullAccess,
      userRoles = [],
      route: { path: source = "" } = {},
    } = this.props;
    const {
      investors = [],
      loading,
      openDIGDownDialog,
      errorMessageDIGDown,
      loadingMore,
    } = this.state;
    if (loading) {
      return <Loading fixed={true} />;
    }
    if (investors.length === 0) {
      return <div>There is no companies to display.</div>;
    }
    return (
      <div className={"investors-view"} style={{ marginTop: "24px" }}>
        {this.getErrorDialog(this.state.error)}
        <div
          ref={(el) => (this._el = el)}
          data-investors-num={investors.length}
        >
          {investors.map((investor) => {
            return (
              <div
                className={"preview-organization"}
                key={"preview-organization" + investor.id}
              >
                <PreviewInvestor
                  key={investor.id}
                  investor={investor}
                  profile={profile}
                  client={client}
                  returnUrl={getReturnUrl(pathname, searchParams)}
                  handleErrorOpen={this.handleErrorOpen}
                  userHasFullAccess={userHasFullAccess}
                  userRoles={userRoles}
                  handleErrorOpenDialog={this.handleErrorOpenDialog}
                  source={source}
                />
              </div>
            );
          })}
        </div>
        <If condition={loadingMore}>
          <RefreshIndicator
            size={30}
            loadingColor="#337ab7"
            status="loading"
            left={0}
            top={0}
            style={{ position: "relative", margin: "24px auto" }}
          />
        </If>
        <DIGDownDialog
          open={openDIGDownDialog}
          errorMessage={errorMessageDIGDown}
          handleClose={this.handleCloseDIGDownDialog}
        />
      </div>
    );
  }
}

export default withRouter(Investors);
