import React from "react";
import PropTypes from "prop-types";
import JsonDev from "../JsonDev";
import SocialLink from "../organization/SocialLink";
import InboxControls from "../organization/InboxControls";
import { Toolbar } from "material-ui";
import { ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import { Link, withRouter } from "react-router-v4";
import IconButton from "material-ui/IconButton/IconButton";
import Avatar from "material-ui/Avatar";
import imageUrl from "../../lib/imageUrl";
import IconMenu from "material-ui/IconMenu";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import MenuItem from "material-ui/MenuItem";
import { titleCase } from "../../lib/helpers";
import TopBarActions from "./TopBarActions";
import AlertsCheckbox from "./AlertsCheckbox";

class InvestorHeader extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    userRoles: PropTypes.array.isRequired,
    client: PropTypes.string.isRequired,
    showAsPublicProfile: PropTypes.bool,
    userHasFullAccess: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
  };

  state = {};

  goToPage = (page) => {
    const { router, location, investor: { id } = {} } = this.props;
    router.push({
      ...location,
      ...{
        pathname: `/investors/${id}/${page}`,
      },
    });
  };

  render() {
    const {
      client,
      investor: { name, investor_type = [], image_url } = {},
      profile,
    } = this.props;
    const investorTypeString = !investor_type.length
      ? "(Investor)"
      : `(${investor_type.reduce((investmentTypes, investmentType) => {
          return investmentTypes.concat(
            `${investmentTypes !== "" ? "," : ""}${titleCase(
              investmentType.replace(/_/g, " ")
            )}`
          );
        }, "")})`;
    return (
      <React.Fragment>
        <Toolbar className="top-bar-tool-bar">
          <div className="top-bar">
            <ToolbarGroup className="top-bar-first-row">
              <div className="investor-title">
                <Avatar
                  className="investor-avatar"
                  src={imageUrl({ url: image_url })}
                />
                <ToolbarTitle className="investor-name" text={name} />
                <ToolbarTitle
                  className="investor-name"
                  text={investorTypeString}
                  style={{ color: "#5dab49", marginLeft: "0px" }}
                />
              </div>

              <div className="investor-links">
                <AlertsCheckbox
                  client={client}
                  investor={this.props.investor}
                  profile={profile}
                />
                <TopBarActions
                  source="profile"
                  goToPage={this.goToPage}
                  {...this.props}
                />
              </div>
            </ToolbarGroup>
          </div>
        </Toolbar>
      </React.Fragment>
    );
  }
}

export default withRouter(InvestorHeader);
