import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import DialogWrapper from "../../components/DialogWrapper";

export default class ConfirmDeleteScorecardCallDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  };

  render() {
    const { open, onCancel, onConfirm } = this.props;
    const actions = [
      <FlatButton
        label="Cancel"
        style={{
          marginRight: "12px",
        }}
        onClick={onCancel}
      />,
      <FlatButton
        label="Confirm"
        style={{
          backgroundColor: "#f44336",
        }}
        labelStyle={{
          color: "#ffffff",
        }}
        onClick={onConfirm}
      />,
    ];
    return (
      <DialogWrapper
        title="No show"
        modal={false}
        open={open}
        onRequestClose={onCancel}
        titleStyle={{
          paddingBottom: "6px",
        }}
        contentStyle={{
          position: "relative",
          width: "375px",
        }}
        repositionOnUpdate={true}
        actions={actions}
      >
        Are you sure you want to record this call as a no show?
      </DialogWrapper>
    );
  }
}
