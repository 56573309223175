import React from "react";
import PropTypes from "prop-types";
import InvestorStore from "../../../storage/InvestorStore";
import _ from "underscore";
import Loading from "../../loading/Loading";
import { ListItem } from "material-ui/List";
import { List } from "material-ui";
import AddCircleOutline from "material-ui/svg-icons/content/add-circle";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import FlatButton from "material-ui/FlatButton";
import AddInvestor from "./AddInvestor";
import Avatar from "material-ui/Avatar";

export default class EditInvestors extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    addInvestor: PropTypes.func.isRequired,
    removeInvestor: PropTypes.func.isRequired,
  };

  investorStore = new InvestorStore();

  state = {
    investors: [],
    loading: true,
    showAddInvestorDialog: false,
  };

  initComponent = ({ organization: { investor_ids: investorIds = [] } }) => {
    if (investorIds.length < 1) {
      this.setState({ loading: false, showAddInvestorDialog: false });
      return;
    }
    this.setState({ loading: true, showAddInvestorDialog: false });
    this.investorStore
      .multiGetInvestors(investorIds)
      .then((investors) => this.setState({ investors, loading: false }))
      .catch(console.error);
  };

  componentWillMount() {
    this.initComponent(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.organization.id !== this.props.organization.id ||
      !_.isEqual(nextProps.investor_ids, this.props.organization.investor_ids)
    ) {
      this.initComponent(nextProps);
    }
  }

  handleCloseInvestorDialog = () => {
    this.setState({ showAddInvestorDialog: false });
  };

  render() {
    const { loading, investors, showAddInvestorDialog } = this.state;
    const { organization, addInvestor, removeInvestor } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        <If condition={investors.length < 1}>
          <p>No investors have been added yet to this company</p>
        </If>
        <List>
          {investors.map((investor) => (
            <ListItem
              key={investor.id}
              leftAvatar={<Avatar src={investor.image_url} />}
              primaryText={investor.name}
              rightIcon={
                <DeleteIcon onClick={() => removeInvestor(investor)} />
              }
            />
          ))}
        </List>
        <FlatButton
          label="Add investor"
          onClick={() => this.setState({ showAddInvestorDialog: true })}
          icon={<AddCircleOutline color={"#5DAB49"} />}
        />
        <AddInvestor
          open={showAddInvestorDialog}
          organization={organization}
          handleCancel={this.handleCloseInvestorDialog}
          handleAddInvestor={addInvestor}
        />
      </React.Fragment>
    );
  }
}
