import React from "react";
import PropTypes from "prop-types";

import Source from "./Source";

const IGNORED_SOURCES = [
  "INREACH",
  "PRIORI",
  "GOOGLECSE",
  "STREAK",
  "CALLFEEDBACK",
  "RECONNECT",
  "QUALIFICATION",
  "PREQUALIFICATION",
  "OUTREACH",
  "ASSIGN_CAMPAIGNS",
];

export default class SourcesCell extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    horizontal: PropTypes.bool.isRequired,
  };

  render() {
    const {
      organization: {
        source_references,
        source_refs: sources = source_references,
        origin,
      },
      horizontal = false,
    } = this.props;

    if (!sources) {
      return null;
    }

    const classes = ["legacy-sources", "list-unstyled"];

    if (horizontal) {
      classes.push("horizontal");
    }

    const classNames = classes.join(" ");

    const providers = Object.keys(sources).filter(
      (provider) => !IGNORED_SOURCES.includes(provider)
    );

    return (
      <ul className={classNames}>
        {providers.map((provider) => (
          <Source
            key={provider}
            provider={provider}
            isOrigin={origin === provider}
          />
        ))}
      </ul>
    );
  }
}
