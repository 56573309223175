export default [
  {
    active: 0,
    tasks: ["send_invite", "connect_on_linkedin"],
  },
  {
    active: 2,
    tasks: ["send_reminder", "send_inmail"],
  },
  {
    active: 2,
    tasks: ["confirm_unengaged", "add_founder", "restart", "restart_all"],
  },
];
