import React, { Component } from "react";
import PropTypes from "prop-types";
import Avatar from "material-ui/Avatar";
import Tooltip from "../Tooltip";

export default class Highlight extends Component {
  static propTypes = {
    icon: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
  };

  static defaultProps = {
    backgroundColor: "black",
  };

  render() {
    const { icon, label, backgroundColor } = this.props;

    return (
      <div className="highlight" ref={(el) => (this._target = el)}>
        <Avatar backgroundColor={backgroundColor} icon={icon} size={22} />
        <Tooltip
          parent={this._target}
          position="top"
          text={label}
          showOnHover
        />
      </div>
    );
  }
}
