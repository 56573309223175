import React from "react";
import { Router, IndexRoute, Route, browserHistory } from "react-router-v4";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import MuiTheme from "../lib/MuiTheme";

import AppContainer from "./AppContainer";
import AddPerson from "./organization/edit/AddPerson";
import AuthCallback from "./AuthCallback";
import CSVImport from "./CSVImport";
import SourceScores from "./SourceScores";
import CreateOrganization from "./organization/create/CreateOrganization";
import EditOrganization from "./organization/edit/EditOrganization";
import EditIncompleteOrganization from "./organization/edit/EditIncompleteOrganization";
import EditPersonRoute from "./person/EditPersonRoute";
import GatekeeperSingleSpa from "./GatekeeperSingleSpa";
import InboxRoute from "./InboxRoute";
import Index from "./Index";
import Login from "./Login";
import Organization from "./organization/Organization";
import OrganizationsRoute from "./OrganizationsRoute";
import DiscoverRoute from "./DiscoverRoute";
import IncompleteRoute from "./IncompleteRoute";
import PeopleSearch from "./person/PeopleSearch";
import Person from "./person/Person";
import SpamRoute from "./SpamRoute";
import Task from "./task/Task";
import Tasks from "./task/Tasks";
import Unauthorised from "./Unauthorised";
import MergeOrganizationRoute from "./organization/merge/MergeOrganizationRoute";
import MergeOrganizations from "./organization/merge/MergeOrganizations";
import FundingTrackerStats from "./stats/FundingTrackerStats";
import Investor from "./investor/Investor";
import InvestorsPage from "./investor/InvestorsPage";
import InvestorOverview from "./overview/InvestorOverview";
import OperationalOverview from "./overview/OperationalOverview";
import EditInvestor from "./investor/edit/EditInvestor";
import IncompleteBatchRoute from "./incompletes/IncompleteBatchRoute";

const muiTheme = getMuiTheme(MuiTheme);

export default class Main extends React.Component {
  render() {
    const { auth0, inboxState, mountParcel } = this.props;

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <Router history={browserHistory}>
          {/*<Route path="/" component={AppContainer}>*/}
          {/*  <Route path="login" component={Login} />*/}
          {/*  <Route path="callback" component={AuthCallback} />*/}
          {/*  <Route path="unauthorised" component={Unauthorised} />*/}

          {/*  /!* Routes that require authentication *!/*/}
          <Route
            component={GatekeeperSingleSpa}
            auth0={auth0}
            mountParcel={mountParcel}
          >
            {/*    <IndexRoute component={Index} />*/}

            {/* <Route path="discover" component={DiscoverRoute} /> */}
            <Route path="incomplete" component={IncompleteRoute} />
            <Route path="incompletes" component={IncompleteBatchRoute} />

            {/* <Route path="organizations" component={OrganizationsRoute} /> */}
            <Route path="organizations/create" component={CreateOrganization} />
            <Route
              path="organizations/:id"
              mountParcel={mountParcel}
              inboxState={inboxState}
              component={Organization}
            />
            <Route path="organizations/:id/edit" component={EditOrganization} />
            <Route
              path="organizations/:id/edit_incomplete"
              component={EditIncompleteOrganization}
            />
            <Route
              path="organizations/:source/merge(/:target)"
              component={MergeOrganizationRoute}
            />
            <Route
              path="similar_organizations"
              component={MergeOrganizations}
            />
            <Route path="organizations/:id/add_person" component={AddPerson} />
            <Route
              path="organizations/:id(/:tab)(/:mode)"
              component={Organization}
            />

            <Route path="people" component={PeopleSearch} />
            <Route path="people/:id" component={Person} />
            <Route path="people/:id/edit" component={EditPersonRoute} />

            <Route path="tasks" component={Tasks} />
            <Route path="tasks/:id" component={Task} />

            <Route path="inbox" component={InboxRoute} />

            <Route path="spam" component={SpamRoute} />

            <Route path="csv" component={CSVImport} />
            <Route path="source_scores" component={SourceScores} />

            <Route path="investors/tracker" component={FundingTrackerStats} />
            <Route path="investors" component={InvestorsPage} />
            <Route path="investors/:id/edit" component={EditInvestor} />
            <Route path="investors/:id(/:tab)(/:mode)" component={Investor} />
            <Route path="overview" component={InvestorOverview} />
            <Route path="overview/reconnect" component={OperationalOverview} />
            {/*</Route>*/}
          </Route>
        </Router>
      </MuiThemeProvider>
    );
  }
}
