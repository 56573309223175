import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import _ from "underscore";

import Analytics from "../../Analytics";
import Insights from "../../Insights";
import TrafficCountries from "./TrafficCountries";
import TrafficSources from "./TrafficSources";
import NewTag from "../../NewTag";

class WebProduct extends React.Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    signalsLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool,
  };

  buildProduct = () => {
    const {
      product: {
        analytics = [],
        ranking: {
          matched_highlights: highlights = [],
          matched_risks: risks = [],
          matched_red_flags: redFlags = [],
        } = {},
        countries = [],
        sources = [],
        ratings,
      } = {},
    } = this.props;
    return {
      analytics,
      highlights,
      risks,
      redFlags,
      countries,
      sources,
      ratings,
    };
  };

  hasChanged = () => {
    const { product = {}, useSnapshot = false, previous = false } = this.props;
    const { snapshot: snapshotProduct = {} } = product;
    if (useSnapshot && !previous && _.isEmpty(snapshotProduct)) {
      return !_.isEqual(
        this.getWebProductPropertiesUsed(product),
        this.getWebProductPropertiesUsed(snapshotProduct)
      );
    } else {
      return false;
    }
  };

  filterMlUpdates = () => {
    const { mlUpdates } = this.props;
    return mlUpdates
      .filter(
        (object) =>
          (object.name.includes("product") &&
            object.raw === "website" &&
            object.score > 0) ||
          (object.name.includes("market") && object.score > 0)
      )
      .map((update) => (update.notes ? update.notes[0] : update.name));
  };

  getWebProductPropertiesUsed = (product) =>
    _.pick(product, "ranking", "countries", "sources");

  render() {
    const {
      signalsLoading,
      product: { snapshot = {} } = {},
      useSnapshot = false,
      previous = false,
      mlUpdates,
      organization,
      organizationDecision,
    } = this.props;
    const {
      analytics,
      highlights,
      risks,
      redFlags,
      countries,
      sources,
      ratings,
    } = this.buildProduct();
    const hasChanged = this.hasChanged();
    return (
      <div className="product web-product">
        <If condition={hasChanged}>
          <NewTag source="web-product" snapshot={snapshot} />
        </If>
        <div className="grid-container product-grid-container web-grid-container">
          <div className="product-grid-item basic-info-grid-item">
            <div
              className="name-container"
              style={{
                paddingLeft: hasChanged && !previous ? "36px" : "",
              }}
            >
              Website
            </div>
          </div>
          <div className="product-grid-item insights-grid-item">
            <Insights
              highlights={highlights}
              risks={risks}
              redFlags={redFlags}
              mlUpdates={this.filterMlUpdates()}
            />
          </div>
          <div className="product-grid-item performance-grid-item">
            <Analytics
              productType="web"
              graphType="full"
              analytics={analytics}
              signalsLoading={signalsLoading}
              snapshot={snapshot}
              useSnapshot={useSnapshot}
              title="Weekly Web Visits"
              organization={organization}
              organizationDecision={organizationDecision}
              showSubTitle={false}
            />
          </div>
          <div className="product-grid-item other-info-grid-item">
            <div className="other-info">
              <div className="grid-container web-other-info-grid-container">
                <div className="web-other-info-grid-item border1" />
                <div className="web-other-info-grid-item traffic-countries-grid-item">
                  <TrafficCountries countries={countries} />
                </div>
                <div className="web-other-info-grid-item border2" />
                <div className="web-other-info-grid-item traffic-sources-grid-item">
                  <TrafficSources sources={sources} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(WebProduct);
