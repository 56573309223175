import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-v4";

import Snackbar from "material-ui/Snackbar";

import ProfileTab from "./profile/ProfileTab";
import WorkflowTab from "./workflow/WorkflowTab";
import ScorecardTab from "./scorecard/ScorecardTab";
import QualificationTab from "./qualification/QualificationTab";
import FullScreenLoading from "../loading/FullScreenLoading";

import TimelineStore from "../../storage/TimelineStore";
import PersonStore from "../../storage/PersonStore";
import FilesStore from "../../storage/FilesStore";
import ScorecardStore from "../../storage/ScorecardStore";
import ScorecardTemplateStore from "../../storage/ScorecardTemplateStore";
import QualificationStore from "../../storage/QualificationStore";

import _ from "underscore";
import moment from "moment";

import scorecardSections from "../../content/scorecardSections";
import xss from "xss";

class OrganizationTabsContent extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    snapshot: PropTypes.object.isRequired,
    snapshotLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool.isRequired,
    investors: PropTypes.array.isRequired,
    investorsLoading: PropTypes.bool.isRequired,
    organizationDecision: PropTypes.object.isRequired,
    signals: PropTypes.object.isRequired,
    signalsLoading: PropTypes.bool.isRequired,
    fundingRounds: PropTypes.array.isRequired,
    fundingRoundsLoading: PropTypes.bool.isRequired,
    client: PropTypes.string.isRequired,
    userHasFullAccess: PropTypes.bool.isRequired,
    forms: PropTypes.array.isRequired,
    userRoles: PropTypes.array.isRequired,
    selectedTab: PropTypes.string.isRequired,
    organizationStore: PropTypes.object,
    setTabContent: PropTypes.func.isRequired,
    handleOrganizationInvestmentTypeChange: PropTypes.func.isRequired,
    handleOrganizationInvestmentStageChange: PropTypes.func.isRequired,
    getLatestCall: PropTypes.func.isRequired,
    structuredReasons: PropTypes.object.isRequired,
    setScorecard: PropTypes.func.isRequired,
    scorecard: PropTypes.object.isRequired,
  };

  state = {
    timeline: null,
    timelineLoading: false,
    people: [],
    peopleLoading: true,
    files: null,
    filesFolder: "",
    filesLoading: true,
    isAddingFiles: false,
    showUploadFilesModal: false,
    showScorecardModal: false,
    scorecardModalType: "",
    scorecardModalDefaultOpenCallId: "",
    scorecardModalEditIndex: null,
    scorecardModalEditCallId: "",
    confirmCloseWithoutSavingScorecardRecommendationDecisionDialog: false,
    scorecardModalRecommendationDecisionSaved: true,
    scorecardTemplates: null,
    scorecardSectionsForTemplate: null,
    scorecardLoading: true,
    scorecardSaving: false,
    scorecardHasMadeChanges: false,
    qualification: {},
    qualificationLoading: true,
    snackbarOpen: false,
    showFullScreenLoading: false,
  };

  timelineStore = new TimelineStore(this.props.organization.id);
  filesStore = new FilesStore(this.props.organization.id);
  scorecardStore = new ScorecardStore(this.props.organization.id);
  scorecardTemplateStore = new ScorecardTemplateStore();
  qualificationStore = new QualificationStore(this.props.organization.id);

  revenueModelsChanged = true;

  componentWillMount() {
    const { selectedTab } = this.props;
    this.getTabData(selectedTab);
  }

  componentDidUpdate(previousProps) {
    const {
      organization: { person_ids: currentPersonIds = [] },
      setTabContent,
      organizationDecision: currentOrganizationDecision,
    } = this.props;
    const {
      organization: { person_ids: previousPersonIds = [] },
      organizationDecision: previousOrganizationDecision,
    } = previousProps;

    if (
      !_.isEqual(currentPersonIds.sort(), previousPersonIds.sort()) ||
      !_.isEqual(currentOrganizationDecision, previousOrganizationDecision)
    ) {
      return this.getWorkflowData().then((timeline) => {
        setTabContent("workflow", timeline);
      });
    }
  }

  getOtherTabsData = () => {
    ["workflow", "scorecard", "qualification"]
      .filter((tab) => tab !== this.props.selectedTab)
      .forEach((tab) => this.getTabData(tab));
  };

  getTabData = (tabName = this.props.selectedTab, forceUpdate) => {
    const { setTabContent } = this.props;
    switch (tabName) {
      case "profile":
        this.getOtherTabsData();
        break;
      case "workflow":
        const { timeline, people, files } = this.state;
        if (!timeline || !people || !files || forceUpdate) {
          return this.getWorkflowData().then((timeline) => {
            setTabContent(tabName, timeline);
            if (tabName === this.props.selectedTab) {
              this.getOtherTabsData();
            }
          });
        }
        break;
      case "scorecard":
        const { scorecard } = this.props;
        const { scorecardSectionsForTemplate } = this.state;
        if (!scorecard || !scorecardSectionsForTemplate) {
          this.getScorecardData().then((scorecard) => {
            if (tabName === this.props.selectedTab) {
              this.getOtherTabsData();
            }
          });
        }
        break;
      case "qualification":
        if (Object.keys(this.state.qualification).length === 0) {
          return this.getQualificationData().then((qualification) => {
            setTabContent(tabName, qualification);
            if (tabName === this.props.selectedTab) {
              this.getOtherTabsData();
            }
          });
        }
        break;
    }
  };

  getWorkflowData = (timelineLoading = this.state.timelineLoading) => {
    const { setTabContent } = this.props;
    if (timelineLoading) {
      return Promise.resolve(null);
    }
    this.setState({
      timelineLoading: true,
    });
    return this.getWorkflowTimelineData().then((timeline) => {
      this.setState({
        timeline,
        timelineLoading: false,
        peopleLoading: true,
        filesLoading: true,
      });
      return Promise.all([
        this.getWorkflowPeopleData(),
        this.getWorkflowFilesData(),
      ])
        .then(([people, filesData]) => {
          const uniquePeople = [];
          for (let i = 0; i < people.length; i++) {
            let isFirstInstance = true;
            for (let j = 0; j < uniquePeople.length; j++) {
              if (_.isEqual(uniquePeople[j], people[i])) {
                isFirstInstance = false;
              }
            }
            if (isFirstInstance) uniquePeople.push(people[i]);
          }
          const { folder_id: folderId, files = [] } = filesData;
          setTabContent("people", people);
          this.setState({
            people: uniquePeople,
            peopleLoading: false,
            files,
            filesFolder: folderId,
            filesLoading: false,
          });
          return timeline;
        })
        .catch((err) =>
          this.setState({
            peopleLoading: false,
            filesLoading: false,
          })
        );
    });
  };

  getWorkflowTimelineData = (flush = false) => {
    return this.timelineStore.getTimeline(flush);
  };

  getWorkflowPeopleData = (organization = this.props.organization) => {
    const { person_ids: personIds = [], id } = organization;
    if (personIds.length > 0) {
      return new PersonStore(personIds || []).getModels(id);
    }
    return Promise.resolve([]);
  };

  getWorkflowFilesData = (filesLoading = this.state.filesLoading) => {
    if (!filesLoading) {
      this.setState({
        filesLoading: true,
      });
    }
    return this.filesStore.get();
  };

  createWorkflowFilesFolder = () => {
    if (this.state.filesFolder) {
      return;
    }

    this.setState({ filesLoading: true });

    this.filesStore
      .createFolder()
      .then((files) => {
        this.setState({
          files: files.files || [],
          filesFolder: files.folder_id,
          filesLoading: false,
        });
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          filesLoading: false,
        });
      });
  };

  onWorkflowFileChange = (files = []) => {
    if (files.length < 1) {
      return;
    }
    this.setState({
      isAddingFiles: true,
    });

    return Promise.all(
      Array.from(files).map((file) => this.filesStore.uploadFile(file))
    )
      .then((responses) =>
        responses.reduce((a, b) => (a.files.length >= b.files.length ? a : b))
      )
      .then((files) => {
        this.setState({
          files: files.files || [],
          filesFolder: files.folder_id,
          showUploadFilesModal: false,
          isAddingFiles: false,
        });
      })
      .catch(console.error);
  };

  addWorkflowFiles = (fileIds) => {
    if (fileIds.length < 1) {
      return;
    }

    this.setState({ isAdding: true });

    Promise.all(
      fileIds.map((fileId) =>
        this.filesStore.copyOrAddFile(fileId, this.state.copyOrAdd)
      )
    )
      .then((responses) =>
        responses.reduce((a, b) => (a.files.length >= b.files.length ? a : b))
      )
      .then((files) => {
        this.setState({
          files: files.files || [],
          filesFolder: files.folder_id,
          showUploadFilesModal: false,
          isAddingFiles: false,
        });
      })
      .catch(console.error);
  };

  toggleUploadFilesModal = () => {
    this.setState({
      showUploadFilesModal: !this.state.showUploadFilesModal,
    });
  };

  setScorecardLoading = (loading) => {
    if (loading !== this.state.scorecardLoading) {
      this.setState({
        scorecardLoading: loading,
      });
    }
  };

  setRevenueModelsChanged = (changed = false) => {
    this.revenueModelsChanged = changed;
  };

  setScorecardSaving = (saving) => {
    if (saving !== this.state.scorecardSaving) {
      this.setState({
        scorecardSaving: saving,
      });
    }
  };

  setShowFullScreenLoading = (loading) => {
    if (loading !== this.state.showFullScreenLoading) {
      this.setState({
        showFullScreenLoading: loading,
      });
    }
  };

  setScorecardHasMadeChanges = (changes) => {
    if (changes !== this.state.scorecardHasMadeChanges) {
      this.setState({
        scorecardHasMadeChanges: changes,
      });
    }
  };

  setScorecardModalEditIndex = (id) => {
    this.editScorecardModal();
    this.setState({
      scorecardModalEditIndex: id,
      scorecardModalDefaultOpenCallId: id,
    });
  };

  openScorecardModal = (state = "view", callId = "") => {
    this.setState({
      scorecardModalEditIndex: callId,
      showScorecardModal: true,
      scorecardModalType: state,
      scorecardModalDefaultOpenCallId: callId,
    });
  };

  closeScorecardModal = () => {
    if (!this.state.scorecardModalRecommendationDecisionSaved) {
      this.toggleCloseWithoutSavingScorecardRecommendationDecisionDialog();
    } else {
      this.setScorecardHasMadeChanges(false);
      this.setState({
        showScorecardModal: false,
        scorecardModalType: "",
        scorecardModalDefaultOpenCallId: "",
      });
    }
  };

  editScorecardModal = () => {
    this.setState({
      scorecardModalType: "edit",
    });
  };

  getScorecardData = () => {
    const { setTabContent, setScorecard } = this.props;
    this.setScorecardLoading(true);
    return Promise.all([
      this.scorecardStore.getScorecard(),
      this.scorecardTemplateStore.getTemplates(),
      this.scorecardTemplateStore.getSectionsForTemplates(),
    ]).then(([scorecard, scorecardTemplates, scorecardSectionsForTemplate]) => {
      setTabContent("scorecard", scorecard);
      this.setState({
        scorecardTemplates,
        scorecardSectionsForTemplate,
      });
      setScorecard(scorecard);
      this.setScorecardModalEditIndex(scorecard?.calls?.[0].id);
      this.setScorecardLoading(false);
    });
  };

  handleSubmitNoShow = (callId) => {
    const { setScorecard } = this.props;
    this.setScorecardLoading(true);
    this.setShowFullScreenLoading(true);
    return this.scorecardStore
      .submitCallNoShow(callId)
      .then((scorecard) => {
        this.setScorecardLoading(false);
        this.setShowFullScreenLoading(false);
        setScorecard(scorecard);
        return scorecard;
      })
      .catch((error) => {
        this.setState({
          snackbarOpen: true,
        });
      });
  };

  saveScorecardNotes = (callId, notes) => {
    const { setScorecard } = this.props;
    this.setScorecardLoading(true);
    this.setScorecardSaving(true);
    this.setShowFullScreenLoading(true);
    return this.scorecardStore
      .updateNotes(callId, notes)
      .then((scorecard) => {
        this.setScorecardLoading(false);
        this.setScorecardSaving(false);
        this.setShowFullScreenLoading(false);
        setScorecard(scorecard);
        this.closeScorecardModal();
      })
      .catch((error) => {
        this.setState({
          snackbarOpen: true,
        });
      });
  };

  handleSaveScorecardClick = () => {
    const { getLatestCall } = this.props;
    const { scorecardModalEditIndex } = this.state;
    const { scorecard: { calls = [] } = {} } = this.props;
    const { id, notes = {} } = getLatestCall(scorecardModalEditIndex);
    this.saveScorecardNotes(id, _.omit(notes, ["created_at", "updated_at"]));
  };

  handleScorecardCallDetailsChange = (values, hasMadeChanges = true) => {
    const { getLatestCall } = this.props;
    const { scorecardModalEditIndex } = this.state;
    const { scorecard: { calls = [] } = {} } = this.props;
    const { id } = getLatestCall(scorecardModalEditIndex);
    if ("revenue_models" in values) {
      this.setRevenueModelsChanged(true);
    }
    this.setScorecardSaving(true);
    this.setScorecardHasMadeChanges(hasMadeChanges);
    this.partialUpdateScorecardCall(id, values);
  };

  handleScorecardCallWithChange = (event, value) => {
    this.updateScorecardCallWith(value);
    this.setScorecardSaving(true);
    this.setScorecardHasMadeChanges(true);
    this.debounceCallWithChange(value);
  };

  handleScorecardCallTitleChange = (event, value) => {
    this.updateScorecardCallTitle(value);
    this.setScorecardSaving(true);
    this.setScorecardHasMadeChanges(true);
    this.debounceCallTitleChange(value);
  };

  updateScorecardCallWith = (value) => {
    const { getLatestCall } = this.props;
    const { scorecardModalEditIndex } = this.state;
    const { scorecard: initialScorecard = {} } = this.props;
    const scorecard = Object.assign({}, initialScorecard);
    const newCall = getLatestCall(scorecardModalEditIndex);
    newCall.with = xss(value);
    scorecard.calls[scorecardModalEditIndex] = newCall;
    this.setState({
      scorecard,
    });
  };

  updateScorecardCallTitle = (value) => {
    const { getLatestCall, setScorecard } = this.props;
    const { scorecardModalEditIndex } = this.state;
    const { scorecard: initialScorecard = {} } = this.props;
    const scorecard = Object.assign({}, initialScorecard);
    const newCall = getLatestCall(scorecardModalEditIndex);
    newCall.title = xss(value);
    scorecard.calls[scorecardModalEditIndex] = newCall;
    setScorecard(scorecard);
  };

  debounceCallWithChange = _.debounce((value) => {
    const { getLatestCall } = this.props;
    const { scorecardModalEditIndex } = this.state;
    const { scorecard: { calls = [] } = {} } = this.props;
    const { id } = getLatestCall(scorecardModalEditIndex);
    this.partialUpdateScorecardCall(id, { with: value });
  }, 1500);

  debounceCallTitleChange = _.debounce((value) => {
    const { getLatestCall } = this.props;
    const { scorecardModalEditIndex } = this.state;
    const { scorecard: { calls = [] } = {} } = this.props;
    const { id } = getLatestCall(scorecardModalEditIndex);
    this.partialUpdateScorecardCall(id, { title: value });
  }, 1500);

  handleUpdateScorecardCall = (values) => {
    const {
      scorecard: { calls = [] },
      getLatestCall,
    } = this.props;
    const { scorecardModalEditIndex } = this.state;
    const { id, template_id } = getLatestCall(scorecardModalEditIndex);
    this.partialUpdateScorecardCall(id, values);
  };

  handleScorecardSectionChange = (content, section, callId) => {
    return new Promise((resolve) => {
      this.updateScorecardSection(content, section, callId).then(() => {
        this.setScorecardSaving(true);
        this.setScorecardHasMadeChanges(true);
        this.setRevenueModelsChanged(false);
        this.debounceScorecardSectionChange(section, callId);
        resolve(content);
      });
    });
  };

  updateScorecardSection = (content, section, callId) => {
    const { getLatestCall, setScorecard } = this.props;
    const { scorecard: stateScorecard = {} } = this.props;
    const scorecardDuplicate = Object.assign({}, stateScorecard);
    const newCall = getLatestCall(callId);
    return new Promise((resolve) => {
      newCall.notes[section] = content;
      scorecardDuplicate.calls[callId] = newCall;
      setScorecard(stateScorecard);
      resolve(stateScorecard);
    });
  };

  debounceScorecardSectionChange = _.debounce((section, callId) => {
    const { getLatestCall } = this.props;
    const { scorecard: { calls = [] } = {} } = this.props;
    const { id, notes: { [section]: notes } = {} } = getLatestCall(callId);
    this.saveScorecardSectionNote(id, section, notes);
  }, 1500);

  saveScorecardSectionNote = (callId, section, note) => {
    this.setScorecardSaving(true);
    return this.scorecardStore
      .updateNoteSection(callId, section, note)
      .then((responseSection) => {
        this.setScorecardSaving(false);
      })
      .catch((error) => {
        this.setState({
          snackbarOpen: true,
        });
      });
  };

  createNewScorecardCall = () => {
    const { forms = [], setScorecard } = this.props;
    const {
      scorecard: { calls = [] },
    } = this.props;
    this.setScorecardLoading(true);
    const { notes = {} } =
      calls.length > 0
        ? calls
            .sort((a, b) => b.created_at - a.created_at)
            .filter((call) => {
              return !(
                call.template_id in
                ["formcall", "recommendationcall", "prequalificationcall"]
              );
            })
            .shift() || {}
        : {}; /*forms.length > 0
          ? this.extractStructuredDataFromForm()
          : {};*/
    return this.scorecardStore
      .createCall({
        notes: _.pick(
          notes,
          scorecardSections.filter((item) => item !== "notes")
        ),
      })
      .then((scorecard) => {
        this.setScorecardLoading(false);
        setScorecard(scorecard);
        return scorecard;
      })
      .catch((error) => {
        this.setScorecardSaving(false);
        this.setState({
          snackbarOpen: true,
        });
      });
  };

  deleteScorecardCall = (callId) => {
    const { setScorecard } = this.props;
    this.setScorecardLoading(true);
    return this.scorecardStore
      .deleteCall(callId)
      .then((scorecard) => {
        this.setScorecardLoading(false);
        setScorecard(scorecard);
      })
      .catch((error) => {
        this.setScorecardLoading(false);
        this.setState({
          snackbarOpen: true,
        });
      });
  };

  partialEmptyScorecardCall = (callId, sections) => {
    if (sections.length === 0) return;
    this.setScorecardSaving(true);
    this.setShowFullScreenLoading(true);
    return this.scorecardStore
      .updateNotes(
        callId,
        sections.reduce((obj, value) => {
          obj[value] = {
            formatted_content: "",
          };
          return obj;
        }, {})
      )
      .then((notes) => {
        this.setScorecardSaving(false);
        this.setShowFullScreenLoading(false);
      })
      .catch((error) => {
        this.setScorecardSaving(false);
        this.setShowFullScreenLoading(false);
      });
  };

  partialUpdateScorecardCall = (callId, values) => {
    const { setScorecard } = this.props;
    const valuesArr = Object.entries(values);
    if (valuesArr.length === 0) return;
    this.setScorecardSaving(true);
    this.setShowFullScreenLoading(true);
    return this.scorecardStore
      .partialUpdateCall(
        callId,
        valuesArr.map(([key, value]) => ({
          op: "replace",
          path: `/${key}`,
          value,
        }))
      )
      .then((scorecard) => {
        this.setScorecardSaving(false);
        this.setShowFullScreenLoading(false);
        setScorecard(scorecard);
      })
      .catch((error) => {
        this.setScorecardSaving(false);
        this.setShowFullScreenLoading(false);
        this.setState({
          snackbarOpen: true,
        });
      });
  };

  writeScorecardRecommendationOrDecision = (type, content) => {
    const { getLatestCall } = this.props;
    const {
      scorecard: { calls = [] },
    } = this.props;
    const { scorecardModalEditIndex } = this.state;
    const callId = getLatestCall(scorecardModalEditIndex).id;
    this.setScorecardSaving(true);
    this.setShowFullScreenLoading(true);
    return type === "recommendation"
      ? this.scorecardStore
          .writeRecommendation(callId, content)
          .then(this.handleWriteScorecardRecommendationOrDecisionResult)
          .catch((error) => {
            this.setState({
              snackbarOpen: true,
            });
          })
      : this.scorecardStore
          .writeDecision(callId, content)
          .then(this.handleWriteScorecardRecommendationOrDecisionResult)
          .catch((error) => {
            this.setState({
              snackbarOpen: true,
            });
          });
  };

  extractStructuredDataFromForm = () => {
    const { forms = [] } = this.props;
    const {
      people: {
        employees: {
          number_engineering_product = " ",
          number_other = " ",
          number_sales_marketing = " ",
          number_total_employees = " ",
        } = {},
      } = {},
      product: {
        revenue: { revenue_last_month = " " } = {},
        users: { users_last_month = " " } = {},
      } = {},
      funding: {
        external_funding: { received = " " } = {},
        fresh_capital: { looking_for_amount = " " } = {},
      } = {},
    } = forms.sort(
      (a, b) =>
        (b.created || b.date_submitted) - (a.created || a.date_submitted)
    )[0];
    return {
      notes: {
        people: {
          "employees.marketing_sales": { value: number_sales_marketing },
          "employees.other": { value: number_other },
          "employees.total": { value: number_total_employees },
        },
        product: {
          "traction.users": this.getStructuredDataObj(users_last_month),
          "traction.total_mrr": this.getStructuredDataObj(
            revenue_last_month,
            true
          ),
        },
        funding: {
          next_round_amount: this.getStructuredDataObj(
            looking_for_amount,
            true
          ),
          total_funding_amount: this.getStructuredDataObj(received, true),
        },
      },
    };
  };

  getStructuredDataObj = (string = " ", isMoney = false) => {
    if (string === " ")
      return {
        value: " ",
      };
    const currency = isMoney ? this.getCurrency(string) : "";
    const numberStripped = Math.round(
      parseInt(string.replace(/[^0-9\.]/g, ""))
    ).toString();
    const length = numberStripped.length;
    const remainder = length % 3;
    return {
      value: numberStripped.substring(0, remainder === 0 ? 3 : remainder),
      multiplier:
        length <= 3
          ? ""
          : length >= 4 && length <= 6
          ? "K"
          : length >= 7 && length <= 9
          ? "M"
          : "B",
      ...(isMoney
        ? {
            currency: currency
              ? currency === "£"
                ? "GBP"
                : currency === "$"
                ? "USD"
                : "EUR"
              : "",
          }
        : {}),
    };
  };

  getCurrency = (string = "") => {
    const firstChar = string.substring(0, 1);
    return new RegExp(/[$£€]/).test(firstChar) ? firstChar : "";
  };

  handleWriteScorecardRecommendationOrDecisionResult = (scorecard) => {
    const { setScorecard } = this.props;
    this.setScorecardSaving(false);
    this.setShowFullScreenLoading(false);
    setScorecard(scorecard);
  };

  setScorecardModalRecommendationDecisionSaved = (saved) => {
    if (saved !== this.state.scorecardModalRecommendationDecisionSaved) {
      this.setState({
        scorecardModalRecommendationDecisionSaved: saved,
      });
    }
  };

  toggleCloseWithoutSavingScorecardRecommendationDecisionDialog = () => {
    const { confirmCloseWithoutSavingScorecardRecommendationDecisionDialog } =
      this.state;
    this.setState({
      confirmCloseWithoutSavingScorecardRecommendationDecisionDialog:
        !confirmCloseWithoutSavingScorecardRecommendationDecisionDialog,
    });
  };

  handleCloseWithoutSavingRecommendationDecision = () => {
    this.setState(
      {
        scorecardModalRecommendationDecisionSaved: true,
        confirmCloseWithoutSavingScorecardRecommendationDecisionDialog: false,
      },
      () => {
        this.closeScorecardModal();
      }
    );
  };

  getQualificationData = (
    qualificationLoading = this.state.qualificationLoading
  ) => {
    if (!qualificationLoading) {
      this.setState({
        qualificationLoading: true,
      });
    }
    return this.qualificationStore.getQualification().then((qualification) => {
      this.setState({
        qualification,
        qualificationLoading: false,
      });
      return qualification;
    });
  };

  updateQualificationTask = (categoryKey, taskKey, changedTask) => {
    if (changedTask.completed && "sub_tasks" in changedTask) {
      changedTask.sub_tasks.map((subTask) => {
        if (!subTask.completed) {
          subTask.completed = moment().format("x");
        }
      });
    }
    this.qualificationStore
      .updateTask(categoryKey, taskKey, changedTask)
      .then(this.updateQualification);
  };

  updateQualificationSubTask = (categoryKey, taskKey, updatedSubTask) => {
    this.qualificationStore
      .updateSubTask(categoryKey, taskKey, updatedSubTask)
      .then(this.updateQualification);
    let changedTask = this.state.qualification[categoryKey][taskKey];
    const all_enabled =
      !!changedTask.sub_tasks &&
      _.every(changedTask.sub_tasks, (subTask) => {
        return !!subTask.completed;
      });

    if (!all_enabled && !!changedTask.completed) {
      changedTask["completed"] = null;
      this.updateQualificationTask(categoryKey, taskKey, changedTask);
    } else if (all_enabled && !changedTask.completed) {
      changedTask["completed"] = moment().format("x");
      this.updateQualificationTask(categoryKey, taskKey, changedTask);
    }
  };

  deleteQualificationSubTask = (categoryKey, taskKey, subTaskKey) => {
    this.qualificationStore
      .deleteSubTask(categoryKey, taskKey, subTaskKey)
      .then(this.updateQualification);
  };

  deleteQualificationFile = (categoryKey, taskKey, fileKey) => {
    this.qualificationStore
      .deleteFile(categoryKey, taskKey, fileKey)
      .then(this.updateQualification);
  };

  updateQualification = (qualification) => {
    this.setState({ qualification });
  };

  refreshTimeline = (event) => {
    this.setState({
      timelineLoading: true,
    });
    this.getWorkflowTimelineData(event.shiftKey).then((timeline) => {
      this.setState({
        timeline,
        timelineLoading: false,
      });
    });
  };

  handleCloseSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    const { organization, forms, scorecard, selectedTab, mountParcel } =
      this.props;
    const {
      timeline,
      timelineLoading,
      people,
      peopleLoading,
      files,
      filesFolder,
      filesLoading,
      isAddingFiles,
      showUploadFilesModal,
      showScorecardModal,
      scorecardModalType,
      scorecardModalDefaultOpenCallId,
      confirmCloseWithoutSavingScorecardRecommendationDecisionDialog,
      scorecardModalRecommendationDecisionSaved,
      scorecardTemplates,
      scorecardSectionsForTemplate,
      scorecardLoading,
      scorecardSaving,
      scorecardHasMadeChanges,
      qualification,
      qualificationLoading,
      snackbarOpen,
      showFullScreenLoading,
      scorecardModalEditIndex,
    } = this.state;
    return (
      <div className="organization-component">
        <If condition={selectedTab === "profile"}>
          <ProfileTab
            {...this.props}
            people={people}
            peopleLoading={peopleLoading}
            mountParcel={mountParcel}
          />
        </If>
        <If condition={selectedTab === "workflow" && scorecard}>
          <WorkflowTab
            timeline={timeline || {}}
            timelineLoading={timelineLoading}
            refreshTimeline={this.refreshTimeline}
            people={people || []}
            peopleLoading={peopleLoading}
            files={files || []}
            filesFolder={filesFolder}
            filesLoading={filesLoading}
            createFilesFolder={this.createWorkflowFilesFolder}
            onFileChange={this.onWorkflowFileChange}
            addFiles={this.addWorkflowFiles}
            toggleUploadFilesModal={this.toggleUploadFilesModal}
            isAddingFiles={isAddingFiles}
            showUploadFilesModal={showUploadFilesModal}
            {...this.props}
          />
        </If>
        <If condition={selectedTab === "scorecard"}>
          <ScorecardTab
            forms={forms || []}
            organizationName={organization.name}
            showScorecardModal={showScorecardModal}
            scorecardModalType={scorecardModalType}
            defaultOpenId={scorecardModalDefaultOpenCallId}
            modalRecommendationDecisionSaved={
              scorecardModalRecommendationDecisionSaved
            }
            scorecard={scorecard || {}}
            templates={scorecardTemplates || []}
            sections={scorecardSectionsForTemplate || {}}
            loading={scorecardLoading}
            createNewCall={this.createNewScorecardCall}
            deleteCall={this.deleteScorecardCall}
            writeRecommendationOrDecision={
              this.writeScorecardRecommendationOrDecision
            }
            submitNoShow={this.handleSubmitNoShow}
            handleChangeSection={this.handleScorecardSectionChange}
            updateScorecardCall={this.handleUpdateScorecardCall}
            emptyScorecardCall={this.partialEmptyScorecardCall}
            saveFullScorecard={this.handleSaveScorecardClick}
            saving={scorecardSaving}
            revenueModelsChanged={this.revenueModelsChanged}
            setRevenueModelsChanged={this.setRevenueModelsChanged}
            handleCallDetailsChange={this.handleScorecardCallDetailsChange}
            handleCallWithChange={this.handleScorecardCallWithChange}
            handleCallTitleChange={this.handleScorecardCallTitleChange}
            hasMadeChanges={scorecardHasMadeChanges}
            setScorecardHasMadeChanges={this.setScorecardHasMadeChanges}
            confirmCloseWithoutSavingRecommendationDecisionDialog={
              confirmCloseWithoutSavingScorecardRecommendationDecisionDialog
            }
            toggleCloseWithoutSavingRecommendationDecisionDialog={
              this.toggleCloseWithoutSavingScorecardRecommendationDecisionDialog
            }
            handleCloseWithoutSavingRecommendationDecision={
              this.handleCloseWithoutSavingRecommendationDecision
            }
            setModalRecommendationDecisionSaved={
              this.setScorecardModalRecommendationDecisionSaved
            }
            openScorecardModal={this.openScorecardModal}
            closeScorecardModal={this.closeScorecardModal}
            editScorecardModal={this.editScorecardModal}
            filesFolder={filesFolder}
            scorecardModalEditIndex={scorecardModalEditIndex}
            setScorecardModalEditIndex={this.setScorecardModalEditIndex}
            {...this.props}
          />
        </If>
        <If condition={selectedTab === "qualification"}>
          <QualificationTab
            qualification={qualification}
            qualificationLoading={qualificationLoading}
            updateTask={this.updateQualificationTask}
            updateSubTask={this.updateQualificationSubTask}
            deleteSubTask={this.deleteQualificationSubTask}
            deleteFile={this.deleteQualificationFile}
            updateQualification={this.updateQualification}
            files={files || []}
            filesFolder={filesFolder}
            {...this.props}
          />
        </If>
        <Snackbar
          open={snackbarOpen}
          message="Save failed. Please copy your work and refresh your browser."
          autoHideDuration={5000}
          onRequestClose={this.handleCloseSnackbar}
        />
        <FullScreenLoading show={showFullScreenLoading} />
      </div>
    );
  }
}

export default withRouter(OrganizationTabsContent);
