import PropTypes from "prop-types";
import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import ContentSave from "material-ui/svg-icons/content/save";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiTheme from "../../lib/MuiTheme";
import SaveFiltersSetDialog from "./SaveFiltersSetDialog";
import FiltersSetSelector from "./FiltersSetSelector";

class FiltersSets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSaveDialog: false,
    };

    this.handleSaveDialogClose = this.handleSaveDialogClose.bind(this);
    this.handleSaveDialogOpen = this.handleSaveDialogOpen.bind(this);
  }

  handleSaveDialogClose() {
    this.setState({
      openSaveDialog: false,
    });
  }

  handleSaveDialogOpen() {
    this.setState({
      openSaveDialog: true,
    });
  }

  getChildContext() {
    return {
      muiTheme: getMuiTheme(MuiTheme),
    };
  }

  render() {
    return (
      <div className="filters-sets">
        <If condition={this.props.data}>
          {Object.keys(this.props.data).map((filtersKey) => {
            return (
              <FiltersSetSelector
                key={filtersKey}
                isSelected={filtersKey === this.props.selectedFiltersSet}
                name={filtersKey}
                handleDeselectFiltersSet={this.props.handleDeselectFiltersSet}
                handleSelectFiltersSet={this.props.handleSelectFiltersSet}
                handleDeleteFiltersSet={this.props.handleDeleteFiltersSet}
              />
            );
          })}
        </If>
        <If condition={!this.props.selectedFiltersSet}>
          <RaisedButton
            label="Save current selection"
            fullWidth={true}
            backgroundColor={MuiTheme.palette.secondary1color}
            labelColor="#fff"
            onClick={this.handleSaveDialogOpen}
            icon={<ContentSave />}
          />
        </If>
        <SaveFiltersSetDialog
          updateFiltersSets={this.props.updateFiltersSets}
          searchParams={this.props.searchParams}
          open={this.state.openSaveDialog}
          handleClose={this.handleSaveDialogClose}
        />
      </div>
    );
  }
}

FiltersSets.childContextTypes = {
  muiTheme: PropTypes.object.isRequired,
};

export default FiltersSets;
