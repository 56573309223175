import PropTypes from "prop-types";
import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import PersonOutline from "material-ui/svg-icons/social/person-outline";
import Tooltip from "./Tooltip";
import isMobile from "ismobilejs";

import { getDateAndTime, getUserName } from "../lib/helpers";

export default class StateButton extends React.Component {
  static propTypes = {
    showUnseen: PropTypes.bool,
    optionalState: PropTypes.string,
    value: PropTypes.string,
    state: PropTypes.string,
    icon: PropTypes.element,
    handleClick: PropTypes.func,
    activity: PropTypes.object,
  };

  state = {
    showTooltip: false,
  };

  buttonClass = (value, state, activity) => {
    const classes = ["button"];
    switch (value) {
      case "never":
        classes.push("button-left button-never");
        break;
      case "missed_opportunity":
        classes.push("button-missed_opportunity-center");
        break;
      case "not_now":
        classes.push("button-center");
        break;
      case "contact":
        if (this.props.showUnseen) {
          classes.push("button-contact-center");
        } else {
          classes.push("button-right");
        }
        break;
      case this.props.optionalState:
        classes.push("button-right");
        break;
    }
    if (state) {
      if (state === value) {
        if (!!this.props.analystFilter) {
          classes.push("analyst-state");
        } else if (
          !!this.props.user_role &&
          this.props.user_role === "investor"
        ) {
          classes.push("investor-state");
        } else {
          classes.push("state-active");
        }
      } else if (
        activity &&
        activity.partners &&
        activity.partners.length > 0
      ) {
        classes.push("state-active");
      } else if (
        activity &&
        activity.analysts &&
        activity.analysts.length > 0
      ) {
        classes.push("analyst-state");
      }
    } else if (activity && activity.analysts && activity.analysts.length > 0) {
      classes.push("analyst-state");
    } else if (
      activity &&
      activity.investors &&
      activity.investors.length > 0
    ) {
      classes.push("investor-state");
    }

    return classes.join(" ");
  };

  getLabel = (label, activity, state) => {
    if (
      !state &&
      activity &&
      activity.analysts &&
      activity.analysts.length > 1
    ) {
      return (
        <div className="multi-people-state">
          <div>
            {activity.analysts.length} <PersonOutline className="label-icon" />
          </div>
          <div>{label}</div>
        </div>
      );
    }

    return label;
  };

  getTooltipText = (activity) => {
    const text = [];
    Object.entries(activity).map(([type, users = []]) => {
      if (users.length > 0) {
        text.push(
          `<b>${type
            .split("_")
            .map((word) => word.substring(0, 1).toUpperCase())
            .join("")}</b>:&nbsp;${users
            .map(
              ({ user, timestamp }, index) =>
                `${getUserName(user)} on ${getDateAndTime(timestamp).date} ${
                  index !== users.length - 1 ? "<br/>" : ""
                }`
            )
            .join("")}`
        );
      }
    });
    return text.join("<br/>");
  };

  handleClick = (evt) => {
    this.props.handleClick(this.props.value, this.props.state, evt.shiftKey);
  };

  render() {
    const { activity, value, state, label } = this.props;
    const mlDecision =
      activity &&
      activity.analysts.some(
        (analyst) => analyst.user === "ml@inreachventures.com"
      );

    const investorDecision =
      activity && !!activity.investors && activity.investors.length > 0;

    return (
      <div
        ref={(el) => (this._state = el)}
        className="state-button"
        onMouseLeave={() => this.setState({ showTooltip: false })}
        onMouseEnter={() => this.setState({ showTooltip: true })}
      >
        <If
          condition={
            activity &&
            ((activity.trusted_partners &&
              activity.trusted_partners.length > 0) ||
              mlDecision ||
              investorDecision)
          }
        >
          <div className={`trusted-partner-state ${value}`} />
        </If>
        <RaisedButton
          value={value}
          label={this.getLabel(label, activity, state)}
          icon={this.props.icon}
          className={this.buttonClass(value, state, activity)}
          onClick={this.handleClick}
        />
        <If
          condition={
            activity &&
            ((activity.partners && activity.partners.length > 0) ||
              (activity.analysts && activity.analysts.length > 0) ||
              (activity.investors && activity.investors.length > 0) ||
              (activity.trusted_partners &&
                activity.trusted_partners.length > 0))
          }
        >
          <Tooltip
            parent={this._state}
            showTooltip={this.state.showTooltip}
            text={this.getTooltipText(activity)}
            customClasses={["state-tooltip"]}
          />
        </If>
      </div>
    );
  }
}
