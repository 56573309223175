import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Checkbox } from "material-ui";

export default class TimelineEventTask extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    section: PropTypes.string.isRequired,
    onTaskClick: PropTypes.func.isRequired,
  };

  buildItem = (item = this.props.item, section = this.props.section) => {
    const task =
      item.type === "inreachTask" ? item : item.task || item.other.task;
    const { dueString, overdue } =
      task.type === "inreachTask"
        ? { dueString: null, overdue: false }
        : this.getTaskDueDateInfo(section, task.due_at);
    const { label, subLabel } =
      task.type === "inreachTask"
        ? { label: this.getInreachTaskLabel(task), subLabel: null }
        : this.getTaskLabels(task.action, task.note, dueString);
    return {
      key: item.key,
      label: label,
      subLabel: subLabel,
      complete: task.completed,
      overdue: overdue,
      disabled: section === "upcoming",
    };
  };

  getTaskDueDateInfo = (section = this.props.section, dueDate) => {
    const dueDateInfo = {
      dueString: null,
      overdue: false,
    };
    if (section !== "past") {
      if (new Date(dueDate) < new Date()) {
        dueDateInfo.dueString = `${moment(dueDate).fromNow(true)} overdue`;
        dueDateInfo.overdue = true;
      } else {
        dueDateInfo.dueString = `Due in ${moment(dueDate).fromNow(true)}`;
      }
    }
    return dueDateInfo;
  };

  getTaskLabels = (action, note, dueString) => {
    const labelInfo = {
      label: null,
      subLabel: null,
    };
    switch (action) {
      case "linkedin_send_message":
        labelInfo.label = `Send LinkedIn message`;
        /*if (note) {
          labelInfo.subLabel = `- ${note}`;
        }*/
        break;
      case "email":
        labelInfo.label = `Send an email`;
        /*if (note) {
          labelInfo.subLabel = `- ${note}`;
        }*/
        break;
      case "action_item":
      case "in_person":
        labelInfo.label = note;
        break;
    }
    if (dueString) labelInfo.label += ` (${dueString})`;
    return labelInfo;
  };

  getInreachTaskLabel = (task) => {
    return task.dueIn
      ? `${task.text} (${moment(task.dueIn).fromNow()})`
      : task.text;
  };

  getLabelColour = (overdue, disabled) => {
    return overdue ? "#f44336" : disabled ? "#939598" : "#5dab49";
  };

  render() {
    const { key, label, subLabel, complete, disabled, overdue } =
      this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <Checkbox
          label={label}
          labelStyle={{
            color: this.getLabelColour(overdue, disabled),
            margin: 0,
            fontWeight: overdue ? "bold" : "normal",
          }}
          checked={complete}
          //disabled={disabled}
          onClick={() => {
            this.props.onTaskClick(key);
          }}
          className={"timeline-event-checkbox" + (disabled ? " disabled" : "")}
        />
      </div>
    );
  }
}
