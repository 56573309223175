import React from "react";
import PropTypes from "prop-types";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import { Checkbox } from "material-ui";
import ActionDelete from "material-ui/svg-icons/action/delete";

import xss from "xss";
import _ from "underscore";

import locationsData from "../../../content/locations";

export default class EditOrganizationLocations extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    handleLocationChange: PropTypes.func.isRequired,
    handleDeleteLocation: PropTypes.func.isRequired,
    source: PropTypes.string.isRequired,
  };

  state = {
    location: {},
    errors: {
      city: false,
      country: false,
      continent: false,
    },
    typing: false,
  };

  componentWillMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.location, this.props.location)) {
      this.initialize();
    }
  }

  initialize = () => {
    const {
      location: { city = "", country = "", continent = "", is_hq = false } = {},
      source = "edit-incomplete",
    } = this.props;
    this.setState({
      location: {
        city,
        country,
        continent,
        is_hq,
      },
      errors: {
        country: source === "edit-incomplete" && country === "",
        continent: source === "edit-incomplete" && continent === "",
      },
    });
  };

  buildClassName = () => {
    const className = ["edit-organization-location"];
    if (this.state.typing) {
      className.push("typing");
    }
    return className.join(" ");
  };

  handleChange = (field, value) => {
    const { source = "edit-incomplete" } = this.props;
    const { location, errors } = this.state;
    location[field] = typeof value === "string" ? xss(value) : value;
    if (_.has(errors, field)) {
      errors[field] = source === "edit-incomplete" && location[field] === "";
    }
    this.setState({
      location,
      errors,
      typing: true,
    });
    this.debounceChange(location);
  };

  debounceChange = _.debounce((location) => {
    const { index, handleLocationChange } = this.props;
    handleLocationChange(index, location);
    this.setState({
      typing: false,
    });
  }, 500);

  handleDelete = () => {
    if (!this.state.typing) {
      const { index, handleDeleteLocation } = this.props;
      handleDeleteLocation(index);
    }
  };

  render() {
    const {
      location: { city = "", country = "", continent = "", is_hq = false } = {},
      errors: {
        country: countryError = false,
        continent: continentError = false,
      } = {},
    } = this.state;
    return (
      <div className={this.buildClassName()}>
        <SelectField
          className="edit-organization-location-field select-continent edit-organization-input-field medium"
          floatingLabelText="CONTINENT"
          value={continent}
          onChange={(event, index, value) => {
            this.handleChange("continent", value);
          }}
          errorText={continentError ? "Required" : false}
        >
          {locationsData.continents.map((continent) => (
            <MenuItem
              className="continent-entry"
              key={continent}
              value={continent}
              primaryText={continent}
            />
          ))}
        </SelectField>
        <SelectField
          className="edit-organization-location-field select-country edit-organization-input-field medium"
          floatingLabelText="COUNTRY"
          value={country}
          onChange={(event, index, value) => {
            this.handleChange("country", value);
          }}
          disabled={continent === ""}
          errorText={countryError ? "Required" : false}
        >
          <If condition={continent !== ""}>
            {locationsData.countries[continent].map((country) => (
              <MenuItem
                className="country-entry"
                key={country}
                value={country}
                primaryText={country}
              />
            ))}
          </If>
        </SelectField>
        <TextField
          className="edit-organization-location-field select-city edit-organization-input-field medium"
          floatingLabelText="CITY"
          value={city}
          onChange={(event) => {
            this.handleChange("city", event.target.value);
          }}
          disabled={country === ""}
          name="edit-organization-location-city"
        />
        <div className="spacer" />
        <Checkbox
          className="edit-organization-location-hq"
          label="HQ"
          checked={is_hq}
          onClick={() => {
            this.handleChange("is_hq", !is_hq);
          }}
          style={{
            width: "auto",
          }}
        />
        <ActionDelete
          className="edit-organization-location-delete"
          color="#f44336"
          onClick={this.handleDelete}
        />
      </div>
    );
  }
}
