export default {
  full: {
    credits: {
      enabled: false,
    },
    title: {
      text: "",
      style: { display: "none" },
    },
    chart: {
      height: 300,
      type: "area",
      spacing: [12, 0, 12, 0],
      backgroundColor: "#ffffff",
      animation: false,
    },
    tooltip: {
      headerFormat: "",
      pointFormat: "{point.x:%e %b %Y}: <b>{point.y}</b>",
      outside: true,
      valueDecimals: 0,
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%b %y",
      },
      tickInterval: 30 * 24 * 3600 * 1000,
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    yAxis: {
      visible: true,
      title: {
        text: "",
        style: { display: "none" },
      },
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
  },
  half: {
    credits: {
      enabled: false,
    },
    title: {
      text: "",
      style: { display: "none" },
    },
    chart: {
      height: 200,
      type: "area",
      spacing: [12, 0, 12, 0],
      backgroundColor: "#ffffff",
      animation: false,
    },
    tooltip: {
      headerFormat: "",
      pointFormat: "{point.x:%e %b %Y}: <b>{point.y}</b>",
      outside: true,
      valueDecimals: 0,
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%b %y",
      },
      tickInterval: 30 * 24 * 3600 * 1000,
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    yAxis: {
      visible: true,
      title: {
        text: "",
        style: { display: "none" },
      },
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
  },
  spark: {
    credits: {
      enabled: false,
    },
    title: {
      text: "",
      style: { display: "none" },
    },
    chart: {
      height: 120,
      type: "area",
      spacing: [6, 0, 6, 0],
      backgroundColor: "#ffffff",
      animation: false,
    },
    tooltip: {
      headerFormat: "",
      pointFormat: "{point.x:%e %b %Y}: <b>{point.y}</b>",
      outside: true,
      valueDecimals: 0,
    },
    xAxis: {
      type: "datetime",
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
        style: { display: "none" },
      },
      showFirstLabel: true,
      showLastLabel: true,
      gridLineColor: "#e5e5e5",
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
  },
};
