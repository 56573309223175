import React from "react";
import PersonStore from "../../storage/PersonStore";
import TwitterUser from "./TwitterUser";
import GithubUser from "./GithubUser";

import Loading from "../loading/Loading";
import OxyleadsMember from "./OxyleadsMember";
import PersonHeader from "./PersonHeader";
import PersonExperiences from "../organization/PersonExperiences";
import Tags from "../organization/Tags";
import Companies from "./Companies";
import Analytics from "./Analytics";
import ClearbitPerson from "./ClearbitPerson";

export default class Person extends React.Component {
  state = {};

  personStore = new PersonStore(this.props.params.id);

  componentWillMount() {
    this.loadPerson();
  }

  handlePersonChange = (person) => {
    this.setState({ person: person });
  };

  loadPerson = () => {
    this.personStore.getModel().then(this.handlePersonChange);
  };

  render() {
    const person = this.state.person;
    if (!person) {
      return <Loading />;
    }
    const sources = person.source_refs || person.source_references || {};

    return (
      <div className="person-view">
        <div className="container person-container full-width">
          <PersonHeader person={person} onPersonChange={this.loadPerson} />
          <div>
            <If condition={person.bio}>
              <div className="person-section">
                <div className="bio">
                  <label> Bio </label>
                  <p> {person.bio} </p>
                </div>
              </div>
            </If>
            <If condition={person.experience}>
              <div className="person-section">
                <div className="experience">
                  <label> Experience </label>
                  <PersonExperiences experiences={person.experience} />
                </div>
              </div>
            </If>
            <If condition={person.skills}>
              <div className="person-section">
                <div className="experience">
                  <label> Skills </label>
                  <Tags data={person.skills} />
                </div>
              </div>
            </If>
            <If condition={person.organizations}>
              <div className="person-section">
                <div className="companies">
                  <label> Companies </label>
                  <Companies organizationIds={person.organizations} />
                </div>
              </div>
            </If>
            <div className="person-section">
              <div className="signals-section">
                <label> Signals </label>
                <Analytics person={person} />
              </div>
            </div>
            <If condition={sources.TWITTER}>
              {[].concat(sources.TWITTER).map((id) => {
                return (
                  <div key={id} className="person-section">
                    <label> Twitter </label>
                    <div className="twitter source">
                      <TwitterUser id={id} />
                    </div>
                  </div>
                );
              })}
            </If>
            <If condition={sources.GITHUB}>
              {[].concat(sources.GITHUB).map((id) => {
                return (
                  <div key={id} className="person-section">
                    <label> Github </label>
                    <div className="github source">
                      <GithubUser id={id} />
                    </div>
                  </div>
                );
              })}
            </If>
            <If condition={sources.CLEARBIT}>
              {[].concat(sources.CLEARBIT).map((id) => {
                return (
                  <div key={id} className="person-section">
                    <label> Clearbit </label>
                    <div className="clearbit source">
                      <ClearbitPerson id={id} />
                    </div>
                  </div>
                );
              })}
            </If>
            <If condition={sources.OXYLEADS}>
              {[].concat(sources.OXYLEADS).map((id) => {
                return (
                  <div key={id} className="person-section">
                    <label> Oxyleads </label>
                    <div className="oxyleads source">
                      <OxyleadsMember id={id} />
                    </div>
                  </div>
                );
              })}
            </If>
          </div>
        </div>
      </div>
    );
  }
}
