import React from "react";
import { Link, withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import Loading from "../../../loading/Loading";

import JobOpeningStore from "../../../../storage/JobOpeningStore";

class JobOpening extends React.Component {
  static propTypes = {
    jobOpening: PropTypes.object.isRequired,
  };

  render() {
    const { jobOpening: { title, posted } = {} } = this.props;
    return <div className="data-content-list-item text">{title}</div>;
  }
}

class JobOpenings extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
  };

  state = {
    highlightedJobOpenings: [],
    otherJobOpenings: [],
    showOtherJobOpenings: false,
    jobOpeningsLoading: true,
  };

  componentDidMount() {
    const { organization: { job_openings = [] } = {} } = this.props;
    new JobOpeningStore(job_openings)
      .jobOpenings()
      .then((jobOpenings = []) => this.processJobOpenings(jobOpenings))
      .catch((err) => this.setState({ jobOpeningsLoading: false }));
  }

  processJobOpenings = (jobOpenings) => {
    const { highlightedJobOpenings, otherJobOpenings } = jobOpenings.reduce(
      ({ highlightedJobOpenings, otherJobOpenings }, jobOpening, index) => {
        if (index < 5) {
          highlightedJobOpenings.push(jobOpening);
        } else {
          otherJobOpenings.push(jobOpening);
        }
        return {
          highlightedJobOpenings,
          otherJobOpenings,
        };
      },
      { highlightedJobOpenings: [], otherJobOpenings: [] }
    );
    this.setState({
      highlightedJobOpenings,
      otherJobOpenings,
      jobOpeningsLoading: false,
    });
  };

  handleToggleOtherJobOpenings = () => {
    this.setState({
      showOtherJobOpenings: !this.state.showOtherJobOpenings,
    });
  };

  render() {
    const {
      highlightedJobOpenings = [],
      otherJobOpenings = [],
      showOtherJobOpenings,
      jobOpeningsLoading,
    } = this.state;
    return (
      <div className="data-wrapper job-openings">
        <div className="data-header">Job Openings</div>
        <Choose>
          <When condition={jobOpeningsLoading}>
            <Loading small={true} align="left" />
          </When>
          <Otherwise>
            <Choose>
              <When
                condition={
                  highlightedJobOpenings.length === 0 &&
                  otherJobOpenings.length === 0
                }
              >
                <div className="data-content text empty">No Data</div>
              </When>
              <Otherwise>
                <If condition={highlightedJobOpenings.length > 0}>
                  <div className="data-content list">
                    {highlightedJobOpenings.map((jobOpening) => (
                      <JobOpening
                        jobOpening={jobOpening}
                        key={jobOpening.title}
                      />
                    ))}
                  </div>
                </If>
                <If condition={otherJobOpenings.length > 0}>
                  <If condition={showOtherJobOpenings}>
                    <div className="data-content list">
                      {otherJobOpenings.map((jobOpening) => (
                        <JobOpening
                          jobOpening={jobOpening}
                          key={jobOpening.title}
                        />
                      ))}
                    </div>
                  </If>
                  <div
                    className={`data-content text ${
                      showOtherJobOpenings || highlightedJobOpenings.length > 0
                        ? "component"
                        : ""
                    }`}
                  >
                    <Link
                      style={{ cursor: "pointer" }}
                      onClick={this.handleToggleOtherJobOpenings}
                    >
                      {showOtherJobOpenings ? "Hide more" : "Show more"}
                    </Link>
                  </div>
                </If>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(JobOpenings);
