export default [
  { value: "ANGELLIST", label: "AngelList" },
  { value: "CRUNCHBASE", label: "CrunchBase" },
  { value: "PRODUCTHUNT", label: "ProductHunt" },
  { value: "DEALROOM", label: "DealRoom" },
  { value: "CROWDSURFER", label: "CrowdSurfer" },
  { value: "DUTCHSTARTUPS", label: "DutchStartups" },
  { value: "OPENGRAPH", label: "OpenGraph" },
  { value: "IMPORT", label: "Import" },
  { value: "FUNDING", label: "Funding Form" },
  { value: "V2FUNDING", label: "Funding Form V2" },
  { value: "THEHUB", label: "The Hub" },
  { value: "NORDICTECHLIST", label: "Nordic Tech List" },
  { value: "STARTUPEXPLORE", label: "Startup Explore" },
  { value: "TECH_LONDON", label: "Tech.London" },
  { value: "ESTONIANMAFIA", label: "Estonian Mafia" },
  { value: "STARTUPSBE", label: "Startups.be" },
  { value: "STARTUPS_LIST", label: "Startups-list.com" },
  { value: "BCNSTARTUPMAP", label: "Barcelona Startups" },
  { value: "STARTUPNORWAY", label: "Startupnorway.com" },
  { value: "STARTUP_POLAND", label: "Startuppoland.org" },
  { value: "BLOCKCHAINSTARTUPS", label: "Blockchain Startups" },
  { value: "F6S", label: "F6S" },
  { value: "SMITHANDCROWN", label: "Smith And Crown (ICOs)" },
  { value: "TOKENDATA", label: "TokenData (ICOs)" },
  { value: "OXYLEADS", label: "Oxyleads" },
  { value: "YCOMBINATOR", label: "YCombinator" },
  { value: "PREDICTLEADS", label: "PredictLeads" },
  { value: "NUMA_HUB", label: "Numa Hub" },
  { value: "JAIMELESSTARTUPS", label: "Jaime Les Startups" },
  { value: "BETA_LIST", label: "Beta List" },
  { value: "KIMA_VENTURES", label: "Kima Ventures" },
  { value: "ROCKSTART", label: "Rockstart" },
  { value: "HAMBURG_STARTUPS", label: "Hamburg Startup Monitor" },
  { value: "WAYRA_UK", label: "Wayra UK" },
  { value: "OPENFUTURE", label: "Openfuture" },
  { value: "STARTUP_OLE", label: "Startup Ole" },
  { value: "LES_PEPITES_TECH", label: "Les Pepites Tech" },
  { value: "EU_STARTUPS", label: "EU Startups" },
  { value: "WELCOME_TO_THE_JUNGLE", label: "Welcome to the Jungle" },
  { value: "GRUENDERSZENE", label: "Gruenderszene" },
  { value: "AZERTYJOBS", label: "Azerty Jobs" },
  { value: "DIGITAL_GRENOBLE", label: "Digital Grenoble" },
  { value: "DUBLIN_TECH_SUMMIT", label: "Dublin Tech Summit" },
  { value: "MUNICH_STARTUP", label: "Munich Startup" },
  { value: "SEEDCAMP", label: "Seedcamp" },
  { value: "TECH_PITCH_4PT5", label: "Tech Pitch 4pt5" },
  { value: "UNICORN_HUNT", label: "Unicorn Hunt" },
  { value: "FINSMES", label: "FinSMEs" },
  { value: "INVESTIERE", label: "Investiere" },
  { value: "STARTUP_HUBS", label: "Startup Hubs" },
  { value: "STARTUP_BRETT", label: "Startup Brett" },
  { value: "ROMANIANSTARTUPS", label: "Romanian Startups" },
  { value: "INC_5000_EU", label: "Inc. 5000 Europe" },
  { value: "SUP46", label: "sup46 (Start-up People of Sweden)" },
  { value: "CZECHSTARTUPS", label: "Czech Startups" },
  { value: "STARTUPJOBS_CZ", label: "StartupJobs (CZ)" },
  { value: "STARTUP_ONLY", label: "Startup Only" },
  { value: "MAM_STARTUP", label: "Mam Startup" },
  { value: "FINTASTICO", label: "Fintastico" },
  { value: "STARTUPS_HU", label: "Startups HU" },
  { value: "LABS_OF_LATVIA", label: "Labs of Latvia" },
  { value: "VCEE_STARTUPS", label: "Vcee Startups" },
  { value: "THE_FACTORY", label: "The Factory" },
  { value: "STARTUPLISBOA", label: "Startup Lisboa" },
  { value: "STARTUPBLINK", label: "Startup Blink" },
  { value: "HIRED", label: "Hired" },
  { value: "STARTUPRANKING", label: "Startup Ranking" },
  { value: "BERLIN_STARTUP_JOBS", label: "Berlin Startup Jobs" },
  { value: "STARTUS", label: "StartUs" },
  { value: "STARTUP_NAVIGATOR", label: "Startup Navigator" },
  { value: "CAPTERRA", label: "Capterra" },
  { value: "EIPP", label: "European Investment Project Portal (EIPP)" },
  { value: "FT_1000", label: "FT 1000" },
  { value: "SEED_DB", label: "Seed DB" },
  { value: "FUNDERBEAM", label: "Funderbeam" },
  { value: "STARTUP_INSPIRE", label: "Startup Inspire" },
  {
    value: "NATIONAL_BUSINESS_AWARDS_UK",
    label: "National Business Awards UK",
  },
  { value: "LAUNCHED", label: "Launched" },
  { value: "NO_FLUFF_JOBS", label: "No Fluff Jobs" },
  { value: "DEALROOM_TRANSACTIONS", label: "Dealroom Transactions" },
  { value: "PREDICTLEADS_POST", label: "PredictLeads HackerNews Posts" },
  { value: "SENSORTOWER", label: "SensorTower" },
  { value: "STARTUPMATCHER", label: "StartupMatcher" },
  { value: "ALL_STARTUPS", label: "All Startups" },
  { value: "NEULOGY", label: "Neulogy" },
  { value: "STARTUPBERLIN", label: "StartupBerlin" },
  { value: "THE_HUB", label: "The Hub" },
  { value: "STARTUP_XPLORE", label: "Startup Xplore" },
  { value: "Y_COMBINATOR", label: "YCombinator" },
  { value: "BCN_STARTUP_MAP", label: "BCN Startup Map" },
  { value: "TOKEN_DATA", label: "Token Data" },
  { value: "SMITH_AND_CROWN", label: "Smith and Crown" },
  { value: "STARTUPS_BE", label: "Startups BE" },
  { value: "DER_BRUTKASTEN", label: "Der Brutkasten" },
  { value: "ERASMUS_CENTRE", label: "Erasmus Centre" },
  { value: "DEEPTECHFORGOOD", label: "Deep Tech for Good" },
  { value: "LAFRENCHTECH_VISAS", label: "La French Tech Visas" },
  { value: "LUPA", label: "Lupa CZ" },
  { value: "SAAS1000", label: "Saas 1000" },
  { value: "LISBON_CHALLENGE", label: "Lisbon Challenge" },
  { value: "GETINTHERING", label: "Get in the Ring" },
  { value: "JOBS_STATIONF", label: "Jobs StationF" },
  { value: "IMPERIALENTERPRISELAB", label: "Imperial Enterprise Lab" },
  { value: "B10", label: "B10" },
  { value: "TOP100STARTUP", label: "Top 100 Startup" },
  { value: "SPEEDINVEST", label: "Speedinvest" },
  { value: "STARTUPS_CO_UK", label: "Startups.co.uk" },
  { value: "ELEVEN", label: "Eleven" },
  { value: "LAUNCHUB", label: "Launchub" },
  { value: "CREDOVENTURES", label: "Credo" },
  { value: "SEEDCAPITAL_DK", label: "Seedcapital DK" },
  { value: "ACCEL", label: "Accel" },
  { value: "FOMCAP", label: "Force Over Mass Capital" },
  { value: "BALDERTON", label: "Balderton" },
  { value: "ALBION", label: "Albion VC" },
  { value: "ATOMICO", label: "Atomico" },
  { value: "DNCAPITAL", label: "DN Capital" },
  { value: "DOWNING", label: "Downing" },
  { value: "FIRSTMINUTE_CAPITAL", label: "Firstminute Capital" },
  { value: "FORWARDPARTNERS", label: "Forward Partners" },
  { value: "HIGHLAND_EUROPE", label: "Highland Europe" },
  { value: "EPISODE1", label: "Episode 1" },
  { value: "BARCELONATECHCITY", label: "Barcelona Tech City" },
  { value: "INDEX_VENTURES", label: "Index Ventures" },
  { value: "INITIAL_CAPITAL", label: "Initial Capital" },
  { value: "IQ_CAPITAL", label: "IQ Capital" },
  { value: "ENTREPRENEUR_FIRST", label: "Entrepreneur First" },
  { value: "ISOMER_CAPITAL", label: "Isomer Capital" },
  { value: "MMC_VENTURES", label: "MMC Ventures" },
  { value: "OXFORD_CAPITAL", label: "Oxford Capital" },
  { value: "PARKWALK_ADVISORS", label: "Parkwalk Advisors" },
  { value: "PASSION", label: "Passion" },
  { value: "PLAYFAIR", label: "Playfair Capital" },
  { value: "NORTHZONE", label: "Northzone" },
  { value: "ANTHEMIS", label: "Anthemis" },
  { value: "WORKINSTARTUPS", label: "Work in Startups" },
  { value: "INDUSTRYINVESTMENT", label: "Industry Investment (Tesi)" },
  { value: "OMNESCAPITAL", label: "Omnes Capital" },
  { value: "THE_FSE_GROUP", label: "The FSE Group" },
  { value: "SEVENTURE", label: "Seventure" },
  { value: "XANGE", label: "Xange" },
  { value: "PARTECH", label: "Partech Partners" },
  { value: "360CAPITAL", label: "360 Capital" },
  { value: "NEWFUND", label: "Newfund" },
  { value: "ATLANTICLABS", label: "Atlantic Labs" },
  { value: "CAPNAMICVENTURES", label: "Capnamic Ventures" },
  { value: "COPARION", label: "Coparion" },
  { value: "EVENTURES", label: "Eventures" },
  { value: "HIGH_TECH_GRUENDERFONDS", label: "High Tech Gruenderfonds" },
  { value: "IBB_BET", label: "IBB Beteiligungsgesellschaft" },
  { value: "PROJECT_A", label: "Project A" },
  { value: "S_UBG_GROUP", label: "S-UBG Group" },
  { value: "TECH_IRELAND", label: "TechIreland" },
  { value: "CUBE_FIVE", label: "Cube Five" },
  { value: "CYLONLAB", label: "Cylon Lab" },
  { value: "FASTTRACKMALMO", label: "Fast Track Malmo" },
  { value: "STARTBASE", label: "Startbase" },
  { value: "CREATHOR", label: "Creathor" },
  { value: "EARLYBIRD", label: "Earlybird" },
  { value: "SAP_IO", label: "SAP.io" },
  { value: "CONORFUND", label: "Conor Fund" },
  { value: "HIVENTURES", label: "Hiventures" },
  { value: "GITHUB_TRENDING", label: "Github Trending" },
  { value: "VENTUREFRIENDS", label: "VentureFriends" },
  { value: "BVP", label: "Business Venture Partners" },
  { value: "DRAPERESPRIT", label: "Draper Esprit" },
  { value: "SOSV", label: "SOSV" },
  { value: "DELTAPARTNERS", label: "Delta Partners" },
  { value: "FRONTLINE_VENTURES", label: "Frontline Ventures" },
  { value: "LVENTURE", label: "LVenture" },
  { value: "OLTREVENTURE", label: "Oltre Venture" },
  { value: "P101", label: "P101" },
  { value: "STING", label: "Sting" },
  { value: "CAPHUB_IO", label: "CapHub" },
  { value: "MAZE_X", label: "Maze X" },
  { value: "APOLLO_IO", label: "Apollo" },
  { value: "ARMILAR", label: "Armilar" },
  { value: "BESTHORIZON", label: "Best Horizon" },
  { value: "PORTUGALVENTURES", label: "Portugal Ventures" },
  { value: "CHALMERS_VENTURES", label: "Chalmers Ventures" },
  { value: "ALMI_INVEST", label: "Almi Invest" },
  { value: "INVEREADY", label: "Inveready" },
  { value: "SEAYAVENTURES", label: "Seaya Ventures" },
  { value: "SAMAIPATA", label: "Samaipata" },
  { value: "NAUTACAPITAL", label: "Nauta Capital" },
  { value: "LANZAME", label: "Lanzame" },
  { value: "KFUND", label: "Kfund" },
  { value: "JME_VENTURES", label: "JME Ventures" },
  { value: "CAIXA_CAPITAL_RISC", label: "Caixa Capital Risc" },
  { value: "TECHSTARS_DEMODAY_AIRTABLE", label: "Techstars Demoday Airtable" },
  { value: "TECHSTARS_AIRTABLE", label: "Techstars Airtable" },
  { value: "INDUSTRIFONDEN", label: "Industrifonden" },
  { value: "DJAKNE", label: "Djakne" },
  { value: "SCHIBSTED_GROWTH", label: "Schibsted Growth" },
  { value: "MOUNTAIN_PARTNERS", label: "Mountain Partners" },
  { value: "CREANDUM", label: "Creandum" },
  { value: "EVENTMANAGERBLOG", label: "Eventmanager Blog" },
  { value: "ANTLER", label: "Antler" },
  { value: "STARTUPBUFFER", label: "Startup Buffer" },
  { value: "STARTUPBUTTON", label: "Startup Button" },
  { value: "STARTUPROULETTE", label: "Startup Roulette" },
  { value: "LAUNCHINGNEXT", label: "Launching Next" },
  { value: "STARTUPLIFT", label: "Startup Lift" },
  { value: "STARTUP88", label: "Startup88" },
  { value: "STARTUPBENCHMARKS", label: "Startup Benchmarks" },
  { value: "STARTUPBASE", label: "Startupbase" },
  { value: "STARTUPCOLLECTIONS", label: "Startup Collections" },
  { value: "LANDINGFOLIO", label: "Landingfolio" },
  { value: "THE_STARTUP_PITCH", label: "The Startup Pitch" },
  { value: "NGP_CAPITAL", label: "NGP Capital" },
  { value: "CHERRY_VC", label: "Cherry VC" },
  { value: "HV_CAPITAL", label: "HV Capital" },
  { value: "COLLISION", label: "Collision Conf" },
  { value: "MONACOTECH", label: "Monacotech" },
  { value: "TECHNOPARK_LIECHTENSTEIN", label: "Technopark Liechtenstein" },
  { value: "DAPHNI", label: "Daphni" },
  { value: "PILABS", label: "Pilabs" },
  { value: "STARTERSTORY", label: "Starter Story" },
  { value: "JOBFLUENT", label: "Job Fluent" },
  { value: "POSTMAKE", label: "Postmake" },
  { value: "ACME", label: "Acme" },
  { value: "TOMORROW_TODAY", label: "Tommorrow Today" },
  { value: "STARTUP_LAB", label: "Startup Lab" },
  { value: "START_GREEN", label: "Start Green" },
  { value: "WEB_SUMMIT", label: "Web Summit" },
  { value: "GET_LATKA", label: "Get Latka" },
  { value: "ACCELERACE", label: "Accelerace" },
  { value: "E27", label: "E27" },
  { value: "AGORANOV", label: "Agoranov" },
  { value: "THE_VENTURE_CITY", label: "The Venture City" },
  { value: "DIGITAL_HEALTH_LONDON", label: "Digital Health London" },
  { value: "REAKTORX", label: "Reaktor X" },
  { value: "PLUG_AND_PLAY", label: "Plug and Play" },
  { value: "MERIAN_VENTURES", label: "Merian Ventures" },
  { value: "ADA_VENTURES", label: "Ada Ventures" },
  { value: "FEMALE_FOUNDERS_GROW_F", label: "Female Founders Grow F" },
  { value: "ABSL_STARTUP_CHALLENGE", label: "ABSL Startup Challenge" },
  { value: "STARTUP_TARTU", label: "Startup Tartu" },
  { value: "STARTUP_ESTONIA", label: "Startup Estonia" },
  { value: "EXPERIOR_VENTURE_FUND", label: "Experior Venture Fund" },
  { value: "OKTOGON_VC", label: "Oktogon Ventures" },
  { value: "OTB_VENTURES", label: "OTB Ventures" },
  { value: "RBVC", label: "Robert Bosch VC" },
  { value: "ELEVATOR_VENTURES", label: "Elevator Ventures" },
  { value: "AXA_VP", label: "AXA Venture Partners" },
  { value: "UNICORNS_LT", label: "Lithuanian Unicorns" },
  { value: "APX", label: "APX" },
  { value: "OPEN_OCEAN", label: "Open Ocean" },
  { value: "STARTUP_CHILE", label: "Startup Chile" },
  { value: "STARTUP_WISEGUYS", label: "Startup Wiseguys" },
  { value: "SIGNALS_VC", label: "Signals Venture Capital" },
  { value: "WHITE_STAR_CAPITAL", label: "White Star Capital" },
  { value: "LHV", label: "Lighthouse Ventures" },
  { value: "ANGEL_ACADEME", label: "Angel Academe" },
  { value: "NEVEQ", label: "NEVEQ" },
  { value: "BRIGHTCAP_VC", label: "Brightcap Ventures" },
  { value: "AM_VENTURES", label: "AM Ventures" },
  { value: "3TS_CAPITAL", label: "3TS Capital" },
  { value: "TERA_VC", label: "Tera Ventures" },
  { value: "PRESTO_VENTURES", label: "Presto Ventures" },
  { value: "J_AND_T_VENTURES", label: "J&T Ventures" },
  { value: "BORN2GROW", label: "BORN2GROW" },
  { value: "HOXTON_VENTURES", label: "Hoxton Ventures" },
  { value: "KAYA", label: "Kaya" },
  { value: "BREEGA", label: "Breega" },
  { value: "F10", label: "F10" },
  { value: "MANTA_RAY", label: "Manta Ray" },
  { value: "BLACK_PEARLS", label: "Black Pearls" },
  { value: "ALIGO", label: "Aligo" },
  { value: "ALVEN", label: "Alven" },
  { value: "BLACK_FOUNDERS_FUND", label: "Black Founders Fund" },
  { value: "CAP_HORN", label: "Cap Horn" },
  { value: "DAY_ONE_CAPITAL", label: "Day One Capital" },
  { value: "EDP_VENTURES", label: "EDP Ventures" },
  { value: "FANTASTIC_BEASTS", label: "Fantastic Beasts" },
  { value: "FINCH_CAPITAL", label: "Finch Capital" },
  { value: "FLY_VENTURES", label: "Fly Ventures" },
  { value: "I2BF", label: "I2bf" },
  { value: "INVENTURE", label: "Inventure" },
  { value: "LEA_PARTNERS", label: "Lea Partners" },
  { value: "LEMONADE_STAND", label: "Lemonade Stand" },
  { value: "MAGMA_VC", label: "Magma VC" },
  { value: "PAUA_VENTURES", label: "Paua Ventures" },
  { value: "RHODIUM", label: "Rhodium" },
  { value: "SPEEDUPGROUP", label: "Speedup Group" },
  { value: "UVC", label: "UVC" },
  { value: "VENTECH", label: "Ventech" },
  { value: "ZOOM_MARKETPLACE", label: "Zoom Marketplace" },
  { value: "ADARA_VENTURES", label: "Adara Ventures" },
  { value: "ENDEIT", label: "Endeit" },
  { value: "HENQ", label: "Henq" },
  { value: "MAINPORT_INNOVATION_FUND", label: "Mainport Innovation Fund" },
  { value: "SHIFT_INVEST", label: "Shift Invest" },
  { value: "ALMAZ_CAPITAL", label: "Almaz Capital" },
  { value: "SPINTOP_VENTURES", label: "Spintop Ventures" },
  { value: "BTOV", label: "BTOV" },
  { value: "EMERALD_VENTURES", label: "Emerald Ventures" },
  { value: "LAKESTAR", label: "Lakestar" },
  { value: "POLYTECH_VENTURES", label: "Polytech Ventures" },
  { value: "REDALPINE", label: "Redalpine" },
  { value: "212_VC", label: "212 VC" },
  { value: "7PERCENT", label: "7 Percent" },
  { value: "83NORTH", label: "83 North" },
  { value: "AMADEUS_CAPITAL", label: "Amadeus Capital" },
  { value: "ATLANTIC_BRIDGE", label: "Atlantic Bridge" },
  { value: "CONCENTRIC", label: "Concentric" },
  { value: "ILLUMINATE_FINANCIAL", label: "Illuminate Financial" },
  { value: "NOTION_CAPITAL", label: "Notion Capital" },
  { value: "CREATIVE_DESTRUCTION_LAB", label: "Creative Destruction Lab" },
  { value: "MOONFIRE", label: "Moonfire" },
  { value: "LUNAR_VENTURES", label: "Lunar Ventures" },
  { value: "PROPEL", label: "Propel" },
  { value: "OCTOPUS_VENTURES", label: "Octopus Ventures" },
  { value: "1KUBATOR", label: "1Kubator" },
  { value: "BAIN_CAPITAL_VENTURES", label: "Bain Capital Ventures" },
  { value: "BREYER_CAPITAL", label: "Breyer Capital" },
  { value: "BRIGHTEYES", label: "Brighteye VC" },
  { value: "CHARLOTTE_STREET_CAPITAL", label: "Charlotte Street Ventures" },
  { value: "DGC", label: "DGC" },
  { value: "ENHJORNING", label: "Enhjorning.bot" },
  { value: "ESTONIA_E_RESIDENCY", label: "Estonia E-Residency" },
  { value: "GENERAL_CATALYST", label: "General Catalyst" },
  { value: "GLOBAL_FOUNDERS_CAPITAL", label: "Global Founders Capital" },
  { value: "HARDWARECLUB", label: "Hardware Club" },
  { value: "ID4", label: "Id4" },
  { value: "MARATHON_VC", label: "Marathon VC" },
  { value: "PALADIN", label: "Paladin Capital Group" },
  { value: "PENTECH", label: "Pentech VC" },
  { value: "PJ_TECH_CATALYST", label: "PJ Tech Catalyst" },
  { value: "RUNACAP", label: "Runa Capital" },
  { value: "SLUG", label: "Friendly name" },
  { value: "STARTUP_GRIND_JOBS", label: "Startup Grind Jobs" },
  { value: "STARTUP_PIRATE_JOBS", label: "Startup Pirate Jobs" },
  { value: "UNUSUAL", label: "Unusual VC" },
  { value: "VISTA_EQUITY_PARTNERS", label: "Vista Equity Partners" },
  { value: "SEEDANDSPEED", label: "Seed + Speed" },
  { value: "LA_FAMIGLIA", label: "LaFamiglia" },
  { value: "VITO_VENTURES", label: "Vito Ventures" },
  { value: "COINIX", label: "Coin IX" },
  { value: "MAVEN_11", label: "Maven 11 Capital" },
  { value: "BLOCKWALL", label: "Blockwall Capital" },
  { value: "GREENFIELD_ONE", label: "Greenfield One" },
  { value: "INFLECTION", label: "Inflection" },
  { value: "LEADBLOCK_PARTNERS", label: "LeadBlock Partners" },
  { value: "HYPERLEDGER_FOUNDATION", label: "Hyperledger" },
  { value: "MULTICHAIN", label: "MultiChain" },
  {
    value: "ENTERPRISE_ETHEREUM_ALLIANCE",
    label: "Enterprise Ethereum Alliance",
  },
  { value: "DRAPERGORENHOLM", label: "Draper Goren Holm" },
  { value: "PINKSALTVENTURES", label: "Pink Salt Ventures" },
  { value: "TIOGA_CAPITAL", label: "Tioga Capital" },
  { value: "OPENWEBCOLLECTIVE", label: "Open Web Collective" },
  { value: "BLOCKSTART", label: "Blockstart" },
  { value: "CRYPTOSLATE", label: "Cryptoslate" },
  { value: "BLOCKCHAIN_CAPITAL", label: "Blockchain Capital" },
  { value: "BLOCCELERATE", label: "Bloccelerate" },
  { value: "DEFIANCE_CAPITAL", label: "Defiance Capital" },
  { value: "PANTERA_CAPITAL", label: "Pantera Capital" },
  { value: "MULTICOIN_CAPITAL", label: "Multicoin Capital" },
  { value: "HASHED", label: "Hashed" },
  { value: "COINTELLIGENCE", label: "Cointelligence" },
  { value: "PITHIA", label: "Pithia" },
  { value: "LAUNCHPOOLLABS", label: "Launchpoollabs" },
  { value: "THREE_ARROWS_CAPITAL", label: "Three Arrows Capital" },
  { value: "UNION_SQUARE_VENTURES", label: "Union Square Ventures" },
  { value: "PARADIGM", label: "Paradigm" },
  { value: "A16Z", label: "A16z" },
  { value: "FELIX", label: "Felix Capital" },
  { value: "MAIRDUMONT_VENTURES", label: "Mairdumont Ventures" },
  { value: "KARMA_VC", label: "Karma Ventures" },
  { value: "BVALUE", label: "Bvalue VC" },
  { value: "SAMPO_ACCELERATOR", label: "Sampo Accelerator" },
  { value: "HAMBRO_PERKS", label: "Hambro Perks" },
  { value: "MANGROVE", label: "Mangrove Capital Partners" },
  { value: "FINLAB", label: "FinLab" },
  { value: "KORELYA_CAPITAL", label: "Korelya Capital" },
  { value: "OUTLIER_VENTURES", label: "Outlier Ventures" },
  { value: "THEBLOX", label: "The Blox" },
  { value: "PYRATZLABS", label: "Pyratz Labs" },
  { value: "ANIMOCA_BRANDS", label: "Animoca Brands" },
  { value: "NOVA", label: "Nova" },
  { value: "REVO", label: "Revo" },
  { value: "SOUTH_CENTRAL_VENTURES", label: "South Central Ventures" },
  { value: "SIMPACT", label: "Simpact" },
  { value: "TARGET_GLOBAL", label: "Target Global" },
  { value: "LVP", label: "London Venture Partners" },
  { value: "BLACKPEAK_CAPITAL", label: "BlackPeak Capital" },
  { value: "ROCKAWAY_CAPITAL", label: "Rockaway Capital" },
  { value: "FLASHPOINT_VC", label: "Flashpoint VC" },
  { value: "PROPTECH1", label: "PropTech1" },
  { value: "INNOVESTOR", label: "Innovestor" },
  { value: "EURAZEO", label: "Eurazeo" },
  { value: "INNOVATION_DOCK", label: "Innovation Dock" },
  { value: "GRANATUS_VENTURES", label: "Granatus Ventures" },
  { value: "MOMENTUM", label: "Momentum" },
  { value: "SNO", label: "Snö" },
  { value: "6AM", label: "6am" },
  { value: "TLAB", label: "T:lab" },
  { value: "IMPACT_STARTUP", label: "Impact StartUp" },
  { value: "VISINNNOVASJON", label: "Vis Innovasjon" },
  { value: "PORTFOLION", label: "PortfoLion" },
  { value: "SIGNALFIRE", label: "SignalFire" },
  { value: "DOMAIN_SOURCER", label: "Domain Sourcer" },
  { value: "V3FUNDING", label: "V3 Funding" },
  { value: "CORESIGNAL_DB", label: "CoreSignal (DB)" },
  { value: "OUTREACH", label: "OutReach" },
  { value: "HUBSPOT", label: "Hubspot" },
  { value: "LOYAL_VC", label: "Loyal VC" },
  { value: "ELAIA", label: "Elaia" },
  { value: "ACCENTURE_VENTURES", label: "Accenture Ventures" },
  { value: "PICUS_CAPITAL", label: "Picus Capital" },
  { value: "HEARTCORE", label: "Heartcore Capital" },
  { value: "ENTREE", label: "Entrée Capital" },
  { value: "AMPLIFY_LA", label: "Amplify LA" },
  { value: "RTP_GLOBAL", label: "RTP Global" },
  { value: "PROMUS_VENTURES", label: "Promus Ventures" },
  { value: "ISAI_VENTURES", label: "ISAI Ventures" },
  { value: "FINSJ", label: "Financière Saint James" },
  { value: "10X_GROUP", label: "10x Group" },
  { value: "FIRESTARTR", label: "Firestartr" },
  { value: "HAATCH", label: "Haatch" },
  { value: "SPIDER_CAPITAL", label: "Spider Capital" },
  { value: "HEADLINE", label: "Headline" },
  { value: "NEXT47", label: "Next47" },
  { value: "CONNECT_VENTURES", label: "Connect Ventures" },
  { value: "TAV", label: "TA Ventures" },
  { value: "SERENA", label: "Serena" },
  { value: "SCRIPT_CAPITAL", label: "Script Capital" },
  { value: "CIC", label: "Cambridge Innovation Capital" },
  { value: "SAMOS", label: "Samos Investments" },
  { value: "1KX", label: "1kx" },
  { value: "BACKED", label: "BACKED VC" },
  { value: "MOURO_CAPITAL", label: "Mouro Capital" },
  { value: "UNIQA_VENTURES", label: "UNIQA Ventures" },
  { value: "BONNIER_CAPITAL", label: "Bonnier Ventures" },
  { value: "ONERAGTIME", label: "OneRagtime" },
  { value: "FABER", label: "Faber" },
  { value: "CHANGE_VENTURES", label: "Change Ventures" },
  { value: "STRIDE_VC", label: "Stride.VC" },
  { value: "FREIGEIST", label: "Freigeist Capital" },
  { value: "BONSAI_PARTNERS", label: "Bonsai Partners" },
  { value: "DUTCH_FOUNDERS_FUND", label: "Dutch Founders Fund" },
  { value: "VISIONARIES_VC", label: "Visionaries Club" },
  { value: "MSM_VC", label: "Mustard Seed MAZE" },
  { value: "FINLEAP", label: "Finleap" },
  { value: "ALLEGION", label: "Allegion Ventures" },
  { value: "TEAM_GLOBAL", label: "Team Global" },
  { value: "AHREN", label: "Ahren Innovation Capital" },
  { value: "NORDIC_NINJA", label: "NordicNinja VC" },
  { value: "BACB", label: "bacb Business Angels Club Berlin-Brandenburg" },
  { value: "VENTURE_STARS", label: "Venture Stars" },
  { value: "POLAR_LIGHT_VENTURES", label: "Polar Light Ventures" },
  { value: "365_FINTECH", label: "365.fintech" },
  { value: "THE_NORDIC_WEB", label: "The Nordic Web Ventures" },
  { value: "FAIRPOINT_CAPITAL", label: "Fairpoint Capital" },
  { value: "BRUNNUR_VENTURES", label: "Brunnur Ventures" },
  { value: "ZOBITO", label: "Zobito" },
  { value: "OUTWARD", label: "Outward VC" },
  { value: "CONTRARIAN_VENTURES", label: "Contrarian Ventures" },
  { value: "JOIN_CAPITAL", label: "Join Capital" },
  { value: "KERALA_VENTURES", label: "Kerala Ventures" },
  { value: "GULLSPANG", label: "Gullspang Invest" },
  { value: "AEROSPACE_XELERATED", label: "Aerospace Xelerated" },
  { value: "VENISTA_VENTURES", label: "Venista Venturesy" },
  { value: "XTX_MARKETS", label: "XTX Ventures" },
  { value: "42CAP", label: "42CAP" },
  { value: "GIANT_VENTURES", label: "Giant Ventures" },
  { value: "FRENCH_PARTNERS", label: "French Partners" },
  { value: "CG_PARTNERS", label: "CG Partners" },
  { value: "FFOH", label: "Female Founders Office Hours" },
  { value: "ASABYS", label: "Asabys Partners" },
  { value: "BYFOUNDERS", label: "ByFounders" },
  { value: "HEXA", label: "Hexa" },
  { value: "MAKI_VC", label: "Maki.vc" },
  { value: "BACB", label: "Business Angels Club Berlin-Brandenburg" },
  { value: "HINRICHS", label: "Hinrichs" },
  { value: "TRUEGROWTH", label: "True Growth Capital" },
  { value: "PANDO_VENTURES", label: "PANDO Ventures" },
  { value: "8CAPITA", label: "8capita Partners" },
  { value: "CAVALRY_VC", label: "Cavalry Ventures" },
  { value: "FRST", label: "Frst" },
  { value: "SUPERANGEL", label: "Superangel" },
  { value: "MOELLER_VENTURES", label: "Möller Ventures" },
].sort((a, b) => (b.label < a.label ? 1 : -1));
