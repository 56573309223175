import PropTypes from "prop-types";
import React from "react";
import SocialLink from "./organization/SocialLink";

import Config from "../config";

export default class JsonDev extends React.Component {
  static propTypes = {
    organization: PropTypes.object,
    investor: PropTypes.object,
    objectType: PropTypes.string.isRequired,
    person: PropTypes.object,
    token: PropTypes.string.isRequired,
  };

  render() {
    const {
      organization: { id: organizationId } = {},
      investor: { id: investorId } = {},
      person: { id: personId } = {},
      objectType,
      token,
    } = this.props;
    const accessToken = token
      ? token
      : JSON.parse(localStorage.auth0).accessToken;
    const objectId =
      objectType === "organizations"
        ? organizationId
        : objectType === "people"
        ? personId
        : investorId;
    const link = `${Config.api.host}/${objectType}/${objectId}?client=inreachventures&access_token=${accessToken}`;
    return <SocialLink className="icon" source="json" link={link} />;
  }
}
