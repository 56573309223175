import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

import contactRolePriorities from "../../../../content/contactRolePriorities";

import Card from "../../../card/Card";
import CardHeader from "../../../card/CardHeader";
import CardBody from "../../../card/CardBody";
import Loading from "../../../loading/Loading";
import ContactsHeader from "./ContactsHeader";
import ContactsBody from "./ContactsBody";
import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import Contact from "./Contact";
import DialogWrapper from "../../../components/DialogWrapper";

export default class Contacts extends React.Component {
  static propTypes = {
    people: PropTypes.array.isRequired,
    peopleLoading: PropTypes.bool.isRequired,
    socialMessages: PropTypes.array,
    primaryContactId: PropTypes.string.isRequired,
    updateOrganization: PropTypes.func.isRequired,
  };

  state = {
    selectedPerson: 0,
    confirmChangePrimaryContactModalOpen: false,
    changePrimaryContactId: null,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(this.props.contacts, prevProps.contacts)) {
      this.resetSelectedPerson();
    }
  }

  resetSelectedPerson = () => {
    this.setState({
      selectedPerson: 0,
    });
  };

  filterAndSortPeople = (people = this.state.people) => {
    if (people.length < 1) {
      return [];
    }
    const sortedPeople = this.sortPeople(this.filterPeople(people));
    sortedPeople.length = Math.min(sortedPeople.length, 20);
    return sortedPeople;
  };

  filterPeople = (people) => {
    const uniquePeople = {};
    for (const person of people) {
      const name = String(person.name || "")
        .replace(/\s/g, "")
        .toLowerCase();
      if (!uniquePeople[name]) {
        uniquePeople[name] = person;
      }
    }
    return Object.values(uniquePeople);
  };

  sortPeople = (people) => people.sort(this.getPersonPriority);

  getPersonPriority = (a, b) =>
    this.getRolePriority(this.getRole(a)) -
    this.getRolePriority(this.getRole(b));

  getRolePriority = (role) => {
    return (
      contactRolePriorities[role] ||
      Object.keys(contactRolePriorities).length + 1
    );
  };

  getRole = (person) => {
    const { primaryContactId } = this.props;
    if (primaryContactId && primaryContactId === person.id) {
      return "primary";
    }
    return (person.role || person.title || "").toLowerCase();
  };

  getSelectedPersonId = (selectedPerson, filteredAndSortedPeople) => {
    if (filteredAndSortedPeople.length === 0) return "";
    return filteredAndSortedPeople[selectedPerson]
      ? filteredAndSortedPeople[selectedPerson].id
      : "";
  };

  changeSelectedPerson = (
    direction,
    selectedPerson = this.state.selectedPerson,
    peopleLength
  ) => {
    switch (direction) {
      case -1:
        if (selectedPerson > 0) {
          this.setState({
            selectedPerson: --selectedPerson,
          });
        }
        break;
      case 1:
        if (selectedPerson < peopleLength - 1) {
          this.setState({
            selectedPerson: ++selectedPerson,
          });
        }
        break;
    }
  };

  sortSocialEngaged = (socialMessages) => {
    if (!socialMessages) return [];
    return socialMessages
      .filter((socialMessage) => socialMessage.engaged)
      .map((engagedSocialMessage) => engagedSocialMessage.person_id);
  };

  showConfirmChangePrimaryContactDialog = (id) => {
    this.setState({
      confirmChangePrimaryContactModalOpen: true,
      changePrimaryContactId: id,
    });
  };

  hideConfirmChangePrimaryContactDialog = () => {
    this.setState({
      confirmChangePrimaryContactModalOpen: false,
      changePrimaryContactId: null,
    });
  };

  confirmSetPrimaryContact = () => {
    this.props.updateOrganization([
      {
        op: "replace",
        path: "/primary_contact_id",
        value: this.state.changePrimaryContactId,
      },
    ]);
    this.hideConfirmChangePrimaryContactDialog();
    this.resetSelectedPerson();
  };

  render() {
    const { selectedPerson, confirmChangePrimaryContactModalOpen } = this.state;
    const { people, peopleLoading, socialMessages, primaryContactId } =
      this.props;
    const filteredAndSortedPeople = this.filterAndSortPeople(people);
    const socialEngagedIds = this.sortSocialEngaged(socialMessages);
    const primarySocialEngaged = socialEngagedIds.includes(primaryContactId);
    return (
      <div className="contacts">
        <Card>
          <CardHeader title="Contacts">
            <ContactsHeader
              selectedPerson={selectedPerson}
              selectedPersonId={this.getSelectedPersonId(
                selectedPerson,
                filteredAndSortedPeople
              )}
              peopleLength={filteredAndSortedPeople.length}
              changeSelectedPerson={this.changeSelectedPerson}
              {...this.props}
            />
          </CardHeader>
          <CardBody>
            <Choose>
              <When condition={!peopleLoading}>
                <Choose>
                  <When condition={filteredAndSortedPeople.length > 0}>
                    <ContactsBody
                      handleMakeContactChecklistChange={
                        this.props.handleMakeContactChecklistChange
                      }
                      contacts={filteredAndSortedPeople}
                      socialEngagedIds={socialEngagedIds}
                      primarySocialEngaged={primarySocialEngaged}
                      selectedPerson={selectedPerson}
                      changePrimaryContactClick={
                        this.showConfirmChangePrimaryContactDialog
                      }
                      {...this.props}
                    />
                  </When>
                  <Otherwise>
                    <p className="no-content">No contacts</p>
                  </Otherwise>
                </Choose>
              </When>
              <Otherwise>
                <Loading />
              </Otherwise>
            </Choose>
          </CardBody>
        </Card>
        <DialogWrapper
          title="Confirm change Primary Contact"
          actions={[
            <RaisedButton
              className="assign-action"
              label="Cancel"
              style={{
                marginRight: "10px",
              }}
              onClick={this.hideConfirmChangePrimaryContactDialog}
            />,
            <RaisedButton
              children={<span style={{ color: "white" }}>CONFIRM</span>}
              className="assign-action"
              backgroundColor="#5DAB49"
              labelStyle={{
                color: "white",
              }}
              onClick={this.confirmSetPrimaryContact}
            />,
          ]}
          modal={false}
          open={confirmChangePrimaryContactModalOpen}
          onRequestClose={this.hideConfirmChangePrimaryContactDialog}
        >
          Are you sure you want to update the Primary Contact?
        </DialogWrapper>
      </div>
    );
  }
}
