import React from "react";
import PropTypes from "prop-types";

import sourceImage from "../../lib/sourceImage";

class SourcesCell extends React.Component {
  static propTypes = {
    person: PropTypes.object.isRequired,
  };

  render() {
    var sources =
      this.props.person.source_refs ||
      this.props.person.source_references ||
      {};

    return (
      <ul className="list-unstyled">
        {Object.keys(sources).map(function (provider) {
          return (
            <li key={provider}>
              <img className="source_logo" src={sourceImage(provider)} />
            </li>
          );
        })}
      </ul>
    );
  }
}

export default SourcesCell;
