import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-v4";

export default class PersonExperience extends React.Component {
  static propTypes = {
    experience: PropTypes.object.isRequired,
    highlighted: PropTypes.bool.isRequired,
  };

  static duration(start, end, duration) {
    if (end && duration) {
      return duration;
    }
    const started = moment(start);
    const ended = end && end !== "Present" ? moment(end) : moment();
    const years = ended.diff(started, "years");
    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""}`;
    }
    const months = ended.diff(started, "months");
    if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""}`;
    }
    const days = ended.diff(started, "days");
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""}`;
    }
    return "?";
  }

  duration = () => {
    const {
      experience: { start, end, duration },
    } = this.props;
    return PersonExperience.duration(start, end, duration);
  };

  render() {
    const { experience = {}, highlighted = false } = this.props;

    return (
      <div
        key={`${experience.title}`}
        className={`person-experience${
          highlighted ? " person-highlighted" : ""
        }`}
      >
        <If condition={experience.title}>
          <div className="title">{experience.title}</div>
        </If>
        <If condition={experience.company}>
          <div className="company">
            <Choose>
              <When condition={experience.organization_id}>
                <Link
                  to={`/organizations/${experience.organization_id}`}
                  title={experience.company}
                >
                  {experience.company}
                </Link>
              </When>
              <Otherwise>{experience.company}</Otherwise>
            </Choose>
          </div>
        </If>
        <If condition={experience.description}>
          <div className="description">{experience.description}</div>
        </If>
        <If condition={experience.start}>
          <div className="duration">
            {experience.start} - {experience.end || "Present"} (
            {this.duration()})
          </div>
        </If>
      </div>
    );
  }
}
