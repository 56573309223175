import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import Technologies from "./Technologies";

class OtherInfoProduct extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
  };

  render() {
    const { organization = {} } = this.props;
    return (
      <div className="other-info-section">
        <div className="section-title">Product</div>
        <Technologies organization={organization} />
      </div>
    );
  }
}

export default withRouter(OtherInfoProduct);
