import request from "superagent";
import auth from "./Auth";
import Config from "../config";

export default class SourceScorer {
  constructor() {
    this.url = Config.api.host + "/source_scores";
  }

  scoreCSV(csv) {
    return auth
      .getAuthData()
      .then((authData) => {
        return request
          .post(this.url)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .set("Accept", "application/json")
          .set("Content-type", "text/csv")
          .send(csv);
      })
      .then((res) => res.body);
  }
}
