import React from "react";
import Highlight from "./Highlight";
import insights from "../../content/insights";

class Highlights extends React.Component {
  render() {
    return (
      <If condition={this.props.data}>
        {Object.keys(this.props.data).map((highlight) => {
          const highlightData = insights[highlight];

          return highlightData ? (
            <Highlight
              key={highlightData.name}
              backgroundColor="rgba(93, 171, 73, 0.7)"
              icon={highlightData.icon}
              label={`Highlight: ${highlightData.name}`}
            />
          ) : null;
        })}
      </If>
    );
  }
}

export default Highlights;
