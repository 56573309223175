import PropTypes from "prop-types";
import React from "react";
import Image from "../Image";

import { Link } from "react-router-v4";

export default class ImageCell extends React.Component {
  static propTypes = {
    person: PropTypes.object.isRequired,
  };

  render() {
    const {
      person: { id, image_url },
    } = this.props;

    if (!image_url) {
      return <div className="pull-left org-image" />;
    }
    return (
      <div className="pull-left org-image">
        <Link to={`/people/${id}`}>
          <Image url={image_url} height={50} width={50} />
        </Link>
      </div>
    );
  }
}
