import React from "react";
import PropTypes from "prop-types";
import Clipboard from "clipboard";
import FlatButton from "material-ui/FlatButton";
import Tooltip from "../../../Tooltip";
import SocialLink from "../../SocialLink";
import { getLinkedInUrl } from "../../../../lib/helpers";
import ActionAssignment from "material-ui/svg-icons/action/assignment";
import placeholder from "../../../../../images/person-placeholder.jpg";
import PersonOrganizations from "../../PersonOrganizations";
import AssignCampaigns from "../../../../lib/AssignCampaigns";
import CircularProgress from "material-ui/CircularProgress";
import Warning from "material-ui/svg-icons/alert/warning";

export default class Contact extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    organizationDecision: PropTypes.object.isRequired,
    showErrorDialog: PropTypes.func.isRequired,
    person: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    isPrimary: PropTypes.bool,
    isSocialEngaged: PropTypes.bool,
    updateOrganization: PropTypes.func.isRequired,
    changePrimaryContactClick: PropTypes.func.isRequired,
    recentMessages: PropTypes.object,
    socialEngagedIds: PropTypes.array,
    primarySocialEngaged: PropTypes.bool,
    sendSocialMessage: PropTypes.func.isRequired,
  };

  state = {
    confirmChangePrimaryContactModalOpen: false,
    changePrimaryContactId: null,
    loading: false,
  };

  avatarRef = React.createRef();
  nameCopyRef = React.createRef();
  roleCopyRef = React.createRef();
  titleCopyRef = React.createRef();
  emailCopyRef = React.createRef();

  clipboard = new Clipboard(".copy-buttons");

  getContactInfo = (contactInfo = {}) => {
    return {
      id: contactInfo.id,
      name: contactInfo.name,
      role: contactInfo.role || "",
      title: contactInfo.title || "",
      email: contactInfo.email || null,
      imageUrl: contactInfo.image_url || null,
      organizations: contactInfo.organizations || [],
      socials: {
        website: contactInfo.url || null,
        twitter: contactInfo.twitter_url || null,
        angellist: contactInfo.angellist_url || null,
        linkedin: contactInfo.linkedin_url || null,
        facebook: contactInfo.facebook_url || null,
        github: contactInfo.github_url || null,
      },
    };
  };

  getClassName = (selected) => {
    return selected ? "contact selected" : "contact";
  };

  getAvatarClassName = (isPrimary) => {
    return isPrimary ? "user-avatar primary" : "user-avatar";
  };

  getAvatarStyles = (imageUrl) => {
    return {
      backgroundImage: imageUrl
        ? `url('${imageUrl}'), url('${placeholder}')`
        : `url(${placeholder})`,
    };
  };

  sendSocialMessage = (personId, medium, type) => {
    const {
      showErrorDialog,
      sendSocialMessage,
      reloadOrganization,
      organization,
    } = this.props;
    sendSocialMessage(personId, medium, type)
      .then(() => {
        if (type === "engaged" || type === "sent") {
          const {
            stage,
            make_contact_checklist: currentMakeContactChecklist = {},
          } = this.props.organizationDecision;
          if (!!stage && stage === "make_contact") {
            this.props.handleMakeContactChecklistChange(
              Object.assign({}, currentMakeContactChecklist, {
                ["send_inmail"]: new Date().getTime(),
              })
            );
          }
        }
        this.setState({ loading: false });
        reloadOrganization(organization.id);
      })
      .catch((error) => {
        showErrorDialog(AssignCampaigns.processError(error));
      });
  };

  render() {
    const {
      person,
      selected,
      isPrimary,
      changePrimaryContactClick,
      isSocialEngaged,
      organizationDecision,
      recentMessages,
      primarySocialEngaged,
      socialEngagedIds = [],
    } = this.props;
    const { loading } = this.state;
    const contactInfo = this.getContactInfo(person);
    const recentContactMessagesNumbers = (
      (recentMessages || {}).emailCounts || []
    ).find((counts) => counts.id === contactInfo.id);
    const loadingIndicator = (
      <div className="loading-indicator">
        <CircularProgress size={20} />
      </div>
    );

    return (
      <div className={this.getClassName(selected)}>
        <table className="workflow-table">
          <tbody>
            <tr className="workflow-row contact-info">
              <td className="workflow-row-key">
                <div
                  ref={this.avatarRef}
                  className={this.getAvatarClassName(isPrimary)}
                  style={this.getAvatarStyles(contactInfo.imageUrl)}
                />
              </td>
              <td className="workflow-row-value">
                <a href={`/people/${contactInfo.id}`} target="_blank">
                  <span>{contactInfo.name}</span>
                </a>
              </td>
              <td
                className="workflow-row-actions"
                align="right"
                style={{
                  width: contactInfo.organizations.length > 1 ? "54px" : "24px",
                }}
              >
                <div className="workflow-row-actions-wrapper">
                  <If condition={contactInfo.organizations.length > 1}>
                    <PersonOrganizations person={contactInfo} />
                  </If>
                  <div ref={this.nameCopyRef}>
                    <ActionAssignment
                      color="#7F7F7F"
                      className="copy-to-clipboard copy-buttons"
                      data-clipboard-text={contactInfo.name}
                    />
                  </div>
                </div>
                <Tooltip
                  parent={this.nameCopyRef.current}
                  text={"Copy to clipboard"}
                  showOnHover={true}
                  customStyles={{
                    right: "0px",
                    top: "0px",
                  }}
                />
              </td>
            </tr>
            <If condition={contactInfo.role !== ""}>
              <tr className="workflow-row role">
                <td className="workflow-row-key">Role:</td>
                <td className="workflow-row-value">
                  <span>{contactInfo.role}</span>
                </td>
                <td className="workflow-row-actions" align="right">
                  <div ref={this.roleCopyRef}>
                    <ActionAssignment
                      color="#7F7F7F"
                      className="copy-to-clipboard copy-buttons"
                      data-clipboard-text={contactInfo.role}
                    />
                  </div>
                  <Tooltip
                    parent={this.roleCopyRef.current}
                    position="top"
                    text={"Copy to clipboard"}
                    showOnHover={true}
                    customStyles={{
                      right: "0px",
                    }}
                  />
                </td>
              </tr>
            </If>
            <If condition={contactInfo.title !== ""}>
              <tr className="workflow-row role">
                <td className="workflow-row-key">Title:</td>
                <td className="workflow-row-value">
                  <span>{contactInfo.title}</span>
                </td>
                <td className="workflow-row-actions" align="right">
                  <div ref={this.titleCopyRef}>
                    <ActionAssignment
                      color="#7F7F7F"
                      className="copy-to-clipboard copy-buttons"
                      data-clipboard-text={contactInfo.title}
                    />
                  </div>
                  <Tooltip
                    parent={this.titleCopyRef.current}
                    position="top"
                    text={"Copy to clipboard"}
                    showOnHover={true}
                    customStyles={{
                      right: "0px",
                    }}
                  />
                </td>
              </tr>
            </If>
            <If condition={contactInfo.email}>
              <tr className="workflow-row email">
                <td className="workflow-row-key">Email:</td>
                <td className="workflow-row-value">
                  <span>{contactInfo.email}</span>
                </td>
                <td className="workflow-row-actions" align="right">
                  <div ref={this.emailCopyRef}>
                    <ActionAssignment
                      color="#7F7F7F"
                      className="copy-to-clipboard copy-buttons"
                      data-clipboard-text={contactInfo.email}
                    />
                  </div>
                  <Tooltip
                    parent={this.emailCopyRef.current}
                    position="top"
                    text={"Copy to clipboard"}
                    showOnHover={true}
                    customStyles={{
                      right: "0px",
                    }}
                  />
                </td>
              </tr>
            </If>
          </tbody>
        </table>
        <div className="socials-wrapper" style={{ margin: "5px" }}>
          <If condition={contactInfo.socials.website}>
            <SocialLink source="website" link={contactInfo.socials.website} />
          </If>
          <If condition={contactInfo.socials.twitter}>
            <SocialLink source="twitter" link={contactInfo.socials.twitter} />
          </If>
          <If condition={contactInfo.socials.angellist}>
            <SocialLink
              source="angellist"
              link={contactInfo.socials.angellist}
            />
          </If>
          <If condition={contactInfo.socials.linkedin}>
            <SocialLink
              source="linkedin"
              link={getLinkedInUrl(contactInfo.socials.linkedin)}
            />
          </If>
          <If condition={contactInfo.socials.facebook}>
            <SocialLink source="facebook" link={contactInfo.socials.facebook} />
          </If>
          <If condition={contactInfo.socials.github}>
            <SocialLink source="github" link={contactInfo.socials.github} />
          </If>
        </div>
        <If condition={recentContactMessagesNumbers}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "4px 0 16px 0",
            }}
          >
            <div style={{ display: "flex" }}>
              <div className="workflow-email-count-key">Sent:</div>
              <div className="workflow-email-count-value">
                {recentContactMessagesNumbers.sent}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="workflow-email-count-key">Replied:</div>
              <div className="workflow-email-count-value">
                {recentContactMessagesNumbers.replied}
              </div>
            </div>
            <If condition={isSocialEngaged}>
              <div style={{ display: "flex" }}>
                <div
                  className="social-link linkedin"
                  style={{ color: "#000000" }}
                />
                Engaged
              </div>
            </If>
          </div>
        </If>
        <If
          condition={
            isPrimary &&
            recentMessages &&
            recentMessages.lastEmailFrom &&
            !recentMessages.matchesPrimary
          }
        >
          <div className="error-message" style={{ marginBottom: "12px" }}>
            <Warning color="#FF0000" className="icon" />
            The last email we received was from a different email address:{" "}
            {recentMessages.lastEmailFrom}
          </div>
        </If>
        <If condition={socialEngagedIds.length && !primarySocialEngaged}>
          <div className="error-message" style={{ marginBottom: "12px" }}>
            <Warning color="#FF0000" className="icon" />
            We have engaged with someone else on Linkedin
          </div>
        </If>
        <If
          condition={recentMessages && recentMessages.lastEmailFromEntrepreneur}
        >
          <div className="warning-message" style={{ marginBottom: "12px" }}>
            <Warning color="#eb9605" className="icon" />
            We didn't respond to the last email from:{" "}
            {recentMessages.lastEmailFrom}
          </div>
        </If>
        <div
          className="workflow-row workflow-social-engagements"
          style={{ marginTop: "15px" }}
        >
          <FlatButton
            onClick={() =>
              !isPrimary ? changePrimaryContactClick(contactInfo.id) : null
            }
            label={isPrimary ? "Primary Contact" : "Set Primary Contact"}
            disabled={isPrimary}
            style={{
              backgroundColor: isPrimary ? "#5dab49" : "#337ab7",
              height: "35px",
              width: "100%",
            }}
            labelStyle={{
              color: "#ffffff",
              padding: "5px",
              fontSize: "11px",
            }}
          />
        </div>
        <If
          condition={[
            "make_contact",
            "unable_to_contact",
            "in_tracking",
          ].includes(organizationDecision.stage || "")}
        >
          <div
            className="workflow-row workflow-social-engagements"
            style={{ marginTop: "15px" }}
          >
            <FlatButton
              className="sent-message-button"
              onClick={() => {
                this.setState({ loading: true });
                this.sendSocialMessage(person.id, "linkedin", "sent");
              }}
              disabled={loading}
              label={loading ? null : "Message Sent"}
              icon={
                loading ? (
                  loadingIndicator
                ) : (
                  <div
                    className="social-link linkedin"
                    style={{ color: "#000", display: "inline-block" }}
                  />
                )
              }
              style={{
                backgroundColor: "#d8d8d8",
                cursor: "pointer",
                width: "calc(50% - 6px)",
                minHeight: "35px",
              }}
              labelStyle={{
                color: "#000",
                fontSize: "10px",
                paddingRight: "0",
                marginRight: "10px",
              }}
            />
            <div className="spacer" />
            <FlatButton
              className="engaged-button"
              onClick={() => {
                this.setState({ loading: true });
                isSocialEngaged
                  ? this.sendSocialMessage(person.id, "linkedin", "unengaged")
                  : this.sendSocialMessage(person.id, "linkedin", "engaged");
              }}
              disabled={loading}
              label={
                loading ? null : isSocialEngaged ? "Engaged" : "Mark as Engaged"
              }
              icon={
                loading ? (
                  loadingIndicator
                ) : (
                  <div
                    className="social-link linkedin"
                    style={{
                      color: isSocialEngaged ? "#ffffff" : "#000000",
                      display: "inline-block",
                    }}
                  />
                )
              }
              style={{
                backgroundColor: isSocialEngaged ? "#5dab49" : "#d8d8d8",
                width: "calc(50% - 6px)",
                height: "35px",
              }}
              labelStyle={{
                color: isSocialEngaged ? "#ffffff" : "#000000",
                fontSize: isSocialEngaged ? "9px" : "10px",
                paddingRight: "0",
                marginRight: "10px",
              }}
            />
          </div>
        </If>
      </div>
    );
  }
}
