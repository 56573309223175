import request from "superagent";
import auth from "./Auth";
import Config from "../config";
import organizationsStore from "./OrganizationsStore";

export default class InvestorStore {
  getInvestor = (id) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(`${Config.api.host}/investors/${id}`).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then(({ body = {} }) => {
        return body;
      });
  };

  multiGetInvestors = (ids) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(`${Config.api.host}/investors/${ids.join(",")}`).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then(({ body = {} }) => {
        if (body.models && Array.isArray(body.models)) {
          return body.models;
        }
        return [body];
      });
  };

  addInvestorToOrganization = (organizationId, investorId) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(
            `${Config.api.host}/organizations/${organizationId}/investors/${investorId}`
          )
          .send({})
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then((response) => response.body);
  };

  getInvestors = (url) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(`${Config.api.host}/investors?${url}`).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then(({ body: { investors = [], total = 0 } } = {}) => {
        return [investors, total];
      });
  };

  updateInvestor = (id, patchData) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .patch(`${Config.api.host}/investors/${id}`)
          .send(patchData)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then((response) => response.body);
  };
}
