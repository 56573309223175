import React from "react";

class Money extends React.Component {
  static roundMoney(m) {
    return Math.round(m * 100) / 100;
  }
  static formatMoney(m) {
    return Math.abs(Number(m)) >= 1.0e9
      ? this.roundMoney(Math.abs(Number(m)) / 1.0e9) + "B"
      : Math.abs(Number(m)) >= 1.0e6
      ? this.roundMoney(Math.abs(Number(m)) / 1.0e6) + "M"
      : Math.abs(Number(m)) >= 1.0e3
      ? this.roundMoney(Math.abs(Number(m)) / 1.0e3) + "K"
      : Math.abs(Number(m));
  }

  render() {
    return <span>${Money.formatMoney(this.props.money).toLocaleString()}</span>;
  }
}

export default Money;
