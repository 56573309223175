import PropTypes from "prop-types";
import React from "react";
import AppBar from "material-ui/AppBar";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import IconButton from "material-ui/IconButton";
import Drawer from "material-ui/Drawer";
import CommunicationBusiness from "material-ui/svg-icons/communication/business";
import SocialPeople from "material-ui/svg-icons/social/people";
import EditorShowChart from "material-ui/svg-icons/editor/show-chart";
import ActionExitToApp from "material-ui/svg-icons/action/exit-to-app";
import AttachMoney from "material-ui/svg-icons/editor/attach-money";
import ContentInbox from "material-ui/svg-icons/content/inbox";
import OrganizationsStore from "../storage/OrganizationsStore";
import ContentAddCircleOutline from "material-ui/svg-icons/content/add-circle-outline";
import ContentDeleteSweep from "material-ui/svg-icons/content/delete-sweep";
import ActionViewList from "material-ui/svg-icons/action/view-list";
import SocialWhatsHot from "material-ui/svg-icons/social/whatshot";
import PieChart from "material-ui/svg-icons/editor/pie-chart-outlined";
import Profile from "./Profile";
import { Link, withRouter } from "react-router-v4";
import auth from "../storage/Auth";
import _ from "underscore";
import ROUTES_REQUIRING_FULL_ACCESS from "../content/routesRequiringFullAccess";

class NavigationItems extends React.Component {
  render() {
    const {
      handleClick,
      handleLogout,
      inboxNumber,
      location,
      organizationParams,
      token,
      accessType,
      email,
      client,
    } = this.props;

    const getClassName = (pathname) => {
      const classNames = ["navigation-link"];

      if (location.pathname.startsWith(pathname)) {
        classNames.push("link-active");
      }

      if (ROUTES_REQUIRING_FULL_ACCESS.indexOf(pathname) !== -1 && accessType) {
        if (accessType !== "full") {
          classNames.push("hide");
        }
      }

      return classNames.join(" ");
    };

    return (
      <div className="links">
        <If condition={token}>
          <Profile />
          <Link
            className="navigation-link"
            onClick={handleClick}
            to="/organizations/create"
          >
            <ContentAddCircleOutline className="icon-link" />
            <span>Create</span>
          </Link>
          <Link
            className={getClassName("/organizations")}
            onClick={handleClick}
            to={{
              pathname: "/organizations",
              query: organizationParams,
            }}
          >
            <CommunicationBusiness className="icon-link" />
            <span>Organizations</span>
          </Link>
          <Link
            className={getClassName("/discover")}
            onClick={handleClick}
            to={{
              pathname: "/discover",
            }}
          >
            <SocialWhatsHot className="icon-link" />
            <span>Discover</span>
          </Link>
          <Link
            className={getClassName("/overview")}
            onClick={handleClick}
            to={{
              pathname: "/overview",
              query: email != null ? { assigned_to: email } : {},
            }}
          >
            <ActionViewList className="icon-link" />
            <span>Overview</span>
          </Link>
          <Link
            className={getClassName("/inbox")}
            to={{
              pathname: "/inbox",
              query: {
                inbox: "true",
                order_by: `inboxes.${client}.updated_at`,
                order: "desc",
                user_inbox: email,
              },
            }}
            onClick={handleClick}
          >
            <If condition={inboxNumber > 0}>
              <div className="badge-inbox">
                <div>{inboxNumber}</div>
              </div>
            </If>
            <ContentInbox className="icon-link" />
            <span>Inbox</span>
          </Link>
          <Link
            className={getClassName("/people")}
            onClick={handleClick}
            to="/people"
          >
            <SocialPeople className="icon-link" />
            <span>People</span>
          </Link>
          <Link
            className={getClassName("/investors")}
            onClick={handleClick}
            to="/investors"
          >
            <AttachMoney className="icon-link" />
            <span>Investors</span>
          </Link>
          <Link
            className={getClassName("/spam")}
            onClick={handleClick}
            to={"/spam"}
          >
            <ContentDeleteSweep className="icon-link" />
            <span>Spam</span>
          </Link>
          <Link
            className="navigation-link"
            href="/logout"
            onClick={handleLogout}
          >
            <ActionExitToApp className="icon-link" />
            <span>Logout</span>
          </Link>
        </If>
      </div>
    );
  }
}

export default class NavigationBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDrawer: false,
      inboxNumber: null,
      token: null,
      organizationParams: {},
      email: null,
      accessType: null,
    };

    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.updateInboxSize = _.debounce(this.updateInboxSize.bind(this), 500);
    this.setOrganizationParams = this.setOrganizationParams.bind(this);
    this.initNavigationBar = this.initNavigationBar.bind(this);
    this.handleInboxUpdate = this.handleInboxUpdate.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(nextProps.location.pathname, this.props.location.pathname) ||
      !_.isEqual(nextState, this.state)
    );
  }

  componentDidUpdate(nextProps) {
    const isPathnameEqual = _.isEqual(
      nextProps.location.pathname,
      this.props.location.pathname
    );
    this.initNavigationBar(!isPathnameEqual);
  }

  initNavigationBar(hasToUpdateInboxSize) {
    auth
      .getAuthData()
      .then(({ token, accessType, client, profile: { email } }) => {
        this.setState({ token, accessType, client, email }, () => {
          this.setOrganizationParams();
        });
        if (
          accessType === "full" &&
          (hasToUpdateInboxSize || !this.state.inboxNumber)
        ) {
          this.updateInboxSize();
        }
      })
      .catch(console.error);
  }

  handleInboxUpdate(e) {
    const inboxNumber = e.detail
      ? this.state.inboxNumber + 1
      : this.state.inboxNumber - 1;

    this.setState({
      inboxNumber,
    });
  }

  componentDidMount() {
    window.addEventListener("inbox_update", this.handleInboxUpdate, false);

    this.initNavigationBar();

    this.unsubscribe = auth.subscribe((e, ar) => {
      this.initNavigationBar();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("inbox_update", this.handleInboxUpdate);

    this.unsubscribe();
  }

  setOrganizationParams() {
    auth.getCustomisedFeedParams().then((organizationParams) => {
      this.setState(() => ({ organizationParams }));
    });
  }

  updateInboxSize() {
    OrganizationsStore.getInboxSize().then((inboxNumber) => {
      this.setState({
        inboxNumber,
      });
    });
  }

  logout(e) {
    e.preventDefault();
    auth.signOut();
  }

  showLock() {
    auth.showLock();
  }

  handleToggleMenu() {
    this.setState({ openDrawer: !this.state.openDrawer });
  }

  handleCloseMenu() {
    this.setState({ openDrawer: false });
  }

  render() {
    const { location } = this.props;

    const navigationItems = (
      <NavigationItems
        handleClick={this.handleCloseMenu}
        handleLogout={this.logout}
        inboxNumber={this.state.inboxNumber}
        location={location}
        organizationParams={this.state.organizationParams}
        token={this.state.token}
        accessType={this.state.accessType}
        email={this.state.email}
        client={this.state.client}
      />
    );

    return (
      <div className="navigation-header">
        <AppBar
          className="navigation-bar"
          title={
            <Link
              className="title"
              to={{
                pathname: "/organizations",
                query: this.state.organizationParams,
              }}
            >
              <div className="logo" />
            </Link>
          }
          showMenuIconButton={!!this.state.token}
          iconElementRight={navigationItems}
          iconElementLeft={
            <IconButton
              onClick={this.handleToggleMenu}
              className="navigation-icon"
            >
              <NavigationMenu className="navigation-menu" />
            </IconButton>
          }
        />
        <Drawer
          docked={false}
          width={250}
          className="menu-drawer"
          open={this.state.openDrawer}
          onRequestChange={(openDrawer) => this.setState({ openDrawer })}
          overlayStyle={{ background: "black", opacity: 0.6 }}
        >
          {navigationItems}
        </Drawer>
      </div>
    );
  }
}

NavigationBar.contextTypes = {
  router: PropTypes.object.isRequired,
};
