const IS_URL =
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
  IS_EMAIL =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class CreateOrganizationHelper {
  isUrl(s) {
    return IS_URL.test(s);
  }

  isEmail(email) {
    return IS_EMAIL.test(email);
  }

  validateField(ref, value, type) {
    switch (type) {
      case "required":
        return value ? false : "This field is required";
      case "URL":
        return !value || this.isUrl(value)
          ? false
          : "Please specify a valid URL, including http:// or https://";
      case "number":
        return !value || !isNaN(value)
          ? false
          : "Please specify a valid number";
      case "email":
        return !value || this.isEmail(value)
          ? false
          : "Please specify a valid email";
      default:
        return false;
    }
  }

  validateAllFields(refs, entry) {
    let isValid = true;
    const errorMessages = {};
    Object.keys(refs).map((fieldKey) => {
      const field = refs[fieldKey];
      if (field.props && field.props.name && field.props["data-validation"]) {
        const value = entry[field.props.name];

        const errorMessage = this.validateField(
          fieldKey,
          value,
          field.props["data-validation"]
        );
        if (errorMessage) {
          errorMessages[fieldKey] = errorMessage;
          isValid = false;
        }
      }
    });

    return {
      isValid,
      errors: errorMessages,
    };
  }
}
