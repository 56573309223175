import PropTypes from "prop-types";
import React from "react";
import PreviewOrganization from "../organization/preview/PreviewOrganization";
import NameCell from "../organization/NameCell";
import Image from "../Image";
import ActionDelete from "material-ui/svg-icons/action/delete";
import FlatButton from "material-ui/FlatButton";
import Divider from "material-ui/Divider";

class EditableOrganizationSummary extends PreviewOrganization {
  shouldComponentUpdate() {
    return true;
  }

  render() {
    const organization = this.props.organization;
    return (
      <div className="editable-organization-summary">
        <div className="organization-container">
          <div className="summary">
            <Image url={organization.image_url} />

            <NameCell openInNewTab={true} organization={organization} />
          </div>
          <FlatButton
            label="Remove"
            labelStyle={{ color: "#C30000" }}
            icon={<ActionDelete color={"#C30000"} />}
            onClick={() => this.props.onRemoveOrganization(this.props.index)}
          />
        </div>
        <Divider />
      </div>
    );
  }
}

EditableOrganizationSummary.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default EditableOrganizationSummary;
