import PropTypes from "prop-types";
import React from "react";
import OrganizationStore from "../../storage/OrganizationStore";
import auth from "../../storage/Auth";

import Loading from "../loading/Loading";
import PreviewOrganization from "../organization/preview/PreviewOrganization";
import EditableOrganizationSummary from "./EditableOrganizationSummary";

export default class OrganizationSummaryRetriever extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  state = {
    userHasFullAccess: false,
    userRoles: [],
    client: null,
    profile: null,
  };

  organizationStore = new OrganizationStore(this.props.id);

  componentWillMount() {
    this.organizationStore.getModel().then(this.handleOrganizationChange);
    auth
      .getAuthData()
      .then(({ accessType, roles, profile, client }) => {
        this.setState({
          userHasFullAccess: accessType === "full",
          userRoles: roles,
          profile,
          client,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  handleOrganizationChange = (organization) => {
    this.setState({ organization: organization });
  };

  handleError = (error) => {
    console.error(error);
  };

  render() {
    const {
      organization,
      editable = false,
      client,
      profile,
      userRoles,
      userHasFullAccess,
    } = this.state;
    if (!organization || !client || !profile) {
      return <Loading />;
    }

    if (editable) {
      return (
        <EditableOrganizationSummary
          index={this.props.index}
          key={organization.id}
          organization={organization}
          onRemoveOrganization={this.props.onRemoveOrganization}
        />
      );
    }

    return (
      <PreviewOrganization
        key={organization.id}
        organization={organization}
        profile={profile}
        client={client}
        addToInbox={() => {}}
        removeFromInbox={() => {}}
        showInboxModal={() => {}}
        organizationsStoreParams={{}}
        filterSource={{}}
        returnUrl={""}
        isUpdatingInbox={false}
        showPredictions={false}
        activeInboxUser={""}
        handleErrorOpen={() => {}}
        userHasFullAccess={userHasFullAccess}
        userRoles={userRoles}
        handleErrorOpenDialog={this.handleError}
        reloadOrganization={() => {}}
        source={"edit-person"}
        feedbackCategories={[]}
        structuredReasons={{}}
        handleOrganizationChange={this.handleOrganizationChange}
      />
    );
  }
}
