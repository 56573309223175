import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

class NumberOfInvestors extends React.Component {
  static propTypes = {
    investors: PropTypes.array.isRequired,
  };

  render() {
    const { investors = [] } = this.props;
    return (
      <div className="data-wrapper number-of-funding-rounds">
        <div className="data-header">No. of Investors</div>
        <div className="data-content text">{investors.length}</div>
      </div>
    );
  }
}

export default withRouter(NumberOfInvestors);
