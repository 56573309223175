import React, { Component } from "react";
import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";
import ContentAddCircleOutline from "material-ui/svg-icons/content/add-circle-outline";
import FilterSummaryTag from "./FilterSummaryTag";

const csvsToArray = (csvs) => (csvs ? csvs.split(",") : []);

class CSVFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: csvsToArray(props.values),
      valuesInput: "",
    };

    this.addValue = this.addValue.bind(this);
    this.deleteValue = this.deleteValue.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.updateValuesInput = this.updateValuesInput.bind(this);
  }

  addValue() {
    if (this.state.valuesInput.length > 0) {
      const values = new Set(this.state.values);

      this.state.valuesInput.split(",").forEach((value) => {
        values.add(value.trim());
      });

      this.props.didChangeValues(Array.from(values.values()).join(","));
      this.setState({
        valuesInput: "",
      });
    }
  }

  deleteValue(value) {
    this.props.didChangeValues(
      this.state.values.filter((v) => v !== value).join(",")
    );
  }

  onKeyPress(event) {
    const ENTER_KEY_CHAR_CODE = 13;
    const COMMA_KEY_CHAR_CODE = 44;
    const triggerCharCodes = [ENTER_KEY_CHAR_CODE, COMMA_KEY_CHAR_CODE];

    if (triggerCharCodes.includes(event.charCode)) {
      event.preventDefault();
      this.addValue();
    }
  }

  updateValuesInput(e, value) {
    this.setState({
      valuesInput: value,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState(() => ({ values: csvsToArray(nextProps.values) }));
  }

  render() {
    return (
      <div className="csvs-filters">
        <div className="add-csvs">
          <label> {this.props.label} </label>
          <div>
            <TextField
              className="input"
              onKeyPress={this.onKeyPress}
              id={this.props.id}
              hintText={this.props.hintText}
              underlineFocusStyle={{ borderColor: "#337ab7" }}
              value={this.state.valuesInput}
              onChange={this.updateValuesInput}
            />
            <IconButton onClick={this.addValue}>
              <ContentAddCircleOutline color="#337ab7" />
            </IconButton>
          </div>
        </div>
        <If condition={this.props.values}>
          <div className="csvs-tags">
            {this.props.values.split(",").map((value) => (
              <FilterSummaryTag
                key={value}
                type="number"
                text={value}
                handleDelete={() => this.deleteValue(value)}
              />
            ))}
          </div>
        </If>
      </div>
    );
  }
}

export default CSVFilter;
