import React from "react";
import PropTypes from "prop-types";

import INREACH_TEAM from "./../../../../../config/inreach_team";
import RaisedButton from "material-ui/RaisedButton";

import TimelineEventActions from "./TimelineEventActions";

import moment from "moment";
import SocialMessagesStore from "../../../../../storage/SocialMessagesStore";

export default class TimelineEventSocialMessage extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    reloadOrganization: PropTypes.func,
  };

  maxLabelLength = 100;

  state = {
    actionLoading: false,
    showReconnectCancelConfirmDialog: false,
  };

  socialMessagesStore = new SocialMessagesStore(this.props.organization.id);

  getLabels = (item) => {
    const {
      person: { name: personName = "Unknown" } = {},
      social_messages: { messages: socialMessages = [] } = {},
    } = item;
    const {
      user_id: userId,
      type: type,
      remind_at: remindAt = null,
    } = socialMessages.reduce(function (prev, current) {
      return prev.y < current.y ? prev : current;
    });
    const userName = (
      INREACH_TEAM.find((person) => person.email === userId) || {}
    ).name;
    switch (item.action_status) {
      case "past":
        switch (type) {
          case "sent":
            return {
              label: <span>Messaged: {personName}</span>,
              subLabel: null,
              actions: null,
            };
          case "snoozed":
            return {
              label: (
                <span>
                  Snoozed reminders: <b>{personName}</b>
                </span>
              ),
              actions: null,
            };
          case "dismiss":
            return {
              label: (
                <span>
                  Dismissed reminders: <b>{personName}</b>
                </span>
              ),
              subLabel: null,
              actions: null,
            };
          case "engaged":
            return {
              label: (
                <span>
                  Engaged: <b>{personName}</b>
                </span>
              ),
              subLabel: null,
              actions: null,
            };
          case "unengaged":
            return {
              label: (
                <span>
                  Unengaged with <b>{personName}</b>
                </span>
              ),
              subLabel: null,
              actions: this.getActions(item),
            };
          default:
            return {
              label: "Don't know this type",
              sublabel: null,
              actions: null,
            };
        }
      case "upcoming":
      case "todo":
        switch (type) {
          case "engaged":
            return {
              label: (
                <span>
                  Engaged: <b>{personName}</b>
                </span>
              ),
              subLabel:
                item.action_status === "upcoming" && remindAt
                  ? this.getSubLabel(remindAt)
                  : null,
              actions: this.getActions(item),
            };
          case "unengaged":
            return {
              label: (
                <span>
                  Unengaged: <b>{personName}</b>
                </span>
              ),
              subLabel:
                item.action_status === "upcoming" && remindAt
                  ? this.getSubLabel(remindAt)
                  : null,
              actions: this.getActions(item),
            };
          case "sent":
            return {
              label: (
                <span>
                  Check messages: <b>{personName}</b>
                </span>
              ),
              subLabel:
                item.action_status === "upcoming" && remindAt
                  ? this.getSubLabel(remindAt)
                  : null,
              actions: this.getActions(item),
            };
          case "snoozed":
            return {
              label: (
                <span>
                  Check messages: <b>{personName}</b>
                </span>
              ),
              subLabel:
                item.action_status === "upcoming" && remindAt
                  ? this.getSubLabel(remindAt)
                  : null,
              actions: this.getActions(item),
            };
        }
      default:
        return {
          label: "",
          subLabel: null,
          actions: null,
        };
    }
  };
  getSubLabel = (remindAt) => {
    return moment(new Date(remindAt)).fromNow(false);
  };
  getActions = (item) => {
    const {
      reloadOrganization,
      organization: { id: organizationId },
    } = this.props;
    const { person: { id: personId = null } = {} } = item;
    const { actionLoading } = this.state;
    if (actionLoading) {
      return [
        {
          text: "Loading",
          loading: true,
        },
      ];
    }
    switch (item.action_status) {
      case "upcoming":
        return [
          {
            text: "Dismiss",
            color: "red",
            onClick: () => {
              this.setState({
                actionLoading: true,
              });
              this.socialMessagesStore
                .sendSocialMessageType(personId, "linkedin", "dismiss")
                .then(() => {
                  this.setState({
                    actionLoading: false,
                  });
                  reloadOrganization(organizationId);
                });
            },
          },
        ];
      case "todo":
        return [
          {
            text: "Snooze",
            onClick: () => {
              this.setState({
                actionLoading: true,
              });
              this.socialMessagesStore
                .sendSocialMessageType(personId, "linkedin", "snoozed")
                .then(() => {
                  const {
                    stage,
                    make_contact_checklist: currentMakeContactChecklist = {},
                  } = this.props.organizationDecision;
                  if (!!stage && stage === "make_contact") {
                    this.props.handleMakeContactChecklistChange(
                      Object.assign({}, currentMakeContactChecklist, {
                        ["send_inmail"]: new Date().getTime(),
                      })
                    );
                  }
                  this.setState({
                    actionLoading: false,
                  });
                  reloadOrganization(organizationId);
                });
            },
          },
          {
            text: "Dismiss",
            color: "red",
            onClick: () => {
              this.setState({
                actionLoading: true,
              });
              this.socialMessagesStore
                .sendSocialMessageType(personId, "linkedin", "dismiss")
                .then(() => {
                  this.setState({
                    actionLoading: false,
                  });
                  reloadOrganization(organizationId);
                });
            },
          },
        ];
      case "past":
        return null;
    }
  };

  buildItem = (item = this.props.item) => {
    const labels = this.getLabels(item);
    return {
      label: labels.label,
      subLabel: labels.subLabel,
      actions: labels.actions,
    };
  };

  render() {
    const { label, subLabel, actions } = this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <h5 className="timeline-event-label">{label}</h5>
        <If condition={subLabel !== null}>
          <p className="timeline-event-sub-label">{subLabel}</p>
        </If>
        <If condition={actions !== null}>
          <TimelineEventActions actions={actions} />
        </If>
      </div>
    );
  }
}
