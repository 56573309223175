import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-v4";
import TextField from "material-ui/TextField";
import ContentFilterList from "material-ui/svg-icons/content/filter-list";
import RaisedButton from "material-ui/RaisedButton";
import Drawer from "material-ui/Drawer";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import Snackbar from "material-ui/Snackbar";
import MuiTheme from "../../lib/MuiTheme";
import auth from "../../storage/Auth";
import _ from "underscore";
import moment from "moment";

import InvestorFilterSet from "../investor/investor_search/InvestorFilterSet";
import OrganizationFilterSet from "./OrganizationFilterSet";
import investorsCategories from "../../content/investorsCategories";
import investorScores from "../../content/investorScores";

const DEFAULT_FILTER_PARAMS = {
  based_in_continent: "Europe",
  is_good_investor: true,
};

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

class SearchFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openFilter: false,
      userHasFullAccess: false,
      openSavedFilterMessage: false,
      savedFilterMessage: "",
    };
  }

  getChildContext() {
    const palette = Object.assign({}, MuiTheme.palette, {
      accent1Color: "#337ab7",
    });
    return {
      muiTheme: getMuiTheme(Object.assign({}, MuiTheme, { palette })),
    };
  }

  onChangeDebounced = _.debounce(this.props.onChange, 50);

  saveCurrentParamsAsDefaultFilters = () => {
    const params = _.omit(this.props.searchParams, "offset");
    auth.setFilters(params).then(() => {
      this.handleShowSaveFilterMessage("Saved as default");
    });
  };

  handleShowSaveFilterMessage = (savedFilterMessage) => {
    this.setState({
      openSavedFilterMessage: true,
      savedFilterMessage: savedFilterMessage,
    });
  };

  handleCloseSaveFilterMessage = () => {
    this.setState({
      openSavedFilterMessage: false,
    });
  };

  handleContinentChange = (event, index, value) => {
    const searchParams = _.omit(
      this.props.searchParams,
      "based_in_continent",
      "based_in_country",
      "based_in_city"
    );
    searchParams.based_in_continent = value || undefined;
    this.props.onChange(searchParams);
  };

  handleCountryChange = (event, index, value) => {
    const searchParams = _.omit(
      this.props.searchParams,
      "based_in_country",
      "based_in_city"
    );
    searchParams.based_in_country = value || undefined;
    this.props.onChange(searchParams);
  };

  handleCityChange = ({ target: { value } }) => {
    this.handleChange("based_in_city", value);
  };

  handleNameChange = ({ target: { value } }) => {
    if (!this.props.searchParams.name) {
      const searchParams = _.omit(
        this.props.searchParams,
        "name",
        "order_by",
        "order",
        "requires_more_info",
        "is_complete",
        "has_predictions",
        "ids",
        "based_in_continent",
        "is_good_investor"
      );

      this.onChangeDebounced({ ...searchParams, name: value });
    } else {
      this.handleChangeDebounced("name", value);
    }
    if (window.mixpanel) {
      mixpanel.track("Searched for organization", {
        query: value,
      });
    }
  };

  handleTermsChange = (terms) => {
    if (!this.props.searchParams.terms) {
      const searchParams = _.omit(
        this.props.searchParams,
        "terms",
        "order_by",
        "order"
      );
      searchParams.terms = terms;
      this.props.onChange(searchParams);
    } else {
      this.handleChange("terms", terms);
    }
  };

  handleCountriesChange = (countries) => {
    if (!this.props.searchParams.based_in_country) {
      const searchParams = _.omit(
        this.props.searchParams,
        "based_in_country",
        "order_by",
        "order"
      );
      searchParams.based_in_country = countries;
      this.props.onChange(searchParams);
    } else {
      this.handleChange("based_in_country", countries);
    }
  };

  handleStateChange = (value) => {
    const searchParams = this.props.searchParams,
      unseenIsSet = searchParams.state && searchParams.state === "unseen";
    if (value.state === "unseen" && unseenIsSet) {
      this.handleChange("state", false);
    } else {
      this.handleChange("state", value.state);
    }
  };

  handleTransitionalStateChange = (value) => {
    const user = this.state.user,
      searchParams = this.props.searchParams,
      unseenIsSet =
        searchParams.by_user === user &&
        searchParams.has_user_decisions === "false";

    if (value.state && value.state !== "unseen") {
      this.handleChanges([
        { key: "by_user", value: user },
        { key: "has_user_decisions", value: "true" },
        { key: "user_state", value: value.state },
      ]);
    } else if (value.state === "unseen" && !unseenIsSet) {
      this.handleChanges([
        { key: "by_user", value: user },
        { key: "has_user_decisions", value: "false" },
        { key: "user_state", value: false },
      ]);
    } else {
      this.handleChanges([
        { key: "by_user", value: false },
        { key: "has_user_decisions", value: false },
        { key: "user_state", value: false },
      ]);
    }
  };

  handleStageChange = (value) => {
    this.handleChange("stage", value);
  };

  handleInvestorChange = ({ target: { value } }) => {
    this.handleChange("investor", value);
  };

  handleFirstInvestmentAfterChange = (defaultParam, moment) => {
    this.handleChange(
      "first_funding_round_announced_on_gt",
      moment ? formatDate(moment) : undefined
    );
  };

  handleFirstInvestmentBeforeChange = (defaultParam, moment) => {
    this.handleChange(
      "first_funding_round_announced_on_lt",
      moment ? formatDate(moment) : undefined
    );
  };

  handleLastInvestmentAfterChange = (defaultParam, moment) => {
    this.handleChange(
      "last_funding_round_announced_on_gt",
      moment ? formatDate(moment) : undefined
    );
  };

  handleLastInvestmentBeforeChange = (defaultParam, moment) => {
    this.handleChange(
      "last_funding_round_announced_on_lt",
      moment ? formatDate(moment) : undefined
    );
  };

  handleFoundedOnBeforeChange = (defaultParam, moment) => {
    this.handleChange(
      "founded_on_before",
      moment ? moment.valueOf() : undefined
    );
  };

  handleFoundedOnAfterChange = (defaultParam, moment) => {
    this.handleChange(
      "founded_on_after",
      moment ? moment.valueOf() : undefined
    );
  };

  handleCreatedAtBeforeChange = (defaultParam, moment) => {
    this.handleChange(
      "created_at_before",
      moment ? moment.valueOf() : undefined
    );
  };

  handleCreatedAtAfterChange = (defaultParam, moment) => {
    this.handleChange(
      "created_at_after",
      moment ? moment.valueOf() : undefined
    );
  };

  handleOrderByChange = (event, index, value) => {
    this.handleChange("order_by", value);
  };

  handleTrendOrganizationMagnitudeChange = (event, index, value) => {
    if (value === "medium") {
      this.handleChanges([
        { key: "trend_organization_magnitude_gt", value: 0.72 },
      ]);
    } else if (value === "high") {
      this.handleChanges([
        { key: "trend_organization_magnitude_gt", value: 0.91 },
      ]);
    } else if (value === "very_high") {
      this.handleChanges([
        { key: "trend_organization_magnitude_gt", value: 0.98 },
      ]);
    } else {
      this.handleChanges([
        { key: "trend_organization_magnitude_gt", value: "" },
        { key: "trend_organization_magnitude_lt", value: "" },
      ]);
    }
  };

  handleTrendOrganizationMagnitudeValue = (gt) => {
    if (gt == 0.7) {
      return "medium";
    } else if (gt == 0.9) {
      return "high";
    } else if (gt == 0.97) {
      return "very_high";
    } else {
      return "all";
    }
  };

  handleTrendOrganizationBetaChange = (event, index, value) => {
    if (value === "medium") {
      this.handleChanges([{ key: "trend_organization_beta_gt", value: 0.45 }]);
    } else if (value === "high") {
      this.handleChanges([{ key: "trend_organization_beta_gt", value: 0.8 }]);
    } else if (value === "very_high") {
      this.handleChanges([{ key: "trend_organization_beta_gt", value: 0.9 }]);
    } else {
      this.handleChanges([
        { key: "trend_organization_beta_gt", value: "" },
        { key: "trend_organization_beta_lt", value: "" },
      ]);
    }
  };

  handleTrendOrganizationBetaValue = (gt) => {
    if (gt == 0.45) {
      return "medium";
    } else if (gt == 0.8) {
      return "high";
    } else if (gt == 0.9) {
      return "very_high";
    } else {
      return "all";
    }
  };

  handleOrderChange = (event, index, value) => {
    this.handleChange("order", value);
  };

  handleSourceChange = (event, index, value) => {
    this.handleChange("source", value);
  };

  handleTagChange = ({ target: { value } }) => {
    this.handleChange("tag", value);
  };

  handleEventChange = ({ target: { value } }) => {
    this.handleChange("event", value);
  };

  handleInvestmentGreaterThanChange = ({ target: { value } }) => {
    this.handleChange("investment_gt", value);
  };

  handleInvestmentLessThanChange = ({ target: { value } }) => {
    this.handleChange("investment_lt", value);
  };

  handleInvestmentsGreaterThanChange = ({ target: { value } }) => {
    this.handleChange("organization_ids_gt", value);
  };

  handleInvestmentsLessThanChange = ({ target: { value } }) => {
    this.handleChange("organization_ids_lt", value);
  };

  handleExitsGreaterThanChange = ({ target: { value } }) => {
    this.handleChange("exits_gt", value);
  };

  handleExitsLessThanChange = ({ target: { value } }) => {
    this.handleChange("exits_lt", value);
  };

  handleNeverPredictionGreaterThanChange = ({ target: { value } }) => {
    this.handleChange("never_prediction_gt", value / 100);
  };

  handleNeverPredictionLessThanChange = ({ target: { value } }) => {
    this.handleChange("never_prediction_lt", value / 100);
  };

  handleNotNowPredictionGreaterThanChange = ({ target: { value } }) => {
    this.handleChange("not_now_prediction_gt", value / 100);
  };

  handleNotNowPredictionLessThanChange = ({ target: { value } }) => {
    this.handleChange("not_now_prediction_lt", value / 100);
  };

  handleContactPredictionGreaterThanChange = ({ target: { value } }) => {
    this.handleChange("contact_prediction_gt", value / 100);
  };

  handleContactPredictionLessThanChange = ({ target: { value } }) => {
    this.handleChange("contact_prediction_lt", value / 100);
  };

  handleShowPredictionChange = (value) => {
    this.handleChange("show_predictions", value);
  };

  handleShowClassificationsChange = (value) => {
    this.handleChange("show_classifications", value);
  };

  handleInboxChange = (e, value) => {
    this.handleChange("inbox", value);
  };

  handleClassificationChange = (value) => {
    this.handleChange("classification", value.state);
  };

  handleSpamChange = (value) => {
    if (value) {
      this.handleChange("classification_not", "never,missed_opportunity");
    } else {
      this.handleChange("classification_not", false);
    }
  };

  handleRequiresMoreInfoChange = (value) => {
    this.handleChange("requires_more_info", value ? "true" : "false");
  };

  handleIsCompleteChange = (value) => {
    this.handleChange("is_complete", value ? "false" : "true");
  };

  handleChange = (key, value) => {
    const searchParams = _.omit(this.props.searchParams, key);
    if (value) {
      searchParams[key] = value;
    }
    this.props.onChange(searchParams);
  };

  handleChangeDebounced = _.debounce(this.handleChange, 50);

  handleChanges = (params) => {
    let searchParams = this.props.searchParams;
    params.forEach((queryParam) => {
      const value = queryParam.value,
        key = queryParam.key;
      searchParams = _.omit(searchParams, key);
      if (key && value) {
        searchParams[key] = value;
      }
    });

    this.props.onChange(searchParams);
  };

  handleClearFilter = () => {
    this.props.onChange(DEFAULT_FILTER_PARAMS);
  };

  handleToggleFilter = () => {
    this.setState({ openFilter: !this.state.openFilter });
  };

  handleCloseFilter = () => {
    this.setState({ openFilter: false });
  };

  handleHighlightChange = (event, index, highlights) => {
    this.handleChange("highlights", highlights.join(","));
  };

  handleInvestmentTypesChange = (event, index, investmentTypes) => {
    this.handleChange("investment_types", investmentTypes.join(","));
  };
  handleInvestorScoreHigherChange = (event, index, investorHigherScore) => {
    const investorScoreCategory = investorScores[investorHigherScore];
    this.handleChange("ml_investor_score_gt", investorScoreCategory.min);
  };
  handleInvestorScoreLowerChange = (event, index, investorLowerScore) => {
    const investorScoreCategory = investorScores[investorLowerScore];
    this.handleChange("ml_investor_score_lt", investorScoreCategory.max);
  };
  filterAnyWord = (selection) => {
    return selection.filter(
      (word) => word !== "a" && word !== "n" && word !== "y"
    );
  };

  handleMostInvestedTypeChange = (event, index, mostInvestedTypes) => {
    this.handleChange(
      "most_invested_investment_type",
      this.filterAnyWord(mostInvestedTypes).join(",")
    );
  };
  handleInvestorTypeChange = (event, index, investorType) => {
    this.handleChange(
      "investor_type",
      this.filterAnyWord(investorType).join(",")
    );
  };
  handleInvestorMLTypeChange = (event, index, investorMLType) => {
    this.handleChange(
      "ml_investor_type",
      this.filterAnyWord(investorMLType).join(",")
    );
  };

  handleHighlightsGreaterThanChange = ({ target: { value } }) => {
    this.handleChange("highlights_gt", value);
  };

  handleHighlightsLessThanChange = ({ target: { value } }) => {
    this.handleChange("highlights_lt", value);
  };

  handleIdsChange = (ids) => {
    this.handleChange("ids", ids);
  };

  handleMLTagChange = (tag) => {
    const tags = (this.props.searchParams.tag || "").split(",").filter(Boolean);

    this.handleChange("tag", [...tags, tag].sort().join(","));
  };

  handleMLTagRemove = (tag) => {
    const tags = (this.props.searchParams.tag || "").split(",").filter(Boolean);

    this.handleChange("tag", tags.filter((t) => t !== tag).join(","));
  };
  handleMostInvestedTagsChange = (tag) => {
    const tags = (this.props.searchParams.most_invested_tags || "")
      .split(",")
      .filter(Boolean);

    this.handleChange("most_invested_tags", [...tags, tag].sort().join(","));
  };

  handleMostInvestedTagsRemove = (tag) => {
    const tags = (this.props.searchParams.most_invested_tags || "")
      .split(",")
      .filter(Boolean);

    this.handleChange(
      "most_invested_tags",
      tags.filter((t) => t !== tag).join(",")
    );
  };
  getTransitionalState = () => {
    const { searchParams } = this.props;
    if (searchParams.has_user_decisions === "false") {
      return "unseen";
    }
    return this.props.searchParams.user_state;
  };

  componentDidMount() {
    auth
      .getAuthData()
      .then(({ accessType, profile: { email } }) => {
        this.setState({
          userHasFullAccess: accessType === "full",
          user: email,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  handleShowOnlyGoodInvestorChange = (value) => {
    this.handleChange("is_good_investor", value);
  };

  handleShowOnlyTopTierChange = (value) => {
    this.handleChange("is_top_tier", value);
  };

  componentDidUpdate() {
    if (
      !this.props.searchParams.based_in_continent &&
      document.getElementById("based_in_continent")
    ) {
      document.getElementById("based_in_continent").value = "";
    }
    if (
      !this.props.searchParams.name &&
      document.getElementsByName("query").lenth > 0
    ) {
      if (document.getElementsByName("query")[0]) {
        document.getElementsByName("query")[0].value = "";
      }
    }
  }

  render() {
    const {
      searchParams,
      selectedFiltersSet,
      updateFiltersSets,
      handleDeselectFiltersSet,
      handleSelectFiltersSet,
      handleDeleteFiltersSet,
      filtersSets,
      exportToCSV,
    } = this.props;
    const {
      openFilter,
      userHasFullAccess,
      openSavedFilterMessage,
      savedFilterMessage,
    } = this.state;
    const orderByValue = searchParams.order_by
        ? searchParams.order_by
        : searchParams.query
        ? "score"
        : "founded_on",
      highlights = searchParams.highlights
        ? searchParams.highlights.split(",")
        : [],
      investmentTypes = searchParams.investment_types
        ? searchParams.investment_types.split(",")
        : [],
      investorType = searchParams.investor_type
        ? searchParams.investor_type.split(",")
        : undefined,
      ml_investor_score_gt = searchParams.ml_investor_score_gt,
      ml_investor_score_lt = searchParams.ml_investor_score_lt,
      mostInvestedTypes = searchParams.most_invested_investment_type
        ? searchParams.most_invested_investment_type.split(",")
        : undefined;
    const { client } = this.props;
    const mostInvestedTags = (searchParams.most_invested_tags || "")
      .split(",")
      .filter(Boolean);
    const tags = (searchParams.tag || "").split(",").filter(Boolean);
    const { filtersType = "organizations", router } = this.props;
    const investorScoreHigherCategory = Object.entries(investorScores).filter(
      ([name, score]) => score.min === parseFloat(ml_investor_score_gt)
    );
    const investorScoreLowerCategory = Object.entries(investorScores).filter(
      ([name, score]) => score.max === parseFloat(ml_investor_score_lt)
    );
    const investorHigherScore = investorScoreHigherCategory.length
      ? investorScoreHigherCategory[0][0]
      : "none";
    const investorLowerScore = investorScoreLowerCategory.length
      ? investorScoreLowerCategory[0][0]
      : "none";
    return (
      <div className="search-filters">
        <i className="search-icon" aria-hidden="true" />
        <TextField
          className="input-search"
          hintText={
            filtersType === "organizations"
              ? "Search for Company Name"
              : "Search for Investor Name"
          }
          ref={(searchQueryTextfield) => {
            if (
              searchQueryTextfield &&
              !searchParams.name &&
              searchQueryTextfield.input.value
            ) {
              searchQueryTextfield.setState({
                hasValue: false,
              });
              searchQueryTextfield.input.value = null;
            }
          }}
          defaultValue={searchParams.name}
          onChange={this.handleNameChange}
          underlineFocusStyle={{ borderColor: "#337ab7" }}
        />
        <RaisedButton
          label="Filters"
          labelPosition="before"
          primary={true}
          icon={<ContentFilterList className="filter-icon" />}
          className="add-filter-button"
          onClick={this.handleToggleFilter}
        />
        <Drawer
          docked={false}
          width={680}
          className="filter-drawer"
          open={openFilter}
          onRequestChange={(openFilter) => this.setState({ openFilter })}
          openSecondary={true}
          overlayStyle={{ background: "black", opacity: 0.6 }}
          containerStyle={{ overflowX: "hidden" }}
        >
          <If condition={filtersType === "investors"}>
            <InvestorFilterSet
              searchParams={searchParams}
              mostInvestedTypes={mostInvestedTypes}
              mostInvestedTags={mostInvestedTags}
              investorType={investorType}
              investorHigherScore={investorHigherScore}
              investorLowerScore={investorLowerScore}
              handleShowOnlyTopTier={this.handleShowOnlyTopTierChange}
              handleShowOnlyGoodInvestor={this.handleShowOnlyGoodInvestorChange}
              handleMostInvestedTypeChange={this.handleMostInvestedTypeChange}
              handleMostInvestedTagsChange={this.handleMostInvestedTagsChange}
              handleMostInvestedTagsRemove={this.handleMostInvestedTagsRemove}
              handleInvestorMLTypeChange={this.handleInvestorMLTypeChange}
              handleInvestorTypeChange={this.handleInvestorTypeChange}
              handleInvestorScoreHigherChange={
                this.handleInvestorScoreHigherChange
              }
              handleInvestorScoreLowerChange={
                this.handleInvestorScoreLowerChange
              }
              handleClearFilter={this.handleClearFilter}
              handleCloseFilter={this.handleCloseFilter}
              orderByValue={orderByValue}
              handleOrderChange={this.handleOrderChange}
              handleOrderByChange={this.handleOrderByChange}
              handleInvestmentsLessThanChange={
                this.handleInvestmentsLessThanChange
              }
              handleInvestmentsGreaterThanChange={
                this.handleInvestmentsGreaterThanChange
              }
              handleExitsLessThanChange={this.handleExitsLessThanChange}
              handleExitsGreaterThanChange={this.handleExitsGreaterThanChange}
              handleContinentChange={this.handleContinentChange}
              handleCountriesChange={this.handleCountriesChange}
              handleCityChange={this.handleCityChange}
              handleFirstInvestmentAfterChange={
                this.handleFirstInvestmentAfterChange
              }
              handleFirstInvestmentBeforeChange={
                this.handleFirstInvestmentBeforeChange
              }
              handleLastInvestmentAfterChange={
                this.handleLastInvestmentAfterChange
              }
              handleLastInvestmentBeforeChange={
                this.handleLastInvestmentBeforeChange
              }
            />
          </If>
          <If condition={filtersType === "organizations" && true}>
            <OrganizationFilterSet
              client={client}
              tags={tags}
              orderByValue={orderByValue}
              highlights={highlights}
              getTransitionalState={this.getTransitionalState}
              investmentTypes={investmentTypes}
              searchParams={searchParams}
              handleClearFilter={this.handleClearFilter}
              handleCloseFilter={this.handleCloseFilter}
              selectedFiltersSet={selectedFiltersSet}
              updateFiltersSets={updateFiltersSets}
              handleDeselectFiltersSet={handleDeselectFiltersSet}
              handleSelectFiltersSet={handleSelectFiltersSet}
              handleDeleteFiltersSet={handleDeleteFiltersSet}
              filtersSets={filtersSets}
              handleOrderByChange={this.handleOrderByChange}
              handleOrderChange={this.handleOrderChange}
              handleIdsChange={this.handleIdsChange}
              handleInvestmentTypesChange={this.handleInvestmentTypesChange}
              handleTermsChange={this.handleTermsChange}
              handleMLTagChange={this.handleMLTagChange}
              handleMLTagRemove={this.handleMLTagRemove}
              handleTrendOrganizationMagnitudeChange={
                this.handleTrendOrganizationMagnitudeChange
              }
              handleTrendOrganizationMagnitudeValue={
                this.handleTrendOrganizationMagnitudeValue
              }
              handleTrendOrganizationBetaChange={
                this.handleTrendOrganizationBetaChange
              }
              handleHighlightChange={this.handleHighlightChange}
              handleFoundedOnAfterChange={this.handleFoundedOnAfterChange}
              handleFoundedOnBeforeChange={this.handleFoundedOnBeforeChange}
              handleCreatedAtAfterChange={this.handleCreatedAtAfterChange}
              handleCreatedAtBeforeChange={this.handleCreatedAtBeforeChange}
              handleChanges={this.handleChanges}
              handleInvestmentGreaterThanChange={
                this.handleInvestmentGreaterThanChange
              }
              handleStateChange={this.handleStateChange}
              handleInvestmentLessThanChange={
                this.handleInvestmentLessThanChange
              }
              handleNeverPredictionGreaterThanChange={
                this.handleNeverPredictionGreaterThanChange
              }
              handleNeverPredictionLessThanChange={
                this.handleNeverPredictionLessThanChange
              }
              handleNotNowPredictionGreaterThanChange={
                this.handleNotNowPredictionGreaterThanChange
              }
              handleNotNowPredictionLessThanChange={
                this.handleNotNowPredictionLessThanChange
              }
              handleContactPredictionGreaterThanChange={
                this.handleContactPredictionGreaterThanChange
              }
              handleContactPredictionLessThanChange={
                this.handleContactPredictionLessThanChange
              }
              saveCurrentParamsAsDefaultFilters={
                this.saveCurrentParamsAsDefaultFilters
              }
              handleTrendOrganizationBetaValue={
                this.handleTrendOrganizationBetaValue
              }
              exportToCSV={exportToCSV}
              handleChange={this.handleChange}
              userHasFullAccess={userHasFullAccess}
              handleStageChange={this.handleStageChange}
              handleContinentChange={this.handleContinentChange}
              handleCountriesChange={this.handleCountriesChange}
              handleCityChange={this.handleCityChange}
              handleInvestorChange={this.handleInvestorChange}
              handleSourceChange={this.handleSourceChange}
              handleEventChange={this.handleEventChange}
              handleInboxChange={this.handleInboxChange}
              handleClassificationChange={this.handleClassificationChange}
              handleSpamChange={this.handleSpamChange}
              handleRequiresMoreInfoChange={this.handleRequiresMoreInfoChange}
              handleIsCompleteChange={this.handleIsCompleteChange}
              handleShowPredictionChange={this.handleShowPredictionChange}
              handleShowClassificationsChange={
                this.handleShowClassificationsChange
              }
              handleTransitionalStateChange={this.handleTransitionalStateChange}
            />
          </If>
        </Drawer>
        <Snackbar
          open={openSavedFilterMessage}
          message={savedFilterMessage}
          autoHideDuration={4000}
          onRequestClose={this.handleCloseSaveFilterMessage}
        />
      </div>
    );
  }
}

SearchFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  client: PropTypes.string.isRequired,
};

SearchFilters.childContextTypes = {
  muiTheme: PropTypes.object.isRequired,
};

export default SearchFilters;
