import auth from "../storage/Auth";
import Config from "../config";
import React from "react";
import request from "superagent";

export default class QualificationStore {
  constructor(id) {
    this.url = `${Config.api.host}/organizations/${id}/qualification`;
  }

  getQualification = () => {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(this.url).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then((response) => response.body);
  };

  createQualificationChecklist = () => {
    return auth
      .getAuthData()
      .then((authData) =>
        request.post(this.url).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then((response) => response.body);
  };

  updateTask = (categoryKey, taskKey, changedTask) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(`${this.url}/${categoryKey}/${taskKey}`)
          .send(changedTask)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then((response) => response.body);
  };

  updateSubTask = (categoryKey, taskKey, updatedSubTask) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(
            `${this.url}/${categoryKey}/${taskKey}/subtasks/${updatedSubTask.id}`
          )
          .send(updatedSubTask)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then((response) => response.body);
  };

  deleteSubTask = (categoryKey, taskKey, subTaskKey) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .delete(
            `${this.url}/${categoryKey}/${taskKey}/subtasks/${subTaskKey}`
          )
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then((response) => response.body);
  };

  deleteFile = (categoryKey, taskKey, fileKey) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .delete(`${this.url}/${categoryKey}/${taskKey}/files/${fileKey}`)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then((response) => response.body);
  };

  uploadFile = (categoryKey, taskKey, file) => {
    const formData = new FormData();
    formData.append("file", file);
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(`${this.url}/${categoryKey}/${taskKey}/files`)
          .send(formData)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then((response) => response.body);
  };

  addFileToTask = (categoryKey, taskKey, fileId) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(`${this.url}/${categoryKey}/${taskKey}/files/${fileId}`)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
      )
      .then((response) => response.body);
  };
}
