import React from "react";
import PropTypes from "prop-types";

import Config from "../../../../../config";
import inboxReasons from "../../../../../content/inboxReasons";

import TimelineEventInboxBody from "./TimelineEventInboxBody";
import { truncateString } from "../../../../../lib/helpers";

import moment from "moment";
import xss from "xss";

export default class TimelineEventInbox extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    getUserName: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
  };

  maxLabelLength = 110;

  buildItem = (item = this.props.item, type = this.props.type) => {
    const inbox = item.inbox;
    const createdAt = moment(item.keen.timestamp || inbox.created_at).format(
      "dddd Do MMMM YYYY"
    );
    const labels =
      type === "inbox" || !inbox.inbox
        ? this.getInboxLabels(inbox, createdAt)
        : this.getFormLabels(
            inbox.reason,
            inbox.notes || null,
            inbox.assigned_to || null,
            createdAt
          );
    return {
      label: labels.label,
      subLabel: labels.subLabel || null,
      notes: inbox.notes || null,
      createdAt: createdAt,
      actions: type === "inbox" ? null : this.getFormActions(inbox),
    };
  };

  getInboxLabels = (inbox, createdAt) => {
    const label = this.buildLabel(inbox, inbox.reason);
    const subLabel = inbox.notes
      ? this.buildSubLabel(createdAt, inbox.notes, label.length)
      : null;
    return {
      label,
      subLabel,
    };
  };

  buildLabel = (inbox, reason) => {
    if (inbox.inbox) {
      return inbox.user_id &&
        inbox.user_id !== "dig@inreachventures.com" &&
        inbox.user_id !== "ml@inreachventures.com"
        ? reason
          ? `${this.props.getUserName(
              inbox.user_id
            )} > <i>${this.props.getUserName(
              inbox.assigned_to
            )}</i>'s inbox: <b>${this.getInboxReason(reason)}</b>`
          : `${this.props.getUserName(
              inbox.user_id
            )} > <b>${this.props.getUserName(inbox.assigned_to)}</b>'s inbox`
        : reason
        ? `Added to <i>${this.props.getUserName(
            inbox.assigned_to
          )}</i>'s inbox: <b>${this.getInboxReason(reason)}</b>`
        : `Added to <b>${this.props.getUserName(
            inbox.assigned_to
          )}</b>'s inbox`;
    } else {
      return reason
        ? `Removed from <i>${this.props.getUserName(
            inbox.assigned_to
          )}</i>'s inbox: <b>${this.getInboxReason(reason)}</b>`
        : `Removed from <b>${this.props.getUserName(
            inbox.assigned_to
          )}</b>'s inbox`;
    }
  };

  getFormLabels = (reason, notes, assignedTo, createdAt) => {
    let label, subLabel;
    switch (reason) {
      case "FORM_PARTIAL":
        label = `Partial Form Received`;
        break;
      case "FORM_UPDATED":
        label = `Updated Form Received`;
        break;
      case "FORM":
        label = `Complete Form Received`;
        break;
      case "MISSED_OPPORTUNITY":
        label = `${this.props.getUserName(
          assignedTo
        )}: <b>${this.getInboxReason(reason)}</b>`;
        subLabel = notes ? `- ${createdAt} - ${notes}` : `- ${createdAt}`;
        break;
      default:
        label = reason;
        break;
    }
    return {
      label,
      subLabel,
    };
  };

  buildSubLabel = (createdAt, note, labelLength) => {
    const subLabel =
      this.props.section === "todo" ? `- ${createdAt} - ${note}` : `- ${note}`;
    return truncateString(subLabel, this.maxLabelLength, labelLength);
  };

  getInboxReason = (reason) => {
    return inboxReasons[reason.toUpperCase()];
  };

  getFormActions = (inbox) => {
    return [
      {
        text: "Open Scorecard",
        href: `${Config.web.host}/organizations/${inbox.organization_id}/scorecard`,
      },
    ];
  };

  render() {
    const { section } = this.props;
    const { label, subLabel, notes, createdAt, actions } = this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <h5
          className="timeline-event-label"
          dangerouslySetInnerHTML={{ __html: xss(label) }}
        />
        <If condition={subLabel !== null}>
          <p className="timeline-event-sub-label">{subLabel}</p>
        </If>
        <div className="spacer" />
        {/*<If condition={actions !== null}>*/}
        {/*<TimelineEventActions actions={actions} />*/}
        {/*</If>*/}
        <TimelineEventInboxBody
          createdAt={createdAt}
          notes={notes}
          section={section}
        />
      </div>
    );
  }
}
