import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import ActionDelete from "material-ui/svg-icons/action/delete";
import Dialog from "material-ui/Dialog";
import DialogWrapper from "../../components/DialogWrapper";

class DeleteOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.showConfirmDeleteDialog = this.showConfirmDeleteDialog.bind(this);
    this.hideConfirmDeleteDialog = this.hideConfirmDeleteDialog.bind(this);
  }

  showConfirmDeleteDialog() {
    this.setState({
      open: true,
    });
  }

  hideConfirmDeleteDialog() {
    this.setState({
      open: false,
    });
  }

  render() {
    const actions = [
      <RaisedButton
        className="assign-action"
        label="Cancel"
        style={{
          marginRight: "10px",
        }}
        onClick={this.hideConfirmDeleteDialog}
      />,
      <RaisedButton
        children={<span style={{ color: "white" }}>CONFIRM</span>}
        className="assign-action"
        backgroundColor="#5DAB49"
        labelStyle={{
          color: "white",
        }}
        onClick={this.props.onDeleteOrganization}
      />,
    ];

    return (
      <div className="delete-organization">
        <RaisedButton
          label="Delete organization"
          backgroundColor="#C30000"
          labelColor="#fff"
          icon={<ActionDelete color="white" />}
          onClick={this.showConfirmDeleteDialog}
        />
        <DialogWrapper
          title="Confirm organization deletion"
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.hideConfirmDeleteDialog}
        >
          Are you sure you want to delete this organization?
        </DialogWrapper>
      </div>
    );
  }
}

export default DeleteOrganization;
