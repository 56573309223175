import SearchParamsHelper from "../lib/SearchParamsHelper";

import request from "superagent";
import auth from "./Auth";
import Config from "../config";
import _ from "underscore";
import Q from "q";

export var PeopleStore = function () {
  this.cache = [];
  this.loading = false;
  this.nextOffset = 0;
  this.searchParams = {};

  this.initialize = function (options) {
    if (
      !SearchParamsHelper.arePropsEqual(
        options.searchParams,
        this.searchParams
      ) ||
      this.nextOffset < options.offset
    ) {
      this.size = undefined;
      this.cache = [];
      this.searchParams = options.searchParams;
      this.nextOffset = options.offset || 0;
      this.loading = false;
    }
  }.bind(this);

  this.generateParams = function (idToken, client, offset, limit) {
    const params = _.extend(
      {
        access_token: idToken,
        client: client,
        limit: limit,
        offset: offset,
        order_by: "created_at",
        order: "desc",
      },
      this.searchParams
    );
    if (params.skills) {
      params.skills = params.skills.split(",").map((skill) => {
        return skill.trim();
      });
    }
    return params;
  }.bind(this);

  this.create = function (person) {
    return new Promise((resolve, reject) => {
      auth.getAuthData().then((authData) => {
        request
          .post(`${Config.api.host}/people`)
          .send(person)
          .query({
            access_token: authData.token,
            client: authData.client,
          })
          .end((error, response) => {
            if (response && response.ok) {
              resolve(response.body);
            } else {
              reject(error);
            }
          });
      });
    });
  };

  this.delete = function (id) {
    return auth
      .getAuthData()
      .then((authData) =>
        request.delete(`${Config.api.host}/people/${id}`).query({
          access_token: authData.token,
          client: authData.client,
        })
      )
      .then((response) => response.body);
  };

  this.fetch = function fetch(offset) {
    var limit = 30,
      defer = Q.defer(),
      nextOffset;

    if (this.cache[offset] && this.cache[this.nextOffset - 1]) {
      defer.resolve(this.nextOffset);
    }

    if (this.nextOffset > offset) {
      limit = this.nextOffset + limit;
    }

    nextOffset = offset + limit;

    if (this.cache[nextOffset - 1]) {
      defer.resolve(nextOffset);
    }
    if (typeof this.size !== "undefined" && this.size <= offset) {
      defer.resolve(offset);
    }
    if (this.loading) {
      defer.resolve(nextOffset);
    }
    this.loading = true;
    this.nextOffset = nextOffset;

    auth.getAuthData().then((authData) => {
      var people, i;
      request
        .get(Config.api.host + "/people")
        .query(
          this.generateParams(authData.token, authData.client, offset, limit)
        )
        .end(
          function (error, response) {
            if (response && response.ok) {
              people = response.body.people || [];
              for (i = 0; i < people.length; i++) {
                this.cache[offset + i] = people[i];
              }
              this.size = response.body.total;
              this.loading = false;
              defer.resolve(nextOffset);
            }
          }.bind(this)
        );
    });

    return defer.promise;
  }.bind(this);

  this.isLoading = function isLoading() {
    return this.loading;
  }.bind(this);

  this.replaceModel = function replaceModel(model) {
    var i;
    for (i = 0; i < this.cache.length; i++) {
      if (this.cache[i].id === model.id) {
        this.cache[i] = model;
        break;
      }
    }

    return this.cache;
  }.bind(this);

  this.removeModel = function removeModel(id) {
    var i;
    for (i = 0; i < this.cache.length; i++) {
      if (this.cache[i].id === id) {
        this.cache.splice(i, 1);
        break;
      }
    }

    return this.cache;
  }.bind(this);

  this.getAll = function getAll() {
    return this.cache;
  }.bind(this);

  this.getSize = function getSize() {
    return this.size === undefined ? 0 : this.size;
  }.bind(this);

  return this;
};

export default new PeopleStore();
