import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-v4";
import convertedLegacyInsights from "../../content/convertedLegacyInsights";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import CardBody from "../card/CardBody";
import InvestorSummaryRow from "./InvestorSummaryRow";
import Description from "../organization/profile/summary/Description";

class InvestorSummarySection extends React.Component {
  static propTypes = {
    organization: PropTypes.object,
    investor: PropTypes.object.isRequired,
    tabsContent: PropTypes.object,
  };

  buildSummary = () => {
    return {
      companyInfo: this.getCompanyInfoData(),
      description: this.getDescriptionData(),
      tags: this.getTagsData(),
      people: this.getPeopleData(),
    };
  };

  getCompanyInfoData = () => {
    return {
      founded: this.getFoundedOn(),
      locations: this.getLocations(),
      fundingRounds: this.getFundingRounds(),
      contacts: this.getContacts(),
    };
  };

  getFoundedOn = () => {
    const {
      organization: {
        created_at: createdAt = 0,
        founded_on: foundedOn = 0,
      } = {},
    } = this.props;
    return {
      on: foundedOn,
      firstSeen: createdAt,
    };
  };

  getLocations = () => {
    const { organization: { locations = [] } = {} } = this.props;
    return {
      locations,
    };
  };

  getFundingRounds = () => {
    const { fundingRounds = [] } = this.props;
    return {
      fundingRounds,
    };
  };

  getContacts = () => {
    const {
      organization: {
        num_employees_min: minEmployees = 0,
        num_employees_max: maxEmployees = 0,
        person_ids: personIds = [],
      } = {},
    } = this.props;
    return {
      min: minEmployees,
      max: maxEmployees,
      inDIG: personIds.length,
    };
  };

  getDescriptionData = () => {
    const { organization: { description = "", short_description = "" } = {} } =
      this.props;
    const {
      investor: {
        description: investorDescription = "",
        short_description: investorShortDescription = "",
      } = {},
    } = this.props;
    const combinedDescription =
      description !== "" ? description : investorDescription;
    const combinedShortDescription =
      short_description !== "" ? short_description : investorShortDescription;
    return {
      description: combinedDescription,
      shortDescription: combinedShortDescription,
    };
  };

  getTagsData = () => {
    const {
      organization: {
        ml_features: { tags_prediction: { score = 0, tags = {} } = {} } = {},
      } = {},
    } = this.props;
    const min = 0.3309008660410637;
    const max = 0.728999904772718;
    return {
      tags,
      highlighted: Math.max(Math.min((score - min) / (max - min), 1), 0) > 0.8,
    };
  };

  getPeopleData = () => ({
    ...this.getSectionInsights("people"),
  });

  getSectionInsights = (section, snapshot = false) => {
    const {
      organization: {
        ml_features: {
          ui_ranking: { [section]: uiRankingSection = [] } = {},
        } = {},
      } = {},
    } = this.props;
    if (!snapshot && uiRankingSection.length === 0) {
      return this.getLegacyPeopleInsights(snapshot);
    } else {
      return uiRankingSection.reduce(
        (
          {
            highlights: uniqueHighlights,
            risks: uniqueRisks,
            redFlags: uniqueRedFlags,
          },
          {
            matched_highlights: highlights = [],
            matched_risks: risks = [],
            matched_red_flags: redFlags = [],
          }
        ) => {
          highlights.forEach((highlight) => {
            if (
              !uniqueHighlights.find(
                ({ insight_id: uniqueHighlightId }) =>
                  uniqueHighlightId === highlight.insight_id
              )
            ) {
              uniqueHighlights.push(highlight);
            }
          });
          risks.forEach((risk) => {
            if (
              !uniqueRisks.find(
                ({ insight_id: uniqueRiskId }) =>
                  uniqueRiskId === risk.insight_id
              )
            ) {
              uniqueRisks.push(risk);
            }
          });
          redFlags.forEach((redFlag) => {
            if (
              !uniqueRedFlags.find(
                ({ insight_id: uniqueRedFlagId }) =>
                  uniqueRedFlagId === redFlag.insight_id
              )
            ) {
              uniqueRedFlags.push(redFlag);
            }
          });
          return {
            highlights: uniqueHighlights,
            risks: uniqueRisks,
            redFlags: uniqueRedFlags,
          };
        },
        {
          highlights: [],
          risks: [],
          redFlags: [],
        }
      );
    }
  };

  getLegacyPeopleInsights = (snapshot) => {
    const {
      organization: {
        ml_features: {
          highlights: { highlights: legacyHighlights = {} } = {},
        } = {},
      } = {},
      snapshot: {
        organization: {
          ml_features: {
            highlights: { highlights: snapshotLegacyHighlights = {} } = {},
          } = {},
        } = {},
      } = {},
    } = this.props;
    return Object.entries(
      !snapshot ? legacyHighlights : snapshotLegacyHighlights
    ).reduce(
      ({ highlights, risks, redFlags }, [highlight]) => {
        if (
          highlight !== "tech_keywords" &&
          highlight !== "trend" &&
          highlight !== "investors"
        ) {
          highlights.push(convertedLegacyInsights[highlight]);
        }
        return {
          highlights,
          risks,
          redFlags,
        };
      },
      { highlights: [], risks: [], redFlags: [] }
    );
  };

  render() {
    const { isTop } = this.props;
    const { companyInfo, description } = this.buildSummary();
    return (
      <div className="summary-section">
        <Card>
          <CardHeader title="Summary" />
          <CardBody>
            <div className="grid-container investor-summary-grid-container">
              <div className="investor-summary-grid-item investor-info-grid-item">
                <InvestorSummaryRow
                  companyInfo={companyInfo}
                  {...this.props}
                  isTop={true}
                />
              </div>
              <div className="investor-summary-grid-item description-grid-item">
                <Description description={description} compressed={false} />
              </div>
              <div className="investor-summary-grid-item investor-info-2-grid-item">
                <InvestorSummaryRow
                  companyInfo={companyInfo}
                  {...this.props}
                  isTop={false}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(InvestorSummarySection);
