import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import WebProduct from "./product/WebProduct";
import AppProduct from "./product/AppProduct";

class KeyProducts extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    signalsLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool.isRequired,
    mlUpdates: PropTypes.array,
    signals: PropTypes.object,
  };

  render() {
    const {
      organization,
      products = [],
      signalsLoading,
      signals,
      useSnapshot = false,
      mlUpdates,
      organizationDecision,
    } = this.props;
    return (
      <div className="key-products">
        <div className="section-title main">Key Products</div>
        <Choose>
          <When condition={products.length === 0}>
            <div className="section-no-content">No Key Products</div>
          </When>
          <Otherwise>
            <div className="products-container">
              {products.map((product) => (
                <Choose>
                  <When condition={product.type === "web"}>
                    <WebProduct
                      organization={organization}
                      product={product}
                      source="other"
                      key={product.id}
                      signalsLoading={signalsLoading}
                      signals={signals}
                      useSnapshot={useSnapshot}
                      mlUpdates={mlUpdates}
                      organizationDecision={organizationDecision}
                    />
                  </When>
                  <Otherwise>
                    <AppProduct
                      organization={organization}
                      product={product}
                      source="other"
                      key={product.id}
                      signalsLoading={signalsLoading}
                      signals={signals}
                      useSnapshot={useSnapshot}
                      mlUpdates={mlUpdates}
                      organizationDecision={organizationDecision}
                    />
                  </Otherwise>
                </Choose>
              ))}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(KeyProducts);
