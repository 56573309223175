import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import ToggleStar from "material-ui/svg-icons/toggle/star";
import ToggleStarBorder from "material-ui/svg-icons/toggle/star-border";
import PhoneIPhone from "material-ui/svg-icons/hardware/phone-iphone";

import {
  getDataWrapperClassNames,
  getRoundedUpValue,
} from "../../../lib/helpers";
import Tooltip from "../../Tooltip";

function Stars(props) {
  const { rating } = props;

  function getRatingPercentage(rating) {
    const max = 5;
    return Math.round((rating / max) * 100);
  }

  return (
    <div className="stars-wrapper">
      <div
        className="stars-full-wrapper"
        style={{
          width: `${getRatingPercentage(rating)}%`,
        }}
      >
        <ToggleStar className="star" />
        <ToggleStar className="star" />
        <ToggleStar className="star" />
        <ToggleStar className="star" />
        <ToggleStar className="star" />
      </div>
      <div className="stars-outline-wrapper">
        <ToggleStarBorder className="star border" />
        <ToggleStarBorder className="star border" />
        <ToggleStarBorder className="star border" />
        <ToggleStarBorder className="star border" />
        <ToggleStarBorder className="star border" />
      </div>
    </div>
  );
}

class Ratings extends React.Component {
  static propTypes = {
    ratings: PropTypes.object.isRequired,
    productType: PropTypes.string.isRequired,
    showTitle: PropTypes.bool,
    compressed: PropTypes.bool.isRequired,
  };

  state = {
    showTooltip: false,
  };

  getTooltipText = () => {
    const {
      ratings: { ratingCount },
      compressed = false,
    } = this.props;
    const text = [];
    if (compressed) {
      text.push("App Analytics");
    }
    text.push(`${ratingCount} ratings`);
    return text.join("<br/>");
  };

  render() {
    const {
      ratings: { rating, ratingCount },
      productType,
      showTitle = true,
      compressed = false,
    } = this.props;
    const { showTooltip = false } = this.state;
    return (
      <div
        className={getDataWrapperClassNames(compressed)}
        onMouseLeave={() => {
          if (compressed) {
            this.setState({ showTooltip: false });
          }
        }}
        onMouseEnter={() => {
          if (compressed) {
            this.setState({ showTooltip: true });
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <Choose>
          <When condition={productType === "web"}>
            <If condition={showTitle}>
              <div className="data-header">Web ratings</div>
            </If>
            <div className="data-content text empty">Not available</div>
          </When>
          <Otherwise>
            <If condition={showTitle}>
              <div className="data-header">App ratings</div>
            </If>
            <If condition={compressed}>
              <Tooltip
                parent={this._parent}
                showTooltip={showTooltip}
                text={this.getTooltipText()}
                customClasses={["app-analytics-tooltip"]}
              />
            </If>
            <Choose>
              <When condition={ratingCount}>
                <div className="data-icon">
                  <PhoneIPhone className="data-icon-svg" />
                </div>
                <div className="data-content component ratings">
                  <div className="ratings-wrapper">
                    <Stars rating={rating} />
                    <If condition={!compressed}>
                      <div className="rating-count">
                        {getRoundedUpValue(ratingCount)}
                      </div>
                    </If>
                  </div>
                </div>
              </When>
              <Otherwise>
                <div className="data-content text empty">No data</div>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(Ratings);
