import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import { List } from "material-ui/List";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import CircularProgress from "material-ui/CircularProgress";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import SelectPerson from "../assign/SelectPerson";

import inboxHelper from "../../lib/InboxHelper";

import inboxReasons from "../../content/inboxReasons";
import inreachTeam from "../../config/inreach_team";
import DialogWrapper from "../components/DialogWrapper";
export default class InboxModal extends React.Component {
  state = {
    reason: inboxHelper.getDefaultReason(
      this.props.organization,
      this.props.client
    ),
  };

  handleNotesChange = (e) => {
    this.setState({
      notes: e.target.value,
    });
  };

  handleReasonChange = (e, key, reason) => {
    this.setState({
      reason,
    });
  };

  handleSubmit = () => {
    this.props.handleSubmit(
      this.props.organization,
      this.props.assignedTo,
      this.state.notes,
      this.state.reason
    );
  };

  render() {
    const { assignedTo, organization } = this.props;

    if (!assignedTo || !organization) {
      return null;
    }

    const { reason } = this.state;

    const actions = [
      <RaisedButton
        key="cancel"
        className="assign-action cancel"
        label="Cancel"
        style={{
          marginRight: "10px",
        }}
        onClick={this.props.handleClose}
      />,
      <RaisedButton
        key="submit"
        className="assign-action submit"
        backgroundColor="#5DAB49"
        labelStyle={{
          color: "white",
        }}
        onClick={this.handleSubmit}
        label="submit"
      />,
    ];

    const recipient = inreachTeam.find((tm) => tm.email === assignedTo) || {
      name: email,
    };

    return (
      <DialogWrapper
        title={`Why are you sending ${this.props.organization.name} to ${recipient.name}?`}
        className="inbox-dialog"
        actions={actions}
        actionsContainerClassName="actions-container"
        modal={false}
        open={true}
        onRequestClose={this.props.handleClose}
      >
        <div className="assign-container">
          <div className="inbox-section notes">
            <TextField
              floatingLabelText="Notes"
              floatingLabelFixed={true}
              hintText="Write your notes here..."
              underlineFocusStyle={{ borderColor: "#5DAB49" }}
              onChange={this.handleNotesChange}
              fullWidth={true}
            />
          </div>
          <div className="inbox-section reason">
            <SelectField
              selectedMenuItemStyle={{ color: "#5DAB49" }}
              floatingLabelText="Reason"
              floatingLabelFixed={true}
              value={this.state.reason}
              onChange={this.handleReasonChange}
            >
              {Object.entries(inboxReasons).map(([key, reason]) => (
                <MenuItem key={key} value={key} primaryText={reason} />
              ))}
            </SelectField>
          </div>
        </div>
      </DialogWrapper>
    );
  }
}
