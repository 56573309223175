import React from "react";
import PropTypes from "prop-types";
import imageUrl from "../lib/imageUrl";

import placeholder from "../../images/person-placeholder.jpg";

export default class Image extends React.Component {
  static propTypes = {
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    center: PropTypes.string,
    classes: PropTypes.array,
    circularBorder: PropTypes.bool,
  };

  state = {
    failed: false,
    loaded: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.url !== nextProps.url ||
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.state.loaded !== nextState.loaded ||
      this.state.failed !== nextState.failed
    );
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  onImageLoad = () => {
    if (this._mounted) {
      this.setState({ loaded: true });
    }
  };

  getClasses = (url) => {
    const classesList = [];
    if (this.state.loaded || !url) {
      classesList.push("image-loaded");
    }
    if (this.props.bordered) {
      classesList.push("bordered");
    }
    if (this.props.noMarginMobile) {
      classesList.push("no-margin-mobile");
    }
    return classesList.join(" ");
  };

  handleError = () => {
    this.setState({ failed: true });
  };

  render() {
    const url = imageUrl(this.props),
      classes = this.getClasses(url),
      { width, height, circularBorder = true } = this.props,
      { failed } = this.state;

    return (
      <div
        className="image-component"
        style={Object.assign(
          {
            width: `${width || 50}px`,
            height: `${height || width || 50}px`,
          },
          circularBorder ? { borderRadius: "50%" } : {}
        )}
      >
        <Choose>
          <When condition={url && !failed}>
            <img
              className={classes}
              src={url}
              onLoad={this.onImageLoad}
              onError={this.handleError}
            />
          </When>
          <Otherwise>
            <img className={classes} src={placeholder} />
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
