import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import SummarySection from "./summary/SummarySection";
//import PeopleSection from './people/PeopleSection';
import ProductSection from "./product/ProductSection";
import FundingSection from "./funding/FundingSection";
import SocialSection from "./social/SocialSection";
import OtherInfoSection from "./other-info/OtherInfoSection";

import Loading from "../../loading/Loading";

import defaultProfileOrder from "../../../content/defaultProfileOrder";
import Card from "../../card/Card";
import CardHeader from "../../card/CardHeader";
import CardBody from "../../card/CardBody";
import Organizations from "../../../lib/Organizations";
import _ from "underscore";

import Parcel from "single-spa-react/parcel";
import CompetitorsSection from "./competitors/CompetitorsSection";

class ProfileTab extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    snapshot: PropTypes.object.isRequired,
    snapshotLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool.isRequired,
    investors: PropTypes.array.isRequired,
    investorsLoading: PropTypes.bool.isRequired,
    people: PropTypes.array.isRequired,
    peopleLoading: PropTypes.bool.isRequired,
    showAsPublicProfile: PropTypes.bool.isRequired,
    signals: PropTypes.object.isRequired,
    signalsLoading: PropTypes.bool.isRequired,
    fundingRounds: PropTypes.array.isRequired,
    fundingRoundsLoading: PropTypes.bool.isRequired,
    investmentValues: PropTypes.object.isRequired,
    userRoles: PropTypes.array.isRequired,
    editPerson: PropTypes.func.isRequired,
  };

  getSectionMlUpdates = (sections = ["product", "market", "funding"]) => {
    const {
      organization: { ml_updated_features = {}, ml_updated_scores = [] } = {},
    } = this.props;

    const partnerLastScore = ml_updated_scores.filter((score_last_seen) => {
      const { user_id = "" } = score_last_seen;
      return user_id === "roberto@inreachventures.com";
    });
    const lastSeen = !_.isEmpty(partnerLastScore)
      ? partnerLastScore.last_seen
      : 0;
    const sectionUpdates = Object.values(ml_updated_features).filter(
      (mlUpdate) => {
        const { name = "", created_at = 0 } = mlUpdate;
        return sections.includes(name.split("_")[0]) && created_at > lastSeen;
      }
    );
    return sectionUpdates;
  };

  getSectionsOrder = () => {
    const {
      organization: {
        ml_features: {
          ui_ranking: {
            people = [],
            product = [],
            competitors = [],
            social = [],
            funding = [],
          } = {},
        } = {},
      } = {},
    } = this.props;
    const importanceRanks = {
      people: this.getImportanceRank(people),
      product: this.getImportanceRank(product),
      competitors: this.getImportanceRank(competitors),
      social: this.getImportanceRank(social),
      funding: this.getImportanceRank(funding),
    };
    return defaultProfileOrder.sort(
      (a, b) => importanceRanks[b] - importanceRanks[a]
    );
  };

  getImportanceRank = (section = []) =>
    (section.sort((a, b) => b.importance - a.importance)[0] || {}).importance ||
    0;

  getProductType = (useSnapshot = false) => {
    return Organizations.primaryTrendTypeFromOrganizationOrSnapshot(
      useSnapshot,
      this.props
    );
  };

  getProductRatings = (productType, useSnapshot = false) => {
    return productType === "app"
      ? this.getPrimaryAppRatings(useSnapshot)
      : { ratings: null, ratingCount: null };
  };

  getPrimaryAppRatings = (useSnapshot = false) => {
    const {
      organization: { products = [] } = {},
      snapshot: { organization: { products: snapshotProducts = [] } = {} },
    } = this.props;
    const { rating = 0, rating_count: ratingCount = 0 } =
      (!useSnapshot ? products : snapshotProducts).find(
        ({ id }) => id === this.getPrimaryAppId(useSnapshot)
      ) || {};
    return {
      rating,
      ratingCount,
    };
  };

  getProductAnalytics = (type, appId = null, useSnapshot = false) => {
    const {
      organization: {
        ml_features: {
          organization_trend: { transformed = "[]", raw = "" } = {},
        } = {},
      },
      signals: apiSignals = {},
    } = this.props;

    let signals;

    if (type === "web" && raw === "SIMILAR_WEB-TOTAL_VISIT") {
      signals = JSON.parse(transformed);

      if (signals.length === 0) {
        signals = apiSignals[raw];
      }
    } else if (
      type === "app" &&
      raw === `SENSORTOWER-SENSORTOWER_DOWNLOADS-${appId}`
    ) {
      signals = JSON.parse(transformed);
    } else {
      signals = this.getProductSignals(type, appId);
    }

    return {
      signals,
      magnitude: this.getProductTrendData("magnitude", useSnapshot),
      beta: this.getProductTrendData("beta", useSnapshot),
      name: raw,
    };
  };

  getProductSignalsName = (type, appId) => {
    return type === "app"
      ? `SENSORTOWER-SENSORTOWER_DOWNLOADS-${appId}`
      : "SIMILAR_WEB-TOTAL_VISIT";
  };

  getProductRevenue = (appId = null, useSnapshot = false) => {
    return {
      signals: this.getProductSignals("app", appId, true, false, useSnapshot),
    };
  };

  getProductSignals = (
    type,
    appId = null,
    revenue = false,
    useMlFeatures = false,
    useSnapshot = false
  ) => {
    const {
      organization = {},
      client,
      signals: apiSignals = {},
      snapshot: { decision: { created_at } = {} } = {},
      location: { query: { created_at: decisionCreatedAt } = {} } = {},
    } = this.props;

    return Organizations.primarySignals(
      organization,
      client,
      !useMlFeatures
        ? apiSignals[
            type === "app"
              ? `SENSORTOWER-SENSORTOWER_${
                  !revenue ? "DOWNLOADS" : "REVENUE"
                }-${appId === null ? this.getPrimaryAppId() : appId}`
              : "SIMILAR_WEB-TOTAL_VISIT"
          ] || null
        : null,
      useSnapshot ? created_at : null,
      decisionCreatedAt
    );
  };

  getPrimaryAppId = (useSnapshot = false) => {
    return Organizations.primaryTrendAppIdFromOrganizationOrSnapshot(
      useSnapshot,
      this.props
    );
  };

  getProductTrendData = (type, useSnapshot = false) => {
    return Organizations.primaryTrendDataFromOrganizationOrSnapshot(
      type,
      useSnapshot,
      this.props
    );
  };

  getProductTrend = (useSnapshot = false) => {
    return Organizations.primaryTrendFromOrganizationOrSnapshot(
      useSnapshot,
      this.props
    );
  };

  render() {
    const {
      organization = {},
      snapshot,
      snapshotLoading,
      useSnapshot,
      investors = [],
      investorsLoading,
      people = [],
      peopleLoading,
      signals = {},
      signalsLoading,
      fundingRounds,
      fundingRoundsLoading,
      investmentValues,
      userRoles,
      editPerson,
      organizationDecision,
      mountParcel,
    } = this.props;

    const sectionsOrder = this.getSectionsOrder();
    const mlUpdates = this.getSectionMlUpdates();
    return (
      <div className="profile-tab">
        <Choose>
          <When condition={Object.keys(organization).length === 0}>
            <Loading />
          </When>
          <Otherwise>
            <div className="summary-section">
              <Card>
                <CardHeader title="Summary" />
                <CardBody>
                  <SummarySection
                    organization={organization}
                    snapshot={snapshot}
                    snapshotLoading={snapshotLoading}
                    useSnapshot={useSnapshot}
                    investors={investors}
                    investorsLoading={investorsLoading}
                    signals={signals}
                    signalsLoading={signalsLoading}
                    fundingRounds={fundingRounds}
                    fundingRoundsLoading={fundingRoundsLoading}
                    investmentValues={investmentValues}
                    organizationDecision={organizationDecision}
                    getProductType={this.getProductType}
                    getProductRatings={this.getProductRatings}
                    getProductAnalytics={this.getProductAnalytics}
                    getProductTrend={this.getProductTrend}
                    getPrimaryAppId={this.getPrimaryAppId}
                    compressed={false}
                  />
                  <Parcel
                    config={() =>
                      System.import(
                        "@InReachVentures/organizations-explainable"
                      )
                    }
                    mountParcel={mountParcel}
                    organization={organization}
                    compressed={false}
                  />
                </CardBody>
              </Card>
            </div>
            <hr className="summary-hr" />
            {sectionsOrder.map((section) => {
              switch (section) {
                case "people":
                  return (
                    <Parcel
                      config={() =>
                        System.import("@InReachVentures/organizations-people")
                      }
                      mountParcel={mountParcel}
                      people={people}
                      peopleLoading={peopleLoading}
                      primaryContactId={organization.primary_contact_id}
                      verifiedPeople={
                        organization?.ml_features?.verified_people
                      }
                    />
                  );
                case "product":
                  return (
                    <ProductSection
                      organization={organization}
                      snapshot={snapshot}
                      snapshotLoading={snapshotLoading}
                      useSnapshot={useSnapshot}
                      signals={signals}
                      signalsLoading={signalsLoading}
                      key={section}
                      getProductType={this.getProductType}
                      getProductRatings={this.getProductRatings}
                      getProductAnalytics={this.getProductAnalytics}
                      getProductRevenue={this.getProductRevenue}
                      getProductTrend={this.getProductTrend}
                      getPrimaryAppId={this.getPrimaryAppId}
                      mlUpdates={mlUpdates}
                      organizationDecision={organizationDecision}
                    />
                  );
                case "competitors":
                  return (
                    <CompetitorsSection
                      organization={organization}
                      key={section}
                    />
                  );
                case "social":
                  return (
                    <SocialSection
                      organization={organization}
                      signals={signals}
                      key={section}
                      mlUpdates={mlUpdates}
                    />
                  );
                case "funding":
                  return (
                    <FundingSection
                      organization={organization}
                      snapshot={snapshot}
                      snapshotLoading={snapshotLoading}
                      useSnapshot={useSnapshot}
                      investors={investors}
                      investorsLoading={investorsLoading}
                      fundingRounds={fundingRounds}
                      fundingRoundsLoading={fundingRoundsLoading}
                      key={section}
                      mlUpdates={mlUpdates}
                    />
                  );
              }
            })}
            <hr className="summary-hr" />
            <OtherInfoSection organization={organization} />
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(ProfileTab);
