import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";
import Tooltip from "../../Tooltip";
import ActivityIcon from "material-ui/svg-icons/action/timeline";
import _ from "underscore";

class InvestorActivityPreview extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
  };
  state = {
    showTooltip: false,
  };

  hasError = () => {
    return true;
  };

  render() {
    const {
      investor: { aggregations, aggregations: { activity = {} } = {} } = {},
    } = this.props;
    const { showTooltip } = this.state;
    return (
      <div
        className="data-wrapper activity compressed"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: false });
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: true });
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-icon">
          <ActivityIcon className="data-icon-svg" />
        </div>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text="Investor Activity"
        />
        <Choose>
          <When condition={_.isEmpty(aggregations) || _.isEmpty(activity)}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="preview-box">
              <div className="invesor-profile-row" style={{ fontSize: "12px" }}>
                <div className="data-content text invesor-profile-div">
                  Year
                </div>
                <div className="spacer" />
                <div className="data-content text invesor-profile-div">New</div>
                <div className="spacer" />
                <div className="data-content text invesor-profile-div">
                  Follow
                </div>
                <div className="spacer" />
                <div className="data-content text invesor-profile-div">
                  Total
                </div>
              </div>
              {Object.entries(activity)
                .sort((a, b) => {
                  return b[0] - a[0];
                })
                .map(([key, value]) => {
                  const {
                    new: newInvestments = 0,
                    follow: followInvestments = 0,
                  } = value;
                  return (
                    <div className="invesor-profile-row" key={key}>
                      <div className="data-content text invesor-profile-div">
                        {key}
                      </div>
                      <div className="spacer" />
                      <div className="data-content text invesor-profile-div">
                        {newInvestments}
                      </div>
                      <div className="spacer" />
                      <div className="data-content text invesor-profile-div">
                        {followInvestments}
                      </div>
                      <div className="spacer" />
                      <div className="data-content text invesor-profile-div">
                        {newInvestments + followInvestments}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(InvestorActivityPreview);
