import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";

import Person from "./person/Person";

import ToggleShowMoreButton from "../ToggleShowMoreButton";

class OtherPeople extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    people: PropTypes.array.isRequired,
    editPerson: PropTypes.func.isRequired,
    useSnapshot: PropTypes.bool.isRequired,
  };

  state = {
    showPeople: false,
  };

  toggleShowMorePeopleClickHandler = () => {
    this.setState({
      showPeople: !this.state.showPeople,
    });
  };

  render() {
    const {
      organization = {},
      people = [],
      editPerson,
      useSnapshot = false,
    } = this.props;
    const { showPeople } = this.state;
    return (
      <div className="other-people">
        <ToggleShowMoreButton
          text={
            showPeople
              ? `Hide More People (${people.length})`
              : `Show More People (${people.length})`
          }
          clickHandler={this.toggleShowMorePeopleClickHandler}
          show={!showPeople}
        />
        <If condition={showPeople}>
          <div className="section-title main">Other People</div>
          <Choose>
            <When condition={people.length === 0}>
              <div className="section-no-content">No Other People</div>
            </When>
            <Otherwise>
              <div className="people-container">
                {people.map((person) => (
                  <Person
                    organization={organization}
                    person={person}
                    source="other"
                    key={person.id}
                    editPerson={editPerson}
                    useSnapshot={useSnapshot}
                  />
                ))}
              </div>
              <div className="bottom-other-section-hide-button">
                <ToggleShowMoreButton
                  text={`Hide More People (${people.length})`}
                  clickHandler={this.toggleShowMorePeopleClickHandler}
                  show={false}
                />
              </div>
            </Otherwise>
          </Choose>
        </If>
      </div>
    );
  }
}

export default withRouter(OtherPeople);
