import React from "react";
import PropTypes from "prop-types";

import WorkflowInformation from "./workflow-information/WorkflowInformation";
import Contacts from "./contacts/Contacts";
import OrganizationDetails from "./organization-details/OrganizationDetails";
import Files from "./Files";
import Timeline from "./timeline/Timeline";

import scrollToId from "../../../lib/pageScroll";

import OrganizationStore from "../../../storage/OrganizationStore";
import BackupMessageCustomizeCampaign from "../../assign/BackupMessageCustomizeCampaign";

import NavigationExpandLess from "material-ui/svg-icons/navigation/expand-less";
import NavigationExpandMore from "material-ui/svg-icons/navigation/expand-more";

export default class WorkflowTab extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired,
    files: PropTypes.array.isRequired,
    handleOrganizationChange: PropTypes.func.isRequired,
  };

  state = {
    showBackupMessageModal: false,
    scrollDirection: 1,
  };

  organizationStore = new OrganizationStore(this.props.organization.id);

  updateOrganization = (patches) => {
    this.organizationStore.updateModel(patches).then((result) => {
      this.props.handleOrganizationChange(result);
    });
  };

  toggleBackupMessageModal = () => {
    this.setState({
      showBackupMessageModal: !this.state.showBackupMessageModal,
    });
  };

  render() {
    const { organization, files } = this.props;
    return (
      <div id="workflow" className="workflow-content-container">
        <div className="first-section-content-container">
          <div className="left-column">
            <WorkflowInformation
              hasPrimaryContact={!!organization.primary_contact_id}
              {...this.props}
            />
            <Contacts
              primaryContactId={organization.primary_contact_id || ""}
              updateOrganization={this.updateOrganization}
              {...this.props}
            />
            <OrganizationDetails organization={organization} />
            <Files files={files || []} {...this.props} />
          </div>
          <div className="right-column">
            <Timeline
              openBackupMessageModal={this.toggleBackupMessageModal}
              {...this.props}
            />
          </div>
        </div>
        <BackupMessageCustomizeCampaign
          open={this.state.showBackupMessageModal}
          toggleBackupMessageModal={this.toggleBackupMessageModal}
          {...this.props}
        />
      </div>
    );
  }
}
