import PropTypes from "prop-types";
import React from "react";
import Category from "./Category";
import _ from "underscore";
import { categoryNames } from "../../../content/qualification";

export default class Categories extends React.Component {
  static propTypes = {
    qualification: PropTypes.object.isRequired,
  };

  getCompletedAndPrioritaryCategory = (category, key) => {
    const priority = !_.every(category, (task) => {
      return !(task.priority && task.completed ? false : task.priority);
    });
    const completed = _.every(category, (task) => {
      return task.completed;
    });
    return { id: key, completed: completed, priority: priority };
  };

  getInitiallyExpandedCategories = () => {
    let categoriesStatus = {};
    let categoriesExpanded = {};
    const categories = Object.entries(
      _.pick(this.props.qualification, Object.keys(categoryNames))
    );
    categories.map(([key, category]) => {
      categoriesStatus[key] = this.getCompletedAndPrioritaryCategory(
        category,
        key
      );
    });
    const expandPriorities = !_.every(categoriesStatus, (category) => {
      return !category.priority;
    });

    const completeFirst = _.findWhere(categoriesStatus, { completed: false });

    if (categoriesStatus === {}) {
      return categoriesExpanded;
    }
    if (!expandPriorities && !completeFirst) {
      return Object.keys(categoriesStatus).map((key) => {
        categoriesExpanded[key] = false;
      });
    }

    Object.entries(categoriesStatus).map(([key, value]) => {
      categoriesExpanded[key] = expandPriorities
        ? value.priority
        : key === completeFirst.id;
    });
    return categoriesExpanded;
  };

  getAssignedFilesList = () => {
    const categories = Object.values(
      _.pick(this.props.qualification, Object.keys(categoryNames))
    );
    if (categories.length === 0) {
      return;
    }

    return Object.values(categories)
      .reduce((list, category) => list.concat(Object.values(category)), [])
      .filter((task) => "files" in task)
      .reduce((filesList, task) => filesList.concat(task.files), [])
      .map((file) => file.drive_id);
  };

  hasPreviousDecision = () => {
    const { organization, client, qualification } = this.props;
    return !!(
      !!organization.source_refs &&
      !!organization.source_refs.QUALIFICATION &&
      organization.source_refs.QUALIFICATION[0].includes(
        `${organization.id}-${client}`
      ) &&
      "decision" in qualification &&
      ["pass", "reconnect", "termsheet"].indexOf(qualification.decision)
    );
  };

  render() {
    const categoriesExpanded = this.getInitiallyExpandedCategories();
    const assignedFiles = this.getAssignedFilesList();
    const { qualification } = this.props;
    const hasPreviousDecision = this.hasPreviousDecision();

    return (
      <div className="categories-container">
        {Object.entries(_.pick(qualification, Object.keys(categoryNames))).map(
          ([key, category]) => {
            categoryNames[key] = categoryNames[key] || {};
            return (
              <Category
                key={key}
                initiallyExpanded={
                  hasPreviousDecision ? false : categoriesExpanded[key] || false
                }
                organizationId={qualification.organization_id}
                category={category}
                categoryTitle={categoryNames[key].title}
                categoryIcon={categoryNames[key].icon}
                assignedFiles={assignedFiles}
                {...this.props}
              />
            );
          }
        )}
      </div>
    );
  }
}
