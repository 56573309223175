import React from "react";
import PropTypes from "prop-types";
import TimelineEventMailingBody from "./TimelineEventMailingBody";

import { truncateString } from "../../../../../lib/helpers";

import moment from "moment";
import xss from "xss";
import DeliveredIcon from "material-ui/svg-icons/content/send";
import ViewedIcon from "material-ui/svg-icons/action/face";
import RepliedIcon from "material-ui/svg-icons/content/reply";
import BouncedIcon from "material-ui/svg-icons/alert/error";
import Tooltip from "../../../../Tooltip";

export default class TimelineEventMailing extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    getUserName: PropTypes.func.isRequired,
  };

  emailDeliveredRef = React.createRef();
  emailReadRef = React.createRef();
  emailRepliedRef = React.createRef();
  emailBouncedRef = React.createRef();

  maxLabelLength = 120;

  buildItem = (item = this.props.item, type = this.props.type) => {
    const email = item.mailing || item.other.mailing;
    const fromPerson =
      this.props.getUserName(email.mailbox_address) || email.mailbox_address;
    const { label, subLabel } =
      email.body_text && "person" in item
        ? this.getEmailLabels(
            fromPerson,
            [item.person.name || item.person.email],
            email.subject,
            String(
              email.body_text ? email.body_text.replace(/(<([^>]+)>)/g, "") : ""
            ).replace(/\r?\n|\r/g, " ")
          )
        : this.getEventInvitationLabels(fromPerson, email.subject);
    return {
      label: this.getShortLabel(label),
      subLabel: subLabel,
      email: this.getMailingEmailContent(email, item.person),
      showBody: typeof email.body_text !== "undefined",
    };
  };

  getEmailLabels = (emailFrom, recipients = [], subject, body) => {
    const toString = this.getEmailToLabelString(recipients);
    const label = `${this.getPerson(
      emailFrom
    )} > ${toString}: <i>${subject}</i>`;
    const labelLength = label.length;
    return {
      label: label,
      subLabel: this.getSubLabel(body, labelLength),
    };
  };

  getEventInvitationLabels = (fromPerson, subject) => {
    const string = `${fromPerson} ${subject}`;
    return {
      label: truncateString(string, this.maxLabelLength),
      subLabel: null,
    };
  };

  getEmailToLabelString = (recipients) => {
    if (recipients.length < 1) {
      return "";
    }
    return recipients.length > 1
      ? this.getPerson(recipients[0]) + "..."
      : this.getPerson(recipients[0]);
  };

  getSubLabel = (body, labelLength) => {
    return `- ${truncateString(body, this.maxLabelLength, labelLength)}`;
  };

  getShortLabel = (label) => {
    return `${truncateString(label, this.maxLabelLength)}`;
  };

  getMailingEmailContent = (email, person) => {
    const emailTo = person ? `${person.name} <${person.email}>` : null;
    return {
      emailFrom: this.getPerson(email.mailbox_address),
      sent: this.reformatDate(email.delivered_at),
      emailTo: emailTo ? this.getEmailToString([emailTo]) : null,
      subject: email.subject,
      body: email.body_html || "",
    };
  };

  reformatDate = (sent) => {
    return moment(sent).format("dddd, MMMM DD, YYYY LT");
  };

  getEmailToString = (recipients = []) => {
    if (recipients.length < 1) {
      return "";
    }
    return recipients.map((recipient) => recipient).join(", ");
  };

  getPerson = (person) => {
    return person === "bonanzinga@inreachventures.com"
      ? "Roberto Bonanzinga"
      : person.indexOf("<") > -1
      ? person.substring(0, person.indexOf("<") - 1)
      : this.props.getUserName(person) || person;
  };

  buildEmailStatus = () => {
    if (!this.props.item) return null;
    const {
      item: {
        mailing: {
          open_count: openCount,
          opened_at: openedAt,
          delivered_at: deliveredAt,
          replied_at: repliedAt,
          bounced_at: bouncedAt,
        },
      },
    } = this.props;
    return (
      <React.Fragment>
        <div ref={this.emailDeliveredRef} className="email-status">
          <DeliveredIcon
            ref
            color={deliveredAt ? "#48a0ff" : "#c7c7c7"}
            style={{ marginRight: "8px" }}
          />
        </div>
        <Tooltip
          parent={this.emailDeliveredRef.current}
          text={
            deliveredAt
              ? `Delivered on ${this.reformatDate(deliveredAt)}`
              : "Hasn't been delivered"
          }
          showOnHover={true}
          position="Top"
          customStyles={{ right: 0 }}
        />
        {/*<div ref={this.emailReadRef} className="email-status">*/}
        {/*<ViewedIcon*/}
        {/*color={openedAt ? '#48a0ff' : '#c7c7c7'}*/}
        {/*style={{marginRight: '8px'}}*/}
        {/*/>*/}
        {/*</div>*/}
        {/*<Tooltip*/}
        {/*parent={this.emailReadRef.current}*/}
        {/*text={*/}
        {/*openedAt*/}
        {/*? `Opened ${openCount} times, last on ${this.reformatDate(*/}
        {/*openedAt*/}
        {/*)}`*/}
        {/*: "Hasn't been opened"*/}
        {/*}*/}
        {/*showOnHover={true}*/}
        {/*position="Top"*/}
        {/*customStyles={{right: 0}}*/}
        {/*/>*/}
        <div ref={this.emailRepliedRef} className="email-status">
          <RepliedIcon
            color={repliedAt ? "#48a0ff" : "#c7c7c7"}
            style={{ marginRight: "8px" }}
          />
        </div>
        <Tooltip
          parent={this.emailRepliedRef.current}
          text={
            repliedAt
              ? `Replied on ${this.reformatDate(repliedAt)}`
              : "Hasn't replied"
          }
          showOnHover={true}
          position="Top"
          customStyles={{ right: 0 }}
        />
        <div ref={this.emailBouncedRef} className="email-status">
          <BouncedIcon
            color={bouncedAt ? "#48a0ff" : "#c7c7c7"}
            style={{ marginRight: "8px" }}
          />
        </div>
        <Tooltip
          parent={this.emailBouncedRef.current}
          text={
            bouncedAt
              ? `Bounced on ${this.reformatDate(bouncedAt)}`
              : "Hasn't Bounced"
          }
          showOnHover={true}
          position="Top"
          customStyles={{ right: 0 }}
        />
      </React.Fragment>
    );
  };

  render() {
    const { label, subLabel, email, showBody } = this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <div className="timeline-event-email-text">
          <h5
            className="timeline-event-label"
            dangerouslySetInnerHTML={{ __html: xss(label) }}
          />
          <p className="timeline-event-sub-label">{subLabel}</p>
        </div>
        <div className="spacer" />
        {this.buildEmailStatus()}
        <If condition={showBody}>
          <TimelineEventMailingBody email={email} />
        </If>
      </div>
    );
  }
}
