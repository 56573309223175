import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";

import moment from "moment";
import { campaignConfigs } from "../../../../../config/campaigns";
import TimelineEventActions from "./TimelineEventActions";
import DialogWrapper from "../../../../components/DialogWrapper";
export default class TimelineEventAssignCampaign extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    handleAssignCampaignCancel: PropTypes.func.isRequired,
    handleAssignCampaignRetry: PropTypes.func.isRequired,
  };

  state = {
    showCancel: false,
    showRetry: false,
  };

  renderLabel = (item = this.props.item) => {
    const {
      assign_campaign: {
        made_at: madeAt = new Date().getTime(),
        sequence_name: sequenceName,
        mailing: { template_name: templateName } = {},
      } = {},
    } = item;
    const campaignConfig =
      campaignConfigs[sequenceName || templateName] ||
      campaignConfigs[templateName || ""] ||
      {};
    const { shortLabel, type = "" } = campaignConfig;

    if (type.indexOf("campaign") > -1) {
      return (
        <span>
          Attempted assigning to <b>{shortLabel}</b> campaign on{" "}
          <b>{moment(new Date(madeAt)).format("dddd Do MMMM YYYY")}</b>
        </span>
      );
    }
    return (
      <span>
        Attempted sending <b>{shortLabel}</b> on{" "}
        <b>{moment(new Date(madeAt)).format("dddd Do MMMM YYYY")}</b>
      </span>
    );
  };

  handleCancel = () => {
    this.setState({ showCancel: false });
    this.props.handleAssignCampaignCancel();
  };

  handleRetry = () => {
    this.setState({ showRetry: false });
    this.props.handleAssignCampaignRetry();
  };

  render() {
    const { showCancel, showRetry } = this.state;
    const actions = [
      {
        text: "Retry",
        onClick: () => this.setState({ showRetry: true }),
      },
      {
        text: "Cancel",
        onClick: () => this.setState({ showCancel: true }),
      },
    ];

    return (
      <div className="timeline-event-inner-wrapper">
        <h5 className="timeline-event-label">{this.renderLabel()}</h5>
        <p className="timeline-event-sub-label">needs complete data</p>
        <div className="spacer" />
        <TimelineEventActions actions={actions} />
        <DialogWrapper
          title="Confirm cancel Campaign Assignment"
          actions={[
            <RaisedButton
              className="assign-action"
              key="cancel"
              label="Cancel"
              style={{
                marginRight: "10px",
              }}
              onClick={() => this.setState({ showCancel: false })}
            />,
            <RaisedButton
              className="assign-action"
              key="confirm"
              backgroundColor="#5DAB49"
              labelStyle={{
                color: "white",
              }}
              label="Confirm"
              onClick={this.handleCancel}
            />,
          ]}
          modal={false}
          open={showCancel}
          onRequestClose={() => this.setState({ showCancel: false })}
        >
          Are you sure you want to cancel this campaign?
        </DialogWrapper>
        <DialogWrapper
          title="Confirm retry Campaign Assignment"
          actions={[
            <RaisedButton
              className="assign-action"
              key="cancel"
              label="Cancel"
              style={{
                marginRight: "10px",
              }}
              onClick={() => this.setState({ showRetry: false })}
            />,
            <RaisedButton
              className="assign-action"
              key="confirm"
              backgroundColor="#5DAB49"
              labelStyle={{
                color: "white",
              }}
              label="Confirm"
              onClick={this.handleRetry}
            />,
          ]}
          modal={false}
          open={showRetry}
          onRequestClose={() => this.setState({ showRetry: false })}
        >
          Are you sure you want to retry this campaign?
        </DialogWrapper>
      </div>
    );
  }
}
