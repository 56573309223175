import React from "react";
import InvestorStore from "../../storage/InvestorStore";
import InvestorNavigator from "./InvestorNavigator";
import Loading from "../loading/Loading";
import InvestorTopBar from "./InvestorTopBar";
import InvestorTabsContent from "./InvestorTabsContent";
import OrganizationStore from "../../storage/OrganizationStore";
import auth from "../../storage/Auth";
import { withRouter } from "react-router-v4";
import _ from "underscore";
import EditPersonDialog from "../organization/EditPersonDialog";
import ErrorMessage from "../organization/Organization";
import Organizations from "../../lib/Organizations";
import AddPersonDialog from "../organization/AddPersonDialog";

class Investor extends React.Component {
  state = {
    openDIGDownDialog: false,
    selectedTab: this.props.params.tab || "profile",
    userHasFullAccess: false,
    showEditPersonDialog: false,
    editPersonId: null,
    showAddPersonDialog: false,
    client: null,
    displayError: false,
    error: null,
    tabsContent: {},
    organizationDecision: {},
  };

  getLinkedOrganization = (organizationId) => {
    this.organizationStore = new OrganizationStore(organizationId);
    this.organizationStore
      .getModel()
      .then((organization) => {
        const { client } = this.state;
        const organizationDecision =
          Organizations.getOrganizationState(organization, client) || {};
        this.setState({
          loading: false,
          organizationDecision,
        });

        this.handleOrganizationChange(organization);
      })
      .catch((err) => {
        const errorMessage = `Problem retrieving organization ${organizationId}: ${err}`;
        console.error(errorMessage, err);
        this.setState({
          openDIGDownDialog: true,
          errorMessageDIGDown: errorMessage,
          loading: false,
        });
      });
  };

  initComponent = (investor_id) => {
    this.investorStore = new InvestorStore();

    this.setState({
      loading: true,
    });
    auth
      .getAuthData()
      .then(({ client, profile, accessType, roles }) => {
        this.setState({
          userHasFullAccess: accessType === "full",
          userRoles: roles,
          client,
          profile,
          showAsPublicProfile: false,
        });
      })
      .catch((err) => {
        this.setState({
          userHasFullAccess: false,
          userRoles: ["browse_share"],
        });
        console.error(err);
      });

    this.investorStore
      .getInvestor(investor_id)
      .then((investor) => {
        const { organization_id = null } = investor;
        if (organization_id) {
          this.getLinkedOrganization(organization_id);
        }
        this.handleInvestorChange(investor);
      })
      .catch((err) => {
        const errorMessage = `Problem retrieving Investor ${this.props.params.id}: ${err}`;
        console.log(errorMessage);
        console.log(err);
        console.error(errorMessage, err);
        this.setState({
          openDIGDownDialog: true,
          errorMessageDIGDown: errorMessage,
          loading: false,
        });
      });
  };

  componentWillMount() {
    this.initComponent(this.props.params.id);
  }

  componentDidUpdate(prevProps) {
    const { selectedTab } = this.state;
    const { params: { tab: paramTab } = {} } = this.props;
    const currentTab =
      paramTab && paramTab.includes("?")
        ? paramTab.split("?")[0]
        : paramTab
        ? paramTab
        : "profile";
    if (currentTab !== selectedTab) {
      this.setState({ selectedTab: currentTab });
    }
    const { id, tab } = this.props;

    const { tab: prevTab } = prevProps;
  }

  handleOrganizationChange = (organization) => {
    this.setState({
      organization,
    });
  };

  handleInvestorChange = (investor) => {
    this.setState({ investor: investor });
  };

  editPerson = (personId) => {
    this.setState({
      showEditPersonDialog: true,
      editPersonId: personId,
    });
  };

  handleSaveEditPersonDialog = () => {
    this.handleOrganizationChange(this.state.organization.id);
    this.setState({
      showEditPersonDialog: false,
      editPersonId: null,
    });
  };

  handleCloseEditPersonDialog = () => {
    this.setState({
      showEditPersonDialog: false,
      editPersonId: null,
    });
  };

  addPerson = () => {
    this.setState({
      showAddPersonDialog: true,
    });
  };

  handleCloseAddPersonDialog = () => {
    this.setState({
      showAddPersonDialog: false,
    });
  };

  handlePersonAdd = (personId) => {
    const organization = Object.assign({}, this.state.organization, {
      person_ids: this.state.organization.person_ids
        ? this.state.organization.person_ids.concat(personId)
        : [personId],
    });
    this.setState({
      organization,
      showAddPersonDialog: false,
    });
  };

  updateSelectedTab = (selectedTab) => {
    this.setState({ selectedTab });
  };

  setTabContent = (key, data) => {
    if (data) {
      const storedData = this.state.tabsContent;
      storedData[key] = data;
      this.setState({ tabsContent: storedData });
    }
  };

  render() {
    const {
      organization,
      organization: { id: organizationId } = {},
      investor,
      userHasFullAccess,
      client,
      selectedTab,
      showAsPublicProfile,
      openDIGDownDialog,
      error,
      displayError,
      userRoles,
      tabsContent,
      showEditPersonDialog,
      showAddPersonDialog,
      editPersonId,
      organizationDecision,
      profile,
    } = this.state;
    if (_.isEmpty(investor)) {
      return <Loading />;
    } else {
      return (
        <div className="investor-view">
          <InvestorNavigator investor={investor} />
          <EditPersonDialog
            onCloseDialog={this.handleCloseEditPersonDialog}
            onSaveDialog={this.handleSaveEditPersonDialog}
            show={showEditPersonDialog}
            personId={editPersonId}
            organizationId={organizationId}
          />
          <If condition={organizationId}>
            <AddPersonDialog
              onCloseDialog={this.handleCloseAddPersonDialog}
              show={showAddPersonDialog}
              organizationId={organizationId}
              onAddPerson={this.handlePersonAdd}
            />
          </If>
          <If condition={error}>
            <ErrorMessage
              error={error || ""}
              open={displayError}
              closeErrorDialog={this.handleErrorClose}
            />
          </If>
          <If condition={investor}>
            <div className="page-width investor-container">
              <InvestorTopBar
                investor={investor}
                organization={organization}
                updateSelectedTab={this.updateSelectedTab}
                tabsContent={tabsContent}
                selectedTab={selectedTab}
                temporaryShowNote={this.temporaryShowNote}
                client={client}
                userRoles={userRoles}
                userHasFullAccess={userHasFullAccess}
                showAsPublicProfile={showAsPublicProfile}
                profile={profile}
              />
              <InvestorTabsContent
                investor={investor}
                organization={organization}
                setTabContent={this.setTabContent}
                updateSelectedTab={this.updateSelectedTab}
                editPerson={this.editPerson}
                addPerson={this.addPerson}
                handleOrganizationChange={this.handleOrganizationChange}
                tabsContent={tabsContent}
                selectedTab={selectedTab}
                client={client}
                userRoles={userRoles}
                userHasFullAccess={userHasFullAccess}
                showAsPublicProfile={showAsPublicProfile}
                organizationDecision={organizationDecision}
              />
            </div>
          </If>
        </div>
      );
    }
  }
}

export default withRouter(Investor);
