import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

class FilterSummary extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
  };

  capitalize = (str) => {
    return str.replace(/(?:^|\s)\S/g, function (word) {
      return word.toUpperCase();
    });
  };

  joinArray = (array) => {
    var result = "";
    for (var i = 0; i < array.length; i++) {
      if (i == 0) {
        result = array[i];
      } else if (i === array.length - 1) {
        result += " and " + array[i];
      } else {
        result += ", " + array[i];
      }
    }
    return result;
  };

  render() {
    var params = this.props.params;
    var text = [];

    if (params.query) {
      text.push(`containing the text '${params.query}'`);
    }

    if (params.skills) {
      text.push(`have the skills '${params.skills}'`);
    }

    if (params.order_by) {
      var direction = params.order === "asc" ? "an ascending" : "a descending";
      text.push(`ordered by '${params.order_by}' in ${direction}.farhion`);
    }

    if (params.created_at_after) {
      if (params.created_at_before) {
        text.push(
          `first seen between ${moment(params.created_at_after, "x").fromNow(
            true
          )} and ${moment(params.created_at_before, "x").fromNow()}`
        );
      } else {
        text.push(
          `first seen after ${moment(params.created_at_after, "x").fromNow()}`
        );
      }
    } else if (params.created_at_before) {
      text.push(
        `first seen before ${moment(params.created_at_before, "x").fromNow()}`
      );
    }

    if (text.length < 1) {
      text.push("are unfiltered...");
    }

    return <h5>People {this.joinArray(text)}</h5>;
  }
}

export default FilterSummary;
