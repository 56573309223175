import React from "react";
import { Link, withRouter } from "react-router-v4";
import PropTypes from "prop-types";

class Description extends React.Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
  };

  state = {
    showFullDescription: false,
  };

  maxDefaultLength = 150;

  getDescriptionText = () => {
    const { description = "" } = this.props;
    if (description.length < this.maxDefaultLength) {
      return description;
    } else {
      const shortenedDescription = description.substring(
        0,
        this.maxDefaultLength
      );
      let index = shortenedDescription.length - 1;
      while (index > 0) {
        if (shortenedDescription.substring(index, index + 1) === " ") {
          return `${shortenedDescription.substring(0, index)}...`;
        }
        index--;
      }
      return `${shortenedDescription}...`;
    }
  };

  handleToggleFullDescription = () => {
    this.setState({
      showFullDescription: !this.state.showFullDescription,
    });
  };

  render() {
    const { description: originalDescription = "" } = this.props;
    const { showFullDescription = false } = this.state;
    const reducedDescription = this.getDescriptionText();
    return (
      <div className="data-wrapper description">
        <div className="data-header">Description</div>
        <Choose>
          <When condition={originalDescription === ""}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="data-content text description">
              <Choose>
                <When
                  condition={!showFullDescription}
                >{`${reducedDescription} `}</When>
                <Otherwise>{`${originalDescription} `}</Otherwise>
              </Choose>
            </div>
            <If
              condition={originalDescription.length > reducedDescription.length}
            >
              <Link
                style={{ cursor: "pointer" }}
                onClick={this.handleToggleFullDescription}
                className="show-more-text-link"
              >
                {!showFullDescription ? "Show more" : "Show less"}
              </Link>
            </If>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(Description);
