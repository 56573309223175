import ModelStore from "./ModelStore";

var GithubUserStore = function (id) {
  this.id = id;
  this.path = "/providers/github/users/{id}";

  return this;
};
GithubUserStore.prototype = new ModelStore();

export default GithubUserStore;
