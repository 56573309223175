import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";
import Tooltip from "../../Tooltip";
import InsightsIcon from "material-ui/svg-icons/action/stars";
import { investorScoreCategory } from "../../../content/investorScore";
class InvestorInsightsPreview extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
  };
  state = {
    showTooltip: false,
  };

  hasError = () => {
    return true;
  };

  render() {
    const { investor = {} } = this.props;
    const {
      ml_features: {
        investor_score: { score = 0 } = {},
        is_good_investor: { score: isGoodInvestor = 0 } = {},
        is_top_tier: { score: isTopTier = 0 } = {},
        number_qualifying_investments: { score: numberInvestments = 0 } = {},
        number_top_tier_investments: {
          score: numberTopTierInvestments = 0,
        } = {},
      } = {},
    } = investor;
    //const scoreCategory = investorScoreCategory(investor);
    const { showTooltip } = this.state;
    return (
      <div
        className="data-wrapper investments compressed"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: false });
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: true });
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-icon">
          <InsightsIcon className="data-icon-svg" />
        </div>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text="Investor Insights"
        />
        <Choose>
          <When condition={false}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="preview-box">
              <div className="invesor-profile-row">
                <Choose>
                  <When condition={isTopTier}>
                    <div className="data-content text invesor-profile-div">
                      <span className="highlighted-text">Top tier</span>
                    </div>
                  </When>
                  <Otherwise>
                    <div className="data-content text invesor-profile-div">
                      Prob of follow on:{" "}
                      <span
                        className={isGoodInvestor ? "highlighted-text" : ""}
                      >
                        {Math.round(score)}%
                      </span>
                      <div className="spacer" />({numberTopTierInvestments} /{" "}
                      {numberInvestments} relevant investments)
                    </div>
                  </Otherwise>
                </Choose>
              </div>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(InvestorInsightsPreview);
