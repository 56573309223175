import React from "react";
import { withRouter } from "react-router-v4";
import PropTypes from "prop-types";
import Tooltip from "../../Tooltip";
import ExploreIcon from "material-ui/svg-icons/action/explore";
import _ from "underscore";

class LocationsPreview extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
  };
  state = {
    showTooltip: false,
  };

  hasError = () => {
    return true;
  };

  render() {
    const {
      investor: {
        aggregations,
        aggregations: { country_counts = {} } = {},
      } = {},
    } = this.props;
    const { showTooltip } = this.state;
    return (
      <div
        className="data-wrapper geographic-coverage compressed"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: false });
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({ showTooltip: true });
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text="Geographic Coverage"
        />
        <div className="data-icon">
          <ExploreIcon className="data-icon-svg" />
        </div>
        <Choose>
          <When
            condition={_.isEmpty(aggregations) || _.isEmpty(country_counts)}
          >
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="preview-box">
              {Object.entries(country_counts)
                .sort((a, b) => {
                  return b[1] - a[1];
                })
                .map(([key, value]) => {
                  return (
                    <div className="invesor-profile-row" key={key}>
                      <div className="data-content text invesor-profile-div">
                        {key}
                      </div>
                      <div className="spacer" />
                      {value}
                    </div>
                  );
                })}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(LocationsPreview);
